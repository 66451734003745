import { Action } from 'vuex';

import PublicApi from 'Apis/PublicData';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import Asset from 'Entities/publicPresenter/Asset';
import BlockchainsRequest from 'Lib/entities/publicPresenter/BlockchainsRequest';
import AssetsRequest from 'Lib/entities/publicPresenter/AssetsRequest';
import AccountsApi from 'Apis/Accounts';
import BalancesRequest from 'Entities/privatePresenter/BalancesRequest';
import Balance from 'Entities/privatePresenter/Balance';
import WalletsApi from 'Apis/Wallets';
import UserTransferAddressesParams from 'Entities/privatePresenter/UserTransferAddressesParams';
import { actionCreator, mutationCreator } from 'Store/utils';
import UserTransferAddress from 'Entities/privatePresenter/UserTransferAddress';
import CreateDepositRequestData, { ICreateDepositRequestData } from 'Entities/walletExecutor/CreateDepositRequestData';
import WalletAddressRequestData from 'Entities/walletExecutor/WalletAddressRequestData';
import WalletAddressResponseData from 'Entities/walletExecutor/WalletAddressResponseData';
import TransferRequest from 'Entities/privatePresenter/TransferRequest';
import { UPDATE_TRANSFER } from 'Store/v2/TransferHistory';
import EmptyResult from 'Lib/entities/walletExecutor/EmptyResult';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';

export interface DepositUI {
    currentAssetIndex: number, // asset index
    currentBlockchainIndex: number, // blockchain index
    currentAddressIndex: number,
    asset: string,
    blockchain: string | undefined,
    address: null | UserTransferAddress,
    destinationAddress: null | WalletAddressResponseData | EmptyResult,
    balances: Balance[],
    amount: number,
    transferId: string,
}

const state = {
    blockchains: undefined as undefined | Map<string, Blockchain>,
    assets: undefined as undefined | Map<string, Asset>,
    addresses: [] as UserTransferAddress[],
    clickedDeposit: null,
    ui: {
        currentAssetIndex: 0,
        currentBlockchainIndex: 0,
        currentAddressIndex: 0,
        asset: '',
        blockchain: '',
        address: null,
        destinationAddress: null,
        balances: [],
        amount: 0,
        transferId: '',
    } as DepositUI,
};

export type DepositState = typeof state;

export enum DepositGetters {
    GET_ACTIVE_BALANCE = 'GET_ACTIVE_BALANCE',
    GET_BLOCKCHAINS = 'GET_BLOCKCHAINS',
    GET_ASSETS = 'GET_ASSETS',
    GET_TRANSFER_MIN_SIZE = 'GET_TRANSFER_MIN_SIZE'
}

type GettersReturn<G extends { [key in DepositGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[DepositGetters]> };

interface Getters {
    GET_BLOCKCHAINS: (state: DepositState, getters: GettersReturn<Getters>) => Blockchain[];
    GET_ASSETS: (state: DepositState, getters: GettersReturn<Getters>) => Asset[];
    GET_ACTIVE_BALANCE: (state: DepositState, getters: GettersReturn<Getters>) => Balance[];
    GET_TRANSFER_MIN_SIZE: (state: DepositState, getters: GettersReturn<Getters>) => number;
}

const getters: Getters = {
    GET_BLOCKCHAINS(state) {
        if (!state.assets) {
            return [];
        }
        return Array.from(state.blockchains?.values() || []).filter((b) => {
            let flag = false;
            state.assets!.get(state.ui.asset)!.transferDetails!.forEach((d) => {
                if (d.blockchainName === b.name) {
                    flag = true;
                }
            });
            return flag;
        });
    },
    GET_ASSETS(state) {
        return Array.from(state.assets?.values() || []);
    },
    GET_ACTIVE_BALANCE(state) {
        return state.ui.balances!.length > 0 ? state.ui.balances!.filter((b) => Array.from(state.assets?.values() || [])[state.ui.currentAssetIndex].symbol === b.assetSymbol && state.ui.blockchain === b.blockchainName && b.placementName === 'Single Broker') : [];
    },
    GET_TRANSFER_MIN_SIZE(state) {
        const asset = state.assets?.get(state.ui.asset);
        if (!asset) {
            return 0;
        }
        const transferDetail = asset.transferDetails?.find((d) => d.blockchainName === state.ui.blockchain);
        if (!transferDetail || !transferDetail.transferMinSize) {
            return 0;
        }
        return Number(transferDetail.transferMinSize);
    },
};

export enum DepositMutations {
    SET_BLOCKCHAINS = 'SET_BLOCKCHAINS',
    SET_ASSETS = 'SET_ASSETS',
    SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET',
    SET_ACTIVE_BLOCKCHAIN = 'SET_ACTIVE_BLOCKCHAIN',
    SET_ACTIVE_ADDRESS = 'SET_ACTIVE_ADDRESS',
    SET_AMOUNT = 'SET_AMOUNT',
    SET_CLICKED_DEPOSIT = 'SET_CLICKED_DEPOSIT',
}

export const SET_BLOCKCHAINS = mutationCreator<Blockchain[]>('Deposit', DepositMutations.SET_BLOCKCHAINS);
export const SET_ASSETS = mutationCreator<Asset[]>('Deposit', DepositMutations.SET_ASSETS);
export const SET_ACTIVE_ASSET = mutationCreator<number>('Deposit', DepositMutations.SET_ACTIVE_ASSET);
export const SET_ACTIVE_BLOCKCHAIN = mutationCreator<number>('Deposit', DepositMutations.SET_ACTIVE_BLOCKCHAIN);
export const SET_ACTIVE_ADDRESS = mutationCreator<number>('Deposit', DepositMutations.SET_ACTIVE_ADDRESS);
export const SET_AMOUNT = mutationCreator<number>('Deposit', DepositMutations.SET_AMOUNT);
export const SET_CLICKED_DEPOSIT = mutationCreator<any>('Deposit', DepositMutations.SET_CLICKED_DEPOSIT);

const mutations: Record<DepositMutations, (state: DepositState, ...args: any) => void> = {
    SET_BLOCKCHAINS(state, blockchains: ReturnType<typeof SET_BLOCKCHAINS>) {
        const map = new Map<string, Blockchain>();
        blockchains.payload.forEach((b) => map.set(b.name, b));
        state.blockchains = map;
        state.ui.blockchain = Array.from(state.assets?.values() || [])[state.ui.currentAssetIndex].transferDetails![0].blockchainName;
    },
    SET_ASSETS(state, assets: ReturnType<typeof SET_ASSETS>) {
        const map = new Map<string, Asset>();
        assets.payload.forEach((b) => map.set(b.symbol, b));
        state.assets = map;
        state.ui.asset = Array.from(state.assets?.values() || [])[state.ui.currentAssetIndex].symbol;
    },
    SET_ACTIVE_ASSET(state, assetIndex: ReturnType<typeof SET_ACTIVE_ASSET>) {
        state.ui.currentAssetIndex = assetIndex.payload;
        state.ui.asset = Array.from(state.assets?.values() || [])[state.ui.currentAssetIndex].symbol;
        state.ui.blockchain = state.assets!.get(state.ui.asset)!.transferDetails![0].blockchainName;
    },
    SET_ACTIVE_BLOCKCHAIN(state, blockchainIndex: ReturnType<typeof SET_ACTIVE_BLOCKCHAIN>) {
        state.ui.currentBlockchainIndex = blockchainIndex.payload;
        const blockchainsArray = Array.from(state.blockchains?.values() || []).filter((b) => {
            let flag = false;
            state.assets!.get(state.ui.asset)!.transferDetails!.forEach((d) => {
                if (d.blockchainName === b.name) {
                    flag = true;
                }
            });
            return flag;
        });
        state.ui.blockchain = blockchainsArray[blockchainIndex.payload].name;
    },
    SET_ACTIVE_ADDRESS(state, addressIndex: ReturnType<typeof SET_ACTIVE_ADDRESS>) {
        state.ui.currentAddressIndex = addressIndex.payload;
        state.ui.address = state.addresses[addressIndex.payload];
    },
    SET_AMOUNT(state, amount: ReturnType<typeof SET_AMOUNT>) {
        state.ui.amount = amount.payload;
    },
    SET_CLICKED_DEPOSIT(state, data: ReturnType<typeof SET_CLICKED_DEPOSIT>) {
        state.clickedDeposit = data.payload;
    },
};

export enum DepositActions {
    init = 'init',
    getBlockchains = 'getBlockchains',
    getAssets = 'getAssets',
    getBalance = 'getBalance',
    getAddresses = 'getAddresses',
    createDeposit = 'createDeposit',
    getAddress = 'getAddress',
}

export const init = actionCreator<null>('Deposit', DepositActions.init);
export const getBlockchains = actionCreator<null>('Deposit', DepositActions.getBlockchains);
export const getAssets = actionCreator<null>('Deposit', DepositActions.getAssets);
export const getBalance = actionCreator<string>('Deposit', DepositActions.getBalance);
export const getAddresses = actionCreator<null>('Deposit', DepositActions.getAddresses);
export const createDeposit = actionCreator<string>('Deposit', DepositActions.createDeposit);
export const getAddress = actionCreator<string>('Deposit', DepositActions.getAddress);

const actions: Record<DepositActions, Action<DepositState, any>> = {
    async init({ dispatch }) {
        await dispatch(getAssets(null, true));
        await dispatch(getBlockchains(null, true));
    },
    async getBlockchains({ commit, dispatch }) {
        try {
            const { data: resp } = await PublicApi.publicGetBlockchains(new BlockchainsRequest({}));
            commit(SET_BLOCKCHAINS(resp, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting blockchains', { root: true });
            }
        }
    },
    async getAssets({ commit, dispatch }) {
        try {
            const { data: resp } = await PublicApi.publicGetAssets(new AssetsRequest({
                fromPlacementName: 'Single Broker',
                transferable: true,
                includeTransferDetails: true,
                perPage: 256,
            }));
            commit(SET_ASSETS(resp, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting assets', { root: true });
            }
        }
        return Promise.resolve();
    },
    async getBalance({ state, dispatch }, accountId: ReturnType<typeof getBalance>) {
        try {
            let allBalances: Balance[];
            const { data: res, headers } = await AccountsApi.privateGetBalances(new BalancesRequest({
                accountId: accountId.payload,
                placementName: 'Single Broker',
                perPage: 100,
                page: 1,
                includeTotal: true,
            }), true);
            allBalances = [...res];
            if (headers) {
                const totalPages = parsePaginationHeaders(headers).totalPage;
                if (totalPages && totalPages > 1) {
                    for (let i = 2; i <= totalPages; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        const { data: resExtra } = await AccountsApi.privateGetBalances(new BalancesRequest({
                            accountId: accountId.payload,
                            placementName: 'Single Broker',
                            perPage: 100,
                            page: i,
                        }));
                        allBalances = [...allBalances, ...resExtra];
                    }
                }
            }
            state.ui.balances = allBalances;
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting balances', { root: true });
            }
        }
    },
    async getAddresses({ state, dispatch }) {
        try {
            const { data: res } = await WalletsApi.privateGetUserTransferAddresses(new UserTransferAddressesParams({
                blockchainName: state.ui.blockchain,
                status: 'APPROVED',
            }));
            state.addresses = res;
            [state.ui.address] = res;
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting addresses', { root: true });
            }
        }
    },
    async createDeposit({ state, dispatch, commit }, accountId: ReturnType<typeof createDeposit>) {
        let requestData = {} as ICreateDepositRequestData;
        if (state.ui.address!.memo) {
            requestData = {
                accountId: accountId.payload,
                address: state.ui.address!.address,
                assetSymbol: state.ui.asset,
                blockchainName: state.ui.blockchain ?? '',
                amount: state.ui.amount,
                memo: state.ui.address!.memo,
            };
        } else {
            requestData = {
                accountId: accountId.payload,
                address: state.ui.address!.address,
                assetSymbol: state.ui.asset,
                blockchainName: state.ui.blockchain ?? '',
                amount: state.ui.amount,
            };
        }
        try {
            if (!state.ui.blockchain) {
                throw new Error();
            }
            commit(SET_LOADING_ON(undefined), { root: true });
            const { data: res } = await WalletsApi.createDeposit(new CreateDepositRequestData(requestData));
            const { data: transfer } = await WalletsApi.privateGetTransfer(new TransferRequest({
                id: res.transferId,
            }));
            commit(UPDATE_TRANSFER(transfer.serialize()), { root: true });
            state.ui.transferId = res.transferId;
            await dispatch(getAddress(accountId.payload, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during performing deposit', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async getAddress({ state, dispatch }, accountId: ReturnType<typeof getAddress>) {
        try {
            if (!state.ui.blockchain) {
                throw new Error();
            }
            const { data: res } = await WalletsApi.getAddress(new WalletAddressRequestData({
                accountId: accountId.payload,
                assetSymbol: state.ui.asset,
                blockchainName: state.ui.blockchain,
            }));
            state.ui.destinationAddress = res;
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong', { root: true });
            } else {
                await dispatch('Notificator/showErrorNotification', 'Error during getting address', { root: true });
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
