import BalanceHistory, { IBalanceHistory } from './BalanceHistory';

// This file was autogenerated. Please do not change.
export interface ISummaryBalanceHistory {
    end?: IBalanceHistory;
    start?: IBalanceHistory;
}

export default class SummaryBalanceHistory {
    readonly _end: BalanceHistory | undefined;

    get end(): BalanceHistory | undefined {
        return this._end;
    }

    readonly _start: BalanceHistory | undefined;

    get start(): BalanceHistory | undefined {
        return this._start;
    }

    constructor(props: ISummaryBalanceHistory) {
        if (props.end) {
            this._end = new BalanceHistory(props.end);
        }
        if (props.start) {
            this._start = new BalanceHistory(props.start);
        }
    }

    serialize(): ISummaryBalanceHistory {
        const data: ISummaryBalanceHistory = {
        };
        if (typeof this._end !== 'undefined') {
            data.end = this._end.serialize();
        }
        if (typeof this._start !== 'undefined') {
            data.start = this._start.serialize();
        }
        return data;
    }

    toJSON(): ISummaryBalanceHistory {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            end: !this._end ? true : this._end.validate().length === 0,
            start: !this._start ? true : this._start.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
