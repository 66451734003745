import { actionCreator, mutationCreator } from 'Store/utils';
import Preset from 'Entities/userSettings/Preset';
import SettingsApi from 'Apis/Settings';
import PresetRequest from 'Entities/userSettings/PresetRequest';
import UpdatePresetRequest from 'Entities/userSettings/UpdatePresetRequest';
import SavePresetRequest from 'Entities/userSettings/SavePresetRequest';
import router from '@/router';
import ApiError from 'Entities/ApiError';

export enum PRESETS_TYPES {
    Terminal = 'TERMINAL',
    Wallets = 'WALLETS',
    Portfolio = 'PORTFOLIO',
    Workspaces = 'WORKSPACE',
}

export enum PRESET_TYPES_TO_LAYOUTS_NAMES {
    TERMINAL = 'terminal',
    WALLETS = 'wallets',
    PORTFOLIO = 'portfolios',
    WORKSPACE = 'workspace'
}

export enum PRESET_TYPES_TO_ROUTE_NAMES {
    TERMINAL = 'Terminal',
    WALLETS = 'Wallets',
    PORTFOLIO = 'Portfolio',
    WORKSPACE = 'Workspaces'
}

interface IUpdatePresetPayload {
    preset: Preset,
    name: string,
}

const state = {
    presetsList: undefined as undefined | Preset[],
    defaultPresetsList: undefined as undefined | Preset[],
    activePresets: undefined as undefined | Preset[],
};

export type PresetsState = typeof state;

export enum PresetsMutations {
    SET_PRESETS_LIST = 'SET_PRESETS_LIST',
    SET_ACTIVE_PRESETS = 'SET_ACTIVE_PRESETS',
    REMOVE_PRESET = 'REMOVE_PRESET',
    UPDATE_PRESET_NAME = 'UPDATE_PRESET_NAME',
    SET_DEFAULT_PRESETS_LIST = 'SET_DEFAULT_PRESETS_LIST',
}

export const SET_PRESETS_LIST = mutationCreator<Preset[]>('Presets', PresetsMutations.SET_PRESETS_LIST);
export const SET_ACTIVE_PRESETS = mutationCreator<Preset[]>('Presets', PresetsMutations.SET_ACTIVE_PRESETS);
export const REMOVE_PRESET = mutationCreator<Preset>('Presets', PresetsMutations.REMOVE_PRESET);
export const UPDATE_PRESET_NAME = mutationCreator<IUpdatePresetPayload>('Presets', PresetsMutations.UPDATE_PRESET_NAME);
export const SET_DEFAULT_PRESETS_LIST = mutationCreator<Preset[]>('Presets', PresetsMutations.SET_DEFAULT_PRESETS_LIST);

const mutations: Record<PresetsMutations, (state: PresetsState, ...args: any) => void> = {
    SET_PRESETS_LIST(state, { payload: presetsList }: ReturnType<typeof SET_PRESETS_LIST>) {
        state.presetsList = presetsList.sort(({ createdAt: aTime }, { createdAt: bTime }) => {
            const aTimestamp = new Date(aTime!).valueOf();
            const bTimestamp = new Date(bTime!).valueOf();
            return aTimestamp < bTimestamp ? 1 : -1;
        });
    },
    SET_ACTIVE_PRESETS(state, { payload: activePresets }: ReturnType<typeof SET_ACTIVE_PRESETS>) {
        state.activePresets = activePresets;
    },
    REMOVE_PRESET(state, { payload: preset }: ReturnType<typeof REMOVE_PRESET>) {
        const presetIndex = state.presetsList!.findIndex((p) => p.id === preset.id && p.type === preset.type);
        if (presetIndex !== -1) {
            // eslint-disable-next-line no-unused-expressions
            state.presetsList?.splice(presetIndex, 1);
        }
    },
    UPDATE_PRESET_NAME(state, { payload: data }: ReturnType<typeof UPDATE_PRESET_NAME>) {
        const { preset, name } = data;
        const presetIndex = state.presetsList?.findIndex((el) => el.id === preset.id && el.type === preset.type);
        const presetObj = state.presetsList![presetIndex!].serialize();
        presetObj!.name = name;
        state.presetsList![presetIndex!] = new Preset(presetObj);
    },
    SET_DEFAULT_PRESETS_LIST(state, { payload: defaultPresetsList }: ReturnType<typeof SET_DEFAULT_PRESETS_LIST>) {
        state.defaultPresetsList = defaultPresetsList;
    },
};

export enum PresetsActions {
    getPresetsList = 'getPresetsList',
    getActivePresets = 'getActivePresets',
    removePreset = 'removePreset',
    updatePreset = 'updatePreset',
    savePreset = 'savePreset',
    doPreset = 'doPreset',
    makeDefaultPresets = 'makeDefaultPresets',
    previewPreset = 'previewPreset',
}

export const getPresetsList = actionCreator<undefined>('Presets', PresetsActions.getPresetsList);
export const getActivePresets = actionCreator<undefined>('Presets', PresetsActions.getActivePresets);
export const removePreset = actionCreator<Preset>('Presets', PresetsActions.removePreset);
export const updatePreset = actionCreator<IUpdatePresetPayload>('Presets', PresetsActions.updatePreset);
export const savePreset = actionCreator<string>('Presets', PresetsActions.savePreset);
export const doPreset = actionCreator<{ preset: Preset, page?: string }>('Presets', PresetsActions.doPreset);
export const makeDefaultPresets = actionCreator<undefined>('Presets', PresetsActions.makeDefaultPresets);
export const previewPreset = actionCreator<Preset>('Presets', PresetsActions.previewPreset);

const actions = {
    async getPresetsList({ commit }) {
        try {
            const { data: res } = await SettingsApi.listPresets();
            commit(SET_PRESETS_LIST(res, true));
        } catch {
            commit(SET_PRESETS_LIST([], true));
        }
    },
    async getActivePresets({ commit, rootState }) {
        const { data: res } = await SettingsApi.getActivePresets();
        commit(SET_ACTIVE_PRESETS(res, true));
        res.forEach((preset) => {
            const layer = rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[preset.type]].lg;
            const { data } = preset;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data.forEach(({ x, y, w, h, i, name, placement, pair }, index) => {
                if (layer[index]) {
                    layer[index].x = x;
                    layer[index].y = y;
                    layer[index].w = w;
                    layer[index].h = h;
                } else {
                    layer.push({ x, y, w, h, i, name, placement, pair });
                }
            });
            rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[preset.type]].lg = layer;
        });
    },
    makeDefaultPresets({ commit, rootState }) {
        const terminalPresetData = rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES.TERMINAL].lg;
        const walletsPresetData = rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES.WALLETS].lg;
        const portfoliosPresetData = rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES.PORTFOLIO].lg;
        const workspacePresetData = rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES.WORKSPACE].lg;

        const defaultTerminalPreset = new Preset({
            type: PRESETS_TYPES.Terminal,
            name: 'Default Preset',
            description: 'default',
            data: terminalPresetData,
        });
        const defaultWalletsPreset = new Preset({
            type: PRESETS_TYPES.Wallets,
            name: 'Default Preset',
            description: 'default',
            data: walletsPresetData,
        });
        const defaultPortfoliosPreset = new Preset({
            type: PRESETS_TYPES.Portfolio,
            name: 'Default Preset',
            description: 'default',
            data: portfoliosPresetData,
        });
        const defaultWorkspacePreset = new Preset({
            type: PRESETS_TYPES.Workspaces,
            name: 'Default Preset',
            description: 'default',
            data: workspacePresetData,
        });
        commit(SET_DEFAULT_PRESETS_LIST([defaultTerminalPreset, defaultWalletsPreset, defaultPortfoliosPreset, defaultWorkspacePreset], true));
    },
    async removePreset({ commit }, { payload: preset }) {
        await SettingsApi.deletePreset(new PresetRequest({
            id: preset.id,
            type: preset.type,
        }));
        commit(REMOVE_PRESET(preset, true));
    },
    async updatePreset({ commit, dispatch }, { payload: presetData }: ReturnType<typeof updatePreset>) {
        const { data: preset } = await SettingsApi.getPreset(new PresetRequest({
            id: presetData.preset.id!,
            type: presetData.preset.type,
        }));
        await SettingsApi.updatePreset(new UpdatePresetRequest({
            data: preset.data!,
            id: preset.id!,
            type: preset.type,
            name: presetData.name,
        }));
        commit(UPDATE_PRESET_NAME(presetData, true));
        await dispatch(getPresetsList(undefined, true));
    },
    async savePreset({ dispatch, rootState }, { payload: name }: ReturnType<typeof savePreset>) {
        if (!PRESETS_TYPES[router.currentRoute.name!]) {
            throw new ApiError('Page error', {
                data: {
                    message: 'Presets are not available for current page',
                },
            });
        }
        await SettingsApi.savePreset(new SavePresetRequest({
            name,
            type: PRESETS_TYPES[router.currentRoute.name!],
            data: router.currentRoute.name === 'Workspaces'
            && router.currentRoute.query.isNew
            && router.currentRoute.query.isNew === 'true'
                ? rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg
                : rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg,
        }));
        await dispatch(getPresetsList(undefined, true));
    },
    async doPreset({ dispatch, rootState }, { payload: { preset, page } }: ReturnType<typeof doPreset>) {
        let currentRoute;
        if (page) {
            currentRoute = page;
        } else {
            currentRoute = router.currentRoute.name;
        }
        if (preset.description === 'default') {
            await SettingsApi.switchPreset(new PresetRequest({
                id: -1,
                type: preset.type,
            }));

            const layer = preset.type !== PRESETS_TYPES.Workspaces ? rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[currentRoute]]].lg : [];
            const { data } = preset;
            if (preset.type !== PRESETS_TYPES.Workspaces) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data.forEach(({ x, y, w, h }, index) => {
                    layer[index].x = x;
                    layer[index].y = y;
                    layer[index].w = w;
                    layer[index].h = h;
                });
            }
            rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[currentRoute]]].lg = layer;
            if (preset.type === PRESETS_TYPES.Workspaces) {
                rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[currentRoute]]].lg = [];
            }
            await dispatch(getPresetsList(undefined, true));
            await dispatch(getActivePresets(undefined, true));
            return;
        }
        const { data: res } = await SettingsApi.switchPreset(new PresetRequest({
            id: preset.id!,
            type: preset.type,
        }));
        if (res.data) {
            const layer = preset.type !== PRESETS_TYPES.Workspaces ? rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[currentRoute]]].lg : [];
            const { data } = res;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data.forEach(({ x, y, w, h, i, name, placement, pair }, index) => {
                if (preset.type !== PRESETS_TYPES.Workspaces) {
                    layer[index].x = x;
                    layer[index].y = y;
                    layer[index].w = w;
                    layer[index].h = h;
                } else {
                    layer.push({ x, y, w, h, i, name, placement, pair });
                }
            });
            rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[currentRoute]]].lg = layer;
            if (preset.type === PRESETS_TYPES.Workspaces) {
                rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[currentRoute]]].lg = layer;
            }
            await dispatch(getPresetsList(undefined, true));
            await dispatch(getActivePresets(undefined, true));
        }
    },
    async previewPreset({ rootState }, { payload: preset }: ReturnType<typeof previewPreset>) {
        if (preset.description === 'default') {
            const layer = preset.type !== PRESETS_TYPES.Workspaces ? rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg : [];
            const { data } = preset;
            if (preset.type !== PRESETS_TYPES.Workspaces) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                data.forEach(({ x, y, w, h }, index) => {
                    layer[index].x = x;
                    layer[index].y = y;
                    layer[index].w = w;
                    layer[index].h = h;
                });
            }
            rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg = layer;
            if (preset.type === PRESETS_TYPES.Workspaces) {
                rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg = [];
            }
            return;
        }
        const { data: res } = await SettingsApi.getPreset(new PresetRequest({
            id: preset.id!,
            type: preset.type,
        }));
        if (res.data) {
            const layer = preset.type !== PRESETS_TYPES.Workspaces ? rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg : [];
            const { data } = res;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            data.forEach(({ x, y, w, h, i, name, placement, pair }, index) => {
                if (preset.type !== PRESETS_TYPES.Workspaces) {
                    layer[index].x = x;
                    layer[index].y = y;
                    layer[index].w = w;
                    layer[index].h = h;
                } else {
                    layer.push({ x, y, w, h, i, name, placement, pair });
                }
            });
            rootState.Interface.pageGrids[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg = layer;
            if (preset.type === PRESETS_TYPES.Workspaces) {
                rootState.Interface.defaultPresetsPagesGrid[PRESET_TYPES_TO_LAYOUTS_NAMES[PRESETS_TYPES[router.currentRoute.name!]]].lg = layer;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters: {},
    mutations,
    actions,
};
