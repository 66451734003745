import ApiError from 'Entities/ApiError';
import { ITradePoints } from 'Entities/portfolioBalanceHistory/TradePoints';
import ChartPayload from 'Entities/portfolioBalanceHistory/ChartPayload';
import PNLPayload from 'Entities/portfolioBalanceHistory/PNLPayload';
import PNLPoints from 'Entities/portfolioBalanceHistory/PNLPoints';
import ServiceStatus from 'Entities/portfolioBalanceHistory/ServiceStatus';
import TradePayload from 'Entities/portfolioBalanceHistory/TradePayload';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class PortfolioApi {
    static async getChartPoints(chartpayload: ChartPayload, withHeaders?: boolean): Promise<{ data: Record<string, object> | number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...chartpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getChartPoints', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/history/portfolio/getChartPoints`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(chartpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Portfolio/getChartPoints', { response: res, data: result, fields: [JSON.stringify({ chartpayload })] });
    }

    static async getCurrentPNL(pnlpayload: PNLPayload, withHeaders?: boolean): Promise<{ data: PNLPoints; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...pnlpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getCurrentPNL', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/history/portfolio/getCurrentPNL`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(pnlpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new PNLPoints(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Portfolio/getCurrentPNL', { response: res, data: result, fields: [JSON.stringify({ pnlpayload })] });
    }

    static async getPNLHistory(pnlpayload: PNLPayload, withHeaders?: boolean): Promise<{ data: PNLPoints; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...pnlpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getPNLHistory', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/history/portfolio/getPNLHistory`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(pnlpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new PNLPoints(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Portfolio/getPNLHistory', { response: res, data: result, fields: [JSON.stringify({ pnlpayload })] });
    }

    static async getTrades(tradepayload: TradePayload, withHeaders?: boolean): Promise<{ data: Record<string, ITradePoints>; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...tradepayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/history/portfolio/getTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(tradepayload.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Portfolio/getTrades', { response: res, data: result, fields: [JSON.stringify({ tradepayload })] });
    }
}
