// This file was autogenerated. Please do not change.
export interface IInternalUserResponse {
    availableQuota?: number;
    firstName: string;
    id: string;
    kycStatus: ('None' | 'Pending' | 'Verified' | 'Rejected');
    lastName: string;
    onboardingCompleted?: boolean;
    onboardingStatus?: number;
    possibleNames?: string[];
    status?: ('Active' | 'Suspended');
    type?: string;
}

export default class InternalUserResponse {
    readonly _availableQuota: number | undefined;

    /** Description: Available request quota */
    get availableQuota(): number | undefined {
        return this._availableQuota;
    }

    readonly _firstName: string;

    /** Description: First name/Company Name */
    get firstName(): string {
        return this._firstName;
    }

    readonly _id: string;

    /** Description: User unique id */
    get id(): string {
        return this._id;
    }

    readonly _kycStatus: ('None' | 'Pending' | 'Verified' | 'Rejected');

    /** Description: KYC Verification status */
    get kycStatus(): ('None' | 'Pending' | 'Verified' | 'Rejected') {
        return this._kycStatus;
    }

    readonly _lastName: string;

    /** Description: Last name/Company Number */
    get lastName(): string {
        return this._lastName;
    }

    readonly _onboardingCompleted: boolean | undefined;

    /** Description: Onboarding completed */
    get onboardingCompleted(): boolean | undefined {
        return this._onboardingCompleted;
    }

    readonly _onboardingStatus: number | undefined;

    /** Description: Onboarding status */
    get onboardingStatus(): number | undefined {
        return this._onboardingStatus;
    }

    readonly _possibleNames: string[] | undefined;

    /** Description: Possible names */
    get possibleNames(): string[] | undefined {
        return this._possibleNames;
    }

    readonly _status: ('Active' | 'Suspended') | undefined;

    /** Description: Activation status */
    get status(): ('Active' | 'Suspended') | undefined {
        return this._status;
    }

    readonly _type: string | undefined;

    /** Description: User type */
    get type(): string | undefined {
        return this._type;
    }

    constructor(props: IInternalUserResponse) {
        if (typeof props.availableQuota === 'number') {
            this._availableQuota = props.availableQuota;
        }
        this._firstName = props.firstName.trim();
        this._id = props.id.trim();
        this._kycStatus = props.kycStatus;
        this._lastName = props.lastName.trim();
        if (typeof props.onboardingCompleted === 'boolean') {
            this._onboardingCompleted = props.onboardingCompleted;
        }
        if (typeof props.onboardingStatus === 'number') {
            this._onboardingStatus = props.onboardingStatus;
        }
        if (props.possibleNames) {
            this._possibleNames = props.possibleNames;
        }
        if (props.status) {
            this._status = props.status;
        }
        if (typeof props.type === 'string') {
            this._type = props.type.trim();
        }
    }

    serialize(): IInternalUserResponse {
        const data: IInternalUserResponse = {
            firstName: this._firstName,
            id: this._id,
            kycStatus: this._kycStatus,
            lastName: this._lastName,
        };
        if (typeof this._availableQuota !== 'undefined') {
            data.availableQuota = this._availableQuota;
        }
        if (typeof this._onboardingCompleted !== 'undefined') {
            data.onboardingCompleted = this._onboardingCompleted;
        }
        if (typeof this._onboardingStatus !== 'undefined') {
            data.onboardingStatus = this._onboardingStatus;
        }
        if (typeof this._possibleNames !== 'undefined') {
            data.possibleNames = this._possibleNames;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IInternalUserResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            type: !this._type ? true : typeof this._type === 'string',
            availableQuota: !this._availableQuota ? true : typeof this._availableQuota === 'number',
            firstName: typeof this._firstName === 'string' && !!this._firstName.trim(),
            lastName: typeof this._lastName === 'string' && !!this._lastName.trim(),
            onboardingStatus: !this._onboardingStatus ? true : typeof this._onboardingStatus === 'number',
            onboardingCompleted: !this._onboardingCompleted ? true : typeof this._onboardingCompleted === 'boolean',
            possibleNames: !this._possibleNames ? true : this._possibleNames.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            id: typeof this._id === 'string' && !!this._id.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
