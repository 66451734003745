// This file was autogenerated. Please do not change.
export interface IDexTransaction {
    aAssetSymbol: string;
    aQuantity: string;
    accountId: string;
    bAssetSymbol?: string;
    bQuantity?: string;
    blockchainCommissionAssetSymbol?: string;
    blockchainCommissionQuantity?: string;
    blockchainName: string;
    createdAt: string;
    id?: string;
    message?: string;
    placementCommissionQuantity?: string;
    placementCommissionRate?: string;
    placementName: string;
    status: ('processing' | 'confirmed' | 'failed');
    txHash?: string;
    type: ('APPROVE' | 'SWAP' | 'LIQUIDITY_IN' | 'LIQUIDITY_OUT' | 'APPROVE_SWAP' | 'APPROVE_LT' | 'WRAP' | 'UNWRAP');
    updatedAt: string;
}

export default class DexTransaction {
    readonly _aAssetSymbol: string;

    /**
     * Example: ETH
     */
    get aAssetSymbol(): string {
        return this._aAssetSymbol;
    }

    readonly _aQuantity: number;

    /**
     * Example: 0.123
     */
    get aQuantity(): number {
        return this._aQuantity;
    }

    readonly _accountId: string;

    /**
     * Example: ACXXU119CYU
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _bAssetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get bAssetSymbol(): string | undefined {
        return this._bAssetSymbol;
    }

    readonly _bQuantity: number | undefined;

    /**
     * Example: 0.123
     */
    get bQuantity(): number | undefined {
        return this._bQuantity;
    }

    readonly _blockchainCommissionAssetSymbol: string | undefined;

    /**
     * Example: ETH
     */
    get blockchainCommissionAssetSymbol(): string | undefined {
        return this._blockchainCommissionAssetSymbol;
    }

    readonly _blockchainCommissionQuantity: number | undefined;

    /**
     * Example: 0.123
     */
    get blockchainCommissionQuantity(): number | undefined {
        return this._blockchainCommissionQuantity;
    }

    readonly _blockchainName: string;

    /**
     * Example: ETH
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _id: string | undefined;

    /**
     * Example: DTGR63LG9NZL9PW529CZEV
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _message: string | undefined;

    get message(): string | undefined {
        return this._message;
    }

    readonly _placementCommissionQuantity: number | undefined;

    /**
     * Example: 0.123
     */
    get placementCommissionQuantity(): number | undefined {
        return this._placementCommissionQuantity;
    }

    readonly _placementCommissionRate: number | undefined;

    /**
     * Example: 0.123
     */
    get placementCommissionRate(): number | undefined {
        return this._placementCommissionRate;
    }

    readonly _placementName: string;

    /**
     * Example: UniswapV2
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _status: ('processing' | 'confirmed' | 'failed');

    /**
     * Example: confirmed
     */
    get status(): ('processing' | 'confirmed' | 'failed') {
        return this._status;
    }

    readonly _txHash: string | undefined;

    /**
     * Example: 9.090945430343985e+76
     */
    get txHash(): string | undefined {
        return this._txHash;
    }

    readonly _type: ('APPROVE' | 'SWAP' | 'LIQUIDITY_IN' | 'LIQUIDITY_OUT' | 'APPROVE_SWAP' | 'APPROVE_LT' | 'WRAP' | 'UNWRAP');

    /**
     * Example: SWAP
     */
    get type(): ('APPROVE' | 'SWAP' | 'LIQUIDITY_IN' | 'LIQUIDITY_OUT' | 'APPROVE_SWAP' | 'APPROVE_LT' | 'WRAP' | 'UNWRAP') {
        return this._type;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IDexTransaction) {
        this._aAssetSymbol = props.aAssetSymbol.trim();
        this._aQuantity = Number(props.aQuantity);
        this._accountId = props.accountId.trim();
        if (typeof props.bAssetSymbol === 'string') {
            this._bAssetSymbol = props.bAssetSymbol.trim();
        }
        if (typeof props.bQuantity === 'string') {
            this._bQuantity = Number(props.bQuantity);
        }
        if (typeof props.blockchainCommissionAssetSymbol === 'string') {
            this._blockchainCommissionAssetSymbol = props.blockchainCommissionAssetSymbol.trim();
        }
        if (typeof props.blockchainCommissionQuantity === 'string') {
            this._blockchainCommissionQuantity = Number(props.blockchainCommissionQuantity);
        }
        this._blockchainName = props.blockchainName.trim();
        this._createdAt = props.createdAt.trim();
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.message === 'string') {
            this._message = props.message.trim();
        }
        if (typeof props.placementCommissionQuantity === 'string') {
            this._placementCommissionQuantity = Number(props.placementCommissionQuantity);
        }
        if (typeof props.placementCommissionRate === 'string') {
            this._placementCommissionRate = Number(props.placementCommissionRate);
        }
        this._placementName = props.placementName.trim();
        this._status = props.status;
        if (typeof props.txHash === 'string') {
            this._txHash = props.txHash.trim();
        }
        this._type = props.type;
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IDexTransaction {
        const data: IDexTransaction = {
            aAssetSymbol: this._aAssetSymbol,
            aQuantity: String(this._aQuantity),
            accountId: this._accountId,
            blockchainName: this._blockchainName,
            createdAt: this._createdAt,
            placementName: this._placementName,
            status: this._status,
            type: this._type,
            updatedAt: this._updatedAt,
        };
        if (typeof this._bAssetSymbol !== 'undefined') {
            data.bAssetSymbol = this._bAssetSymbol;
        }
        if (typeof this._bQuantity !== 'undefined') {
            data.bQuantity = String(this._bQuantity);
        }
        if (typeof this._blockchainCommissionAssetSymbol !== 'undefined') {
            data.blockchainCommissionAssetSymbol = this._blockchainCommissionAssetSymbol;
        }
        if (typeof this._blockchainCommissionQuantity !== 'undefined') {
            data.blockchainCommissionQuantity = String(this._blockchainCommissionQuantity);
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._message !== 'undefined') {
            data.message = this._message;
        }
        if (typeof this._placementCommissionQuantity !== 'undefined') {
            data.placementCommissionQuantity = String(this._placementCommissionQuantity);
        }
        if (typeof this._placementCommissionRate !== 'undefined') {
            data.placementCommissionRate = String(this._placementCommissionRate);
        }
        if (typeof this._txHash !== 'undefined') {
            data.txHash = this._txHash;
        }
        return data;
    }

    toJSON(): IDexTransaction {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetSymbol: typeof this._aAssetSymbol === 'string' && !!this._aAssetSymbol.trim(),
            aQuantity: true,
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            bAssetSymbol: !this._bAssetSymbol ? true : typeof this._bAssetSymbol === 'string',
            bQuantity: true,
            blockchainCommissionAssetSymbol: !this._blockchainCommissionAssetSymbol ? true : typeof this._blockchainCommissionAssetSymbol === 'string',
            blockchainCommissionQuantity: true,
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            id: !this._id ? true : typeof this._id === 'string',
            message: !this._message ? true : typeof this._message === 'string',
            placementCommissionQuantity: true,
            placementCommissionRate: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            txHash: !this._txHash ? true : typeof this._txHash === 'string',
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
