// This file was autogenerated. Please do not change.
export interface IGroupRequest {
    createdAt: string;
    groupName: string;
    groupUid: string;
    id: string;
    issuer: string;
    issuerEmail?: string;
    issuerFirstName?: string;
    issuerLastName?: string;
    recipient: string;
    recipientEmail?: string;
    recipientFirstName?: string;
    recipientLastName?: string;
    role: ('creator' | 'admin' | 'alias');
    status: ('processing' | 'confirmed' | 'cancelled' | 'rejected');
    updatedAt: string;
}

export default class GroupRequest {
    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _groupName: string;

    /**
     * Example: Trading group
     */
    get groupName(): string {
        return this._groupName;
    }

    readonly _groupUid: string;

    /**
     * Example: GD3TGKFC3
     */
    get groupUid(): string {
        return this._groupUid;
    }

    readonly _id: string;

    /**
     * Example: GR6V2M56DM
     */
    get id(): string {
        return this._id;
    }

    readonly _issuer: string;

    /**
     * Example: UL6HEQRK3
     */
    get issuer(): string {
        return this._issuer;
    }

    readonly _issuerEmail: string | undefined;

    /**
     * Example: foo@bar.com
     */
    get issuerEmail(): string | undefined {
        return this._issuerEmail;
    }

    readonly _issuerFirstName: string | undefined;

    /**
     * Example: Foo
     */
    get issuerFirstName(): string | undefined {
        return this._issuerFirstName;
    }

    readonly _issuerLastName: string | undefined;

    /**
     * Example: Bar
     */
    get issuerLastName(): string | undefined {
        return this._issuerLastName;
    }

    readonly _recipient: string;

    /**
     * Example: U4X4WUFD2
     */
    get recipient(): string {
        return this._recipient;
    }

    readonly _recipientEmail: string | undefined;

    /**
     * Example: foo@bar.com
     */
    get recipientEmail(): string | undefined {
        return this._recipientEmail;
    }

    readonly _recipientFirstName: string | undefined;

    /**
     * Example: Foo
     */
    get recipientFirstName(): string | undefined {
        return this._recipientFirstName;
    }

    readonly _recipientLastName: string | undefined;

    /**
     * Example: Bar
     */
    get recipientLastName(): string | undefined {
        return this._recipientLastName;
    }

    readonly _role: ('creator' | 'admin' | 'alias');

    /**
     * Example: member
     */
    get role(): ('creator' | 'admin' | 'alias') {
        return this._role;
    }

    readonly _status: ('processing' | 'confirmed' | 'cancelled' | 'rejected');

    /**
     * Example: processing
     */
    get status(): ('processing' | 'confirmed' | 'cancelled' | 'rejected') {
        return this._status;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IGroupRequest) {
        this._createdAt = props.createdAt.trim();
        this._groupName = props.groupName.trim();
        this._groupUid = props.groupUid.trim();
        this._id = props.id.trim();
        this._issuer = props.issuer.trim();
        if (typeof props.issuerEmail === 'string') {
            this._issuerEmail = props.issuerEmail.trim();
        }
        if (typeof props.issuerFirstName === 'string') {
            this._issuerFirstName = props.issuerFirstName.trim();
        }
        if (typeof props.issuerLastName === 'string') {
            this._issuerLastName = props.issuerLastName.trim();
        }
        this._recipient = props.recipient.trim();
        if (typeof props.recipientEmail === 'string') {
            this._recipientEmail = props.recipientEmail.trim();
        }
        if (typeof props.recipientFirstName === 'string') {
            this._recipientFirstName = props.recipientFirstName.trim();
        }
        if (typeof props.recipientLastName === 'string') {
            this._recipientLastName = props.recipientLastName.trim();
        }
        this._role = props.role;
        this._status = props.status;
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IGroupRequest {
        const data: IGroupRequest = {
            createdAt: this._createdAt,
            groupName: this._groupName,
            groupUid: this._groupUid,
            id: this._id,
            issuer: this._issuer,
            recipient: this._recipient,
            role: this._role,
            status: this._status,
            updatedAt: this._updatedAt,
        };
        if (typeof this._issuerEmail !== 'undefined') {
            data.issuerEmail = this._issuerEmail;
        }
        if (typeof this._issuerFirstName !== 'undefined') {
            data.issuerFirstName = this._issuerFirstName;
        }
        if (typeof this._issuerLastName !== 'undefined') {
            data.issuerLastName = this._issuerLastName;
        }
        if (typeof this._recipientEmail !== 'undefined') {
            data.recipientEmail = this._recipientEmail;
        }
        if (typeof this._recipientFirstName !== 'undefined') {
            data.recipientFirstName = this._recipientFirstName;
        }
        if (typeof this._recipientLastName !== 'undefined') {
            data.recipientLastName = this._recipientLastName;
        }
        return data;
    }

    toJSON(): IGroupRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            groupName: typeof this._groupName === 'string' && !!this._groupName.trim(),
            groupUid: typeof this._groupUid === 'string' && !!this._groupUid.trim(),
            id: typeof this._id === 'string' && !!this._id.trim(),
            issuer: typeof this._issuer === 'string' && !!this._issuer.trim(),
            issuerEmail: !this._issuerEmail ? true : typeof this._issuerEmail === 'string',
            issuerFirstName: !this._issuerFirstName ? true : typeof this._issuerFirstName === 'string',
            issuerLastName: !this._issuerLastName ? true : typeof this._issuerLastName === 'string',
            recipient: typeof this._recipient === 'string' && !!this._recipient.trim(),
            recipientEmail: !this._recipientEmail ? true : typeof this._recipientEmail === 'string',
            recipientFirstName: !this._recipientFirstName ? true : typeof this._recipientFirstName === 'string',
            recipientLastName: !this._recipientLastName ? true : typeof this._recipientLastName === 'string',
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
