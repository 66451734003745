import TransferFee, { ITransferFee } from './TransferFee';

// This file was autogenerated. Please do not change.
export interface ITransfer {
    address?: string;
    assetSymbol: string;
    bankRequisiteAlias?: string;
    blockchainName?: string;
    blockchainTxId?: string;
    clientId?: string;
    commission: ITransferFee[];
    createdAt: string;
    failureCode?: number;
    failureReason?: string;
    fromAccountId?: string;
    fromPlacementName?: string;
    id: string;
    initiatorType: string;
    initiatorUserId?: string;
    memo?: string;
    quantity: string;
    riskLabels?: string[];
    status?: ('created' | 'pending' | 'processing' | 'confirmed' | 'failed' | 'recovering' | 'recovered' | 'recovery_failed' | 'manual_recovering' | 'not_completed' | 'cancelled');
    toAccountId?: string;
    toPlacementName?: string;
    type: string;
}

export default class Transfer {
    readonly _address: string | undefined;

    get address(): string | undefined {
        return this._address;
    }

    readonly _assetSymbol: string;

    /**
     * Example: XLM
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _bankRequisiteAlias: string | undefined;

    /**
     * Example: MyBank
     */
    get bankRequisiteAlias(): string | undefined {
        return this._bankRequisiteAlias;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: BSC
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _blockchainTxId: string | undefined;

    get blockchainTxId(): string | undefined {
        return this._blockchainTxId;
    }

    readonly _clientId: string | undefined;

    get clientId(): string | undefined {
        return this._clientId;
    }

    readonly _commission: TransferFee[];

    get commission(): TransferFee[] {
        return this._commission;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-23T14:33:40.600512Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _failureCode: number | undefined;

    get failureCode(): number | undefined {
        return this._failureCode;
    }

    readonly _failureReason: string | undefined;

    get failureReason(): string | undefined {
        return this._failureReason;
    }

    readonly _fromAccountId: string | undefined;

    /**
     * Example: AMU23UZRSLH
     */
    get fromAccountId(): string | undefined {
        return this._fromAccountId;
    }

    readonly _fromPlacementName: string | undefined;

    /**
     * Example: Binance
     */
    get fromPlacementName(): string | undefined {
        return this._fromPlacementName;
    }

    readonly _id: string;

    /**
     * Example: TQ1CAJW8FH48WLSU7HGCL
     */
    get id(): string {
        return this._id;
    }

    readonly _initiatorType: string;

    /**
     * Example: users
     */
    get initiatorType(): string {
        return this._initiatorType;
    }

    readonly _initiatorUserId: string | undefined;

    /**
     * Example: U3CVSWZ3K
     */
    get initiatorUserId(): string | undefined {
        return this._initiatorUserId;
    }

    readonly _memo: string | undefined;

    get memo(): string | undefined {
        return this._memo;
    }

    readonly _quantity: number;

    /**
     * Example: 63.3584
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _riskLabels: string[] | undefined;

    get riskLabels(): string[] | undefined {
        return this._riskLabels;
    }

    readonly _status: ('created' | 'pending' | 'processing' | 'confirmed' | 'failed' | 'recovering' | 'recovered' | 'recovery_failed' | 'manual_recovering' | 'not_completed' | 'cancelled') | undefined;

    /**
     * Example: confirmed
     */
    get status(): ('created' | 'pending' | 'processing' | 'confirmed' | 'failed' | 'recovering' | 'recovered' | 'recovery_failed' | 'manual_recovering' | 'not_completed' | 'cancelled') | undefined {
        return this._status;
    }

    readonly _toAccountId: string | undefined;

    /**
     * Example: AMU23UZRSLH
     */
    get toAccountId(): string | undefined {
        return this._toAccountId;
    }

    readonly _toPlacementName: string | undefined;

    /**
     * Example: Huobi
     */
    get toPlacementName(): string | undefined {
        return this._toPlacementName;
    }

    readonly _type: string;

    /**
     * Example: transfer
     */
    get type(): string {
        return this._type;
    }

    constructor(props: ITransfer) {
        if (typeof props.address === 'string') {
            this._address = props.address.trim();
        }
        this._assetSymbol = props.assetSymbol.trim();
        if (typeof props.bankRequisiteAlias === 'string') {
            this._bankRequisiteAlias = props.bankRequisiteAlias.trim();
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.blockchainTxId === 'string') {
            this._blockchainTxId = props.blockchainTxId.trim();
        }
        if (typeof props.clientId === 'string') {
            this._clientId = props.clientId.trim();
        }
        this._commission = props.commission.map((p) => new TransferFee(p));
        this._createdAt = props.createdAt.trim();
        if (typeof props.failureCode === 'number') {
            this._failureCode = props.failureCode;
        }
        if (typeof props.failureReason === 'string') {
            this._failureReason = props.failureReason.trim();
        }
        if (typeof props.fromAccountId === 'string') {
            this._fromAccountId = props.fromAccountId.trim();
        }
        if (typeof props.fromPlacementName === 'string') {
            this._fromPlacementName = props.fromPlacementName.trim();
        }
        this._id = props.id.trim();
        this._initiatorType = props.initiatorType.trim();
        if (typeof props.initiatorUserId === 'string') {
            this._initiatorUserId = props.initiatorUserId.trim();
        }
        if (typeof props.memo === 'string') {
            this._memo = props.memo.trim();
        }
        this._quantity = Number(props.quantity);
        if (props.riskLabels) {
            this._riskLabels = props.riskLabels;
        }
        if (props.status) {
            this._status = props.status;
        }
        if (typeof props.toAccountId === 'string') {
            this._toAccountId = props.toAccountId.trim();
        }
        if (typeof props.toPlacementName === 'string') {
            this._toPlacementName = props.toPlacementName.trim();
        }
        this._type = props.type.trim();
    }

    serialize(): ITransfer {
        const data: ITransfer = {
            assetSymbol: this._assetSymbol,
            commission: this._commission.map((p) => p.serialize()),
            createdAt: this._createdAt,
            id: this._id,
            initiatorType: this._initiatorType,
            quantity: String(this._quantity),
            type: this._type,
        };
        if (typeof this._address !== 'undefined') {
            data.address = this._address;
        }
        if (typeof this._bankRequisiteAlias !== 'undefined') {
            data.bankRequisiteAlias = this._bankRequisiteAlias;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._blockchainTxId !== 'undefined') {
            data.blockchainTxId = this._blockchainTxId;
        }
        if (typeof this._clientId !== 'undefined') {
            data.clientId = this._clientId;
        }
        if (typeof this._failureCode !== 'undefined') {
            data.failureCode = this._failureCode;
        }
        if (typeof this._failureReason !== 'undefined') {
            data.failureReason = this._failureReason;
        }
        if (typeof this._fromAccountId !== 'undefined') {
            data.fromAccountId = this._fromAccountId;
        }
        if (typeof this._fromPlacementName !== 'undefined') {
            data.fromPlacementName = this._fromPlacementName;
        }
        if (typeof this._initiatorUserId !== 'undefined') {
            data.initiatorUserId = this._initiatorUserId;
        }
        if (typeof this._memo !== 'undefined') {
            data.memo = this._memo;
        }
        if (typeof this._riskLabels !== 'undefined') {
            data.riskLabels = this._riskLabels;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._toAccountId !== 'undefined') {
            data.toAccountId = this._toAccountId;
        }
        if (typeof this._toPlacementName !== 'undefined') {
            data.toPlacementName = this._toPlacementName;
        }
        return data;
    }

    toJSON(): ITransfer {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            address: !this._address ? true : typeof this._address === 'string',
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            bankRequisiteAlias: !this._bankRequisiteAlias ? true : typeof this._bankRequisiteAlias === 'string',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            blockchainTxId: !this._blockchainTxId ? true : typeof this._blockchainTxId === 'string',
            clientId: !this._clientId ? true : typeof this._clientId === 'string',
            commission: this._commission.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            failureCode: !this._failureCode ? true : typeof this._failureCode === 'number',
            failureReason: !this._failureReason ? true : typeof this._failureReason === 'string',
            fromAccountId: !this._fromAccountId ? true : typeof this._fromAccountId === 'string',
            fromPlacementName: !this._fromPlacementName ? true : typeof this._fromPlacementName === 'string',
            id: typeof this._id === 'string' && !!this._id.trim(),
            initiatorType: typeof this._initiatorType === 'string' && !!this._initiatorType.trim(),
            initiatorUserId: !this._initiatorUserId ? true : typeof this._initiatorUserId === 'string',
            memo: !this._memo ? true : typeof this._memo === 'string',
            quantity: true,
            riskLabels: !this._riskLabels ? true : this._riskLabels.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            toAccountId: !this._toAccountId ? true : typeof this._toAccountId === 'string',
            toPlacementName: !this._toPlacementName ? true : typeof this._toPlacementName === 'string',
            type: typeof this._type === 'string' && !!this._type.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
