import store from '@/store';
import { hasManifest, getManifest } from 'Models/assetsManifest';

export const assetsTypes = {
    FIAT: 'fiat',
    CRYPTO_SPOT: 'crypto-spot',
    STABLE_COIN: 'crypto-stable-coin',
};

export const defaultQuotationsAsset = 'USD';
export const LS_QUOTATION_ASSET_SYMBOL_KEY = 'quotationAssetSymbol';

export function setLocalStorageQuotationAsset(assetSymbol) {
    return localStorage.setItem(LS_QUOTATION_ASSET_SYMBOL_KEY, assetSymbol);
}
export function getLocalStorageQuotationAsset() {
    return localStorage.getItem(LS_QUOTATION_ASSET_SYMBOL_KEY) || defaultQuotationsAsset;
}

export class Asset {
    id

    symbol

    type

    description

    multiplier

    precision

    memoRequired

    transferable

    blockchainAddressExplorerUrl

    blockchainTxExplorerUrl

    quotations

    manifest

    constructor(asset) {
        this.id = asset.id;
        this.symbol = asset.symbol;
        this.type = asset.type;
        this.description = asset.description;

        this.multiplier = asset.multiplier;
        this.precision = Math.min(Math.ceil(Math.log10(asset.multiplier)), 8);

        this.memoRequired = asset.memoRequired;
        this.transferable = asset.transferable;

        this.blockchainAddressExplorerUrl = asset.blockchainAddressExplorerUrl;
        this.blockchainTxExplorerUrl = asset.blockchainTxExplorerUrl;

        this.quotations = {
            USD: 0,
            BTC: 0,
            ETH: 0,
            EUR: 0,
        };

        this.manifest = hasManifest(asset.symbol) ? getManifest(asset.symbol) : null;
    }

    get quotation() {
        return this.quotations[store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL']];
    }

    setQuotation(quotationAssetSymbol, quotation) {
        this.quotations[quotationAssetSymbol] = quotation;
    }
}
