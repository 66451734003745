// This file was autogenerated. Please do not change.
export interface IWalletAddressResponseData {
    address: string;
    memo?: string;
}

export default class WalletAddressResponseData {
    readonly _address: string;

    /** Description: Deposit address */
    get address(): string {
        return this._address;
    }

    readonly _memo: string | undefined;

    /** Description: Deposit address tag (MEMO) */
    get memo(): string | undefined {
        return this._memo;
    }

    constructor(props: IWalletAddressResponseData) {
        this._address = props.address.trim();
        if (typeof props.memo === 'string') {
            this._memo = props.memo.trim();
        }
    }

    serialize(): IWalletAddressResponseData {
        const data: IWalletAddressResponseData = {
            address: this._address,
        };
        if (typeof this._memo !== 'undefined') {
            data.memo = this._memo;
        }
        return data;
    }

    toJSON(): IWalletAddressResponseData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            address: typeof this._address === 'string' && !!this._address.trim(),
            memo: !this._memo ? true : typeof this._memo === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
