const copyTextWithPermissions = async (value: string, dispatchFunc, valueName = 'Value') => {
    const clipboardWrite = 'clipboard-write';

    const copy = (canCopy: boolean) => {
        try {
            if (canCopy) {
                // eslint-disable-next-line no-unused-expressions
                navigator.clipboard?.writeText(value);
                dispatchFunc('Notificator/showSuccessNotification', `${valueName} was successfully copied to your clipboard`);
            } else {
                dispatchFunc('Notificator/showErrorNotification', 'Your browser does not have permissions to copy text');
            }
        } catch {
            dispatchFunc('Notificator/showErrorNotification', 'Error during coping text');
        }
    };
    try {
        // Mozila does not support this type of permissions and will throw error;
        const result = await navigator.permissions.query({ name: clipboardWrite as PermissionName });
        copy(!(result.state === 'denied'));
    } catch {
        // We try to copy without permission then
        copy(true);
    }
};

export { copyTextWithPermissions };
