// This file was autogenerated. Please do not change.
export interface IBalanceHistory {
    date?: string;
    quantity?: string;
}

export default class BalanceHistory {
    readonly _date: string | undefined;

    /**
     * Example: 2021-04-28T00:00:00Z
     */
    get date(): string | undefined {
        return this._date;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 156.7736993
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    constructor(props: IBalanceHistory) {
        if (typeof props.date === 'string') {
            this._date = props.date.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
    }

    serialize(): IBalanceHistory {
        const data: IBalanceHistory = {
        };
        if (typeof this._date !== 'undefined') {
            data.date = this._date;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        return data;
    }

    toJSON(): IBalanceHistory {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            date: !this._date ? true : typeof this._date === 'string',
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
