// This file was autogenerated. Please do not change.
export interface IRepresentativeResponse {
    fullName: string;
}

export default class RepresentativeResponse {
    readonly _fullName: string;

    /** Description: Full name of representative */
    get fullName(): string {
        return this._fullName;
    }

    constructor(props: IRepresentativeResponse) {
        this._fullName = props.fullName.trim();
    }

    serialize(): IRepresentativeResponse {
        const data: IRepresentativeResponse = {
            fullName: this._fullName,
        };
        return data;
    }

    toJSON(): IRepresentativeResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            fullName: typeof this._fullName === 'string' && !!this._fullName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
