import TransferEntry, { ITransferEntry } from './TransferEntry';

// This file was autogenerated. Please do not change.
export interface ITransferFeeRequestData {
    amount: number;
    assetSymbol: string;
    blockchainName?: string;
    destination: ITransferEntry;
    fee?: number;
    feeSize?: ('low' | 'medium' | 'high');
    source: ITransferEntry;
}

export default class TransferFeeRequestData {
    readonly _amount: number;

    /** Description: Asset amount */
    get amount(): number {
        return this._amount;
    }

    readonly _assetSymbol: string;

    /** Description: Asset symbol */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _blockchainName: string | undefined;

    /** Description: Blockchain name */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _destination: TransferEntry;

    get destination(): TransferEntry {
        return this._destination;
    }

    readonly _fee: number | undefined;

    /** Description: Fee value */
    get fee(): number | undefined {
        return this._fee;
    }

    readonly _feeSize: ('low' | 'medium' | 'high') | undefined;

    /** Description: Fee size */
    get feeSize(): ('low' | 'medium' | 'high') | undefined {
        return this._feeSize;
    }

    readonly _source: TransferEntry;

    get source(): TransferEntry {
        return this._source;
    }

    constructor(props: ITransferFeeRequestData) {
        this._amount = props.amount;
        this._assetSymbol = props.assetSymbol.trim();
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        this._destination = new TransferEntry(props.destination);
        if (typeof props.fee === 'number') {
            this._fee = props.fee;
        }
        if (props.feeSize) {
            this._feeSize = props.feeSize;
        }
        this._source = new TransferEntry(props.source);
    }

    serialize(): ITransferFeeRequestData {
        const data: ITransferFeeRequestData = {
            amount: this._amount,
            assetSymbol: this._assetSymbol,
            destination: this._destination.serialize(),
            source: this._source.serialize(),
        };
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._fee !== 'undefined') {
            data.fee = this._fee;
        }
        if (typeof this._feeSize !== 'undefined') {
            data.feeSize = this._feeSize;
        }
        return data;
    }

    toJSON(): ITransferFeeRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            source: this._source.validate().length === 0,
            destination: this._destination.validate().length === 0,
            fee: !this._fee ? true : typeof this._fee === 'number',
            amount: typeof this._amount === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
