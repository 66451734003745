import AccountInfo, { IAccountInfo } from './AccountInfo';
import AccountRequestInfo, { IAccountRequestInfo } from './AccountRequestInfo';

// This file was autogenerated. Please do not change.
export interface IConfirmedAccountRequestSwaggerSchema {
    account: IAccountInfo;
    request: IAccountRequestInfo;
}

export default class ConfirmedAccountRequestSwaggerSchema {
    readonly _account: AccountInfo;

    get account(): AccountInfo {
        return this._account;
    }

    readonly _request: AccountRequestInfo;

    get request(): AccountRequestInfo {
        return this._request;
    }

    constructor(props: IConfirmedAccountRequestSwaggerSchema) {
        this._account = new AccountInfo(props.account);
        this._request = new AccountRequestInfo(props.request);
    }

    serialize(): IConfirmedAccountRequestSwaggerSchema {
        const data: IConfirmedAccountRequestSwaggerSchema = {
            account: this._account.serialize(),
            request: this._request.serialize(),
        };
        return data;
    }

    toJSON(): IConfirmedAccountRequestSwaggerSchema {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            request: this._request.validate().length === 0,
            account: this._account.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
