<template>
    <transition name="fade">
        <div :class="['loading-icon', {
                    'is-absolute-centered': isAbsoluteCentered,
                    'is-in-module-header': isInModuleHeader
                }]"
             v-if="show"
        >
            <svg class="spinner" width="20px" height="20px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
                <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"/>
            </svg>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'Preloader',
    props: {
        show: {
            type: Boolean,
            default: true,
        },
        isAbsoluteCentered: {
            type: Boolean,
            default: false,
        },
        isInModuleHeader: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>

.spinner {
    animation: rotator 187 linear infinite;
}

@keyframes rotator {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(270deg); }
}

.path {
    stroke-dasharray: 187;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation:
        dash 1.4s ease-in-out infinite,
        colors 5.6s ease-in-out infinite;
}

@keyframes colors {
    0% { stroke: var(--sb-theme-color); }
    25% { stroke: var(--sb-theme-color); }
    50% { stroke: var(--sb-theme-color); }
    75% { stroke: var(--sb-theme-color); }
    100% { stroke: var(--sb-theme-color); }
}

@keyframes dash {
    0% { stroke-dashoffset: 187; }
    50% {
        stroke-dashoffset: 46;
        transform:rotate(135deg);
    }
    100% {
        stroke-dashoffset: 187;
        transform:rotate(450deg);
    }
}

.loading-icon.is-absolute-centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.loading-icon.is-in-module-header {
    padding-top: 10px;
    margin-right: -15px;
}
</style>
