// This file was autogenerated. Please do not change.
export interface IFuturesFundingRecord {
    accountId: string;
    assetSymbol: string;
    balanceId: string;
    outerTimestamp: string;
    placementName: string;
    quantity: string;
    quotationAssetSymbol: string;
    quotedQuantity: string;
    spotAssetPairSymbol: string;
}

export default class FuturesFundingRecord {
    readonly _accountId: string;

    /**
     * Example: A5Q98WZ7PDX
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetSymbol: string;

    /**
     * Example: USDT
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _balanceId: string;

    /**
     * Example: BNZQ2DWD9W6
     */
    get balanceId(): string {
        return this._balanceId;
    }

    readonly _outerTimestamp: string;

    /**
     * Example: 2021-04-20T14:04:06.38Z
     */
    get outerTimestamp(): string {
        return this._outerTimestamp;
    }

    readonly _placementName: string;

    /**
     * Example: Binance Futures
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _quantity: number;

    /**
     * Example: 0.0175
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _quotationAssetSymbol: string;

    /**
     * Example: USD
     */
    get quotationAssetSymbol(): string {
        return this._quotationAssetSymbol;
    }

    readonly _quotedQuantity: number;

    /**
     * Example: 0.0172
     */
    get quotedQuantity(): number {
        return this._quotedQuantity;
    }

    readonly _spotAssetPairSymbol: string;

    /**
     * Example: XRP/USDT
     */
    get spotAssetPairSymbol(): string {
        return this._spotAssetPairSymbol;
    }

    constructor(props: IFuturesFundingRecord) {
        this._accountId = props.accountId.trim();
        this._assetSymbol = props.assetSymbol.trim();
        this._balanceId = props.balanceId.trim();
        this._outerTimestamp = props.outerTimestamp.trim();
        this._placementName = props.placementName.trim();
        this._quantity = Number(props.quantity);
        this._quotationAssetSymbol = props.quotationAssetSymbol.trim();
        this._quotedQuantity = Number(props.quotedQuantity);
        this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
    }

    serialize(): IFuturesFundingRecord {
        const data: IFuturesFundingRecord = {
            accountId: this._accountId,
            assetSymbol: this._assetSymbol,
            balanceId: this._balanceId,
            outerTimestamp: this._outerTimestamp,
            placementName: this._placementName,
            quantity: String(this._quantity),
            quotationAssetSymbol: this._quotationAssetSymbol,
            quotedQuantity: String(this._quotedQuantity),
            spotAssetPairSymbol: this._spotAssetPairSymbol,
        };
        return data;
    }

    toJSON(): IFuturesFundingRecord {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            balanceId: typeof this._balanceId === 'string' && !!this._balanceId.trim(),
            outerTimestamp: typeof this._outerTimestamp === 'string' && !!this._outerTimestamp.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            quantity: true,
            quotationAssetSymbol: typeof this._quotationAssetSymbol === 'string' && !!this._quotationAssetSymbol.trim(),
            quotedQuantity: true,
            spotAssetPairSymbol: typeof this._spotAssetPairSymbol === 'string' && !!this._spotAssetPairSymbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
