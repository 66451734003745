
import Vue from 'vue';

import Icon from 'UI/Icon.vue';

interface Props {
    text?: string;
    hasPositionRelative?: boolean;
    isUser?: boolean;
}

interface Methods {
    reload: () => void;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, Methods, NoData, Props>({
    components: { Icon },
    props: {
        text: {
            type: String,
            default: 'No data to display.',
        },
        hasPositionRelative: {
            type: Boolean,
            default: false,
        },
        isUser: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        reload() {
            window.location.reload();
        },
    },
});
