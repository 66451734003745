import Asset, { IAsset } from './Asset';

// This file was autogenerated. Please do not change.
export interface IFuturesTradeQuotation {
    asset?: IAsset;
    commissionQuantity: string;
    quantity: string;
}

export default class FuturesTradeQuotation {
    readonly _asset: Asset | undefined;

    get asset(): Asset | undefined {
        return this._asset;
    }

    readonly _commissionQuantity: number;

    /**
     * Example: 0
     */
    get commissionQuantity(): number {
        return this._commissionQuantity;
    }

    readonly _quantity: number;

    /**
     * Example: 0
     */
    get quantity(): number {
        return this._quantity;
    }

    constructor(props: IFuturesTradeQuotation) {
        if (props.asset) {
            this._asset = new Asset(props.asset);
        }
        this._commissionQuantity = Number(props.commissionQuantity);
        this._quantity = Number(props.quantity);
    }

    serialize(): IFuturesTradeQuotation {
        const data: IFuturesTradeQuotation = {
            commissionQuantity: String(this._commissionQuantity),
            quantity: String(this._quantity),
        };
        if (typeof this._asset !== 'undefined') {
            data.asset = this._asset.serialize();
        }
        return data;
    }

    toJSON(): IFuturesTradeQuotation {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            asset: !this._asset ? true : this._asset.validate().length === 0,
            commissionQuantity: true,
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
