import AuthEventResult, { IAuthEventResult } from './AuthEventResult';

// This file was autogenerated. Please do not change.
export interface IUserAuthEvents {
    events: IAuthEventResult[];
    nextToken?: string;
}

export default class UserAuthEvents {
    readonly _events: AuthEventResult[];

    /** Description: List of authentication events */
    get events(): AuthEventResult[] {
        return this._events;
    }

    readonly _nextToken: string | undefined;

    /** Description: Token for the next events */
    get nextToken(): string | undefined {
        return this._nextToken;
    }

    constructor(props: IUserAuthEvents) {
        this._events = props.events.map((p) => new AuthEventResult(p));
        if (typeof props.nextToken === 'string') {
            this._nextToken = props.nextToken.trim();
        }
    }

    serialize(): IUserAuthEvents {
        const data: IUserAuthEvents = {
            events: this._events.map((p) => p.serialize()),
        };
        if (typeof this._nextToken !== 'undefined') {
            data.nextToken = this._nextToken;
        }
        return data;
    }

    toJSON(): IUserAuthEvents {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            events: this._events.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            nextToken: !this._nextToken ? true : typeof this._nextToken === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
