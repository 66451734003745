// This file was autogenerated. Please do not change.
export interface IStakingProduct {
    apr: string;
    asset: string;
    duration: number;
    minAmount: string;
    placementName: string;
    rewardAsset: string;
}

export default class StakingProduct {
    readonly _apr: number;

    get apr(): number {
        return this._apr;
    }

    readonly _asset: string;

    /**
     * Example: ETH
     */
    get asset(): string {
        return this._asset;
    }

    readonly _duration: number;

    /**
     * Example: 30
     */
    get duration(): number {
        return this._duration;
    }

    readonly _minAmount: number;

    get minAmount(): number {
        return this._minAmount;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _rewardAsset: string;

    /**
     * Example: ETH
     */
    get rewardAsset(): string {
        return this._rewardAsset;
    }

    constructor(props: IStakingProduct) {
        this._apr = Number(props.apr);
        this._asset = props.asset.trim();
        this._duration = props.duration;
        this._minAmount = Number(props.minAmount);
        this._placementName = props.placementName.trim();
        this._rewardAsset = props.rewardAsset.trim();
    }

    serialize(): IStakingProduct {
        const data: IStakingProduct = {
            apr: String(this._apr),
            asset: this._asset,
            duration: this._duration,
            minAmount: String(this._minAmount),
            placementName: this._placementName,
            rewardAsset: this._rewardAsset,
        };
        return data;
    }

    toJSON(): IStakingProduct {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            apr: true,
            asset: typeof this._asset === 'string' && !!this._asset.trim(),
            duration: typeof this._duration === 'number',
            minAmount: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            rewardAsset: typeof this._rewardAsset === 'string' && !!this._rewardAsset.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
