// This file was autogenerated. Please do not change.
export interface IAssetQuotation {
    assetPairSymbol: string;
    rate: string;
}

export default class AssetQuotation {
    readonly _assetPairSymbol: string;

    /**
     * Example: BTC/USDT
     */
    get assetPairSymbol(): string {
        return this._assetPairSymbol;
    }

    readonly _rate: number;

    /**
     * Example: 123456.789
     */
    get rate(): number {
        return this._rate;
    }

    constructor(props: IAssetQuotation) {
        this._assetPairSymbol = props.assetPairSymbol.trim();
        this._rate = Number(props.rate);
    }

    serialize(): IAssetQuotation {
        const data: IAssetQuotation = {
            assetPairSymbol: this._assetPairSymbol,
            rate: String(this._rate),
        };
        return data;
    }

    toJSON(): IAssetQuotation {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetPairSymbol: typeof this._assetPairSymbol === 'string' && !!this._assetPairSymbol.trim(),
            rate: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
