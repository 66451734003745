import OrderBookLevel, { IOrderBookLevel } from './OrderBookLevel';

// This file was autogenerated. Please do not change.
export interface IOrderBook {
    asks: IOrderBookLevel[];
    bids: IOrderBookLevel[];
    sequence: number;
}

export default class OrderBook {
    readonly _asks: OrderBookLevel[];

    get asks(): OrderBookLevel[] {
        return this._asks;
    }

    readonly _bids: OrderBookLevel[];

    get bids(): OrderBookLevel[] {
        return this._bids;
    }

    readonly _sequence: number;

    get sequence(): number {
        return this._sequence;
    }

    constructor(props: IOrderBook) {
        this._asks = props.asks.map((p) => new OrderBookLevel(p));
        this._bids = props.bids.map((p) => new OrderBookLevel(p));
        this._sequence = props.sequence;
    }

    serialize(): IOrderBook {
        const data: IOrderBook = {
            asks: this._asks.map((p) => p.serialize()),
            bids: this._bids.map((p) => p.serialize()),
            sequence: this._sequence,
        };
        return data;
    }

    toJSON(): IOrderBook {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            asks: this._asks.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            bids: this._bids.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            sequence: typeof this._sequence === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
