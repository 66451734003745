import Vue from 'vue';
import Popper from 'vue-popperjs';

import LineChart from 'Components/Charts/LineChart';
import DoughnutChart from 'Components/Charts/DoughnutChart';
import ShortLineChart from 'Components/Charts/ShortLineChart';
import BarChart from 'Components/Charts/BarChart';
import EmptyBarChart from 'Components/Charts/EmptyBarChart.vue';
import CryptoIcon from 'Common/CryptoIcon.vue';
import PlacementIcon from 'Common/PlacementIcon.vue';
import Preloader from 'Common/Preloader.vue';
import Guid from 'Common/Guid.vue';
// TODO: refactor styles for following components;
import Paginator from 'Common/Paginator.vue';
import ToggleBar from 'Components/FormElements/CustomToggleBar.vue';

Vue.component('Popper', Popper);
Vue.component('CryptoIcon', CryptoIcon); // middleware component for vue-cryptoicon
Vue.component('PlacementIcon', PlacementIcon);
Vue.component('Preloader', Preloader);
Vue.component('Paginator', Paginator);
Vue.component('ToggleBar', ToggleBar);
Vue.component('Guid', Guid);

Vue.component('LineChart', LineChart);
Vue.component('DoughnutChart', DoughnutChart);
Vue.component('ShortLineChart', ShortLineChart);
Vue.component('BarChart', BarChart);
Vue.component('EmptyBarChart', EmptyBarChart);
