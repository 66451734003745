// This file was autogenerated. Please do not change.
export interface IDexTransactionsRequest {
    accountId?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
    status?: string;
    statuses?: string[];
    type?: ('APPROVE' | 'SWAP');
}

export default class DexTransactionsRequest {
    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Dex
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    readonly _statuses: string[] | undefined;

    get statuses(): string[] | undefined {
        return this._statuses;
    }

    readonly _type: ('APPROVE' | 'SWAP') | undefined;

    get type(): ('APPROVE' | 'SWAP') | undefined {
        return this._type;
    }

    constructor(props: IDexTransactionsRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (props.statuses) {
            this._statuses = props.statuses;
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): IDexTransactionsRequest {
        const data: IDexTransactionsRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._statuses !== 'undefined') {
            data.statuses = this._statuses;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IDexTransactionsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            statuses: !this._statuses ? true : this._statuses.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
