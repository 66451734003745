// This file was autogenerated. Please do not change.
export interface IGroupParams {
    id: string;
}

export default class GroupParams {
    readonly _id: string;

    /**
     * Example: GD3TGKFC3
     */
    get id(): string {
        return this._id;
    }

    constructor(props: IGroupParams) {
        this._id = props.id.trim();
    }

    serialize(): IGroupParams {
        const data: IGroupParams = {
            id: this._id,
        };
        return data;
    }

    toJSON(): IGroupParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
