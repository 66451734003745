// This file was autogenerated. Please do not change.
export interface ISwitchLeverageRequest {
    accountId: string;
    contractSymbol: string;
    leverage: number;
    placementName: string;
}

export default class SwitchLeverageRequest {
    readonly _accountId: string;

    /**
     * Example: A00000001
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _contractSymbol: string;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _leverage: number;

    /**
     * Example: 10
     */
    get leverage(): number {
        return this._leverage;
    }

    readonly _placementName: string;

    /**
     * Example: Binance Futures
     */
    get placementName(): string {
        return this._placementName;
    }

    constructor(props: ISwitchLeverageRequest) {
        this._accountId = props.accountId.trim();
        this._contractSymbol = props.contractSymbol.trim();
        this._leverage = props.leverage;
        this._placementName = props.placementName.trim();
    }

    serialize(): ISwitchLeverageRequest {
        const data: ISwitchLeverageRequest = {
            accountId: this._accountId,
            contractSymbol: this._contractSymbol,
            leverage: this._leverage,
            placementName: this._placementName,
        };
        return data;
    }

    toJSON(): ISwitchLeverageRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            leverage: typeof this._leverage === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
