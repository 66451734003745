// This file was autogenerated. Please do not change.
export interface ISwitchLeverageResponse {
    data?: string;
}

export default class SwitchLeverageResponse {
    readonly _data: string | undefined;

    /**
     * Example: OK
     */
    get data(): string | undefined {
        return this._data;
    }

    constructor(props: ISwitchLeverageResponse) {
        if (typeof props.data === 'string') {
            this._data = props.data.trim();
        }
    }

    serialize(): ISwitchLeverageResponse {
        const data: ISwitchLeverageResponse = {
        };
        if (typeof this._data !== 'undefined') {
            data.data = this._data;
        }
        return data;
    }

    toJSON(): ISwitchLeverageResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            data: !this._data ? true : typeof this._data === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
