// This file was autogenerated. Please do not change.
export interface IActivationStatusWithSecret {
    secret: string;
    status: string;
}

export default class ActivationStatusWithSecret {
    readonly _secret: string;

    /** Description: Secret */
    get secret(): string {
        return this._secret;
    }

    readonly _status: string;

    /** Description: Status of the current operation */
    get status(): string {
        return this._status;
    }

    constructor(props: IActivationStatusWithSecret) {
        this._secret = props.secret.trim();
        this._status = props.status.trim();
    }

    serialize(): IActivationStatusWithSecret {
        const data: IActivationStatusWithSecret = {
            secret: this._secret,
            status: this._status,
        };
        return data;
    }

    toJSON(): IActivationStatusWithSecret {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            status: typeof this._status === 'string' && !!this._status.trim(),
            secret: typeof this._secret === 'string' && !!this._secret.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
