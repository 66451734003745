import { OrdersSides } from 'Models/trading';

const state = {
    bestBid: 0,
    bestAsk: 0,
};

const getters = {
    bookBestBid: (state) => state.bestBid,
    bookBestAsk: (state) => state.bestAsk,
    marketPrice: (state, getters) => ({
        [OrdersSides.BUY.value]: getters.bookBestAsk,
        [OrdersSides.SELL.value]: getters.bookBestBid,
    }),
};

const mutations = {
    SET_BEST_PRICES(state, { bestBid, bestAsk }) {
        state.bestBid = bestBid;
        state.bestAsk = bestAsk;
    },
};

const actions = {
    setBestPrices({ commit }, data) {
        commit('SET_BEST_PRICES', data);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
