import { actionCreator, mutationCreator } from 'Store/utils';
import Placement from 'Entities/publicPresenter/Placement';
import PublicDataApi from 'Apis/PublicData';
import PlacementsRequest from 'Entities/publicPresenter/PlacementsRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import TradingData, { ITradingData } from 'Entities/publicPresenter/TradingData';
import MarketDataApi from 'Apis/MarketData';
import TradingDataRequest from 'Entities/publicPresenter/TradingDataRequest';
import { init, subscribe } from 'Store/v2/PublicSocketData';
import SpotAssetPairsRequest from 'Entities/publicPresenter/SpotAssetPairsRequest';
import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';

export enum RESTRICTED_PLACEMENTS {
    KuCoin = 'KuCoin',
    Bittrex = 'Bittrex',
    HitBTC = 'HitBTC',
    'Gate.io' = 'Gate.io',
}

interface ISetActivePlacement {
    placementName: string,
    assetPairsData: TradingData[],
}

const state = {
    placements: undefined as undefined | Placement[],
    activePlacements: new Map() as Map<string, TradingData[]>,
    usedPlacementsNames: new Set<string>(),
};

export type WorkspaceChooseToolState = typeof state;

export enum WorkspaceChooseToolMutations {
    SET_PLACEMENTS = 'SET_PLACEMENTS',
    SET_NEW_ACTIVE_PLACEMENT = 'SET_NEW_ACTIVE_PLACEMENT',
    UPDATE_PLACEMENT_ASSET_PAIRS = 'UPDATE_PLACEMENT_ASSET_PAIRS',
}

export const SET_PLACEMENTS = mutationCreator<Placement[]>('WorkspaceChooseTool', WorkspaceChooseToolMutations.SET_PLACEMENTS);
export const SET_NEW_ACTIVE_PLACEMENT = mutationCreator<ISetActivePlacement>('WorkspaceChooseTool', WorkspaceChooseToolMutations.SET_NEW_ACTIVE_PLACEMENT);
export const UPDATE_PLACEMENT_ASSET_PAIRS = mutationCreator<ISetActivePlacement>('WorkspaceChooseTool', WorkspaceChooseToolMutations.UPDATE_PLACEMENT_ASSET_PAIRS);

const mutations: Record<WorkspaceChooseToolMutations, (state: WorkspaceChooseToolState, ...args: any) => void> = {
    SET_PLACEMENTS(state, { payload: placements }: ReturnType<typeof SET_PLACEMENTS>) {
        state.placements = placements;
    },
    SET_NEW_ACTIVE_PLACEMENT(state, { payload: { placementName, assetPairsData } }: ReturnType<typeof SET_NEW_ACTIVE_PLACEMENT>) {
        state.activePlacements = new Map(state.activePlacements).set(placementName.toUpperCase(), assetPairsData);
    },
    UPDATE_PLACEMENT_ASSET_PAIRS(state, { payload: { placementName, assetPairsData } }: ReturnType<typeof UPDATE_PLACEMENT_ASSET_PAIRS>) {
        const tempMap = new Map<string | undefined, TradingData>();
        assetPairsData.forEach((pair) => {
            tempMap.set(pair.symbol, pair);
        });
        let placementAssetPairs = state.activePlacements.get(placementName.toUpperCase());
        if (placementAssetPairs) {
            placementAssetPairs = placementAssetPairs.map((pair) => {
                if (tempMap.has(pair.symbol)) {
                    return tempMap.get(pair.symbol)!;
                }
                return pair;
            });
            state.activePlacements.set(placementName.toUpperCase(), placementAssetPairs);
        }
    },
};

export enum WorkspaceChooseToolActions {
    getPlacements = 'getPlacements',
    setActivePlacement = 'setActivePlacement',
}

export const getPlacements = actionCreator<undefined>('WorkspaceChooseTool', WorkspaceChooseToolActions.getPlacements);
export const setActivePlacement = actionCreator<string>('WorkspaceChooseTool', WorkspaceChooseToolActions.setActivePlacement);

const actions = {
    async getPlacements({ state, commit }) {
        if (state.placements) {
            return;
        }
        let allPlacements = [] as Placement[];
        const { data: placements, headers } = await PublicDataApi.publicGetPlacements(new PlacementsRequest({
            page: 1,
            perPage: 20,
            includeTotal: true,
        }), true);
        allPlacements = [...placements];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers)?.totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraPlacements } = await PublicDataApi.publicGetPlacements(new PlacementsRequest({
                        page: i,
                        perPage: 20,
                    }));
                    allPlacements = [...allPlacements, ...extraPlacements];
                }
            }
        }
        commit(SET_PLACEMENTS(allPlacements.filter(({ type, name }) => type.indexOf('decentralized') === -1 && type !== 'otc-spot' && name !== 'Single Broker'), true));
    },
    async setActivePlacement({ commit, dispatch, state, rootState }, { payload: placementName }: ReturnType<typeof setActivePlacement>) {
        if (state.usedPlacementsNames.has(placementName.toUpperCase())) {
            return;
        }
        state.usedPlacementsNames.add(placementName.toUpperCase());

        let allAssetPairs: SpotAssetPair[] = [];
        const { data: assetPairs, headers } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
            placementName,
            page: 1,
            perPage: 100,
            includeTotal: true,
        }), true);
        allAssetPairs = [...assetPairs];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraAssetPairs } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                        placementName,
                        page: i,
                        perPage: 100,
                    }));
                    allAssetPairs = [...allAssetPairs, ...extraAssetPairs];
                }
            }
        }

        let { data: tradingData } = await MarketDataApi.publicGetTradingData(new TradingDataRequest({
            placementName,
            assetPairSymbols: allAssetPairs.map(({ symbol }) => symbol),
        }));
        tradingData = tradingData.sort(({ symbol: aSymbol }, { symbol: bSymbol }) => {
            return aSymbol! > bSymbol! ? 1 : -1;
        });
        commit(SET_NEW_ACTIVE_PLACEMENT({ placementName, assetPairsData: tradingData }, true));

        if (!rootState.PublicSocketData.centrifuge) {
            await dispatch(init(undefined), { root: true });
        }
        await dispatch(subscribe({
            channel: `tradingdata:${placementName.toUpperCase()}`,
            callback: ({ data }: { data: ITradingData[] }) => {
                commit(UPDATE_PLACEMENT_ASSET_PAIRS({ placementName, assetPairsData: data.map((el) => new TradingData(el)) }, true));
            },
        }), { root: true });
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
