// This file was autogenerated. Please do not change.
export interface IRelatedBalanceAsset {
    destination?: string;
    source?: string;
}

export default class RelatedBalanceAsset {
    readonly _destination: string | undefined;

    get destination(): string | undefined {
        return this._destination;
    }

    readonly _source: string | undefined;

    /**
     * Example: BBTC
     */
    get source(): string | undefined {
        return this._source;
    }

    constructor(props: IRelatedBalanceAsset) {
        if (typeof props.destination === 'string') {
            this._destination = props.destination.trim();
        }
        if (typeof props.source === 'string') {
            this._source = props.source.trim();
        }
    }

    serialize(): IRelatedBalanceAsset {
        const data: IRelatedBalanceAsset = {
        };
        if (typeof this._destination !== 'undefined') {
            data.destination = this._destination;
        }
        if (typeof this._source !== 'undefined') {
            data.source = this._source;
        }
        return data;
    }

    toJSON(): IRelatedBalanceAsset {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            destination: !this._destination ? true : typeof this._destination === 'string',
            source: !this._source ? true : typeof this._source === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
