// This file was autogenerated. Please do not change.
export interface IStakingTransaction {
    amount: string;
    createdAt: string;
    type?: ('SUBSCRIPTION' | 'REDEMPTION' | 'INTEREST');
    updatedAt: string;
}

export default class StakingTransaction {
    readonly _amount: number;

    /**
     * Example: 0.15
     */
    get amount(): number {
        return this._amount;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _type: ('SUBSCRIPTION' | 'REDEMPTION' | 'INTEREST') | undefined;

    get type(): ('SUBSCRIPTION' | 'REDEMPTION' | 'INTEREST') | undefined {
        return this._type;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IStakingTransaction) {
        this._amount = Number(props.amount);
        this._createdAt = props.createdAt.trim();
        if (props.type) {
            this._type = props.type;
        }
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IStakingTransaction {
        const data: IStakingTransaction = {
            amount: String(this._amount),
            createdAt: this._createdAt,
            updatedAt: this._updatedAt,
        };
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IStakingTransaction {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            amount: true,
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
