var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{class:[_vm.theme.buttons.button, {
        [_vm.theme.buttons.fullWidth]: _vm.wide,
        [_vm.theme.buttons.primary]: _vm.buttonType === _vm.ButtonTypes.primary,
        [_vm.theme.buttons.info]: _vm.buttonType === _vm.ButtonTypes.info,
        [_vm.theme.buttons.infoNoBackground]: _vm.buttonType === _vm.ButtonTypes.infoNoBackground,
        [_vm.theme.buttons.success]: _vm.buttonType === _vm.ButtonTypes.success,
        [_vm.theme.buttons.error]: _vm.buttonType === _vm.ButtonTypes.error,
        [_vm.theme.buttons.errorLink]: _vm.buttonType === _vm.ButtonTypes.errorLink,
        [_vm.theme.buttons.download]: _vm.buttonType === _vm.ButtonTypes.download,
        [_vm.theme.buttons.infoRounded]: _vm.buttonType === _vm.ButtonTypes.infoRounded,
        [_vm.theme.buttons.link]: _vm.buttonType === _vm.ButtonTypes.link,
        [_vm.theme.buttons.underlinedLink]: _vm.buttonType === _vm.ButtonTypes.underlinedLink,
        [_vm.theme.buttons.disabled]: _vm.buttonDisabled,
    }],attrs:{"type":_vm.isSubmit && !_vm.buttonDisabled ? 'submit' : 'button'},on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)},"mousedown":function($event){return _vm.$emit('mousedown')}}},[_c('div',{class:_vm.theme.buttons.row},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }