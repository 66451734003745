import ClientObject, { IClientObject } from './ClientObject';

// This file was autogenerated. Please do not change.
export interface IAuthEventResult {
    client?: IClientObject;
    eventType?: string;
    published?: string;
    userId?: string;
}

export default class AuthEventResult {
    readonly _client: ClientObject | undefined;

    get client(): ClientObject | undefined {
        return this._client;
    }

    readonly _eventType: string | undefined;

    get eventType(): string | undefined {
        return this._eventType;
    }

    readonly _published: string | undefined;

    get published(): string | undefined {
        return this._published;
    }

    readonly _userId: string | undefined;

    get userId(): string | undefined {
        return this._userId;
    }

    constructor(props: IAuthEventResult) {
        if (props.client) {
            this._client = new ClientObject(props.client);
        }
        if (typeof props.eventType === 'string') {
            this._eventType = props.eventType.trim();
        }
        if (typeof props.published === 'string') {
            this._published = props.published.trim();
        }
        if (typeof props.userId === 'string') {
            this._userId = props.userId.trim();
        }
    }

    serialize(): IAuthEventResult {
        const data: IAuthEventResult = {
        };
        if (typeof this._client !== 'undefined') {
            data.client = this._client.serialize();
        }
        if (typeof this._eventType !== 'undefined') {
            data.eventType = this._eventType;
        }
        if (typeof this._published !== 'undefined') {
            data.published = this._published;
        }
        if (typeof this._userId !== 'undefined') {
            data.userId = this._userId;
        }
        return data;
    }

    toJSON(): IAuthEventResult {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            client: !this._client ? true : this._client.validate().length === 0,
            eventType: !this._eventType ? true : typeof this._eventType === 'string',
            published: !this._published ? true : typeof this._published === 'string',
            userId: !this._userId ? true : typeof this._userId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
