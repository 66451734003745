
import Vue from 'vue';

import { IconName } from 'UI/Icons.vue';

interface IconProps {
    icon: IconName;
    className?: string;
    small?: boolean;
}
/** Emit
 * clicked: () => void;
 */

type NoData = Record<string, never>;

export { IconName };

export default Vue.extend<NoData, NoData, NoData, IconProps>({
    name: 'Icon',
    props: {
        icon: String as any,
        className: String,
        small: {
            type: Boolean,
            default: false,
        },
    },
});
