import { Action } from 'vuex';

import { actionCreator } from 'Store/utils';

export enum UiActionsActions {
    needUpdateTransferHistory = 'needUpdateTransferHistory',
    needUpdateAddresses = 'needUpdateAddresses',
}

export const needUpdateTransferHistory = actionCreator<undefined>('UiActions', UiActionsActions.needUpdateTransferHistory);
export const needUpdateAddresses = actionCreator<undefined>('UiActions', UiActionsActions.needUpdateAddresses);

const actions: Record<UiActionsActions, Action<any, any>> = {
    needUpdateTransferHistory() {
        return true;
    },
    needUpdateAddresses() {
        return true;
    },
};

export default {
    namespaced: true,
    actions,
};
