
import Vue from 'vue';

import theme from 'Theme';

enum ButtonTypes {
    primary = 'primary',
    info = 'info',
    infoNoBackground = 'infoNoBackground',
    infoRounded = 'infoRounded',
    success = 'success',
    error = 'error',
    errorLink = 'errorLink',
    download = 'download',
    link = 'link',
    underlinedLink = 'underlinedLink',
}

interface Props {
    buttonType?: string;
    isSubmit?: boolean;
    buttonDisabled?: boolean;
    wide?: boolean;
}

export default Vue.extend<any, any, any, Props>({
    props: {
        buttonType: {
            type: String,
            default: ButtonTypes.primary,
        },
        isSubmit: {
            type: Boolean,
            default: false,
        },
        buttonDisabled: {
            type: Boolean,
            default: false,
        },
        wide: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            theme,
            ButtonTypes,
        };
    },
    methods: {
        onClick() {
            if (this.buttonDisabled) {
                return;
            }
            this.$emit('click');
        },
    },
});
