import { mutationCreator } from 'Store/utils';
import EmptyResult from 'Entities/walletExecutor/EmptyResult';

type IUi = {
    activeVariantIndex: number;
    activeCurrencyIndex: number;
    activeBlockchainIndex: number;
    fromPlacementIndex: number;
    toPlacements: { name: string; quantity: number; feeSize: null | string; fees: Record<string, unknown> | EmptyResult | undefined; checked: boolean; }[];
};

type ISetUi = Partial<IUi>;

const state = {
    ui: {
        activeVariantIndex: 0,
        activeCurrencyIndex: 0,
        activeBlockchainIndex: 0,
        fromPlacementIndex: 0,
        toPlacements: [],
    } as IUi,
    needMountUi: false,
};

export type MultiTransfersState = typeof state;

export enum MultiTransfersMutations {
    SET_UI = 'SET_UI',
    SET_NEED_MOUNT_UI = 'SET_NEED_MOUNT_UI',
}

export const SET_UI = mutationCreator<ISetUi>('MultiTransfers', MultiTransfersMutations.SET_UI);
export const SET_NEED_MOUNT_UI = mutationCreator<boolean>('MultiTransfers', MultiTransfersMutations.SET_NEED_MOUNT_UI);

const mutations: Record<MultiTransfersMutations, (state: MultiTransfersState, ...args: any) => void> = {
    SET_UI(state, { payload: ui }: ReturnType<typeof SET_UI>) {
        state.ui = { ...state.ui, ...ui };
    },
    SET_NEED_MOUNT_UI(state, { payload: value }: ReturnType<typeof SET_NEED_MOUNT_UI>) {
        state.needMountUi = value;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
