// This file was autogenerated. Please do not change.
export interface IGroupAccount {
    accountId?: string;
}

export default class GroupAccount {
    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    constructor(props: IGroupAccount) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
    }

    serialize(): IGroupAccount {
        const data: IGroupAccount = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        return data;
    }

    toJSON(): IGroupAccount {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
