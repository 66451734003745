// This file was autogenerated. Please do not change.
export interface IGetTradeAPIKeySecretsRequest {
    key: string;
    placementTag: string;
    totp?: string;
}

export default class GetTradeAPIKeySecretsRequest {
    readonly _key: string;

    /**
     * Example: qwertyuiopasdfghjklzxcvbnm
     */
    get key(): string {
        return this._key;
    }

    readonly _placementTag: string;

    /**
     * Example: BINANCE
     */
    get placementTag(): string {
        return this._placementTag;
    }

    readonly _totp: string | undefined;

    /**
     * Example: 123456
     */
    get totp(): string | undefined {
        return this._totp;
    }

    constructor(props: IGetTradeAPIKeySecretsRequest) {
        this._key = props.key.trim();
        this._placementTag = props.placementTag.trim();
        if (typeof props.totp === 'string') {
            this._totp = props.totp.trim();
        }
    }

    serialize(): IGetTradeAPIKeySecretsRequest {
        const data: IGetTradeAPIKeySecretsRequest = {
            key: this._key,
            placementTag: this._placementTag,
        };
        if (typeof this._totp !== 'undefined') {
            data.totp = this._totp;
        }
        return data;
    }

    toJSON(): IGetTradeAPIKeySecretsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            key: typeof this._key === 'string' && !!this._key.trim(),
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
            totp: !this._totp ? true : typeof this._totp === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
