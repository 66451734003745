import Vue from 'vue';
import { Bar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default Vue.extend<any, any, any, any>({
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        tooltipTitleCallback: {
            type: Function,
            default: null,
        },
        tooltipBeforeLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelColorCallback: {
            type: Function,
            default: null,
        },
        tooltipFooterCallback: {
            type: Function,
            default: null,
        },
        tooltipTextColorCallback: {
            type: Function,
            default: null,
        },
        yAxeLabelCallback: {
            type: Function,
            default: null,
        },
        minX: {
            type: Number,
            default: undefined,
        },
        maxX: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        darkTheme() {
            return this.$store.getters.isThemeDark;
        },
        fontColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(35, 36, 77, 0.5)';
        },
        gridLinesColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)';
        },
        tooltipCallbacks() {
            const result: any = {};

            if (this.tooltipTitleCallback) { result.title = this.tooltipTitleCallback; }
            if (this.tooltipLabelCallback) { result.label = this.tooltipLabelCallback; }
            if (this.tooltipBeforeLabelCallback) { result.beforeLabel = this.tooltipBeforeLabelCallback; }
            if (this.tooltipLabelColorCallback) { result.labelColor = this.tooltipLabelColorCallback; }
            if (this.tooltipFooterCallback) { result.footer = this.tooltipFooterCallback; }
            if (this.tooltipTextColorCallback) { result.labelTextColor = this.tooltipTextColorCallback; }

            return result;
        },
        data() {
            let { datasets } = this.chartData;
            const { labels } = this.chartData;

            datasets = datasets.map((dataset) => (dataset.datalabels
                ? dataset
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels,
                datasets,
            };
        },
        options() {
            const options = {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            color: '#f5f5f5',
                            ticks: {
                                beginAtZero: true,
                                fontSize: 13,
                                fontColor: this.fontColor,
                                fontFamily: 'Nunito Sans, sans-serif',
                                fontWeight: 500,
                                padding: 5,
                                callback: this.yAxeLabelCallback ? this.yAxeLabelCallback : (value) => value,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineBorderDashOffset: 1,
                                zeroLineColor: this.$store.getters.isThemeDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                                color: this.$store.getters.isThemeDark ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)',
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'time',
                            mode: 'x',
                            time: {
                                unit: 'day',
                                stepSize: 2,
                            },
                            color: '#fff',
                            showTicks: false,
                            gridLines: false,
                            ticks: {
                                major: {
                                    enabled: false,
                                    fontSize: 11,
                                    fontStyle: 'bold',
                                },
                                maxRotation: 0,
                                minRotation: 0,
                                fontFamily: 'Nunito Sans, sans-serif',
                                fontSize: 10,
                                fontColor: this.fontColor,
                                lineHeight: 3,
                                min: this.minX,
                                max: this.maxX,
                                autoSkip: true,
                                maxTicksLimit: 10,
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    titleFontFamily: 'Nunito Sans, sans-serif',
                    bodyFontFamily: 'Nunito Sans, sans-serif',
                    callbacks: this.tooltipCallbacks,
                    titleFontSize: 13,
                    bodyFontSize: 13,
                    footerFontSize: 13,
                },
                hover: {
                    mode: 'x',
                    intersect: false,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                },
            };
            return options;
        },
        chart() {
            return this.$data._chart;
        },
    },
    methods: {
        updateChart() {
            this.chart.update();
        },
        hideDataset(index) {
            if (this.chart.data.datasets.length > index) {
                const meta = this.chart.data.datasets[index]._meta;

                Object.keys(meta).forEach((field) => {
                    meta[field].hidden = true;
                });

                this.updateChart();
            } else {
                throw 'No available dataset';
            }
        },
        showDataset(index) {
            if (this.chart.data.datasets.length > index) {
                const meta = this.chart.data.datasets[index]._meta;

                Object.keys(meta).forEach((field) => {
                    meta[field].hidden = null;
                });

                this.updateChart();
            } else {
                throw 'No available dataset';
            }
        },
        Render() {
            this.renderChart(this.data, this.options);
        },
    },
    mounted() {
        this.Render();
    },
    watch: {
        darkTheme() {
            this.Render();
        },
    },
});
