/**
 *
 * @param {Object} aggregation
 * @param {Boolean} isAsk - if True, rounding will be performed to biggest numbers, if False - rounding will be performed to smallest numbers
 * @return {Array}
 */
Array.prototype.aggregate = function ({ aggregation }, isAsk = false) {

    let result = new Set();

    this.forEach((price) => {

        result.add(((isAsk ? (price / aggregation).ceil(0) : (price / aggregation).floor(0)) * aggregation).floor(Math.abs(Math.log10(aggregation))))

    });

    return [...result]

};
