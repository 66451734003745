import Attributes, { IAttributes } from './Attributes';
import Leverages, { ILeverages } from './Leverages';

// This file was autogenerated. Please do not change.
export interface ISpotAssetPair {
    baseAssetId: number;
    baseAssetPlacementSymbol?: string;
    baseAssetSymbol?: string;
    contractPrice?: string;
    disabled?: boolean;
    id: number;
    placementAttributes?: IAttributes;
    placementLeverages?: ILeverages[];
    placementName?: string;
    placementPrecisionPrice?: number;
    placementPrecisionQuantity?: number;
    placementSymbol?: string;
    quoteAssetId: number;
    quoteAssetPlacementSymbol?: string;
    quoteAssetSymbol?: string;
    symbol: string;
}

export default class SpotAssetPair {
    readonly _baseAssetId: number;

    /**
     * Example: 17
     */
    get baseAssetId(): number {
        return this._baseAssetId;
    }

    readonly _baseAssetPlacementSymbol: string | undefined;

    /**
     * Example: BTC
     */
    get baseAssetPlacementSymbol(): string | undefined {
        return this._baseAssetPlacementSymbol;
    }

    readonly _baseAssetSymbol: string | undefined;

    /**
     * Example: BTC
     */
    get baseAssetSymbol(): string | undefined {
        return this._baseAssetSymbol;
    }

    readonly _contractPrice: number | undefined;

    /**
     * Example: 0.001
     */
    get contractPrice(): number | undefined {
        return this._contractPrice;
    }

    readonly _disabled: boolean | undefined;

    get disabled(): boolean | undefined {
        return this._disabled;
    }

    readonly _id: number;

    /**
     * Example: 28
     */
    get id(): number {
        return this._id;
    }

    readonly _placementAttributes: Attributes | undefined;

    get placementAttributes(): Attributes | undefined {
        return this._placementAttributes;
    }

    readonly _placementLeverages: Leverages[] | undefined;

    get placementLeverages(): Leverages[] | undefined {
        return this._placementLeverages;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _placementPrecisionPrice: number | undefined;

    /**
     * Example: 2
     */
    get placementPrecisionPrice(): number | undefined {
        return this._placementPrecisionPrice;
    }

    readonly _placementPrecisionQuantity: number | undefined;

    get placementPrecisionQuantity(): number | undefined {
        return this._placementPrecisionQuantity;
    }

    readonly _placementSymbol: string | undefined;

    /**
     * Example: BTCUSDT
     */
    get placementSymbol(): string | undefined {
        return this._placementSymbol;
    }

    readonly _quoteAssetId: number;

    /**
     * Example: 7
     */
    get quoteAssetId(): number {
        return this._quoteAssetId;
    }

    readonly _quoteAssetPlacementSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get quoteAssetPlacementSymbol(): string | undefined {
        return this._quoteAssetPlacementSymbol;
    }

    readonly _quoteAssetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get quoteAssetSymbol(): string | undefined {
        return this._quoteAssetSymbol;
    }

    readonly _symbol: string;

    /**
     * Example: BTC/USDT
     */
    get symbol(): string {
        return this._symbol;
    }

    constructor(props: ISpotAssetPair) {
        this._baseAssetId = props.baseAssetId;
        if (typeof props.baseAssetPlacementSymbol === 'string') {
            this._baseAssetPlacementSymbol = props.baseAssetPlacementSymbol.trim();
        }
        if (typeof props.baseAssetSymbol === 'string') {
            this._baseAssetSymbol = props.baseAssetSymbol.trim();
        }
        if (typeof props.contractPrice === 'string') {
            this._contractPrice = Number(props.contractPrice);
        }
        if (typeof props.disabled === 'boolean') {
            this._disabled = props.disabled;
        }
        this._id = props.id;
        if (props.placementAttributes) {
            this._placementAttributes = new Attributes(props.placementAttributes);
        }
        if (props.placementLeverages) {
            this._placementLeverages = props.placementLeverages.map((p) => new Leverages(p));
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.placementPrecisionPrice === 'number') {
            this._placementPrecisionPrice = props.placementPrecisionPrice;
        }
        if (typeof props.placementPrecisionQuantity === 'number') {
            this._placementPrecisionQuantity = props.placementPrecisionQuantity;
        }
        if (typeof props.placementSymbol === 'string') {
            this._placementSymbol = props.placementSymbol.trim();
        }
        this._quoteAssetId = props.quoteAssetId;
        if (typeof props.quoteAssetPlacementSymbol === 'string') {
            this._quoteAssetPlacementSymbol = props.quoteAssetPlacementSymbol.trim();
        }
        if (typeof props.quoteAssetSymbol === 'string') {
            this._quoteAssetSymbol = props.quoteAssetSymbol.trim();
        }
        this._symbol = props.symbol.trim();
    }

    serialize(): ISpotAssetPair {
        const data: ISpotAssetPair = {
            baseAssetId: this._baseAssetId,
            id: this._id,
            quoteAssetId: this._quoteAssetId,
            symbol: this._symbol,
        };
        if (typeof this._baseAssetPlacementSymbol !== 'undefined') {
            data.baseAssetPlacementSymbol = this._baseAssetPlacementSymbol;
        }
        if (typeof this._baseAssetSymbol !== 'undefined') {
            data.baseAssetSymbol = this._baseAssetSymbol;
        }
        if (typeof this._contractPrice !== 'undefined') {
            data.contractPrice = String(this._contractPrice);
        }
        if (typeof this._disabled !== 'undefined') {
            data.disabled = this._disabled;
        }
        if (typeof this._placementAttributes !== 'undefined') {
            data.placementAttributes = this._placementAttributes.serialize();
        }
        if (typeof this._placementLeverages !== 'undefined') {
            data.placementLeverages = this._placementLeverages.map((p) => p.serialize());
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._placementPrecisionPrice !== 'undefined') {
            data.placementPrecisionPrice = this._placementPrecisionPrice;
        }
        if (typeof this._placementPrecisionQuantity !== 'undefined') {
            data.placementPrecisionQuantity = this._placementPrecisionQuantity;
        }
        if (typeof this._placementSymbol !== 'undefined') {
            data.placementSymbol = this._placementSymbol;
        }
        if (typeof this._quoteAssetPlacementSymbol !== 'undefined') {
            data.quoteAssetPlacementSymbol = this._quoteAssetPlacementSymbol;
        }
        if (typeof this._quoteAssetSymbol !== 'undefined') {
            data.quoteAssetSymbol = this._quoteAssetSymbol;
        }
        return data;
    }

    toJSON(): ISpotAssetPair {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            baseAssetId: typeof this._baseAssetId === 'number',
            baseAssetPlacementSymbol: !this._baseAssetPlacementSymbol ? true : typeof this._baseAssetPlacementSymbol === 'string',
            baseAssetSymbol: !this._baseAssetSymbol ? true : typeof this._baseAssetSymbol === 'string',
            contractPrice: true,
            disabled: !this._disabled ? true : typeof this._disabled === 'boolean',
            id: typeof this._id === 'number',
            placementAttributes: !this._placementAttributes ? true : this._placementAttributes.validate().length === 0,
            placementLeverages: !this._placementLeverages ? true : this._placementLeverages.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            placementPrecisionPrice: !this._placementPrecisionPrice ? true : typeof this._placementPrecisionPrice === 'number',
            placementPrecisionQuantity: !this._placementPrecisionQuantity ? true : typeof this._placementPrecisionQuantity === 'number',
            placementSymbol: !this._placementSymbol ? true : typeof this._placementSymbol === 'string',
            quoteAssetId: typeof this._quoteAssetId === 'number',
            quoteAssetPlacementSymbol: !this._quoteAssetPlacementSymbol ? true : typeof this._quoteAssetPlacementSymbol === 'string',
            quoteAssetSymbol: !this._quoteAssetSymbol ? true : typeof this._quoteAssetSymbol === 'string',
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
