const state = {
    currentCurrencyIndex: 0,
    address: '',
    memo: '',
    quantity: 0,

    currentFees: null,
    chosenFee: null,
    isFeeUpdating: false,
};

const getters = {
    currenciesList: (state, getters, rootState, rootGetters) => Object.values(rootGetters['Transactions/Transfer/transferablePlacementAssets'](rootGetters['Placements/platformPlacementName'])),
    currentCurrencyIndex: (state) => state.currentCurrencyIndex,
    currentCurrency: (state, getters) => (getters.currenciesList.length > 0 ? getters.currenciesList[getters.currentCurrencyIndex] : {}),
    currentCurrencySymbol: (state, getters) => (getters.currentCurrency.symbol ? getters.currentCurrency.symbol.toUpperCase() : null),

    currentCurrencyBalance: (state, getters, rootState, rootGetters) => (getters.currentCurrencySymbol ? rootGetters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](rootGetters['Placements/platformPlacementId'], getters.currentCurrencySymbol) : null),
    currentCurrencyBalanceAmount: (state, getters) => (getters.currentCurrencyBalance ? getters.currentCurrencyBalance.total : 0),
    currentCurrencyBalanceHold: (state, getters) => (getters.currentCurrencyBalance ? getters.currentCurrencyBalance.hold : 0),
    currentCurrencyBalanceFree: (state, getters) => (getters.currentCurrencyBalance ? getters.currentCurrencyBalance.free : 0),

    address: (state) => state.address,
    memo: (state) => state.memo,
    quantity: (state) => state.quantity,

    currentFees: (state) => state.currentFees,
    chosenFee: (state) => state.chosenFee,
    chosenFeeAmount: (state, getters) => (getters.chosenFee && getters.currentFees ? getters.currentFees[getters.chosenFee].blockchain.amount : null),
    isFeeUpdating: (state) => state.isFeeUpdating,

    currentWithdrawalMinSize: (state, getters) => (getters.currentCurrency ? getters.currentCurrency.withdrawalMinSize : 0),
    currentWithdrawalMaxSize: (state, getters, rootState, rootGetters) => rootGetters['Assets/GET_ASSET_QUANTITY_BY_QUOTE'](getters.currentCurrencySymbol, rootGetters['Accounts/activeAccountDailyTransferRemain']),

    quantityMaxSize: (state, getters) => Math.min(getters.currentCurrencyBalanceFree, getters.currentWithdrawalMaxSize),
};

const mutations = {
    SET_CURRENT_CURRENCY_INDEX(state, index) {
        state.currentCurrencyIndex = index;
    },
    SET_ADDRESS(state, index) {
        state.address = index;
    },
    SET_MEMO(state, memo) {
        state.memo = memo;
    },
    SET_QUANTITY(state, quantity) {
        state.quantity = quantity;
    },

    SET_CURRENT_FEES(state, currentFees) {
        state.currentFees = currentFees;
    },
    SET_CHOSEN_FEE(state, chosenFee) {
        state.chosenFee = chosenFee;
    },
    SET_IS_FEE_UPDATING(state, status) {
        state.isFeeUpdating = status;
    },
};

const actions = {
    setCurrentCurrencyIndex({ commit }, index) {
        commit('SET_CURRENT_CURRENCY_INDEX', index);
    },
    setAddress({ commit }, address) {
        commit('SET_ADDRESS', address);
    },
    setMemo({ commit }, memo) {
        commit('SET_MEMO', memo);
    },
    setQuantity({ commit }, quantity) {
        commit('SET_QUANTITY', quantity);
    },

    setCurrentFees({ commit }, currentFees) {
        commit('SET_CURRENT_FEES', currentFees);
    },
    setChosenFee({ commit }, chosenFee) {
        commit('SET_CHOSEN_FEE', chosenFee);
    },
    setIsFeeUpdating({ commit }, status) {
        commit('SET_IS_FEE_UPDATING', status);
    },

    /**
     *
     * @property {ActionContext} [vuexContext]
     * @property {object} withdrawData
     * @property {string} withdrawData.toAddress
     * @property {string} [withdrawData.memo]
     * @property {string} withdrawData.assetSymbol
     * @property {string} withdrawData.amount
     * @property {string} withdrawData.totp
     * @property {string} withdrawData.emailCode
     *
     * @return {Promise<{assetSymbol: *, fromAccountId: *, transferAmount: *, memo: *, toAddress: *}>}
     */

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
