// This file was autogenerated. Please do not change.
export interface IPoolInfo {
    aAssetPrice: string;
    aAssetReserve: string;
    bAssetPrice: string;
    bAssetReserve: string;
}

export default class PoolInfo {
    readonly _aAssetPrice: number;

    /**
     * Example: 100.25
     */
    get aAssetPrice(): number {
        return this._aAssetPrice;
    }

    readonly _aAssetReserve: number;

    /**
     * Example: 1000000.25
     */
    get aAssetReserve(): number {
        return this._aAssetReserve;
    }

    readonly _bAssetPrice: number;

    /**
     * Example: 100.25
     */
    get bAssetPrice(): number {
        return this._bAssetPrice;
    }

    readonly _bAssetReserve: number;

    /**
     * Example: 1000000.25
     */
    get bAssetReserve(): number {
        return this._bAssetReserve;
    }

    constructor(props: IPoolInfo) {
        this._aAssetPrice = Number(props.aAssetPrice);
        this._aAssetReserve = Number(props.aAssetReserve);
        this._bAssetPrice = Number(props.bAssetPrice);
        this._bAssetReserve = Number(props.bAssetReserve);
    }

    serialize(): IPoolInfo {
        const data: IPoolInfo = {
            aAssetPrice: String(this._aAssetPrice),
            aAssetReserve: String(this._aAssetReserve),
            bAssetPrice: String(this._bAssetPrice),
            bAssetReserve: String(this._bAssetReserve),
        };
        return data;
    }

    toJSON(): IPoolInfo {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetPrice: true,
            aAssetReserve: true,
            bAssetPrice: true,
            bAssetReserve: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
