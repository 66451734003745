/**
 * Time points interval constants
 *
 * Key format is a name of point
 * Value format is a Number of milliseconds
 */

/**
 * @type {Object.<string, number>} timeConstants
 *
 */
export const timeConstants = {
    MILLISECOND: 1,
    SECOND: 1000,
    MINUTE: 60000, // 60 * 1000
    HOUR: 3600000, // 60 * 60 * 1000
    DAY: 86400000, // 24 * 60 * 60 * 1000
    WEEK: 604800000, // 7 * 24 * 60 * 60 * 1000
    MONTH: 2635200000, // 30.5 * 24 * 60 * 60 * 1000
    QUARTER: 7905600000, // 3 * 30.5 * 24 * 60 * 60 * 1000
    YEAR: 31622400000, // 12 * 30.5 * 24 * 60 * 60 * 100
};
