
/* eslint-disable import/order */
import Vue from 'vue';

import Preset from 'Entities/userSettings/Preset';
import Notifications from './Notifications.vue';
import {
    doPreset,
    PRESETS_TYPES, previewPreset,
    removePreset,
    savePreset,
    updatePreset,
} from 'Store/v2/Presets';
import ApiError from 'Entities/ApiError';
import { required } from 'vuelidate/dist/validators.min';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { HIDE_MODAL, SHOW_MODAL } from 'Store/v2/Modal';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import { initSDK } from 'Store/v2/KYC';
import { composedPath } from 'Lib/utils/eventPathChecker';
import Icon from 'UI/Icon.vue';
import Button from 'Control/Button.vue';
import TextInput from 'Control/TextInput.vue';
import NoData from 'UI/NoData.vue';
import { startIntroByStep } from 'Store/v2/Intro';

interface Data {
    isMobileMenuOpen: boolean;
    showPresetsDropdown: boolean;
    selectedPreset: null | Preset;
    updatedPreset: null | Preset;
    presetName: string;
    filteredPresetsList: Preset[];
    searchedValue: string;
    isSearchActive: boolean;
    PRESETS_TYPES: any;
    isDefaultPresetActive: boolean;
    updatePresetError: boolean;
    savePresetError: boolean;
    disabledLinks: string[];
}
interface Methods {
    filterPresets: (data: Preset[]) => Preset[];
    openUpdatePresetModal: (data: Preset) => void;
    updatePreset: () => void;
    searchPresets: (data: string) => void;
    loadPresetsList: () => void;
    savePreset: () => void;
    removePreset: (data: Preset) => void;
    selectPreset: (event: any, data: Preset) => void;
    doPreset: () => void;
    hidePresetsDropdown: () => void;
    mobileLayoutTabsUpdate: (data: boolean) => void;
    logout: () => void;
    setLightTheme: () => void;
    setDarkTheme: () => void;
    processHideChat: () => void;
    openChat: () => void;
    clearSearch: () => void;
    onModalOpen: () => void;
    onModalClose: () => void;
    startKyc: () => void;
    updateIsDefaultPresetActive: () => void;
    openNewWorkspaceWindow: (data: Preset) => void;
    previewPreset: () => void;
    changeGridToActivePreset: () => void;
    openOnboarding: () => void;
    goTo: (data: string, name?: string) => void;
}
interface Computed {
    userEmail: string;
    navigationRoutes: any[];
    fullPresetsList: Preset[];
    isThemeDark: boolean;
    theme: string;
    currentUser: InternalUserResponse | undefined;
    isKycVerified: boolean;
    currentRoute: string;
    currentPath: string;
}

interface Props {
    hasMobileLayoutTabs: any,
}

export default Vue.extend<Data, Methods, Computed, Props>({
    name: 'Header',
    components: {
        Notifications,
        Icon,
        Button,
        TextInput,
        NoData,
    },
    props: {
        hasMobileLayoutTabs: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isMobileMenuOpen: false,
            showPresetsDropdown: false,
            PRESETS_TYPES,

            selectedPreset: null,
            updatedPreset: null,
            presetName: '',
            filteredPresetsList: [],
            searchedValue: '',
            isSearchActive: false,
            isDefaultPresetActive: false,
            updatePresetError: false,
            savePresetError: false,
            disabledLinks: [
                'earn',
            ],
        };
    },
    validations() {
        return {
            presetName: {
                required,
            },
        };
    },
    computed: {
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return true;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        userEmail() {
            return this.$store.getters['Auth/profileEmail'];
        },
        navigationRoutes() {
            // using `any` here because Vue's typedef appears to be wrong about $router
            return (this.$router as any).options.routes.filter((route) => route.meta.isInNavigation);
        },
        fullPresetsList() {
            const { presetsList, defaultPresetsList } = this.$store.state.Presets;
            if (!presetsList && !defaultPresetsList) {
                return [];
            }
            return [...defaultPresetsList || [], ...presetsList || []];
        },
        isThemeDark() {
            this.$forceUpdate();
            return this.$store.getters.isThemeDark;
        },
        theme() {
            this.$forceUpdate();
            return this.$store.getters.theme;
        },
        currentRoute() {
            return this.$router.currentRoute.name ?? '';
        },
        currentPath() {
            return this.$route.path;
        },
    },
    methods: {
        goTo(path, name) {
            if (this.$router.currentRoute.path !== path) {
                if (name !== undefined && this.disabledLinks.indexOf(name.toLowerCase()) !== -1) {
                    return;
                }
                this.$router.push(path);
            }
        },
        async startKyc() {
            await this.$store.dispatch(initSDK());
        },
        filterPresets(array) {
            return array.filter((el) => el.type === PRESETS_TYPES[this.$router.currentRoute.name!]);
        },
        openUpdatePresetModal(preset) {
            this.updatedPreset = preset;
            this.$modal.hide('presetsListModal');
            this.$modal.show('updatePresetModal');
        },
        async updatePreset() {
            try {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    await this.$store.dispatch(updatePreset({ preset: this.updatedPreset!, name: this.presetName }));
                    await this.$store.dispatch('Notificator/showSuccessNotification', `Preset "${this.presetName}" was successfully updated`);
                } else {
                    this.updatePresetError = true;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Something went wrong');
                }
            } finally {
                if (!this.$v.$invalid) {
                    this.$modal.hide('updatePresetModal');
                    this.presetName = '';
                    this.updatePresetError = false;
                }
            }
        },
        searchPresets(value) {
            this.searchedValue = value;
            this.isSearchActive = this.searchedValue !== '';
            if (!this.isSearchActive) {
                this.filteredPresetsList = [];
                return;
            }
            this.filteredPresetsList = this.fullPresetsList.filter((p) => p.name!.toLowerCase().indexOf(this.searchedValue.toLowerCase()) !== -1);
        },
        async loadPresetsList() {
            this.$modal.show('presetsListModal');
        },
        async savePreset() {
            try {
                this.$v.$touch();
                if (!this.$v.$invalid) {
                    await this.$store.dispatch(savePreset(this.presetName));
                    await this.$store.dispatch('Notificator/showSuccessNotification', `Preset "${this.presetName}" was successfully saved`);
                } else {
                    this.savePresetError = true;
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Something went wrong');
                }
            } finally {
                if (!this.$v.$invalid) {
                    this.$modal.hide('savePresetModal');
                    this.presetName = '';
                    this.savePresetError = false;
                }
            }
        },
        async removePreset(preset) {
            try {
                await this.$store.dispatch(removePreset(preset));
                await this.$store.dispatch('Notificator/showSuccessNotification', `Preset "${preset.name}" was successfully removed`);
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
                } else {
                    await this.$store.dispatch('Notificator/showErrorNotification', 'Something went wrong', { root: true });
                }
            }
        },
        async selectPreset(e, preset) {
            this.selectedPreset = preset;
            Array.from(document.querySelectorAll('.preset-row')).forEach((row) => {
                row.classList.remove('active');
            });
            const path = composedPath(e.target);
            path.forEach((el) => {
                if (el.classList) {
                    if (el.classList.value === 'preset-row') {
                        el.classList.add('active');
                    }
                }
            });
            await this.previewPreset();
        },
        async doPreset() {
            await this.$store.dispatch(doPreset({ preset: this.selectedPreset!, page: this.$router.currentRoute.name ?? undefined }));
            this.$modal.hide('presetsListModal');
        },
        async previewPreset() {
            await this.$store.dispatch(previewPreset(this.selectedPreset!));
        },
        hidePresetsDropdown() {
            this.showPresetsDropdown = false;
        },
        mobileLayoutTabsUpdate(hasContent) {
            this.$emit('change-mobile-layout-tabs', hasContent);
        },
        async logout() {
            this.$store.commit(SET_LOADING_ON(undefined));
            try {
                await this.$store.dispatch('Auth/logout');
                await this.goTo('/signin');
            } catch {
                document.location.reload();
            } finally {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        setLightTheme() {
            this.$store.dispatch('setLightTheme');
        },
        setDarkTheme() {
            this.$store.dispatch('setDarkTheme');
        },
        processHideChat() {
            const messengerContainer = document.querySelector<HTMLElement>('.engagebay-messenger-frame-enter-done');

            if (messengerContainer) {
                // if is open
                if (messengerContainer.style.width === '') {
                    messengerContainer.style.transform = 'translateX(0)';
                } else {
                    messengerContainer.style.transform = 'translateX(100%)';
                }
            }
        },
        openChat() {
            // eslint-disable-next-line no-undef
            EhLiveChat.open_chat();
            this.processHideChat();
        },
        async clearSearch() {
            this.searchedValue = '';
            this.isSearchActive = false;
            await this.changeGridToActivePreset();
        },
        async changeGridToActivePreset() {
            const activePreset = this.filterPresets(this.fullPresetsList).find(({ active, description }) => active || ((this.isDefaultPresetActive && description === 'default')));
            if (activePreset) {
                await this.$store.dispatch(doPreset({ preset: activePreset, page: this.$router.currentRoute.name ?? undefined }));
            }
        },
        onModalOpen() {
            this.$store.commit(SHOW_MODAL(undefined));
        },
        onModalClose() {
            this.$store.commit(HIDE_MODAL(undefined));
            this.updatePresetError = false;
            this.savePresetError = false;
        },
        updateIsDefaultPresetActive() {
            const presets = this.filterPresets(this.fullPresetsList);
            if (presets.length === 0) {
                this.isDefaultPresetActive = true;
            }
            this.isDefaultPresetActive = !presets.some(({ active }) => active);
        },
        openNewWorkspaceWindow(preset) {
            const routeData = this.$router.resolve({ path: '/workspace', query: { isNew: 'true', preset: String(preset.id) } });
            window.open(routeData.href);
        },
        async openOnboarding() {
            if (this.currentUser?.onboardingStatus !== undefined) {
                await this.$store.dispatch(startIntroByStep(this.currentUser.onboardingStatus + 1));
            }
        },
    },
    mounted() {
        this.processHideChat();
        this.$router.afterEach(() => {
            this.updateIsDefaultPresetActive();
        });
    },
    watch: {
        fullPresetsList() {
            this.updateIsDefaultPresetActive();
        },
        currentPath() {
            this.$modal.hide('presetsListModal');
            this.$modal.hide('savePresetModal');
            this.$modal.hide('updatePresetModal');
        },
    },
});
