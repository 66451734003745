import { AllActions, AllMutations, useAction, useMutation, ModuleNames } from 'Store';

export type Act<T = any> = {
    type: string,
    payload: T
};

export function actionCreator<T>(moduleName: ModuleNames, type: AllActions) {
    return function action(payload: T, local?: boolean): Act<T> {
        return {
            type: local ? type : useAction(moduleName, type),
            payload: payload as T,
        };
    };
}

export function mutationCreator<T>(moduleName: ModuleNames, type: AllMutations) {
    return function action(payload: T, local?: boolean): Act<T> {
        return {
            type: local ? type : useMutation(moduleName, type),
            payload: payload as T,
        };
    };
}

export function withQueue(action: (props: any, shouldStop: { shouldStop: boolean; }) => Promise<any>) {
    let isBusy = false;
    let callsToMake = 0;
    const stop = { shouldStop: false };
    return function enqueue(props: any) {
        if (isBusy) {
            stop.shouldStop = true;
            callsToMake += 1;
            return;
        }
        isBusy = true;
        action(props, stop).finally(() => {
            isBusy = false;
            stop.shouldStop = false;
            if (callsToMake > 0) {
                callsToMake -= 1;
                enqueue(props);
            }
        });
    };
}

// type GettersReturn<G extends { [key in TransferHistoryGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[TransferHistoryGetters]> };
// interface Getters {}
