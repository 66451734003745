import store from '@/store';
import { LS_FAVORITE_ASSET_PAIRS_FIELD, LS_ACTIVE_ASSET_PAIR_FIELD } from 'Const/localStorage';

/**
 * @typedef localStorageFavoriteAssetPair
 * @type Object
 *
 * @property {String} exchange - uppercased name of exchange
 * @property {String} pair - asset pair symbol
 */

export function getFavoriteAssetPairsList() {
    const pairsString = localStorage.getItem(LS_FAVORITE_ASSET_PAIRS_FIELD);
    let result: any[] = [];
    let currentItem = {};

    if (pairsString) {
        result = pairsString.split('&').map((item) => {
            currentItem = item.split('@');

            return {
                exchange: currentItem[0],
                pair: currentItem[1],
            };
        });
    }

    return result;
}

/**
 *
 * @param {Array.<localStorageFavoriteAssetPair>} list
 */

export function setFavoriteAssetPairsList(list) {
    localStorage.setItem(LS_FAVORITE_ASSET_PAIRS_FIELD, list.map((item) => `${item.exchange}@${item.pair}`).join('&'));
}

/**
 *
 * @param {String|Number} assetPairsId
 */
export function setLocalStorageActiveAssetPair(assetPairsId) {
    localStorage.setItem(LS_ACTIVE_ASSET_PAIR_FIELD, assetPairsId.toString());
}

/**
 *
 * @return {String}
 */
export function getLocalStorageActiveAssetPair() {
    return localStorage.getItem(LS_ACTIVE_ASSET_PAIR_FIELD);
}

/**
 *
 * @return {Boolean}
 */
export function hasLocalStorageActiveAssetPair() {
    // eslint-disable-next-line no-prototype-builtins
    return localStorage.hasOwnProperty(LS_ACTIVE_ASSET_PAIR_FIELD);
}

export function parseTickerData(ticker) {
    return {
        price: +ticker.price,
        high: +ticker.high,
        low: +ticker.low,
        volume24h: +ticker.volume,
        volume24h_usd: 0,
        percentChange: (+ticker.percentChange).round(),
    };
}

export class AssetPair {
    id

    symbol

    baseAssetId

    baseAssetPlacementSymbol

    baseAssetSymbol

    quoteAssetId

    quoteAssetPlacementSymbol

    quoteAssetSymbol

    placementName

    placementSymbol

    placementPrecisionQuantity

    placementPrecisionPrice

    placementAttributes

    baseAssetQuotation

    quoteAssetQuotation

    contractPrice

    placementLeverages

    ticker: any;

    constructor(data) {
        this.id = data.id;
        this.symbol = data.symbol;

        this.baseAssetId = data.baseAssetId;
        this.baseAssetPlacementSymbol = data.baseAssetPlacementSymbol;
        this.baseAssetSymbol = data.baseAssetSymbol;

        this.quoteAssetId = data.quoteAssetId;
        this.quoteAssetPlacementSymbol = data.quoteAssetPlacementSymbol;
        this.quoteAssetSymbol = data.quoteAssetSymbol;

        this.placementName = data.placementName;
        this.placementSymbol = data.placementSymbol;

        this.placementPrecisionQuantity = data.placementPrecisionQuantity;
        this.placementPrecisionPrice = data.placementPrecisionPrice;

        this.placementAttributes = data.placementAttributes;

        this.contractPrice = data.contractPrice;
        this.placementLeverages = data.placementLeverages;

        this.updateQuotations();
    }

    setTicker(ticker) {
        this.ticker = ticker;
        this.updateVolume24hUsd();
    }

    updateVolume24hUsd() {
        if (this.ticker) {
            this.ticker.volume24h_usd = this.getQuoteQuotation(+this.ticker.volume24h);
        }
    }

    updateQuotations() {
        const baseAssetQuotation = store.getters['Assets/GET_ASSET_QUOTATION_BY_SYMBOL'](this.baseAssetSymbol);
        const quoteAssetQuotation = store.getters['Assets/GET_ASSET_QUOTATION_BY_SYMBOL'](this.quoteAssetSymbol);

        if (typeof baseAssetQuotation === 'number' && !Number.isNaN(baseAssetQuotation)) {
            this.baseAssetQuotation = baseAssetQuotation;
        }

        if (typeof quoteAssetQuotation === 'number' && !Number.isNaN(quoteAssetQuotation)) {
            this.quoteAssetQuotation = quoteAssetQuotation;
        }

        this.updateVolume24hUsd();
    }

    getBaseQuotation(quantity) {
        return quantity * this.baseAssetQuotation;
    }

    getQuoteQuotation(quantity) {
        return quantity * this.quoteAssetQuotation;
    }
}
