import ApiError from 'Entities/ApiError';
import AssignAccountPayload from 'Entities/groupManagement/AssignAccountPayload';
import BaseGroupPayload from 'Entities/groupManagement/BaseGroupPayload';
import BaseGroupRequestPayload from 'Entities/groupManagement/BaseGroupRequestPayload';
import BaseGroupRequestResponse from 'Entities/groupManagement/BaseGroupRequestResponse';
import BaseGroupResponse from 'Entities/groupManagement/BaseGroupResponse';
import CreateGroupPayload from 'Entities/groupManagement/CreateGroupPayload';
import GroupMemberPayload from 'Entities/groupManagement/GroupMemberPayload';
import GroupMemberResponse from 'Entities/groupManagement/GroupMemberResponse';
import InviteGroupMemberPayload from 'Entities/groupManagement/InviteGroupMemberPayload';
import MFAGroupPayload from 'Entities/groupManagement/MFAGroupPayload';
import RevokeAccountPayload from 'Entities/groupManagement/RevokeAccountPayload';
import ServiceStatus from 'Entities/groupManagement/ServiceStatus';
import UpdateGroupPayload from 'Entities/groupManagement/UpdateGroupPayload';
import UpdateMemberPayload from 'Entities/groupManagement/UpdateMemberPayload';
import Group from 'Entities/privatePresenter/Group';
import GroupParams from 'Entities/privatePresenter/GroupParams';
import GroupRequest from 'Entities/privatePresenter/GroupRequest';
import GroupRequestParams from 'Entities/privatePresenter/GroupRequestParams';
import GroupRequestsParams from 'Entities/privatePresenter/GroupRequestsParams';
import GroupsParams from 'Entities/privatePresenter/GroupsParams';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class GroupsApi {
    static async assignAccount(assignaccountpayload: AssignAccountPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...assignaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('assignAccount', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/assignAccount`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(assignaccountpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/assignAccount', { response: res, data: result, fields: [JSON.stringify({ assignaccountpayload })] });
    }

    static async cancelGroupRequest(basegrouprequestpayload: BaseGroupRequestPayload, withHeaders?: boolean): Promise<{ data: BaseGroupRequestResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...basegrouprequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('cancelGroupRequest', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/requests/cancel`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(basegrouprequestpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new BaseGroupRequestResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/cancelGroupRequest', { response: res, data: result, fields: [JSON.stringify({ basegrouprequestpayload })] });
    }

    static async confirmGroupRequest(basegrouprequestpayload: BaseGroupRequestPayload, withHeaders?: boolean): Promise<{ data: BaseGroupRequestResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...basegrouprequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('confirmGroupRequest', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/requests/confirm`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(basegrouprequestpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new BaseGroupRequestResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/confirmGroupRequest', { response: res, data: result, fields: [JSON.stringify({ basegrouprequestpayload })] });
    }

    static async createGroup(creategrouppayload: CreateGroupPayload, withHeaders?: boolean): Promise<{ data: BaseGroupResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...creategrouppayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('createGroup', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/create`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(creategrouppayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BaseGroupResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/createGroup', { response: res, data: result, fields: [JSON.stringify({ creategrouppayload })] });
    }

    static async createGroupRequest(invitegroupmemberpayload: InviteGroupMemberPayload, withHeaders?: boolean): Promise<{ data: BaseGroupRequestResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...invitegroupmemberpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('createGroupRequest', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/requests/create`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(invitegroupmemberpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BaseGroupRequestResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/createGroupRequest', { response: res, data: result, fields: [JSON.stringify({ invitegroupmemberpayload })] });
    }

    static async deleteGroup(mfagrouppayload: MFAGroupPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...mfagrouppayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('deleteGroup', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/delete`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(mfagrouppayload.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/deleteGroup', { response: res, data: result, fields: [JSON.stringify({ mfagrouppayload })] });
    }

    static async getGroupInformation(basegrouppayload: BaseGroupPayload, withHeaders?: boolean): Promise<{ data: GroupMemberResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...basegrouppayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getGroupInformation', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/getInfo`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(basegrouppayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new GroupMemberResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/getGroupInformation', { response: res, data: result, fields: [JSON.stringify({ basegrouppayload })] });
    }

    static async leaveGroup(basegrouppayload: BaseGroupPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...basegrouppayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('leaveGroup', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/leave`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(basegrouppayload.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/leaveGroup', { response: res, data: result, fields: [JSON.stringify({ basegrouppayload })] });
    }

    static async rejectGroupRequest(basegrouprequestpayload: BaseGroupRequestPayload, withHeaders?: boolean): Promise<{ data: BaseGroupRequestResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...basegrouprequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('rejectGroupRequest', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/requests/reject`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(basegrouprequestpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new BaseGroupRequestResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/rejectGroupRequest', { response: res, data: result, fields: [JSON.stringify({ basegrouprequestpayload })] });
    }

    static async removeGroupMember(groupmemberpayload: GroupMemberPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...groupmemberpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('removeGroupMember', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/removeMember`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(groupmemberpayload.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/removeGroupMember', { response: res, data: result, fields: [JSON.stringify({ groupmemberpayload })] });
    }

    static async revokeAccount(revokeaccountpayload: RevokeAccountPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...revokeaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('revokeAccount', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/revokeAccount`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(revokeaccountpayload.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/revokeAccount', { response: res, data: result, fields: [JSON.stringify({ revokeaccountpayload })] });
    }

    static async updateGroup(updategrouppayload: UpdateGroupPayload, withHeaders?: boolean): Promise<{ data: BaseGroupResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updategrouppayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('updateGroup', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/update`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updategrouppayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new BaseGroupResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/updateGroup', { response: res, data: result, fields: [JSON.stringify({ updategrouppayload })] });
    }

    static async updateGroupMember(updatememberpayload: UpdateMemberPayload, withHeaders?: boolean): Promise<{ data: GroupMemberResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updatememberpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('updateGroupMember', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/groups/updateMember`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updatememberpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new GroupMemberResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/updateGroupMember', { response: res, data: result, fields: [JSON.stringify({ updatememberpayload })] });
    }

    static async privateGetGroup(groupparams: GroupParams, withHeaders?: boolean): Promise<{ data: Group; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...groupparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetGroup', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getGroup`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(groupparams.serialize()),
        });
        if (res.status === 200) {
            return { data: new Group(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/privateGetGroup', { response: res, data: result, fields: [JSON.stringify({ groupparams })] });
    }

    static async privateGetGroupRequest(grouprequestparams: GroupRequestParams, withHeaders?: boolean): Promise<{ data: GroupRequest; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...grouprequestparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetGroupRequest', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getGroupRequest`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(grouprequestparams.serialize()),
        });
        if (res.status === 200) {
            return { data: new GroupRequest(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/privateGetGroupRequest', { response: res, data: result, fields: [JSON.stringify({ grouprequestparams })] });
    }

    static async privateGetGroupRequests(grouprequestsparams: GroupRequestsParams, withHeaders?: boolean): Promise<{ data: GroupRequest[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...grouprequestsparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetGroupRequests', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getGroupRequests`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(grouprequestsparams.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new GroupRequest(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/privateGetGroupRequests', { response: res, data: result, fields: [JSON.stringify({ grouprequestsparams })] });
    }

    static async privateGetGroups(groupsparams: GroupsParams, withHeaders?: boolean): Promise<{ data: Group[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...groupsparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetGroups', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getGroups`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(groupsparams.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Group(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Groups/privateGetGroups', { response: res, data: result, fields: [JSON.stringify({ groupsparams })] });
    }
}
