// This file was autogenerated. Please do not change.
export interface IAssignedAccount {
    accountId?: string;
    policies?: string[];
}

export default class AssignedAccount {
    readonly _accountId: string | undefined;

    /** Description: Account unique identifier */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _policies: string[] | undefined;

    /** Description: List of allowed permissions */
    get policies(): string[] | undefined {
        return this._policies;
    }

    constructor(props: IAssignedAccount) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
    }

    serialize(): IAssignedAccount {
        const data: IAssignedAccount = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        return data;
    }

    toJSON(): IAssignedAccount {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
