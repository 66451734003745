import Vue from 'vue';
import VueCookies from 'vue-cookies';
import VTooltip from 'v-tooltip';
import VueTheMask from 'vue-the-mask';
import vClickOutside from 'v-click-outside';
import VCalendar from 'v-calendar';
import Cryptoicon from 'vue-cryptoicon';
import VueDebounce from 'vue-debounce';
import Vuelidate from 'vuelidate';
import VueCurrencyInput from 'vue-currency-input';
import 'chartjs-plugin-datalabels';
import 'chartjs-plugin-piechart-outlabels';
import PortalVue from 'portal-vue';
import icons from 'vue-cryptoicon/src/icons';

Vue.use(VTooltip);
Vue.use(VueCookies);
Vue.use(VueTheMask);
Vue.use(vClickOutside);
Vue.use(VCalendar);
Vue.use(Cryptoicon);
Vue.use(VueDebounce, {
    listenTo: ['change'],
    defaultTime: '500ms',
});
Vue.use(Vuelidate);
Vue.use(VueCurrencyInput);
Vue.use(PortalVue);

Cryptoicon.add(icons);
