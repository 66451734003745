<template>
    <div :class="['paginator', {'is-disabled': disabled}]">

        <button :class="['paginator-back', 'paginator-arrow', {'inactive': page === 1}]"
                @click="setPreviousPage"
        >
            <img src="@/assets/images/icons/ArrowDown_light.svg"
                 alt="arrow left"
                 v-if="$store.getters.isThemeDark"
            />

            <img src="@/assets/images/icons/ArrowDown.svg"
                 alt="arrow left"
                 v-else
            />
        </button>

        <div class="paginator-labels-container">

            <button :class="['paginator-label', {'active': page === 1}]"
                    @click="setActivePage(1)"
            >
                1
            </button>

            <span class="paginator-labels-separator" v-if="showStartSeparator"></span>

            <template v-for="(pageNumber) in pagesQuantity > 1 ? (pagesQuantity - 2) : 0">

                <button :key="pageNumber"
                        :class="['paginator-label', {'active': pageNumber + 1 === page}]"
                        v-if="showPageLabel(pageNumber + 1)"
                        @click="setActivePage(pageNumber + 1)"
                >
                    {{ pageNumber + 1 }}
                </button>

            </template>

            <span class="paginator-labels-separator" v-if="showEndSeparator"></span>

            <button :class="['paginator-label', {'active': page === pagesQuantity}]"
                    @click="setActivePage(pagesQuantity)"
                    v-if="pagesQuantity > 1"
            >
                {{ pagesQuantity }}
            </button>

        </div>

        <button :class="['paginator-forth', 'paginator-arrow', {'inactive': page === pagesQuantity}]"
                @click="setNextPage"
        >
            <img src="@/assets/images/icons/ArrowDown_light.svg"
                 alt="arrow left"
                 v-if="$store.getters.isThemeDark"
            />

            <img src="@/assets/images/icons/ArrowDown.svg"
                 alt="arrow left"
                 v-else
            />
        </button>

    </div>
</template>

<script>
export default {
    name: 'Paginator',
    model: {
        prop: 'page',
        event: 'change',
    },
    props: {
        page: {
            type: Number,
            default: 1,
        },
        pagesQuantity: {
            type: Number,
            default: 1,
        },
        labelsQuantity: {
            type: Number,
            default: 3,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        showStartSeparator() {
            return this.page >= this.labelsQuantity + 2;
        },
        showEndSeparator() {
            return this.page <= this.pagesQuantity - (this.labelsQuantity + 1);
        },
    },
    methods: {
        setActivePage(pageNumber) {
            if (pageNumber !== this.page) {
                this.$emit('change', pageNumber);
            }
        },
        showPageLabel(pageNumber) {
            return pageNumber - this.page < this.labelsQuantity && this.page - pageNumber < this.labelsQuantity;
        },
        setPreviousPage() {
            if (this.page > 1) {
                this.setActivePage(this.page - 1);
            }
        },
        setNextPage() {
            if (this.page < this.pagesQuantity) {
                this.setActivePage(this.page + 1);
            }
        },
    },
};
</script>
