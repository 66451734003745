import { mutationCreator } from 'Store/utils';

const state = {
    isLoading: false,
    isShadowShown: false,
};

export type PreloaderState = typeof state;

export enum PreloaderGetters {}

const getters: Record<PreloaderGetters, (state: PreloaderState, ...args: any) => void> = {};

export enum PreloaderMutations {
    SET_LOADING_ON = 'SET_LOADING_ON',
    SET_LOADING_OFF = 'SET_LOADING_OFF',
    SHOW_SHADOW = 'SHOW_SHADOW',
    HIDE_SHADOW = 'HIDE_SHADOW',
}

export const SET_LOADING_ON = mutationCreator<void>('Preloader', PreloaderMutations.SET_LOADING_ON);
export const SET_LOADING_OFF = mutationCreator<void>('Preloader', PreloaderMutations.SET_LOADING_OFF);
export const SHOW_SHADOW = mutationCreator<void>('Preloader', PreloaderMutations.SHOW_SHADOW);
export const HIDE_SHADOW = mutationCreator<void>('Preloader', PreloaderMutations.HIDE_SHADOW);

const mutations: Record<PreloaderMutations, (state: PreloaderState, ...args: any) => void> = {
    SET_LOADING_ON(state) {
        document.getElementById('mainBlock')!.classList.add('loader-opacity');
        state.isLoading = true;
    },
    SET_LOADING_OFF(state) {
        document.getElementById('mainBlock')!.classList.remove('loader-opacity');
        state.isLoading = false;
    },
    SHOW_SHADOW(state) {
        state.isShadowShown = true;
    },
    HIDE_SHADOW(state) {
        state.isShadowShown = false;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
