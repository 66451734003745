import SpotTradeQuotation, { ISpotTradeQuotation } from './SpotTradeQuotation';

// This file was autogenerated. Please do not change.
export interface ISpotTrade {
    accountId: string;
    baseAssetId: number;
    baseAssetSymbol: string;
    baseBalanceId: string;
    baseQuantity: string;
    commissionAssetId: number;
    commissionAssetSymbol: string;
    commissionQuantity: string;
    createdAt: string;
    id: string;
    isBuy: boolean;
    orderId: string;
    orderInitiatorType?: string;
    orderType: ('LIMIT' | 'MARKET' | 'LIMIT_STOP_LOSS' | 'LIMIT_FOK' | 'LIMIT_GTC' | 'LIMIT_IOC' | 'MARKET_STOP' | 'LIMIT_STOP_GTC' | 'LIMIT_STOP_FOK' | 'LIMIT_STOP_IOC');
    outerId: string;
    outerTimestamp: string;
    placementId: number;
    placementName: string;
    platformCommissionQuantity: string;
    price: string;
    quotations?: ISpotTradeQuotation[];
    quoteAssetId: number;
    quoteAssetSymbol: string;
    quoteBalanceId: string;
    quoteQuantity: string;
    spotAssetPairId: number;
    spotAssetPairSymbol: string;
    spotOrderPrice: string;
    updatedAt: string;
}

export default class SpotTrade {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _baseAssetId: number;

    /**
     * Example: 311
     */
    get baseAssetId(): number {
        return this._baseAssetId;
    }

    readonly _baseAssetSymbol: string;

    /**
     * Example: UNI
     */
    get baseAssetSymbol(): string {
        return this._baseAssetSymbol;
    }

    readonly _baseBalanceId: string;

    get baseBalanceId(): string {
        return this._baseBalanceId;
    }

    readonly _baseQuantity: number;

    /**
     * Example: 0.65
     */
    get baseQuantity(): number {
        return this._baseQuantity;
    }

    readonly _commissionAssetId: number;

    /**
     * Example: 7
     */
    get commissionAssetId(): number {
        return this._commissionAssetId;
    }

    readonly _commissionAssetSymbol: string;

    /**
     * Example: USDT
     */
    get commissionAssetSymbol(): string {
        return this._commissionAssetSymbol;
    }

    readonly _commissionQuantity: number;

    /**
     * Example: 0.020058
     */
    get commissionQuantity(): number {
        return this._commissionQuantity;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _id: string;

    /**
     * Example: TTNZ5E2RF1HV29J1FJVWQ
     */
    get id(): string {
        return this._id;
    }

    readonly _isBuy: boolean;

    get isBuy(): boolean {
        return this._isBuy;
    }

    readonly _orderId: string;

    /**
     * Example: OVRVXFN8DTUR8PGX8FXT9
     */
    get orderId(): string {
        return this._orderId;
    }

    readonly _orderInitiatorType: string | undefined;

    /**
     * Example: users
     */
    get orderInitiatorType(): string | undefined {
        return this._orderInitiatorType;
    }

    readonly _orderType: ('LIMIT' | 'MARKET' | 'LIMIT_STOP_LOSS' | 'LIMIT_FOK' | 'LIMIT_GTC' | 'LIMIT_IOC' | 'MARKET_STOP' | 'LIMIT_STOP_GTC' | 'LIMIT_STOP_FOK' | 'LIMIT_STOP_IOC');

    /**
     * Example: LIMIT
     */
    get orderType(): ('LIMIT' | 'MARKET' | 'LIMIT_STOP_LOSS' | 'LIMIT_FOK' | 'LIMIT_GTC' | 'LIMIT_IOC' | 'MARKET_STOP' | 'LIMIT_STOP_GTC' | 'LIMIT_STOP_FOK' | 'LIMIT_STOP_IOC') {
        return this._orderType;
    }

    readonly _outerId: string;

    /**
     * Example: 37791802
     */
    get outerId(): string {
        return this._outerId;
    }

    readonly _outerTimestamp: string;

    /**
     * Example: 2021-04-20T14:04:06.38Z
     */
    get outerTimestamp(): string {
        return this._outerTimestamp;
    }

    readonly _placementId: number;

    /**
     * Example: 2
     */
    get placementId(): number {
        return this._placementId;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _platformCommissionQuantity: number;

    /**
     * Example: 0
     */
    get platformCommissionQuantity(): number {
        return this._platformCommissionQuantity;
    }

    readonly _price: number;

    /**
     * Example: 30.8572
     */
    get price(): number {
        return this._price;
    }

    readonly _quotations: SpotTradeQuotation[] | undefined;

    get quotations(): SpotTradeQuotation[] | undefined {
        return this._quotations;
    }

    readonly _quoteAssetId: number;

    /**
     * Example: 7
     */
    get quoteAssetId(): number {
        return this._quoteAssetId;
    }

    readonly _quoteAssetSymbol: string;

    /**
     * Example: USDT
     */
    get quoteAssetSymbol(): string {
        return this._quoteAssetSymbol;
    }

    readonly _quoteBalanceId: string;

    /**
     * Example: B4V2D6AZAH3
     */
    get quoteBalanceId(): string {
        return this._quoteBalanceId;
    }

    readonly _quoteQuantity: number;

    /**
     * Example: 20.05718
     */
    get quoteQuantity(): number {
        return this._quoteQuantity;
    }

    readonly _spotAssetPairId: number;

    /**
     * Example: 14
     */
    get spotAssetPairId(): number {
        return this._spotAssetPairId;
    }

    readonly _spotAssetPairSymbol: string;

    /**
     * Example: UNI/USDT
     */
    get spotAssetPairSymbol(): string {
        return this._spotAssetPairSymbol;
    }

    readonly _spotOrderPrice: number;

    /**
     * Example: 30.8371
     */
    get spotOrderPrice(): number {
        return this._spotOrderPrice;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: ISpotTrade) {
        this._accountId = props.accountId.trim();
        this._baseAssetId = props.baseAssetId;
        this._baseAssetSymbol = props.baseAssetSymbol.trim();
        this._baseBalanceId = props.baseBalanceId.trim();
        this._baseQuantity = Number(props.baseQuantity);
        this._commissionAssetId = props.commissionAssetId;
        this._commissionAssetSymbol = props.commissionAssetSymbol.trim();
        this._commissionQuantity = Number(props.commissionQuantity);
        this._createdAt = props.createdAt.trim();
        this._id = props.id.trim();
        this._isBuy = props.isBuy;
        this._orderId = props.orderId.trim();
        if (typeof props.orderInitiatorType === 'string') {
            this._orderInitiatorType = props.orderInitiatorType.trim();
        }
        this._orderType = props.orderType;
        this._outerId = props.outerId.trim();
        this._outerTimestamp = props.outerTimestamp.trim();
        this._placementId = props.placementId;
        this._placementName = props.placementName.trim();
        this._platformCommissionQuantity = Number(props.platformCommissionQuantity);
        this._price = Number(props.price);
        if (props.quotations) {
            this._quotations = props.quotations.map((p) => new SpotTradeQuotation(p));
        }
        this._quoteAssetId = props.quoteAssetId;
        this._quoteAssetSymbol = props.quoteAssetSymbol.trim();
        this._quoteBalanceId = props.quoteBalanceId.trim();
        this._quoteQuantity = Number(props.quoteQuantity);
        this._spotAssetPairId = props.spotAssetPairId;
        this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        this._spotOrderPrice = Number(props.spotOrderPrice);
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): ISpotTrade {
        const data: ISpotTrade = {
            accountId: this._accountId,
            baseAssetId: this._baseAssetId,
            baseAssetSymbol: this._baseAssetSymbol,
            baseBalanceId: this._baseBalanceId,
            baseQuantity: String(this._baseQuantity),
            commissionAssetId: this._commissionAssetId,
            commissionAssetSymbol: this._commissionAssetSymbol,
            commissionQuantity: String(this._commissionQuantity),
            createdAt: this._createdAt,
            id: this._id,
            isBuy: this._isBuy,
            orderId: this._orderId,
            orderType: this._orderType,
            outerId: this._outerId,
            outerTimestamp: this._outerTimestamp,
            placementId: this._placementId,
            placementName: this._placementName,
            platformCommissionQuantity: String(this._platformCommissionQuantity),
            price: String(this._price),
            quoteAssetId: this._quoteAssetId,
            quoteAssetSymbol: this._quoteAssetSymbol,
            quoteBalanceId: this._quoteBalanceId,
            quoteQuantity: String(this._quoteQuantity),
            spotAssetPairId: this._spotAssetPairId,
            spotAssetPairSymbol: this._spotAssetPairSymbol,
            spotOrderPrice: String(this._spotOrderPrice),
            updatedAt: this._updatedAt,
        };
        if (typeof this._orderInitiatorType !== 'undefined') {
            data.orderInitiatorType = this._orderInitiatorType;
        }
        if (typeof this._quotations !== 'undefined') {
            data.quotations = this._quotations.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): ISpotTrade {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            baseAssetId: typeof this._baseAssetId === 'number',
            baseAssetSymbol: typeof this._baseAssetSymbol === 'string' && !!this._baseAssetSymbol.trim(),
            baseBalanceId: typeof this._baseBalanceId === 'string' && !!this._baseBalanceId.trim(),
            baseQuantity: true,
            commissionAssetId: typeof this._commissionAssetId === 'number',
            commissionAssetSymbol: typeof this._commissionAssetSymbol === 'string' && !!this._commissionAssetSymbol.trim(),
            commissionQuantity: true,
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            id: typeof this._id === 'string' && !!this._id.trim(),
            isBuy: typeof this._isBuy === 'boolean',
            orderId: typeof this._orderId === 'string' && !!this._orderId.trim(),
            orderInitiatorType: !this._orderInitiatorType ? true : typeof this._orderInitiatorType === 'string',
            outerId: typeof this._outerId === 'string' && !!this._outerId.trim(),
            outerTimestamp: typeof this._outerTimestamp === 'string' && !!this._outerTimestamp.trim(),
            placementId: typeof this._placementId === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            platformCommissionQuantity: true,
            price: true,
            quotations: !this._quotations ? true : this._quotations.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            quoteAssetId: typeof this._quoteAssetId === 'number',
            quoteAssetSymbol: typeof this._quoteAssetSymbol === 'string' && !!this._quoteAssetSymbol.trim(),
            quoteBalanceId: typeof this._quoteBalanceId === 'string' && !!this._quoteBalanceId.trim(),
            quoteQuantity: true,
            spotAssetPairId: typeof this._spotAssetPairId === 'number',
            spotAssetPairSymbol: typeof this._spotAssetPairSymbol === 'string' && !!this._spotAssetPairSymbol.trim(),
            spotOrderPrice: true,
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
