import Vue from 'vue';
import Router from 'vue-router';

import store from '../store';

import routes from './routes';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path !== '/signup-confirm' && store.getters['Auth/isLogged'] && !store.getters['Auth/isEmailVerified']) {
        next('/signup-confirm');
    } else if (to.matched.some((route) => !route.meta.is_auth) && !store.getters['Auth/isLogged']) {
        if (store.getters['Auth/isInitialized']) {
            next('/signin');
        } else {
            store.dispatch('VuexEventListener/addActionListener', {
                type: 'Auth/setIsInitialized',
                once: true,
                callback: () => {
                    if (!store.getters['Auth/isLogged']) {
                        next('/signin');
                    } else {
                        next();
                    }
                },
            });
        }
    } else {
        next();
    }
});

export default router;
