// This file was autogenerated. Please do not change.
export interface ILeverages {
    level?: string;
    max_value?: string;
    min_value?: string;
}

export default class Leverages {
    readonly _level: number | undefined;

    /**
     * Example: 5
     */
    get level(): number | undefined {
        return this._level;
    }

    readonly _max_value: number | undefined;

    /**
     * Example: 5000000.0
     */
    get maxValue(): number | undefined {
        return this._max_value;
    }

    readonly _min_value: number | undefined;

    /**
     * Example: 0.01
     */
    get minValue(): number | undefined {
        return this._min_value;
    }

    constructor(props: ILeverages) {
        if (typeof props.level === 'string') {
            this._level = Number(props.level);
        }
        if (typeof props.max_value === 'string') {
            this._max_value = Number(props.max_value);
        }
        if (typeof props.min_value === 'string') {
            this._min_value = Number(props.min_value);
        }
    }

    serialize(): ILeverages {
        const data: ILeverages = {
        };
        if (typeof this._level !== 'undefined') {
            data.level = String(this._level);
        }
        if (typeof this._max_value !== 'undefined') {
            data.max_value = String(this._max_value);
        }
        if (typeof this._min_value !== 'undefined') {
            data.min_value = String(this._min_value);
        }
        return data;
    }

    toJSON(): ILeverages {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            level: true,
            max_value: true,
            min_value: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
