// This file was autogenerated. Please do not change.
export interface IFuturesTrade {
    commissionQuantity?: string;
    contractSymbol?: string;
    createdAt?: string;
    id?: string;
    placementName?: string;
    price?: string;
    quantity?: string;
    side?: ('BUY' | 'SELL');
    totalQuantity?: string;
    type?: string;
}

export default class FuturesTrade {
    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 0.00194215
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _contractSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string | undefined {
        return this._contractSymbol;
    }

    readonly _createdAt: string | undefined;

    /**
     * Example: 2022-04-21T10:08:23.516666Z
     */
    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _id: string | undefined;

    /**
     * Example: FTDQ7DCMHAF5TL7FPWSS3C1
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance Futures
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _price: string | undefined;

    /**
     * Example: 13.31266445
     */
    get price(): string | undefined {
        return this._price;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 12.25586354
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _side: ('BUY' | 'SELL') | undefined;

    /**
     * Example: SELL
     */
    get side(): ('BUY' | 'SELL') | undefined {
        return this._side;
    }

    readonly _totalQuantity: number | undefined;

    /**
     * Example: 12.25392139
     */
    get totalQuantity(): number | undefined {
        return this._totalQuantity;
    }

    readonly _type: string | undefined;

    /**
     * Example: LIMIT_STOP
     */
    get type(): string | undefined {
        return this._type;
    }

    constructor(props: IFuturesTrade) {
        if (typeof props.commissionQuantity === 'string') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.contractSymbol === 'string') {
            this._contractSymbol = props.contractSymbol.trim();
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.price === 'string') {
            this._price = props.price.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (props.side) {
            this._side = props.side;
        }
        if (typeof props.totalQuantity === 'string') {
            this._totalQuantity = Number(props.totalQuantity);
        }
        if (typeof props.type === 'string') {
            this._type = props.type.trim();
        }
    }

    serialize(): IFuturesTrade {
        const data: IFuturesTrade = {
        };
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = String(this._commissionQuantity);
        }
        if (typeof this._contractSymbol !== 'undefined') {
            data.contractSymbol = this._contractSymbol;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._price !== 'undefined') {
            data.price = this._price;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        if (typeof this._totalQuantity !== 'undefined') {
            data.totalQuantity = String(this._totalQuantity);
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IFuturesTrade {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            commissionQuantity: true,
            contractSymbol: !this._contractSymbol ? true : typeof this._contractSymbol === 'string',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            id: !this._id ? true : typeof this._id === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            price: !this._price ? true : typeof this._price === 'string',
            quantity: true,
            totalQuantity: true,
            type: !this._type ? true : typeof this._type === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
