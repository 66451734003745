
import Vue from 'vue';
import { nanoid } from 'nanoid';

import { composedPath } from 'Lib/utils/eventPathChecker';

type Props = {
    text: string;
    size?: number;
};

type Data = {
    showTooltip: boolean;
    uniqueId: string;
};

type Methods = {
    setShowTooltip: (data: boolean) => void;
    closeTooltip: () => void;
};

type NoData = Record<string, never>;

export default Vue.extend<Data, Methods, NoData, Props>({
    props: {
        text: {
            type: String,
            required: true,
        },
        size: {
            type: Number,
            default: 16,
        },
    },
    data() {
        return {
            showTooltip: false,
            uniqueId: '',
        };
    },
    methods: {
        setShowTooltip(isShown) {
            this.showTooltip = isShown;
        },
        closeTooltip() {
            let flag = true;
            // eslint-disable-next-line no-restricted-globals
            const path = composedPath(event?.target);
            if (path) {
                path.forEach((el) => {
                    if (el.classList && Array.from(el.classList).includes('info-tooltip')) {
                        if (el.id === this.uniqueId) {
                            flag = false;
                        }
                    }
                });
                if (flag) {
                    this.showTooltip = false;
                }
            }
        },
    },
    mounted() {
        this.uniqueId = nanoid(16);
        document.addEventListener('click', this.closeTooltip);
    },
    beforeDestroy() {
        document.removeEventListener('click', this.closeTooltip);
    },
});
