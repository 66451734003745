const composedPath = (el: any): any[] => {
    const path: any[] = [];
    let counter = 0;
    while (el) {
        if (counter === 1000) {
            return path;
        }
        counter += 1;
        path.push(el);
        if (el.tagName === 'HTML' || !el.parentElement) {
            path.push(document);
            path.push(window);
            return path;
        }
        el = el.parentElement;
    }
    return path;
};

export { composedPath };
