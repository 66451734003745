import ApiError from 'Entities/ApiError';
import StakingPosition from 'Entities/privatePresenter/StakingPosition';
import StakingPositionsRequest from 'Entities/privatePresenter/StakingPositionsRequest';
import StakingTransaction from 'Entities/privatePresenter/StakingTransaction';
import StakingTransactionsRequest from 'Entities/privatePresenter/StakingTransactionsRequest';
import GetQuotaRequest from 'Entities/staking/GetQuotaRequest';
import GetQuotaResponse from 'Entities/staking/GetQuotaResponse';
import Position from 'Entities/staking/Position';
import PurchaseRequest from 'Entities/staking/PurchaseRequest';
import RedeemRequest from 'Entities/staking/RedeemRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class StakingApi {
    static async privateGetStakingPositions(stakingpositionsrequest: StakingPositionsRequest, withHeaders?: boolean): Promise<{ data: StakingPosition[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...stakingpositionsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetStakingPositions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getStakingPositions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(stakingpositionsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new StakingPosition(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Staking/privateGetStakingPositions', { response: res, data: result, fields: [JSON.stringify({ stakingpositionsrequest })] });
    }

    static async privateGetStakingTransactions(stakingtransactionsrequest: StakingTransactionsRequest, withHeaders?: boolean): Promise<{ data: StakingTransaction[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...stakingtransactionsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetStakingTransactions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getStakingTransactions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(stakingtransactionsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new StakingTransaction(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Staking/privateGetStakingTransactions', { response: res, data: result, fields: [JSON.stringify({ stakingtransactionsrequest })] });
    }

    static async getQuota(getquotarequest: GetQuotaRequest, withHeaders?: boolean): Promise<{ data: GetQuotaResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getquotarequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('getQuota', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/staking/getQuota`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getquotarequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new GetQuotaResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Staking/getQuota', { response: res, data: result, fields: [JSON.stringify({ getquotarequest })] });
    }

    static async purchase(purchaserequest: PurchaseRequest, withHeaders?: boolean): Promise<{ data: Position; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...purchaserequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('purchase', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/staking/purchase`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(purchaserequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new Position(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Staking/purchase', { response: res, data: result, fields: [JSON.stringify({ purchaserequest })] });
    }

    static async redeem(redeemrequest: RedeemRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...redeemrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('redeem', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/staking/redeem`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(redeemrequest.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Staking/redeem', { response: res, fields: [JSON.stringify({ redeemrequest })] });
    }
}
