// This file was autogenerated. Please do not change.
export interface ILiquidationsSummary {
    assetSymbol?: string;
    quantity?: string;
    quotationSymbol?: string;
    quotedQuantity?: string;
}

export default class LiquidationsSummary {
    readonly _assetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 9.5425505
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _quotationSymbol: string | undefined;

    /**
     * Example: USD
     */
    get quotationSymbol(): string | undefined {
        return this._quotationSymbol;
    }

    readonly _quotedQuantity: number | undefined;

    /**
     * Example: 9.5425505
     */
    get quotedQuantity(): number | undefined {
        return this._quotedQuantity;
    }

    constructor(props: ILiquidationsSummary) {
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.quotationSymbol === 'string') {
            this._quotationSymbol = props.quotationSymbol.trim();
        }
        if (typeof props.quotedQuantity === 'string') {
            this._quotedQuantity = Number(props.quotedQuantity);
        }
    }

    serialize(): ILiquidationsSummary {
        const data: ILiquidationsSummary = {
        };
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._quotationSymbol !== 'undefined') {
            data.quotationSymbol = this._quotationSymbol;
        }
        if (typeof this._quotedQuantity !== 'undefined') {
            data.quotedQuantity = String(this._quotedQuantity);
        }
        return data;
    }

    toJSON(): ILiquidationsSummary {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            quantity: true,
            quotationSymbol: !this._quotationSymbol ? true : typeof this._quotationSymbol === 'string',
            quotedQuantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
