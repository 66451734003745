/**
 *
 * @return {Generator<number, void, *>}
 */
function* getIdGenerator() {
    let index = 0;
    while (true) {
        yield index += 1;
    }
}

export default getIdGenerator();
