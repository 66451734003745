// This file was autogenerated. Please do not change.
export interface IVerifyCodePayload {
    email: string;
    passCode: string;
}

export default class VerifyCodePayload {
    readonly _email: string;

    /** Description: User's email */
    get email(): string {
        return this._email;
    }

    readonly _passCode: string;

    /** Description: One-time verification code */
    get passCode(): string {
        return this._passCode;
    }

    constructor(props: IVerifyCodePayload) {
        this._email = props.email.trim();
        this._passCode = props.passCode.trim();
    }

    serialize(): IVerifyCodePayload {
        const data: IVerifyCodePayload = {
            email: this._email,
            passCode: this._passCode,
        };
        return data;
    }

    toJSON(): IVerifyCodePayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            email: typeof this._email === 'string' && !!this._email.trim(),
            passCode: typeof this._passCode === 'string' && !!this._passCode.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
