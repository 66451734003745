<template>
    <div class="empty-bar-chart">

        <div class="outer-circle"></div>
        <div class="inner-circle"></div>

        <div class="doughnut-separator"></div>

    </div>
</template>

<script>
export default {
    name: 'EmptyBarChart',
};
</script>
