// This file was autogenerated. Please do not change.
/* A response for updating quota */
export interface IUpdateQuotaResponse {
    id: string;
    quota?: number;
}

export default class UpdateQuotaResponse {
    readonly _id: string;

    /** Description: User unique identifier */
    get id(): string {
        return this._id;
    }

    readonly _quota: number | undefined;

    /** Description: Total available quota */
    get quota(): number | undefined {
        return this._quota;
    }

    constructor(props: IUpdateQuotaResponse) {
        this._id = props.id.trim();
        if (typeof props.quota === 'number') {
            this._quota = props.quota;
        }
    }

    serialize(): IUpdateQuotaResponse {
        const data: IUpdateQuotaResponse = {
            id: this._id,
        };
        if (typeof this._quota !== 'undefined') {
            data.quota = this._quota;
        }
        return data;
    }

    toJSON(): IUpdateQuotaResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
            quota: !this._quota ? true : typeof this._quota === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
