/**
 *
 * @return {String}
 */
Number.prototype.noExponents = function() {
    let data = String(this).split(/[eE]/);
    if (data.length === 1) return data[0];

    let z = '',
        sign = this < 0 ? '-' : '',
        str = data[0].replace('.', ''),
        mag = Number(data[1]) + 1;

    if (mag < 0) {
        z = sign + '0.';
        while (mag++) z += '0';
        // eslint-disable-next-line no-useless-escape
        return z + str.replace(/^\-/, '');
    }
    mag -= str.length;
    while (mag--) z += '0';
    return str + z;
};

/**
 *
 * @param deep
 * @return {number}
 */
Number.prototype.round = function (deep = 2) {

    return Math.round((this as any) * (10 ** deep)) / (10 ** deep)

};

/**
 *
 * @param deep
 * @return {number}
 */
Number.prototype.floor = function (deep = 2) {

    return Math.floor((this as any) * (10 ** deep)) / (10 ** deep)

};

/**
 *
 * @param deep
 * @return {number}
 */
Number.prototype.ceil = function (deep = 2) {

    return Math.ceil((this as any) * (10 ** deep)) / (10 ** deep)

};

/**
 *
 * @return {number}
 */
Number.prototype.getInt = function () {

    return Math.trunc((this as any))

};

/**
 *
 * @return {(number|string)}
 */

Number.prototype.fraction = function () {

    return this.noExponents().substr(Math.trunc((this as any)).toString().length)

};

Number.prototype.nFormat = function nFormatter(digits = 4) {
    let num = this

    const si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "K" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "B" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;

    let i;
    for (i = si.length - 1; i > 0; i--) {
        if (num >= si[i].value) {
            break;
        }
    }

    return (num as any / si[i].value).toPrecision(digits).replace(rx, "$1") + si[i].symbol;
}

Number.prototype.getPrecision = function getPrecision() {

    return this.noExponents().getPrecision()

}

/**
 * @return {string}
 */
Number.prototype.getSeparatedDigits = function () {

    let int = this.getInt();
    let fraction = this.fraction();

    return String(int).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ') + fraction

};

/**
 * @return {string}
 */
Number.prototype.getSpaceSeparatedDigits = function () {

    let int = this.getInt();
    let fraction = this.fraction();

    return String(int).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ') + fraction

};

/**
 * Fx(λ) = e^(λ * x)
 *
 * @this {Number} x
 * @param {Number} lambda - exponential growth parameter - λ
 *
 * @return {Number}
 */
Number.prototype.exponentialGrowth = function (lambda = 1) {
    return Math.E ** (lambda * (this as unknown as number))
}

/**
 *
 * @param {Number} number
 *
 * @return {Boolean}
 */
Number.prototype.isAliquotOf = function (number) {

    let that = +this

    if (that > number) {

        return false

    } else if (that === number || that === 0 || that === 1) {

        return true

    } else if (that < 1 && that > 0) {

        let deep = 10 ** Math.abs(Math.floor(Math.log10(that)))
        return Math.round(number * deep) % Math.round(that * deep) === 0

    } else {

        return number % that === 0

    }

}
