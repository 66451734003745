// This file was autogenerated. Please do not change.
export interface ITradeAPIKey {
    accountId?: string;
    createdAt?: string;
    ips?: string[];
    key?: string;
    label?: string;
    placementTags?: string[];
    status?: string;
    updatedAt?: string;
}

export default class TradeAPIKey {
    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _createdAt: string | undefined;

    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _ips: string[] | undefined;

    get ips(): string[] | undefined {
        return this._ips;
    }

    readonly _key: string | undefined;

    get key(): string | undefined {
        return this._key;
    }

    readonly _label: string | undefined;

    get label(): string | undefined {
        return this._label;
    }

    readonly _placementTags: string[] | undefined;

    get placementTags(): string[] | undefined {
        return this._placementTags;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    readonly _updatedAt: string | undefined;

    get updatedAt(): string | undefined {
        return this._updatedAt;
    }

    constructor(props: ITradeAPIKey) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (props.ips) {
            this._ips = props.ips;
        }
        if (typeof props.key === 'string') {
            this._key = props.key.trim();
        }
        if (typeof props.label === 'string') {
            this._label = props.label.trim();
        }
        if (props.placementTags) {
            this._placementTags = props.placementTags;
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (typeof props.updatedAt === 'string') {
            this._updatedAt = props.updatedAt.trim();
        }
    }

    serialize(): ITradeAPIKey {
        const data: ITradeAPIKey = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._ips !== 'undefined') {
            data.ips = this._ips;
        }
        if (typeof this._key !== 'undefined') {
            data.key = this._key;
        }
        if (typeof this._label !== 'undefined') {
            data.label = this._label;
        }
        if (typeof this._placementTags !== 'undefined') {
            data.placementTags = this._placementTags;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._updatedAt !== 'undefined') {
            data.updatedAt = this._updatedAt;
        }
        return data;
    }

    toJSON(): ITradeAPIKey {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            ips: !this._ips ? true : this._ips.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            key: !this._key ? true : typeof this._key === 'string',
            label: !this._label ? true : typeof this._label === 'string',
            placementTags: !this._placementTags ? true : this._placementTags.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            status: !this._status ? true : typeof this._status === 'string',
            updatedAt: !this._updatedAt ? true : typeof this._updatedAt === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
