// This file was autogenerated. Please do not change.
export interface ISpotOrderStatus {
    count?: number;
    status?: string;
}

export default class SpotOrderStatus {
    readonly _count: number | undefined;

    /**
     * Example: 100
     */
    get count(): number | undefined {
        return this._count;
    }

    readonly _status: string | undefined;

    /**
     * Example: FILLED
     */
    get status(): string | undefined {
        return this._status;
    }

    constructor(props: ISpotOrderStatus) {
        if (typeof props.count === 'number') {
            this._count = props.count;
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): ISpotOrderStatus {
        const data: ISpotOrderStatus = {
        };
        if (typeof this._count !== 'undefined') {
            data.count = this._count;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): ISpotOrderStatus {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            count: !this._count ? true : typeof this._count === 'number',
            status: !this._status ? true : typeof this._status === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
