// This file was autogenerated. Please do not change.
export interface ITransfersRequest {
    accountId: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    type?: ('deposit' | 'withdrawal' | 'transfer' | 'rebate');
}

export default class TransfersRequest {
    readonly _accountId: string;

    /**
     * Example: AL6RG9MWFPZ
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _type: ('deposit' | 'withdrawal' | 'transfer' | 'rebate') | undefined;

    /**
     * Example: deposit
     */
    get type(): ('deposit' | 'withdrawal' | 'transfer' | 'rebate') | undefined {
        return this._type;
    }

    constructor(props: ITransfersRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): ITransfersRequest {
        const data: ITransfersRequest = {
            accountId: this._accountId,
        };
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): ITransfersRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
