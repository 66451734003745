import ApiError from 'Entities/ApiError';
import AddLiquidityRequest from 'Entities/dex/AddLiquidityRequest';
import ApproveRequest from 'Entities/dex/ApproveRequest';
import ApproveResponse from 'Entities/dex/ApproveResponse';
import CheckApprovalResponse from 'Entities/dex/CheckApprovalResponse';
import CheckApprovalStatusResponse from 'Entities/dex/CheckApprovalStatusResponse';
import ExecuteTxRequest from 'Entities/dex/ExecuteTxRequest';
import GasPriceParams from 'Entities/dex/GasPriceParams';
import GasPriceResponse from 'Entities/dex/GasPriceResponse';
import GasPrices from 'Entities/dex/GasPrices';
import GetAllowanceRequest from 'Entities/dex/GetAllowanceRequest';
import GetAllowanceResponse from 'Entities/dex/GetAllowanceResponse';
import GetPoolInfoRequest from 'Entities/dex/GetPoolInfoRequest';
import GetPositionRequest from 'Entities/dex/GetPositionRequest';
import GetRoutesResponse from 'Entities/dex/GetRoutesResponse';
import LiquidityApproveRequest from 'Entities/dex/LiquidityApproveRequest';
import LiquidityPosition from 'Entities/dex/LiquidityPosition';
import PoolInfo from 'Entities/dex/PoolInfo';
import PreparedTxResponse from 'Entities/dex/PreparedTxResponse';
import Quote from 'Entities/dex/Quote';
import QuoteParams from 'Entities/dex/QuoteParams';
import RemoveLiquidityRequest from 'Entities/dex/RemoveLiquidityRequest';
import RoutesParams from 'Entities/dex/RoutesParams';
import SwapRequest from 'Entities/dex/SwapRequest';
import DexPosition from 'Entities/privatePresenter/DexPosition';
import DexPositionRequest from 'Entities/privatePresenter/DexPositionRequest';
import DexPositionsRequest from 'Entities/privatePresenter/DexPositionsRequest';
import DexTransaction from 'Entities/privatePresenter/DexTransaction';
import DexTransactionRequest from 'Entities/privatePresenter/DexTransactionRequest';
import DexTransactionsRequest from 'Entities/privatePresenter/DexTransactionsRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class DeFiApi {
    static async privateDexExecuteTransaction(executetxrequest: ExecuteTxRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...executetxrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexExecuteTransaction', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/executeTransaction`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(executetxrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexExecuteTransaction', { response: res, data: result, fields: [JSON.stringify({ executetxrequest })] });
    }

    static async privateDexGetGasPrice(gaspriceparams: GasPriceParams, withHeaders?: boolean): Promise<{ data: GasPriceResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...gaspriceparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexGetGasPrice', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/getGasPrice`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(gaspriceparams.serialize()),
        });
        if (res.status === 200) {
            return { data: new GasPriceResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexGetGasPrice', { response: res, data: result, fields: [JSON.stringify({ gaspriceparams })] });
    }

    static async privateDexGetGasPrices(gaspriceparams: GasPriceParams, withHeaders?: boolean): Promise<{ data: GasPrices; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...gaspriceparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexGetGasPrices', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/getGasPrices`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(gaspriceparams.serialize()),
        });
        if (res.status === 200) {
            return { data: new GasPrices(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexGetGasPrices', { response: res, data: result, fields: [JSON.stringify({ gaspriceparams })] });
    }

    static async privateDexGetPoolInfo(getpoolinforequest: GetPoolInfoRequest, withHeaders?: boolean): Promise<{ data: PoolInfo; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getpoolinforequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexGetPoolInfo', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/getPoolInfo`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getpoolinforequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new PoolInfo(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexGetPoolInfo', { response: res, data: result, fields: [JSON.stringify({ getpoolinforequest })] });
    }

    static async privateDexGetQuotes(quoteparams: QuoteParams, withHeaders?: boolean): Promise<{ data: Quote[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...quoteparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexGetQuotes', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/getQuotes`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(quoteparams.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Quote(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexGetQuotes', { response: res, data: result, fields: [JSON.stringify({ quoteparams })] });
    }

    static async privateDexGetRoutes(routesparams: RoutesParams, withHeaders?: boolean): Promise<{ data: GetRoutesResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...routesparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexGetRoutes', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/getRoutes`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(routesparams.serialize()),
        });
        if (res.status === 200) {
            return { data: new GetRoutesResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexGetRoutes', { response: res, data: result, fields: [JSON.stringify({ routesparams })] });
    }

    static async privateDexLiquidityAddLiquidity(addliquidityrequest: AddLiquidityRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...addliquidityrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityAddLiquidity', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/addLiquidity`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(addliquidityrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityAddLiquidity', { response: res, data: result, fields: [JSON.stringify({ addliquidityrequest })] });
    }

    static async privateDexLiquidityApprove(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityApprove', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/approve`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityApprove', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexLiquidityApproveLiquidity(liquidityapproverequest: LiquidityApproveRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...liquidityapproverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityApproveLiquidity', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/approveLiquidity`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(liquidityapproverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityApproveLiquidity', { response: res, data: result, fields: [JSON.stringify({ liquidityapproverequest })] });
    }

    static async privateDexLiquidityCheckApproval(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: CheckApprovalResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityCheckApproval', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/checkApproval`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new CheckApprovalResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityCheckApproval', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexLiquidityCheckApprovalStatus(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: CheckApprovalStatusResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityCheckApprovalStatus', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/checkApprovalStatus`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new CheckApprovalStatusResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityCheckApprovalStatus', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexLiquidityCheckLiquidityApproval(liquidityapproverequest: LiquidityApproveRequest, withHeaders?: boolean): Promise<{ data: CheckApprovalResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...liquidityapproverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityCheckLiquidityApproval', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/checkLiquidityApproval`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(liquidityapproverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new CheckApprovalResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityCheckLiquidityApproval', { response: res, data: result, fields: [JSON.stringify({ liquidityapproverequest })] });
    }

    static async privateDexLiquidityCheckLiquidityApprovalStatus(liquidityapproverequest: LiquidityApproveRequest, withHeaders?: boolean): Promise<{ data: CheckApprovalStatusResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...liquidityapproverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityCheckLiquidityApprovalStatus', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/checkLiquidityApprovalStatus`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(liquidityapproverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new CheckApprovalStatusResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityCheckLiquidityApprovalStatus', { response: res, data: result, fields: [JSON.stringify({ liquidityapproverequest })] });
    }

    static async privateDexLiquidityGetAllowance(getallowancerequest: GetAllowanceRequest, withHeaders?: boolean): Promise<{ data: GetAllowanceResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getallowancerequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityGetAllowance', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/getAllowance`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getallowancerequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new GetAllowanceResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityGetAllowance', { response: res, data: result, fields: [JSON.stringify({ getallowancerequest })] });
    }

    static async privateDexLiquidityGetPosition(getpositionrequest: GetPositionRequest, withHeaders?: boolean): Promise<{ data: LiquidityPosition; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getpositionrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityGetPosition', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/getPosition`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getpositionrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new LiquidityPosition(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityGetPosition', { response: res, data: result, fields: [JSON.stringify({ getpositionrequest })] });
    }

    static async privateDexLiquidityPrepareAddLiquidity(addliquidityrequest: AddLiquidityRequest, withHeaders?: boolean): Promise<{ data: PreparedTxResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...addliquidityrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityPrepareAddLiquidity', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/prepareAddLiquidity`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(addliquidityrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new PreparedTxResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityPrepareAddLiquidity', { response: res, data: result, fields: [JSON.stringify({ addliquidityrequest })] });
    }

    static async privateDexLiquidityPrepareApprove(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse | PreparedTxResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityPrepareApprove', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/prepareApprove`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new PreparedTxResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityPrepareApprove', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexLiquidityPrepareApproveLiquidity(liquidityapproverequest: LiquidityApproveRequest, withHeaders?: boolean): Promise<{ data: PreparedTxResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...liquidityapproverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityPrepareApproveLiquidity', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/prepareApproveLiquidity`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(liquidityapproverequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new PreparedTxResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityPrepareApproveLiquidity', { response: res, data: result, fields: [JSON.stringify({ liquidityapproverequest })] });
    }

    static async privateDexLiquidityPrepareRemoveLiquidity(removeliquidityrequest: RemoveLiquidityRequest, withHeaders?: boolean): Promise<{ data: PreparedTxResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...removeliquidityrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityPrepareRemoveLiquidity', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/prepareRemoveLiquidity`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(removeliquidityrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new PreparedTxResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityPrepareRemoveLiquidity', { response: res, data: result, fields: [JSON.stringify({ removeliquidityrequest })] });
    }

    static async privateDexLiquidityRemoveLiquidity(removeliquidityrequest: RemoveLiquidityRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...removeliquidityrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexLiquidityRemoveLiquidity', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/liquidity/removeLiquidity`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(removeliquidityrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexLiquidityRemoveLiquidity', { response: res, data: result, fields: [JSON.stringify({ removeliquidityrequest })] });
    }

    static async privateDexSwapsApprove(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsApprove', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/approve`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsApprove', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexSwapsCheckApproval(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: CheckApprovalResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsCheckApproval', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/checkApproval`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new CheckApprovalResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsCheckApproval', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexSwapsCheckApprovalStatus(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: CheckApprovalStatusResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsCheckApprovalStatus', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/checkApprovalStatus`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new CheckApprovalStatusResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsCheckApprovalStatus', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexSwapsGetAllowance(getallowancerequest: GetAllowanceRequest, withHeaders?: boolean): Promise<{ data: GetAllowanceResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getallowancerequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsGetAllowance', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/getAllowance`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getallowancerequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new GetAllowanceResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsGetAllowance', { response: res, data: result, fields: [JSON.stringify({ getallowancerequest })] });
    }

    static async privateDexSwapsPrepareApprove(approverequest: ApproveRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse | PreparedTxResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...approverequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsPrepareApprove', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/prepareApprove`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(approverequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new PreparedTxResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsPrepareApprove', { response: res, data: result, fields: [JSON.stringify({ approverequest })] });
    }

    static async privateDexSwapsPrepareSwap(swaprequest: SwapRequest, withHeaders?: boolean): Promise<{ data: PreparedTxResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...swaprequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsPrepareSwap', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/prepareSwap`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(swaprequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new PreparedTxResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsPrepareSwap', { response: res, data: result, fields: [JSON.stringify({ swaprequest })] });
    }

    static async privateDexSwapsSwap(swaprequest: SwapRequest, withHeaders?: boolean): Promise<{ data: ApproveResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...swaprequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateDexSwapsSwap', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/dex/swaps/swap`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(swaprequest.serialize()),
        });
        if (res.status === 201) {
            return { data: new ApproveResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateDexSwapsSwap', { response: res, data: result, fields: [JSON.stringify({ swaprequest })] });
    }

    static async privateGetDexPosition(dexpositionrequest: DexPositionRequest, withHeaders?: boolean): Promise<{ data: DexPosition; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...dexpositionrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetDexPosition', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getDexPosition`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(dexpositionrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new DexPosition(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateGetDexPosition', { response: res, data: result, fields: [JSON.stringify({ dexpositionrequest })] });
    }

    static async privateGetDexPositions(dexpositionsrequest: DexPositionsRequest, withHeaders?: boolean): Promise<{ data: DexPosition[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...dexpositionsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetDexPositions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getDexPositions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(dexpositionsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new DexPosition(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateGetDexPositions', { response: res, data: result, fields: [JSON.stringify({ dexpositionsrequest })] });
    }

    static async privateGetDexTransaction(dextransactionrequest: DexTransactionRequest, withHeaders?: boolean): Promise<{ data: DexTransaction; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...dextransactionrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetDexTransaction', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getDexTransaction`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(dextransactionrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new DexTransaction(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateGetDexTransaction', { response: res, data: result, fields: [JSON.stringify({ dextransactionrequest })] });
    }

    static async privateGetDexTransactions(dextransactionsrequest: DexTransactionsRequest, withHeaders?: boolean): Promise<{ data: DexTransaction[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...dextransactionsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetDexTransactions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getDexTransactions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(dextransactionsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new DexTransaction(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('DeFi/privateGetDexTransactions', { response: res, data: result, fields: [JSON.stringify({ dextransactionsrequest })] });
    }
}
