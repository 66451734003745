<template>
    <label :class="['toggle-bar', {'active': value, 'invert-colors': invertColors, 'disabled-toggle-bar': disabled}]"
    >

        <span class="toggle-bar__symbol symbol-on">
            <slot name="symbol-on">
                {{ symbolOn }}
            </slot>
        </span>

        <span class="toggle-bar__symbol symbol-off">
            <slot name="symbol-off">
                <span :class="{ 'cross-out': crossOutOffSymbol }">{{ symbolOff }}</span>
            </slot>
        </span>

        <span class="toggle-bar__slider"></span>

        <input type="checkbox"
               :value="value"
               @input="input"
               class="toggle-bar__input"
               :disabled="disabled"
        />
    </label>
</template>

<script>
export default {
    name: 'ToggleBar',
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        symbolOff: {
            type: String,
            default: '+',
        },
        symbolOn: {
            type: String,
            default: '-',
        },
        invertColors: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        crossOutOffSymbol: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        input() {
            this.$emit('input', !this.value);
        },
    },
};
</script>

<style scoped>
.disabled-toggle-bar {
    opacity: .5;
}
.cross-out {
    text-decoration: unset;
    position: relative;
}
.cross-out:before {
    content: "";
    border-bottom: 1px solid var(--main-text-color);
    opacity: .5;
    position: absolute;
    width: 200%;
    height: 50%;
    transform: rotate(-35deg);
    left: -6px;
 }

</style>
