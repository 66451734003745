// This file was autogenerated. Please do not change.
export interface ISpotOrderView {
    accountId?: string;
    baseAssetId?: number;
    baseAssetSymbol?: string;
    baseBalanceId?: string;
    baseQuantity?: number;
    commissionAssetId?: number;
    commissionAssetSymbol?: string;
    commissionQuantity?: number;
    createdAt?: string;
    executedBaseQuantity?: number;
    executedQuoteQuantity?: number;
    id?: string;
    initiatorType?: string;
    initiatorUserId?: string;
    isBuy?: boolean;
    orderType?: string;
    outerId?: string;
    parentId?: number;
    parentType?: string;
    placementId?: number;
    placementName?: string;
    placementPrecisionPrice?: number;
    placementPrecisionQuantity?: number;
    platformCommissionQuantity?: number;
    price?: number;
    priceAvg?: number;
    quoteAssetId?: number;
    quoteAssetSymbol?: string;
    quoteBalanceId?: string;
    quoteQuantity?: number;
    rejectReason?: string;
    spotAssetPairId?: number;
    spotAssetPairSymbol?: string;
    status?: string;
    timeInForce?: string;
    triggerPrice?: number;
    updatedAt?: string;
}

export default class SpotOrderView {
    readonly _accountId: string | undefined;

    /**
     * Example: ATJFR5Y2Q7S
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _baseAssetId: number | undefined;

    /**
     * Example: 79
     */
    get baseAssetId(): number | undefined {
        return this._baseAssetId;
    }

    readonly _baseAssetSymbol: string | undefined;

    /**
     * Example: BAND
     */
    get baseAssetSymbol(): string | undefined {
        return this._baseAssetSymbol;
    }

    readonly _baseBalanceId: string | undefined;

    /**
     * Example: BP3QLDDR3PG
     */
    get baseBalanceId(): string | undefined {
        return this._baseBalanceId;
    }

    readonly _baseQuantity: number | undefined;

    /**
     * Example: 7
     */
    get baseQuantity(): number | undefined {
        return this._baseQuantity;
    }

    readonly _commissionAssetId: number | undefined;

    /**
     * Example: 7
     */
    get commissionAssetId(): number | undefined {
        return this._commissionAssetId;
    }

    readonly _commissionAssetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get commissionAssetSymbol(): string | undefined {
        return this._commissionAssetSymbol;
    }

    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 0.1205575
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _createdAt: string | undefined;

    /**
     * Example: 2022-03-24T14:58:34.946892Z
     */
    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _executedBaseQuantity: number | undefined;

    /**
     * Example: 7
     */
    get executedBaseQuantity(): number | undefined {
        return this._executedBaseQuantity;
    }

    readonly _executedQuoteQuantity: number | undefined;

    /**
     * Example: 29.05
     */
    get executedQuoteQuantity(): number | undefined {
        return this._executedQuoteQuantity;
    }

    readonly _id: string | undefined;

    /**
     * Example: O4EL7YFR7M5MRLRT2N17K
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _initiatorType: string | undefined;

    /**
     * Example: users
     */
    get initiatorType(): string | undefined {
        return this._initiatorType;
    }

    readonly _initiatorUserId: string | undefined;

    /**
     * Example: U1W7CLF6U
     */
    get initiatorUserId(): string | undefined {
        return this._initiatorUserId;
    }

    readonly _isBuy: boolean | undefined;

    get isBuy(): boolean | undefined {
        return this._isBuy;
    }

    readonly _orderType: string | undefined;

    /**
     * Example: LIMIT_STOP
     */
    get orderType(): string | undefined {
        return this._orderType;
    }

    readonly _outerId: string | undefined;

    /**
     * Example: 917083103
     */
    get outerId(): string | undefined {
        return this._outerId;
    }

    readonly _parentId: number | undefined;

    get parentId(): number | undefined {
        return this._parentId;
    }

    readonly _parentType: string | undefined;

    get parentType(): string | undefined {
        return this._parentType;
    }

    readonly _placementId: number | undefined;

    /**
     * Example: 2
     */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _placementPrecisionPrice: number | undefined;

    /**
     * Example: 3
     */
    get placementPrecisionPrice(): number | undefined {
        return this._placementPrecisionPrice;
    }

    readonly _placementPrecisionQuantity: number | undefined;

    /**
     * Example: 1
     */
    get placementPrecisionQuantity(): number | undefined {
        return this._placementPrecisionQuantity;
    }

    readonly _platformCommissionQuantity: number | undefined;

    get platformCommissionQuantity(): number | undefined {
        return this._platformCommissionQuantity;
    }

    readonly _price: number | undefined;

    /**
     * Example: 4.149
     */
    get price(): number | undefined {
        return this._price;
    }

    readonly _priceAvg: number | undefined;

    /**
     * Example: 4.15
     */
    get priceAvg(): number | undefined {
        return this._priceAvg;
    }

    readonly _quoteAssetId: number | undefined;

    /**
     * Example: 7
     */
    get quoteAssetId(): number | undefined {
        return this._quoteAssetId;
    }

    readonly _quoteAssetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get quoteAssetSymbol(): string | undefined {
        return this._quoteAssetSymbol;
    }

    readonly _quoteBalanceId: string | undefined;

    /**
     * Example: BJQNKQDCPVG
     */
    get quoteBalanceId(): string | undefined {
        return this._quoteBalanceId;
    }

    readonly _quoteQuantity: number | undefined;

    get quoteQuantity(): number | undefined {
        return this._quoteQuantity;
    }

    readonly _rejectReason: string | undefined;

    get rejectReason(): string | undefined {
        return this._rejectReason;
    }

    readonly _spotAssetPairId: number | undefined;

    /**
     * Example: 137
     */
    get spotAssetPairId(): number | undefined {
        return this._spotAssetPairId;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: BAND/USDT
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    readonly _status: string | undefined;

    /**
     * Example: FILLED
     */
    get status(): string | undefined {
        return this._status;
    }

    readonly _timeInForce: string | undefined;

    /**
     * Example: GTC
     */
    get timeInForce(): string | undefined {
        return this._timeInForce;
    }

    readonly _triggerPrice: number | undefined;

    /**
     * Example: 4.15
     */
    get triggerPrice(): number | undefined {
        return this._triggerPrice;
    }

    readonly _updatedAt: string | undefined;

    /**
     * Example: 2022-03-24T14:58:34.946892Z
     */
    get updatedAt(): string | undefined {
        return this._updatedAt;
    }

    constructor(props: ISpotOrderView) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.baseAssetId === 'number') {
            this._baseAssetId = props.baseAssetId;
        }
        if (typeof props.baseAssetSymbol === 'string') {
            this._baseAssetSymbol = props.baseAssetSymbol.trim();
        }
        if (typeof props.baseBalanceId === 'string') {
            this._baseBalanceId = props.baseBalanceId.trim();
        }
        if (typeof props.baseQuantity === 'number') {
            this._baseQuantity = Number(props.baseQuantity);
        }
        if (typeof props.commissionAssetId === 'number') {
            this._commissionAssetId = props.commissionAssetId;
        }
        if (typeof props.commissionAssetSymbol === 'string') {
            this._commissionAssetSymbol = props.commissionAssetSymbol.trim();
        }
        if (typeof props.commissionQuantity === 'number') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.executedBaseQuantity === 'number') {
            this._executedBaseQuantity = Number(props.executedBaseQuantity);
        }
        if (typeof props.executedQuoteQuantity === 'number') {
            this._executedQuoteQuantity = Number(props.executedQuoteQuantity);
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.initiatorType === 'string') {
            this._initiatorType = props.initiatorType.trim();
        }
        if (typeof props.initiatorUserId === 'string') {
            this._initiatorUserId = props.initiatorUserId.trim();
        }
        if (typeof props.isBuy === 'boolean') {
            this._isBuy = props.isBuy;
        }
        if (typeof props.orderType === 'string') {
            this._orderType = props.orderType.trim();
        }
        if (typeof props.outerId === 'string') {
            this._outerId = props.outerId.trim();
        }
        if (typeof props.parentId === 'number') {
            this._parentId = props.parentId;
        }
        if (typeof props.parentType === 'string') {
            this._parentType = props.parentType.trim();
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.placementPrecisionPrice === 'number') {
            this._placementPrecisionPrice = props.placementPrecisionPrice;
        }
        if (typeof props.placementPrecisionQuantity === 'number') {
            this._placementPrecisionQuantity = props.placementPrecisionQuantity;
        }
        if (typeof props.platformCommissionQuantity === 'number') {
            this._platformCommissionQuantity = Number(props.platformCommissionQuantity);
        }
        if (typeof props.price === 'number') {
            this._price = Number(props.price);
        }
        if (typeof props.priceAvg === 'number') {
            this._priceAvg = Number(props.priceAvg);
        }
        if (typeof props.quoteAssetId === 'number') {
            this._quoteAssetId = props.quoteAssetId;
        }
        if (typeof props.quoteAssetSymbol === 'string') {
            this._quoteAssetSymbol = props.quoteAssetSymbol.trim();
        }
        if (typeof props.quoteBalanceId === 'string') {
            this._quoteBalanceId = props.quoteBalanceId.trim();
        }
        if (typeof props.quoteQuantity === 'number') {
            this._quoteQuantity = Number(props.quoteQuantity);
        }
        if (typeof props.rejectReason === 'string') {
            this._rejectReason = props.rejectReason.trim();
        }
        if (typeof props.spotAssetPairId === 'number') {
            this._spotAssetPairId = props.spotAssetPairId;
        }
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (typeof props.timeInForce === 'string') {
            this._timeInForce = props.timeInForce.trim();
        }
        if (typeof props.triggerPrice === 'number') {
            this._triggerPrice = Number(props.triggerPrice);
        }
        if (typeof props.updatedAt === 'string') {
            this._updatedAt = props.updatedAt.trim();
        }
    }

    serialize(): ISpotOrderView {
        const data: ISpotOrderView = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._baseAssetId !== 'undefined') {
            data.baseAssetId = this._baseAssetId;
        }
        if (typeof this._baseAssetSymbol !== 'undefined') {
            data.baseAssetSymbol = this._baseAssetSymbol;
        }
        if (typeof this._baseBalanceId !== 'undefined') {
            data.baseBalanceId = this._baseBalanceId;
        }
        if (typeof this._baseQuantity !== 'undefined') {
            data.baseQuantity = Number(this._baseQuantity);
        }
        if (typeof this._commissionAssetId !== 'undefined') {
            data.commissionAssetId = this._commissionAssetId;
        }
        if (typeof this._commissionAssetSymbol !== 'undefined') {
            data.commissionAssetSymbol = this._commissionAssetSymbol;
        }
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = Number(this._commissionQuantity);
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._executedBaseQuantity !== 'undefined') {
            data.executedBaseQuantity = Number(this._executedBaseQuantity);
        }
        if (typeof this._executedQuoteQuantity !== 'undefined') {
            data.executedQuoteQuantity = Number(this._executedQuoteQuantity);
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._initiatorType !== 'undefined') {
            data.initiatorType = this._initiatorType;
        }
        if (typeof this._initiatorUserId !== 'undefined') {
            data.initiatorUserId = this._initiatorUserId;
        }
        if (typeof this._isBuy !== 'undefined') {
            data.isBuy = this._isBuy;
        }
        if (typeof this._orderType !== 'undefined') {
            data.orderType = this._orderType;
        }
        if (typeof this._outerId !== 'undefined') {
            data.outerId = this._outerId;
        }
        if (typeof this._parentId !== 'undefined') {
            data.parentId = this._parentId;
        }
        if (typeof this._parentType !== 'undefined') {
            data.parentType = this._parentType;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._placementPrecisionPrice !== 'undefined') {
            data.placementPrecisionPrice = this._placementPrecisionPrice;
        }
        if (typeof this._placementPrecisionQuantity !== 'undefined') {
            data.placementPrecisionQuantity = this._placementPrecisionQuantity;
        }
        if (typeof this._platformCommissionQuantity !== 'undefined') {
            data.platformCommissionQuantity = Number(this._platformCommissionQuantity);
        }
        if (typeof this._price !== 'undefined') {
            data.price = Number(this._price);
        }
        if (typeof this._priceAvg !== 'undefined') {
            data.priceAvg = Number(this._priceAvg);
        }
        if (typeof this._quoteAssetId !== 'undefined') {
            data.quoteAssetId = this._quoteAssetId;
        }
        if (typeof this._quoteAssetSymbol !== 'undefined') {
            data.quoteAssetSymbol = this._quoteAssetSymbol;
        }
        if (typeof this._quoteBalanceId !== 'undefined') {
            data.quoteBalanceId = this._quoteBalanceId;
        }
        if (typeof this._quoteQuantity !== 'undefined') {
            data.quoteQuantity = Number(this._quoteQuantity);
        }
        if (typeof this._rejectReason !== 'undefined') {
            data.rejectReason = this._rejectReason;
        }
        if (typeof this._spotAssetPairId !== 'undefined') {
            data.spotAssetPairId = this._spotAssetPairId;
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        if (typeof this._triggerPrice !== 'undefined') {
            data.triggerPrice = Number(this._triggerPrice);
        }
        if (typeof this._updatedAt !== 'undefined') {
            data.updatedAt = this._updatedAt;
        }
        return data;
    }

    toJSON(): ISpotOrderView {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            baseAssetId: !this._baseAssetId ? true : typeof this._baseAssetId === 'number',
            baseAssetSymbol: !this._baseAssetSymbol ? true : typeof this._baseAssetSymbol === 'string',
            baseBalanceId: !this._baseBalanceId ? true : typeof this._baseBalanceId === 'string',
            baseQuantity: true,
            commissionAssetId: !this._commissionAssetId ? true : typeof this._commissionAssetId === 'number',
            commissionAssetSymbol: !this._commissionAssetSymbol ? true : typeof this._commissionAssetSymbol === 'string',
            commissionQuantity: true,
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            executedBaseQuantity: true,
            executedQuoteQuantity: true,
            id: !this._id ? true : typeof this._id === 'string',
            initiatorType: !this._initiatorType ? true : typeof this._initiatorType === 'string',
            initiatorUserId: !this._initiatorUserId ? true : typeof this._initiatorUserId === 'string',
            isBuy: !this._isBuy ? true : typeof this._isBuy === 'boolean',
            orderType: !this._orderType ? true : typeof this._orderType === 'string',
            outerId: !this._outerId ? true : typeof this._outerId === 'string',
            parentId: !this._parentId ? true : typeof this._parentId === 'number',
            parentType: !this._parentType ? true : typeof this._parentType === 'string',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            placementPrecisionPrice: !this._placementPrecisionPrice ? true : typeof this._placementPrecisionPrice === 'number',
            placementPrecisionQuantity: !this._placementPrecisionQuantity ? true : typeof this._placementPrecisionQuantity === 'number',
            platformCommissionQuantity: true,
            price: true,
            priceAvg: true,
            quoteAssetId: !this._quoteAssetId ? true : typeof this._quoteAssetId === 'number',
            quoteAssetSymbol: !this._quoteAssetSymbol ? true : typeof this._quoteAssetSymbol === 'string',
            quoteBalanceId: !this._quoteBalanceId ? true : typeof this._quoteBalanceId === 'string',
            quoteQuantity: true,
            rejectReason: !this._rejectReason ? true : typeof this._rejectReason === 'string',
            spotAssetPairId: !this._spotAssetPairId ? true : typeof this._spotAssetPairId === 'number',
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            timeInForce: !this._timeInForce ? true : typeof this._timeInForce === 'string',
            triggerPrice: true,
            updatedAt: !this._updatedAt ? true : typeof this._updatedAt === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
