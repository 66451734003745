import { mutationCreator } from 'Store/utils';

interface ISetUi {
    quantity: number;
    total: number;
    price: number;
    stopPrice?: number;
}

const state = {
    ui: {
        quantity: 0 as number,
        total: 0 as number,
        price: 0 as number,
        stopPrice: 0 as number,
    },
};

export type MultiaccountOrderState = typeof state;

export enum MultiaccountOrderMutations {
    SET_UI = 'SET_UI',
}

export const SET_UI = mutationCreator<ISetUi>('MultiaccountOrder', MultiaccountOrderMutations.SET_UI);

const mutations: Record<MultiaccountOrderMutations, (state: MultiaccountOrderState, ...args: any) => void> = {
    SET_UI(state, { payload: ui }: ReturnType<typeof SET_UI>) {
        const defaults = {
            quantity: 0,
            total: 0,
            price: 0,
            stopPrice: 0,
        };
        state.ui = {
            ...defaults,
            ...ui,
        };
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
