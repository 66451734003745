import { Action } from 'vuex';

import WalletApi from 'Apis/Wallets';
import TransfersRequest from 'Entities/privatePresenter/TransfersRequest';
import Transfer, { ITransfer } from 'Entities/privatePresenter/Transfer';
import { actionCreator, mutationCreator } from 'Store/utils';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

export enum TransferTypes {
    ALL = '',
    TRANSFER = 'transfer',
    WITHDRAW = 'withdrawal',
    DEPOSIT = 'deposit',
}

export interface TransferHistoryUI {
    currentPage: number;
    itemsPerPage: number;
    pages: number;
    totalItemsCount: number;
    transferType: TransferTypes
}

interface IGetTransfersPayload {
    page: number,
    transferType: number,
}

const state = {
    ui: {
        itemsPerPage: 11,
        pages: 1,
        currentPage: 1,
        totalItemsCount: 0,
        transferType: TransferTypes.ALL,
    } as TransferHistoryUI,
    transfers: [] as Transfer[],
};

export type TransferHistoryState = typeof state;

export enum TransferHistoryGetters {}

const getters = {};

export enum TransferHistoryMutations {
    SET_UI = 'SET_UI',
    SET_TRANSFERS = 'SET_TRANSFERS',
    UPDATE_TRANSFER = 'UPDATE_TRANSFER',
}
export const SET_UI = mutationCreator<Partial<TransferHistoryUI>>('TransferHistory', TransferHistoryMutations.SET_UI);
export const SET_TRANSFERS = mutationCreator<Transfer[]>('TransferHistory', TransferHistoryMutations.SET_TRANSFERS);
export const UPDATE_TRANSFER = mutationCreator<ITransfer>('TransferHistory', TransferHistoryMutations.UPDATE_TRANSFER);

const mutations: Record<TransferHistoryMutations, (state: TransferHistoryState, ...args: any) => void> = {
    SET_UI(state, { payload }: ReturnType<typeof SET_UI>) {
        state.ui = { ...state.ui, ...payload };
    },
    SET_TRANSFERS(state, { payload }: ReturnType<typeof SET_TRANSFERS>) {
        state.transfers = payload;
    },
    UPDATE_TRANSFER(state, { payload }: ReturnType<typeof UPDATE_TRANSFER>) {
        const index = state.transfers.findIndex((t) => t.id === payload.id);
        if (index !== -1) {
            const data = state.transfers[index].serialize();
            state.transfers[index] = new Transfer({ ...data, ...payload });
            state.transfers = [...state.transfers];
            return;
        }
        if (state.ui.currentPage === 1) {
            state.transfers = [new Transfer(payload), ...state.transfers];
        }
    },
};

export enum TransferHistoryActions {
    getTransferHistory = 'getTransferHistory'
}

export const getTransferHistory = actionCreator<IGetTransfersPayload>('TransferHistory', TransferHistoryActions.getTransferHistory);

const actions: Record<TransferHistoryActions, Action<TransferHistoryState, any>> = {
    async getTransferHistory({ dispatch, commit, rootGetters, state: { ui } }, props: ReturnType<typeof getTransferHistory>) {
        if (!props.payload) {
            return;
        }
        const { page, transferType } = props.payload;
        ui.currentPage = page;
        const accountId = rootGetters['Accounts/activeAccountID'];
        if (!accountId) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: () => dispatch(getTransferHistory(props.payload, true)),
                once: true,
            }, { root: true });
            return;
        }
        let type: ('transfer' | 'deposit' | 'withdrawal') | undefined;
        switch (transferType) {
            case 1: {
                type = 'transfer';
                break;
            }
            case 2: {
                type = 'withdrawal';
                break;
            }
            case 3: {
                type = 'deposit';
                break;
            }
            default: {
                type = undefined;
                break;
            }
        }
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            const { data: transfers, headers } = await WalletApi.privateGetTransfers(new TransfersRequest({
                accountId,
                type,
                page,
                perPage: ui.itemsPerPage,
                includeTotal: true,
            }), true);

            const { total, totalPage } = parsePaginationHeaders(headers ?? new Headers());

            if (total && totalPage) {
                commit(SET_UI({
                    pages: totalPage || 1,
                    totalItemsCount: total || 1,
                }, true));
            }
            commit(SET_TRANSFERS(transfers, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting transfer history', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
