// This file was autogenerated. Please do not change.
export interface IAccountManagerInfo {
    firstName: string;
    id: string;
    lastName: string;
    policies: string[];
}

export default class AccountManagerInfo {
    readonly _firstName: string;

    get firstName(): string {
        return this._firstName;
    }

    readonly _id: string;

    get id(): string {
        return this._id;
    }

    readonly _lastName: string;

    get lastName(): string {
        return this._lastName;
    }

    readonly _policies: string[];

    get policies(): string[] {
        return this._policies;
    }

    constructor(props: IAccountManagerInfo) {
        this._firstName = props.firstName.trim();
        this._id = props.id.trim();
        this._lastName = props.lastName.trim();
        this._policies = props.policies;
    }

    serialize(): IAccountManagerInfo {
        const data: IAccountManagerInfo = {
            firstName: this._firstName,
            id: this._id,
            lastName: this._lastName,
            policies: this._policies,
        };
        return data;
    }

    toJSON(): IAccountManagerInfo {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
            firstName: typeof this._firstName === 'string' && !!this._firstName.trim(),
            lastName: typeof this._lastName === 'string' && !!this._lastName.trim(),
            policies: this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
