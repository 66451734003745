import DexTransactionsTotal, { IDexTransactionsTotal } from './DexTransactionsTotal';
import FinancialReport, { IFinancialReport } from './FinancialReport';
import OrdersSummary, { IOrdersSummary } from './OrdersSummary';
import SpotOrdersStatuses, { ISpotOrdersStatuses } from './SpotOrdersStatuses';
import SummaryBalance, { ISummaryBalance } from './SummaryBalance';
import SummaryBalanceHistory, { ISummaryBalanceHistory } from './SummaryBalanceHistory';
import TransferType, { ITransferType } from './TransferType';

// This file was autogenerated. Please do not change.
export interface ISummaryReport {
    balanceHistory?: ISummaryBalanceHistory;
    currentBalance?: ISummaryBalance;
    dexTransactions?: IDexTransactionsTotal;
    finance?: IFinancialReport;
    spotOrders?: IOrdersSummary;
    spotOrdersStatuses?: ISpotOrdersStatuses;
    transfers?: ITransferType[];
}

export default class SummaryReport {
    readonly _balanceHistory: SummaryBalanceHistory | undefined;

    get balanceHistory(): SummaryBalanceHistory | undefined {
        return this._balanceHistory;
    }

    readonly _currentBalance: SummaryBalance | undefined;

    get currentBalance(): SummaryBalance | undefined {
        return this._currentBalance;
    }

    readonly _dexTransactions: DexTransactionsTotal | undefined;

    get dexTransactions(): DexTransactionsTotal | undefined {
        return this._dexTransactions;
    }

    readonly _finance: FinancialReport | undefined;

    get finance(): FinancialReport | undefined {
        return this._finance;
    }

    readonly _spotOrders: OrdersSummary | undefined;

    get spotOrders(): OrdersSummary | undefined {
        return this._spotOrders;
    }

    readonly _spotOrdersStatuses: SpotOrdersStatuses | undefined;

    get spotOrdersStatuses(): SpotOrdersStatuses | undefined {
        return this._spotOrdersStatuses;
    }

    readonly _transfers: TransferType[] | undefined;

    get transfers(): TransferType[] | undefined {
        return this._transfers;
    }

    constructor(props: ISummaryReport) {
        if (props.balanceHistory) {
            this._balanceHistory = new SummaryBalanceHistory(props.balanceHistory);
        }
        if (props.currentBalance) {
            this._currentBalance = new SummaryBalance(props.currentBalance);
        }
        if (props.dexTransactions) {
            this._dexTransactions = new DexTransactionsTotal(props.dexTransactions);
        }
        if (props.finance) {
            this._finance = new FinancialReport(props.finance);
        }
        if (props.spotOrders) {
            this._spotOrders = new OrdersSummary(props.spotOrders);
        }
        if (props.spotOrdersStatuses) {
            this._spotOrdersStatuses = new SpotOrdersStatuses(props.spotOrdersStatuses);
        }
        if (props.transfers) {
            this._transfers = props.transfers.map((p) => new TransferType(p));
        }
    }

    serialize(): ISummaryReport {
        const data: ISummaryReport = {
        };
        if (typeof this._balanceHistory !== 'undefined') {
            data.balanceHistory = this._balanceHistory.serialize();
        }
        if (typeof this._currentBalance !== 'undefined') {
            data.currentBalance = this._currentBalance.serialize();
        }
        if (typeof this._dexTransactions !== 'undefined') {
            data.dexTransactions = this._dexTransactions.serialize();
        }
        if (typeof this._finance !== 'undefined') {
            data.finance = this._finance.serialize();
        }
        if (typeof this._spotOrders !== 'undefined') {
            data.spotOrders = this._spotOrders.serialize();
        }
        if (typeof this._spotOrdersStatuses !== 'undefined') {
            data.spotOrdersStatuses = this._spotOrdersStatuses.serialize();
        }
        if (typeof this._transfers !== 'undefined') {
            data.transfers = this._transfers.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): ISummaryReport {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            balanceHistory: !this._balanceHistory ? true : this._balanceHistory.validate().length === 0,
            currentBalance: !this._currentBalance ? true : this._currentBalance.validate().length === 0,
            dexTransactions: !this._dexTransactions ? true : this._dexTransactions.validate().length === 0,
            finance: !this._finance ? true : this._finance.validate().length === 0,
            spotOrders: !this._spotOrders ? true : this._spotOrders.validate().length === 0,
            spotOrdersStatuses: !this._spotOrdersStatuses ? true : this._spotOrdersStatuses.validate().length === 0,
            transfers: !this._transfers ? true : this._transfers.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
