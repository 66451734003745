// This file was autogenerated. Please do not change.
export interface IGetPositionRequest {
    aAssetSymbol: string;
    accountId: string;
    bAssetSymbol: string;
    blockchainName: string;
    fee: string;
    placementName: string;
}

export default class GetPositionRequest {
    readonly _aAssetSymbol: string;

    /**
     * Example: USDT
     */
    get aAssetSymbol(): string {
        return this._aAssetSymbol;
    }

    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _bAssetSymbol: string;

    /**
     * Example: BNB
     */
    get bAssetSymbol(): string {
        return this._bAssetSymbol;
    }

    readonly _blockchainName: string;

    /**
     * Example: BNB
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _fee: number;

    /**
     * Example: 0.01
     */
    get fee(): number {
        return this._fee;
    }

    readonly _placementName: string;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string {
        return this._placementName;
    }

    constructor(props: IGetPositionRequest) {
        this._aAssetSymbol = props.aAssetSymbol.trim();
        this._accountId = props.accountId.trim();
        this._bAssetSymbol = props.bAssetSymbol.trim();
        this._blockchainName = props.blockchainName.trim();
        this._fee = Number(props.fee);
        this._placementName = props.placementName.trim();
    }

    serialize(): IGetPositionRequest {
        const data: IGetPositionRequest = {
            aAssetSymbol: this._aAssetSymbol,
            accountId: this._accountId,
            bAssetSymbol: this._bAssetSymbol,
            blockchainName: this._blockchainName,
            fee: String(this._fee),
            placementName: this._placementName,
        };
        return data;
    }

    toJSON(): IGetPositionRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetSymbol: typeof this._aAssetSymbol === 'string' && !!this._aAssetSymbol.trim(),
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            bAssetSymbol: typeof this._bAssetSymbol === 'string' && !!this._bAssetSymbol.trim(),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            fee: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
