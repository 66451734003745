export const USER_GROUPS = {
    EMAIL_VERIFIED: 'EmailVerified',
    MFA_VERIFIED: 'MFAVerified',
};

export const MFA_ENROLL_FACTOR_TYPES = {
    TOTP: 'token:software:totp',
    EMAIL: 'email',
    EMAIL_TOTP: 'email-totp',

    SMS: 'sms_enroll_factor_type_plug',
};

export const MFA_FACTOR_TYPES = {
    TOTP: [
        MFA_ENROLL_FACTOR_TYPES.TOTP,
    ],
    EMAIL: [
        MFA_ENROLL_FACTOR_TYPES.EMAIL,
    ],
};

export const ISSUE_MFA_ACTIONS = {
    MFA_DISABLE: 'MFADisable',
    EMAIL_VERIFICATION: 'EmailVerification',
    PASSWORD_FORGOT: 'PasswordForgot',
    PASSWORD_CHANGE: 'PasswordChange',
    TRANSFER: 'Transfer',
    WITHDRAW: 'Withdraw',
    SHARE_ACCOUNT_ACCESS: 'ShareAccountAccess',
    CHANGE_ACCOUNT_ACCESS_PERMISSION: 'ChangeAccountAccessPermission',
};
