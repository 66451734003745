// This file was autogenerated. Please do not change.
export interface IFinancialReport {
    marginAvg?: string;
    profit?: string;
}

export default class FinancialReport {
    readonly _marginAvg: number | undefined;

    /**
     * Example: 0.03
     */
    get marginAvg(): number | undefined {
        return this._marginAvg;
    }

    readonly _profit: number | undefined;

    /**
     * Example: 147.40315193
     */
    get profit(): number | undefined {
        return this._profit;
    }

    constructor(props: IFinancialReport) {
        if (typeof props.marginAvg === 'string') {
            this._marginAvg = Number(props.marginAvg);
        }
        if (typeof props.profit === 'string') {
            this._profit = Number(props.profit);
        }
    }

    serialize(): IFinancialReport {
        const data: IFinancialReport = {
        };
        if (typeof this._marginAvg !== 'undefined') {
            data.marginAvg = String(this._marginAvg);
        }
        if (typeof this._profit !== 'undefined') {
            data.profit = String(this._profit);
        }
        return data;
    }

    toJSON(): IFinancialReport {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            marginAvg: true,
            profit: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
