// This file was autogenerated. Please do not change.
export interface IFiatTransferDetails {
    alias?: string;
}

export default class FiatTransferDetails {
    readonly _alias: string | undefined;

    /** Description: Alias for user requisites */
    get alias(): string | undefined {
        return this._alias;
    }

    constructor(props: IFiatTransferDetails) {
        if (typeof props.alias === 'string') {
            this._alias = props.alias.trim();
        }
    }

    serialize(): IFiatTransferDetails {
        const data: IFiatTransferDetails = {
        };
        if (typeof this._alias !== 'undefined') {
            data.alias = this._alias;
        }
        return data;
    }

    toJSON(): IFiatTransferDetails {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: !this._alias ? true : typeof this._alias === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
