<template>
    <CommonNotification v-if="isCommonNotification" :notification="notification.data"/>
    <IncomingAccountManagementRequest v-else-if="isIncomingAccountManagementRequest" :request="notification.data"/>
    <IncomingGroupRequest v-else-if="isGroupRequest" :request="notification.data" />
    <li v-html="notification.data" :class="s.text" v-else></li>
</template>

<script>
import { NotificationTypes } from 'Models/notifications';
import IncomingGroupRequest from 'Components/Header/Notifications/IncomingGroupRequest.vue';

import CommonNotification from './Notifications/CommonNotification.vue';
import IncomingAccountManagementRequest from './Notifications/IncomingAccountManagementRequest.vue';

export default {
    name: 'Notification',
    components: {
        CommonNotification,
        IncomingAccountManagementRequest,
        IncomingGroupRequest,
    },
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        type() {
            return this.notification.type;
        },

        isCommonNotification() {
            return this.type === NotificationTypes.COMMON_NOTIFICATION;
        },
        isIncomingAccountManagementRequest() {
            return this.type === NotificationTypes.INCOMING_ACCOUNT_MANAGEMENT_REQUEST;
        },
        isGroupRequest() {
            return this.type === NotificationTypes.INCOMING_GROUP_REQUEST;
        },
    },
};
</script>

<style lang="postcss" module="s">
.text {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-m);
    line-height: var(--fs-xl);
    color: var(--cl-gray);
    text-align: left;
}
</style>
