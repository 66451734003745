// This file was autogenerated. Please do not change.
export interface IBaseGroupResponse {
    description?: string;
    id?: string;
    name?: string;
}

export default class BaseGroupResponse {
    readonly _description: string | undefined;

    /** Description: Group description */
    get description(): string | undefined {
        return this._description;
    }

    readonly _id: string | undefined;

    /** Description: Group unique identifier */
    get id(): string | undefined {
        return this._id;
    }

    readonly _name: string | undefined;

    /** Description: Group name */
    get name(): string | undefined {
        return this._name;
    }

    constructor(props: IBaseGroupResponse) {
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
    }

    serialize(): IBaseGroupResponse {
        const data: IBaseGroupResponse = {
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        return data;
    }

    toJSON(): IBaseGroupResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            name: !this._name ? true : typeof this._name === 'string',
            description: !this._description ? true : typeof this._description === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
