// This file was autogenerated. Please do not change.
export interface IFeesResponse {
    high?: Record<string, number>;
    low?: Record<string, number>;
    medium: Record<string, number>;
}

export default class FeesResponse {
    readonly _high: Record<string, number> | undefined;

    /** Description: High fee values */
    get high(): Record<string, number> | undefined {
        return this._high;
    }

    readonly _low: Record<string, number> | undefined;

    /** Description: Low fee values */
    get low(): Record<string, number> | undefined {
        return this._low;
    }

    readonly _medium: Record<string, number>;

    /** Description: Medium fee values */
    get medium(): Record<string, number> {
        return this._medium;
    }

    constructor(props: IFeesResponse) {
        if (props.high) {
            this._high = props.high;
        }
        if (props.low) {
            this._low = props.low;
        }
        this._medium = props.medium;
    }

    serialize(): IFeesResponse {
        const data: IFeesResponse = {
            medium: this._medium,
        };
        if (typeof this._high !== 'undefined') {
            data.high = this._high;
        }
        if (typeof this._low !== 'undefined') {
            data.low = this._low;
        }
        return data;
    }

    toJSON(): IFeesResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
