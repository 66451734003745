// This file was autogenerated. Please do not change.
export interface IAccount {
    color?: string;
    description?: string;
    holdQuoted: string[];
    holdUsd: string;
    id: string;
    managersCount: number;
    name: string;
    ownerId: string;
    pendingPlacementLinks?: string[];
    placementLinks?: string[];
    policies: string[];
    quotationAssetSymbols: string[];
    role: string;
    status: ('active' | 'suspended' | 'blocked');
    totalQuoted: string[];
    totalUsd: string;
    totalUsdOtc: string;
}

export default class Account {
    readonly _color: string | undefined;

    /**
     * Example: white
     */
    get color(): string | undefined {
        return this._color;
    }

    readonly _description: string | undefined;

    /**
     * Example: My test account
     */
    get description(): string | undefined {
        return this._description;
    }

    readonly _holdQuoted: string[];

    /**
     * Example: 12.34,0.0000123
     */
    get holdQuoted(): string[] {
        return this._holdQuoted;
    }

    readonly _holdUsd: number;

    /**
     * Example: 12.34
     */
    get holdUsd(): number {
        return this._holdUsd;
    }

    readonly _id: string;

    /**
     * Example: AQVW2VSEUU1
     */
    get id(): string {
        return this._id;
    }

    readonly _managersCount: number;

    /**
     * Example: 1
     */
    get managersCount(): number {
        return this._managersCount;
    }

    readonly _name: string;

    /**
     * Example: test
     */
    get name(): string {
        return this._name;
    }

    readonly _ownerId: string;

    /**
     * Example: U4X4WUFD2
     */
    get ownerId(): string {
        return this._ownerId;
    }

    readonly _pendingPlacementLinks: string[] | undefined;

    /**
     * Example: Binance,Bitfinex
     */
    get pendingPlacementLinks(): string[] | undefined {
        return this._pendingPlacementLinks;
    }

    readonly _placementLinks: string[] | undefined;

    /**
     * Example: Single Broker,Binance,Bitfinex
     */
    get placementLinks(): string[] | undefined {
        return this._placementLinks;
    }

    readonly _policies: string[];

    /**
     * Example: order,withdraw,transfer,deposit,profile,display,account
     */
    get policies(): string[] {
        return this._policies;
    }

    readonly _quotationAssetSymbols: string[];

    /**
     * Example: USD,BTC
     */
    get quotationAssetSymbols(): string[] {
        return this._quotationAssetSymbols;
    }

    readonly _role: string;

    /**
     * Example: owner
     */
    get role(): string {
        return this._role;
    }

    readonly _status: ('active' | 'suspended' | 'blocked');

    /**
     * Example: active
     */
    get status(): ('active' | 'suspended' | 'blocked') {
        return this._status;
    }

    readonly _totalQuoted: string[];

    /**
     * Example: 123.45,0.0001312
     */
    get totalQuoted(): string[] {
        return this._totalQuoted;
    }

    readonly _totalUsd: number;

    /**
     * Example: 123.45
     */
    get totalUsd(): number {
        return this._totalUsd;
    }

    readonly _totalUsdOtc: number;

    /**
     * Example: 12.34
     */
    get totalUsdOtc(): number {
        return this._totalUsdOtc;
    }

    constructor(props: IAccount) {
        if (typeof props.color === 'string') {
            this._color = props.color.trim();
        }
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._holdQuoted = props.holdQuoted;
        this._holdUsd = Number(props.holdUsd);
        this._id = props.id.trim();
        this._managersCount = props.managersCount;
        this._name = props.name.trim();
        this._ownerId = props.ownerId.trim();
        if (props.pendingPlacementLinks) {
            this._pendingPlacementLinks = props.pendingPlacementLinks;
        }
        if (props.placementLinks) {
            this._placementLinks = props.placementLinks;
        }
        this._policies = props.policies;
        this._quotationAssetSymbols = props.quotationAssetSymbols;
        this._role = props.role.trim();
        this._status = props.status;
        this._totalQuoted = props.totalQuoted;
        this._totalUsd = Number(props.totalUsd);
        this._totalUsdOtc = Number(props.totalUsdOtc);
    }

    serialize(): IAccount {
        const data: IAccount = {
            holdQuoted: this._holdQuoted,
            holdUsd: String(this._holdUsd),
            id: this._id,
            managersCount: this._managersCount,
            name: this._name,
            ownerId: this._ownerId,
            policies: this._policies,
            quotationAssetSymbols: this._quotationAssetSymbols,
            role: this._role,
            status: this._status,
            totalQuoted: this._totalQuoted,
            totalUsd: String(this._totalUsd),
            totalUsdOtc: String(this._totalUsdOtc),
        };
        if (typeof this._color !== 'undefined') {
            data.color = this._color;
        }
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._pendingPlacementLinks !== 'undefined') {
            data.pendingPlacementLinks = this._pendingPlacementLinks;
        }
        if (typeof this._placementLinks !== 'undefined') {
            data.placementLinks = this._placementLinks;
        }
        return data;
    }

    toJSON(): IAccount {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            color: !this._color ? true : typeof this._color === 'string',
            description: !this._description ? true : typeof this._description === 'string',
            holdQuoted: this._holdQuoted.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            holdUsd: true,
            id: typeof this._id === 'string' && !!this._id.trim(),
            managersCount: typeof this._managersCount === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            ownerId: typeof this._ownerId === 'string' && !!this._ownerId.trim(),
            pendingPlacementLinks: !this._pendingPlacementLinks ? true : this._pendingPlacementLinks.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            placementLinks: !this._placementLinks ? true : this._placementLinks.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            policies: this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            quotationAssetSymbols: this._quotationAssetSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            role: typeof this._role === 'string' && !!this._role.trim(),
            totalQuoted: this._totalQuoted.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            totalUsd: true,
            totalUsdOtc: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
