// This file was autogenerated. Please do not change.
export interface IGetAllowanceResponse {
    quantity?: string;
}

export default class GetAllowanceResponse {
    readonly _quantity: number | undefined;

    /**
     * Example: 10.01
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    constructor(props: IGetAllowanceResponse) {
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
    }

    serialize(): IGetAllowanceResponse {
        const data: IGetAllowanceResponse = {
        };
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        return data;
    }

    toJSON(): IGetAllowanceResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
