// This file was autogenerated. Please do not change.
export interface ITimeResponse {
    time: number;
}

export default class TimeResponse {
    readonly _time: number;

    get time(): number {
        return this._time;
    }

    constructor(props: ITimeResponse) {
        this._time = props.time;
    }

    serialize(): ITimeResponse {
        const data: ITimeResponse = {
            time: this._time,
        };
        return data;
    }

    toJSON(): ITimeResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            time: typeof this._time === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
