import { Action } from 'vuex';

import UserApi from 'Apis/User';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import ApiError from 'Entities/ApiError';
import router from '@/router';

export const USER_RELOAD_INTERVAL = 60 * 1000;

const state = {
    currentUser: undefined as InternalUserResponse | undefined,
    currentUserStatus: undefined as number | undefined,
    inited: false,
};

export type UserState = typeof state;

export enum UserGetters {}

// type GettersReturn<G extends { [key in UserGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[UserGetters]> };
// interface Getters {}

const getters = {};

export enum UserMutations {
    SET_CURRENT_USER = 'SET_CURRENT_USER',
    SET_INITED = 'SET_INITED',
    SET_NEW_QUOTA = 'SET_NEW_QUOTA',
    SET_CURRENT_USER_STATUS = 'SET_CURRENT_USER_STATUS',
}

const mutations: Record<UserMutations, (state: UserState, ...args: any) => void> = {
    SET_CURRENT_USER(state, currentUser) {
        state.currentUser = currentUser;
    },
    SET_INITED(state, inited) {
        state.inited = inited;
    },
    SET_NEW_QUOTA(state, quota: number) {
        const user = state.currentUser?.serialize();
        user!.availableQuota = quota;
        state.currentUser = new InternalUserResponse(user!);
    },
    SET_CURRENT_USER_STATUS(state, status: number | undefined) {
        state.currentUserStatus = status;
    },
};

export enum UserActions {
    getCurrentUser = 'getCurrentUser'
}

const actions: Record<UserActions, Action<UserState, any>> = {
    async getCurrentUser({ commit, dispatch }) {
        try {
            const { data: user } = await UserApi.getCurrentUser();
            commit(UserMutations.SET_CURRENT_USER, user);
        } catch (error) {
            commit(UserMutations.SET_CURRENT_USER, undefined);
            if (error instanceof ApiError) {
                commit(UserMutations.SET_CURRENT_USER_STATUS, error.response?.status || undefined);
                if (state.currentUserStatus === 401) {
                    try {
                        await dispatch('Auth/logout', undefined, { root: true });
                        await router.push('/signin');
                    } catch {
                        document.location.reload();
                    }
                }
            } else {
                commit(UserMutations.SET_CURRENT_USER_STATUS, undefined);
            }
        } finally {
            commit(UserMutations.SET_INITED, true);
            setTimeout(() => {
                dispatch(UserActions.getCurrentUser);
            }, USER_RELOAD_INTERVAL);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
