import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import SpotAssetPair from 'Entities/publicPresenter/SpotAssetPair';
import FavoriteAssetPair from 'Entities/userSettings/FavoriteAssetPair';
import Asset from 'Entities/publicPresenter/Asset';
import { IAttributes } from 'Entities/publicPresenter/Attributes';
import {
    getLocalStorageActiveAssetPair,
    hasLocalStorageActiveAssetPair,
    setLocalStorageActiveAssetPair,
} from 'Models/assetPairs';
import PublicDataApi from 'Apis/PublicData';
import SpotAssetPairsRequest from 'Entities/publicPresenter/SpotAssetPairsRequest';
import SettingsApi from 'Apis/Settings';
import GetFavoriteAssetPairsRequest from 'Entities/userSettings/GetFavoriteAssetPairsRequest';
import ApiError from 'Entities/ApiError';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import SpotAssetPairRequest from 'Entities/publicPresenter/SpotAssetPairRequest';

const state = {
    spotAssetPairs: new Map<number, SpotAssetPair>(),
    workspaceSpotAssetPairs: new Map<string, Map<string, SpotAssetPair>>(),
    workspaceAssetPairsPlacements: new Set<string>(),
    currentPage: 0 as number,
    currentPlacementId: null as number | null,
    favoriteTools: [] as FavoriteAssetPair[],
    activeTerminalAssetPairId: null as number | null,
    isLoading: false as boolean,
    loaderId: null as any,
};

export type AssetPairsState = typeof state;

export enum AssetPairsGetters {
    GET_ASSET_PAIRS = 'GET_ASSET_PAIRS',
    GET_ACTIVE_TERMINAL_PLACEMENT_UPPERCASED_NAME = 'GET_ACTIVE_TERMINAL_PLACEMENT_UPPERCASED_NAME',
    GET_ASSET_PAIRS_BY_ASSET_SYMBOL = 'GET_ASSET_PAIRS_BY_ASSET_SYMBOL',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_ID = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_ID',
    GET_ACTIVE_TERMINAL_ASSET_PAIR = 'GET_ACTIVE_TERMINAL_ASSET_PAIR',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE',
    GET_ACTIVE_TERMINAL_ASSET_PAIR_ATTRIBUTES = 'GET_ACTIVE_TERMINAL_ASSET_PAIR_ATTRIBUTES',
    GET_ROUND_AMOUNT_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION = 'GET_ROUND_AMOUNT_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION',
    GET_ROUND_PRICE_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION = 'GET_ROUND_PRICE_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION',
    GET_IS_LOADING = 'GET_IS_LOADING',
    GET_LOADER_ID = 'GET_LOADER_ID',
}

type GettersReturn<G extends { [key in AssetPairsGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[AssetPairsGetters]> };

interface Getters {
    GET_ASSET_PAIRS: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => SpotAssetPair[];
    GET_ACTIVE_TERMINAL_PLACEMENT_UPPERCASED_NAME: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string | null;
    GET_ASSET_PAIRS_BY_ASSET_SYMBOL: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetSymbol: string) => SpotAssetPair[];
    GET_ACTIVE_TERMINAL_ASSET_PAIR_ID: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => number | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => SpotAssetPair | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => Asset | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => Asset | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string | null;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => number;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => number;
    GET_ACTIVE_TERMINAL_ASSET_PAIR_ATTRIBUTES: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => IAttributes | Record<string, never>;
    GET_ROUND_AMOUNT_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (price: string | number) => number;
    GET_ROUND_PRICE_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (price: string | number) => string;
    GET_IS_LOADING: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => boolean;
    GET_LOADER_ID: (state: AssetPairsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => any;
}

const getters: Getters = {
    GET_ASSET_PAIRS(state) {
        return Array.from(state.spotAssetPairs.values());
    },
    GET_ACTIVE_TERMINAL_PLACEMENT_UPPERCASED_NAME(state, getters, rootState, rootGetters) {
        return rootGetters['Placements/activeTerminalPlacementName'] ? rootGetters['Placements/activeTerminalPlacementName'].toUpperCase() : null;
    },
    GET_ASSET_PAIRS_BY_ASSET_SYMBOL(state, getters) {
        return (assetSymbol) => {
            return (getters.GET_ASSET_PAIRS as SpotAssetPair[]).filter(({ baseAssetSymbol, quoteAssetSymbol }) => baseAssetSymbol === assetSymbol || quoteAssetSymbol === assetSymbol);
        };
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_ID(state) {
        return state.activeTerminalAssetPairId;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR(state, getters) {
        if (typeof getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_ID !== 'number') {
            return null;
        }
        return state.spotAssetPairs.get(getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_ID as number) ?? null;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_SYMBOL(state, getters) {
        return getters.GET_ACTIVE_TERMINAL_ASSET_PAIR ? (getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair).symbol : null;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET(state, getters, rootState, rootGetters) {
        return getters.GET_ACTIVE_TERMINAL_ASSET_PAIR ? rootGetters['Assets/GET_ASSET_BY_SYMBOL']((getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair).baseAssetSymbol) : null;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET(state, getters, rootState, rootGetters) {
        return getters.GET_ACTIVE_TERMINAL_ASSET_PAIR ? rootGetters['Assets/GET_ASSET_BY_SYMBOL']((getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair).quoteAssetSymbol) : null;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL(state, getters) {
        return (getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair)?.baseAssetSymbol ?? null;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL(state, getters) {
        return (getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair)?.quoteAssetSymbol ?? null;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT(state, getters) {
        return (getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair)?.placementPrecisionQuantity ?? 0;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE(state, getters) {
        return (getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair)?.placementPrecisionPrice ?? 0;
    },
    GET_ACTIVE_TERMINAL_ASSET_PAIR_ATTRIBUTES(state, getters) {
        return (getters.GET_ACTIVE_TERMINAL_ASSET_PAIR as SpotAssetPair)?.placementAttributes ?? {};
    },
    GET_ROUND_AMOUNT_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION(state, getters, rootState, rootGetters) {
        return (price) => {
            return rootGetters.round(Number(price), getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT);
        };
    },
    GET_ROUND_PRICE_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION(state, getters) {
        return (price) => {
            return Number(price).toFixed(getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE as number);
        };
    },
    GET_IS_LOADING(state) {
        return state.isLoading;
    },
    GET_LOADER_ID(state) {
        return state.loaderId;
    },
};

export enum AssetPairsMutations {
    SET_SPOT_ASSET_PAIRS = 'SET_SPOT_ASSET_PAIRS',
    ADD_SPOT_ASSET_PAIR = 'ADD_SPOT_ASSET_PAIR',
    SET_CURRENT_PAGE_NUMBER = 'SET_CURRENT_PAGE_NUMBER',
    CLEAR_SPOT_ASSET_PAIRS = 'CLEAR_SPOT_ASSET_PAIRS',
    SET_ACTIVE_TERMINAL_ASSET_PAIR_ID = 'SET_ACTIVE_TERMINAL_ASSET_PAIR_ID',
    SET_LOADING_MODE = 'SET_LOADING_MODE',
    SET_LOADER_ID = 'SET_LOADER_ID',
    SET_CURRENT_PLACEMENT_ID = 'SET_CURRENT_PLACEMENT_ID',
    UPDATE_FAVORITE_TOOL = 'UPDATE_FAVORITE_TOOL',
    SET_WORKSPACE_ASSET_PAIRS = 'SET_WORKSPACE_ASSET_PAIRS',
}

export const SET_SPOT_ASSET_PAIRS = mutationCreator<SpotAssetPair[]>('AssetPairs', AssetPairsMutations.SET_SPOT_ASSET_PAIRS);
export const ADD_SPOT_ASSET_PAIR = mutationCreator<SpotAssetPair>('AssetPairs', AssetPairsMutations.ADD_SPOT_ASSET_PAIR);
export const SET_CURRENT_PAGE_NUMBER = mutationCreator<number>('AssetPairs', AssetPairsMutations.SET_CURRENT_PAGE_NUMBER);
export const CLEAR_SPOT_ASSET_PAIRS = mutationCreator<undefined>('AssetPairs', AssetPairsMutations.CLEAR_SPOT_ASSET_PAIRS);
export const SET_ACTIVE_TERMINAL_ASSET_PAIR_ID = mutationCreator<number>('AssetPairs', AssetPairsMutations.SET_ACTIVE_TERMINAL_ASSET_PAIR_ID);
export const SET_LOADING_MODE = mutationCreator<boolean>('AssetPairs', AssetPairsMutations.SET_LOADING_MODE);
export const SET_LOADER_ID = mutationCreator<any>('AssetPairs', AssetPairsMutations.SET_LOADER_ID);
export const SET_CURRENT_PLACEMENT_ID = mutationCreator<number>('AssetPairs', AssetPairsMutations.SET_CURRENT_PLACEMENT_ID);
export const UPDATE_FAVORITE_TOOL = mutationCreator<FavoriteAssetPair[]>('AssetPairs', AssetPairsMutations.UPDATE_FAVORITE_TOOL);
export const SET_WORKSPACE_ASSET_PAIRS = mutationCreator<{ placementName: string; assetPairs: SpotAssetPair[]; }>('AssetPairs', AssetPairsMutations.SET_WORKSPACE_ASSET_PAIRS);

const mutations: Record<AssetPairsMutations, (state: AssetPairsState, ...args: any) => void> = {
    SET_SPOT_ASSET_PAIRS(state, { payload: spotAssetPairs }: ReturnType<typeof SET_SPOT_ASSET_PAIRS>) {
        const newSpotPairs = new Map(state.spotAssetPairs);
        spotAssetPairs.forEach((pair) => {
            if (!newSpotPairs.has(pair.id)) {
                newSpotPairs.set(pair.id, pair);
            }
        });
        state.spotAssetPairs = newSpotPairs;
    },
    ADD_SPOT_ASSET_PAIR(state, { payload: assetPair }: ReturnType<typeof ADD_SPOT_ASSET_PAIR>) {
        if (!state.spotAssetPairs.get(assetPair.id)) {
            state.spotAssetPairs.set(assetPair.id, assetPair);
        }
    },
    SET_CURRENT_PAGE_NUMBER(state, { payload: number }: ReturnType<typeof SET_CURRENT_PAGE_NUMBER>) {
        state.currentPage = number;
    },
    CLEAR_SPOT_ASSET_PAIRS(state) {
        state.spotAssetPairs = new Map();
    },
    SET_ACTIVE_TERMINAL_ASSET_PAIR_ID(state, { payload: pairId }: ReturnType<typeof SET_ACTIVE_TERMINAL_ASSET_PAIR_ID>) {
        state.activeTerminalAssetPairId = Number(pairId);
    },
    SET_LOADING_MODE(state, { payload: mode }: ReturnType<typeof SET_LOADING_MODE>) {
        state.isLoading = mode;
    },
    SET_LOADER_ID(state, { payload: loaderId }: ReturnType<typeof SET_LOADER_ID>) {
        state.loaderId = loaderId;
    },
    SET_CURRENT_PLACEMENT_ID(state, { payload: id }: ReturnType<typeof SET_CURRENT_PLACEMENT_ID>) {
        state.currentPlacementId = id;
    },
    UPDATE_FAVORITE_TOOL(state, { payload: toolsList }: ReturnType<typeof UPDATE_FAVORITE_TOOL>) {
        state.favoriteTools = toolsList;
    },
    SET_WORKSPACE_ASSET_PAIRS(state, { payload: { placementName, assetPairs } }: ReturnType<typeof SET_WORKSPACE_ASSET_PAIRS>) {
        const newMap = new Map(state.workspaceSpotAssetPairs);
        const assetPairsMap = new Map<string, SpotAssetPair>();
        assetPairs.forEach((el) => {
            assetPairsMap.set(el.symbol.toUpperCase(), el);
        });
        newMap.set(placementName.toUpperCase(), assetPairsMap);
        state.workspaceSpotAssetPairs = newMap;
    },
};

export enum AssetPairsActions {
    setLoaderId = 'setLoaderId',
    setSpotAssetPairs = 'setSpotAssetPairs',
    updateSpotAssetPairsList = 'updateSpotAssetPairsList',
    getFavoriteAssetPairsList = 'getFavoriteAssetPairsList',
    setActiveTerminalAssetPairId = 'setActiveTerminalAssetPairId',
    getNewWorkspaceSpotAssetPairs = 'getNewWorkspaceSpotAssetPairs',
}

export const setLoaderId = actionCreator<any>('AssetPairs', AssetPairsActions.setLoaderId);
export const setSpotAssetPairs = actionCreator<SpotAssetPair[]>('AssetPairs', AssetPairsActions.setSpotAssetPairs);
export const updateSpotAssetPairsList = actionCreator<{ placementId: number; isFirstDownload: boolean; } | Record<string, never>>('AssetPairs', AssetPairsActions.updateSpotAssetPairsList);
export const getFavoriteAssetPairsList = actionCreator<undefined>('AssetPairs', AssetPairsActions.getFavoriteAssetPairsList);
export const setActiveTerminalAssetPairId = actionCreator<number>('AssetPairs', AssetPairsActions.setActiveTerminalAssetPairId);
export const getNewWorkspaceSpotAssetPairs = actionCreator<string>('AssetPairs', AssetPairsActions.getNewWorkspaceSpotAssetPairs);

const actions: Record<AssetPairsActions, Action<AssetPairsState, any>> = {
    setLoaderId({ commit }, { payload: loaderId }: ReturnType<typeof setLoaderId>) {
        commit(SET_LOADER_ID(loaderId, true));
    },
    async setSpotAssetPairs({ state, commit, getters, dispatch }, { payload: spotAssetPairs }: ReturnType<typeof setSpotAssetPairs>) {
        const isFirstUpload = getters.GET_ASSET_PAIRS.length === 0;

        commit(SET_SPOT_ASSET_PAIRS(spotAssetPairs, true));

        if (spotAssetPairs.length > 0 && !spotAssetPairs.some((assetPair) => assetPair.id === getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_ID) && state.currentPage === 1) {
            if (isFirstUpload && hasLocalStorageActiveAssetPair() && spotAssetPairs.some(({ id }) => Number(id) === Number(getLocalStorageActiveAssetPair()!))) {
                await dispatch(setActiveTerminalAssetPairId(Number(getLocalStorageActiveAssetPair()), true));
            } else {
                await dispatch(setActiveTerminalAssetPairId(spotAssetPairs[0].id, true));
            }
        }
    },
    async updateSpotAssetPairsList({ getters, state, rootGetters, commit, dispatch }, { payload: { placementId, isFirstDownload } }: ReturnType<typeof updateSpotAssetPairsList>) {
        try {
            commit(SET_LOADING_MODE(true, true));
            if (isFirstDownload) {
                commit(SET_CURRENT_PAGE_NUMBER(0, true));
                commit(CLEAR_SPOT_ASSET_PAIRS(undefined, true));
                commit(SET_CURRENT_PLACEMENT_ID(placementId, true));
                await dispatch(getFavoriteAssetPairsList(undefined, true));
            } else if (state.currentPlacementId) {
                if (state.currentPlacementId !== placementId) {
                    commit(SET_CURRENT_PAGE_NUMBER(0, true));
                    commit(CLEAR_SPOT_ASSET_PAIRS(undefined, true));
                    commit(SET_CURRENT_PLACEMENT_ID(placementId, true));
                    await dispatch(getFavoriteAssetPairsList(undefined, true));
                }
            } else {
                commit(SET_CURRENT_PLACEMENT_ID(placementId, true));
                await dispatch(getFavoriteAssetPairsList(undefined, true));
            }

            const currentLoaderId = rootGetters.generateId();
            await dispatch(setLoaderId(currentLoaderId, true));
            commit(SET_CURRENT_PAGE_NUMBER(state.currentPage + 1, true));
            const { data: spotAssetPairs } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                placementId,
                perPage: 300,
                page: state.currentPage,
            }));

            if (!spotAssetPairs.some(({ id }) => id === getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_ID || id === Number(getLocalStorageActiveAssetPair()))) {
                try {
                    const { data: assetPair } = await PublicDataApi.publicGetSpotAssetPair(new SpotAssetPairRequest({
                        id: getters.GET_ACTIVE_TERMINAL_ASSET_PAIR_ID ?? Number(getLocalStorageActiveAssetPair()),
                        placementId: Number(state.currentPlacementId),
                    }));
                    spotAssetPairs.push(assetPair);
                } catch {
                    // Asset pair not found
                }
            }

            if (currentLoaderId !== getters.GET_LOADER_ID) {
                return false;
            }

            await dispatch(setSpotAssetPairs(spotAssetPairs, true));

            return Promise.resolve(spotAssetPairs);
        } catch (error) {
            return Promise.reject(error);
        } finally {
            commit(SET_LOADING_MODE(false, true));
        }
    },
    async getFavoriteAssetPairsList({ dispatch, commit }) {
        try {
            const { data: toolsList } = await SettingsApi.getFavorites(new GetFavoriteAssetPairsRequest({}));
            commit(UPDATE_FAVORITE_TOOL(toolsList, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error during getting favorite tools', { root: true });
            }
        }
    },
    setActiveTerminalAssetPairId({ commit }, { payload: pairId }: ReturnType<typeof setActiveTerminalAssetPairId>) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            commit(SET_ACTIVE_TERMINAL_ASSET_PAIR_ID(pairId, true));

            setLocalStorageActiveAssetPair(pairId);
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
    async getNewWorkspaceSpotAssetPairs({ state, commit, rootGetters }, { payload: placementName }: ReturnType<typeof getNewWorkspaceSpotAssetPairs>) {
        if (state.workspaceAssetPairsPlacements.has(placementName) || !placementName) {
            return;
        }
        state.workspaceAssetPairsPlacements.add(placementName);
        const realPlacementName = rootGetters['Placements/placements'].find(({ name }) => name.toLowerCase() === placementName.toLowerCase())?.name;
        if (!realPlacementName) {
            return;
        }
        let allSpotAssetPairs: SpotAssetPair[] = [];
        const { data: assetPairs, headers } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
            page: 1,
            perPage: 300,
            includeTotal: true,
            placementName: realPlacementName,
        }), true);
        allSpotAssetPairs = [...assetPairs];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraAssetPairs } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                        page: i,
                        perPage: 300,
                        placementName: realPlacementName,
                    }));
                    allSpotAssetPairs = [...allSpotAssetPairs, ...extraAssetPairs];
                }
            }
        }
        commit(SET_WORKSPACE_ASSET_PAIRS({ placementName, assetPairs: allSpotAssetPairs }, true));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
