// This file was autogenerated. Please do not change.
export interface IGeoContextObject {
    city?: string;
    country?: string;
    state?: string;
}

export default class GeoContextObject {
    readonly _city: string | undefined;

    /** Description: City */
    get city(): string | undefined {
        return this._city;
    }

    readonly _country: string | undefined;

    /** Description: Country */
    get country(): string | undefined {
        return this._country;
    }

    readonly _state: string | undefined;

    /** Description: State */
    get state(): string | undefined {
        return this._state;
    }

    constructor(props: IGeoContextObject) {
        if (typeof props.city === 'string') {
            this._city = props.city.trim();
        }
        if (typeof props.country === 'string') {
            this._country = props.country.trim();
        }
        if (typeof props.state === 'string') {
            this._state = props.state.trim();
        }
    }

    serialize(): IGeoContextObject {
        const data: IGeoContextObject = {
        };
        if (typeof this._city !== 'undefined') {
            data.city = this._city;
        }
        if (typeof this._country !== 'undefined') {
            data.country = this._country;
        }
        if (typeof this._state !== 'undefined') {
            data.state = this._state;
        }
        return data;
    }

    toJSON(): IGeoContextObject {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            city: !this._city ? true : typeof this._city === 'string',
            country: !this._country ? true : typeof this._country === 'string',
            state: !this._state ? true : typeof this._state === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
