// This file was autogenerated. Please do not change.
export interface IFuturesPosition {
    accountId: string;
    balanceId: string;
    contractSymbol: string;
    entryPrice: string;
    leverage: string;
    liquidationPrice: string;
    margin: string;
    markPrice: string;
    placementName: string;
    profit: string;
    profitPercent: number;
    quantity: string;
    quantityUnit: string;
    quotationAssetSymbol: string;
    quoteQuantity: string;
    quotedQuantity: string;
    quotedUnrealizedProfit: string;
    side: string;
    unrealizedProfit: string;
    updatedAt: string;
}

export default class FuturesPosition {
    readonly _accountId: string;

    /**
     * Example: A5Q98WZ7PDX
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _balanceId: string;

    /**
     * Example: BNZQ2DWD9W6
     */
    get balanceId(): string {
        return this._balanceId;
    }

    readonly _contractSymbol: string;

    /**
     * Example: XRP/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _entryPrice: number;

    /**
     * Example: 0.0172
     */
    get entryPrice(): number {
        return this._entryPrice;
    }

    readonly _leverage: string;

    /**
     * Example: 5
     */
    get leverage(): string {
        return this._leverage;
    }

    readonly _liquidationPrice: number;

    /**
     * Example: 0.0172
     */
    get liquidationPrice(): number {
        return this._liquidationPrice;
    }

    readonly _margin: number;

    /**
     * Example: 0.0172
     */
    get margin(): number {
        return this._margin;
    }

    readonly _markPrice: string;

    /**
     * Example: 11.01
     */
    get markPrice(): string {
        return this._markPrice;
    }

    readonly _placementName: string;

    /**
     * Example: Binance Futures
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _profit: number;

    /**
     * Example: 0.0172
     */
    get profit(): number {
        return this._profit;
    }

    readonly _profitPercent: number;

    /**
     * Example: 11.01
     */
    get profitPercent(): number {
        return this._profitPercent;
    }

    readonly _quantity: number;

    /**
     * Example: 0.0172
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _quantityUnit: string;

    /**
     * Example: contract
     */
    get quantityUnit(): string {
        return this._quantityUnit;
    }

    readonly _quotationAssetSymbol: string;

    /**
     * Example: USD
     */
    get quotationAssetSymbol(): string {
        return this._quotationAssetSymbol;
    }

    readonly _quoteQuantity: number;

    /**
     * Example: 0.0172
     */
    get quoteQuantity(): number {
        return this._quoteQuantity;
    }

    readonly _quotedQuantity: number;

    /**
     * Example: 0.9374
     */
    get quotedQuantity(): number {
        return this._quotedQuantity;
    }

    readonly _quotedUnrealizedProfit: number;

    /**
     * Example: 0.0172
     */
    get quotedUnrealizedProfit(): number {
        return this._quotedUnrealizedProfit;
    }

    readonly _side: string;

    /**
     * Example: long
     */
    get side(): string {
        return this._side;
    }

    readonly _unrealizedProfit: number;

    /**
     * Example: 0.0172
     */
    get unrealizedProfit(): number {
        return this._unrealizedProfit;
    }

    readonly _updatedAt: string;

    /**
     * Example: 1660221027
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IFuturesPosition) {
        this._accountId = props.accountId.trim();
        this._balanceId = props.balanceId.trim();
        this._contractSymbol = props.contractSymbol.trim();
        this._entryPrice = Number(props.entryPrice);
        this._leverage = props.leverage.trim();
        this._liquidationPrice = Number(props.liquidationPrice);
        this._margin = Number(props.margin);
        this._markPrice = props.markPrice.trim();
        this._placementName = props.placementName.trim();
        this._profit = Number(props.profit);
        this._profitPercent = props.profitPercent;
        this._quantity = Number(props.quantity);
        this._quantityUnit = props.quantityUnit.trim();
        this._quotationAssetSymbol = props.quotationAssetSymbol.trim();
        this._quoteQuantity = Number(props.quoteQuantity);
        this._quotedQuantity = Number(props.quotedQuantity);
        this._quotedUnrealizedProfit = Number(props.quotedUnrealizedProfit);
        this._side = props.side.trim();
        this._unrealizedProfit = Number(props.unrealizedProfit);
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IFuturesPosition {
        const data: IFuturesPosition = {
            accountId: this._accountId,
            balanceId: this._balanceId,
            contractSymbol: this._contractSymbol,
            entryPrice: String(this._entryPrice),
            leverage: this._leverage,
            liquidationPrice: String(this._liquidationPrice),
            margin: String(this._margin),
            markPrice: this._markPrice,
            placementName: this._placementName,
            profit: String(this._profit),
            profitPercent: this._profitPercent,
            quantity: String(this._quantity),
            quantityUnit: this._quantityUnit,
            quotationAssetSymbol: this._quotationAssetSymbol,
            quoteQuantity: String(this._quoteQuantity),
            quotedQuantity: String(this._quotedQuantity),
            quotedUnrealizedProfit: String(this._quotedUnrealizedProfit),
            side: this._side,
            unrealizedProfit: String(this._unrealizedProfit),
            updatedAt: this._updatedAt,
        };
        return data;
    }

    toJSON(): IFuturesPosition {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            balanceId: typeof this._balanceId === 'string' && !!this._balanceId.trim(),
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            entryPrice: true,
            leverage: typeof this._leverage === 'string' && !!this._leverage.trim(),
            liquidationPrice: true,
            margin: true,
            markPrice: typeof this._markPrice === 'string' && !!this._markPrice.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            profit: true,
            profitPercent: typeof this._profitPercent === 'number',
            quantity: true,
            quantityUnit: typeof this._quantityUnit === 'string' && !!this._quantityUnit.trim(),
            quotationAssetSymbol: typeof this._quotationAssetSymbol === 'string' && !!this._quotationAssetSymbol.trim(),
            quoteQuantity: true,
            quotedQuantity: true,
            quotedUnrealizedProfit: true,
            side: typeof this._side === 'string' && !!this._side.trim(),
            unrealizedProfit: true,
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
