const state = {
    currentCurrencyIndex: null,
    currentAddressData: null,
};

const getters = {
    currenciesList: (state, getters, rootState, rootGetters) => rootGetters['Assets/GET_ASSETS'].filter(({ symbol }) => {
        const transferableAssets = rootGetters['Transactions/Transfer/transferablePlacementAssets'](rootGetters['Placements/platformPlacementName']);

        // eslint-disable-next-line no-prototype-builtins
        return transferableAssets.hasOwnProperty(symbol);
    }),
    currentCurrencyIndex: (state) => state.currentCurrencyIndex,
    activeCurrencyData: (state, getters) => (
        getters.currenciesList.length > 0 && getters.currentCurrencyIndex !== null
            ? getters.currenciesList[getters.currentCurrencyIndex]
            : {}
    ),
    activeCurrencySymbol: (state, getters) => getters.activeCurrencyData.symbol,
    currentAddressData: (state) => state.currentAddressData,
};

const mutations = {
    SET_CURRENT_CURRENCY_INDEX(state, index) {
        state.currentCurrencyIndex = index;
    },
    SET_CURRENT_ADDRESS_DATA(state, value) {
        state.currentAddressData = value;
    },
};

const actions = {
    setCurrentAddressData({ commit }, value) {
        commit('SET_CURRENT_ADDRESS_DATA', value);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
