// This file was autogenerated. Please do not change.
export interface IStakingPosition {
    accountId: string;
    accrualDays?: number;
    amount: string;
    apr: string;
    asset: string;
    createdAt: string;
    daysLeft?: number;
    deliverDate?: string;
    duration: number;
    interestEndDate?: string;
    interestPeriod?: number;
    nextInterestAmount?: string;
    nextInterestDate?: string;
    placementName: string;
    purchasedAt?: string;
    redeemAmount?: string;
    redeemPeriod?: number;
    rewardAmount?: string;
    rewardAsset: string;
    status: ('CREATED' | 'HOLDING' | 'REDEEMING' | 'CLOSED');
    uid: string;
    updatedAt: string;
}

export default class StakingPosition {
    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _accrualDays: number | undefined;

    /**
     * Example: 2
     */
    get accrualDays(): number | undefined {
        return this._accrualDays;
    }

    readonly _amount: number;

    /**
     * Example: 0.1
     */
    get amount(): number {
        return this._amount;
    }

    readonly _apr: number;

    /**
     * Example: 0.15
     */
    get apr(): number {
        return this._apr;
    }

    readonly _asset: string;

    /**
     * Example: ETH
     */
    get asset(): string {
        return this._asset;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _daysLeft: number | undefined;

    /**
     * Example: 88
     */
    get daysLeft(): number | undefined {
        return this._daysLeft;
    }

    readonly _deliverDate: string | undefined;

    /**
     * Example: 2021-04-20T00:00:00.000000Z
     */
    get deliverDate(): string | undefined {
        return this._deliverDate;
    }

    readonly _duration: number;

    /**
     * Example: 30
     */
    get duration(): number {
        return this._duration;
    }

    readonly _interestEndDate: string | undefined;

    /**
     * Example: 2021-04-20T00:00:00.000000Z
     */
    get interestEndDate(): string | undefined {
        return this._interestEndDate;
    }

    readonly _interestPeriod: number | undefined;

    /**
     * Example: 1
     */
    get interestPeriod(): number | undefined {
        return this._interestPeriod;
    }

    readonly _nextInterestAmount: number | undefined;

    /**
     * Example: 0.1
     */
    get nextInterestAmount(): number | undefined {
        return this._nextInterestAmount;
    }

    readonly _nextInterestDate: string | undefined;

    /**
     * Example: 2021-04-20T00:00:00.000000Z
     */
    get nextInterestDate(): string | undefined {
        return this._nextInterestDate;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _purchasedAt: string | undefined;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get purchasedAt(): string | undefined {
        return this._purchasedAt;
    }

    readonly _redeemAmount: number | undefined;

    /**
     * Example: 0.1
     */
    get redeemAmount(): number | undefined {
        return this._redeemAmount;
    }

    readonly _redeemPeriod: number | undefined;

    /**
     * Example: 1
     */
    get redeemPeriod(): number | undefined {
        return this._redeemPeriod;
    }

    readonly _rewardAmount: number | undefined;

    /**
     * Example: 0.1
     */
    get rewardAmount(): number | undefined {
        return this._rewardAmount;
    }

    readonly _rewardAsset: string;

    /**
     * Example: ETH
     */
    get rewardAsset(): string {
        return this._rewardAsset;
    }

    readonly _status: ('CREATED' | 'HOLDING' | 'REDEEMING' | 'CLOSED');

    get status(): ('CREATED' | 'HOLDING' | 'REDEEMING' | 'CLOSED') {
        return this._status;
    }

    readonly _uid: string;

    /**
     * Example: SPKP2W4P2VHEKP2W4P2VHE
     */
    get uid(): string {
        return this._uid;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IStakingPosition) {
        this._accountId = props.accountId.trim();
        if (typeof props.accrualDays === 'number') {
            this._accrualDays = props.accrualDays;
        }
        this._amount = Number(props.amount);
        this._apr = Number(props.apr);
        this._asset = props.asset.trim();
        this._createdAt = props.createdAt.trim();
        if (typeof props.daysLeft === 'number') {
            this._daysLeft = props.daysLeft;
        }
        if (typeof props.deliverDate === 'string') {
            this._deliverDate = props.deliverDate.trim();
        }
        this._duration = props.duration;
        if (typeof props.interestEndDate === 'string') {
            this._interestEndDate = props.interestEndDate.trim();
        }
        if (typeof props.interestPeriod === 'number') {
            this._interestPeriod = props.interestPeriod;
        }
        if (typeof props.nextInterestAmount === 'string') {
            this._nextInterestAmount = Number(props.nextInterestAmount);
        }
        if (typeof props.nextInterestDate === 'string') {
            this._nextInterestDate = props.nextInterestDate.trim();
        }
        this._placementName = props.placementName.trim();
        if (typeof props.purchasedAt === 'string') {
            this._purchasedAt = props.purchasedAt.trim();
        }
        if (typeof props.redeemAmount === 'string') {
            this._redeemAmount = Number(props.redeemAmount);
        }
        if (typeof props.redeemPeriod === 'number') {
            this._redeemPeriod = props.redeemPeriod;
        }
        if (typeof props.rewardAmount === 'string') {
            this._rewardAmount = Number(props.rewardAmount);
        }
        this._rewardAsset = props.rewardAsset.trim();
        this._status = props.status;
        this._uid = props.uid.trim();
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IStakingPosition {
        const data: IStakingPosition = {
            accountId: this._accountId,
            amount: String(this._amount),
            apr: String(this._apr),
            asset: this._asset,
            createdAt: this._createdAt,
            duration: this._duration,
            placementName: this._placementName,
            rewardAsset: this._rewardAsset,
            status: this._status,
            uid: this._uid,
            updatedAt: this._updatedAt,
        };
        if (typeof this._accrualDays !== 'undefined') {
            data.accrualDays = this._accrualDays;
        }
        if (typeof this._daysLeft !== 'undefined') {
            data.daysLeft = this._daysLeft;
        }
        if (typeof this._deliverDate !== 'undefined') {
            data.deliverDate = this._deliverDate;
        }
        if (typeof this._interestEndDate !== 'undefined') {
            data.interestEndDate = this._interestEndDate;
        }
        if (typeof this._interestPeriod !== 'undefined') {
            data.interestPeriod = this._interestPeriod;
        }
        if (typeof this._nextInterestAmount !== 'undefined') {
            data.nextInterestAmount = String(this._nextInterestAmount);
        }
        if (typeof this._nextInterestDate !== 'undefined') {
            data.nextInterestDate = this._nextInterestDate;
        }
        if (typeof this._purchasedAt !== 'undefined') {
            data.purchasedAt = this._purchasedAt;
        }
        if (typeof this._redeemAmount !== 'undefined') {
            data.redeemAmount = String(this._redeemAmount);
        }
        if (typeof this._redeemPeriod !== 'undefined') {
            data.redeemPeriod = this._redeemPeriod;
        }
        if (typeof this._rewardAmount !== 'undefined') {
            data.rewardAmount = String(this._rewardAmount);
        }
        return data;
    }

    toJSON(): IStakingPosition {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            accrualDays: !this._accrualDays ? true : typeof this._accrualDays === 'number',
            amount: true,
            apr: true,
            asset: typeof this._asset === 'string' && !!this._asset.trim(),
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            daysLeft: !this._daysLeft ? true : typeof this._daysLeft === 'number',
            deliverDate: !this._deliverDate ? true : typeof this._deliverDate === 'string',
            duration: typeof this._duration === 'number',
            interestEndDate: !this._interestEndDate ? true : typeof this._interestEndDate === 'string',
            interestPeriod: !this._interestPeriod ? true : typeof this._interestPeriod === 'number',
            nextInterestAmount: true,
            nextInterestDate: !this._nextInterestDate ? true : typeof this._nextInterestDate === 'string',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            purchasedAt: !this._purchasedAt ? true : typeof this._purchasedAt === 'string',
            redeemAmount: true,
            redeemPeriod: !this._redeemPeriod ? true : typeof this._redeemPeriod === 'number',
            rewardAmount: true,
            rewardAsset: typeof this._rewardAsset === 'string' && !!this._rewardAsset.trim(),
            uid: typeof this._uid === 'string' && !!this._uid.trim(),
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
