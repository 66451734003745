import FuturesTradeQuotation, { IFuturesTradeQuotation } from './FuturesTradeQuotation';

// This file was autogenerated. Please do not change.
export interface IFuturesTrade {
    accountId: string;
    baseBalanceId?: string;
    baseQuantity: string;
    commissionAssetSymbol: string;
    commissionQuantity: string;
    createdAt: string;
    id: string;
    isBuy?: boolean;
    orderId: string;
    orderInitiatorType?: string;
    orderType?: ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | ' FUTURES_MARKET_STOP');
    outerId?: string;
    outerTimestamp?: string;
    placementName: string;
    positionSide?: ('long' | 'short' | 'both');
    price: string;
    quantity: string;
    quantityUnit: ('contract' | 'base' | 'quote');
    quotations?: IFuturesTradeQuotation[];
    quoteQuantity: string;
    spotAssetPairSymbol?: string;
    spotOrderPrice?: string;
    updatedAt: string;
}

export default class FuturesTrade {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _baseBalanceId: string | undefined;

    /**
     * Example: BL5LP5UP9VW
     */
    get baseBalanceId(): string | undefined {
        return this._baseBalanceId;
    }

    readonly _baseQuantity: number;

    /**
     * Example: 0.65
     */
    get baseQuantity(): number {
        return this._baseQuantity;
    }

    readonly _commissionAssetSymbol: string;

    /**
     * Example: USDT
     */
    get commissionAssetSymbol(): string {
        return this._commissionAssetSymbol;
    }

    readonly _commissionQuantity: number;

    /**
     * Example: 0.65
     */
    get commissionQuantity(): number {
        return this._commissionQuantity;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _id: string;

    /**
     * Example: FTDF7SWXE262DD5C9YADJLX
     */
    get id(): string {
        return this._id;
    }

    readonly _isBuy: boolean | undefined;

    get isBuy(): boolean | undefined {
        return this._isBuy;
    }

    readonly _orderId: string;

    /**
     * Example: FOJS2CHEHXLH5LD75Y11VL
     */
    get orderId(): string {
        return this._orderId;
    }

    readonly _orderInitiatorType: string | undefined;

    /**
     * Example: placements
     */
    get orderInitiatorType(): string | undefined {
        return this._orderInitiatorType;
    }

    readonly _orderType: ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | ' FUTURES_MARKET_STOP') | undefined;

    /**
     * Example: FUTURES_LIMIT
     */
    get orderType(): ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | ' FUTURES_MARKET_STOP') | undefined {
        return this._orderType;
    }

    readonly _outerId: string | undefined;

    /**
     * Example: 37791802
     */
    get outerId(): string | undefined {
        return this._outerId;
    }

    readonly _outerTimestamp: string | undefined;

    /**
     * Example: 2021-04-20T14:04:06.38Z
     */
    get outerTimestamp(): string | undefined {
        return this._outerTimestamp;
    }

    readonly _placementName: string;

    /**
     * Example: Binance Futures
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _positionSide: ('long' | 'short' | 'both') | undefined;

    /**
     * Example: long
     */
    get positionSide(): ('long' | 'short' | 'both') | undefined {
        return this._positionSide;
    }

    readonly _price: number;

    /**
     * Example: 30.8572
     */
    get price(): number {
        return this._price;
    }

    readonly _quantity: number;

    /**
     * Example: 0.65
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _quantityUnit: ('contract' | 'base' | 'quote');

    /**
     * Example: contract
     */
    get quantityUnit(): ('contract' | 'base' | 'quote') {
        return this._quantityUnit;
    }

    readonly _quotations: FuturesTradeQuotation[] | undefined;

    get quotations(): FuturesTradeQuotation[] | undefined {
        return this._quotations;
    }

    readonly _quoteQuantity: number;

    /**
     * Example: 20.05718
     */
    get quoteQuantity(): number {
        return this._quoteQuantity;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: XRP/USDT
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    readonly _spotOrderPrice: number | undefined;

    /**
     * Example: 30.8371
     */
    get spotOrderPrice(): number | undefined {
        return this._spotOrderPrice;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IFuturesTrade) {
        this._accountId = props.accountId.trim();
        if (typeof props.baseBalanceId === 'string') {
            this._baseBalanceId = props.baseBalanceId.trim();
        }
        this._baseQuantity = Number(props.baseQuantity);
        this._commissionAssetSymbol = props.commissionAssetSymbol.trim();
        this._commissionQuantity = Number(props.commissionQuantity);
        this._createdAt = props.createdAt.trim();
        this._id = props.id.trim();
        if (typeof props.isBuy === 'boolean') {
            this._isBuy = props.isBuy;
        }
        this._orderId = props.orderId.trim();
        if (typeof props.orderInitiatorType === 'string') {
            this._orderInitiatorType = props.orderInitiatorType.trim();
        }
        if (props.orderType) {
            this._orderType = props.orderType;
        }
        if (typeof props.outerId === 'string') {
            this._outerId = props.outerId.trim();
        }
        if (typeof props.outerTimestamp === 'string') {
            this._outerTimestamp = props.outerTimestamp.trim();
        }
        this._placementName = props.placementName.trim();
        if (props.positionSide) {
            this._positionSide = props.positionSide;
        }
        this._price = Number(props.price);
        this._quantity = Number(props.quantity);
        this._quantityUnit = props.quantityUnit;
        if (props.quotations) {
            this._quotations = props.quotations.map((p) => new FuturesTradeQuotation(p));
        }
        this._quoteQuantity = Number(props.quoteQuantity);
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
        if (typeof props.spotOrderPrice === 'string') {
            this._spotOrderPrice = Number(props.spotOrderPrice);
        }
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IFuturesTrade {
        const data: IFuturesTrade = {
            accountId: this._accountId,
            baseQuantity: String(this._baseQuantity),
            commissionAssetSymbol: this._commissionAssetSymbol,
            commissionQuantity: String(this._commissionQuantity),
            createdAt: this._createdAt,
            id: this._id,
            orderId: this._orderId,
            placementName: this._placementName,
            price: String(this._price),
            quantity: String(this._quantity),
            quantityUnit: this._quantityUnit,
            quoteQuantity: String(this._quoteQuantity),
            updatedAt: this._updatedAt,
        };
        if (typeof this._baseBalanceId !== 'undefined') {
            data.baseBalanceId = this._baseBalanceId;
        }
        if (typeof this._isBuy !== 'undefined') {
            data.isBuy = this._isBuy;
        }
        if (typeof this._orderInitiatorType !== 'undefined') {
            data.orderInitiatorType = this._orderInitiatorType;
        }
        if (typeof this._orderType !== 'undefined') {
            data.orderType = this._orderType;
        }
        if (typeof this._outerId !== 'undefined') {
            data.outerId = this._outerId;
        }
        if (typeof this._outerTimestamp !== 'undefined') {
            data.outerTimestamp = this._outerTimestamp;
        }
        if (typeof this._positionSide !== 'undefined') {
            data.positionSide = this._positionSide;
        }
        if (typeof this._quotations !== 'undefined') {
            data.quotations = this._quotations.map((p) => p.serialize());
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        if (typeof this._spotOrderPrice !== 'undefined') {
            data.spotOrderPrice = String(this._spotOrderPrice);
        }
        return data;
    }

    toJSON(): IFuturesTrade {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            baseBalanceId: !this._baseBalanceId ? true : typeof this._baseBalanceId === 'string',
            baseQuantity: true,
            commissionAssetSymbol: typeof this._commissionAssetSymbol === 'string' && !!this._commissionAssetSymbol.trim(),
            commissionQuantity: true,
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            id: typeof this._id === 'string' && !!this._id.trim(),
            isBuy: !this._isBuy ? true : typeof this._isBuy === 'boolean',
            orderId: typeof this._orderId === 'string' && !!this._orderId.trim(),
            orderInitiatorType: !this._orderInitiatorType ? true : typeof this._orderInitiatorType === 'string',
            outerId: !this._outerId ? true : typeof this._outerId === 'string',
            outerTimestamp: !this._outerTimestamp ? true : typeof this._outerTimestamp === 'string',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            price: true,
            quantity: true,
            quotations: !this._quotations ? true : this._quotations.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            quoteQuantity: true,
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
            spotOrderPrice: true,
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
