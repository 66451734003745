// This file was autogenerated. Please do not change.
export interface IBlockchainRequest {
    id?: number;
    name?: string;
}

export default class BlockchainRequest {
    readonly _id: number | undefined;

    /**
     * Description: Blockchain platform ID
     * Example: 1
     */
    get id(): number | undefined {
        return this._id;
    }

    readonly _name: string | undefined;

    /**
     * Description: Blockchain platform name
     * Example: BTC
     */
    get name(): string | undefined {
        return this._name;
    }

    constructor(props: IBlockchainRequest) {
        if (typeof props.id === 'number') {
            this._id = props.id;
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
    }

    serialize(): IBlockchainRequest {
        const data: IBlockchainRequest = {
        };
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        return data;
    }

    toJSON(): IBlockchainRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'number',
            name: !this._name ? true : typeof this._name === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
