// This file was autogenerated. Please do not change.
export interface IPaidFeeQuotation {
    accountId?: string;
    assetSymbol?: string;
    periodEnd?: string;
    periodStart?: string;
    placementName?: string;
    quantity?: string;
    transferId?: string;
}

export default class PaidFeeQuotation {
    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _assetSymbol: string | undefined;

    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _periodEnd: string | undefined;

    get periodEnd(): string | undefined {
        return this._periodEnd;
    }

    readonly _periodStart: string | undefined;

    get periodStart(): string | undefined {
        return this._periodStart;
    }

    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _quantity: number | undefined;

    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _transferId: string | undefined;

    get transferId(): string | undefined {
        return this._transferId;
    }

    constructor(props: IPaidFeeQuotation) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.periodEnd === 'string') {
            this._periodEnd = props.periodEnd.trim();
        }
        if (typeof props.periodStart === 'string') {
            this._periodStart = props.periodStart.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.transferId === 'string') {
            this._transferId = props.transferId.trim();
        }
    }

    serialize(): IPaidFeeQuotation {
        const data: IPaidFeeQuotation = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._periodEnd !== 'undefined') {
            data.periodEnd = this._periodEnd;
        }
        if (typeof this._periodStart !== 'undefined') {
            data.periodStart = this._periodStart;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._transferId !== 'undefined') {
            data.transferId = this._transferId;
        }
        return data;
    }

    toJSON(): IPaidFeeQuotation {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            periodEnd: !this._periodEnd ? true : typeof this._periodEnd === 'string',
            periodStart: !this._periodStart ? true : typeof this._periodStart === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            quantity: true,
            transferId: !this._transferId ? true : typeof this._transferId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
