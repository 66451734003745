// This file was autogenerated. Please do not change.
export interface IPaidRebate {
    accountId?: string;
    assetSymbol?: string;
    createdAt?: string;
    id?: string;
    placementName?: string;
    quantity?: string;
    updatedAt?: string;
}

export default class PaidRebate {
    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _assetSymbol: string | undefined;

    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _createdAt: string | undefined;

    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _id: string | undefined;

    get id(): string | undefined {
        return this._id;
    }

    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _quantity: number | undefined;

    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _updatedAt: string | undefined;

    get updatedAt(): string | undefined {
        return this._updatedAt;
    }

    constructor(props: IPaidRebate) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.updatedAt === 'string') {
            this._updatedAt = props.updatedAt.trim();
        }
    }

    serialize(): IPaidRebate {
        const data: IPaidRebate = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._updatedAt !== 'undefined') {
            data.updatedAt = this._updatedAt;
        }
        return data;
    }

    toJSON(): IPaidRebate {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            id: !this._id ? true : typeof this._id === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            quantity: true,
            updatedAt: !this._updatedAt ? true : typeof this._updatedAt === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
