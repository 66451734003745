// This file was autogenerated. Please do not change.
export interface IGetQuotaResponse {
    quotaLeft: string;
}

export default class GetQuotaResponse {
    readonly _quotaLeft: number;

    /**
     * Example: 0.1
     */
    get quotaLeft(): number {
        return this._quotaLeft;
    }

    constructor(props: IGetQuotaResponse) {
        this._quotaLeft = Number(props.quotaLeft);
    }

    serialize(): IGetQuotaResponse {
        const data: IGetQuotaResponse = {
            quotaLeft: String(this._quotaLeft),
        };
        return data;
    }

    toJSON(): IGetQuotaResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            quotaLeft: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
