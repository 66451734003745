/**
 * @typedef {Object} RGB
 *
 * @property {Number} r - red part of color in range of 0-255
 * @property {Number} g - green part of color in range of 0-255
 * @property {Number} b - blue part of color in range of 0-255
 */

/**
 *
 * @param {String} hex
 *
 * @return {RGB}
 */
export function hexToRgb(hex) {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
    } : null;
}

/**
 *
 * @param {String} hex
 *
 * @return {String}
 */
export function hexToRgbString(hex) {
    const rgb = hexToRgb(hex);
    return rgb ? `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})` : 'rgb(0, 0, 0)';
}

/**
 *
 * @param {String} hex
 * @param {String|Number} opacity
 *
 * @return {String}
 */
export function hexToRgbaString(hex, opacity = 1) {
    const rgb = hexToRgb(hex);
    return rgb ? `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})` : `rgba(0, 0, 0, ${opacity})`;
}
