/**
 *
 * @param {Number} number
 * @return {Number}
 */
export function smartLog10Round(number) {
    const data = Math.log10(number);

    return Math.sign(data) * (data < 0 ? Math.ceil(Math.abs(data)) : Math.floor(Math.abs(data)));
}

/**
 * Get Aggregations
 *
 * @param {Object} asks
 * @param {Object} bids
 * @param {Number} minRecords - min count records in aggregation
 * @param {Number} minAggregationsCount - min count of aggregation levels
 * @return {{}|*[]}
 */
export function getAggregations(asks, bids, minRecords = 20, minAggregationsCount = 3) {
    const records = { ...asks, ...bids };

    if (Object.keys(records).length < 2) {
        return [];
    }

    const prices = Object.keys(records).sort((a, b) => +a - +b);
    const differences = prices.map((price, index) => {
        const result = index < prices.length - 1 ? +prices[index + 1] - +price : +price - +prices[index - 1];
        return result + (10 ** -12);
    });
    const aggregations = {};

    const minAggregation = smartLog10Round(Math.min(...differences));
    const maxAggregation = smartLog10Round(Math.max(...differences));

    for (let i = minAggregation; i <= maxAggregation; i++) {
        const min = 10 ** i;
        const max = 10 ** (i + 1);

        aggregations[i] = differences.filter((price) => price > min && price <= max).length;
    }

    for (let i = minAggregation; i <= maxAggregation; i++) {
        if (Object.keys(aggregations).length <= minAggregationsCount) {
            break;
        } else if (aggregations[i] < minRecords) {
            delete aggregations[i];
        }
    }

    if (Object.keys(aggregations).length < minAggregationsCount) {
        const currentMaxAggregation = Math.max(...Object.keys(aggregations).map((a) => +a));

        for (let i = currentMaxAggregation; i <= currentMaxAggregation + (minAggregationsCount - Object.keys(aggregations).length) + 1; i++) {
            aggregations[i] = 0;
        }
    }
    return aggregations;
}

export function getAggregationLevelName(digit) {
    if (digit >= 0) {
        return `1${new Array(digit + 1).join('0')}`;
    }

    return `0.${new Array(-digit).join('0')}1`;
}
