// This file was autogenerated. Please do not change.
export interface IApproveRequest {
    accountId: string;
    amount?: string;
    assetSymbol: string;
    blockchainName: string;
    placementName: string;
    quantity: string;
}

export default class ApproveRequest {
    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _amount: string | undefined;

    get amount(): string | undefined {
        return this._amount;
    }

    readonly _assetSymbol: string;

    /**
     * Example: USDT
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _blockchainName: string;

    /**
     * Example: BNB
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _placementName: string;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _quantity: number;

    /**
     * Example: 10
     */
    get quantity(): number {
        return this._quantity;
    }

    constructor(props: IApproveRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.amount === 'string') {
            this._amount = props.amount.trim();
        }
        this._assetSymbol = props.assetSymbol.trim();
        this._blockchainName = props.blockchainName.trim();
        this._placementName = props.placementName.trim();
        this._quantity = Number(props.quantity);
    }

    serialize(): IApproveRequest {
        const data: IApproveRequest = {
            accountId: this._accountId,
            assetSymbol: this._assetSymbol,
            blockchainName: this._blockchainName,
            placementName: this._placementName,
            quantity: String(this._quantity),
        };
        if (typeof this._amount !== 'undefined') {
            data.amount = this._amount;
        }
        return data;
    }

    toJSON(): IApproveRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            amount: !this._amount ? true : typeof this._amount === 'string',
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
