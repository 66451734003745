
import Vue from 'vue';
import { nanoid } from 'nanoid';

import Icon from 'UI/Icon.vue';
/*
* emits
* state-changed: () => void;
* */
interface Props {
    value: boolean;
    disabled?: boolean;
    favorite?: boolean;
    round?: boolean;
    labelClass?: any;
    labelActiveClass?: any;
    hasError?: boolean;
}

interface Data {
    id: string;
}

type NoData = Record<string, never>;

export default Vue.extend<Data, NoData, NoData, Props>({
    name: 'Checkbox',
    components: {
        Icon,
    },
    data() {
        return {
            id: nanoid(7),
        };
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        favorite: {
            type: Boolean,
            default: false,
        },
        round: {
            type: Boolean,
            default: false,
        },
        labelClass: {
            type: undefined,
            default: '',
        },
        labelActiveClass: {
            type: undefined,
            default: '',
        },
        hasError: {
            type: Boolean,
            default: false,
        },
    },
});
