import LiquidationsSummary, { ILiquidationsSummary } from './LiquidationsSummary';

// This file was autogenerated. Please do not change.
export interface IOrdersSummary {
    commissionQuantity?: string;
    executedQuantity?: string;
    executedQuantityPerOrderAvg?: string;
    liquidations?: ILiquidationsSummary[];
    ordersCount?: number;
    tradePriceAvg?: string;
    tradesCount?: number;
    tradesPerOrderAvg?: number;
}

export default class OrdersSummary {
    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 9.5425505
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _executedQuantity: number | undefined;

    /**
     * Example: 5784.86669895
     */
    get executedQuantity(): number | undefined {
        return this._executedQuantity;
    }

    readonly _executedQuantityPerOrderAvg: number | undefined;

    /**
     * Example: 8.12481277
     */
    get executedQuantityPerOrderAvg(): number | undefined {
        return this._executedQuantityPerOrderAvg;
    }

    readonly _liquidations: LiquidationsSummary[] | undefined;

    get liquidations(): LiquidationsSummary[] | undefined {
        return this._liquidations;
    }

    readonly _ordersCount: number | undefined;

    /**
     * Example: 712
     */
    get ordersCount(): number | undefined {
        return this._ordersCount;
    }

    readonly _tradePriceAvg: number | undefined;

    /**
     * Example: 1505.31265587
     */
    get tradePriceAvg(): number | undefined {
        return this._tradePriceAvg;
    }

    readonly _tradesCount: number | undefined;

    /**
     * Example: 363
     */
    get tradesCount(): number | undefined {
        return this._tradesCount;
    }

    readonly _tradesPerOrderAvg: number | undefined;

    /**
     * Example: 0.5
     */
    get tradesPerOrderAvg(): number | undefined {
        return this._tradesPerOrderAvg;
    }

    constructor(props: IOrdersSummary) {
        if (typeof props.commissionQuantity === 'string') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.executedQuantity === 'string') {
            this._executedQuantity = Number(props.executedQuantity);
        }
        if (typeof props.executedQuantityPerOrderAvg === 'string') {
            this._executedQuantityPerOrderAvg = Number(props.executedQuantityPerOrderAvg);
        }
        if (props.liquidations) {
            this._liquidations = props.liquidations.map((p) => new LiquidationsSummary(p));
        }
        if (typeof props.ordersCount === 'number') {
            this._ordersCount = props.ordersCount;
        }
        if (typeof props.tradePriceAvg === 'string') {
            this._tradePriceAvg = Number(props.tradePriceAvg);
        }
        if (typeof props.tradesCount === 'number') {
            this._tradesCount = props.tradesCount;
        }
        if (typeof props.tradesPerOrderAvg === 'number') {
            this._tradesPerOrderAvg = props.tradesPerOrderAvg;
        }
    }

    serialize(): IOrdersSummary {
        const data: IOrdersSummary = {
        };
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = String(this._commissionQuantity);
        }
        if (typeof this._executedQuantity !== 'undefined') {
            data.executedQuantity = String(this._executedQuantity);
        }
        if (typeof this._executedQuantityPerOrderAvg !== 'undefined') {
            data.executedQuantityPerOrderAvg = String(this._executedQuantityPerOrderAvg);
        }
        if (typeof this._liquidations !== 'undefined') {
            data.liquidations = this._liquidations.map((p) => p.serialize());
        }
        if (typeof this._ordersCount !== 'undefined') {
            data.ordersCount = this._ordersCount;
        }
        if (typeof this._tradePriceAvg !== 'undefined') {
            data.tradePriceAvg = String(this._tradePriceAvg);
        }
        if (typeof this._tradesCount !== 'undefined') {
            data.tradesCount = this._tradesCount;
        }
        if (typeof this._tradesPerOrderAvg !== 'undefined') {
            data.tradesPerOrderAvg = this._tradesPerOrderAvg;
        }
        return data;
    }

    toJSON(): IOrdersSummary {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            commissionQuantity: true,
            executedQuantity: true,
            executedQuantityPerOrderAvg: true,
            liquidations: !this._liquidations ? true : this._liquidations.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            ordersCount: !this._ordersCount ? true : typeof this._ordersCount === 'number',
            tradePriceAvg: true,
            tradesCount: !this._tradesCount ? true : typeof this._tradesCount === 'number',
            tradesPerOrderAvg: !this._tradesPerOrderAvg ? true : typeof this._tradesPerOrderAvg === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
