// This file was autogenerated. Please do not change.
export interface IOrderBookRequest {
    placementName: string;
    symbol: string;
}

export default class OrderBookRequest {
    readonly _placementName: string;

    /**
     * Description: Placement (CEx) name, e.g. Binance, Bitfinex
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _symbol: string;

    /**
     * Description: Asset symbol, e.g.  BTC, ETH
     * Example: BTC/USDT
     */
    get symbol(): string {
        return this._symbol;
    }

    constructor(props: IOrderBookRequest) {
        this._placementName = props.placementName.trim();
        this._symbol = props.symbol.trim();
    }

    serialize(): IOrderBookRequest {
        const data: IOrderBookRequest = {
            placementName: this._placementName,
            symbol: this._symbol,
        };
        return data;
    }

    toJSON(): IOrderBookRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
