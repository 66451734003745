// This file was autogenerated. Please do not change.
export interface IOnboardingResponse {
    completed: boolean;
    stepNumber?: number;
    userId?: string;
}

export default class OnboardingResponse {
    readonly _completed: boolean;

    /** Description: Onboarding is finished */
    get completed(): boolean {
        return this._completed;
    }

    readonly _stepNumber: number | undefined;

    /** Description: current step */
    get stepNumber(): number | undefined {
        return this._stepNumber;
    }

    readonly _userId: string | undefined;

    /** Description: User unique identifier */
    get userId(): string | undefined {
        return this._userId;
    }

    constructor(props: IOnboardingResponse) {
        this._completed = props.completed;
        if (typeof props.stepNumber === 'number') {
            this._stepNumber = props.stepNumber;
        }
        if (typeof props.userId === 'string') {
            this._userId = props.userId.trim();
        }
    }

    serialize(): IOnboardingResponse {
        const data: IOnboardingResponse = {
            completed: this._completed,
        };
        if (typeof this._stepNumber !== 'undefined') {
            data.stepNumber = this._stepNumber;
        }
        if (typeof this._userId !== 'undefined') {
            data.userId = this._userId;
        }
        return data;
    }

    toJSON(): IOnboardingResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            userId: !this._userId ? true : typeof this._userId === 'string',
            stepNumber: !this._stepNumber ? true : typeof this._stepNumber === 'number',
            completed: typeof this._completed === 'boolean',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
