import English from './localizations/English.json';

const localizations = {
    English,
};

const state = {
    localizations,
    currentLanguage: 'English',
};

const getters = {
    availableLanguages: (state) => Object.keys(state.localizations),

    localizations: (state) => state.localizations,
    currentLanguage: (state) => state.currentLanguage,
    localization: (state, getters) => state.localizations[getters.currentLanguage],
};

const mutations = {
    SET_ACTIVE_LANGUAGE(state, language) {
        state.currentLanguage = language;
    },
};

const actions = {
    setActiveLanguage({ commit, getters }, language) {
        if (getters.availableLanguages.some((lan) => lan === language)) {
            commit('SET_ACTIVE_LANGUAGE', language);
        }
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
