// This file was autogenerated. Please do not change.
export interface IDexData {
    placementName: string;
    poolCount: number;
    tvl?: string;
    volume24h?: string;
    volume30d?: string;
}

export default class DexData {
    readonly _placementName: string;

    get placementName(): string {
        return this._placementName;
    }

    readonly _poolCount: number;

    get poolCount(): number {
        return this._poolCount;
    }

    readonly _tvl: number | undefined;

    get tvl(): number | undefined {
        return this._tvl;
    }

    readonly _volume24h: number | undefined;

    get volume24h(): number | undefined {
        return this._volume24h;
    }

    readonly _volume30d: number | undefined;

    get volume30d(): number | undefined {
        return this._volume30d;
    }

    constructor(props: IDexData) {
        this._placementName = props.placementName.trim();
        this._poolCount = props.poolCount;
        if (typeof props.tvl === 'string') {
            this._tvl = Number(props.tvl);
        }
        if (typeof props.volume24h === 'string') {
            this._volume24h = Number(props.volume24h);
        }
        if (typeof props.volume30d === 'string') {
            this._volume30d = Number(props.volume30d);
        }
    }

    serialize(): IDexData {
        const data: IDexData = {
            placementName: this._placementName,
            poolCount: this._poolCount,
        };
        if (typeof this._tvl !== 'undefined') {
            data.tvl = String(this._tvl);
        }
        if (typeof this._volume24h !== 'undefined') {
            data.volume24h = String(this._volume24h);
        }
        if (typeof this._volume30d !== 'undefined') {
            data.volume30d = String(this._volume30d);
        }
        return data;
    }

    toJSON(): IDexData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            poolCount: typeof this._poolCount === 'number',
            tvl: true,
            volume24h: true,
            volume30d: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
