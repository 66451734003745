import DexPositionQuotation, { IDexPositionQuotation } from './DexPositionQuotation';

// This file was autogenerated. Please do not change.
export interface IDexPosition {
    aAssetSymbol: string;
    aInvestedQuantity: string;
    aQuantity: string;
    accountId: string;
    bAssetSymbol: string;
    bInvestedQuantity: string;
    bQuantity: string;
    blockchainName: string;
    createdAt: string;
    placementCommissionRate: string;
    placementName: string;
    quotations?: IDexPositionQuotation[];
    share: string;
    status?: string;
    updatedAt: string;
}

export default class DexPosition {
    readonly _aAssetSymbol: string;

    /**
     * Example: ETH
     */
    get aAssetSymbol(): string {
        return this._aAssetSymbol;
    }

    readonly _aInvestedQuantity: number;

    /**
     * Example: 1200.23
     */
    get aInvestedQuantity(): number {
        return this._aInvestedQuantity;
    }

    readonly _aQuantity: number;

    /**
     * Example: 0.1
     */
    get aQuantity(): number {
        return this._aQuantity;
    }

    readonly _accountId: string;

    /**
     * Example: ACXXU119CYU
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _bAssetSymbol: string;

    /**
     * Example: USDT
     */
    get bAssetSymbol(): string {
        return this._bAssetSymbol;
    }

    readonly _bInvestedQuantity: number;

    /**
     * Example: 1000.21
     */
    get bInvestedQuantity(): number {
        return this._bInvestedQuantity;
    }

    readonly _bQuantity: number;

    /**
     * Example: 1204.15
     */
    get bQuantity(): number {
        return this._bQuantity;
    }

    readonly _blockchainName: string;

    /**
     * Example: ETH
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _placementCommissionRate: number;

    /**
     * Example: 0.3
     */
    get placementCommissionRate(): number {
        return this._placementCommissionRate;
    }

    readonly _placementName: string;

    /**
     * Example: UniswapV2
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _quotations: DexPositionQuotation[] | undefined;

    get quotations(): DexPositionQuotation[] | undefined {
        return this._quotations;
    }

    readonly _share: number;

    /**
     * Example: 0.153
     */
    get share(): number {
        return this._share;
    }

    readonly _status: string | undefined;

    /**
     * Example: ACTIVE
     */
    get status(): string | undefined {
        return this._status;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IDexPosition) {
        this._aAssetSymbol = props.aAssetSymbol.trim();
        this._aInvestedQuantity = Number(props.aInvestedQuantity);
        this._aQuantity = Number(props.aQuantity);
        this._accountId = props.accountId.trim();
        this._bAssetSymbol = props.bAssetSymbol.trim();
        this._bInvestedQuantity = Number(props.bInvestedQuantity);
        this._bQuantity = Number(props.bQuantity);
        this._blockchainName = props.blockchainName.trim();
        this._createdAt = props.createdAt.trim();
        this._placementCommissionRate = Number(props.placementCommissionRate);
        this._placementName = props.placementName.trim();
        if (props.quotations) {
            this._quotations = props.quotations.map((p) => new DexPositionQuotation(p));
        }
        this._share = Number(props.share);
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IDexPosition {
        const data: IDexPosition = {
            aAssetSymbol: this._aAssetSymbol,
            aInvestedQuantity: String(this._aInvestedQuantity),
            aQuantity: String(this._aQuantity),
            accountId: this._accountId,
            bAssetSymbol: this._bAssetSymbol,
            bInvestedQuantity: String(this._bInvestedQuantity),
            bQuantity: String(this._bQuantity),
            blockchainName: this._blockchainName,
            createdAt: this._createdAt,
            placementCommissionRate: String(this._placementCommissionRate),
            placementName: this._placementName,
            share: String(this._share),
            updatedAt: this._updatedAt,
        };
        if (typeof this._quotations !== 'undefined') {
            data.quotations = this._quotations.map((p) => p.serialize());
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IDexPosition {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetSymbol: typeof this._aAssetSymbol === 'string' && !!this._aAssetSymbol.trim(),
            aInvestedQuantity: true,
            aQuantity: true,
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            bAssetSymbol: typeof this._bAssetSymbol === 'string' && !!this._bAssetSymbol.trim(),
            bInvestedQuantity: true,
            bQuantity: true,
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            placementCommissionRate: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            quotations: !this._quotations ? true : this._quotations.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            share: true,
            status: !this._status ? true : typeof this._status === 'string',
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
