/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    LS_FAVORITE_ASSET_PAIRS_FIELD,
    LS_IS_OWNED_ACCOUNTS_SHOW,
    LS_IS_MANAGED_ACCOUNTS_SHOW,
    LS_ACTIVE_ACCOUNT_ID_FIELD,
    LS_ACCOUNTS_ORDERS_FIELD,
    LS_ACTIVE_ASSET_PAIR_FIELD,
    LS_QUOTATION_ASSET_SYMBOL_KEY,
    LS_PAGES_GRID_FIELD,
    LS_ACTIVE_COLOR_THEME,
    LS_ACTIVE_PLACEMENT_ID_FIELD,
} from 'Const/localStorage';

class UserDataManager {
    activeAccountId: string | null;

    accountsOrders: Record<string /** accoutId */, any[]>;

    constructor() {
        this.activeAccountId = localStorage.getItem(LS_ACTIVE_ACCOUNT_ID_FIELD);
        const accountsOrders = localStorage.getItem(LS_ACCOUNTS_ORDERS_FIELD);
        this.accountsOrders = accountsOrders ? JSON.parse(accountsOrders) : {};
    }

    setActiveAccountId(accountId: string) {
        localStorage.setItem(LS_ACTIVE_ACCOUNT_ID_FIELD, accountId);
        this.activeAccountId = accountId;
    }

    setAccountsOrders(accountsOrders: Record<string /** accoutId */, any>) {
        localStorage.setItem(LS_ACCOUNTS_ORDERS_FIELD, JSON.stringify(accountsOrders));
        this.accountsOrders = accountsOrders;
    }

    setAccountOrder(accountId: string, order: any) {
        this.accountsOrders[accountId] = order;
        this.setAccountsOrders(this.accountsOrders);
    }
}

export default new UserDataManager();
