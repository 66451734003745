// This file was autogenerated. Please do not change.
export interface IPosition {
    amount: string;
    outerId?: string;
    placementName?: string;
    status: ('CREATED' | 'HOLDING' | 'REDEEMING' | 'CLOSED');
    uid?: string;
}

export default class Position {
    readonly _amount: number;

    /**
     * Example: 0.1
     */
    get amount(): number {
        return this._amount;
    }

    readonly _outerId: string | undefined;

    get outerId(): string | undefined {
        return this._outerId;
    }

    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _status: ('CREATED' | 'HOLDING' | 'REDEEMING' | 'CLOSED');

    get status(): ('CREATED' | 'HOLDING' | 'REDEEMING' | 'CLOSED') {
        return this._status;
    }

    readonly _uid: string | undefined;

    get uid(): string | undefined {
        return this._uid;
    }

    constructor(props: IPosition) {
        this._amount = Number(props.amount);
        if (typeof props.outerId === 'string') {
            this._outerId = props.outerId.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        this._status = props.status;
        if (typeof props.uid === 'string') {
            this._uid = props.uid.trim();
        }
    }

    serialize(): IPosition {
        const data: IPosition = {
            amount: String(this._amount),
            status: this._status,
        };
        if (typeof this._outerId !== 'undefined') {
            data.outerId = this._outerId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._uid !== 'undefined') {
            data.uid = this._uid;
        }
        return data;
    }

    toJSON(): IPosition {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            amount: true,
            outerId: !this._outerId ? true : typeof this._outerId === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            uid: !this._uid ? true : typeof this._uid === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
