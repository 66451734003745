// This file was autogenerated. Please do not change.
export interface IFuturesOrdersTotal {
    commissionQuantity?: string;
    longQuantity?: number;
    quantity?: string;
    shortQuantity?: number;
    tradeQuantityAvg?: string;
    tradesCount?: number;
}

export default class FuturesOrdersTotal {
    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 0.00309598
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _longQuantity: number | undefined;

    get longQuantity(): number | undefined {
        return this._longQuantity;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 1.84393625
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _shortQuantity: number | undefined;

    get shortQuantity(): number | undefined {
        return this._shortQuantity;
    }

    readonly _tradeQuantityAvg: number | undefined;

    /**
     * Example: 0.00512204
     */
    get tradeQuantityAvg(): number | undefined {
        return this._tradeQuantityAvg;
    }

    readonly _tradesCount: number | undefined;

    /**
     * Example: 360
     */
    get tradesCount(): number | undefined {
        return this._tradesCount;
    }

    constructor(props: IFuturesOrdersTotal) {
        if (typeof props.commissionQuantity === 'string') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.longQuantity === 'number') {
            this._longQuantity = props.longQuantity;
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.shortQuantity === 'number') {
            this._shortQuantity = props.shortQuantity;
        }
        if (typeof props.tradeQuantityAvg === 'string') {
            this._tradeQuantityAvg = Number(props.tradeQuantityAvg);
        }
        if (typeof props.tradesCount === 'number') {
            this._tradesCount = props.tradesCount;
        }
    }

    serialize(): IFuturesOrdersTotal {
        const data: IFuturesOrdersTotal = {
        };
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = String(this._commissionQuantity);
        }
        if (typeof this._longQuantity !== 'undefined') {
            data.longQuantity = this._longQuantity;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._shortQuantity !== 'undefined') {
            data.shortQuantity = this._shortQuantity;
        }
        if (typeof this._tradeQuantityAvg !== 'undefined') {
            data.tradeQuantityAvg = String(this._tradeQuantityAvg);
        }
        if (typeof this._tradesCount !== 'undefined') {
            data.tradesCount = this._tradesCount;
        }
        return data;
    }

    toJSON(): IFuturesOrdersTotal {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            commissionQuantity: true,
            longQuantity: !this._longQuantity ? true : typeof this._longQuantity === 'number',
            quantity: true,
            shortQuantity: !this._shortQuantity ? true : typeof this._shortQuantity === 'number',
            tradeQuantityAvg: true,
            tradesCount: !this._tradesCount ? true : typeof this._tradesCount === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
