import Deposit from './transactions/Deposit';
import Transfer from './transactions/Transfer';
import Withdraw from './transactions/Withdraw';
import TransfersHistory from './transactions/TransfersHistory';
import TransactionsHistory from './transactions/TransactionsHistory';

const state = {
    activeWalletsPaymentModulePage: 0,

    backLightWalletsPaymentModuleActive: false,
    backLightWalletsTransferModuleActive: false,
};

const getters = {
    activeWalletsPaymentModulePage: (state) => state.activeWalletsPaymentModulePage,
    backLightWalletsPaymentModuleActive: (state) => state.backLightWalletsPaymentModuleActive,
    backLightWalletsTransferModuleActive: (state) => state.backLightWalletsTransferModuleActive,
};

const mutations = {
    SET_ACTIVE_WALLETS_PAYMENT_MODULE_PAGE(state, pageIndex) {
        state.activeWalletsPaymentModulePage = pageIndex;
    },
    SET_BACK_LIGHT_WALLETS_PAYMENT_MODULE_ACTIVE(state, value) {
        state.backLightWalletsPaymentModuleActive = value;
    },
    SET_BACK_LIGHT_WALLETS_TRANSFER_MODULE_ACTIVE(state, value) {
        state.backLightWalletsTransferModuleActive = value;
    },
};

const actions = {
    backLightWalletsPaymentModule({ commit }) {
        commit('SET_BACK_LIGHT_WALLETS_PAYMENT_MODULE_ACTIVE', true);

        setTimeout(() => {
            commit('SET_BACK_LIGHT_WALLETS_PAYMENT_MODULE_ACTIVE', false);
        }, 500);
    },
    backLightWalletsTransferModule({ commit }) {
        commit('SET_BACK_LIGHT_WALLETS_TRANSFER_MODULE_ACTIVE', true);

        setTimeout(() => {
            commit('SET_BACK_LIGHT_WALLETS_TRANSFER_MODULE_ACTIVE', false);
        }, 500);
    },

    setActiveWalletsPaymentModulePage({ commit }, pageIndex) {
        commit('SET_ACTIVE_WALLETS_PAYMENT_MODULE_PAGE', pageIndex);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

    modules: {
        Deposit,
        Transfer,
        Withdraw,

        TransfersHistory,
        TransactionsHistory,
    },
};
