// This file was autogenerated. Please do not change.
export interface IPNLPayload {
    UTCFromTs?: number;
    UTCToTs?: number;
    accountId?: string;
    accountIds?: string[];
    pnlResultType?: ('PerAccount' | 'Summary');
    quotationAssetSymbol: string;
}

export default class PNLPayload {
    readonly _UTCFromTs: number | undefined;

    get UTCFromTs(): number | undefined {
        return this._UTCFromTs;
    }

    readonly _UTCToTs: number | undefined;

    get UTCToTs(): number | undefined {
        return this._UTCToTs;
    }

    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _accountIds: string[] | undefined;

    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _pnlResultType: ('PerAccount' | 'Summary') | undefined;

    /** Description: Pnl Result Type */
    get pnlResultType(): ('PerAccount' | 'Summary') | undefined {
        return this._pnlResultType;
    }

    readonly _quotationAssetSymbol: string;

    get quotationAssetSymbol(): string {
        return this._quotationAssetSymbol;
    }

    constructor(props: IPNLPayload) {
        if (typeof props.UTCFromTs === 'number') {
            this._UTCFromTs = props.UTCFromTs;
        }
        if (typeof props.UTCToTs === 'number') {
            this._UTCToTs = props.UTCToTs;
        }
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        if (props.pnlResultType) {
            this._pnlResultType = props.pnlResultType;
        }
        this._quotationAssetSymbol = props.quotationAssetSymbol.trim();
    }

    serialize(): IPNLPayload {
        const data: IPNLPayload = {
            quotationAssetSymbol: this._quotationAssetSymbol,
        };
        if (typeof this._UTCFromTs !== 'undefined') {
            data.UTCFromTs = this._UTCFromTs;
        }
        if (typeof this._UTCToTs !== 'undefined') {
            data.UTCToTs = this._UTCToTs;
        }
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        if (typeof this._pnlResultType !== 'undefined') {
            data.pnlResultType = this._pnlResultType;
        }
        return data;
    }

    toJSON(): IPNLPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            quotationAssetSymbol: typeof this._quotationAssetSymbol === 'string' && !!this._quotationAssetSymbol.trim(),
            UTCFromTs: !this._UTCFromTs ? true : typeof this._UTCFromTs === 'number',
            UTCToTs: !this._UTCToTs ? true : typeof this._UTCToTs === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
