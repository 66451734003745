// This file was autogenerated. Please do not change.
export interface IGasPriceResponse {
    value: string;
}

export default class GasPriceResponse {
    readonly _value: number;

    /**
     * Example: 1
     */
    get value(): number {
        return this._value;
    }

    constructor(props: IGasPriceResponse) {
        this._value = Number(props.value);
    }

    serialize(): IGasPriceResponse {
        const data: IGasPriceResponse = {
            value: String(this._value),
        };
        return data;
    }

    toJSON(): IGasPriceResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            value: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
