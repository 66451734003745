// This file was autogenerated. Please do not change.
export interface IOrderBookLevel {
    price: string;
    volume: string;
}

export default class OrderBookLevel {
    readonly _price: number;

    get price(): number {
        return this._price;
    }

    readonly _volume: number;

    get volume(): number {
        return this._volume;
    }

    constructor(props: IOrderBookLevel) {
        this._price = Number(props.price);
        this._volume = Number(props.volume);
    }

    serialize(): IOrderBookLevel {
        const data: IOrderBookLevel = {
            price: String(this._price),
            volume: String(this._volume),
        };
        return data;
    }

    toJSON(): IOrderBookLevel {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            price: true,
            volume: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
