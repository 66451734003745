// This file was autogenerated. Please do not change.
export interface IWalletAddressRequestData {
    accountId: string;
    assetSymbol: string;
    blockchainName: string;
}

export default class WalletAddressRequestData {
    readonly _accountId: string;

    /** Description: Platform account UID */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetSymbol: string;

    /** Description: Wallet asset symbol */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _blockchainName: string;

    /** Description: Name of blockchain */
    get blockchainName(): string {
        return this._blockchainName;
    }

    constructor(props: IWalletAddressRequestData) {
        this._accountId = props.accountId.trim();
        this._assetSymbol = props.assetSymbol.trim();
        this._blockchainName = props.blockchainName.trim();
    }

    serialize(): IWalletAddressRequestData {
        const data: IWalletAddressRequestData = {
            accountId: this._accountId,
            assetSymbol: this._assetSymbol,
            blockchainName: this._blockchainName,
        };
        return data;
    }

    toJSON(): IWalletAddressRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
