// This file was autogenerated. Please do not change.
export interface IFuturesOrder {
    commissionQuantity?: string;
    contractSymbol?: string;
    createdAt?: string;
    executedQuantity?: string;
    id?: string;
    placementName?: string;
    positionSide?: ('LONG' | 'SHORT');
    side?: ('BUY' | 'SELL');
    status?: string;
    tradePriceAvg?: string;
    tradeQuantityAvg?: string;
    tradesCount?: number;
    type?: string;
}

export default class FuturesOrder {
    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 0.00194215
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _contractSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string | undefined {
        return this._contractSymbol;
    }

    readonly _createdAt: string | undefined;

    /**
     * Example: 2022-04-21T10:08:23.516666Z
     */
    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _executedQuantity: number | undefined;

    /**
     * Example: 12.25586354
     */
    get executedQuantity(): number | undefined {
        return this._executedQuantity;
    }

    readonly _id: string | undefined;

    /**
     * Example: OQ7DCMHAF5TL7FPWSS3C1
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _positionSide: ('LONG' | 'SHORT') | undefined;

    /**
     * Example: LONG
     */
    get positionSide(): ('LONG' | 'SHORT') | undefined {
        return this._positionSide;
    }

    readonly _side: ('BUY' | 'SELL') | undefined;

    /**
     * Example: SELL
     */
    get side(): ('BUY' | 'SELL') | undefined {
        return this._side;
    }

    readonly _status: string | undefined;

    /**
     * Example: CANCELED
     */
    get status(): string | undefined {
        return this._status;
    }

    readonly _tradePriceAvg: string | undefined;

    /**
     * Example: 13.31266445
     */
    get tradePriceAvg(): string | undefined {
        return this._tradePriceAvg;
    }

    readonly _tradeQuantityAvg: string | undefined;

    get tradeQuantityAvg(): string | undefined {
        return this._tradeQuantityAvg;
    }

    readonly _tradesCount: number | undefined;

    /**
     * Example: 1
     */
    get tradesCount(): number | undefined {
        return this._tradesCount;
    }

    readonly _type: string | undefined;

    /**
     * Example: LIMIT_STOP
     */
    get type(): string | undefined {
        return this._type;
    }

    constructor(props: IFuturesOrder) {
        if (typeof props.commissionQuantity === 'string') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.contractSymbol === 'string') {
            this._contractSymbol = props.contractSymbol.trim();
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.executedQuantity === 'string') {
            this._executedQuantity = Number(props.executedQuantity);
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (props.positionSide) {
            this._positionSide = props.positionSide;
        }
        if (props.side) {
            this._side = props.side;
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (typeof props.tradePriceAvg === 'string') {
            this._tradePriceAvg = props.tradePriceAvg.trim();
        }
        if (typeof props.tradeQuantityAvg === 'string') {
            this._tradeQuantityAvg = props.tradeQuantityAvg.trim();
        }
        if (typeof props.tradesCount === 'number') {
            this._tradesCount = props.tradesCount;
        }
        if (typeof props.type === 'string') {
            this._type = props.type.trim();
        }
    }

    serialize(): IFuturesOrder {
        const data: IFuturesOrder = {
        };
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = String(this._commissionQuantity);
        }
        if (typeof this._contractSymbol !== 'undefined') {
            data.contractSymbol = this._contractSymbol;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._executedQuantity !== 'undefined') {
            data.executedQuantity = String(this._executedQuantity);
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._positionSide !== 'undefined') {
            data.positionSide = this._positionSide;
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._tradePriceAvg !== 'undefined') {
            data.tradePriceAvg = this._tradePriceAvg;
        }
        if (typeof this._tradeQuantityAvg !== 'undefined') {
            data.tradeQuantityAvg = this._tradeQuantityAvg;
        }
        if (typeof this._tradesCount !== 'undefined') {
            data.tradesCount = this._tradesCount;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IFuturesOrder {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            commissionQuantity: true,
            contractSymbol: !this._contractSymbol ? true : typeof this._contractSymbol === 'string',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            executedQuantity: true,
            id: !this._id ? true : typeof this._id === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            tradePriceAvg: !this._tradePriceAvg ? true : typeof this._tradePriceAvg === 'string',
            tradeQuantityAvg: !this._tradeQuantityAvg ? true : typeof this._tradeQuantityAvg === 'string',
            tradesCount: !this._tradesCount ? true : typeof this._tradesCount === 'number',
            type: !this._type ? true : typeof this._type === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
