Date.getDayStartByUTC = function (date) {

    if (typeof date === 'number') {
        date = new Date(date)
    }

    date.setUTCHours(0, 0, 0, 0)

    return date.getTime() + (date.getTimezoneOffset() * 60 * 1000)

}

Date.getTimeString = function (date, { showSeconds } = { showSeconds: true }) {

    if (typeof date === 'number') {
        date = new Date(date)
    }

    let result = '';

    result += `${ date.getHours() < 10 ? '0' + date.getHours() : date.getHours() }`;
    result += `:${ date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() }`;
    if (showSeconds) {
        result += `:${ date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() }`
    }

    return result

}
