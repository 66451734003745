// This file was autogenerated. Please do not change.
export interface IGroupRequestsParams {
    groupId?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    status?: string;
}

export default class GroupRequestsParams {
    readonly _groupId: string | undefined;

    /**
     * Example: GD3TGKFC3
     */
    get groupId(): string | undefined {
        return this._groupId;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _status: string | undefined;

    /**
     * Example: processing
     */
    get status(): string | undefined {
        return this._status;
    }

    constructor(props: IGroupRequestsParams) {
        if (typeof props.groupId === 'string') {
            this._groupId = props.groupId.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): IGroupRequestsParams {
        const data: IGroupRequestsParams = {
        };
        if (typeof this._groupId !== 'undefined') {
            data.groupId = this._groupId;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IGroupRequestsParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: !this._groupId ? true : typeof this._groupId === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            status: !this._status ? true : typeof this._status === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
