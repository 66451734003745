class Endpoints {
    IS_IN_PRODUCTION

    IS_IN_DEVELOPMENT

    SERVER_URL

    PRICE_CHART_DATA_SOURCE_SERVER_URN

    PRIVATE_DATA_SOURCE

    QUADENCY_PUBLIC_DATA_SOURCE_URL

    QUADENCY_PUBLIC_DATA_SOURCE_PROXY_URL

    QUADENCY_PUBLIC_DATA_SOURCE_SOCKET_SERVER_URL

    CRYPTOWATCH_PUBLIC_DATA_SOURCE_URL

    CRYPTOWATCH_WEB_SOCKET_URL

    constructor() {
        this.IS_IN_PRODUCTION = process.env.NODE_ENV === 'production';
        this.IS_IN_DEVELOPMENT = process.env.NODE_ENV === 'development';

        this.SERVER_URL = process.env.VUE_APP_SERVER_URL;
        this.PRICE_CHART_DATA_SOURCE_SERVER_URN = process.env.VUE_APP_PRICE_CHART_DATA_SOURCE_SERVER_URN;
        this.PRIVATE_DATA_SOURCE = process.env.VUE_APP_PRIVATE_DATA_SOURCE;

        this.QUADENCY_PUBLIC_DATA_SOURCE_URL = process.env.VUE_APP_QUADENCY_PUBLIC_DATA_SOURCE_URL;
        this.QUADENCY_PUBLIC_DATA_SOURCE_PROXY_URL = `${this.SERVER_URL}/public/v1/marketData/quadency`;
        this.QUADENCY_PUBLIC_DATA_SOURCE_SOCKET_SERVER_URL = process.env.VUE_APP_QUADENCY_PUBLIC_DATA_SOURCE_SOCKET_SERVER_URL;

        this.CRYPTOWATCH_PUBLIC_DATA_SOURCE_URL = process.env.VUE_APP_CRYPTOWATCH_PUBLIC_DATA_SOURCE_URL;
        this.CRYPTOWATCH_WEB_SOCKET_URL = process.env.VUE_APP_CRYPTOWATCH_WEB_SOCKET_URL;
    }

    get selfServer() {
        return this.SERVER_URL;
    }

    get priceChartDataSource() {
        return this.selfServer + this.PRICE_CHART_DATA_SOURCE_SERVER_URN;
    }

    get privateDataSource() {
        return this.PRIVATE_DATA_SOURCE;
    }

    get quadencyRestDataSource() {
        return this.IS_IN_DEVELOPMENT ? this.QUADENCY_PUBLIC_DATA_SOURCE_PROXY_URL : this.QUADENCY_PUBLIC_DATA_SOURCE_URL;
    }

    get quadencySocketDataSource() {
        return this.QUADENCY_PUBLIC_DATA_SOURCE_SOCKET_SERVER_URL;
    }

    get cryptowatchPublicDataSource() {
        return this.CRYPTOWATCH_PUBLIC_DATA_SOURCE_URL;
    }

    get cryptowatchWebSocketUrl() {
        return this.CRYPTOWATCH_WEB_SOCKET_URL;
    }
}

export default new Endpoints();
