// This file was autogenerated. Please do not change.
export interface ICheckApprovalStatusResponse {
    status?: ('NOT_APPROVED' | 'PROCESSING' | 'APPROVED');
}

export default class CheckApprovalStatusResponse {
    readonly _status: ('NOT_APPROVED' | 'PROCESSING' | 'APPROVED') | undefined;

    /**
     * Example: APPROVED
     */
    get status(): ('NOT_APPROVED' | 'PROCESSING' | 'APPROVED') | undefined {
        return this._status;
    }

    constructor(props: ICheckApprovalStatusResponse) {
        if (props.status) {
            this._status = props.status;
        }
    }

    serialize(): ICheckApprovalStatusResponse {
        const data: ICheckApprovalStatusResponse = {
        };
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): ICheckApprovalStatusResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
