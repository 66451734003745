
import Vue from 'vue';

export const BANNER_WIDTH_RATIO = 0.8;
export const BANNER_BACKGROUND_ASPECT_RATIO = 1.75;

export default Vue.extend<any, any, any>({
    data() {
        return {
            contentWidth: 0,
            contentHeight: 0,
        };
    },
    methods: {
        resize() {
            const rect = document.querySelector('#app')?.getBoundingClientRect();
            this.contentWidth = (rect?.width ?? 0) * BANNER_WIDTH_RATIO;
            this.contentHeight = this.contentWidth / BANNER_BACKGROUND_ASPECT_RATIO;
        },
    },
    mounted() {
        this.resize();
        window.addEventListener('resize', this.resize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.resize);
    },
});
