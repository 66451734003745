export const Routes = {
    Terminal: '/terminal',
    Portfolios: '/portfolio',
    Wallets: '/wallets',
    Defi: '/defi',
    Workspace: '/workspace',
    Earn: '/earn',
    ConfirmTransfer: '/wallets/confirmTransfer',
    Profile: '/profile',
    ProfileAddresses: '/profile/addresses',
    ProfileChangePassword: '/profile/changepassword',
    ProfileDevices: '/profile/devices',
    ProfileTradePassword: '/profile/tradepassword',
    ProfileSetSmsMFA: '/profile/setsmsmfa',
    ProfileSetTotpMfa: '/profile/settotpmfa',
    ProfileManageMfa: '/profile/managemfa',
    AccountsPermissionsSettings: '/accounts/permissions-settings',
    SignIn: '/signin',
    SignUp: '/signup',
    SignInConfirm: '/signin-confirm',
    SignUpConfirm: '/signup-confirm',
    PreRegistration: '/pre-registration',
    RestorePassword: '/restorepassword',
    MfaTotp: '/mfa/totp',
    MfaSms: '/mfa/sms',
    MfaEmail: '/mfa/email',
    Statement: '/statement',
    OTC: '/otc',
};
