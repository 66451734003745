// This file was autogenerated. Please do not change.
export interface IPlacementPayload {
    id?: string;
    tag: string;
}

export default class PlacementPayload {
    readonly _id: string | undefined;

    /** Description: Account unique identifier */
    get id(): string | undefined {
        return this._id;
    }

    readonly _tag: string;

    /** Description: Placement tag */
    get tag(): string {
        return this._tag;
    }

    constructor(props: IPlacementPayload) {
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        this._tag = props.tag.trim();
    }

    serialize(): IPlacementPayload {
        const data: IPlacementPayload = {
            tag: this._tag,
        };
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        return data;
    }

    toJSON(): IPlacementPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            tag: typeof this._tag === 'string' && !!this._tag.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
