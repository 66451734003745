import ApiError from 'Entities/ApiError';
import ActivationStatusWithSecret from 'Entities/oktaAuthentication/ActivationStatusWithSecret';
import BaseFactorPayload from 'Entities/oktaAuthentication/BaseFactorPayload';
import DisableMFAPayload from 'Entities/oktaAuthentication/DisableMFAPayload';
import IssueFactorPayload from 'Entities/oktaAuthentication/IssueFactorPayload';
import OperationStatus from 'Entities/oktaAuthentication/OperationStatus';
import PasswordPayload from 'Entities/oktaAuthentication/PasswordPayload';
import PrivatePasswordPayload from 'Entities/oktaAuthentication/PrivatePasswordPayload';
import SendVerificationCodePayload from 'Entities/oktaAuthentication/SendVerificationCodePayload';
import ServiceStatus from 'Entities/oktaAuthentication/ServiceStatus';
import SetPasswordPayload from 'Entities/oktaAuthentication/SetPasswordPayload';
import UserPayload from 'Entities/oktaAuthentication/UserPayload';
import VerifyCodePayload from 'Entities/oktaAuthentication/VerifyCodePayload';
import VerifyFactorPayload from 'Entities/oktaAuthentication/VerifyFactorPayload';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class AuthenticationApi {
    static async activateFactor(verifyfactorpayload: VerifyFactorPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...verifyfactorpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('activateFactor', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/authentication/activateFactor`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(verifyfactorpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/activateFactor', { response: res, data: result, fields: [JSON.stringify({ verifyfactorpayload })] });
    }

    static async changePassword(privatepasswordpayload: PrivatePasswordPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...privatepasswordpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('changePassword', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/authentication/changePassword`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(privatepasswordpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/changePassword', { response: res, data: result, fields: [JSON.stringify({ privatepasswordpayload })] });
    }

    static async disableFactor(disablemfapayload: DisableMFAPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...disablemfapayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('disableFactor', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/authentication/disableFactor`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(disablemfapayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/disableFactor', { response: res, data: result, fields: [JSON.stringify({ disablemfapayload })] });
    }

    static async enrollFactor(basefactorpayload: BaseFactorPayload, withHeaders?: boolean): Promise<{ data: ActivationStatusWithSecret; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...basefactorpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('enrollFactor', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/authentication/enrollFactor`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(basefactorpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new ActivationStatusWithSecret(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/enrollFactor', { response: res, data: result, fields: [JSON.stringify({ basefactorpayload })] });
    }

    static async forgotPassword(passwordpayload: PasswordPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...passwordpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('forgotPassword', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/authentication/forgotPassword`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(passwordpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/forgotPassword', { response: res, data: result, fields: [JSON.stringify({ passwordpayload })] });
    }

    static async issueFactor(issuefactorpayload: IssueFactorPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...issuefactorpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('issueFactor', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/authentication/issueFactor`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(issuefactorpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/issueFactor', { response: res, data: result, fields: [JSON.stringify({ issuefactorpayload })] });
    }

    static async registerUser(userpayload: UserPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...userpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('registerUser', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/authentication/registerUser`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(userpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/registerUser', { response: res, data: result, fields: [JSON.stringify({ userpayload })] });
    }

    static async sendVerificationCode(sendverificationcodepayload: SendVerificationCodePayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...sendverificationcodepayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('sendVerificationCode', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/authentication/sendVerificationCode`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(sendverificationcodepayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/sendVerificationCode', { response: res, data: result, fields: [JSON.stringify({ sendverificationcodepayload })] });
    }

    static async setPassword(setpasswordpayload: SetPasswordPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...setpasswordpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('setPassword', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/authentication/setPassword`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(setpasswordpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/setPassword', { response: res, data: result, fields: [JSON.stringify({ setpasswordpayload })] });
    }

    static async verifyCode(verifycodepayload: VerifyCodePayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...verifycodepayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('verifyCode', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/authentication/verifyCode`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(verifycodepayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/verifyCode', { response: res, data: result, fields: [JSON.stringify({ verifycodepayload })] });
    }

    static async verifyFactor(verifyfactorpayload: VerifyFactorPayload, withHeaders?: boolean): Promise<{ data: OperationStatus; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...verifyfactorpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('verifyFactor', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/authentication/verifyFactor`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(verifyfactorpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OperationStatus(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Authentication/verifyFactor', { response: res, data: result, fields: [JSON.stringify({ verifyfactorpayload })] });
    }
}
