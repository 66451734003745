import { ISubscription, subscribe, unsubscribeChannel } from 'Store/v2/PublicSocketData';
import MarketDataApi from 'Apis/MarketData';
import { actionCreator, mutationCreator } from 'Store/utils';
import TradingDataRequest from 'Entities/publicPresenter/TradingDataRequest';
import TradingData, { ITradingData } from 'Entities/publicPresenter/TradingData';
import { ISpotAssetPair } from 'Entities/publicPresenter/SpotAssetPair';
import Assets from 'Store/v2/Assets';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationsRequest from 'Entities/publicPresenter/AssetQuotationsRequest';
import AssetQuotation from 'Entities/publicPresenter/AssetQuotation';

interface ISpotAssetPairData extends ISpotAssetPair {
    ticker: {
        price?: number;
        percentChange?: number;
        volume24h?: number;
        // eslint-disable-next-line camelcase
        volume24h_usd?: number;
        high24h?: number;
        low24h?: number;
    }
}

interface IInitPayload {
    placement: string;
    tag: string;
    pair: string;
    allPairs: string;
    quotationSymbol: string;
}

const state = {
    subscriptionIndex: null as ISubscription | null,
    snapshotData: [] as ISpotAssetPairData[],
    assetQuotations: new Map() as Map<string, number>,
    currentPlacement: '' as string,
    lastSnapshotData: [] as (ISpotAssetPairData | TradingData)[],
    activeAssetPair: null as any,
};

export type TradingDataState = typeof state;

export enum TradingDataGetters {}

const getters: Record<TradingDataGetters, (state: TradingDataState, ...args: any) => void> = {};

export enum TradingDataMutations {
    SET_SUBSCRIPTION_INDEX = 'SET_SUBSCRIPTION_INDEX',
    SET_TRADING_DATA_SNAPSHOT = 'SET_TRADING_DATA_SNAPSHOT',
    SET_TRADING_DATA = 'SET_TRADING_DATA',
    SET_QUOTATIONS = 'SET_QUOTATIONS',
    SET_CURRENT_PLACEMENT = 'SET_CURRENT_PLACEMENT',
    ADD_ASSET_PAIR = 'ADD_ASSET_PAIR',
    SET_ACTIVE_ASSET_PAIR = 'SET_ACTIVE_ASSET_PAIR',
}

export const SET_SUBSCRIPTION_INDEX = mutationCreator<ISubscription>('TradingData', TradingDataMutations.SET_SUBSCRIPTION_INDEX);
export const SET_TRADING_DATA_SNAPSHOT = mutationCreator<TradingData[]>('TradingData', TradingDataMutations.SET_TRADING_DATA_SNAPSHOT);
export const SET_TRADING_DATA = mutationCreator<TradingData[]>('TradingData', TradingDataMutations.SET_TRADING_DATA);
export const SET_QUOTATIONS = mutationCreator<AssetQuotation[]>('TradingData', TradingDataMutations.SET_QUOTATIONS);
export const SET_CURRENT_PLACEMENT = mutationCreator<string>('TradingData', TradingDataMutations.SET_CURRENT_PLACEMENT);
export const ADD_ASSET_PAIR = mutationCreator<any>('TradingData', TradingDataMutations.ADD_ASSET_PAIR);
export const SET_ACTIVE_ASSET_PAIR = mutationCreator<any>('TradingData', TradingDataMutations.SET_ACTIVE_ASSET_PAIR);

const mutations: Record<TradingDataMutations, (state: TradingDataState, ...args: any) => void> = {
    SET_ACTIVE_ASSET_PAIR(state, data: ReturnType<typeof SET_ACTIVE_ASSET_PAIR>) {
        state.activeAssetPair = data.payload;
    },
    ADD_ASSET_PAIR(state, data: ReturnType<typeof ADD_ASSET_PAIR>) {
        const pair = state.snapshotData.find((el) => el.id === data.payload.id);
        const pairInLastSnapshot = state.lastSnapshotData.find((el) => {
            if (!(el instanceof TradingData)) {
                return el.id === data.payload.id;
            }
            return false;
        });
        if (!pair) {
            state.snapshotData.push(data.payload);
        }
        if (!pairInLastSnapshot) {
            state.lastSnapshotData.push(data.payload);
        }
    },
    SET_SUBSCRIPTION_INDEX(state, index: ReturnType<typeof SET_SUBSCRIPTION_INDEX>) {
        state.subscriptionIndex = index.payload;
    },
    SET_TRADING_DATA_SNAPSHOT(state, data: ReturnType<typeof SET_TRADING_DATA_SNAPSHOT>) {
        const newSnapshot: any[] = [];
        state.lastSnapshotData = [...state.lastSnapshotData, ...data.payload];
        const tempArray = state.lastSnapshotData;
        const pairsMap = new Map();
        tempArray.forEach((el) => {
            pairsMap.set(el.symbol, el);
        });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        this.state.AssetPairs.spotAssetPairs.forEach((pair) => {
            if (pairsMap.has(pair.symbol)) {
                const temp: ISpotAssetPairData = pair.serialize === undefined ? { ...pair } : { ...pair.serialize() };
                const { quotationAssetSymbol } = Assets.state;
                let rate;
                if (state.assetQuotations.has(`${pair.symbol.split('/')[0]}/${quotationAssetSymbol}`)) {
                    rate = state.assetQuotations.get(`${pair.symbol.split('/')[0]}/${quotationAssetSymbol}`);
                }

                if (!temp.ticker) {
                    temp.ticker = {};
                }
                temp.ticker.price = pairsMap.get(pair.symbol).currentPrice;
                temp.ticker.percentChange = pairsMap.get(pair.symbol).priceChangePercent24h;
                temp.ticker.volume24h = pairsMap.get(pair.symbol).volume24h;
                temp.ticker.volume24h_usd = rate ? pairsMap.get(pair.symbol).volume24h * rate : undefined;
                temp.ticker.high24h = pairsMap.get(pair.symbol).high24h;
                temp.ticker.low24h = pairsMap.get(pair.symbol).low24h;
                newSnapshot.push(temp);
            }
        });
        state.snapshotData = newSnapshot;
    },
    SET_TRADING_DATA(state, { payload }: ReturnType<typeof SET_TRADING_DATA>) {
        if (state.assetQuotations.size > 0 && state.snapshotData.length > 0) {
            const pairsMap = new Map();
            const newSnapshot = [...state.snapshotData];
            newSnapshot.forEach((el, index) => {
                pairsMap.set(el.symbol, index);
            });
            payload.forEach((pair) => {
                if (pairsMap.has(pair.symbol)) {
                    const { quotationAssetSymbol } = Assets.state;
                    let rate;
                    if (state.assetQuotations.has(`${pair.symbol?.split('/')[0]}/${quotationAssetSymbol}`)) {
                        rate = state.assetQuotations.get(`${pair.symbol?.split('/')[0]}/${quotationAssetSymbol}`);
                    }
                    const ticker = {
                        price: pair.currentPrice,
                        percentChange: pair.priceChangePercent24h,
                        volume24h: pair.volume24h,
                        volume24h_usd: rate ? pair.volume24h! * rate : undefined,
                        high24h: pair.high24h,
                        low24h: pair.low24h,
                    };
                    const index = pairsMap.get(pair.symbol);
                    const newAssetPair = newSnapshot[index];
                    newAssetPair.ticker = ticker;
                    newSnapshot[index] = newAssetPair;
                }
            });
            state.snapshotData = newSnapshot;
        }
    },
    SET_QUOTATIONS(state, { payload: assetQuotationsArray }: ReturnType<typeof SET_QUOTATIONS>) {
        const newAssetQuotations = new Map(state.assetQuotations);
        assetQuotationsArray.forEach((el) => {
            newAssetQuotations.set(el.assetPairSymbol, el.rate);
        });

        state.assetQuotations = newAssetQuotations;
    },
    SET_CURRENT_PLACEMENT(state, placementName: ReturnType<typeof SET_CURRENT_PLACEMENT>) {
        state.currentPlacement = placementName.payload;
        state.assetQuotations = new Map();
        state.snapshotData = [];
        state.lastSnapshotData = [];
    },
};

export enum TradingDataActions {
    init = 'init',
    unsubscribeBeforeDestroy = 'unsubscribeBeforeDestroy',
}

export const init = actionCreator<IInitPayload>('TradingData', TradingDataActions.init);
export const unsubscribeBeforeDestroy = actionCreator<undefined>('TradingData', TradingDataActions.unsubscribeBeforeDestroy);

// velosiped, for not init twice
let initTo = '';

const actions = {
    async init(props, placementAndPairData: ReturnType<typeof init>) {
        const { commit, dispatch } = props;
        const { placement, allPairs, quotationSymbol, tag } = placementAndPairData.payload;

        if (!placement || !allPairs) {
            return;
        }

        if (state.currentPlacement !== placement) {
            state.snapshotData = [];
            state.lastSnapshotData = [];
            commit(SET_CURRENT_PLACEMENT(placement, true));
        }

        if (initTo !== `tradingdata:${tag.replace(' ', '_')}`) {
            initTo = `tradingdata:${tag.replace(' ', '_')}`;

            if (state.subscriptionIndex !== null) {
                dispatch(unsubscribeChannel(state.subscriptionIndex), { root: true });
            }

            const index = await dispatch(subscribe({
                channel: `tradingdata:${tag.replace(' ', '_')}`,
                callbacks: {
                    subscribe: () => true,
                    publish: ({ data }: { data: ITradingData[] }) => {
                        commit(SET_TRADING_DATA(data.map((el) => new TradingData(el)), true));
                    },
                },
            }), { root: true });
            commit(SET_SUBSCRIPTION_INDEX(index, true));
        }

        const allPairsArray = allPairs.split(';').slice(0, allPairs.split(';').length - 1);
        const assetsSet = new Set();
        allPairsArray.forEach((pair) => {
            assetsSet.add(pair.split('/')[0]);
        });
        const symbolsArray = [...assetsSet] as string[];
        const { data: result } = await PublicDataApi.publicGetAssetQuotations(new AssetQuotationsRequest({
            assetSymbols: symbolsArray,
            quotationSymbol,
            perPage: 300,
        }));
        commit(SET_QUOTATIONS(result, true));

        const { data: res } = await MarketDataApi.publicGetTradingData(new TradingDataRequest({
            placementName: placement,
            assetPairSymbols: allPairsArray,
        }));
        commit(SET_TRADING_DATA_SNAPSHOT(res, true));
    },
    async unsubscribeBeforeDestroy({ state, dispatch }) {
        if (state.subscriptionIndex !== null) {
            await dispatch(unsubscribeChannel(state.subscriptionIndex), { root: true });
            state.subscriptionIndex = null;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
