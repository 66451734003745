<template>
    <div
        v-if="isSymbolSupported"
        :class="s.iconWrapper"
        :style="`width: ${ size }px; height: ${ size }px;`"
    >
        <img
            :src="symbolIcon"
            alt="cryptoicon"
            :style="`width: ${ size }px; height: ${ size }px;`"
        />
    </div>

    <span class="cryptoicon-template"
          :style="`width: ${ size }px; height: ${ size }px; min-width: ${size}px; min-height: ${size}px; line-height: ${ size }px; background: ${ hasManifest(symbol) ? getManifest(symbol).color : makeColor(symbol) }; color: white; padding-top: 0px;`"
          v-else
    >
        {{ isBlockchain ? nativeBlockchainSymbol.substr(0, 1) : symbol.substr(0, 1) }}
    </span>
</template>

<script>
/**
 * manifest
 * List of crypto-currencies of vue-cryptoicon library
 *
 * @type {Array}
 *
 * @property {object} [] - object with crypto-currency info
 * @property {string} [].symbol - crypto-currency symbol
 * @property {string} [].name - crypto-currency name
 * @property {string} [].color - crypto-currency icon default color
 */
import { getManifest, hasManifest } from 'Models/assetsManifest';
import { makeColor } from 'Lib/utils/hexColorMaker';
import PublicDataApi from 'Apis/PublicData';
import BlockchainRequest from 'Entities/publicPresenter/BlockchainRequest';

export default {
    name: 'CryptoIcon',
    props: {
        symbol: {
            type: String,
            default: '',
        },
        isBlockchain: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: null,
        },
        size: {
            type: [Number, String],
            default: 18,
        },
    },
    data() {
        return {
            hasManifest,
            getManifest,
            makeColor,
            nativeBlockchainSymbol: '',
        };
    },
    computed: {
        symbolIcon() {
            try {
                return require(`@/assets/images/icons/${this.isBlockchain ? 'blockchains' : 'cryptoicons'}/${this.symbol.toLowerCase()}.svg`);
            } catch {
                if (this.isBlockchain) {
                    try {
                        return require(`@/assets/images/icons/cryptoicons/${this.nativeBlockchainSymbol.toLowerCase()}.svg`);
                    } catch {
                        return null;
                    }
                }
                return null;
            }
        },
        isSymbolSupported() {
            return this.symbolIcon !== null;
        },
    },
    async created() {
        if (this.isBlockchain && this.symbol && !this.symbolIcon && this.symbol.toLowerCase() !== 'no available networks' && this.symbol.toLowerCase() !== 'no available blockchains') {
            try {
                const { data: blockchain } = await PublicDataApi.publicGetBlockchain(new BlockchainRequest({
                    name: this.symbol,
                }));
                this.nativeBlockchainSymbol = blockchain.nativeAssetSymbol;
            } catch {
                this.nativeBlockchainSymbol = '';
            }
        }
    },
    watch: {
        async symbolIcon(value) {
            if (this.isBlockchain && !value && this.symbol && this.symbol.toLowerCase() !== 'no available networks' && this.symbol.toLowerCase() !== 'no available blockchains') {
                try {
                    const { data: blockchain } = await PublicDataApi.publicGetBlockchain(new BlockchainRequest({
                        name: this.symbol,
                    }));
                    this.nativeBlockchainSymbol = blockchain.nativeAssetSymbol;
                } catch {
                    this.nativeBlockchainSymbol = '';
                }
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
}
</style>
