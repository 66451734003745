// This file was autogenerated. Please do not change.
export interface IGasPriceParams {
    blockchainName: string;
}

export default class GasPriceParams {
    readonly _blockchainName: string;

    /**
     * Example: BNB
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    constructor(props: IGasPriceParams) {
        this._blockchainName = props.blockchainName.trim();
    }

    serialize(): IGasPriceParams {
        const data: IGasPriceParams = {
            blockchainName: this._blockchainName,
        };
        return data;
    }

    toJSON(): IGasPriceParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
