import MarketDataApi from 'Apis/MarketData';
import CandlesRequest from 'Entities/candlesPresenter/CandlesRequest';
import { actionCreator, mutationCreator } from 'Store/utils';
import { ICandle } from 'Entities/candlesPresenter/Candle';
import Trade from 'Entities/publicPresenter/Trade';

export interface IDatafeed {
    onReady: (callback) => void,
    getServerTime: (callback) => Promise<void>,
    resolveSymbol: (symbolName, onSymbolResolvedCallback, onResolveErrorCallback) => void,
    getBars: (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => Promise<void>,
    subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) => Promise<void>,
    unsubscribeBars: () => boolean,
}

const state = {
    tradingViewDatafeed: null as IDatafeed | null,
    lastCandleData: {} as ICandle,
    currentResolution: 0 as number,
    subscriptionIndex: null as number | null,
};

export type CandlesState = typeof state;

export enum CandlesGetters {}

const getters: Record<CandlesGetters, (state: CandlesState, ...args: any) => void> = {};

export enum CandlesMutations {
    UPDATE_LAST_CANDLE_DATA = 'UPDATE_LAST_CANDLE_DATA',
    SET_DATAFEED = 'SET_DATAFEED',
    SET_SUBSCRIPTION_INDEX = 'SET_SUBSCRIPTION_INDEX',
    CLEAR_LAST_CANDLE_DATA = 'CLEAR_LAST_CANDLE_DATA',
}

export const UPDATE_LAST_CANDLE_DATA = mutationCreator<Trade>('Candles', CandlesMutations.UPDATE_LAST_CANDLE_DATA);
export const SET_DATAFEED = mutationCreator<IDatafeed>('Candles', CandlesMutations.SET_DATAFEED);
export const SET_SUBSCRIPTION_INDEX = mutationCreator<number>('Candles', CandlesMutations.SET_SUBSCRIPTION_INDEX);
export const CLEAR_LAST_CANDLE_DATA = mutationCreator<void>('Candles', CandlesMutations.CLEAR_LAST_CANDLE_DATA);

const mutations: Record<CandlesMutations, (state: CandlesState, ...args: any) => void> = {
    UPDATE_LAST_CANDLE_DATA(state, trade: ReturnType<typeof UPDATE_LAST_CANDLE_DATA>) {
        const lastTrade = trade.payload;
        const fullTimestamp = lastTrade.timestamp;
        if (fullTimestamp! >= state.lastCandleData.time! && fullTimestamp! < state.lastCandleData.time! + state.currentResolution * 60 * 1000) {
            state.lastCandleData = {
                ...state.lastCandleData,
                close: lastTrade.price,
                low: state.lastCandleData.low! > lastTrade.price! ? lastTrade.price : state.lastCandleData.low,
                high: state.lastCandleData.high! < lastTrade.price! ? lastTrade.price : state.lastCandleData.high,
                volume: state.lastCandleData.volume! + lastTrade.size!,
            };
        } else {
            state.lastCandleData = {
                open: lastTrade.price,
                close: lastTrade.price,
                low: lastTrade.price,
                high: lastTrade.price,
                volume: lastTrade.size,
                time: state.lastCandleData.time! + state.currentResolution * 60 * 1000,
            };
        }
    },

    SET_DATAFEED(state, datafeed: ReturnType<typeof SET_DATAFEED>) {
        state.tradingViewDatafeed = datafeed.payload;
    },

    SET_SUBSCRIPTION_INDEX(state, index: ReturnType<typeof SET_SUBSCRIPTION_INDEX>) {
        state.subscriptionIndex = index.payload;
    },

    CLEAR_LAST_CANDLE_DATA(state) {
        state.lastCandleData = {};
    },
};

export enum CandlesActions {
    initDatafeed = 'initDatafeed',
}

export const initDatafeed = actionCreator<void>('Candles', CandlesActions.initDatafeed);

const actions = {
    initDatafeed({ dispatch, commit, state, rootState }) {
        const datafeed = {
            onReady: (callback) => {
                setTimeout(() => {
                    callback({
                        supports_marks: false,
                        supports_time: true,
                        supports_search: true,
                        supports_group_request: false,
                        supported_resolutions: [
                            '1',
                            '3',
                            '5',
                            '15',
                            '30',
                            '60',
                            '120',
                            '240',
                            '360',
                            '480',
                            '720',
                            '1440',
                            '10080',
                            '20160',
                            '40320',
                        ],
                    });
                }, 0);
            },
            resolveSymbol: (symbolName, onSymbolResolvedCallback) => {
                setTimeout(() => {
                    onSymbolResolvedCallback({
                        description: symbolName,
                        exchange: `${state.placement}`,
                        has_intraday: true,
                        minmov: 1,
                        name: symbolName,
                        pointvalue: 1,
                        pricescale: 100000000,
                        session: '24x7',
                        supported_resolutions: [
                            '1',
                            '3',
                            '5',
                            '15',
                            '30',
                            '60',
                            '120',
                            '240',
                            '360',
                            '480',
                            '720',
                            '1440',
                            '10080',
                            '20160',
                            '40320',
                        ],
                        ticker: symbolName,
                        timezone: 'Etc/UTC',
                        type: 'crypto',
                        volume_precision: 8,
                    });
                }, 0);
            },
            getServerTime: async (callback) => {
                const { data: res } = await MarketDataApi.getTime();
                callback(res.time);
            },
            getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback) => {
                resolution = Number(resolution);
                if (!symbolInfo.exchange) {
                    return;
                }
                const { data: res } = await MarketDataApi.getCandles(new CandlesRequest({
                    from: periodParams.from,
                    to: periodParams.to,
                    resolution,
                    placementName: symbolInfo.exchange,
                    symbol: symbolInfo.ticker,
                }));
                if (res.length) {
                    const lastCandle = res[res.length - 1];
                    if (!state.lastCandleData.time || lastCandle.time! > state.lastCandleData.time) {
                        state.lastCandleData = {
                            low: lastCandle.low,
                            high: lastCandle.high,
                            open: lastCandle.open,
                            close: lastCandle.close,
                            volume: lastCandle.volume,
                            time: lastCandle.time,
                        };
                    }
                }
                state.currentResolution = resolution;
                onHistoryCallback(res, { noData: res.length === 0 });
            },
            subscribeBars: async (symbolInfo, resolution, onRealtimeCallback) => {
                await dispatch('VuexEventListener/addMutationListener', {
                    type: 'SpotTrades/SET_TRADES',
                    callback: () => {
                        const newTimestamp = rootState.SpotTrades.trades[0]?.timestamp;
                        if (newTimestamp && newTimestamp > state.lastCandleData.time && state.assetPair === rootState.SpotTrades.activePair) {
                            commit(UPDATE_LAST_CANDLE_DATA(rootState.SpotTrades.trades[0], true));
                            onRealtimeCallback(state.lastCandleData);
                        }
                    },
                }, { root: true });
            },
            unsubscribeBars: () => true,
        };
        commit(SET_DATAFEED(datafeed, true));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
