// This file was autogenerated. Please do not change.
export interface IAssetQuotationsRequest {
    assetSymbols: string[];
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    quotationSymbol?: string;
    quotationSymbols?: string[];
}

export default class AssetQuotationsRequest {
    readonly _assetSymbols: string[];

    /**
     * Description: List of asset symbols that is needed to be quoted
     * Example: BTC,ETH,XLM
     */
    get assetSymbols(): string[] {
        return this._assetSymbols;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _quotationSymbol: string | undefined;

    /**
     * Description: Asset symbol to be quoted to
     * Example: BTC
     */
    get quotationSymbol(): string | undefined {
        return this._quotationSymbol;
    }

    readonly _quotationSymbols: string[] | undefined;

    /**
     * Description: List of asset symbols to be quoted to (will ne ignored if QuotationSymbol is set)
     * Example: BTC,USD
     */
    get quotationSymbols(): string[] | undefined {
        return this._quotationSymbols;
    }

    constructor(props: IAssetQuotationsRequest) {
        this._assetSymbols = props.assetSymbols;
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.quotationSymbol === 'string') {
            this._quotationSymbol = props.quotationSymbol.trim();
        }
        if (props.quotationSymbols) {
            this._quotationSymbols = props.quotationSymbols;
        }
    }

    serialize(): IAssetQuotationsRequest {
        const data: IAssetQuotationsRequest = {
            assetSymbols: this._assetSymbols,
        };
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._quotationSymbol !== 'undefined') {
            data.quotationSymbol = this._quotationSymbol;
        }
        if (typeof this._quotationSymbols !== 'undefined') {
            data.quotationSymbols = this._quotationSymbols;
        }
        return data;
    }

    toJSON(): IAssetQuotationsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbols: this._assetSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            quotationSymbol: !this._quotationSymbol ? true : typeof this._quotationSymbol === 'string',
            quotationSymbols: !this._quotationSymbols ? true : this._quotationSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
