/* eslint-disable max-classes-per-file */
import { LS_ACTIVE_PLACEMENT_ID_FIELD } from 'Const/localStorage';
import { GetPlacementDataSource } from 'Models/publicDataSource/placementsDataSources';

/**
 *
 * @param {String|Number} placementId
 */
export function setLocalStorageActivePlacementId(placementId) {
    localStorage.setItem(LS_ACTIVE_PLACEMENT_ID_FIELD, placementId.toString());
}

/**
 *
 * @return {String} placementId
 */
export function getLocalStorageActivePlacementId() {
    return localStorage.getItem(LS_ACTIVE_PLACEMENT_ID_FIELD);
}

/**
 *
 * @return {Boolean}
 */
export function hasLocalStorageActivePlacementId() {
    // eslint-disable-next-line no-prototype-builtins
    return localStorage.hasOwnProperty(LS_ACTIVE_PLACEMENT_ID_FIELD);
}

export const MaintenanceStatuses = {
    TRADING: 'trading',
    WITHDRAWAL: 'withdrawal',
    DEPOSIT: 'deposit',
};

export const PlacementTypes = {
    SELF: 'self',
    CRYPTO_SPOT: 'crypto-spot',
};

const emptyPlacementFields = {
    id: null,
    name: '',
    type: '',

    orderTypes: [],

    commissionMaker: 0,
    commissionPlatform: 0,
    commissionTaker: 0,

    ticker: {} as any,

    volume24h: 0 as number,

    maintenanceStatus: {},

    assets: '',

    assetPairs: '',

    disabled: false,

    needExternalKyc: false,

    tag: '',
};
const emptyPlacementTickerFields = {
    assetsCount: 0,
    pairsCount: 0,
    volume24h: 0,
};

export class PlacementTicker {
    assetsCount: number;

    pairsCount: number;

    volume24h: number;

    constructor({ assetsCount, pairsCount, volume24h } = emptyPlacementTickerFields) {
        this.assetsCount = assetsCount;
        this.pairsCount = pairsCount;
        this.volume24h = volume24h;
    }
}

export class Placement {
    id

    name

    type

    coinsNumber

    pairsNumber

    orderTypes

    orderQuantityTypes

    commissionMaker

    commissionPlatform

    volume24h

    commissionTaker

    dataSource

    ticker

    blockedModules: any[] = []

    disabled

    needExternalKyc

    tag

    constructor({
        id, name, disabled, type, tag, needExternalKyc, orderTypes, assets, assetPairs, ticker, volume24h, commissionMaker, commissionPlatform, commissionTaker, maintenanceStatus,
    } = emptyPlacementFields) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.needExternalKyc = needExternalKyc;
        this.tag = tag;

        this.coinsNumber = assets;
        this.pairsNumber = assetPairs;

        this.orderTypes = Object.keys(orderTypes);
        this.orderQuantityTypes = orderTypes;

        this.commissionMaker = commissionMaker;
        this.commissionPlatform = commissionPlatform;
        this.commissionTaker = commissionTaker;

        this.volume24h = volume24h;

        this.dataSource = GetPlacementDataSource(name);

        this.ticker = new PlacementTicker(ticker);

        this.setBlockedModules(maintenanceStatus);

        this.disabled = disabled;
    }

    setBlockedModules(maintenanceStatus) {
        Object.keys(maintenanceStatus).forEach((blockedModule) => {
            Object.keys(MaintenanceStatuses).forEach((status) => {
                if (MaintenanceStatuses[status] === blockedModule) {
                    this.blockedModules.push(MaintenanceStatuses[status]);
                }
            });
        });
    }

    isModuleBlocked(givenModule) {
        return this.blockedModules.some((blockedModule) => blockedModule === givenModule);
    }

    setTicker(ticker) {
        this.ticker = new PlacementTicker(ticker);
    }

    get isTradingBlocked() {
        return this.isModuleBlocked(MaintenanceStatuses.TRADING);
    }

    get isWithdrawalBlocked() {
        return this.isModuleBlocked(MaintenanceStatuses.WITHDRAWAL);
    }

    get isDepositBlocked() {
        return this.isModuleBlocked(MaintenanceStatuses.DEPOSIT);
    }
}
