// This file was autogenerated. Please do not change.
export interface IUpdateMemberPayload {
    groupId: string;
    role: ('default' | 'creator' | 'admin' | 'member');
    userId?: string;
}

export default class UpdateMemberPayload {
    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    readonly _role: ('default' | 'creator' | 'admin' | 'member');

    /** Description: User's role in group */
    get role(): ('default' | 'creator' | 'admin' | 'member') {
        return this._role;
    }

    readonly _userId: string | undefined;

    /** Description: User's unique identifier */
    get userId(): string | undefined {
        return this._userId;
    }

    constructor(props: IUpdateMemberPayload) {
        this._groupId = props.groupId.trim();
        this._role = props.role;
        if (typeof props.userId === 'string') {
            this._userId = props.userId.trim();
        }
    }

    serialize(): IUpdateMemberPayload {
        const data: IUpdateMemberPayload = {
            groupId: this._groupId,
            role: this._role,
        };
        if (typeof this._userId !== 'undefined') {
            data.userId = this._userId;
        }
        return data;
    }

    toJSON(): IUpdateMemberPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
            userId: !this._userId ? true : typeof this._userId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
