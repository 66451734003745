// This file was autogenerated. Please do not change.
export interface IGetAllowanceRequest {
    accountId: string;
    assetSymbol: string;
    blockchainName: string;
    placementName: string;
}

export default class GetAllowanceRequest {
    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _assetSymbol: string;

    /**
     * Example: USDT
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _blockchainName: string;

    /**
     * Example: BNB
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _placementName: string;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string {
        return this._placementName;
    }

    constructor(props: IGetAllowanceRequest) {
        this._accountId = props.accountId.trim();
        this._assetSymbol = props.assetSymbol.trim();
        this._blockchainName = props.blockchainName.trim();
        this._placementName = props.placementName.trim();
    }

    serialize(): IGetAllowanceRequest {
        const data: IGetAllowanceRequest = {
            accountId: this._accountId,
            assetSymbol: this._assetSymbol,
            blockchainName: this._blockchainName,
            placementName: this._placementName,
        };
        return data;
    }

    toJSON(): IGetAllowanceRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
