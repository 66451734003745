// This file was autogenerated. Please do not change.
export interface IGasPrices {
    high: string;
    low: string;
    mean: string;
}

export default class GasPrices {
    readonly _high: number;

    /**
     * Example: 1
     */
    get high(): number {
        return this._high;
    }

    readonly _low: number;

    /**
     * Example: 1
     */
    get low(): number {
        return this._low;
    }

    readonly _mean: number;

    /**
     * Example: 1
     */
    get mean(): number {
        return this._mean;
    }

    constructor(props: IGasPrices) {
        this._high = Number(props.high);
        this._low = Number(props.low);
        this._mean = Number(props.mean);
    }

    serialize(): IGasPrices {
        const data: IGasPrices = {
            high: String(this._high),
            low: String(this._low),
            mean: String(this._mean),
        };
        return data;
    }

    toJSON(): IGasPrices {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            high: true,
            low: true,
            mean: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
