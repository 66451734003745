import ApiError from 'Entities/ApiError';
import AccountInfoNamingSwaggerSchema from 'Entities/accountManagement/AccountInfoNamingSwaggerSchema';
import AccountManagerInfoWithGroup from 'Entities/accountManagement/AccountManagerInfoWithGroup';
import AdmitAccountRequestPayload from 'Entities/accountManagement/AdmitAccountRequestPayload';
import BaseAccountNamingPayload from 'Entities/accountManagement/BaseAccountNamingPayload';
import BaseAccountPayload from 'Entities/accountManagement/BaseAccountPayload';
import BaseAccountRequestPayload from 'Entities/accountManagement/BaseAccountRequestPayload';
import BaseAccountSharingPayload from 'Entities/accountManagement/BaseAccountSharingPayload';
import BasicAccountInfoSwaggerSchema from 'Entities/accountManagement/BasicAccountInfoSwaggerSchema';
import BasicAccountRequestInfoSwaggerSchema from 'Entities/accountManagement/BasicAccountRequestInfoSwaggerSchema';
import ConfirmedAccountRequestSwaggerSchema from 'Entities/accountManagement/ConfirmedAccountRequestSwaggerSchema';
import CreateTradeApiKeyPayload from 'Entities/accountManagement/CreateTradeApiKeyPayload';
import DeleteTradeApiKeyPayload from 'Entities/accountManagement/DeleteTradeApiKeyPayload';
import LinkPlacementResponse from 'Entities/accountManagement/LinkPlacementResponse';
import PlacementPayload from 'Entities/accountManagement/PlacementPayload';
import RepresentativeResponse from 'Entities/accountManagement/RepresentativeResponse';
import RevokeAccountPayload from 'Entities/accountManagement/RevokeAccountPayload';
import ServiceStatus from 'Entities/accountManagement/ServiceStatus';
import ShareAccountPayload from 'Entities/accountManagement/ShareAccountPayload';
import SharingAccountRequestInfoSwaggerSchema from 'Entities/accountManagement/SharingAccountRequestInfoSwaggerSchema';
import TradeApiKeyResponse from 'Entities/accountManagement/TradeApiKeyResponse';
import UpdateAccountPayload from 'Entities/accountManagement/UpdateAccountPayload';
import CreateTradeAPIKeyRequest from 'Entities/cexAccountManagement/CreateTradeAPIKeyRequest';
import DeleteAPIKeyRequest from 'Entities/cexAccountManagement/DeleteAPIKeyRequest';
import GetTradeAPIKeySecretsRequest from 'Entities/cexAccountManagement/GetTradeAPIKeySecretsRequest';
import GetTradeAPIKeysRequest from 'Entities/cexAccountManagement/GetTradeAPIKeysRequest';
import TradeAPIKey from 'Entities/cexAccountManagement/TradeAPIKey';
import Account from 'Entities/privatePresenter/Account';
import AccountParams from 'Entities/privatePresenter/AccountParams';
import AccountRequest from 'Entities/privatePresenter/AccountRequest';
import AccountRequestsRequest from 'Entities/privatePresenter/AccountRequestsRequest';
import AccountsParams from 'Entities/privatePresenter/AccountsParams';
import Balance from 'Entities/privatePresenter/Balance';
import BalanceRequest from 'Entities/privatePresenter/BalanceRequest';
import BalancesRequest from 'Entities/privatePresenter/BalancesRequest';
import Role from 'Entities/privatePresenter/Role';
import RoleRequest from 'Entities/privatePresenter/RoleRequest';
import RolesRequest from 'Entities/privatePresenter/RolesRequest';
import PoliciesRequest from 'Entities/publicPresenter/PoliciesRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class AccountsApi {
    static async create(baseaccountnamingpayload: BaseAccountNamingPayload, withHeaders?: boolean): Promise<{ data: AccountInfoNamingSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountnamingpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('create', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/create`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountnamingpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new AccountInfoNamingSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/create', { response: res, data: result, fields: [JSON.stringify({ baseaccountnamingpayload })] });
    }

    static async extCexCreateTradeApiKey(createtradeapikeypayload: CreateTradeApiKeyPayload, withHeaders?: boolean): Promise<{ data: TradeApiKeyResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createtradeapikeypayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extCexCreateTradeApiKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/cex/createTradeApiKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createtradeapikeypayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new TradeApiKeyResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extCexCreateTradeApiKey', { response: res, data: result, fields: [JSON.stringify({ createtradeapikeypayload })] });
    }

    static async extCexDeleteTradeApiKey(deletetradeapikeypayload: DeleteTradeApiKeyPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...deletetradeapikeypayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extCexDeleteTradeApiKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/cex/deleteTradeApiKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(deletetradeapikeypayload.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extCexDeleteTradeApiKey', { response: res, data: result, fields: [JSON.stringify({ deletetradeapikeypayload })] });
    }

    static async extCreate(baseaccountnamingpayload: BaseAccountNamingPayload, withHeaders?: boolean): Promise<{ data: AccountInfoNamingSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountnamingpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extCreate', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/create`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountnamingpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new AccountInfoNamingSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extCreate', { response: res, data: result, fields: [JSON.stringify({ baseaccountnamingpayload })] });
    }

    static async extGetLinkRepresentative(placementpayload: PlacementPayload, withHeaders?: boolean): Promise<{ data: RepresentativeResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placementpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGetLinkRepresentative', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/getLinkRepresentative`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placementpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new RepresentativeResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extGetLinkRepresentative', { response: res, data: result, fields: [JSON.stringify({ placementpayload })] });
    }

    static async extGetManagers(baseaccountpayload: BaseAccountPayload, withHeaders?: boolean): Promise<{ data: AccountManagerInfoWithGroup[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extGetManagers', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/getManagers`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new AccountManagerInfoWithGroup(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extGetManagers', { response: res, data: result, fields: [JSON.stringify({ baseaccountpayload })] });
    }

    static async extLinkPlacement(placementpayload: PlacementPayload, withHeaders?: boolean): Promise<{ data: LinkPlacementResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...placementpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extLinkPlacement', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/linkPlacement`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(placementpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new LinkPlacementResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extLinkPlacement', { response: res, data: result, fields: [JSON.stringify({ placementpayload })] });
    }

    static async extRefuse(baseaccountsharingpayload: BaseAccountSharingPayload, withHeaders?: boolean): Promise<{ data: BasicAccountInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountsharingpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extRefuse', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/refuse`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountsharingpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extRefuse', { response: res, data: result, fields: [JSON.stringify({ baseaccountsharingpayload })] });
    }

    static async extRequestAdmit(admitaccountrequestpayload: AdmitAccountRequestPayload, withHeaders?: boolean): Promise<{ data: ConfirmedAccountRequestSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...admitaccountrequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extRequestAdmit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/requests/admit`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(admitaccountrequestpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new ConfirmedAccountRequestSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extRequestAdmit', { response: res, data: result, fields: [JSON.stringify({ admitaccountrequestpayload })] });
    }

    static async extRequestsCancel(baseaccountrequestpayload: BaseAccountRequestPayload, withHeaders?: boolean): Promise<{ data: BasicAccountRequestInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountrequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extRequestsCancel', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/requests/cancel`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountrequestpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountRequestInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extRequestsCancel', { response: res, data: result, fields: [JSON.stringify({ baseaccountrequestpayload })] });
    }

    static async extRequestsReject(baseaccountrequestpayload: BaseAccountRequestPayload, withHeaders?: boolean): Promise<{ data: BasicAccountRequestInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountrequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extRequestsReject', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/requests/reject`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountrequestpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountRequestInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extRequestsReject', { response: res, data: result, fields: [JSON.stringify({ baseaccountrequestpayload })] });
    }

    static async extRevoke(revokeaccountpayload: RevokeAccountPayload, withHeaders?: boolean): Promise<{ data: BasicAccountInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...revokeaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extRevoke', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/revoke`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(revokeaccountpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extRevoke', { response: res, data: result, fields: [JSON.stringify({ revokeaccountpayload })] });
    }

    static async extShare(shareaccountpayload: ShareAccountPayload, withHeaders?: boolean): Promise<{ data: SharingAccountRequestInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...shareaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extShare', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/share`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(shareaccountpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new SharingAccountRequestInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extShare', { response: res, data: result, fields: [JSON.stringify({ shareaccountpayload })] });
    }

    static async extUpdate(updateaccountpayload: UpdateAccountPayload, withHeaders?: boolean): Promise<{ data: AccountInfoNamingSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updateaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('extUpdate', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/e/accounts/update`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updateaccountpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountInfoNamingSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/extUpdate', { response: res, data: result, fields: [JSON.stringify({ updateaccountpayload })] });
    }

    static async getManagers(baseaccountpayload: BaseAccountPayload, withHeaders?: boolean): Promise<{ data: AccountManagerInfoWithGroup[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getManagers', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/getManagers`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new AccountManagerInfoWithGroup(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/getManagers', { response: res, data: result, fields: [JSON.stringify({ baseaccountpayload })] });
    }

    static async refuse(baseaccountsharingpayload: BaseAccountSharingPayload, withHeaders?: boolean): Promise<{ data: BasicAccountInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountsharingpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('refuse', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/refuse`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountsharingpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/refuse', { response: res, data: result, fields: [JSON.stringify({ baseaccountsharingpayload })] });
    }

    static async requestAdmit(admitaccountrequestpayload: AdmitAccountRequestPayload, withHeaders?: boolean): Promise<{ data: ConfirmedAccountRequestSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...admitaccountrequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('requestAdmit', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/requests/admit`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(admitaccountrequestpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new ConfirmedAccountRequestSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/requestAdmit', { response: res, data: result, fields: [JSON.stringify({ admitaccountrequestpayload })] });
    }

    static async requestCancel(baseaccountrequestpayload: BaseAccountRequestPayload, withHeaders?: boolean): Promise<{ data: BasicAccountRequestInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountrequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('requestCancel', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/requests/cancel`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountrequestpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountRequestInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/requestCancel', { response: res, data: result, fields: [JSON.stringify({ baseaccountrequestpayload })] });
    }

    static async requestReject(baseaccountrequestpayload: BaseAccountRequestPayload, withHeaders?: boolean): Promise<{ data: BasicAccountRequestInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...baseaccountrequestpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('requestReject', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/requests/reject`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(baseaccountrequestpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountRequestInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/requestReject', { response: res, data: result, fields: [JSON.stringify({ baseaccountrequestpayload })] });
    }

    static async revoke(revokeaccountpayload: RevokeAccountPayload, withHeaders?: boolean): Promise<{ data: BasicAccountInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...revokeaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('revoke', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/revoke`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(revokeaccountpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new BasicAccountInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/revoke', { response: res, data: result, fields: [JSON.stringify({ revokeaccountpayload })] });
    }

    static async share(shareaccountpayload: ShareAccountPayload, withHeaders?: boolean): Promise<{ data: SharingAccountRequestInfoSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...shareaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('share', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/share`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(shareaccountpayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new SharingAccountRequestInfoSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/share', { response: res, data: result, fields: [JSON.stringify({ shareaccountpayload })] });
    }

    static async update(updateaccountpayload: UpdateAccountPayload, withHeaders?: boolean): Promise<{ data: AccountInfoNamingSwaggerSchema; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updateaccountpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('update', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/accounts/update`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updateaccountpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountInfoNamingSwaggerSchema(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/update', { response: res, data: result, fields: [JSON.stringify({ updateaccountpayload })] });
    }

    static async privateCexAccountsCreateTradeAPIKey(createtradeapikeyrequest: CreateTradeAPIKeyRequest, withHeaders?: boolean): Promise<{ data: Record<string, string>; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createtradeapikeyrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateCexAccountsCreateTradeAPIKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/cex/accounts/createTradeAPIKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createtradeapikeyrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateCexAccountsCreateTradeAPIKey', { response: res, data: result, fields: [JSON.stringify({ createtradeapikeyrequest })] });
    }

    static async privateCexAccountsDeleteTradeAPIKey(deleteapikeyrequest: DeleteAPIKeyRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...deleteapikeyrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateCexAccountsDeleteTradeAPIKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/cex/accounts/deleteTradeAPIKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(deleteapikeyrequest.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateCexAccountsDeleteTradeAPIKey', { response: res, data: result, fields: [JSON.stringify({ deleteapikeyrequest })] });
    }

    static async privateCexAccountsGetTradeAPIKeySecretsOnce(gettradeapikeysecretsrequest: GetTradeAPIKeySecretsRequest, withHeaders?: boolean): Promise<{ data: Record<string, string>; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...gettradeapikeysecretsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateCexAccountsGetTradeAPIKeySecretsOnce', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/cex/accounts/getTradeAPIKeySecretsOnce`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(gettradeapikeysecretsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateCexAccountsGetTradeAPIKeySecretsOnce', { response: res, data: result, fields: [JSON.stringify({ gettradeapikeysecretsrequest })] });
    }

    static async privateCexAccountsGetTradeAPIKeys(gettradeapikeysrequest: GetTradeAPIKeysRequest, withHeaders?: boolean): Promise<{ data: TradeAPIKey[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...gettradeapikeysrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateCexAccountsGetTradeAPIKeys', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/cex/accounts/getTradeAPIKeys`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(gettradeapikeysrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new TradeAPIKey(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateCexAccountsGetTradeAPIKeys', { response: res, data: result, fields: [JSON.stringify({ gettradeapikeysrequest })] });
    }

    static async privateGetAccount(accountparams: AccountParams, withHeaders?: boolean): Promise<{ data: Account; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...accountparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetAccount', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getAccount`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(accountparams.serialize()),
        });
        if (res.status === 200) {
            return { data: new Account(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetAccount', { response: res, data: result, fields: [JSON.stringify({ accountparams })] });
    }

    static async privateGetAccountRequest(accountrequestsrequest: AccountRequestsRequest, withHeaders?: boolean): Promise<{ data: AccountRequest; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...accountrequestsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetAccountRequest', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getAccountRequest`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(accountrequestsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AccountRequest(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetAccountRequest', { response: res, data: result, fields: [JSON.stringify({ accountrequestsrequest })] });
    }

    static async privateGetAccountRequests(accountrequestsrequest: AccountRequestsRequest, withHeaders?: boolean): Promise<{ data: AccountRequest[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...accountrequestsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetAccountRequests', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getAccountRequests`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(accountrequestsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new AccountRequest(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetAccountRequests', { response: res, data: result, fields: [JSON.stringify({ accountrequestsrequest })] });
    }

    static async privateGetAccounts(accountsparams: AccountsParams, withHeaders?: boolean): Promise<{ data: Account[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...accountsparams.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetAccounts', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getAccounts`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(accountsparams.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Account(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetAccounts', { response: res, data: result, fields: [JSON.stringify({ accountsparams })] });
    }

    static async privateGetBalance(balancerequest: BalanceRequest, withHeaders?: boolean): Promise<{ data: Balance; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...balancerequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetBalance', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getBalance`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(balancerequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Balance(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetBalance', { response: res, data: result, fields: [JSON.stringify({ balancerequest })] });
    }

    static async privateGetBalances(balancesrequest: BalancesRequest, withHeaders?: boolean): Promise<{ data: Balance[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...balancesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetBalances', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getBalances`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(balancesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Balance(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetBalances', { response: res, data: result, fields: [JSON.stringify({ balancesrequest })] });
    }

    static async privateGetRole(rolerequest: RoleRequest, withHeaders?: boolean): Promise<{ data: Role; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...rolerequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetRole', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getRole`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(rolerequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Role(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetRole', { response: res, data: result, fields: [JSON.stringify({ rolerequest })] });
    }

    static async privateGetRoles(rolesrequest: RolesRequest, withHeaders?: boolean): Promise<{ data: Role[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...rolesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetRoles', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getRoles`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(rolesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Role(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/privateGetRoles', { response: res, data: result, fields: [JSON.stringify({ rolesrequest })] });
    }

    static async publicGetPolicies(policiesrequest: PoliciesRequest, withHeaders?: boolean): Promise<{ data: string[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...policiesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetPolicies', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getPolicies`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(policiesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: await res.json(), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Accounts/publicGetPolicies', { response: res, data: result, fields: [JSON.stringify({ policiesrequest })] });
    }
}
