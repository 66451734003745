<template>
    <img
        :src="iconPath"
        :alt="`${ placement } placement icon`"
        :style="`width: ${ size }px; height: ${ size }px`"
        class="placement-icon"
    />
</template>

<script>
import { placementsStyle } from 'Config/placementsManifest';

export default {
    name: 'PlacementIcon',
    props: {
        placement: {
            type: String,
            default: '',
        },
        size: {
            type: [Number, String],
            default: 14,
        },
    },
    computed: {
        placementName() {
            return this.placement.replace(' ', '').replace('.', '').toUpperCase();
        },
        placementStyle() {
            try {
                return placementsStyle[this.placementName];
            } catch (e) {
                this.$emit('icon-not-found', this.placementName);
                return placementsStyle.SINGLEBROKER;
            }
        },
        iconPath() {
            try {
                return require(`@/assets/images/icons/exchanges/${this.placementStyle.icons.default}`);
            } catch (e) {
                this.$emit('icon-not-found', this.placementName);
                return require('@/assets/images/icons/exchanges/singlebroker.svg');
            }
        },
    },
};
</script>
