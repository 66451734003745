// This file was autogenerated. Please do not change.
export interface ICreateDepositResponseData {
    status: string;
    transferId: string;
}

export default class CreateDepositResponseData {
    readonly _status: string;

    /** Description: Deposit status */
    get status(): string {
        return this._status;
    }

    readonly _transferId: string;

    /** Description: Deposit UID */
    get transferId(): string {
        return this._transferId;
    }

    constructor(props: ICreateDepositResponseData) {
        this._status = props.status.trim();
        this._transferId = props.transferId.trim();
    }

    serialize(): ICreateDepositResponseData {
        const data: ICreateDepositResponseData = {
            status: this._status,
            transferId: this._transferId,
        };
        return data;
    }

    toJSON(): ICreateDepositResponseData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            transferId: typeof this._transferId === 'string' && !!this._transferId.trim(),
            status: typeof this._status === 'string' && !!this._status.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
