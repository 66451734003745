// This file was autogenerated. Please do not change.
export interface IUserColorPalette {
    general?: string[];
    private: string[];
}

export default class UserColorPalette {
    readonly _general: string[] | undefined;

    /**
     * Example: #FF0000,#00FF00,#0000FF
     */
    get general(): string[] | undefined {
        return this._general;
    }

    readonly _private: string[];

    /**
     * Example: #FF0000,#00FF00,#0000FF
     */
    get private(): string[] {
        return this._private;
    }

    constructor(props: IUserColorPalette) {
        if (props.general) {
            this._general = props.general;
        }
        this._private = props.private;
    }

    serialize(): IUserColorPalette {
        const data: IUserColorPalette = {
            private: this._private,
        };
        if (typeof this._general !== 'undefined') {
            data.general = this._general;
        }
        return data;
    }

    toJSON(): IUserColorPalette {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            general: !this._general ? true : this._general.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            private: this._private.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
