// This file was autogenerated. Please do not change.
export interface IUpdateAddressRequest {
    address?: string;
    alias?: string;
    blockchainName?: string;
    nativeAssetSymbol?: string;
    newAlias?: string;
}

export default class UpdateAddressRequest {
    readonly _address: string | undefined;

    get address(): string | undefined {
        return this._address;
    }

    readonly _alias: string | undefined;

    get alias(): string | undefined {
        return this._alias;
    }

    readonly _blockchainName: string | undefined;

    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _nativeAssetSymbol: string | undefined;

    get nativeAssetSymbol(): string | undefined {
        return this._nativeAssetSymbol;
    }

    readonly _newAlias: string | undefined;

    get newAlias(): string | undefined {
        return this._newAlias;
    }

    constructor(props: IUpdateAddressRequest) {
        if (typeof props.address === 'string') {
            this._address = props.address.trim();
        }
        if (typeof props.alias === 'string') {
            this._alias = props.alias.trim();
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.nativeAssetSymbol === 'string') {
            this._nativeAssetSymbol = props.nativeAssetSymbol.trim();
        }
        if (typeof props.newAlias === 'string') {
            this._newAlias = props.newAlias.trim();
        }
    }

    serialize(): IUpdateAddressRequest {
        const data: IUpdateAddressRequest = {
        };
        if (typeof this._address !== 'undefined') {
            data.address = this._address;
        }
        if (typeof this._alias !== 'undefined') {
            data.alias = this._alias;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._nativeAssetSymbol !== 'undefined') {
            data.nativeAssetSymbol = this._nativeAssetSymbol;
        }
        if (typeof this._newAlias !== 'undefined') {
            data.newAlias = this._newAlias;
        }
        return data;
    }

    toJSON(): IUpdateAddressRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: !this._alias ? true : typeof this._alias === 'string',
            address: !this._address ? true : typeof this._address === 'string',
            nativeAssetSymbol: !this._nativeAssetSymbol ? true : typeof this._nativeAssetSymbol === 'string',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            newAlias: !this._newAlias ? true : typeof this._newAlias === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
