// This file was autogenerated. Please do not change.
export interface IFuturesFundingTotal {
    placementName?: string;
    quantity?: string;
}

export default class FuturesFundingTotal {
    readonly _placementName: string | undefined;

    /**
     * Example: Binance Futures
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 12.25586354
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    constructor(props: IFuturesFundingTotal) {
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
    }

    serialize(): IFuturesFundingTotal {
        const data: IFuturesFundingTotal = {
        };
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        return data;
    }

    toJSON(): IFuturesFundingTotal {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
