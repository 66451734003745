import ApiError from 'Entities/ApiError';
import NotificationsRequest from 'Entities/privatePresenter/NotificationsRequest';
import UserNotification from 'Entities/privatePresenter/UserNotification';
import InternalUserResponse from 'Entities/userLoginHistory/InternalUserResponse';
import LogsPayload from 'Entities/userLoginHistory/LogsPayload';
import OnboardingPayload from 'Entities/userLoginHistory/OnboardingPayload';
import OnboardingResponse from 'Entities/userLoginHistory/OnboardingResponse';
import ServiceStatus from 'Entities/userLoginHistory/ServiceStatus';
import UserAuthEvents from 'Entities/userLoginHistory/UserAuthEvents';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class UserApi {
    static async privateGetNotifications(notificationsrequest: NotificationsRequest, withHeaders?: boolean): Promise<{ data: UserNotification[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...notificationsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetNotifications', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getNotifications`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(notificationsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new UserNotification(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('User/privateGetNotifications', { response: res, data: result, fields: [JSON.stringify({ notificationsrequest })] });
    }

    static async extGetCurrentUser(withHeaders?: boolean): Promise<{ data: InternalUserResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/e/users/getCurrentUser`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new InternalUserResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('User/extGetCurrentUser', { response: res, data: result, fields: [] });
    }

    static async getAuthEvents(logspayload: LogsPayload, withHeaders?: boolean): Promise<{ data: UserAuthEvents; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...logspayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('getAuthEvents', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/getAuthEvents`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(logspayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new UserAuthEvents(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('User/getAuthEvents', { response: res, data: result, fields: [JSON.stringify({ logspayload })] });
    }

    static async getCurrentUser(withHeaders?: boolean): Promise<{ data: InternalUserResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/getCurrentUser`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new InternalUserResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('User/getCurrentUser', { response: res, data: result, fields: [] });
    }

    static async saveOnboardingStatus(onboardingpayload: OnboardingPayload, withHeaders?: boolean): Promise<{ data: OnboardingResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...onboardingpayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('saveOnboardingStatus', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/users/saveOnboardingStatus`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(onboardingpayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new OnboardingResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('User/saveOnboardingStatus', { response: res, data: result, fields: [JSON.stringify({ onboardingpayload })] });
    }
}
