/* eslint-disable max-classes-per-file */
/* eslint-disable no-prototype-builtins */
/**
 * @typedef {Object} OrderType
 *
 * @property {String} label - user-readable string
 * @property {String} value - API string, to identify type of order
 * @property {String} restriction - API string, to identify type of order restriction
 * @property {Array.<String>} typeNames - list of order expiry types
 */

/**
 *
 * @type {Object.<String, OrderType>}
 */
export const OrdersTypes = {
    LIMIT: {
        field: 'LIMIT',
        value: 'LIMIT',
        restriction: 'limit',
        typeNames: ['LIMIT', 'LIMIT_FOK', 'LIMIT_IOC', 'LIMIT_GTC'],
    },
    MARKET: {
        field: 'MARKET',
        value: 'MARKET',
        restriction: 'market',
        typeNames: ['MARKET'],
    },
    STOP_LIMIT: {
        field: 'STOP_LIMIT',
        value: 'LIMIT_STOP',
        restriction: null,
        typeNames: ['LIMIT_STOP_GTC', 'LIMIT_STOP_IOC', 'LIMIT_STOP_FOK'],
    },
    STOP_MARKET: {
        field: 'STOP_MARKET',
        value: 'MARKET_STOP',
        restriction: null,
        typeNames: ['MARKET_STOP'],
    },
    MARGIN_LIMIT: {
        field: 'MARGIN_LIMIT',
        value: 'MARGIN_LIMIT',
        restriction: null,
        typeNames: [],
    },
};

export const TradingOrdersTypes = OrdersTypes;

/**
 * @typedef {Object} OrderSide
 *
 * @property {String} value - api string
 * @property {String} field - localization field
 */

/**
 *
 * @type {Object.<string, OrderSide>}
 */
export const OrdersSides = {
    BUY: {
        value: 'BUY',
        field: 'BUY',
        restriction: 'buy',
    },
    SELL: {
        value: 'SELL',
        field: 'SELL',
        restriction: 'sell',
    },
};

export const FuturesOrderSides = {
    OPEN: {
        value: 'OPEN',
        field: 'OPEN',
        restriction: 'open',
    },
    CLOSE: {
        value: 'CLOSE',
        field: 'CLOSE',
        restriction: 'close',
    },
};

export const OrderExpiries = {
    GTC: {
        label: 'Good Till Cancelled',
        value: 'GTC',
        orderTypes: ['LIMIT_GTC', 'LIMIT_STOP_GTC'],
        isCancelable: true,
    },
    IOC: {
        label: 'Immediate Or Cancel',
        value: 'IOC',
        orderTypes: ['LIMIT_IOC', 'LIMIT_STOP_IOC'],
        isCancelable: false,
    },
    FOK: {
        label: 'Fill Or Kill',
        value: 'FOK',
        orderTypes: ['LIMIT_FOK', 'LIMIT_STOP_FOK'],
        isCancelable: false,
    },
};

export const OrderQuantityTypes = {
    BASE: 'base',
    QUOTE: 'quote',
};

export const OrderActiveFieldValues = {
    QUANTITY: 'quantity',
    TOTAL: 'total',
};

export const OrderRestrictionFields = {
    QUANTITY: 'baseAsset',
    PRICE: 'price',
    TOTAL: 'quoteAsset',
};

export const OrderRestrictionTypes = {
    MIN: 'min',
    MAX: 'max',
    STEP_SIZE: 'stepSize',
};

export const FormElementValidatorFields = {
    [OrderRestrictionTypes.MIN]: 'minValue',
    [OrderRestrictionTypes.MAX]: 'maxValue',
    [OrderRestrictionTypes.STEP_SIZE]: 'stepSize',
};

export function GenerateRestrictions(givenRestriction) {
    const restriction = givenRestriction || {};

    return {
        [OrderRestrictionTypes.STEP_SIZE]:
      +restriction[OrderRestrictionTypes.STEP_SIZE] || null,
        [OrderRestrictionTypes.MIN]:
      +restriction[OrderRestrictionTypes.MIN] || null,
        [OrderRestrictionTypes.MAX]:
      +restriction[OrderRestrictionTypes.MAX] || null,
    };
}

export function RestrictionsAdapter(restriction) {
    return {
        [FormElementValidatorFields[OrderRestrictionTypes.STEP_SIZE]]:
      restriction[OrderRestrictionTypes.STEP_SIZE] || null,
        [FormElementValidatorFields[OrderRestrictionTypes.MIN]]:
      restriction[OrderRestrictionTypes.MIN] || -Infinity,
        [FormElementValidatorFields[OrderRestrictionTypes.MAX]]:
      restriction[OrderRestrictionTypes.MAX] || Infinity,
    };
}

export class Order {
    accountId: any;

    assetPairId: any;

    placementId: any;

    side: any;

    type: any;

    timeInForce: any;

    quantity: any;

    quoteQuantity: any;

    constructor(data) {
        const {
            accountId, assetPairId, placementId, side,
        } = data;

        this.accountId = accountId;
        this.assetPairId = assetPairId;
        this.placementId = placementId;
        this.side = side;
    }

    setQuantity({ placementOrderTypes, quantity, total }) {
        let orderType = this.type;

        if (placementOrderTypes?.hasOwnProperty(this.type)) {
            orderType = this.type;
        } else if (
      placementOrderTypes?.hasOwnProperty(`${this.type}_${this.timeInForce}`)
        ) {
            orderType = `${this.type}_${this.timeInForce}`;
        } else {
            return false;
        }

        if (placementOrderTypes[orderType][this.side] === OrderQuantityTypes.BASE) {
            this.quantity = quantity;
        } else if (
            placementOrderTypes[orderType][this.side] === OrderQuantityTypes.QUOTE
        ) {
            this.quoteQuantity = total;
        }
    }
}

export class LimitOrder extends Order {
    price: any;

    constructor(data) {
        super(data);

        this.type = OrdersTypes.LIMIT.value;

        const { timeInForce, price } = data;

        this.timeInForce = timeInForce;
        this.price = price;

        this.setQuantity(data);
    }
}

export class MarketOrder extends Order {
    constructor(data) {
        super(data);

        this.type = OrdersTypes.MARKET.value;

        this.setQuantity(data);
    }
}

export class StopLimitOrder extends Order {
    price: any;

    triggerPrice: any;

    marketPrice: any;

    constructor(data) {
        super(data);

        this.type = OrdersTypes.STOP_LIMIT.value;

        const {
            price, triggerPrice, marketPrice, timeInForce,
        } = data;

        this.price = price;
        this.triggerPrice = triggerPrice;
        this.marketPrice = marketPrice;
        this.timeInForce = timeInForce;

        this.setQuantity(data);
    }
}

export class StopMarketOrder extends Order {
    price: any;

    triggerPrice: any;

    marketPrice: any;

    constructor(data) {
        super(data);

        this.type = OrdersTypes.STOP_MARKET.value;

        const { price, triggerPrice, marketPrice } = data;

        this.price = price;
        this.triggerPrice = triggerPrice;
        this.marketPrice = marketPrice;

        this.setQuantity(data);
    }
}
