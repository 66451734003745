// This file was autogenerated. Please do not change.
export interface ISpotOrdersTotal {
    commissionQuantity?: string;
    quantity?: string;
    tradeQuantityAvg?: string;
    tradesCount?: number;
}

export default class SpotOrdersTotal {
    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 0.00309598
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 1.84393625
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _tradeQuantityAvg: number | undefined;

    /**
     * Example: 0.00512204
     */
    get tradeQuantityAvg(): number | undefined {
        return this._tradeQuantityAvg;
    }

    readonly _tradesCount: number | undefined;

    /**
     * Example: 360
     */
    get tradesCount(): number | undefined {
        return this._tradesCount;
    }

    constructor(props: ISpotOrdersTotal) {
        if (typeof props.commissionQuantity === 'string') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.tradeQuantityAvg === 'string') {
            this._tradeQuantityAvg = Number(props.tradeQuantityAvg);
        }
        if (typeof props.tradesCount === 'number') {
            this._tradesCount = props.tradesCount;
        }
    }

    serialize(): ISpotOrdersTotal {
        const data: ISpotOrdersTotal = {
        };
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = String(this._commissionQuantity);
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._tradeQuantityAvg !== 'undefined') {
            data.tradeQuantityAvg = String(this._tradeQuantityAvg);
        }
        if (typeof this._tradesCount !== 'undefined') {
            data.tradesCount = this._tradesCount;
        }
        return data;
    }

    toJSON(): ISpotOrdersTotal {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            commissionQuantity: true,
            quantity: true,
            tradeQuantityAvg: true,
            tradesCount: !this._tradesCount ? true : typeof this._tradesCount === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
