
import Vue from 'vue';

import Icon from 'UI/Icon.vue';

/*
* emits
* close: () => void;
* */

export default Vue.extend({
    components: { Icon },
    data() {
        return {
            test: 'test',
        };
    },
    computed: {
        isShaking() {
            return this.$store.state.Accounts.isDemoAccountShaking;
        },
    },
});
