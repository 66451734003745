import AssignedAccount, { IAssignedAccount } from './AssignedAccount';

// This file was autogenerated. Please do not change.
export interface IGroupUserInfo {
    assignedAccounts?: IAssignedAccount[];
    email?: string;
    firstName?: string;
    joined?: string;
    lastName?: string;
    modified?: string;
    role?: string;
    roleAlias?: string;
    uid?: string;
}

export default class GroupUserInfo {
    readonly _assignedAccounts: AssignedAccount[] | undefined;

    /** Description: AssignedAccounts */
    get assignedAccounts(): AssignedAccount[] | undefined {
        return this._assignedAccounts;
    }

    readonly _email: string | undefined;

    /** Description: Email */
    get email(): string | undefined {
        return this._email;
    }

    readonly _firstName: string | undefined;

    /** Description: First Name */
    get firstName(): string | undefined {
        return this._firstName;
    }

    readonly _joined: string | undefined;

    /** Description: Joined */
    get joined(): string | undefined {
        return this._joined;
    }

    readonly _lastName: string | undefined;

    /** Description: Last Name */
    get lastName(): string | undefined {
        return this._lastName;
    }

    readonly _modified: string | undefined;

    /** Description: Modified */
    get modified(): string | undefined {
        return this._modified;
    }

    readonly _role: string | undefined;

    /** Description: Role */
    get role(): string | undefined {
        return this._role;
    }

    readonly _roleAlias: string | undefined;

    /** Description: RoleAlias */
    get roleAlias(): string | undefined {
        return this._roleAlias;
    }

    readonly _uid: string | undefined;

    /** Description: User's unique identifier */
    get uid(): string | undefined {
        return this._uid;
    }

    constructor(props: IGroupUserInfo) {
        if (props.assignedAccounts) {
            this._assignedAccounts = props.assignedAccounts.map((p) => new AssignedAccount(p));
        }
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
        if (typeof props.firstName === 'string') {
            this._firstName = props.firstName.trim();
        }
        if (typeof props.joined === 'string') {
            this._joined = props.joined.trim();
        }
        if (typeof props.lastName === 'string') {
            this._lastName = props.lastName.trim();
        }
        if (typeof props.modified === 'string') {
            this._modified = props.modified.trim();
        }
        if (typeof props.role === 'string') {
            this._role = props.role.trim();
        }
        if (typeof props.roleAlias === 'string') {
            this._roleAlias = props.roleAlias.trim();
        }
        if (typeof props.uid === 'string') {
            this._uid = props.uid.trim();
        }
    }

    serialize(): IGroupUserInfo {
        const data: IGroupUserInfo = {
        };
        if (typeof this._assignedAccounts !== 'undefined') {
            data.assignedAccounts = this._assignedAccounts.map((p) => p.serialize());
        }
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        if (typeof this._firstName !== 'undefined') {
            data.firstName = this._firstName;
        }
        if (typeof this._joined !== 'undefined') {
            data.joined = this._joined;
        }
        if (typeof this._lastName !== 'undefined') {
            data.lastName = this._lastName;
        }
        if (typeof this._modified !== 'undefined') {
            data.modified = this._modified;
        }
        if (typeof this._role !== 'undefined') {
            data.role = this._role;
        }
        if (typeof this._roleAlias !== 'undefined') {
            data.roleAlias = this._roleAlias;
        }
        if (typeof this._uid !== 'undefined') {
            data.uid = this._uid;
        }
        return data;
    }

    toJSON(): IGroupUserInfo {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            uid: !this._uid ? true : typeof this._uid === 'string',
            firstName: !this._firstName ? true : typeof this._firstName === 'string',
            lastName: !this._lastName ? true : typeof this._lastName === 'string',
            email: !this._email ? true : typeof this._email === 'string',
            role: !this._role ? true : typeof this._role === 'string',
            roleAlias: !this._roleAlias ? true : typeof this._roleAlias === 'string',
            joined: !this._joined ? true : typeof this._joined === 'string',
            modified: !this._modified ? true : typeof this._modified === 'string',
            assignedAccounts: !this._assignedAccounts ? true : this._assignedAccounts.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
