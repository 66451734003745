// This file was autogenerated. Please do not change.
export interface IStakingPositionsRequest {
    accountId: string;
    assetId?: number;
    assetSymbol?: string;
    duration?: number;
    includeTotal?: boolean;
    orderBy?: ('placement' | 'asset' | 'duration' | 'apr' | 'amount' | 'rewardAmount' | 'daysLeft');
    ordering?: ('desc' | 'asc' | 'DESC' | 'ASC');
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
    status?: string;
    statuses?: string[];
}

export default class StakingPositionsRequest {
    readonly _accountId: string;

    get accountId(): string {
        return this._accountId;
    }

    readonly _assetId: number | undefined;

    get assetId(): number | undefined {
        return this._assetId;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Example: ETH
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _duration: number | undefined;

    /**
     * Example: 30
     */
    get duration(): number | undefined {
        return this._duration;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _orderBy: ('placement' | 'asset' | 'duration' | 'apr' | 'amount' | 'rewardAmount' | 'daysLeft') | undefined;

    get orderBy(): ('placement' | 'asset' | 'duration' | 'apr' | 'amount' | 'rewardAmount' | 'daysLeft') | undefined {
        return this._orderBy;
    }

    readonly _ordering: ('desc' | 'asc' | 'DESC' | 'ASC') | undefined;

    get ordering(): ('desc' | 'asc' | 'DESC' | 'ASC') | undefined {
        return this._ordering;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    readonly _statuses: string[] | undefined;

    get statuses(): string[] | undefined {
        return this._statuses;
    }

    constructor(props: IStakingPositionsRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.assetId === 'number') {
            this._assetId = props.assetId;
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.duration === 'number') {
            this._duration = props.duration;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (props.orderBy) {
            this._orderBy = props.orderBy;
        }
        if (props.ordering) {
            this._ordering = props.ordering;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (props.statuses) {
            this._statuses = props.statuses;
        }
    }

    serialize(): IStakingPositionsRequest {
        const data: IStakingPositionsRequest = {
            accountId: this._accountId,
        };
        if (typeof this._assetId !== 'undefined') {
            data.assetId = this._assetId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._duration !== 'undefined') {
            data.duration = this._duration;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._orderBy !== 'undefined') {
            data.orderBy = this._orderBy;
        }
        if (typeof this._ordering !== 'undefined') {
            data.ordering = this._ordering;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._statuses !== 'undefined') {
            data.statuses = this._statuses;
        }
        return data;
    }

    toJSON(): IStakingPositionsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetId: !this._assetId ? true : typeof this._assetId === 'number',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            duration: !this._duration ? true : typeof this._duration === 'number',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            statuses: !this._statuses ? true : this._statuses.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
