// This file was autogenerated. Please do not change.
export interface ILiquidityPosition {
    aAssetQuantity: string;
    bAssetQuantity: string;
    share: string;
}

export default class LiquidityPosition {
    readonly _aAssetQuantity: number;

    /**
     * Example: 100.25
     */
    get aAssetQuantity(): number {
        return this._aAssetQuantity;
    }

    readonly _bAssetQuantity: number;

    /**
     * Example: 200.25
     */
    get bAssetQuantity(): number {
        return this._bAssetQuantity;
    }

    readonly _share: number;

    /**
     * Example: 0.01
     */
    get share(): number {
        return this._share;
    }

    constructor(props: ILiquidityPosition) {
        this._aAssetQuantity = Number(props.aAssetQuantity);
        this._bAssetQuantity = Number(props.bAssetQuantity);
        this._share = Number(props.share);
    }

    serialize(): ILiquidityPosition {
        const data: ILiquidityPosition = {
            aAssetQuantity: String(this._aAssetQuantity),
            bAssetQuantity: String(this._bAssetQuantity),
            share: String(this._share),
        };
        return data;
    }

    toJSON(): ILiquidityPosition {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetQuantity: true,
            bAssetQuantity: true,
            share: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
