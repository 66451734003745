// This file was autogenerated. Please do not change.
export interface IFuturesFunding {
    contractSymbol?: string;
    placementName?: string;
    quantity?: string;
    quantityQuoted?: string;
    timestamp?: string;
}

export default class FuturesFunding {
    readonly _contractSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string | undefined {
        return this._contractSymbol;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance Futures
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 12.25586354
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _quantityQuoted: number | undefined;

    /**
     * Example: 13.21415121
     */
    get quantityQuoted(): number | undefined {
        return this._quantityQuoted;
    }

    readonly _timestamp: string | undefined;

    /**
     * Example: 2022-04-21T10:08:23.516666Z
     */
    get timestamp(): string | undefined {
        return this._timestamp;
    }

    constructor(props: IFuturesFunding) {
        if (typeof props.contractSymbol === 'string') {
            this._contractSymbol = props.contractSymbol.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.quantityQuoted === 'string') {
            this._quantityQuoted = Number(props.quantityQuoted);
        }
        if (typeof props.timestamp === 'string') {
            this._timestamp = props.timestamp.trim();
        }
    }

    serialize(): IFuturesFunding {
        const data: IFuturesFunding = {
        };
        if (typeof this._contractSymbol !== 'undefined') {
            data.contractSymbol = this._contractSymbol;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._quantityQuoted !== 'undefined') {
            data.quantityQuoted = String(this._quantityQuoted);
        }
        if (typeof this._timestamp !== 'undefined') {
            data.timestamp = this._timestamp;
        }
        return data;
    }

    toJSON(): IFuturesFunding {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            contractSymbol: !this._contractSymbol ? true : typeof this._contractSymbol === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            quantity: true,
            quantityQuoted: true,
            timestamp: !this._timestamp ? true : typeof this._timestamp === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
