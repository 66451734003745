// This file was autogenerated. Please do not change.
export interface ISpotOrderPresenter {
    accountId: string;
    baseAssetId: number;
    baseAssetSymbol: string;
    baseBalanceId: string;
    baseQuantity: string;
    commissionAssetId: number;
    commissionAssetSymbol: string;
    commissionQuantity: string;
    createdAt: string;
    executedBaseQuantity: string;
    executedQuoteQuantity: string;
    id: string;
    initiatorType: string;
    initiatorUserId?: string;
    isBuy: boolean;
    orderType: ('LIMIT' | 'MARKET' | 'LIMIT_STOP_LOSS' | 'LIMIT_FOK' | 'LIMIT_GTC' | 'LIMIT_IOC' | 'MARKET_STOP' | 'LIMIT_STOP_GTC' | 'LIMIT_STOP_FOK' | 'LIMIT_STOP_IOC');
    outerId: string;
    parentId?: number;
    parentType?: string;
    placementId: number;
    placementName: string;
    placementPrecisionPrice: number;
    placementPrecisionQuantity: number;
    platformCommissionQuantity: string;
    price: string;
    priceAvg: string;
    quoteAssetId: number;
    quoteAssetSymbol?: string;
    quoteBalanceId: string;
    quoteQuantity: string;
    rejectReason?: string;
    spotAssetPairId: number;
    spotAssetPairSymbol: string;
    status: ('REGISTERED' | 'FILLED' | 'PARTIALY_FILLED' | 'CANCELED' | 'REJECTED');
    timeInForce?: string;
    triggerPrice?: string;
    updatedAt: string;
}

export default class SpotOrderPresenter {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _baseAssetId: number;

    /**
     * Example: 311
     */
    get baseAssetId(): number {
        return this._baseAssetId;
    }

    readonly _baseAssetSymbol: string;

    /**
     * Example: UNI
     */
    get baseAssetSymbol(): string {
        return this._baseAssetSymbol;
    }

    readonly _baseBalanceId: string;

    /**
     * Example: BL5LP5UP9VW
     */
    get baseBalanceId(): string {
        return this._baseBalanceId;
    }

    readonly _baseQuantity: number;

    /**
     * Example: 0.64
     */
    get baseQuantity(): number {
        return this._baseQuantity;
    }

    readonly _commissionAssetId: number;

    /**
     * Example: 7
     */
    get commissionAssetId(): number {
        return this._commissionAssetId;
    }

    readonly _commissionAssetSymbol: string;

    /**
     * Example: USDT
     */
    get commissionAssetSymbol(): string {
        return this._commissionAssetSymbol;
    }

    readonly _commissionQuantity: number;

    /**
     * Example: 0.079192
     */
    get commissionQuantity(): number {
        return this._commissionQuantity;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _executedBaseQuantity: number;

    /**
     * Example: 0.64
     */
    get executedBaseQuantity(): number {
        return this._executedBaseQuantity;
    }

    readonly _executedQuoteQuantity: number;

    /**
     * Example: 19.797571
     */
    get executedQuoteQuantity(): number {
        return this._executedQuoteQuantity;
    }

    readonly _id: string;

    /**
     * Example: ON58ZF4NM3T7DZ1WQCV65
     */
    get id(): string {
        return this._id;
    }

    readonly _initiatorType: string;

    /**
     * Example: user
     */
    get initiatorType(): string {
        return this._initiatorType;
    }

    readonly _initiatorUserId: string | undefined;

    /**
     * Example: URCJVWE3S
     */
    get initiatorUserId(): string | undefined {
        return this._initiatorUserId;
    }

    readonly _isBuy: boolean;

    get isBuy(): boolean {
        return this._isBuy;
    }

    readonly _orderType: ('LIMIT' | 'MARKET' | 'LIMIT_STOP_LOSS' | 'LIMIT_FOK' | 'LIMIT_GTC' | 'LIMIT_IOC' | 'MARKET_STOP' | 'LIMIT_STOP_GTC' | 'LIMIT_STOP_FOK' | 'LIMIT_STOP_IOC');

    /**
     * Example: MARKET
     */
    get orderType(): ('LIMIT' | 'MARKET' | 'LIMIT_STOP_LOSS' | 'LIMIT_FOK' | 'LIMIT_GTC' | 'LIMIT_IOC' | 'MARKET_STOP' | 'LIMIT_STOP_GTC' | 'LIMIT_STOP_FOK' | 'LIMIT_STOP_IOC') {
        return this._orderType;
    }

    readonly _outerId: string;

    /**
     * Example: 719634063
     */
    get outerId(): string {
        return this._outerId;
    }

    readonly _parentId: number | undefined;

    get parentId(): number | undefined {
        return this._parentId;
    }

    readonly _parentType: string | undefined;

    get parentType(): string | undefined {
        return this._parentType;
    }

    readonly _placementId: number;

    /**
     * Example: 2
     */
    get placementId(): number {
        return this._placementId;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _placementPrecisionPrice: number;

    /**
     * Example: 4
     */
    get placementPrecisionPrice(): number {
        return this._placementPrecisionPrice;
    }

    readonly _placementPrecisionQuantity: number;

    /**
     * Example: 2
     */
    get placementPrecisionQuantity(): number {
        return this._placementPrecisionQuantity;
    }

    readonly _platformCommissionQuantity: number;

    /**
     * Example: 0
     */
    get platformCommissionQuantity(): number {
        return this._platformCommissionQuantity;
    }

    readonly _price: number;

    /**
     * Example: 0
     */
    get price(): number {
        return this._price;
    }

    readonly _priceAvg: number;

    /**
     * Example: 30.9337046875
     */
    get priceAvg(): number {
        return this._priceAvg;
    }

    readonly _quoteAssetId: number;

    /**
     * Example: 7
     */
    get quoteAssetId(): number {
        return this._quoteAssetId;
    }

    readonly _quoteAssetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get quoteAssetSymbol(): string | undefined {
        return this._quoteAssetSymbol;
    }

    readonly _quoteBalanceId: string;

    /**
     * Example: B4V2D6AZAH3
     */
    get quoteBalanceId(): string {
        return this._quoteBalanceId;
    }

    readonly _quoteQuantity: number;

    /**
     * Example: 0
     */
    get quoteQuantity(): number {
        return this._quoteQuantity;
    }

    readonly _rejectReason: string | undefined;

    get rejectReason(): string | undefined {
        return this._rejectReason;
    }

    readonly _spotAssetPairId: number;

    /**
     * Example: 14
     */
    get spotAssetPairId(): number {
        return this._spotAssetPairId;
    }

    readonly _spotAssetPairSymbol: string;

    /**
     * Example: UNI/USDT
     */
    get spotAssetPairSymbol(): string {
        return this._spotAssetPairSymbol;
    }

    readonly _status: ('REGISTERED' | 'FILLED' | 'PARTIALY_FILLED' | 'CANCELED' | 'REJECTED');

    /**
     * Example: REGISTERED
     */
    get status(): ('REGISTERED' | 'FILLED' | 'PARTIALY_FILLED' | 'CANCELED' | 'REJECTED') {
        return this._status;
    }

    readonly _timeInForce: string | undefined;

    get timeInForce(): string | undefined {
        return this._timeInForce;
    }

    readonly _triggerPrice: number | undefined;

    get triggerPrice(): number | undefined {
        return this._triggerPrice;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: ISpotOrderPresenter) {
        this._accountId = props.accountId.trim();
        this._baseAssetId = props.baseAssetId;
        this._baseAssetSymbol = props.baseAssetSymbol.trim();
        this._baseBalanceId = props.baseBalanceId.trim();
        this._baseQuantity = Number(props.baseQuantity);
        this._commissionAssetId = props.commissionAssetId;
        this._commissionAssetSymbol = props.commissionAssetSymbol.trim();
        this._commissionQuantity = Number(props.commissionQuantity);
        this._createdAt = props.createdAt.trim();
        this._executedBaseQuantity = Number(props.executedBaseQuantity);
        this._executedQuoteQuantity = Number(props.executedQuoteQuantity);
        this._id = props.id.trim();
        this._initiatorType = props.initiatorType.trim();
        if (typeof props.initiatorUserId === 'string') {
            this._initiatorUserId = props.initiatorUserId.trim();
        }
        this._isBuy = props.isBuy;
        this._orderType = props.orderType;
        this._outerId = props.outerId.trim();
        if (typeof props.parentId === 'number') {
            this._parentId = props.parentId;
        }
        if (typeof props.parentType === 'string') {
            this._parentType = props.parentType.trim();
        }
        this._placementId = props.placementId;
        this._placementName = props.placementName.trim();
        this._placementPrecisionPrice = props.placementPrecisionPrice;
        this._placementPrecisionQuantity = props.placementPrecisionQuantity;
        this._platformCommissionQuantity = Number(props.platformCommissionQuantity);
        this._price = Number(props.price);
        this._priceAvg = Number(props.priceAvg);
        this._quoteAssetId = props.quoteAssetId;
        if (typeof props.quoteAssetSymbol === 'string') {
            this._quoteAssetSymbol = props.quoteAssetSymbol.trim();
        }
        this._quoteBalanceId = props.quoteBalanceId.trim();
        this._quoteQuantity = Number(props.quoteQuantity);
        if (typeof props.rejectReason === 'string') {
            this._rejectReason = props.rejectReason.trim();
        }
        this._spotAssetPairId = props.spotAssetPairId;
        this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        this._status = props.status;
        if (typeof props.timeInForce === 'string') {
            this._timeInForce = props.timeInForce.trim();
        }
        if (typeof props.triggerPrice === 'string') {
            this._triggerPrice = Number(props.triggerPrice);
        }
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): ISpotOrderPresenter {
        const data: ISpotOrderPresenter = {
            accountId: this._accountId,
            baseAssetId: this._baseAssetId,
            baseAssetSymbol: this._baseAssetSymbol,
            baseBalanceId: this._baseBalanceId,
            baseQuantity: String(this._baseQuantity),
            commissionAssetId: this._commissionAssetId,
            commissionAssetSymbol: this._commissionAssetSymbol,
            commissionQuantity: String(this._commissionQuantity),
            createdAt: this._createdAt,
            executedBaseQuantity: String(this._executedBaseQuantity),
            executedQuoteQuantity: String(this._executedQuoteQuantity),
            id: this._id,
            initiatorType: this._initiatorType,
            isBuy: this._isBuy,
            orderType: this._orderType,
            outerId: this._outerId,
            placementId: this._placementId,
            placementName: this._placementName,
            placementPrecisionPrice: this._placementPrecisionPrice,
            placementPrecisionQuantity: this._placementPrecisionQuantity,
            platformCommissionQuantity: String(this._platformCommissionQuantity),
            price: String(this._price),
            priceAvg: String(this._priceAvg),
            quoteAssetId: this._quoteAssetId,
            quoteBalanceId: this._quoteBalanceId,
            quoteQuantity: String(this._quoteQuantity),
            spotAssetPairId: this._spotAssetPairId,
            spotAssetPairSymbol: this._spotAssetPairSymbol,
            status: this._status,
            updatedAt: this._updatedAt,
        };
        if (typeof this._initiatorUserId !== 'undefined') {
            data.initiatorUserId = this._initiatorUserId;
        }
        if (typeof this._parentId !== 'undefined') {
            data.parentId = this._parentId;
        }
        if (typeof this._parentType !== 'undefined') {
            data.parentType = this._parentType;
        }
        if (typeof this._quoteAssetSymbol !== 'undefined') {
            data.quoteAssetSymbol = this._quoteAssetSymbol;
        }
        if (typeof this._rejectReason !== 'undefined') {
            data.rejectReason = this._rejectReason;
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        if (typeof this._triggerPrice !== 'undefined') {
            data.triggerPrice = String(this._triggerPrice);
        }
        return data;
    }

    toJSON(): ISpotOrderPresenter {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            baseAssetId: typeof this._baseAssetId === 'number',
            baseAssetSymbol: typeof this._baseAssetSymbol === 'string' && !!this._baseAssetSymbol.trim(),
            baseBalanceId: typeof this._baseBalanceId === 'string' && !!this._baseBalanceId.trim(),
            baseQuantity: true,
            commissionAssetId: typeof this._commissionAssetId === 'number',
            commissionAssetSymbol: typeof this._commissionAssetSymbol === 'string' && !!this._commissionAssetSymbol.trim(),
            commissionQuantity: true,
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            executedBaseQuantity: true,
            executedQuoteQuantity: true,
            id: typeof this._id === 'string' && !!this._id.trim(),
            initiatorType: typeof this._initiatorType === 'string' && !!this._initiatorType.trim(),
            initiatorUserId: !this._initiatorUserId ? true : typeof this._initiatorUserId === 'string',
            isBuy: typeof this._isBuy === 'boolean',
            outerId: typeof this._outerId === 'string' && !!this._outerId.trim(),
            parentId: !this._parentId ? true : typeof this._parentId === 'number',
            parentType: !this._parentType ? true : typeof this._parentType === 'string',
            placementId: typeof this._placementId === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            placementPrecisionPrice: typeof this._placementPrecisionPrice === 'number',
            placementPrecisionQuantity: typeof this._placementPrecisionQuantity === 'number',
            platformCommissionQuantity: true,
            price: true,
            priceAvg: true,
            quoteAssetId: typeof this._quoteAssetId === 'number',
            quoteAssetSymbol: !this._quoteAssetSymbol ? true : typeof this._quoteAssetSymbol === 'string',
            quoteBalanceId: typeof this._quoteBalanceId === 'string' && !!this._quoteBalanceId.trim(),
            quoteQuantity: true,
            rejectReason: !this._rejectReason ? true : typeof this._rejectReason === 'string',
            spotAssetPairId: typeof this._spotAssetPairId === 'number',
            spotAssetPairSymbol: typeof this._spotAssetPairSymbol === 'string' && !!this._spotAssetPairSymbol.trim(),
            timeInForce: !this._timeInForce ? true : typeof this._timeInForce === 'string',
            triggerPrice: true,
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
