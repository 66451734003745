
import Vue from 'vue';

interface Computed {
    currentYear: number;
}

type NoData = Record<string, never>;

export default Vue.extend<NoData, NoData, Computed>({
    computed: {
        currentYear() {
            return new Date().getFullYear();
        },
    },
});
