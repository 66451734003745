import RelatedBalanceAsset, { IRelatedBalanceAsset } from './RelatedBalanceAsset';

// This file was autogenerated. Please do not change.
export interface ITransferDetails {
    active: boolean;
    blockchainName?: string;
    destination?: string;
    notes?: string;
    relatedBalanceAsset?: IRelatedBalanceAsset;
    source?: string;
    transferMinSize: string;
}

export default class TransferDetails {
    readonly _active: boolean;

    /**
     * Example: true
     */
    get active(): boolean {
        return this._active;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: BTC
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _destination: string | undefined;

    /**
     * Example: Single Broker
     */
    get destination(): string | undefined {
        return this._destination;
    }

    readonly _notes: string | undefined;

    get notes(): string | undefined {
        return this._notes;
    }

    readonly _relatedBalanceAsset: RelatedBalanceAsset | undefined;

    get relatedBalanceAsset(): RelatedBalanceAsset | undefined {
        return this._relatedBalanceAsset;
    }

    readonly _source: string | undefined;

    /**
     * Example: Binance
     */
    get source(): string | undefined {
        return this._source;
    }

    readonly _transferMinSize: number;

    /**
     * Example: 0
     */
    get transferMinSize(): number {
        return this._transferMinSize;
    }

    constructor(props: ITransferDetails) {
        this._active = props.active;
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.destination === 'string') {
            this._destination = props.destination.trim();
        }
        if (typeof props.notes === 'string') {
            this._notes = props.notes.trim();
        }
        if (props.relatedBalanceAsset) {
            this._relatedBalanceAsset = new RelatedBalanceAsset(props.relatedBalanceAsset);
        }
        if (typeof props.source === 'string') {
            this._source = props.source.trim();
        }
        this._transferMinSize = Number(props.transferMinSize);
    }

    serialize(): ITransferDetails {
        const data: ITransferDetails = {
            active: this._active,
            transferMinSize: String(this._transferMinSize),
        };
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._destination !== 'undefined') {
            data.destination = this._destination;
        }
        if (typeof this._notes !== 'undefined') {
            data.notes = this._notes;
        }
        if (typeof this._relatedBalanceAsset !== 'undefined') {
            data.relatedBalanceAsset = this._relatedBalanceAsset.serialize();
        }
        if (typeof this._source !== 'undefined') {
            data.source = this._source;
        }
        return data;
    }

    toJSON(): ITransferDetails {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            active: typeof this._active === 'boolean',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            destination: !this._destination ? true : typeof this._destination === 'string',
            notes: !this._notes ? true : typeof this._notes === 'string',
            relatedBalanceAsset: !this._relatedBalanceAsset ? true : this._relatedBalanceAsset.validate().length === 0,
            source: !this._source ? true : typeof this._source === 'string',
            transferMinSize: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
