// This file was autogenerated. Please do not change.
export interface IDexPoolInfo {
    apr7d?: string;
    asset0Symbol?: string;
    asset1Symbol?: string;
    blockchainName?: string;
    commissionRate?: string;
    lpFee24h?: string;
    placementName?: string;
    reserve?: string;
    reserve24hAgo?: string;
    reserveChange?: string;
    roiUSD30d?: string;
    roiUSD365d?: string;
    roiUSD7d?: string;
    txCount24h?: number;
    txCount7d?: number;
    volume24h?: string;
    volume24hAgo?: string;
    volumeChange?: string;
}

export default class DexPoolInfo {
    readonly _apr7d: number | undefined;

    /**
     * Example: 7.5
     */
    get apr7d(): number | undefined {
        return this._apr7d;
    }

    readonly _asset0Symbol: string | undefined;

    /**
     * Example: USDT
     */
    get asset0Symbol(): string | undefined {
        return this._asset0Symbol;
    }

    readonly _asset1Symbol: string | undefined;

    /**
     * Example: WBNB
     */
    get asset1Symbol(): string | undefined {
        return this._asset1Symbol;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: BNB Smart Chain
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _commissionRate: number | undefined;

    /**
     * Example: 0.3
     */
    get commissionRate(): number | undefined {
        return this._commissionRate;
    }

    readonly _lpFee24h: number | undefined;

    /**
     * Example: 2344251.24
     */
    get lpFee24h(): number | undefined {
        return this._lpFee24h;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _reserve: number | undefined;

    /**
     * Example: 13445123.24
     */
    get reserve(): number | undefined {
        return this._reserve;
    }

    readonly _reserve24hAgo: number | undefined;

    /**
     * Example: 13445123.24
     */
    get reserve24hAgo(): number | undefined {
        return this._reserve24hAgo;
    }

    readonly _reserveChange: number | undefined;

    /**
     * Example: 25.51
     */
    get reserveChange(): number | undefined {
        return this._reserveChange;
    }

    readonly _roiUSD30d: number | undefined;

    /**
     * Example: -15.1532
     */
    get roiUSD30d(): number | undefined {
        return this._roiUSD30d;
    }

    readonly _roiUSD365d: number | undefined;

    /**
     * Example: 102.224
     */
    get roiUSD365d(): number | undefined {
        return this._roiUSD365d;
    }

    readonly _roiUSD7d: number | undefined;

    /**
     * Example: 1.2
     */
    get roiUSD7d(): number | undefined {
        return this._roiUSD7d;
    }

    readonly _txCount24h: number | undefined;

    /**
     * Example: 150
     */
    get txCount24h(): number | undefined {
        return this._txCount24h;
    }

    readonly _txCount7d: number | undefined;

    /**
     * Example: 1000
     */
    get txCount7d(): number | undefined {
        return this._txCount7d;
    }

    readonly _volume24h: number | undefined;

    /**
     * Example: 13445123.24
     */
    get volume24h(): number | undefined {
        return this._volume24h;
    }

    readonly _volume24hAgo: number | undefined;

    /**
     * Example: 13445123.24
     */
    get volume24hAgo(): number | undefined {
        return this._volume24hAgo;
    }

    readonly _volumeChange: number | undefined;

    /**
     * Example: 25.51
     */
    get volumeChange(): number | undefined {
        return this._volumeChange;
    }

    constructor(props: IDexPoolInfo) {
        if (typeof props.apr7d === 'string') {
            this._apr7d = Number(props.apr7d);
        }
        if (typeof props.asset0Symbol === 'string') {
            this._asset0Symbol = props.asset0Symbol.trim();
        }
        if (typeof props.asset1Symbol === 'string') {
            this._asset1Symbol = props.asset1Symbol.trim();
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.commissionRate === 'string') {
            this._commissionRate = Number(props.commissionRate);
        }
        if (typeof props.lpFee24h === 'string') {
            this._lpFee24h = Number(props.lpFee24h);
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.reserve === 'string') {
            this._reserve = Number(props.reserve);
        }
        if (typeof props.reserve24hAgo === 'string') {
            this._reserve24hAgo = Number(props.reserve24hAgo);
        }
        if (typeof props.reserveChange === 'string') {
            this._reserveChange = Number(props.reserveChange);
        }
        if (typeof props.roiUSD30d === 'string') {
            this._roiUSD30d = Number(props.roiUSD30d);
        }
        if (typeof props.roiUSD365d === 'string') {
            this._roiUSD365d = Number(props.roiUSD365d);
        }
        if (typeof props.roiUSD7d === 'string') {
            this._roiUSD7d = Number(props.roiUSD7d);
        }
        if (typeof props.txCount24h === 'number') {
            this._txCount24h = props.txCount24h;
        }
        if (typeof props.txCount7d === 'number') {
            this._txCount7d = props.txCount7d;
        }
        if (typeof props.volume24h === 'string') {
            this._volume24h = Number(props.volume24h);
        }
        if (typeof props.volume24hAgo === 'string') {
            this._volume24hAgo = Number(props.volume24hAgo);
        }
        if (typeof props.volumeChange === 'string') {
            this._volumeChange = Number(props.volumeChange);
        }
    }

    serialize(): IDexPoolInfo {
        const data: IDexPoolInfo = {
        };
        if (typeof this._apr7d !== 'undefined') {
            data.apr7d = String(this._apr7d);
        }
        if (typeof this._asset0Symbol !== 'undefined') {
            data.asset0Symbol = this._asset0Symbol;
        }
        if (typeof this._asset1Symbol !== 'undefined') {
            data.asset1Symbol = this._asset1Symbol;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._commissionRate !== 'undefined') {
            data.commissionRate = String(this._commissionRate);
        }
        if (typeof this._lpFee24h !== 'undefined') {
            data.lpFee24h = String(this._lpFee24h);
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._reserve !== 'undefined') {
            data.reserve = String(this._reserve);
        }
        if (typeof this._reserve24hAgo !== 'undefined') {
            data.reserve24hAgo = String(this._reserve24hAgo);
        }
        if (typeof this._reserveChange !== 'undefined') {
            data.reserveChange = String(this._reserveChange);
        }
        if (typeof this._roiUSD30d !== 'undefined') {
            data.roiUSD30d = String(this._roiUSD30d);
        }
        if (typeof this._roiUSD365d !== 'undefined') {
            data.roiUSD365d = String(this._roiUSD365d);
        }
        if (typeof this._roiUSD7d !== 'undefined') {
            data.roiUSD7d = String(this._roiUSD7d);
        }
        if (typeof this._txCount24h !== 'undefined') {
            data.txCount24h = this._txCount24h;
        }
        if (typeof this._txCount7d !== 'undefined') {
            data.txCount7d = this._txCount7d;
        }
        if (typeof this._volume24h !== 'undefined') {
            data.volume24h = String(this._volume24h);
        }
        if (typeof this._volume24hAgo !== 'undefined') {
            data.volume24hAgo = String(this._volume24hAgo);
        }
        if (typeof this._volumeChange !== 'undefined') {
            data.volumeChange = String(this._volumeChange);
        }
        return data;
    }

    toJSON(): IDexPoolInfo {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            apr7d: true,
            asset0Symbol: !this._asset0Symbol ? true : typeof this._asset0Symbol === 'string',
            asset1Symbol: !this._asset1Symbol ? true : typeof this._asset1Symbol === 'string',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            commissionRate: true,
            lpFee24h: true,
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            reserve: true,
            reserve24hAgo: true,
            reserveChange: true,
            roiUSD7d: true,
            roiUSD30d: true,
            roiUSD365d: true,
            txCount7d: !this._txCount7d ? true : typeof this._txCount7d === 'number',
            txCount24h: !this._txCount24h ? true : typeof this._txCount24h === 'number',
            volume24h: true,
            volume24hAgo: true,
            volumeChange: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
