const state = {
    activeAccountToIndex: 0,
    activeAccountPlacementIndex: 0,
};

const getters = {
    accountsList: (state, getters, rootState, rootGetters) => rootGetters['Accounts/activeAccounts'].filter(({ id }) => id !== rootGetters['Accounts/activeAccountID']),
    activeAccountToIndex: (state) => state.activeAccountToIndex,
    activeAccountTo: (state, getters) => getters.accountsList[getters.activeAccountToIndex],
    activeAccountToId: (state, getters) => (getters.activeAccountTo ? getters.activeAccountTo.id : null),

    placementsList: (state, getters, rootState, rootGetters) => rootGetters['Placements/placements'],
    activeAccountPlacementIndex: (state) => state.activeAccountPlacementIndex,
    activeAccountPlacement: (state, getters) => getters.placementsList[getters.activeAccountPlacementIndex],
    activeAccountPlacementId: (state, getters) => (getters.activeAccountPlacement ? getters.activeAccountPlacement.id : null),
    activeAccountPlacementName: (state, getters) => (getters.activeAccountPlacement ? getters.activeAccountPlacement.name : null),
};

const mutations = {
    SET_ACTIVE_ACCOUNT_TO_INDEX(state, index) {
        state.activeAccountToIndex = index;
    },
    SET_ACTIVE_ACCOUNT_PLACEMENT_INDEX(state, index) {
        state.activeAccountPlacementIndex = index;
    },
};

const actions = {
    setActiveAccountToIndex({ commit }, index) {
        commit('SET_ACTIVE_ACCOUNT_TO_INDEX', index);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
