// This file was autogenerated. Please do not change.
export interface IStreamTokenResponse {
    token: string;
}

export default class StreamTokenResponse {
    readonly _token: string;

    get token(): string {
        return this._token;
    }

    constructor(props: IStreamTokenResponse) {
        this._token = props.token.trim();
    }

    serialize(): IStreamTokenResponse {
        const data: IStreamTokenResponse = {
            token: this._token,
        };
        return data;
    }

    toJSON(): IStreamTokenResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            token: typeof this._token === 'string' && !!this._token.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
