import TransferDetails, { ITransferDetails } from './TransferDetails';

// This file was autogenerated. Please do not change.
export interface IAsset {
    description: string;
    disabled?: boolean;
    displayedDecimalDigits?: number;
    id: number;
    isQuotationAsset?: boolean;
    multiplier: number;
    placementName?: string;
    placementSymbol?: string;
    symbol: string;
    transferDetails?: ITransferDetails[];
    type: string;
}

export default class Asset {
    readonly _description: string;

    /**
     * Example: Bitcoin
     */
    get description(): string {
        return this._description;
    }

    readonly _disabled: boolean | undefined;

    get disabled(): boolean | undefined {
        return this._disabled;
    }

    readonly _displayedDecimalDigits: number | undefined;

    /**
     * Example: 10
     */
    get displayedDecimalDigits(): number | undefined {
        return this._displayedDecimalDigits;
    }

    readonly _id: number;

    /**
     * Example: 1
     */
    get id(): number {
        return this._id;
    }

    readonly _isQuotationAsset: boolean | undefined;

    /**
     * Example: true
     */
    get isQuotationAsset(): boolean | undefined {
        return this._isQuotationAsset;
    }

    readonly _multiplier: number;

    /**
     * Example: 100000000
     */
    get multiplier(): number {
        return this._multiplier;
    }

    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _placementSymbol: string | undefined;

    get placementSymbol(): string | undefined {
        return this._placementSymbol;
    }

    readonly _symbol: string;

    /**
     * Example: BTC
     */
    get symbol(): string {
        return this._symbol;
    }

    readonly _transferDetails: TransferDetails[] | undefined;

    get transferDetails(): TransferDetails[] | undefined {
        return this._transferDetails;
    }

    readonly _type: string;

    /**
     * Example: crypto-spot
     */
    get type(): string {
        return this._type;
    }

    constructor(props: IAsset) {
        this._description = props.description.trim();
        if (typeof props.disabled === 'boolean') {
            this._disabled = props.disabled;
        }
        if (typeof props.displayedDecimalDigits === 'number') {
            this._displayedDecimalDigits = props.displayedDecimalDigits;
        }
        this._id = props.id;
        if (typeof props.isQuotationAsset === 'boolean') {
            this._isQuotationAsset = props.isQuotationAsset;
        }
        this._multiplier = props.multiplier;
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.placementSymbol === 'string') {
            this._placementSymbol = props.placementSymbol.trim();
        }
        this._symbol = props.symbol.trim();
        if (props.transferDetails) {
            this._transferDetails = props.transferDetails.map((p) => new TransferDetails(p));
        }
        this._type = props.type.trim();
    }

    serialize(): IAsset {
        const data: IAsset = {
            description: this._description,
            id: this._id,
            multiplier: this._multiplier,
            symbol: this._symbol,
            type: this._type,
        };
        if (typeof this._disabled !== 'undefined') {
            data.disabled = this._disabled;
        }
        if (typeof this._displayedDecimalDigits !== 'undefined') {
            data.displayedDecimalDigits = this._displayedDecimalDigits;
        }
        if (typeof this._isQuotationAsset !== 'undefined') {
            data.isQuotationAsset = this._isQuotationAsset;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._placementSymbol !== 'undefined') {
            data.placementSymbol = this._placementSymbol;
        }
        if (typeof this._transferDetails !== 'undefined') {
            data.transferDetails = this._transferDetails.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): IAsset {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            description: typeof this._description === 'string' && !!this._description.trim(),
            disabled: !this._disabled ? true : typeof this._disabled === 'boolean',
            displayedDecimalDigits: !this._displayedDecimalDigits ? true : typeof this._displayedDecimalDigits === 'number',
            id: typeof this._id === 'number',
            isQuotationAsset: !this._isQuotationAsset ? true : typeof this._isQuotationAsset === 'boolean',
            multiplier: typeof this._multiplier === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            placementSymbol: !this._placementSymbol ? true : typeof this._placementSymbol === 'string',
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
            transferDetails: !this._transferDetails ? true : this._transferDetails.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            type: typeof this._type === 'string' && !!this._type.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
