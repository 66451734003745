// This file was autogenerated. Please do not change.
export interface ICancelOrderRequest {
    id: string;
}

export default class CancelOrderRequest {
    readonly _id: string;

    /**
     * Example: OFD64T4DTCLY5LMS2KRMJ
     */
    get id(): string {
        return this._id;
    }

    constructor(props: ICancelOrderRequest) {
        this._id = props.id.trim();
    }

    serialize(): ICancelOrderRequest {
        const data: ICancelOrderRequest = {
            id: this._id,
        };
        return data;
    }

    toJSON(): ICancelOrderRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
