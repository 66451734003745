// This file was autogenerated. Please do not change.
export interface ISpotAssetPairsRequest {
    assetPairSymbols?: string[];
    disabled?: boolean;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
}

export default class SpotAssetPairsRequest {
    readonly _assetPairSymbols: string[] | undefined;

    /** Description: Asset pairs symbols, e.g.  ETH/BTC, BTC/USDT */
    get assetPairSymbols(): string[] | undefined {
        return this._assetPairSymbols;
    }

    readonly _disabled: boolean | undefined;

    /** Description: Disability flag; if set to false, active pairs will be returned */
    get disabled(): boolean | undefined {
        return this._disabled;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    /** Description: Placement (CEx) ID */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Description: Placement platform Name
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: ISpotAssetPairsRequest) {
        if (props.assetPairSymbols) {
            this._assetPairSymbols = props.assetPairSymbols;
        }
        if (typeof props.disabled === 'boolean') {
            this._disabled = props.disabled;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): ISpotAssetPairsRequest {
        const data: ISpotAssetPairsRequest = {
        };
        if (typeof this._assetPairSymbols !== 'undefined') {
            data.assetPairSymbols = this._assetPairSymbols;
        }
        if (typeof this._disabled !== 'undefined') {
            data.disabled = this._disabled;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): ISpotAssetPairsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetPairSymbols: !this._assetPairSymbols ? true : this._assetPairSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            disabled: !this._disabled ? true : typeof this._disabled === 'boolean',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
