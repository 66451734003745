/* eslint-disable no-await-in-loop */
/* eslint-disable no-prototype-builtins */
import { TransferTypes } from 'Models/transfer';
import router from '@/router';

import AccountsTransfer from './Transfer/Accounts';

const state = {
    transferTypesValues: Object.values(TransferTypes),
    activeTransferType: TransferTypes.EXCHANGES,

    activePlacementFromIndex: 0,
    activePlacementToIndex: 1,

    activeCurrencyIndex: 0,
    quantity: 0,

    transferableAssets: null,

    currentTransferDetails: null,

    /**
   * {Object} confirmTransferChallengeHandlers
   *
   * @property {function} resolve
   * @property {function} reject
   */
    confirmTransferChallengeHandlers: null,

    currentFees: null,
    chosenFee: null,
};

type TransferState = typeof state;

export enum TransferGetters {
    transferTypesValues = 'transferTypesValues',
    activeTransferType = 'activeTransferType',
    placementsList = 'placementsList',
    activePlacementFromIndex = 'activePlacementFromIndex',
    activePlacementFrom = 'activePlacementFrom',
    activePlacementFromName = 'activePlacementFromName',
    activePlacementToIndex = 'activePlacementToIndex',
    activePlacementTo = 'activePlacementTo',
    activePlacementToName = 'activePlacementToName',
    currenciesList = 'currenciesList',
    activeCurrencyIndex = 'activeCurrencyIndex',
    activeCurrency = 'activeCurrency',
    activeCurrencySymbol = 'activeCurrencySymbol',
    quantity = 'quantity',
    transferableAssets = 'transferableAssets',
    hasTransferableAssets = 'hasTransferableAssets',
    hasPlacementTransferableAssets = 'hasPlacementTransferableAssets',
    transferablePlacementAssets = 'transferablePlacementAssets',
    currentTransferablePlacement = 'currentTransferablePlacement',
    currentTransferable = 'currentTransferable',
    currentTransferableMinSize = 'currentTransferableMinSize',
    currentTransferDetails = 'currentTransferDetails',
    confirmTransferChallengeHandlers = 'confirmTransferChallengeHandlers',
    hasConfirmTransferChallengeHandlers = 'hasConfirmTransferChallengeHandlers',
    confirmTransferChallengeResolve = 'confirmTransferChallengeResolve',
    confirmTransferChallengeReject = 'confirmTransferChallengeReject',
    currentFees = 'currentFees',
    chosenFee = 'chosenFee',
    chosenFeeData = 'chosenFeeData',
}

type Getter<R> = (state: TransferState, getters: Record<TransferGetters, any>, rootSate: any, rootGetters: any) => R;

interface Getters {
    [TransferGetters.transferTypesValues]: Getter<string[]>;
    [TransferGetters.activeTransferType]: Getter<string>;
    [TransferGetters.placementsList]: Getter<any>
    [TransferGetters.activePlacementFromIndex]: Getter<number>;
    [TransferGetters.activePlacementFrom]: Getter<null | Record<string, any>>;
    [TransferGetters.activePlacementFromName]: Getter<string>;
    [TransferGetters.activePlacementToIndex]: Getter<number>;
    [TransferGetters.activePlacementTo]: Getter<null | Record<string, any>>;
    [TransferGetters.activePlacementToName]: Getter<string>;
    [TransferGetters.currenciesList]: Getter<any>;
    [TransferGetters.activeCurrencyIndex]: Getter<any>;
    [TransferGetters.activeCurrency]: Getter<any>;
    [TransferGetters.activeCurrencySymbol]: Getter<any>;
    [TransferGetters.quantity]: Getter<any>;
    [TransferGetters.transferableAssets]: Getter<any>;
    [TransferGetters.hasTransferableAssets]: Getter<any>;
    [TransferGetters.hasPlacementTransferableAssets]: Getter<any>;
    [TransferGetters.transferablePlacementAssets]: Getter<any>;
    [TransferGetters.currentTransferablePlacement]: Getter<any>;
    [TransferGetters.currentTransferable]: Getter<any>;
    [TransferGetters.currentTransferableMinSize]: Getter<any>;
    [TransferGetters.currentTransferDetails]: Getter<any>;
    [TransferGetters.confirmTransferChallengeHandlers]: Getter<any>;
    [TransferGetters.hasConfirmTransferChallengeHandlers]: Getter<any>;
    [TransferGetters.confirmTransferChallengeResolve]: Getter<any>;
    [TransferGetters.confirmTransferChallengeReject]: Getter<any>;
    [TransferGetters.currentFees]: Getter<any>;
    [TransferGetters.chosenFee]: Getter<any>;
    [TransferGetters.chosenFeeData]: Getter<any>;
}

const getters: Getters = {
    transferTypesValues: (state) => state.transferTypesValues,
    activeTransferType: (state) => state.activeTransferType,

    placementsList: (state, getters, rootState, rootGetters) => rootGetters['Placements/placements'],
    activePlacementFromIndex: (state) => state.activePlacementFromIndex,
    activePlacementFrom: (state, getters) => {
        return (getters.placementsList.length > 0 ? getters.placementsList[getters.activePlacementFromIndex] : null);
    },
    activePlacementFromName: (state, getters) => (getters.activePlacementFrom ? getters.activePlacementFrom.name : ''),
    activePlacementToIndex: (state) => state.activePlacementToIndex,
    activePlacementTo: (state, getters) => {
        return (getters.placementsList.length > 0 ? getters.placementsList[getters.activePlacementToIndex] : null);
    },
    activePlacementToName: (state, getters) => (getters.activePlacementTo ? getters.activePlacementTo.name : ''),

    currenciesList: (state, getters, rootState, rootGetters) => {
        const fromTransferable = getters.transferablePlacementAssets(getters.activePlacementFromName.toUpperCase());
        const toTransferable = getters.transferablePlacementAssets(getters.activePlacementToName.toUpperCase());

        return rootGetters['Assets/GET_ASSETS'].filter(({ symbol }) => (
            (fromTransferable.hasOwnProperty(symbol) && getters.activeTransferType === TransferTypes.ACCOUNTS)
            || toTransferable.hasOwnProperty(symbol)
        ));
    },
    activeCurrencyIndex: (state) => state.activeCurrencyIndex,
    activeCurrency: (state, getters) => getters.currenciesList[getters.activeCurrencyIndex],
    activeCurrencySymbol: (state, getters) => getters.activeCurrency?.symbol || '',

    quantity: (state) => state.quantity,

    transferableAssets: (state) => state.transferableAssets,
    hasTransferableAssets: (state, getters) => !!getters.transferableAssets,
    hasPlacementTransferableAssets: (state, getters) => (placementName) => getters.transferableAssets && getters.transferableAssets.hasOwnProperty(placementName.toUpperCase()),
    transferablePlacementAssets: (state, getters) => (placementName) => (getters.hasTransferableAssets && getters.transferableAssets[placementName.toUpperCase()]
        ? getters.transferableAssets[placementName.toUpperCase()]
        : {}),

    currentTransferablePlacement: (state, getters) => {
        if (getters.transferableAssets && getters.activePlacementFromName) {
            return getters.hasPlacementTransferableAssets(getters.activePlacementFromName)
                ? getters.transferablePlacementAssets(getters.activePlacementFromName)
                : null;
        }
        return null;
    },
    currentTransferable: (_, getters) => {
        if (getters.currentTransferablePlacement && getters.activeCurrencySymbol) {
            return getters.currentTransferablePlacement[getters.activeCurrencySymbol];
        }
        return null;
    },
    currentTransferableMinSize: (state, getters) => {
        return (getters.currentTransferable && getters.activeTransferType === TransferTypes.EXCHANGES
            ? Math.max(+getters.currentTransferable.withdrawalMinSize, +getters.currentTransferable.depositMinSize)
            : 0);
    },

    currentTransferDetails: (state) => state.currentTransferDetails,

    confirmTransferChallengeHandlers: (state) => state.confirmTransferChallengeHandlers,
    hasConfirmTransferChallengeHandlers: (state, getters) => !!getters.confirmTransferChallengeHandlers,
    confirmTransferChallengeResolve: (state, getters) => (getters.hasConfirmTransferChallengeHandlers ? getters.confirmTransferChallengeHandlers.resolve : null),
    confirmTransferChallengeReject: (state, getters) => (getters.hasConfirmTransferChallengeHandlers ? getters.confirmTransferChallengeHandlers.reject : null),

    currentFees: (state) => state.currentFees,
    chosenFee: (state) => state.chosenFee,
    chosenFeeData: (state, getters) => (getters.chosenFee && getters.currentFees ? getters.currentFees[getters.chosenFee] : null),
};

const mutations = {
    SET_TRANSFER_TYPE(state, type) {
        state.activeTransferType = type;
    },

    SET_ACTIVE_PLACEMENT_FROM_INDEX(state, index) {
        state.activePlacementFromIndex = index;
    },
    SET_ACTIVE_PLACEMENT_TO_INDEX(state, index) {
        state.activePlacementToIndex = index;
    },

    SET_ACTIVE_CURRENCY_INDEX(state, index) {
        state.activeCurrencyIndex = index;
    },
    SET_QUANTITY(state, value) {
        state.quantity = value;
    },

    SET_TRANSFERABLE_DATA(state, { placement, value }) {
        state.transferableAssets = {
            [placement.toUpperCase()]: Object.fromEntries(
                value.map((transferablePair) => [transferablePair.symbol, transferablePair]),
            ),
            ...state.transferableAssets,
        };
    },

    SET_CURRENT_TRANSFER_DETAILS(state, details) {
        state.currentTransferDetails = details;
    },

    SET_CONFIRM_TRANSFER_CHALLENGE_HANDLERS(state, handlers) {
        state.confirmTransferChallengeHandlers = handlers;
    },

    SET_CURRENT_FEES(state, fees) {
        state.currentFees = fees;
    },
    SET_CHOSEN_FEE(state, feeType) {
        state.chosenFee = feeType;
    },
};

const actions = {
    setTransferType({ commit }, type) {
        commit('SET_TRANSFER_TYPE', type);
        commit('SET_ACTIVE_CURRENCY_INDEX', 0);
    },
    setCurrentFees({ commit }, fees) {
        commit('SET_CURRENT_FEES', fees);
    },
    setChosenFee({ commit }, feeType) {
        commit('SET_CHOSEN_FEE', feeType);
    },

    setActiveCurrencyIndex({ commit }, index) {
        commit('SET_ACTIVE_CURRENCY_INDEX', index);
    },
    setQuantity({ commit }, value) {
        commit('SET_QUANTITY', value);
    },

    setTransferableData({ commit }, data) {
        commit('SET_TRANSFERABLE_DATA', data);
    },

    setCurrentTransferDetails({ commit }, details) {
        commit('SET_CURRENT_TRANSFER_DETAILS', details);
    },

    setConfirmTransferChallengeHandlers({ commit }, handlers) {
        commit('SET_CONFIRM_TRANSFER_CHALLENGE_HANDLERS', handlers);
    },
    clearConfirmTransferChallengeHandlers({ commit }) {
        commit('SET_CONFIRM_TRANSFER_CHALLENGE_HANDLERS', null);
    },

    startConfirmTransferChallenge({ dispatch }, { nextChallengePath, doResolveRouterReplace, doRejectRouterReplace }) {
        // eslint-disable-next-line no-param-reassign
        nextChallengePath = nextChallengePath !== undefined ? nextChallengePath : (router as any).history.current.path;
        // eslint-disable-next-line no-param-reassign
        doResolveRouterReplace = doResolveRouterReplace !== undefined ? doResolveRouterReplace : true;
        // eslint-disable-next-line no-param-reassign
        doRejectRouterReplace = doRejectRouterReplace !== undefined ? doRejectRouterReplace : true;

        return new Promise((resolve, reject) => {
            dispatch('setConfirmTransferChallengeHandlers', {
                resolve: (...params) => {
                    if (doResolveRouterReplace) {
                        router.replace(nextChallengePath);
                    }
                    // WTF?
                    resolve(...(params as [number]));
                },
                reject: (...params) => {
                    if (doRejectRouterReplace) {
                        router.replace(nextChallengePath);
                    }
                    // WTF?
                    // eslint-disable-next-line prefer-promise-reject-errors
                    reject(...params);
                },
            });

            router.replace('/wallets/confirmTransfer');
        });
    },
    cancelTransferChallenge({ getters, dispatch }) {
        getters.confirmTransferChallengeReject();
        dispatch('clearConfirmTransferChallengeHandlers');
    },
    doneTransferChallenge({ getters, dispatch }) {
        getters.confirmTransferChallengeResolve();
        dispatch('clearConfirmTransferChallengeHandlers');
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

    modules: {
        Accounts: AccountsTransfer,
    },
};
