// This file was autogenerated. Please do not change.
export interface ILeverage {
    contractSymbol: string;
    leverage: string;
    placementName: string;
}

export default class Leverage {
    readonly _contractSymbol: string;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _leverage: string;

    /**
     * Example: 5
     */
    get leverage(): string {
        return this._leverage;
    }

    readonly _placementName: string;

    /**
     * Example: Binance Futures
     */
    get placementName(): string {
        return this._placementName;
    }

    constructor(props: ILeverage) {
        this._contractSymbol = props.contractSymbol.trim();
        this._leverage = props.leverage.trim();
        this._placementName = props.placementName.trim();
    }

    serialize(): ILeverage {
        const data: ILeverage = {
            contractSymbol: this._contractSymbol,
            leverage: this._leverage,
            placementName: this._placementName,
        };
        return data;
    }

    toJSON(): ILeverage {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            leverage: typeof this._leverage === 'string' && !!this._leverage.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
