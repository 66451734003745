// This file was autogenerated. Please do not change.
export interface IStatementRequest {
    accountId: string;
    from?: number;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    quotationAsset: string;
    to?: number;
}

export default class StatementRequest {
    readonly _accountId: string;

    /**
     * Example: A00000001
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _from: number | undefined;

    /**
     * Example: 1619448860411
     */
    get from(): number | undefined {
        return this._from;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 2
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 50
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _quotationAsset: string;

    /**
     * Example: USD
     */
    get quotationAsset(): string {
        return this._quotationAsset;
    }

    readonly _to: number | undefined;

    /**
     * Example: 1650984860411
     */
    get to(): number | undefined {
        return this._to;
    }

    constructor(props: IStatementRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.from === 'number') {
            this._from = props.from;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        this._quotationAsset = props.quotationAsset.trim();
        if (typeof props.to === 'number') {
            this._to = props.to;
        }
    }

    serialize(): IStatementRequest {
        const data: IStatementRequest = {
            accountId: this._accountId,
            quotationAsset: this._quotationAsset,
        };
        if (typeof this._from !== 'undefined') {
            data.from = this._from;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._to !== 'undefined') {
            data.to = this._to;
        }
        return data;
    }

    toJSON(): IStatementRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            from: !this._from ? true : typeof this._from === 'number',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            quotationAsset: typeof this._quotationAsset === 'string' && !!this._quotationAsset.trim(),
            to: !this._to ? true : typeof this._to === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
