// This file was autogenerated. Please do not change.
export interface ICreateGroupPayload {
    description?: string;
    name: string;
}

export default class CreateGroupPayload {
    readonly _description: string | undefined;

    /** Description: Group description */
    get description(): string | undefined {
        return this._description;
    }

    readonly _name: string;

    /** Description: Group name */
    get name(): string {
        return this._name;
    }

    constructor(props: ICreateGroupPayload) {
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._name = props.name.trim();
    }

    serialize(): ICreateGroupPayload {
        const data: ICreateGroupPayload = {
            name: this._name,
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        return data;
    }

    toJSON(): ICreateGroupPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            name: typeof this._name === 'string' && !!this._name.trim(),
            description: !this._description ? true : typeof this._description === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
