<template>
    <transition-group
        tag="div"
        :class="[s.container, { [s.front]: snackbarsLength > 0 }]"
        name="list"
    >
        <SnackbarItem
            v-for="snackbar in snackbars"
            :key="snackbar.id"
            :snackbar="snackbar"
        >
            <template #icon>
                <Icon :icon="SnackNotificationIcons[snackbar.type]" />
            </template>
        </SnackbarItem>
    </transition-group>
</template>

<script>
import { mapGetters } from 'vuex';

import { SnackNotificationIcons } from 'Models/snackbars';
import Icon from 'UI/Icon.vue';

import SnackbarItem from './SnackbarItem.vue';

export default {
    name: 'SnackbarContainer',
    components: {
        SnackbarItem,
        Icon,
    },
    data() {
        return {
            SnackNotificationIcons,
        };
    },
    computed: {
        ...mapGetters({
            snackbars: 'Notificator/notifications',
        }),
        snackbarsLength() {
            return this.snackbars.length;
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    position: fixed;
    right: 0;
    top: 0;
    padding: calc(var(--sb-header-height) + var(--m-xl)) var(--m-l) 0;
    display: flex;
    flex-direction: column-reverse;
    &.front {
        z-index: 9999;
    }
}
</style>

<style>
.list-enter-active, .list-leave-active {
    transition: all .3s;
}
.list-enter, .list-leave-to {
    opacity: 0;
    transform: translateX(40px);
}
</style>
