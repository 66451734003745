import RouteNodeExchange, { IRouteNodeExchange } from './RouteNodeExchange';

// This file was autogenerated. Please do not change.
export interface IRouteNode {
    assetSymbol?: string;
    exchanges?: IRouteNodeExchange[];
    id?: string;
    type?: string;
}

export default class RouteNode {
    readonly _assetSymbol: string | undefined;

    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _exchanges: RouteNodeExchange[] | undefined;

    get exchanges(): RouteNodeExchange[] | undefined {
        return this._exchanges;
    }

    readonly _id: string | undefined;

    get id(): string | undefined {
        return this._id;
    }

    readonly _type: string | undefined;

    get type(): string | undefined {
        return this._type;
    }

    constructor(props: IRouteNode) {
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (props.exchanges) {
            this._exchanges = props.exchanges.map((p) => new RouteNodeExchange(p));
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.type === 'string') {
            this._type = props.type.trim();
        }
    }

    serialize(): IRouteNode {
        const data: IRouteNode = {
        };
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._exchanges !== 'undefined') {
            data.exchanges = this._exchanges.map((p) => p.serialize());
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IRouteNode {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            exchanges: !this._exchanges ? true : this._exchanges.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            id: !this._id ? true : typeof this._id === 'string',
            type: !this._type ? true : typeof this._type === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
