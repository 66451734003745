// This file was autogenerated. Please do not change.
export interface IStreamChannelsRequest {
    accountIds?: string[];
    client: string;
}

export default class StreamChannelsRequest {
    readonly _accountIds: string[] | undefined;

    /**
     * Example: AQR5EESSMDA,AR2K7DKY9UV
     */
    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _client: string;

    /**
     * Example: centrifugo_generated_client_id
     */
    get client(): string {
        return this._client;
    }

    constructor(props: IStreamChannelsRequest) {
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        this._client = props.client.trim();
    }

    serialize(): IStreamChannelsRequest {
        const data: IStreamChannelsRequest = {
            client: this._client,
        };
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        return data;
    }

    toJSON(): IStreamChannelsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            client: typeof this._client === 'string' && !!this._client.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
