import ApiError from 'Entities/ApiError';
import CreateKeyPayload from 'Entities/apiManagement/CreateKeyPayload';
import CreateKeyResponse from 'Entities/apiManagement/CreateKeyResponse';
import DeleteKeyPayload from 'Entities/apiManagement/DeleteKeyPayload';
import KeyResponse from 'Entities/apiManagement/KeyResponse';
import ServiceStatus from 'Entities/apiManagement/ServiceStatus';
import UpdateKeyPayload from 'Entities/apiManagement/UpdateKeyPayload';
import UpdateQuotaPayload from 'Entities/apiManagement/UpdateQuotaPayload';
import UpdateQuotaResponse from 'Entities/apiManagement/UpdateQuotaResponse';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class ApiManagementApi {
    static async createApiKey(createkeypayload: CreateKeyPayload, withHeaders?: boolean): Promise<{ data: CreateKeyResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...createkeypayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('createApiKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/apiManagement/createKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(createkeypayload.serialize()),
        });
        if (res.status === 201) {
            return { data: new CreateKeyResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('ApiManagement/createApiKey', { response: res, data: result, fields: [JSON.stringify({ createkeypayload })] });
    }

    static async deleteApiKey(deletekeypayload: DeleteKeyPayload, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...deletekeypayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('deleteApiKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/apiManagement/deleteKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(deletekeypayload.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('ApiManagement/deleteApiKey', { response: res, data: result, fields: [JSON.stringify({ deletekeypayload })] });
    }

    static async getApiKeys(withHeaders?: boolean): Promise<{ data: KeyResponse[]; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/apiManagement/getApiKeys`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new KeyResponse(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('ApiManagement/getApiKeys', { response: res, data: result, fields: [] });
    }

    static async getCurrentKey(withHeaders?: boolean): Promise<{ data: KeyResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/apiManagement/getCurrentKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new KeyResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('ApiManagement/getCurrentKey', { response: res, data: result, fields: [] });
    }

    static async updateApiKey(updatekeypayload: UpdateKeyPayload, withHeaders?: boolean): Promise<{ data: KeyResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updatekeypayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('updateApiKey', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/apiManagement/updateKey`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updatekeypayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new KeyResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('ApiManagement/updateApiKey', { response: res, data: result, fields: [JSON.stringify({ updatekeypayload })] });
    }

    static async updateQuota(updatequotapayload: UpdateQuotaPayload, withHeaders?: boolean): Promise<{ data: UpdateQuotaResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updatequotapayload.validate());
        if (haveError.length > 0) {
            throw new ApiError('updateQuota', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/apiManagement/updateQuota`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updatequotapayload.serialize()),
        });
        if (res.status === 200) {
            return { data: new UpdateQuotaResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('ApiManagement/updateQuota', { response: res, data: result, fields: [JSON.stringify({ updatequotapayload })] });
    }
}
