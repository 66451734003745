// This file was autogenerated. Please do not change.
export interface IOrderCostResponse {
    cost?: string;
}

export default class OrderCostResponse {
    readonly _cost: number | undefined;

    /**
     * Example: 3000
     */
    get cost(): number | undefined {
        return this._cost;
    }

    constructor(props: IOrderCostResponse) {
        if (typeof props.cost === 'string') {
            this._cost = Number(props.cost);
        }
    }

    serialize(): IOrderCostResponse {
        const data: IOrderCostResponse = {
        };
        if (typeof this._cost !== 'undefined') {
            data.cost = String(this._cost);
        }
        return data;
    }

    toJSON(): IOrderCostResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            cost: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
