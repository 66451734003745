import { Action } from 'vuex';
import Centrifuge from 'centrifuge';
import { nanoid } from 'nanoid';

import endpoints from 'Const/endpoints';
import StreamChannelsResponse from 'Entities/privatePresenter/StreamChannelsResponse';
import PrivateChannel from 'Entities/privatePresenter/PrivateChannel';
import PrivateDataApi from 'Apis/PrivateData';
import { actionCreator, mutationCreator } from 'Store/utils';
import router from '@/router';

export interface ISubscription {
    channel: string;
    uid: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export const channelsCallbacks = {} as Record<string, Record<string, {
    subscribe: any;
    publish: any;
}>> | Centrifuge.Subscription;
const callbacksTest = (channel: string) => ({
    subscribe: (...args) => {
        if (channelsCallbacks[channel]) {
            Object.entries(channelsCallbacks[channel]).forEach(([key, callback]) => {
                try {
                    if (key !== 'subscription') {
                        (callback as { subscribe: any; publish: any; }).subscribe(...args);
                    }
                } catch {
                    // code crushed because of network error
                }
            });
        }
    },
    publish: (...args) => {
        if (channelsCallbacks[channel]) {
            Object.entries(channelsCallbacks[channel]).forEach(([key, callback]) => {
                try {
                    if (key !== 'subscription') {
                        (callback as { subscribe: any; publish: any; }).publish(...args);
                    }
                } catch {
                    // code crushed because of network error
                }
            });
        }
    },
});

const state = {
    socketClient: undefined as string | undefined,
    streamChannels: undefined as Map<string, PrivateChannel> | undefined,
    centrifuge: undefined as Centrifuge | undefined,
    subscriptions: [] as Centrifuge.Subscription[],
    resubscriptions: {
        resubscriptionsCounter: 0,
        resubscriptionTimeoutId: null as null | NodeJS.Timeout,
        MAXIMUM_SUBSCRIPTIONS_WITHOUT_TIMEOUT: 2,
        MAXIMUM_RESUBSCRIPTIONS: 5,
        TIMEOUT_VALUE: 5000,
    },
};

export type PublicSocketDataState = typeof state;

export enum PublicSocketDataGetters {}

const getters = {};

export enum PublicSocketDataMutations {
    SET_STREAM_CHANNELS = 'SET_STREAM_CHANNELS',
    SET_SOCKET_CLIENT = 'SET_SOCKET_CLIENT',
    SET_CENTRIFUGE = 'SET_CENTRIFUGE',
}

export const SET_STREAM_CHANNELS = mutationCreator<StreamChannelsResponse>('PublicSocketData', PublicSocketDataMutations.SET_STREAM_CHANNELS);
export const SET_SOCKET_CLIENT = mutationCreator<string>('PublicSocketData', PublicSocketDataMutations.SET_SOCKET_CLIENT);
export const SET_CENTRIFUGE = mutationCreator<Centrifuge>('PublicSocketData', PublicSocketDataMutations.SET_CENTRIFUGE);

const mutations: Record<PublicSocketDataMutations, (state: PublicSocketDataState, ...args: any) => void> = {
    SET_STREAM_CHANNELS(state, streamChannels: ReturnType<typeof SET_STREAM_CHANNELS>) {
        const { channels } = streamChannels.payload;
        const map = new Map();
        channels.forEach((c) => map.set(c.channel, c));
        state.streamChannels = map;
    },
    SET_SOCKET_CLIENT(state, client: ReturnType<typeof SET_SOCKET_CLIENT>) {
        state.socketClient = client.payload;
    },
    SET_CENTRIFUGE(state, centrifuge: ReturnType<typeof SET_CENTRIFUGE>) {
        state.centrifuge = centrifuge.payload;
    },
};

export enum PublicSocketDataActions {
    init = 'init',
    subscribe = 'subscribe',
    unsubscribeChannel = 'unsubscribeChannel',
    setSubscription = 'setSubscription',
    resubscribeWithTimeout = 'resubscribeWithTimeout',
}

export const init = actionCreator('PublicSocketData', PublicSocketDataActions.init);
export const subscribe = actionCreator<{ key?: string; channel: string; callback?:(data: any) => void, callbacks?: { subscribe: (data: any) => void, publish: (data: any) => void } }>('PublicSocketData', PublicSocketDataActions.subscribe);
export const unsubscribeChannel = actionCreator<ISubscription>('PublicSocketData', PublicSocketDataActions.unsubscribeChannel);
export const setSubscription = actionCreator<Centrifuge.Subscription>('PublicSocketData', PublicSocketDataActions.setSubscription);
export const resubscribeWithTimeout = actionCreator<undefined>('PublicSocketData', PublicSocketDataActions.resubscribeWithTimeout);

const actions: Record<PublicSocketDataActions, Action<PublicSocketDataState, any>> = {
    async init({ commit, dispatch, state }) {
        const centrifuge = new Centrifuge(endpoints.privateDataSource, {
            onRefresh: async (ctx, cb) => {
                try {
                    const { data: token } = await PrivateDataApi.privateRefreshStreamToken();
                    cb({ status: 200, data: { token: token.token } });
                } catch (error) {
                    cb({ status: 500, data: { token: 'failed' } });
                }
            },
            onPrivateSubscribe: async (ctx, cb) => {
                const channels = state.streamChannels;
                if (!channels) {
                    return null;
                }
                const subscribeChannels = ctx.data.channels;
                const data = subscribeChannels.map((sc) => {
                    const privateData = channels.get(sc)!;
                    return privateData.serialize();
                });
                cb({ status: 200, data: { channels: data } });
            },
        });
        const { data: token } = await PrivateDataApi.privateGetStreamToken();
        centrifuge.setToken(token.token);
        centrifuge.connect();
        centrifuge.on('error', async () => {
            if (state.centrifuge) {
                await dispatch(resubscribeWithTimeout(undefined, true));
            }
        });
        commit(SET_CENTRIFUGE(centrifuge, true));
    },

    setSubscription({ state }, { payload: subscription }: ReturnType<typeof setSubscription>) {
        return state.subscriptions.push(subscription);
    },

    async subscribe({ state, dispatch }, { payload }: ReturnType<typeof subscribe>): Promise<ISubscription | void> {
        const { centrifuge } = state;
        const { channel, callbacks } = payload;
        if (!centrifuge || !callbacks) {
            return;
        }

        const uid = nanoid();
        if (channelsCallbacks[channel]) {
            channelsCallbacks[channel][uid] = callbacks;
            if (channelsCallbacks[channel].subscription) {
                channelsCallbacks[channel].subscription.unsubscribe();
            }
        } else {
            channelsCallbacks[channel] = { [uid]: callbacks };
        }
        channelsCallbacks[channel].subscription = await centrifuge.subscribe(channel, callbacksTest(channel)).on('error', async () => {
            await dispatch(resubscribeWithTimeout(undefined, true));
        });

        return { channel, uid };
    },

    unsubscribeChannel(_, { payload: { uid, channel } }: ReturnType<typeof unsubscribeChannel>) {
        if (channelsCallbacks[channel]) {
            delete channelsCallbacks[channel][uid];
            if (Object.keys(channelsCallbacks[channel]).length === 1) {
                channelsCallbacks[channel].subscription.unsubscribe();
                delete channelsCallbacks[channel];
            }
        }
    },

    async resubscribeWithTimeout({ state, dispatch }) {
        console.log('public socket resubscribe because of error');
        if (state.resubscriptions.resubscriptionsCounter > state.resubscriptions.MAXIMUM_RESUBSCRIPTIONS) {
            console.log('public socket logout');
            try {
                await dispatch('Auth/logout', undefined, { root: true });
                await router.push('/signin');
            } catch {
                document.location.reload();
            }
            return;
        }
        if (state.resubscriptions.resubscriptionsCounter < state.resubscriptions.MAXIMUM_SUBSCRIPTIONS_WITHOUT_TIMEOUT) {
            state.resubscriptions.resubscriptionsCounter += 1;
            await dispatch(init(undefined, true));
        } else if (state.resubscriptions.resubscriptionTimeoutId === null) {
            state.resubscriptions.resubscriptionTimeoutId = setTimeout(async () => {
                state.resubscriptions.resubscriptionsCounter += 1;
                state.resubscriptions.resubscriptionTimeoutId = null;
                await dispatch(init(undefined, true));
            }, (state.resubscriptions.resubscriptionsCounter - state.resubscriptions.MAXIMUM_SUBSCRIPTIONS_WITHOUT_TIMEOUT) * state.resubscriptions.TIMEOUT_VALUE);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
