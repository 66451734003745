var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"info-tooltip",class:_vm.s.container,style:({
        height: `${_vm.size}px`,
        width: `${_vm.size}px`,
        fontSize: `${_vm.size - 4}px`,
    }),attrs:{"id":_vm.uniqueId},on:{"click":function($event){return _vm.setShowTooltip(!_vm.showTooltip)}}},[_vm._v(" i "),(_vm.showTooltip)?_c('div',{class:_vm.s.tooltip,style:({
            fontSize: `${_vm.size}px`,
        }),domProps:{"innerHTML":_vm._s(_vm.text)},on:{"click":function($event){$event.stopPropagation();}}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }