// This file was autogenerated. Please do not change.
export interface IApproveResponse {
    id?: string;
    status?: ('PENDING' | 'PROCESSING' | 'CONFIRMED' | 'FAILED' | 'APPROVED' | 'REGISTERED');
}

export default class ApproveResponse {
    readonly _id: string | undefined;

    /**
     * Example: DT3E8559P6CNYA33YEN4EW
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _status: ('PENDING' | 'PROCESSING' | 'CONFIRMED' | 'FAILED' | 'APPROVED' | 'REGISTERED') | undefined;

    /**
     * Example: PENDING
     */
    get status(): ('PENDING' | 'PROCESSING' | 'CONFIRMED' | 'FAILED' | 'APPROVED' | 'REGISTERED') | undefined {
        return this._status;
    }

    constructor(props: IApproveResponse) {
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (props.status) {
            this._status = props.status;
        }
    }

    serialize(): IApproveResponse {
        const data: IApproveResponse = {
        };
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IApproveResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
