// This file was autogenerated. Please do not change.
export interface IFuturesFundingRecordsRequest {
    accountId?: string;
    accountIds?: string[];
    assetSymbol?: string;
    fromTimestamp?: number;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementName?: string;
    quotationAsset?: string;
    spotAssetPairSymbol?: string;
    toTimestamp?: number;
}

export default class FuturesFundingRecordsRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _accountIds: string[] | undefined;

    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _fromTimestamp: number | undefined;

    /**
     * Example: 1660302058
     */
    get fromTimestamp(): number | undefined {
        return this._fromTimestamp;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance Futures
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _quotationAsset: string | undefined;

    /**
     * Example: XLM/BTC
     */
    get quotationAsset(): string | undefined {
        return this._quotationAsset;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: XLM/BTC
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    readonly _toTimestamp: number | undefined;

    /**
     * Example: 1660302058
     */
    get toTimestamp(): number | undefined {
        return this._toTimestamp;
    }

    constructor(props: IFuturesFundingRecordsRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.fromTimestamp === 'number') {
            this._fromTimestamp = props.fromTimestamp;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.quotationAsset === 'string') {
            this._quotationAsset = props.quotationAsset.trim();
        }
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
        if (typeof props.toTimestamp === 'number') {
            this._toTimestamp = props.toTimestamp;
        }
    }

    serialize(): IFuturesFundingRecordsRequest {
        const data: IFuturesFundingRecordsRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._fromTimestamp !== 'undefined') {
            data.fromTimestamp = this._fromTimestamp;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._quotationAsset !== 'undefined') {
            data.quotationAsset = this._quotationAsset;
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        if (typeof this._toTimestamp !== 'undefined') {
            data.toTimestamp = this._toTimestamp;
        }
        return data;
    }

    toJSON(): IFuturesFundingRecordsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            fromTimestamp: !this._fromTimestamp ? true : typeof this._fromTimestamp === 'number',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            quotationAsset: !this._quotationAsset ? true : typeof this._quotationAsset === 'string',
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
            toTimestamp: !this._toTimestamp ? true : typeof this._toTimestamp === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
