import AccountInfo, { IAccountInfo } from './AccountInfo';

// This file was autogenerated. Please do not change.
export interface IBasicAccountRequestInfoSwaggerSchema {
    account: IAccountInfo;
    id: string;
    issuer?: string;
    policies?: string[];
    recipient?: string;
    status: string;
}

export default class BasicAccountRequestInfoSwaggerSchema {
    readonly _account: AccountInfo;

    get account(): AccountInfo {
        return this._account;
    }

    readonly _id: string;

    get id(): string {
        return this._id;
    }

    readonly _issuer: string | undefined;

    get issuer(): string | undefined {
        return this._issuer;
    }

    readonly _policies: string[] | undefined;

    get policies(): string[] | undefined {
        return this._policies;
    }

    readonly _recipient: string | undefined;

    get recipient(): string | undefined {
        return this._recipient;
    }

    readonly _status: string;

    get status(): string {
        return this._status;
    }

    constructor(props: IBasicAccountRequestInfoSwaggerSchema) {
        this._account = new AccountInfo(props.account);
        this._id = props.id.trim();
        if (typeof props.issuer === 'string') {
            this._issuer = props.issuer.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
        if (typeof props.recipient === 'string') {
            this._recipient = props.recipient.trim();
        }
        this._status = props.status.trim();
    }

    serialize(): IBasicAccountRequestInfoSwaggerSchema {
        const data: IBasicAccountRequestInfoSwaggerSchema = {
            account: this._account.serialize(),
            id: this._id,
            status: this._status,
        };
        if (typeof this._issuer !== 'undefined') {
            data.issuer = this._issuer;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        if (typeof this._recipient !== 'undefined') {
            data.recipient = this._recipient;
        }
        return data;
    }

    toJSON(): IBasicAccountRequestInfoSwaggerSchema {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
            account: this._account.validate().length === 0,
            issuer: !this._issuer ? true : typeof this._issuer === 'string',
            recipient: !this._recipient ? true : typeof this._recipient === 'string',
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            status: typeof this._status === 'string' && !!this._status.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
