// This file was autogenerated. Please do not change.
export interface ILogsPayload {
    from?: number;
    limit: number;
    nextToken?: string;
    sessionId?: string;
    sortOrder?: string;
    to?: number;
}

export default class LogsPayload {
    readonly _from: number | undefined;

    /** Description: UTC timestamp of starting point */
    get from(): number | undefined {
        return this._from;
    }

    readonly _limit: number;

    /** Description: Maximum amount of events per request */
    get limit(): number {
        return this._limit;
    }

    readonly _nextToken: string | undefined;

    /** Description: Token to get next events */
    get nextToken(): string | undefined {
        return this._nextToken;
    }

    readonly _sessionId: string | undefined;

    /** Description: An Id of current user's session */
    get sessionId(): string | undefined {
        return this._sessionId;
    }

    readonly _sortOrder: string | undefined;

    /** Description: Sorting order of events */
    get sortOrder(): string | undefined {
        return this._sortOrder;
    }

    readonly _to: number | undefined;

    /** Description: UTC timestamp of end point */
    get to(): number | undefined {
        return this._to;
    }

    constructor(props: ILogsPayload) {
        if (typeof props.from === 'number') {
            this._from = props.from;
        }
        this._limit = props.limit;
        if (typeof props.nextToken === 'string') {
            this._nextToken = props.nextToken.trim();
        }
        if (typeof props.sessionId === 'string') {
            this._sessionId = props.sessionId.trim();
        }
        if (typeof props.sortOrder === 'string') {
            this._sortOrder = props.sortOrder.trim();
        }
        if (typeof props.to === 'number') {
            this._to = props.to;
        }
    }

    serialize(): ILogsPayload {
        const data: ILogsPayload = {
            limit: this._limit,
        };
        if (typeof this._from !== 'undefined') {
            data.from = this._from;
        }
        if (typeof this._nextToken !== 'undefined') {
            data.nextToken = this._nextToken;
        }
        if (typeof this._sessionId !== 'undefined') {
            data.sessionId = this._sessionId;
        }
        if (typeof this._sortOrder !== 'undefined') {
            data.sortOrder = this._sortOrder;
        }
        if (typeof this._to !== 'undefined') {
            data.to = this._to;
        }
        return data;
    }

    toJSON(): ILogsPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            from: !this._from ? true : typeof this._from === 'number',
            to: !this._to ? true : typeof this._to === 'number',
            nextToken: !this._nextToken ? true : typeof this._nextToken === 'string',
            limit: typeof this._limit === 'number',
            sortOrder: !this._sortOrder ? true : typeof this._sortOrder === 'string',
            sessionId: !this._sessionId ? true : typeof this._sessionId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
