import SpotOrderStatus, { ISpotOrderStatus } from './SpotOrderStatus';
import SpotOrdersTotal, { ISpotOrdersTotal } from './SpotOrdersTotal';

// This file was autogenerated. Please do not change.
export interface ISpotOrdersSummary {
    spotCount?: number;
    spotStatuses?: ISpotOrderStatus[];
    spotTotal?: ISpotOrdersTotal;
}

export default class SpotOrdersSummary {
    readonly _spotCount: number | undefined;

    get spotCount(): number | undefined {
        return this._spotCount;
    }

    readonly _spotStatuses: SpotOrderStatus[] | undefined;

    get spotStatuses(): SpotOrderStatus[] | undefined {
        return this._spotStatuses;
    }

    readonly _spotTotal: SpotOrdersTotal | undefined;

    get spotTotal(): SpotOrdersTotal | undefined {
        return this._spotTotal;
    }

    constructor(props: ISpotOrdersSummary) {
        if (typeof props.spotCount === 'number') {
            this._spotCount = props.spotCount;
        }
        if (props.spotStatuses) {
            this._spotStatuses = props.spotStatuses.map((p) => new SpotOrderStatus(p));
        }
        if (props.spotTotal) {
            this._spotTotal = new SpotOrdersTotal(props.spotTotal);
        }
    }

    serialize(): ISpotOrdersSummary {
        const data: ISpotOrdersSummary = {
        };
        if (typeof this._spotCount !== 'undefined') {
            data.spotCount = this._spotCount;
        }
        if (typeof this._spotStatuses !== 'undefined') {
            data.spotStatuses = this._spotStatuses.map((p) => p.serialize());
        }
        if (typeof this._spotTotal !== 'undefined') {
            data.spotTotal = this._spotTotal.serialize();
        }
        return data;
    }

    toJSON(): ISpotOrdersSummary {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            spotCount: !this._spotCount ? true : typeof this._spotCount === 'number',
            spotStatuses: !this._spotStatuses ? true : this._spotStatuses.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            spotTotal: !this._spotTotal ? true : this._spotTotal.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
