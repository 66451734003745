// This file was autogenerated. Please do not change.
export interface ICreateTradeAPIKeyRequest {
    accountId: string;
    ips: string;
    label: string;
    placementTag: string;
    totp?: string;
}

export default class CreateTradeAPIKeyRequest {
    readonly _accountId: string;

    /**
     * Example: ABCDEFGH
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _ips: string;

    /**
     * Example: 127.0.0.1,192.168.0.1
     */
    get ips(): string {
        return this._ips;
    }

    readonly _label: string;

    /**
     * Example: trading
     */
    get label(): string {
        return this._label;
    }

    readonly _placementTag: string;

    /**
     * Example: BINANCE
     */
    get placementTag(): string {
        return this._placementTag;
    }

    readonly _totp: string | undefined;

    /**
     * Example: 123456
     */
    get totp(): string | undefined {
        return this._totp;
    }

    constructor(props: ICreateTradeAPIKeyRequest) {
        this._accountId = props.accountId.trim();
        this._ips = props.ips.trim();
        this._label = props.label.trim();
        this._placementTag = props.placementTag.trim();
        if (typeof props.totp === 'string') {
            this._totp = props.totp.trim();
        }
    }

    serialize(): ICreateTradeAPIKeyRequest {
        const data: ICreateTradeAPIKeyRequest = {
            accountId: this._accountId,
            ips: this._ips,
            label: this._label,
            placementTag: this._placementTag,
        };
        if (typeof this._totp !== 'undefined') {
            data.totp = this._totp;
        }
        return data;
    }

    toJSON(): ICreateTradeAPIKeyRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            ips: typeof this._ips === 'string' && !!this._ips.trim(),
            label: typeof this._label === 'string' && !!this._label.trim(),
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
            totp: !this._totp ? true : typeof this._totp === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
