// This file was autogenerated. Please do not change.
export interface IPrivateChannel {
    channel: string;
    token: string;
}

export default class PrivateChannel {
    readonly _channel: string;

    get channel(): string {
        return this._channel;
    }

    readonly _token: string;

    get token(): string {
        return this._token;
    }

    constructor(props: IPrivateChannel) {
        this._channel = props.channel.trim();
        this._token = props.token.trim();
    }

    serialize(): IPrivateChannel {
        const data: IPrivateChannel = {
            channel: this._channel,
            token: this._token,
        };
        return data;
    }

    toJSON(): IPrivateChannel {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            channel: typeof this._channel === 'string' && !!this._channel.trim(),
            token: typeof this._token === 'string' && !!this._token.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
