// This file was autogenerated. Please do not change.
export interface IAccountInfo {
    color?: string;
    description?: string;
    id?: string;
    name?: string;
    policies?: string[];
    status?: string;
}

export default class AccountInfo {
    readonly _color: string | undefined;

    get color(): string | undefined {
        return this._color;
    }

    readonly _description: string | undefined;

    get description(): string | undefined {
        return this._description;
    }

    readonly _id: string | undefined;

    get id(): string | undefined {
        return this._id;
    }

    readonly _name: string | undefined;

    get name(): string | undefined {
        return this._name;
    }

    readonly _policies: string[] | undefined;

    get policies(): string[] | undefined {
        return this._policies;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    constructor(props: IAccountInfo) {
        if (typeof props.color === 'string') {
            this._color = props.color.trim();
        }
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): IAccountInfo {
        const data: IAccountInfo = {
        };
        if (typeof this._color !== 'undefined') {
            data.color = this._color;
        }
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IAccountInfo {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            name: !this._name ? true : typeof this._name === 'string',
            description: !this._description ? true : typeof this._description === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            color: !this._color ? true : typeof this._color === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
