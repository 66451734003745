// This file was autogenerated. Please do not change.
export interface IDexTransaction {
    aAssetSymbol?: string;
    aQuantity?: string;
    aQuantityQuoted?: string;
    bAssetSymbol?: string;
    bQuantity?: string;
    bQuantityQuoted?: string;
    blockchainCommissionAssetSymbol?: string;
    blockchainCommissionQuantity?: string;
    blockchainCommissionQuantityQuoted?: string;
    blockchainName?: string;
    createdAt?: string;
    exchangeCommissionQuantity?: string;
    exchangeCommissionQuantityQuoted?: string;
    id?: string;
    placementName?: string;
    status?: ('PENDING' | 'PROCESSING' | 'CONFIRMED' | 'FAILED' | 'APPROVED' | 'REGISTERED');
    transactionHash?: string;
    type?: ('APPROVE' | 'SWAP');
}

export default class DexTransaction {
    readonly _aAssetSymbol: string | undefined;

    /**
     * Example: BNB
     */
    get aAssetSymbol(): string | undefined {
        return this._aAssetSymbol;
    }

    readonly _aQuantity: number | undefined;

    /**
     * Example: 0.028727808
     */
    get aQuantity(): number | undefined {
        return this._aQuantity;
    }

    readonly _aQuantityQuoted: number | undefined;

    /**
     * Example: 11.48346755
     */
    get aQuantityQuoted(): number | undefined {
        return this._aQuantityQuoted;
    }

    readonly _bAssetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get bAssetSymbol(): string | undefined {
        return this._bAssetSymbol;
    }

    readonly _bQuantity: string | undefined;

    /**
     * Example: 11.75984641
     */
    get bQuantity(): string | undefined {
        return this._bQuantity;
    }

    readonly _bQuantityQuoted: string | undefined;

    /**
     * Example: 11.76196319
     */
    get bQuantityQuoted(): string | undefined {
        return this._bQuantityQuoted;
    }

    readonly _blockchainCommissionAssetSymbol: string | undefined;

    /**
     * Example: BNB
     */
    get blockchainCommissionAssetSymbol(): string | undefined {
        return this._blockchainCommissionAssetSymbol;
    }

    readonly _blockchainCommissionQuantity: number | undefined;

    /**
     * Example: 0.2572429
     */
    get blockchainCommissionQuantity(): number | undefined {
        return this._blockchainCommissionQuantity;
    }

    readonly _blockchainCommissionQuantityQuoted: number | undefined;

    /**
     * Example: 0.2572429
     */
    get blockchainCommissionQuantityQuoted(): number | undefined {
        return this._blockchainCommissionQuantityQuoted;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: BNB
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _createdAt: string | undefined;

    /**
     * Example: 2022-04-22T11:38:10.823906Z
     */
    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _exchangeCommissionQuantity: string | undefined;

    /**
     * Example: 0.0294786
     */
    get exchangeCommissionQuantity(): string | undefined {
        return this._exchangeCommissionQuantity;
    }

    readonly _exchangeCommissionQuantityQuoted: string | undefined;

    /**
     * Example: 0.0294786
     */
    get exchangeCommissionQuantityQuoted(): string | undefined {
        return this._exchangeCommissionQuantityQuoted;
    }

    readonly _id: string | undefined;

    /**
     * Example: DTW2656VDL6RRVMLDWMVFC
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _status: ('PENDING' | 'PROCESSING' | 'CONFIRMED' | 'FAILED' | 'APPROVED' | 'REGISTERED') | undefined;

    /**
     * Example: CONFIRMED
     */
    get status(): ('PENDING' | 'PROCESSING' | 'CONFIRMED' | 'FAILED' | 'APPROVED' | 'REGISTERED') | undefined {
        return this._status;
    }

    readonly _transactionHash: string | undefined;

    /**
     * Example: 2.2228256566850315e+76
     */
    get transactionHash(): string | undefined {
        return this._transactionHash;
    }

    readonly _type: ('APPROVE' | 'SWAP') | undefined;

    /**
     * Example: SWAP
     */
    get type(): ('APPROVE' | 'SWAP') | undefined {
        return this._type;
    }

    constructor(props: IDexTransaction) {
        if (typeof props.aAssetSymbol === 'string') {
            this._aAssetSymbol = props.aAssetSymbol.trim();
        }
        if (typeof props.aQuantity === 'string') {
            this._aQuantity = Number(props.aQuantity);
        }
        if (typeof props.aQuantityQuoted === 'string') {
            this._aQuantityQuoted = Number(props.aQuantityQuoted);
        }
        if (typeof props.bAssetSymbol === 'string') {
            this._bAssetSymbol = props.bAssetSymbol.trim();
        }
        if (typeof props.bQuantity === 'string') {
            this._bQuantity = props.bQuantity.trim();
        }
        if (typeof props.bQuantityQuoted === 'string') {
            this._bQuantityQuoted = props.bQuantityQuoted.trim();
        }
        if (typeof props.blockchainCommissionAssetSymbol === 'string') {
            this._blockchainCommissionAssetSymbol = props.blockchainCommissionAssetSymbol.trim();
        }
        if (typeof props.blockchainCommissionQuantity === 'string') {
            this._blockchainCommissionQuantity = Number(props.blockchainCommissionQuantity);
        }
        if (typeof props.blockchainCommissionQuantityQuoted === 'string') {
            this._blockchainCommissionQuantityQuoted = Number(props.blockchainCommissionQuantityQuoted);
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.exchangeCommissionQuantity === 'string') {
            this._exchangeCommissionQuantity = props.exchangeCommissionQuantity.trim();
        }
        if (typeof props.exchangeCommissionQuantityQuoted === 'string') {
            this._exchangeCommissionQuantityQuoted = props.exchangeCommissionQuantityQuoted.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (props.status) {
            this._status = props.status;
        }
        if (typeof props.transactionHash === 'string') {
            this._transactionHash = props.transactionHash.trim();
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): IDexTransaction {
        const data: IDexTransaction = {
        };
        if (typeof this._aAssetSymbol !== 'undefined') {
            data.aAssetSymbol = this._aAssetSymbol;
        }
        if (typeof this._aQuantity !== 'undefined') {
            data.aQuantity = String(this._aQuantity);
        }
        if (typeof this._aQuantityQuoted !== 'undefined') {
            data.aQuantityQuoted = String(this._aQuantityQuoted);
        }
        if (typeof this._bAssetSymbol !== 'undefined') {
            data.bAssetSymbol = this._bAssetSymbol;
        }
        if (typeof this._bQuantity !== 'undefined') {
            data.bQuantity = this._bQuantity;
        }
        if (typeof this._bQuantityQuoted !== 'undefined') {
            data.bQuantityQuoted = this._bQuantityQuoted;
        }
        if (typeof this._blockchainCommissionAssetSymbol !== 'undefined') {
            data.blockchainCommissionAssetSymbol = this._blockchainCommissionAssetSymbol;
        }
        if (typeof this._blockchainCommissionQuantity !== 'undefined') {
            data.blockchainCommissionQuantity = String(this._blockchainCommissionQuantity);
        }
        if (typeof this._blockchainCommissionQuantityQuoted !== 'undefined') {
            data.blockchainCommissionQuantityQuoted = String(this._blockchainCommissionQuantityQuoted);
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._exchangeCommissionQuantity !== 'undefined') {
            data.exchangeCommissionQuantity = this._exchangeCommissionQuantity;
        }
        if (typeof this._exchangeCommissionQuantityQuoted !== 'undefined') {
            data.exchangeCommissionQuantityQuoted = this._exchangeCommissionQuantityQuoted;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._transactionHash !== 'undefined') {
            data.transactionHash = this._transactionHash;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IDexTransaction {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetSymbol: !this._aAssetSymbol ? true : typeof this._aAssetSymbol === 'string',
            aQuantity: true,
            aQuantityQuoted: true,
            bAssetSymbol: !this._bAssetSymbol ? true : typeof this._bAssetSymbol === 'string',
            bQuantity: !this._bQuantity ? true : typeof this._bQuantity === 'string',
            bQuantityQuoted: !this._bQuantityQuoted ? true : typeof this._bQuantityQuoted === 'string',
            blockchainCommissionAssetSymbol: !this._blockchainCommissionAssetSymbol ? true : typeof this._blockchainCommissionAssetSymbol === 'string',
            blockchainCommissionQuantity: true,
            blockchainCommissionQuantityQuoted: true,
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            exchangeCommissionQuantity: !this._exchangeCommissionQuantity ? true : typeof this._exchangeCommissionQuantity === 'string',
            exchangeCommissionQuantityQuoted: !this._exchangeCommissionQuantityQuoted ? true : typeof this._exchangeCommissionQuantityQuoted === 'string',
            id: !this._id ? true : typeof this._id === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            transactionHash: !this._transactionHash ? true : typeof this._transactionHash === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
