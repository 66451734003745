import TradeAttributes, { ITradeAttributes } from './TradeAttributes';

// This file was autogenerated. Please do not change.
export interface IOrderAttributes {
    buy?: ITradeAttributes;
    sell?: ITradeAttributes;
}

export default class OrderAttributes {
    readonly _buy: TradeAttributes | undefined;

    get buy(): TradeAttributes | undefined {
        return this._buy;
    }

    readonly _sell: TradeAttributes | undefined;

    get sell(): TradeAttributes | undefined {
        return this._sell;
    }

    constructor(props: IOrderAttributes) {
        if (props.buy) {
            this._buy = new TradeAttributes(props.buy);
        }
        if (props.sell) {
            this._sell = new TradeAttributes(props.sell);
        }
    }

    serialize(): IOrderAttributes {
        const data: IOrderAttributes = {
        };
        if (typeof this._buy !== 'undefined') {
            data.buy = this._buy.serialize();
        }
        if (typeof this._sell !== 'undefined') {
            data.sell = this._sell.serialize();
        }
        return data;
    }

    toJSON(): IOrderAttributes {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            buy: !this._buy ? true : this._buy.validate().length === 0,
            sell: !this._sell ? true : this._sell.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
