import Vue from 'vue';
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default Vue.extend<any, any, any, any>({
    extends: Line,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        beginAtZero: {
            type: Boolean,
            default: false,
        },
        suggestedMax: {
            type: Number,
            default: null,
        },
        suggestedMin: {
            type: Number,
            default: null,
        },
    },
    computed: {
        data() {
            let { datasets } = this.chartData;

            datasets = datasets.map((dataset) => (dataset.datalabels
                ? dataset
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels: this.chartData.labels,
                datasets,
            };
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            display: false,
                            ticks: {
                                display: false,
                                beginAtZero: this.beginAtZero,
                                suggestedMax: this.suggestedMax,
                                suggestedMin: this.suggestedMin,
                            },
                            gridLines: {
                                display: false,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            display: false,
                            showTicks: false,
                            gridLines: false,
                            ticks: {
                                display: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                },
            };
        },
    },
    methods: {
        Render() {
            (this as any).renderChart(this.data, this.options);
        },
    },
    mounted() {
        this.Render();
    },
});
