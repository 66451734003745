import { actionCreator, mutationCreator } from 'Store/utils';
import PublicDataApi from 'Apis/PublicData';
import DexPoolsRequest from 'Entities/publicPresenter/DexPoolsRequest';
import DexPoolInfo from 'Entities/publicPresenter/DexPoolInfo';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import ApiError from 'Entities/ApiError';
import DeFiApi from 'Apis/DeFi';
import GetPoolInfoRequest from 'Entities/dex/GetPoolInfoRequest';
import PoolInfo from 'Entities/dex/PoolInfo';
import GetAllowanceRequest from 'Entities/dex/GetAllowanceRequest';
import GetPositionRequest from 'Entities/dex/GetPositionRequest';
import LiquidityPosition from 'Entities/dex/LiquidityPosition';
import LiquidityApproveRequest from 'Entities/dex/LiquidityApproveRequest';
import DexPositionsRequest from 'Entities/privatePresenter/DexPositionsRequest';
import DexPosition, { IDexPosition } from 'Entities/privatePresenter/DexPosition';
import ApproveRequest from 'Entities/dex/ApproveRequest';
import { CHECK_APPROVAL_STATUSES } from 'Store/v2/Defi';

interface IGetPoolsListPayload {
    placementName: string;
    blockchainName: string;
}

interface ISetSortingPayload {
    column: string;
    type: string;
}

interface IUpdatePoolsListPayload extends IGetPoolsListPayload {
    page: number;
}

interface ISearchPoolsPayload extends IGetPoolsListPayload {
    inputValue: string;
}

interface ISetAssetsAllowancePayload {
    aAllowance: {
        aAssetApproved: string;
        aAssetMaximumApproved: number;
    },
    bAllowance: {
        bAssetApproved: string;
        bAssetMaximumApproved: number;
    }
}

export enum MANAGE_LIQUIDITY_TYPES {
    add = 'add',
    remove = 'remove',
}

export enum SORTING_TYPES {
    asc = 'asc',
    desc = 'desc',
    none = 'none',
}

export enum SORTING_COLUMNS {
    volume24h = 'volume24h',
    lpFee24h = 'lpFee24h',
    apr7d = 'apr7d',
    roiUSD7d = 'roiUSD7d',
    roiUSD30d = 'roiUSD30d',
    roiUSD365d = 'roiUSD365d',
    txCount24h = 'txCount24h',
    txCount7d = 'txCount7d',
    reserve = 'reserve',
}

const state = {
    poolsList: [] as DexPoolInfo[],
    searchedPoolsList: [] as DexPoolInfo[],
    poolsPositions: [] as DexPosition[],
    hasOpenedPosition: false,
    ui: {
        totalPoolsListPages: undefined as undefined | number,
        poolsPerPage: 9,
        poolsTableSorting: {
            column: SORTING_COLUMNS.volume24h as string,
            type: SORTING_TYPES.desc as string,
        },

        aAssetSymbolsArray: undefined as { symbol: string, isSelected: boolean }[] | undefined,
        bAssetSymbolsArray: undefined as { symbol: string, isSelected: boolean }[] | undefined,
        isFirstAssetWrapped: false as boolean,
        isSecondAssetWrapped: false as boolean,

        manageLiquidityType: 'add' as MANAGE_LIQUIDITY_TYPES,
        aAsset: '' as string,
        bAsset: '' as string,
        network: '' as string,
        placement: '' as string,
        poolFee: 0 as number,
        poolInfo: undefined as undefined | PoolInfo,
        aAssetApproved: CHECK_APPROVAL_STATUSES.NOT_APPROVED as string,
        bAssetApproved: CHECK_APPROVAL_STATUSES.NOT_APPROVED as string,
        aAssetMaximumApproved: 0 as number,
        bAssetMaximumApproved: 0 as number,
        aAssetPositionQuantity: 0 as number,
        bAssetPositionQuantity: 0 as number,
        poolShare: 0 as number,
        isLiquidityApproved: CHECK_APPROVAL_STATUSES.NOT_APPROVED as string,
    },
};

export type DefiLiquidityPoolsState = typeof state;

export enum DefiLiquidityPoolsMutations {
    SET_TOTAL_LIST_POOLS_PAGES = 'SET_TOTAL_LIST_POOLS_PAGES',
    SET_POOLS_LIST = 'SET_POOLS_LIST',
    SET_MANAGE_LIQUIDITY_TYPE = 'SET_MANAGE_LIQUIDITY_TYPE',
    SET_A_ASSET = 'SET_A_ASSET',
    SET_B_ASSET = 'SET_B_ASSET',
    SET_NETWORK = 'SET_NETWORK',
    SET_PLACEMENT = 'SET_PLACEMENT',
    SET_POOL_FEE = 'SET_POOL_FEE',
    SET_POOL_INFO = 'SET_POOL_INFO',
    SET_ASSETS_ALLOWANCE = 'SET_ASSETS_ALLOWANCE',
    SET_POOL_POSITION = 'SET_POOL_POSITION',
    SET_LIQUIDITY_ALLOWANCE = 'SET_LIQUIDITY_ALLOWANCE',
    SET_SORTING = 'SET_SORTING',
    SET_SEARCHED_POOLS_LIST = 'SET_SEARCHED_POOLS_LIST',
    SET_DEX_POOLS_POSITIONS = 'SET_DEX_POOLS_POSITIONS',
    SET_ACTIVE_A_WRAPPED_ASSET = 'SET_ACTIVE_A_WRAPPED_ASSET',
    SET_ACTIVE_B_WRAPPED_ASSET = 'SET_ACTIVE_B_WRAPPED_ASSET',
    UPDATE_POOL_POSITION = 'UPDATE_POOL_POSITION',
    SET_HAS_OPENED_POSITION = 'SET_HAS_OPENED_POSITION',
}

export const SET_TOTAL_LIST_POOLS_PAGES = mutationCreator<number | undefined>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_TOTAL_LIST_POOLS_PAGES);
export const SET_POOLS_LIST = mutationCreator<DexPoolInfo[]>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_POOLS_LIST);
export const SET_MANAGE_LIQUIDITY_TYPE = mutationCreator<MANAGE_LIQUIDITY_TYPES>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_MANAGE_LIQUIDITY_TYPE);
export const SET_A_ASSET = mutationCreator<string>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_A_ASSET);
export const SET_B_ASSET = mutationCreator<string>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_B_ASSET);
export const SET_NETWORK = mutationCreator<string>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_NETWORK);
export const SET_PLACEMENT = mutationCreator<string>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_PLACEMENT);
export const SET_POOL_FEE = mutationCreator<number>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_POOL_FEE);
export const SET_POOL_INFO = mutationCreator<PoolInfo>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_POOL_INFO);
export const SET_ASSETS_ALLOWANCE = mutationCreator<ISetAssetsAllowancePayload>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_ASSETS_ALLOWANCE);
export const SET_POOL_POSITION = mutationCreator<LiquidityPosition>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_POOL_POSITION);
export const SET_LIQUIDITY_ALLOWANCE = mutationCreator<string>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_LIQUIDITY_ALLOWANCE);
export const SET_SORTING = mutationCreator<ISetSortingPayload>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_SORTING);
export const SET_SEARCHED_POOLS_LIST = mutationCreator<DexPoolInfo[]>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_SEARCHED_POOLS_LIST);
export const SET_DEX_POOLS_POSITIONS = mutationCreator<DexPosition[]>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_DEX_POOLS_POSITIONS);
export const SET_ACTIVE_A_WRAPPED_ASSET = mutationCreator<{ symbol: string }>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_ACTIVE_A_WRAPPED_ASSET);
export const SET_ACTIVE_B_WRAPPED_ASSET = mutationCreator<{ symbol: string }>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_ACTIVE_B_WRAPPED_ASSET);
export const UPDATE_POOL_POSITION = mutationCreator<IDexPosition>('DefiLiquidityPools', DefiLiquidityPoolsMutations.UPDATE_POOL_POSITION);
export const SET_HAS_OPENED_POSITION = mutationCreator<boolean>('DefiLiquidityPools', DefiLiquidityPoolsMutations.SET_HAS_OPENED_POSITION);

const mutations: Record<DefiLiquidityPoolsMutations, (state: DefiLiquidityPoolsState, ...args: any) => void> = {
    SET_TOTAL_LIST_POOLS_PAGES(state, { payload: totalPagesNumber }: ReturnType<typeof SET_TOTAL_LIST_POOLS_PAGES>) {
        state.ui.totalPoolsListPages = totalPagesNumber;
    },
    SET_POOLS_LIST(state, { payload: poolsList }: ReturnType<typeof SET_POOLS_LIST>) {
        state.poolsList = poolsList;
    },
    SET_MANAGE_LIQUIDITY_TYPE(state, { payload: type }: ReturnType<typeof SET_MANAGE_LIQUIDITY_TYPE>) {
        state.ui.manageLiquidityType = type;
    },
    SET_A_ASSET(state, { payload: asset }: ReturnType<typeof SET_A_ASSET>) {
        state.ui.aAsset = asset;
    },
    SET_B_ASSET(state, { payload: asset }: ReturnType<typeof SET_B_ASSET>) {
        state.ui.bAsset = asset;
    },
    SET_NETWORK(state, { payload: network }: ReturnType<typeof SET_NETWORK>) {
        state.ui.network = network;
    },
    SET_PLACEMENT(state, { payload: placement }: ReturnType<typeof SET_PLACEMENT>) {
        state.ui.placement = placement;
    },
    SET_POOL_FEE(state, { payload: fee }: ReturnType<typeof SET_POOL_FEE>) {
        state.ui.poolFee = fee;
    },
    SET_POOL_INFO(state, { payload: poolInfo }: ReturnType<typeof SET_POOL_INFO>) {
        state.ui.poolInfo = poolInfo;
    },
    SET_ASSETS_ALLOWANCE(state, { payload: data }: ReturnType<typeof SET_ASSETS_ALLOWANCE>) {
        state.ui.aAssetApproved = data.aAllowance.aAssetApproved;
        state.ui.bAssetApproved = data.bAllowance.bAssetApproved;
        state.ui.aAssetMaximumApproved = data.aAllowance.aAssetMaximumApproved;
        state.ui.bAssetMaximumApproved = data.bAllowance.bAssetMaximumApproved;
    },
    SET_POOL_POSITION(state, { payload: position }: ReturnType<typeof SET_POOL_POSITION>) {
        state.ui.aAssetPositionQuantity = position.aAssetQuantity;
        state.ui.bAssetPositionQuantity = position.bAssetQuantity;
        state.ui.poolShare = position.share;
    },
    SET_LIQUIDITY_ALLOWANCE(state, { payload: allowance }: ReturnType<typeof SET_LIQUIDITY_ALLOWANCE>) {
        state.ui.isLiquidityApproved = allowance;
    },
    SET_SORTING(state, { payload: sortingData }: ReturnType<typeof SET_SORTING>) {
        state.ui.poolsTableSorting = { ...sortingData };
    },
    SET_SEARCHED_POOLS_LIST(state, { payload: poolsList }: ReturnType<typeof SET_SEARCHED_POOLS_LIST>) {
        state.searchedPoolsList = poolsList;
    },
    SET_DEX_POOLS_POSITIONS(state, { payload: positions }: ReturnType<typeof SET_DEX_POOLS_POSITIONS>) {
        state.poolsPositions = positions;
    },
    SET_ACTIVE_A_WRAPPED_ASSET(state, { payload: { symbol } }: ReturnType<typeof SET_ACTIVE_A_WRAPPED_ASSET>) {
        state.ui.aAssetSymbolsArray = state.ui.aAssetSymbolsArray!.map((a) => {
            if (a.symbol === symbol) {
                a.isSelected = true;
                return a;
            }
            a.isSelected = false;
            return a;
        });
    },
    SET_ACTIVE_B_WRAPPED_ASSET(state, { payload: { symbol } }: ReturnType<typeof SET_ACTIVE_B_WRAPPED_ASSET>) {
        state.ui.bAssetSymbolsArray = state.ui.bAssetSymbolsArray!.map((a) => {
            if (a.symbol === symbol) {
                a.isSelected = true;
                return a;
            }
            a.isSelected = false;
            return a;
        });
    },
    UPDATE_POOL_POSITION(state, { payload: position }: ReturnType<typeof UPDATE_POOL_POSITION>) {
        const updatedPositionIndex = state.poolsPositions.findIndex((p) => p.placementName === position.placementName
            && p.blockchainName === position.blockchainName
            && p.aAssetSymbol === position.aAssetSymbol
            && p.bAssetSymbol === position.bAssetSymbol);
        if (position.status === 'CLOSED') {
            state.poolsPositions.splice(updatedPositionIndex, 1);
        } else if (position.status === 'ACTIVE') {
            if (updatedPositionIndex === -1) {
                const newPosition = new DexPosition({ ...position, createdAt: new Date().toString(), updatedAt: new Date().toString() });
                state.poolsPositions.push(newPosition);
            } else {
                const temp = state.poolsPositions[updatedPositionIndex].serialize();
                temp.share = position.share;
                temp.aQuantity = position.aQuantity;
                temp.bQuantity = position.bQuantity;
                temp.bInvestedQuantity = position.bInvestedQuantity;
                temp.aInvestedQuantity = position.aInvestedQuantity;
                temp.quotations = position.quotations;
                state.poolsPositions.splice(updatedPositionIndex, 1, new DexPosition(temp));
            }
        }
    },
    SET_HAS_OPENED_POSITION(state, { payload: hasOpenedPosition }: ReturnType<typeof SET_HAS_OPENED_POSITION>) {
        state.hasOpenedPosition = hasOpenedPosition;
    },
};

export enum DefiLiquidityPoolsActions {
    getPoolsList = 'getPoolsList',
    updatePoolsListPage = 'updatePoolsListPage',
    getPoolInfo = 'getPoolInfo',
    getAssetsAllowance = 'getAssetsAllowance',
    getPoolPositions = 'getPoolPositions',
    getLiquidityAllowance = 'getLiquidityAllowance',
    searchPools = 'searchPools',
    getDexPoolsPositions = 'getDexPoolsPositions',
    setWrappedAssets = 'setWrappedAssets',
}

export const getPoolsList = actionCreator<IGetPoolsListPayload>('DefiLiquidityPools', DefiLiquidityPoolsActions.getPoolsList);
export const updatePoolsListPage = actionCreator<IUpdatePoolsListPayload>('DefiLiquidityPools', DefiLiquidityPoolsActions.updatePoolsListPage);
export const getPoolInfo = actionCreator<undefined>('DefiLiquidityPools', DefiLiquidityPoolsActions.getPoolInfo);
export const getAssetsAllowance = actionCreator<undefined>('DefiLiquidityPools', DefiLiquidityPoolsActions.getAssetsAllowance);
export const getPoolPositions = actionCreator<undefined>('DefiLiquidityPools', DefiLiquidityPoolsActions.getPoolPositions);
export const getLiquidityAllowance = actionCreator<number>('DefiLiquidityPools', DefiLiquidityPoolsActions.getLiquidityAllowance);
export const searchPools = actionCreator<ISearchPoolsPayload>('DefiLiquidityPools', DefiLiquidityPoolsActions.searchPools);
export const getDexPoolsPositions = actionCreator<undefined>('DefiLiquidityPools', DefiLiquidityPoolsActions.getDexPoolsPositions);
export const setWrappedAssets = actionCreator<undefined>('DefiLiquidityPools', DefiLiquidityPoolsActions.setWrappedAssets);

const actions = {
    async getPoolsList({ state, commit, dispatch }, { payload: { placementName, blockchainName } }: ReturnType<typeof getPoolsList>) {
        if (!placementName || !blockchainName) {
            return;
        }
        try {
            let getDexPoolsPayload;
            if (state.ui.poolsTableSorting.column && state.ui.poolsTableSorting.type) {
                getDexPoolsPayload = new DexPoolsRequest({
                    orderBy: state.ui.poolsTableSorting.column,
                    ordering: state.ui.poolsTableSorting.type,
                    placementName,
                    blockchainName,
                    includeTotal: true,
                    page: 1,
                    perPage: state.ui.poolsPerPage,
                });
            } else {
                getDexPoolsPayload = new DexPoolsRequest({
                    placementName,
                    blockchainName,
                    includeTotal: true,
                    page: 1,
                    perPage: state.ui.poolsPerPage,
                });
            }
            const { data: poolsList, headers } = await PublicDataApi.publicGetDexPools(getDexPoolsPayload, true);

            if (headers) {
                const totalPages = parsePaginationHeaders(headers).totalPage;
                commit(SET_TOTAL_LIST_POOLS_PAGES(totalPages, true));
            }

            commit(SET_POOLS_LIST(poolsList, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async updatePoolsListPage({ state, commit, dispatch }, { payload: { page, placementName, blockchainName } }: ReturnType<typeof updatePoolsListPage>) {
        if (!page || !placementName || !blockchainName) {
            return;
        }

        try {
            let getDexPoolsPayload;
            if (state.ui.poolsTableSorting.column && state.ui.poolsTableSorting.type) {
                getDexPoolsPayload = new DexPoolsRequest({
                    orderBy: state.ui.poolsTableSorting.column,
                    ordering: state.ui.poolsTableSorting.type,
                    placementName,
                    blockchainName,
                    page,
                    perPage: state.ui.poolsPerPage,
                });
            } else {
                getDexPoolsPayload = new DexPoolsRequest({
                    placementName,
                    blockchainName,
                    page,
                    perPage: state.ui.poolsPerPage,
                });
            }
            const { data: poolsList } = await PublicDataApi.publicGetDexPools(getDexPoolsPayload);

            commit(SET_POOLS_LIST(poolsList, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async getPoolInfo({ commit, state }) {
        const { data: poolInfo } = await DeFiApi.privateDexGetPoolInfo(new GetPoolInfoRequest({
            aAssetSymbol: state.ui.aAsset,
            bAssetSymbol: state.ui.bAsset,
            blockchainName: state.ui.network,
            fee: state.ui.poolFee,
            placementName: state.ui.placement,
        }));
        commit(SET_POOL_INFO(poolInfo, true));
    },
    async getAssetsAllowance({ state, commit, rootGetters }) {
        if (!rootGetters['Accounts/activeAccountID']
            || !state.ui.placement
            || !state.ui.aAsset
            || !state.ui.bAsset
            || !state.ui.network) {
            return;
        }
        const { data: aApprovalStatus } = await DeFiApi.privateDexLiquidityCheckApprovalStatus(new ApproveRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            assetSymbol: state.ui.aAsset,
            blockchainName: state.ui.network,
            placementName: state.ui.placement,
            quantity: '1',
        }));
        const { data: bApprovalStatus } = await DeFiApi.privateDexLiquidityCheckApprovalStatus(new ApproveRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            assetSymbol: state.ui.bAsset,
            blockchainName: state.ui.network,
            placementName: state.ui.placement,
            quantity: '1',
        }));
        const { data: aAllowanceResponse } = await DeFiApi.privateDexLiquidityGetAllowance(new GetAllowanceRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            assetSymbol: state.ui.aAsset,
            blockchainName: state.ui.network,
            placementName: state.ui.placement,
        }));
        const { data: bAllowanceResponse } = await DeFiApi.privateDexLiquidityGetAllowance(new GetAllowanceRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            assetSymbol: state.ui.bAsset,
            blockchainName: state.ui.network,
            placementName: state.ui.placement,
        }));
        const payload: ISetAssetsAllowancePayload = {
            aAllowance: {
                aAssetApproved: aApprovalStatus.status ?? 'NOT_APPROVED',
                aAssetMaximumApproved: aAllowanceResponse.quantity ?? 0,
            },
            bAllowance: {
                bAssetApproved: bApprovalStatus.status ?? 'NOT_APPROVED',
                bAssetMaximumApproved: bAllowanceResponse.quantity ?? 0,
            },
        };
        commit(SET_ASSETS_ALLOWANCE(payload, true));
    },
    async getPoolPositions({ state, commit, rootGetters }) {
        if (!rootGetters['Accounts/activeAccountID']
            || !state.ui.aAsset
            || !state.ui.bAsset
            || !state.ui.network
            || !state.ui.poolFee
            || !state.ui.placement) {
            return;
        }
        const { data: position } = await DeFiApi.privateDexLiquidityGetPosition(new GetPositionRequest({
            aAssetSymbol: state.ui.aAsset,
            bAssetSymbol: state.ui.bAsset,
            accountId: rootGetters['Accounts/activeAccountID'],
            placementName: state.ui.placement,
            fee: state.ui.poolFee,
            blockchainName: state.ui.network,
        }));
        commit(SET_POOL_POSITION(position, true));
    },
    async getLiquidityAllowance({ commit, state, rootGetters }, { payload: percent }: ReturnType<typeof getLiquidityAllowance>) {
        if (!rootGetters['Accounts/activeAccountID']
            || !state.ui.placement
            || !String(percent)
            || !state.ui.poolFee
            || !state.ui.aAsset
            || !state.ui.bAsset
            || !state.ui.network) {
            return;
        }
        const { data: allowance } = await DeFiApi.privateDexLiquidityCheckLiquidityApprovalStatus(new LiquidityApproveRequest({
            aAssetSymbol: state.ui.aAsset,
            bAssetSymbol: state.ui.bAsset,
            blockchainName: state.ui.network,
            fee: state.ui.poolFee,
            percent: String(percent),
            placementName: state.ui.placement,
            accountId: rootGetters['Accounts/activeAccountID'],
        }));
        commit(SET_LIQUIDITY_ALLOWANCE(allowance.status ?? 'NOT_APPROVED', true));
    },
    async searchPools({ commit }, { payload: { inputValue, blockchainName, placementName } }: ReturnType<typeof searchPools>) {
        if (!placementName || !blockchainName) {
            return;
        }

        const { data: result } = await PublicDataApi.publicGetDexPools(new DexPoolsRequest({
            assetPairSymbol: inputValue,
            blockchainName,
            placementName,
        }));
        commit(SET_SEARCHED_POOLS_LIST(result, true));
    },
    async getDexPoolsPositions({ rootGetters, commit }) {
        if (!rootGetters['Accounts/activeAccountID']) {
            return;
        }

        const { data: positions } = await DeFiApi.privateGetDexPositions(new DexPositionsRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
        }));
        commit(SET_DEX_POOLS_POSITIONS(positions, true));
    },
    setWrappedAssets({ state, rootState }) {
        // renew data
        state.ui.isFirstAssetWrapped = false;
        state.ui.isSecondAssetWrapped = false;
        state.ui.aAssetSymbolsArray = undefined;
        state.ui.bAssetSymbolsArray = undefined;

        const currentBlockchain = rootState.Defi.blockchains?.find((b) => b.name === state.ui.network);
        if (!currentBlockchain) {
            return;
        }
        if (`W${currentBlockchain.nativeAssetSymbol}` === state.ui.aAsset) {
            state.ui.isFirstAssetWrapped = true;
            state.ui.aAssetSymbolsArray = [
                {
                    symbol: state.ui.aAsset,
                    isSelected: true,
                },
                {
                    symbol: currentBlockchain.nativeAssetSymbol,
                    isSelected: false,
                },
            ];
        } else if (`W${currentBlockchain.nativeAssetSymbol}` === state.ui.bAsset) {
            state.ui.isSecondAssetWrapped = true;
            state.ui.bAssetSymbolsArray = [
                {
                    symbol: state.ui.bAsset,
                    isSelected: true,
                },
                {
                    symbol: currentBlockchain.nativeAssetSymbol,
                    isSelected: false,
                },
            ];
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
