// This file was autogenerated. Please do not change.
export interface ITransferStatus {
    count?: number;
    status?: ('EXECUTED' | 'NOT_EXECUTED');
}

export default class TransferStatus {
    readonly _count: number | undefined;

    /**
     * Example: 100
     */
    get count(): number | undefined {
        return this._count;
    }

    readonly _status: ('EXECUTED' | 'NOT_EXECUTED') | undefined;

    /**
     * Example: EXECUTED
     */
    get status(): ('EXECUTED' | 'NOT_EXECUTED') | undefined {
        return this._status;
    }

    constructor(props: ITransferStatus) {
        if (typeof props.count === 'number') {
            this._count = props.count;
        }
        if (props.status) {
            this._status = props.status;
        }
    }

    serialize(): ITransferStatus {
        const data: ITransferStatus = {
        };
        if (typeof this._count !== 'undefined') {
            data.count = this._count;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): ITransferStatus {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            count: !this._count ? true : typeof this._count === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
