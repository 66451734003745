<template>
    <li :class="s.container">
        <p>
            {{ date }}
        </p>
        <p :class="s.header">
            Incoming account management request
        </p>
        <p :class="s.text">
            You have received access request to managed trading account: <span :class="s.violet">{{ accountId }}</span>
            <br/>
            Managed account owner: <span :class="s.violet">{{ issuerName }}</span>
        </p>
        <p :class="[s.text, s.mtS]" v-if="policies.length > 0">
            Granted access permissions:
        </p>
        <ul :class="s.list">
            <li :class="s.item" v-for="policy in policies" :key="policy">
                {{ $store.getters.localization.AccountAccesses[policy] }}
            </li>
        </ul>
        <div :class="s.buttonsContainer">
            <Button
                wide
                button-type="info"
                @click="reject"
                :class="s.mrXl"
            >
                <template>
                    Reject
                </template>
            </Button>
            <Button
                button-type="primary"
                wide
                @click="admit"
            >
                <template>
                    Accept
                </template>
            </Button>
        </div>
    </li>
</template>

<script>
import Button from 'Control/Button.vue';
import { permissionsOrder } from 'Store/v2/Notifications';

export default {
    name: 'Notification.IncomingAccountManagementRequest',
    components: {
        Button,
    },
    props: {
        request: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            isButtonsDisabled: false,
        };
    },
    computed: {
        issuerId() {
            return this.request.issuer;
        },
        date() {
            return this.request.createdAt;
        },
        accountId() {
            return this.request.accountId;
        },
        requestGuid() {
            return this.request.id;
        },
        policies() {
            return [...this.request.policies]
                .sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b))
                .filter((policy) => policy !== '');
        },
        issuerName() {
            return `${this.request.issuerFirstName} ${this.request.issuerLastName}`;
        },
    },
    methods: {
        admit() {
            this.isButtonsDisabled = true;

            this.$store
                .dispatch('Accounts/Management/admitAccountRequestAccess', {
                    guid: this.requestGuid,
                    name: this.requestGuid,
                })
                .then(() => {
                    this.$store.dispatch('Notificator/showSuccessNotification', 'Access request was successfully admitted');
                })
                .catch(() => {
                    this.$store.dispatch(
                        'Notificator/showSuccessNotification',
                        'During admitting something went wrong. Please, try again later',
                    );
                    this.isButtonsDisabled = false;
                });
        },
        reject() {
            this.isButtonsDisabled = true;

            this.$store
                .dispatch('Accounts/Management/rejectAccountRequestAccess', {
                    guid: this.requestGuid,
                })
                .then(() => {
                    this.$store.dispatch('Notificator/showSuccessNotification', 'Access request was successfully rejected');
                })
                .catch(() => {
                    this.$store.dispatch(
                        'Notificator/showSuccessNotification',
                        'During rejecting something went wrong. Please, try again later',
                    );
                    this.isButtonsDisabled = false;
                });
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    padding: var(--m-m);
}
.list {
    margin-top: var(--m-xs);
    display: flex;
    flex-direction: column;
    align-items: flex-start !important;
}
.item {
    position: relative;
    padding-left: var(--m-l);
    font-weight: var(--fw-semibold);
    font-size: var(--fs-m);
    line-height: var(--fs-xl);
    color: var(--cl-black);
    text-align: left;
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 5px;
        height: 5px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        background: var(--cl-violet);
        border-radius: 50%;
    }
}
.header {
    font-weight: var(--fw-extrabold);
    font-size: var(--fs-l);
    line-height: var(--fs-l);
    color: var(--cl-black);
    text-align: left;
    margin-bottom: var(--m-xs);
}
.text {
    font-weight: var(--fw-semibold);
    font-size: var(--fs-m);
    line-height: var(--fs-xl);
    color: var(--cl-black);
    text-align: left;
    position: relative;
    & .violet {
        color: var(--cl-violet);
    }
}
.mtS {
    margin-top: var(--m-s);
}
.mrXl {
    margin-right: var(--m-xl);
}
.buttonsContainer {
    display: flex;
    margin-top: var(--m-l);
}
</style>
