import store from '@/store';
import { PlacementTypes } from 'Models/placements';

/**
 * TransferPayload constructor property
 * @typedef {Object} TransferPayloadData
 * @property {int} accountId
 * @property {String} [toExchange]
 * @property {String} [fromExchange]
 * @property {String} [toAccountId]
 * @property {String} assetSymbol
 * @property {int} transferAmount
 * @property {String} totp
 */

export const defaultIndexes = {
    PLACEMENT_FROM: 0,
    PLACEMENT_TO: 1,
};

/**
 * Enum transfer types
 * @enum {string}
 */
export const TransferTypes = {
    EXCHANGES: 'Exchanges',
    ACCOUNTS: 'Accounts',
};

export class TransferPayload {
    accountId: any;

    assetSymbol: any;

    transferAmount: number | undefined;

    toExchange: any;

    fromExchange: any;

    toAccountId: any;

    totp: any;

    /**
     *
     * @param {TransferTypes} type - required
     * @param {TransferPayloadData} payloadData - required
     */
    constructor(type, payloadData) {
        this.mountCommonFields(payloadData);

        if (type === TransferTypes.EXCHANGES) {
            this.mountExchangesFields(payloadData);
        } else if (type === TransferTypes.ACCOUNTS) {
            this.mountAccountsFields(payloadData);
        }
    }

    /**
     *
     * @param {TransferPayloadData} payloadData
     */
    mountCommonFields({ accountId, assetSymbol, transferAmount }) {
        this.accountId = accountId;
        this.assetSymbol = assetSymbol;
        this.transferAmount = +transferAmount;
    }

    /**
     *
     * @param {TransferPayloadData} payloadData
     */
    mountExchangesFields({ toExchange, fromExchange }) {
        if (toExchange.toUpperCase() !== store.getters['Placements/platformPlacementName'].toUpperCase()) {
            this.toExchange = toExchange;
        }

        if (fromExchange.toUpperCase() !== store.getters['Placements/platformPlacementName'].toUpperCase()) {
            this.fromExchange = fromExchange;
        }
    }

    /**
     *
     * @param {TransferPayloadData} payloadData
     */
    mountAccountsFields({ toAccountId, fromExchange }) {
        this.toAccountId = toAccountId;

        if (fromExchange.toUpperCase() !== store.getters['Placements/platformPlacementName'].toUpperCase()) {
            this.fromExchange = fromExchange;
        }
    }

    /**
     *
     * @param {String} token
     */
    setTotp(token) {
        this.totp = token;
    }
}

export function getFeesSum(currencySymbol, ...fees) {
    if ((new Set(fees.map(({ assetSymbol }) => assetSymbol))).size === 1) {
        return {
            amount: fees.reduce((current, sum) => +sum.amount + +current, 0),
            assetSymbol: fees[0].assetSymbol,
        };
    }
    const assets = Object.fromEntries(fees.map((fee) => [fee.assetSymbol, { amount: +fee.amount, quotation: store.getters['Assets/GET_ASSET_QUOTATION_BY_SYMBOL'](fee.assetSymbol) }]));
    const otherAssetSymbol = fees.find(({ assetSymbol }) => assetSymbol !== currencySymbol).assetSymbol;
    let result = 0;

    Object.keys(assets).forEach((asset) => {
        if (asset === otherAssetSymbol) {
            result += assets[asset].amount;
        } else {
            result += (assets[asset].amount * assets[asset].quotation) / assets[otherAssetSymbol].quotation;
        }
    });

    return {
        amount: result,
        assetSymbol: otherAssetSymbol,
    };
}

export function getFeeValue(fees, placementFromType, currencySymbol) {
    if (!fees || !placementFromType) { return 0; }

    switch (placementFromType) {
        case PlacementTypes.SELF:
            return getFeesSum(currencySymbol, fees.platform, fees.blockchain);
        case PlacementTypes.CRYPTO_SPOT:
            return getFeesSum(currencySymbol, fees.platform, fees.exchange);
        default:
            return {};
    }
}
