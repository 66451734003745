// This file was autogenerated. Please do not change.
export interface IRouteEdge {
    source?: string;
    target?: string;
}

export default class RouteEdge {
    readonly _source: string | undefined;

    get source(): string | undefined {
        return this._source;
    }

    readonly _target: string | undefined;

    get target(): string | undefined {
        return this._target;
    }

    constructor(props: IRouteEdge) {
        if (typeof props.source === 'string') {
            this._source = props.source.trim();
        }
        if (typeof props.target === 'string') {
            this._target = props.target.trim();
        }
    }

    serialize(): IRouteEdge {
        const data: IRouteEdge = {
        };
        if (typeof this._source !== 'undefined') {
            data.source = this._source;
        }
        if (typeof this._target !== 'undefined') {
            data.target = this._target;
        }
        return data;
    }

    toJSON(): IRouteEdge {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            source: !this._source ? true : typeof this._source === 'string',
            target: !this._target ? true : typeof this._target === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
