import store from '../../store';

export const NotificationTypes = {
    COMMON_NOTIFICATION: 'COMMON_NOTIFICATION',
    INCOMING_ACCOUNT_MANAGEMENT_REQUEST: 'INCOMING_ACCOUNT_MANAGEMENT_REQUEST',
    INCOMING_GROUP_REQUEST: 'INCOMING_GROUP_REQUEST',
};

export const CommonNotificationTypes = {
    sharing_request: {
        type: 'sharing_request',
        messageCallback: ({ issuer: { Id: issuerId }, account: { id: accountId }, policies }) => `You have received access request to managed trading account: ${accountId}.\nManaged account owner: ${issuerId}\nGranted access permissions: ${policies?.map(
            (item) => store.getters.localization.AccountAccesses[item],
        )}`,
    }, // - user "issuer" gives access to account "account"
    change_account_permissions_request: {
        type: 'change_account_permissions_request',
        messageCallback: ({ issuer: { Id: issuerId, firstName, lastName }, account: { id: accountId } }) => `Change account permissions. Issuer ${firstName} ${lastName} (${issuerId}). Account ${accountId}`,
    }, // - user "issuer" tries to change access rights to account "account"
    account_revoked: {
        type: 'account_revoked',
        messageCallback: ({ issuer: { Id: issuerId, firstName, lastName }, account: { id: accountId } }) => `Account revoked. Issuer ${firstName} ${lastName} (${issuerId}). Account ${accountId}`,
    }, // - user "issuer" revoked access rights to account "account"
    account_refused: {
        type: 'account_refused',
        messageCallback: ({ issuer: { Id: issuerId }, account: { id: accountId }, policies }) => `Manager has refused access to your trading account: ${accountId}\nAccess was refused by manager: ${issuerId}\nRejected access permissions: ${policies?.map(
            (item) => store.getters.localization.AccountAccesses[item],
        )}`,
    }, // - user "issuer" refused access rights to account "account"
    account_suspended: {
        type: 'account_suspended',
        messageCallback: ({ account: { id: accountId } }) => `Your trading account has been suspended: ${accountId}. Remember that you can always restore this trading account.`,
    }, // - user "issuer" suspended account "account"
    account_restored: {
        type: 'account_restored',
        messageCallback: ({ account: { id: accountId } }) => `Your trading account has been restored: ${accountId}`,
    }, // - user "issuer" restored account "account"
    account_blocked: {
        type: 'account_blocked',
        messageCallback: ({ account: { id: accountId } }) => `Account blocked. Account ${accountId}`,
    }, // - account "account" was blocked
    sharing_request_admitted: {
        type: 'sharing_request_admitted',
        messageCallback: ({ issuer: { Id: issuerId }, account: { id: accountId }, policies }) => `You have confirmed access request to managed trading account: ${accountId}\nManaged account owner: ${issuerId}\nGranted access permissions: ${policies?.map(
            (item) => store.getters.localization.AccountAccesses[item],
        )}`,
    }, // - user "issuer" admitted request "request"
    sharing_request_rejected: {
        type: 'sharing_request_rejected',
        messageCallback: ({ issuer: { Id: issuerId }, account: { id: accountId }, policies }) => `You have rejected access request to managed trading account: ${accountId}\nManaged account owner: ${issuerId}\nRejected access permissions: ${policies?.map(
            (item) => store.getters.localization.AccountAccesses[item],
        )}`,
    }, // - user "issuer" rejected request "request"
    sharing_request_cancelled: {
        type: 'sharing_request_cancelled',
        messageCallback: ({ issuer: { Id: issuerId, firstName, lastName }, request: { id: requestId } }) => `Sharing request cancelled. Issuer ${firstName} ${lastName} (${issuerId}). Request ${requestId}`,
    }, // - user "issuer" cancelled request "request"
};

export class CommonNotification {
    commonType;

    type;

    createdAt;

    data;

    message;

    constructor({ createdAt, message }) {
        const data = JSON.parse(message);

        this.createdAt = new Date(createdAt);

        this.type = data.type;
        this.data = data;

        this.commonType = CommonNotificationTypes[data.type];
        this.message = this.commonType.messageCallback(data);
    }
}
