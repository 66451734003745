// This file was autogenerated. Please do not change.
/* A payload to delete an API-key */
export interface IDeleteKeyPayload {
    apiKey: string;
    totp: string;
}

export default class DeleteKeyPayload {
    readonly _apiKey: string;

    /** Description: An API-key */
    get apiKey(): string {
        return this._apiKey;
    }

    readonly _totp: string;

    /** Description: One-time verification code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IDeleteKeyPayload) {
        this._apiKey = props.apiKey.trim();
        this._totp = props.totp.trim();
    }

    serialize(): IDeleteKeyPayload {
        const data: IDeleteKeyPayload = {
            apiKey: this._apiKey,
            totp: this._totp,
        };
        return data;
    }

    toJSON(): IDeleteKeyPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
            apiKey: typeof this._apiKey === 'string' && !!this._apiKey.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
