import ApiError from 'Entities/ApiError';
import FavoriteAssetPair from 'Entities/userSettings/FavoriteAssetPair';
import GetFavoriteAssetPairsRequest from 'Entities/userSettings/GetFavoriteAssetPairsRequest';
import GetPairChartDataRequest from 'Entities/userSettings/GetPairChartDataRequest';
import PairChartData from 'Entities/userSettings/PairChartData';
import Preset from 'Entities/userSettings/Preset';
import PresetRequest from 'Entities/userSettings/PresetRequest';
import SavePairChartDataRequest from 'Entities/userSettings/SavePairChartDataRequest';
import SavePresetRequest from 'Entities/userSettings/SavePresetRequest';
import UpdatePresetRequest from 'Entities/userSettings/UpdatePresetRequest';
import UserColorPalette from 'Entities/userSettings/UserColorPalette';
import UserPlatformConfig from 'Entities/userSettings/UserPlatformConfig';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class SettingsApi {
    static async addFavorite(favoriteassetpair: FavoriteAssetPair, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...favoriteassetpair.validate());
        if (haveError.length > 0) {
            throw new ApiError('addFavorite', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/addFavorite`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(favoriteassetpair.serialize()),
        });
        if (res.status === 201) {
            return { data: res.status };
        }
        throw new ApiError('Settings/addFavorite', { response: res, fields: [JSON.stringify({ favoriteassetpair })] });
    }

    static async deleteFavorite(favoriteassetpair: FavoriteAssetPair, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...favoriteassetpair.validate());
        if (haveError.length > 0) {
            throw new ApiError('deleteFavorite', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/deleteFavorite`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(favoriteassetpair.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Settings/deleteFavorite', { response: res, fields: [JSON.stringify({ favoriteassetpair })] });
    }

    static async deletePreset(presetrequest: PresetRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...presetrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('deletePreset', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/deletePreset`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(presetrequest.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Settings/deletePreset', { response: res, fields: [JSON.stringify({ presetrequest })] });
    }

    static async getActivePresets(withHeaders?: boolean): Promise<{ data: Preset[]; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/getActivePresets`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Preset(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/getActivePresets', { response: res, data: result, fields: [] });
    }

    static async getColorPalette(withHeaders?: boolean): Promise<{ data: UserColorPalette; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/getColorPalette`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new UserColorPalette(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/getColorPalette', { response: res, data: result, fields: [] });
    }

    static async getFavorites(getfavoriteassetpairsrequest: GetFavoriteAssetPairsRequest, withHeaders?: boolean): Promise<{ data: FavoriteAssetPair[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getfavoriteassetpairsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('getFavorites', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/getFavorites`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getfavoriteassetpairsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FavoriteAssetPair(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/getFavorites', { response: res, data: result, fields: [JSON.stringify({ getfavoriteassetpairsrequest })] });
    }

    static async getPairChartData(getpairchartdatarequest: GetPairChartDataRequest, withHeaders?: boolean): Promise<{ data: PairChartData; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...getpairchartdatarequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('getPairChartData', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/getPairChartData`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(getpairchartdatarequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new PairChartData(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/getPairChartData', { response: res, data: result, fields: [JSON.stringify({ getpairchartdatarequest })] });
    }

    static async getPlatformConfig(withHeaders?: boolean): Promise<{ data: UserPlatformConfig; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/getPlatformConfig`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new UserPlatformConfig(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/getPlatformConfig', { response: res, data: result, fields: [] });
    }

    static async getPreset(presetrequest: PresetRequest, withHeaders?: boolean): Promise<{ data: Preset; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...presetrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('getPreset', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/getPreset`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(presetrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new Preset(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/getPreset', { response: res, data: result, fields: [JSON.stringify({ presetrequest })] });
    }

    static async listPresets(withHeaders?: boolean): Promise<{ data: Preset[]; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/listPresets`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Preset(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/listPresets', { response: res, data: result, fields: [] });
    }

    static async savePairChartData(savepairchartdatarequest: SavePairChartDataRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...savepairchartdatarequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('savePairChartData', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/savePairChartData`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(savepairchartdatarequest.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Settings/savePairChartData', { response: res, fields: [JSON.stringify({ savepairchartdatarequest })] });
    }

    static async savePlatformConfig(userplatformconfig: UserPlatformConfig, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...userplatformconfig.validate());
        if (haveError.length > 0) {
            throw new ApiError('savePlatformConfig', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/savePlatformConfig`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(userplatformconfig.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Settings/savePlatformConfig', { response: res, fields: [JSON.stringify({ userplatformconfig })] });
    }

    static async savePreset(savepresetrequest: SavePresetRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...savepresetrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('savePreset', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/savePreset`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(savepresetrequest.serialize()),
        });
        if (res.status === 201) {
            return { data: res.status };
        }
        throw new ApiError('Settings/savePreset', { response: res, fields: [JSON.stringify({ savepresetrequest })] });
    }

    static async savePrivateColors(usercolorpalette: UserColorPalette, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...usercolorpalette.validate());
        if (haveError.length > 0) {
            throw new ApiError('savePrivateColors', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/savePrivateColors`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(usercolorpalette.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Settings/savePrivateColors', { response: res, fields: [JSON.stringify({ usercolorpalette })] });
    }

    static async switchPreset(presetrequest: PresetRequest, withHeaders?: boolean): Promise<{ data: Preset; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...presetrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('switchPreset', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/switchPreset`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(presetrequest.serialize()),
        });
        if (res.status === 202) {
            return { data: new Preset(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Settings/switchPreset', { response: res, data: result, fields: [JSON.stringify({ presetrequest })] });
    }

    static async updatePreset(updatepresetrequest: UpdatePresetRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updatepresetrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('updatePreset', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/settings/updatePreset`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updatepresetrequest.serialize()),
        });
        if (res.status === 202) {
            return { data: res.status };
        }
        throw new ApiError('Settings/updatePreset', { response: res, fields: [JSON.stringify({ updatepresetrequest })] });
    }
}
