
import Vue from 'vue';

import GroupRequest from 'Entities/privatePresenter/GroupRequest';
import Button from 'Control/Button.vue';
import GroupsApi from 'Apis/Groups';
import BaseGroupRequestPayload from 'Entities/groupManagement/BaseGroupRequestPayload';
import ApiError from 'Entities/ApiError';

interface Props {
    request: GroupRequest;
}

interface Data {
    isButtonsDisabled: boolean;
}

interface Computed {
    date: string;
    role: string;
    issuerName: string;
    groupName: string;
}

export default Vue.extend<Data, any, Computed, Props>({
    components: {
        Button,
    },
    props: {
        request: {
            type: undefined,
            required: true,
        },
    },
    data() {
        return {
            isButtonsDisabled: false,
        };
    },
    computed: {
        issuerName() {
            return `${this.request.issuerFirstName} ${this.request.issuerLastName}`;
        },
        date() {
            const date = new Date(this.request.createdAt);
            return Intl.DateTimeFormat('en-EN', {
                day: '2-digit',
                month: 'short',
                year: 'numeric',
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
            }).format(date);
        },
        role() {
            return this.request.role;
        },
        groupName() {
            return this.request.groupName;
        },
    },
    methods: {
        async accept() {
            this.isButtonsDisabled = true;
            try {
                await GroupsApi.confirmGroupRequest(new BaseGroupRequestPayload({
                    requestId: this.request.id,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Request was successfully confirmed');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error confirming the request');
                }
            }
        },
        async reject() {
            this.isButtonsDisabled = true;
            try {
                await GroupsApi.rejectGroupRequest(new BaseGroupRequestPayload({
                    requestId: this.request.id,
                }));
                await this.$store.dispatch('Notificator/showSuccessNotification', 'Request was successfully rejected');
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Error rejecting the request');
                }
            }
        },
    },
});
