// This file was autogenerated. Please do not change.
export interface IAccountTransferLimitRequest {
    accountId: string;
}

export default class AccountTransferLimitRequest {
    readonly _accountId: string;

    /**
     * Example: AL6RG9MWFPZ
     */
    get accountId(): string {
        return this._accountId;
    }

    constructor(props: IAccountTransferLimitRequest) {
        this._accountId = props.accountId.trim();
    }

    serialize(): IAccountTransferLimitRequest {
        const data: IAccountTransferLimitRequest = {
            accountId: this._accountId,
        };
        return data;
    }

    toJSON(): IAccountTransferLimitRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
