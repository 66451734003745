import ApiError from 'Entities/ApiError';
import Candle from 'Entities/candlesPresenter/Candle';
import CandlesRequest from 'Entities/candlesPresenter/CandlesRequest';
import TimeResponse from 'Entities/candlesPresenter/TimeResponse';
import OrderBook from 'Entities/publicPresenter/OrderBook';
import OrderBookRequest from 'Entities/publicPresenter/OrderBookRequest';
import SpotTradesRequest from 'Entities/publicPresenter/SpotTradesRequest';
import Trade from 'Entities/publicPresenter/Trade';
import TradingData from 'Entities/publicPresenter/TradingData';
import TradingDataRequest from 'Entities/publicPresenter/TradingDataRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class MarketDataApi {
    static async getCandles(candlesrequest: CandlesRequest, withHeaders?: boolean): Promise<{ data: Candle[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...candlesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('getCandles', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/marketdata/candles/getCandles`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(candlesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Candle(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('MarketData/getCandles', { response: res, data: result, fields: [JSON.stringify({ candlesrequest })] });
    }

    static async getTime(withHeaders?: boolean): Promise<{ data: TimeResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/marketdata/candles/getTime`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new TimeResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('MarketData/getTime', { response: res, data: result, fields: [] });
    }

    static async getPublicSpotTrades(spottradesrequest: SpotTradesRequest, withHeaders?: boolean): Promise<{ data: Trade[] | number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...spottradesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('getPublicSpotTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getSpotTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(spottradesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Trade(r)), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('MarketData/getPublicSpotTrades', { response: res, data: result, fields: [JSON.stringify({ spottradesrequest })] });
    }

    static async publicGetOrderBook(orderbookrequest: OrderBookRequest, withHeaders?: boolean): Promise<{ data: OrderBook | number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...orderbookrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetOrderBook', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getOrderBook`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(orderbookrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new OrderBook(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('MarketData/publicGetOrderBook', { response: res, data: result, fields: [JSON.stringify({ orderbookrequest })] });
    }

    static async publicGetTradingData(tradingdatarequest: TradingDataRequest, withHeaders?: boolean): Promise<{ data: TradingData[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...tradingdatarequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('publicGetTradingData', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/public/v1/meta/getTradingData`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(tradingdatarequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new TradingData(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('MarketData/publicGetTradingData', { response: res, data: result, fields: [JSON.stringify({ tradingdatarequest })] });
    }
}
