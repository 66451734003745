// This file was autogenerated. Please do not change.
export interface IFuturesPositionsRequest {
    accountId?: string;
    accountIds?: string[];
    contractSymbol?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementName?: string;
    quotationAsset?: string;
    side?: ('LONG' | 'SHORT');
}

export default class FuturesPositionsRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _accountIds: string[] | undefined;

    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _contractSymbol: string | undefined;

    /**
     * Example: XRP/USDT
     */
    get contractSymbol(): string | undefined {
        return this._contractSymbol;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance Futures
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _quotationAsset: string | undefined;

    /**
     * Example: USD
     */
    get quotationAsset(): string | undefined {
        return this._quotationAsset;
    }

    readonly _side: ('LONG' | 'SHORT') | undefined;

    /**
     * Example: LONG
     */
    get side(): ('LONG' | 'SHORT') | undefined {
        return this._side;
    }

    constructor(props: IFuturesPositionsRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        if (typeof props.contractSymbol === 'string') {
            this._contractSymbol = props.contractSymbol.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.quotationAsset === 'string') {
            this._quotationAsset = props.quotationAsset.trim();
        }
        if (props.side) {
            this._side = props.side;
        }
    }

    serialize(): IFuturesPositionsRequest {
        const data: IFuturesPositionsRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        if (typeof this._contractSymbol !== 'undefined') {
            data.contractSymbol = this._contractSymbol;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._quotationAsset !== 'undefined') {
            data.quotationAsset = this._quotationAsset;
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        return data;
    }

    toJSON(): IFuturesPositionsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            contractSymbol: !this._contractSymbol ? true : typeof this._contractSymbol === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            quotationAsset: !this._quotationAsset ? true : typeof this._quotationAsset === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
