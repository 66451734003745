import Vue from 'vue';

import {
    Account,
    AccountRoleTypes,
} from 'Models/accounts';
import UserDataManager from 'Lib/utils/UserDataManager';
import PortfolioApi from 'Apis/Portfolio';
import PNLPayload from 'Entities/portfolioBalanceHistory/PNLPayload';
import SettingsApi from 'Apis/Settings';
import ApiError from 'Entities/ApiError';
import AccountsApi from 'Apis/Accounts';
import PoliciesRequest from 'Entities/publicPresenter/PoliciesRequest';
import AccountsParams from 'Entities/privatePresenter/AccountsParams';
import WalletsApi from 'Apis/Wallets';
import AccountTransferLimitRequest from 'Entities/privatePresenter/AccountTransferLimitRequest';
import PlacementPayload from 'Entities/accountManagement/PlacementPayload';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

import Management from './Accounts/Management';

const state = {
    /**
   * @type {Array.<Account>}
   */
    accounts: [] as Account[],
    activeAccountID: null as null | string,
    isBalancesReady: undefined as boolean | undefined,
    colors: null as null | { general: string[], private: string[] },

    activeAccountTransferLimit: null,

    availablePolicies: [],
    showDemoAccountAlert: true,
    isDemoAccountShaking: false,
};
type AccountsState = typeof state;

const getters: Record<string, (state: AccountsState, getters: any, rootState: any, rootGetters: any) => any> = {
    accounts: (state) => state.accounts,
    getAccountById: (state, getters) => (accountId) => getters.accounts.find(({ id }) => id === accountId),
    getAccountByName: (state, getters) => (accountName) => getters.accounts.find(({ name }) => name === accountName),

    activeAccounts: (state, getters) => getters.accounts.filter(({ isActive }) => isActive),
    ownAccounts: (state, getters) => getters.activeAccounts.filter(({ roleType }) => roleType === AccountRoleTypes.OWN),
    manageAccounts: (state, getters) => getters.activeAccounts.filter(({ roleType }) => roleType === AccountRoleTypes.MANAGED),

    suspendedAccounts: (state, getters) => getters.accounts.filter(({ isSuspended }) => isSuspended),
    suspendedOwnAccounts: (state, getters) => getters.suspendedAccounts.filter(({ roleType }) => roleType === AccountRoleTypes.OWN),

    activeAccountID: (state) => state.activeAccountID,
    activeAccount: (state, getters) => {
        const activeAccount: Account = getters.accounts.find(({ id }) => id === getters.activeAccountID);
        return activeAccount;
    },
    activeAccountPlacementLinks: (state, getters) => {
        const acc = getters.accounts.find(({ id }) => id === getters.activeAccountID);
        return acc.placementLinks;
    },
    activeAccountOwnerId: (state, getters) => getters.activeAccount?.ownerId,
    activeAccountName: (state, getters) => (getters.activeAccount ? getters.activeAccount.name : null),
    activeAccountDescription: (state, getters) => (getters.activeAccount ? getters.activeAccount.description : null),
    activeAccountRoleType: (state, getters) => (getters.activeAccount ? getters.activeAccount.roleType : null),
    activeAccountPolicies: (state, getters) => (getters.activeAccount ? getters.activeAccount.policies : null),
    hasActiveAccountPolicy: (state, getters) => (givenPolicy) => (getters.activeAccountPolicies
        ? getters.activeAccountPolicies.some((policy) => policy === givenPolicy)
        : true),
    isActiveAccountMain: (state, getters) => (getters.activeAccount ? getters.activeAccount.isMain : false),
    isActiveAccountOwned: (state, getters) => (getters.activeAccount ? getters.activeAccount.isOwned : false),

    activeAccountTransferLimits: (state) => (state.activeAccountTransferLimit ? state.activeAccountTransferLimit : {}),
    activeAccountTransferLimitsSymbol: (state, getters) => (getters.activeAccountTransferLimits.assetSymbol ? getters.activeAccountTransferLimits.assetSymbol : ''),
    activeAccountDailyTransferLimit: (state, getters) => (getters.activeAccountTransferLimits.dailyLimit ? getters.activeAccountTransferLimits.dailyLimit : 0),
    activeAccountDailyTransferSpent: (state, getters) => (getters.activeAccountTransferLimits.dailySpent ? getters.activeAccountTransferLimits.dailySpent : 0),
    activeAccountDailyTransferRemain: (state, getters) => getters.activeAccountDailyTransferLimit - getters.activeAccountDailyTransferSpent,
    activeAccountDailyPnl: (state, getters) => (getters.activeAccount ? getters.activeAccount.dailyPnl : 0),

    availablePolicies: (state) => state.availablePolicies.filter((p) => p !== 'withdraw' && p !== 'deposit').sort((a, b) => {
        if (a === 'institution') {
            return 1;
        }
        if (b === 'institution') {
            return -1;
        }
        return 0;
    }),
    hasAvailablePolicies: (state, getters) => getters.availablePolicies.length !== 0,
    isActiveAccountDemo: (state) => state.activeAccountID === 'DEMOACC',
    isPlacementLinkedToActiveAccount: (_, getters) => (placementName: string) => {
        return getters.activeAccount?.placementLinks
            ?.map((l: string) => l.toLowerCase())
            ?.indexOf(placementName.toLowerCase())
            !== -1;
    },
    isPlacementPending: (_, getters) => (placementName: string) => {
        const index = getters.activeAccount?.pendingPlacementLinks
            ?.some((p) => {
                return p.toLowerCase() === placementName.toLowerCase();
            });
        if (index === undefined) {
            return false;
        }
        return index;
    },
};

const mutations: Record<string, (state: AccountsState, ...args: any) => void> = {
    SET_ACCOUNTS(state, accounts) {
        state.accounts = accounts;
    },
    SET_BALANCES_READY(state) {
        state.isBalancesReady = true;
    },
    SET_ACTIVE_ACCOUNT(state, id) {
        state.activeAccountID = id;
    },

    SET_ACTIVE_ACCOUNT_TRANSFER_LIMITS(state, limits) {
        state.activeAccountTransferLimit = limits;
    },
    SET_ACCOUNT_DAILY_PNL(state, { accountId, pnl }) {
        const accountIndex = state.accounts.findIndex(({ id }) => id === accountId);
        if (accountIndex >= 0) {
            const account = state.accounts[accountIndex];
            account.setDailyPnl(pnl);
            Vue.set(state.accounts, accountIndex, account);
        }
    },

    SET_ACCOUNT_NAME(state, { id: givenId, name }) {
        const currentAccount = state.accounts.find(({ id }) => id === givenId);

        if (currentAccount) {
            currentAccount.setName(name);
        }
    },
    SET_ACCOUNT_DESCRIPTION(state, { id: givenId, description }) {
        const currentAccount = state.accounts.find(({ id }) => id === givenId);

        if (currentAccount) {
            currentAccount.setDescription(description);
        }
    },
    SET_ACCOUNT_STATUS(state, { id: givenId, status }) {
        const currentAccount = state.accounts.find(({ id }) => id === givenId);

        if (currentAccount) {
            currentAccount.setStatus(status);
        }
    },
    REMOVE_ACCOUNT(state, { id: givenId }) {
        const currentAccountIndex = state.accounts.findIndex(({ id }) => id === givenId);

        if (currentAccountIndex !== -1) {
            state.accounts.splice(currentAccountIndex, 1);
        }
    },

    SET_AVAILABLE_POLICIES(state, policies) {
        state.availablePolicies = policies;
    },
    SET_COLORS(state, colors) {
        state.colors = colors;
    },
    SET_IS_DEMO_ACCOUNT_SHAKING(state) {
        state.isDemoAccountShaking = true;
        setTimeout(() => {
            state.isDemoAccountShaking = false;
        }, 300);
    },
};

const actions = {
    setAccountName({ commit }, { id, name }) {
        commit('SET_ACCOUNT_NAME', { id, name });
    },
    setAccountDescription({ commit }, { id, description }) {
        commit('SET_ACCOUNT_DESCRIPTION', { id, description });
    },
    setAccounts({ getters, commit, dispatch }, accounts: any[]) {
        accounts = accounts.reduce<Account[]>((prev, account) => {
            try {
                prev.push(new Account(account));
            } catch {
                // code crushed because of bad data of account variable
            }
            return prev;
        }, []);

        if (UserDataManager.activeAccountId && accounts.find(({ id }) => id === UserDataManager.activeAccountId)) {
            dispatch('setActiveAccount', UserDataManager.activeAccountId);
        }

        if (getters.activeAccountID === null && accounts.length && accounts.length > 0) {
            let activeAccountId = accounts[0].id;

            if (accounts.some(({ isMain }) => isMain)) {
                activeAccountId = accounts.find(({ isMain }) => isMain).id;
            }

            dispatch('setActiveAccount', activeAccountId);
        }

        commit('SET_ACCOUNTS', accounts);
    },
    async getColorsPalette({ commit }) {
        try {
            const { data: colors } = await SettingsApi.getColorPalette();
            commit('SET_COLORS', colors);
        } catch (error) {
            // API error
        }
    },
    checkHasAccount({ state, dispatch }) {
        if (!state.accounts.some(({ id }) => id === state.activeAccountID)) {
            const main = state.accounts.find(({ name }) => name === 'main');
            if (main) {
                dispatch('setActiveAccount', main.id);
            }
        }
    },
    updateAccountsList({ dispatch }) {
        return new Promise((resolve, reject) => {
            AccountsApi.privateGetAccounts(new AccountsParams({})).then(({ data }) => {
                dispatch('setAccounts', data);
                dispatch('checkHasAccount');
                dispatch('updateAccountsDailyPnl');
                dispatch('getColorsPalette');
                resolve(data);
            }).catch((error) => {
                reject(error);
            });
        });
    },

    setAccountDailyPnl({ commit }, { accountId, pnl }) {
        commit('SET_ACCOUNT_DAILY_PNL', { accountId, pnl });
    },
    async updateAccountsDailyPnl({ getters, rootGetters, dispatch }) {
        const accountIds = [] as string[];
        getters.activeAccounts.forEach(({ id: accountId, policies }) => {
            if (policies.includes('portfolio')) {
                accountIds.push(accountId);
            }
        });
        if (accountIds.length > 0) {
            const { data: res } = await PortfolioApi.getCurrentPNL(new PNLPayload({
                accountIds,
                quotationAssetSymbol: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                pnlResultType: 'PerAccount',
            }));
            if (res.accountIds) {
                res.accountIds!.forEach((el, index) => {
                    dispatch('setAccountDailyPnl', {
                        accountId: el,
                        pnl: res.quantityList![index],
                    });
                });
            }
        }
    },
    setActiveAccount({ commit, dispatch }, id) {
        commit('SET_ACTIVE_ACCOUNT', id);
        UserDataManager.setActiveAccountId(id);

        dispatch('updateActiveAccountTransferLimits');
    },

    setActiveAccountTransferLimits({ commit }, limits) {
        commit('SET_ACTIVE_ACCOUNT_TRANSFER_LIMITS', limits);
    },
    updateActiveAccountTransferLimits({ getters, dispatch }) {
        dispatch('setActiveAccountTransferLimits', null);

        WalletsApi.privateGetAccountTransferLimit(new AccountTransferLimitRequest({
            accountId: getters.activeAccountID,
        })).then(({ data: limits }) => {
            dispatch('setActiveAccountTransferLimits', limits);
        }).catch(() => {
            // code crushed because of network error
        });
    },

    async downloadAvailablePolicies({ commit }) {
        const { data: policies } = await AccountsApi.publicGetPolicies(new PoliciesRequest({}));
        commit('SET_AVAILABLE_POLICIES', policies);
    },
    async linkPlacement({ state, dispatch, commit, rootState }, placementName: string) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            await AccountsApi.extLinkPlacement(new PlacementPayload({
                tag: rootState.Placements.placementNamesToPlacementTags.get(placementName.toUpperCase()) ?? placementName,
                id: state.activeAccountID,
            }));
            await dispatch('updateAccountsList');
            await dispatch('Notificator/showSuccessNotification', 'Placement has been successfully linked', { root: true });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

    modules: {
        Management,
    },
};
