// This file was autogenerated. Please do not change.
export interface IOperationStatus {
    status: string;
}

export default class OperationStatus {
    readonly _status: string;

    /** Description: Status of the current operation */
    get status(): string {
        return this._status;
    }

    constructor(props: IOperationStatus) {
        this._status = props.status.trim();
    }

    serialize(): IOperationStatus {
        const data: IOperationStatus = {
            status: this._status,
        };
        return data;
    }

    toJSON(): IOperationStatus {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            status: typeof this._status === 'string' && !!this._status.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
