// This file was autogenerated. Please do not change.
export interface ISpotTradesRequest {
    accountId: string;
    includeTotal?: boolean;
    orderId?: string;
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
    placementTags?: string[];
    spotAssetPairId?: number;
    spotAssetPairSymbol?: string;
}

export default class SpotTradesRequest {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _orderId: string | undefined;

    get orderId(): string | undefined {
        return this._orderId;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _placementTags: string[] | undefined;

    get placementTags(): string[] | undefined {
        return this._placementTags;
    }

    readonly _spotAssetPairId: number | undefined;

    get spotAssetPairId(): number | undefined {
        return this._spotAssetPairId;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: XLM/BTC
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    constructor(props: ISpotTradesRequest) {
        this._accountId = props.accountId.trim();
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.orderId === 'string') {
            this._orderId = props.orderId.trim();
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (props.placementTags) {
            this._placementTags = props.placementTags;
        }
        if (typeof props.spotAssetPairId === 'number') {
            this._spotAssetPairId = props.spotAssetPairId;
        }
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
    }

    serialize(): ISpotTradesRequest {
        const data: ISpotTradesRequest = {
            accountId: this._accountId,
        };
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._orderId !== 'undefined') {
            data.orderId = this._orderId;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._placementTags !== 'undefined') {
            data.placementTags = this._placementTags;
        }
        if (typeof this._spotAssetPairId !== 'undefined') {
            data.spotAssetPairId = this._spotAssetPairId;
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        return data;
    }

    toJSON(): ISpotTradesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            orderId: !this._orderId ? true : typeof this._orderId === 'string',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            placementTags: !this._placementTags ? true : this._placementTags.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            spotAssetPairId: !this._spotAssetPairId ? true : typeof this._spotAssetPairId === 'number',
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
