// This file was autogenerated. Please do not change.
export interface IGetTradeAPIKeysRequest {
    accountId?: string;
    placementTag?: string;
}

export default class GetTradeAPIKeysRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: ABCDEFGH
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _placementTag: string | undefined;

    /**
     * Example: BINANCE
     */
    get placementTag(): string | undefined {
        return this._placementTag;
    }

    constructor(props: IGetTradeAPIKeysRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
    }

    serialize(): IGetTradeAPIKeysRequest {
        const data: IGetTradeAPIKeysRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        return data;
    }

    toJSON(): IGetTradeAPIKeysRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
