import { hexToRgb } from 'Models/hexToRgb';

/**
 *
 * @type Number
 */
export const defaultChartBackgroundColorOpacity = 0.4;

/**
 *
 * @type String
 */
export const defaultChartLineColor = '#656FDB';

const RGBDefaultChartLineColor = hexToRgb(defaultChartLineColor);

/**
 *
 * @type String
 */
export const defaultChartBackgroundColor = `rgba(${RGBDefaultChartLineColor?.r}, ${RGBDefaultChartLineColor?.g}, ${RGBDefaultChartLineColor?.b}, ${defaultChartBackgroundColorOpacity})`;

/**
 * @type Object
 *
 * @property {String} line
 * @property {String} background
 */
export const defaultChartColors = {
    line: defaultChartLineColor,
    background: defaultChartBackgroundColor,
};

export const showLabelThreshold = 7;
