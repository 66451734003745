// This file was autogenerated. Please do not change.
export interface IBalancesRequest {
    accountId?: string;
    accountIds?: string[];
    assetId?: number;
    assetSymbol?: string;
    blockchainName?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
}

export default class BalancesRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: AL6RG9MWFPZ
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _accountIds: string[] | undefined;

    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _assetId: number | undefined;

    /**
     * Example: 1
     */
    get assetId(): number | undefined {
        return this._assetId;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Example: BTC
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _blockchainName: string | undefined;

    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    /**
     * Example: 1
     */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Single Broker
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: IBalancesRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        if (typeof props.assetId === 'number') {
            this._assetId = props.assetId;
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): IBalancesRequest {
        const data: IBalancesRequest = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        if (typeof this._assetId !== 'undefined') {
            data.assetId = this._assetId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): IBalancesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            assetId: !this._assetId ? true : typeof this._assetId === 'number',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
