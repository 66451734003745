import { RecordsTypes } from 'Models/transfersHistory';

const state = {
    transfers: [],

    transferTypes: [
        {
            label: 'All records',
            value: RecordsTypes.EMPTY_TRANSFER_TYPE_VALUE,
        },
        {
            label: 'Transfers',
            value: RecordsTypes.TRANSFER,
        },
        {
            label: 'Withdrawals',
            value: RecordsTypes.WITHDRAW,
        },
        {
            label: 'Deposits',
            value: RecordsTypes.DEPOSIT,
        },
    ],
    activeTransferTypeIndex: 0,

    activePage: 1,
    itemsPerPage: 11,
    pagesQuantity: 1,
    totalItemsCount: 0,

    transferUpdaterID: null,
    toggleAccountUpdaterID: null,
};

const getters = {
    transfers: (state) => state.transfers,

    transferTypes: (state) => state.transferTypes,
    activeTransferTypeIndex: (state) => state.activeTransferTypeIndex,
    activeTransferType: (state, getters) => getters.transferTypes[getters.activeTransferTypeIndex],
    activeTransferTypeValue: (state, getters) => (getters.activeTransferType ? getters.activeTransferType.value : RecordsTypes.EMPTY_TRANSFER_TYPE_VALUE),

    activePage: (state) => state.activePage,
    itemsPerPage: (state) => state.itemsPerPage,
    pagesQuantity: (state) => state.pagesQuantity,
    totalItemsCount: (state) => state.totalItemsCount,

    transferUpdaterID: (state) => state.transferUpdaterID,
    isTransferUpdaterActive: (state, getters) => !!getters.transferUpdaterID,
    toggleAccountUpdaterID: (state) => state.toggleAccountUpdaterID,
    isToggleAccountUpdaterActive: (state, getters) => !!getters.toggleAccountUpdaterID,
};

const mutations = {
    SET_TRANSFERS(state, transfers) {
        state.transfers = transfers;
    },
    UPDATE_TRANSFER(state, { index, data }) {
        const transfer = state.transfers[index];

        if (transfer) {
            Object.keys(data).forEach((key) => {
                transfer[key] = data[key];
            });
        }
    },

    SET_ACTIVE_PAGE(state, pageNumber) {
        state.activePage = pageNumber;
    },
    SET_ACTIVE_TRANSFER_TYPE_INDEX(state, index) {
        state.activeTransferTypeIndex = index;
    },
    SET_ITEMS_PER_PAGE(state, itemsPerPage) {
        state.itemsPerPage = itemsPerPage;
    },
    SET_PAGES_QUANTITY(state, pagesQuantity) {
        state.pagesQuantity = pagesQuantity;
    },
    SET_TOTAL_ITEMS_COUNT(state, totalItemsCount) {
        state.totalItemsCount = totalItemsCount;
    },

    SET_TRANSFER_UPDATER_ID(state, id) {
        state.transferUpdaterID = id;
    },
    SET_TOGGLE_ACCOUNT_UPDATER_ID(state, id) {
        state.toggleAccountUpdaterID = id;
    },
};

const actions = {
    setTransfersList({ commit }, transfers) {
        commit('SET_TRANSFERS', transfers);
    },
    setPaginationData({ commit }, headers) {
        commit('SET_PAGES_QUANTITY', headers['x-total-pages'] ? +headers['x-total-pages'] : 1);
        commit('SET_TOTAL_ITEMS_COUNT', headers['x-total-count'] ? +headers['x-total-count'] : 1);
    },
    setTransfersUpdatesListenerId({ commit }, listenerId) {
        commit('SET_TRANSFER_UPDATER_ID', listenerId);
    },
    unsubscribeTransfersUpdates({ getters, dispatch }) {
        dispatch('PrivateDataSource/unsubscribe', getters.transferUpdaterID, { root: true });
    },
    setTransfersListUpdater({ getters, dispatch }) {
        if (getters.isTransferUpdaterActive) {
            dispatch('unsubscribeTransfersUpdates');
        }

        dispatch('subscribeTransfersUpdates');

        dispatch('VuexEventListener/addActionListener', {
            type: 'Accounts/setActiveAccount',
            callback: () => {
                dispatch('unsubscribeTransfersUpdates');
                dispatch('subscribeTransfersUpdates');
            },
        }, { root: true });
    },

    destroyWalletsModule({ getters, commit, dispatch }) {
        dispatch('unsubscribeTransfersUpdates');
        commit('SET_TRANSFER_UPDATER_ID', null);

        if (getters.isToggleAccountUpdaterActive) {
            dispatch('VuexEventListener/removeActionListener', {
                type: 'Accounts/setActiveAccount',
                id: getters.toggleAccountUpdaterID,
            }, { root: true }).then(() => {
                commit('SET_TOGGLE_ACCOUNT_UPDATER_ID', null);
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
