import AttributesValues, { IAttributesValues } from './AttributesValues';

// This file was autogenerated. Please do not change.
export interface ITradeAttributes {
    baseAsset?: IAttributesValues;
    price?: IAttributesValues;
    quoteAsset?: IAttributesValues;
}

export default class TradeAttributes {
    readonly _baseAsset: AttributesValues | undefined;

    get baseAsset(): AttributesValues | undefined {
        return this._baseAsset;
    }

    readonly _price: AttributesValues | undefined;

    get price(): AttributesValues | undefined {
        return this._price;
    }

    readonly _quoteAsset: AttributesValues | undefined;

    get quoteAsset(): AttributesValues | undefined {
        return this._quoteAsset;
    }

    constructor(props: ITradeAttributes) {
        if (props.baseAsset) {
            this._baseAsset = new AttributesValues(props.baseAsset);
        }
        if (props.price) {
            this._price = new AttributesValues(props.price);
        }
        if (props.quoteAsset) {
            this._quoteAsset = new AttributesValues(props.quoteAsset);
        }
    }

    serialize(): ITradeAttributes {
        const data: ITradeAttributes = {
        };
        if (typeof this._baseAsset !== 'undefined') {
            data.baseAsset = this._baseAsset.serialize();
        }
        if (typeof this._price !== 'undefined') {
            data.price = this._price.serialize();
        }
        if (typeof this._quoteAsset !== 'undefined') {
            data.quoteAsset = this._quoteAsset.serialize();
        }
        return data;
    }

    toJSON(): ITradeAttributes {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            baseAsset: !this._baseAsset ? true : this._baseAsset.validate().length === 0,
            price: !this._price ? true : this._price.validate().length === 0,
            quoteAsset: !this._quoteAsset ? true : this._quoteAsset.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
