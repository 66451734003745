// This file was autogenerated. Please do not change.
export interface IUserNotification {
    createdAt: string;
    message: string;
}

export default class UserNotification {
    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _message: string;

    /**
     * Example: {"type": "account_restored", "issuer": {"Id": "UG2KHWCEH", "lastName": "A.", "firstName": "Roman"}, "account": {"id": "AH9KAAPK4XR"}}
     */
    get message(): string {
        return this._message;
    }

    constructor(props: IUserNotification) {
        this._createdAt = props.createdAt.trim();
        this._message = props.message.trim();
    }

    serialize(): IUserNotification {
        const data: IUserNotification = {
            createdAt: this._createdAt,
            message: this._message,
        };
        return data;
    }

    toJSON(): IUserNotification {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            message: typeof this._message === 'string' && !!this._message.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
