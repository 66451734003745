import ApiError from 'Entities/ApiError';
import ExpectedRebatesRequest from 'Entities/privatePresenter/ExpectedRebatesRequest';
import PaidFeeQuotation from 'Entities/privatePresenter/PaidFeeQuotation';
import PaidRebate from 'Entities/privatePresenter/PaidRebate';
import PaidRebatesRequest from 'Entities/privatePresenter/PaidRebatesRequest';
import TotalPaidRebatesRequest from 'Entities/privatePresenter/TotalPaidRebatesRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class RebatesApi {
    static async privateGetExpectedRebates(expectedrebatesrequest: ExpectedRebatesRequest, withHeaders?: boolean): Promise<{ data: PaidFeeQuotation[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...expectedrebatesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetExpectedRebates', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getExpectedRebates`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(expectedrebatesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new PaidFeeQuotation(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Rebates/privateGetExpectedRebates', { response: res, data: result, fields: [JSON.stringify({ expectedrebatesrequest })] });
    }

    static async privateGetPaidRebates(paidrebatesrequest: PaidRebatesRequest, withHeaders?: boolean): Promise<{ data: PaidRebate[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...paidrebatesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetPaidRebates', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getPaidRebates`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(paidrebatesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new PaidRebate(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Rebates/privateGetPaidRebates', { response: res, data: result, fields: [JSON.stringify({ paidrebatesrequest })] });
    }

    static async privateGetPlacementExpectedRebates(expectedrebatesrequest: ExpectedRebatesRequest, withHeaders?: boolean): Promise<{ data: PaidFeeQuotation[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...expectedrebatesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetPlacementExpectedRebates', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getPlacementExpectedRebates`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(expectedrebatesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new PaidFeeQuotation(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Rebates/privateGetPlacementExpectedRebates', { response: res, data: result, fields: [JSON.stringify({ expectedrebatesrequest })] });
    }

    static async privateGetTotalExpectedRebates(expectedrebatesrequest: ExpectedRebatesRequest, withHeaders?: boolean): Promise<{ data: PaidFeeQuotation[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...expectedrebatesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetTotalExpectedRebates', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getTotalExpectedRebates`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(expectedrebatesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new PaidFeeQuotation(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Rebates/privateGetTotalExpectedRebates', { response: res, data: result, fields: [JSON.stringify({ expectedrebatesrequest })] });
    }

    static async privateGetTotalPaidRebates(totalpaidrebatesrequest: TotalPaidRebatesRequest, withHeaders?: boolean): Promise<{ data: PaidRebate[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...totalpaidrebatesrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetTotalPaidRebates', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getTotalPaidRebates`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(totalpaidrebatesrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new PaidRebate(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Rebates/privateGetTotalPaidRebates', { response: res, data: result, fields: [JSON.stringify({ totalpaidrebatesrequest })] });
    }
}
