import FiatTransferDetails, { IFiatTransferDetails } from './FiatTransferDetails';

// This file was autogenerated. Please do not change.
export interface IWithdrawalRequestData {
    accountId: string;
    address?: string;
    amount: number;
    assetSymbol: string;
    blockchainName?: string;
    clientId?: string;
    emailCode: string;
    fee: number;
    fiatTransferDetails?: IFiatTransferDetails;
    memo?: string;
    totp: string;
}

export default class WithdrawalRequestData {
    readonly _accountId: string;

    /** Description: Platform account UID */
    get accountId(): string {
        return this._accountId;
    }

    readonly _address: string | undefined;

    /** Description: External address */
    get address(): string | undefined {
        return this._address;
    }

    readonly _amount: number;

    /** Description: Amount of an asset to withdraw */
    get amount(): number {
        return this._amount;
    }

    readonly _assetSymbol: string;

    /** Description: Wallet asset symbol */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _blockchainName: string | undefined;

    /** Description: Blockchain name */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _clientId: string | undefined;

    /** Description: Custom Transfer ID */
    get clientId(): string | undefined {
        return this._clientId;
    }

    readonly _emailCode: string;

    /** Description: Email OTP code */
    get emailCode(): string {
        return this._emailCode;
    }

    readonly _fee: number;

    /** Description: Withdrawal fee value */
    get fee(): number {
        return this._fee;
    }

    readonly _fiatTransferDetails: FiatTransferDetails | undefined;

    get fiatTransferDetails(): FiatTransferDetails | undefined {
        return this._fiatTransferDetails;
    }

    readonly _memo: string | undefined;

    /** Description: External address tag (memo), if required */
    get memo(): string | undefined {
        return this._memo;
    }

    readonly _totp: string;

    /** Description: Software TOTP code (provided by Google Auth) */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IWithdrawalRequestData) {
        this._accountId = props.accountId.trim();
        if (typeof props.address === 'string') {
            this._address = props.address.trim();
        }
        this._amount = props.amount;
        this._assetSymbol = props.assetSymbol.trim();
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.clientId === 'string') {
            this._clientId = props.clientId.trim();
        }
        this._emailCode = props.emailCode.trim();
        this._fee = props.fee;
        if (props.fiatTransferDetails) {
            this._fiatTransferDetails = new FiatTransferDetails(props.fiatTransferDetails);
        }
        if (typeof props.memo === 'string') {
            this._memo = props.memo.trim();
        }
        this._totp = props.totp.trim();
    }

    serialize(): IWithdrawalRequestData {
        const data: IWithdrawalRequestData = {
            accountId: this._accountId,
            amount: this._amount,
            assetSymbol: this._assetSymbol,
            emailCode: this._emailCode,
            fee: this._fee,
            totp: this._totp,
        };
        if (typeof this._address !== 'undefined') {
            data.address = this._address;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._clientId !== 'undefined') {
            data.clientId = this._clientId;
        }
        if (typeof this._fiatTransferDetails !== 'undefined') {
            data.fiatTransferDetails = this._fiatTransferDetails.serialize();
        }
        if (typeof this._memo !== 'undefined') {
            data.memo = this._memo;
        }
        return data;
    }

    toJSON(): IWithdrawalRequestData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            amount: typeof this._amount === 'number',
            address: !this._address ? true : typeof this._address === 'string',
            memo: !this._memo ? true : typeof this._memo === 'string',
            fiatTransferDetails: !this._fiatTransferDetails ? true : this._fiatTransferDetails.validate().length === 0,
            fee: typeof this._fee === 'number',
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
            clientId: !this._clientId ? true : typeof this._clientId === 'string',
            emailCode: typeof this._emailCode === 'string' && !!this._emailCode.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
