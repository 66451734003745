// This file was autogenerated. Please do not change.
export interface IExpectedRebatesRequest {
    accountId?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementTag?: string;
    quotationAsset: string;
}

export default class ExpectedRebatesRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementTag: string | undefined;

    /**
     * Example: BINANCE
     */
    get placementTag(): string | undefined {
        return this._placementTag;
    }

    readonly _quotationAsset: string;

    /**
     * Example: USD
     */
    get quotationAsset(): string {
        return this._quotationAsset;
    }

    constructor(props: IExpectedRebatesRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
        this._quotationAsset = props.quotationAsset.trim();
    }

    serialize(): IExpectedRebatesRequest {
        const data: IExpectedRebatesRequest = {
            quotationAsset: this._quotationAsset,
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        return data;
    }

    toJSON(): IExpectedRebatesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
            quotationAsset: typeof this._quotationAsset === 'string' && !!this._quotationAsset.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
