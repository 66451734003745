<template>
  <li :class="s.item">
    <span :class="s.count" v-if="notificationsCount > 0">
        <span>
            {{ notificationsCount }}
        </span>
    </span>
    <popper
      trigger="clickToOpen"
      :options="{
        placement: 'bottom',
      }"
      :class="s.noPadding"
    >
      <div class="popper">
        <NotificationsPopover :notifications="notifications" />
      </div>
      <div :class="s.noPadding" slot="reference">
        <Icon
            small
            icon="bell"
            :class="s.icon"
        />
      </div>
    </popper>
  </li>
</template>

<script>
import { NotificationTypes } from 'Models/notifications';
import Icon from 'UI/Icon.vue';

import NotificationsPopover from './NotificationsPopover.vue';

export default {
    name: 'Notifications',
    components: {
        NotificationsPopover,
        Icon,
    },
    computed: {
        commonNotifications() {
            return this.$store.state.Notifications.notifications;
        },
        userRequests() {
            return this.$store.state.Notifications.requests;
        },
        groupRequests() {
            return this.$store.state.Notifications.groupsRequests;
        },
        groupsRequests() {
            return this.$store.state.Notifications.groupsRequests;
        },
        parsedCommonNotifications() {
            return this.commonNotifications.map((notification) => ({
                type: NotificationTypes.COMMON_NOTIFICATION,
                data: notification,
            }));
        },
        userManagementRequestsNotifications() {
            return this.userRequests.map((request) => ({
                type: NotificationTypes.INCOMING_ACCOUNT_MANAGEMENT_REQUEST,
                data: request,
            }));
        },
        userGroupsRequestsNotifications() {
            return this.groupsRequests.map((request) => ({
                type: NotificationTypes.INCOMING_GROUP_REQUEST,
                data: request,
            }));
        },
        notificationsCount() {
            return this.userRequests.length + this.groupsRequests.length;
        },
        notifications() {
            return [
                ...this.userManagementRequestsNotifications,
                ...this.userGroupsRequestsNotifications,
                ...this.parsedCommonNotifications,
            ];
        },
    },
};
</script>

<style lang="postcss" module="s">
.item {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 53px;
    border-left: 1px solid var(--cl-gray-light);
    cursor: pointer;
    color: var(--cl-gray);
    position: relative;
    outline: none;
    & .count {
        position: absolute;
        left: 50%;
        bottom: 50%;
        width: 14px;
        height: 14px;
        border-radius: 50%;
        background: var(--cl-violet);
        color: white;
        text-align: center;
        font-size: var(--fs-m);
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & .noPadding {
        padding: 0 !important;
        width: 100%;
        height: 100%;
    }
    & .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
