// This file was autogenerated. Please do not change.
export interface IPrivatePasswordPayload {
    newPassword: string;
    oldPassword: string;
    sessionId: string;
}

export default class PrivatePasswordPayload {
    readonly _newPassword: string;

    /** Description: New password */
    get newPassword(): string {
        return this._newPassword;
    }

    readonly _oldPassword: string;

    /** Description: Old password */
    get oldPassword(): string {
        return this._oldPassword;
    }

    readonly _sessionId: string;

    /** Description: Current user's session Id */
    get sessionId(): string {
        return this._sessionId;
    }

    constructor(props: IPrivatePasswordPayload) {
        this._newPassword = props.newPassword.trim();
        this._oldPassword = props.oldPassword.trim();
        this._sessionId = props.sessionId.trim();
    }

    serialize(): IPrivatePasswordPayload {
        const data: IPrivatePasswordPayload = {
            newPassword: this._newPassword,
            oldPassword: this._oldPassword,
            sessionId: this._sessionId,
        };
        return data;
    }

    toJSON(): IPrivatePasswordPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            oldPassword: typeof this._oldPassword === 'string' && !!this._oldPassword.trim(),
            newPassword: typeof this._newPassword === 'string' && !!this._newPassword.trim(),
            sessionId: typeof this._sessionId === 'string' && !!this._sessionId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
