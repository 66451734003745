import { OAuthResponseType } from '@okta/okta-auth-js';

export default {
    issuer: process.env.VUE_APP_OKTA_ISSUER!,
    clientId: process.env.VUE_APP_OKTA_CLIENT_ID,
    redirectUri: `${window.location.origin}/auth/redirect/login`,

    responseType: 'code' as OAuthResponseType,
    pkce: true,
    scopes: ['openid', 'email', 'profile', 'groups'],

    tokenManager: {
        autoRenew: true,
        expireEarlySeconds: 120,
    },
};
