// This file was autogenerated. Please do not change.
export interface IAssetsRequest {
    blockchainId?: number;
    blockchainName?: string;
    fromPlacementName?: string;
    includeTotal?: boolean;
    includeTransferDetails?: boolean;
    isQuotationAsset?: boolean;
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
    toPlacementName?: string;
    transferable?: boolean;
    type?: ('crypto-spot' | 'crypto-stable-coin' | 'fiat');
}

export default class AssetsRequest {
    readonly _blockchainId: number | undefined;

    /**
     * Description: Blockchain platform ID (will be ignored if transferable or isQuotationAsset flag is set)
     * Example: 1
     */
    get blockchainId(): number | undefined {
        return this._blockchainId;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Description: Common blockchain name, e.g. BTC, ETH, etc. (will be ignored if transferable or isQuotationAsset flag is set)
     * Example: BTC
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _fromPlacementName: string | undefined;

    /**
     * Description: Name of source placement (custody or CEx), e.g. Single Broker, Binance, etc. (transferable flag must be set)
     * Example: Single Broker
     */
    get fromPlacementName(): string | undefined {
        return this._fromPlacementName;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _includeTransferDetails: boolean | undefined;

    /**
     * Example: true
     */
    get includeTransferDetails(): boolean | undefined {
        return this._includeTransferDetails;
    }

    readonly _isQuotationAsset: boolean | undefined;

    /**
     * Description: If set, will return assets with is_quotation_asset = true
     * Example: true
     */
    get isQuotationAsset(): boolean | undefined {
        return this._isQuotationAsset;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    /**
     * Description: Placement platform ID (will be ignored if transferable or isQuotationAsset flag is set)
     * Example: 1
     */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Description: Placement platform Name (will be ignored if transferable or isQuotationAsset flag is set)
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _toPlacementName: string | undefined;

    /**
     * Description: Name of destination placement (custody or CEx), e.g. Single Broker, Binance, etc. (transferable flag must be set)
     * Example: Binance
     */
    get toPlacementName(): string | undefined {
        return this._toPlacementName;
    }

    readonly _transferable: boolean | undefined;

    /**
     * Description: Transferability flag (fromPlacementName must be set)
     * Example: true
     */
    get transferable(): boolean | undefined {
        return this._transferable;
    }

    readonly _type: ('crypto-spot' | 'crypto-stable-coin' | 'fiat') | undefined;

    /** Description: Asset platform type */
    get type(): ('crypto-spot' | 'crypto-stable-coin' | 'fiat') | undefined {
        return this._type;
    }

    constructor(props: IAssetsRequest) {
        if (typeof props.blockchainId === 'number') {
            this._blockchainId = props.blockchainId;
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.fromPlacementName === 'string') {
            this._fromPlacementName = props.fromPlacementName.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.includeTransferDetails === 'boolean') {
            this._includeTransferDetails = props.includeTransferDetails;
        }
        if (typeof props.isQuotationAsset === 'boolean') {
            this._isQuotationAsset = props.isQuotationAsset;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.toPlacementName === 'string') {
            this._toPlacementName = props.toPlacementName.trim();
        }
        if (typeof props.transferable === 'boolean') {
            this._transferable = props.transferable;
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): IAssetsRequest {
        const data: IAssetsRequest = {
        };
        if (typeof this._blockchainId !== 'undefined') {
            data.blockchainId = this._blockchainId;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._fromPlacementName !== 'undefined') {
            data.fromPlacementName = this._fromPlacementName;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._includeTransferDetails !== 'undefined') {
            data.includeTransferDetails = this._includeTransferDetails;
        }
        if (typeof this._isQuotationAsset !== 'undefined') {
            data.isQuotationAsset = this._isQuotationAsset;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._toPlacementName !== 'undefined') {
            data.toPlacementName = this._toPlacementName;
        }
        if (typeof this._transferable !== 'undefined') {
            data.transferable = this._transferable;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IAssetsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            blockchainId: !this._blockchainId ? true : typeof this._blockchainId === 'number',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            fromPlacementName: !this._fromPlacementName ? true : typeof this._fromPlacementName === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            includeTransferDetails: !this._includeTransferDetails ? true : typeof this._includeTransferDetails === 'boolean',
            isQuotationAsset: !this._isQuotationAsset ? true : typeof this._isQuotationAsset === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            toPlacementName: !this._toPlacementName ? true : typeof this._toPlacementName === 'string',
            transferable: !this._transferable ? true : typeof this._transferable === 'boolean',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
