import { Action } from 'vuex';

import StatementsApi from 'Apis/Statements';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import { actionCreator, mutationCreator } from 'Store/utils';
import StatementRequest from 'Entities/statementsPresenter/StatementRequest';
import DexTransaction from 'Entities/statementsPresenter/DexTransaction';
import SpotTrade from 'Entities/statementsPresenter/SpotTrade';
import Transfer from 'Entities/statementsPresenter/Transfer';
import FuturesTrade from 'Entities/statementsPresenter/FuturesTrade';
import FuturesFunding from 'Entities/statementsPresenter/FuturesFunding';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';

export enum TabType {
    SPOT_TRADES = 'SPOT_TRADES',
    FUTURES_TRADES = 'FUTURES_TRADES',
    FUTURES_FUNDINGS = 'FUTURES_FUNDINGS',
    SWAPS = 'SWAPS',
    LIQUIDITY_POOLS = 'LIQUIDITY_POOLS',
    TRANSFERS = 'TRANSFERS',
}
export interface StatementsUI {
    dataType: TabType,
    transactionsPagination: ReturnType<typeof parsePaginationHeaders>,
    isLoading: boolean;
}

const state = {
    ui: {
        dataType: TabType.SPOT_TRADES,
        transactionsPagination: {
            page: 1,
            totalPage: -1,
        },
        isLoading: false,
    } as StatementsUI,
    cardsData: {
        header: {
            total: 0,
            free: 0,
            hold: 0,
            pnl: 0,
        },
        underManagment: {
            start: 0,
            end: 0,
        },
        accountFunds: {
            deposits: 0,
            withdrawal: 0,
        },
        tradingPerformance: {
            spotVolume: 0,
            futuresVolume: 0,
            futuresFundings: 0,
            dexVolume: 0,
            spotTrades: 0,
            spotOrders: 0,
            dexSwaps: 0,
            futuresOrders: 0,
            futuresTrades: 0,
            futuresLiquidations: 0,
        },
        fees: {
            transfer: 0,
            cex: 0,
            futures: 0,
            dex: 0,
        },
    },
    spotTrades: [] as SpotTrade[],
    futuresTrades: [] as FuturesTrade[],
    futuresFundings: [] as FuturesFunding[],
    swaps: [] as DexTransaction[],
    liquidityPools: [] as DexTransaction[],
    transfers: [] as Transfer[],
};

export type StatementsState = typeof state;

export enum StatementsMutations {
    SET_UI = 'SET_UI',
    SET_CARDS_DATA = 'SET_CARDS_DATA',
    SET_SPOT_TRADES = 'SET_SPOT_TRADES',
    SET_FUTURES_TRADES = 'SET_FUTURES_TRADES',
    SET_FUTURES_FUNDINGS = 'SET_FUTURES_FUNDINGS',
    SET_SWAPS = 'SET_SWAPS',
    SET_LIQUIDITY_POOLS = 'SET_LIQUIDITY_POOLS',
    SET_TRANSFERS = 'SET_TRANSFERS',
    SET_TOTAL_PAGES = 'SET_TOTAL_PAGES',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
}

export const SET_UI = mutationCreator<Partial<StatementsUI>>('Statements', StatementsMutations.SET_UI);
export const SET_CARDS_DATA = mutationCreator<StatementsState['cardsData']>('Statements', StatementsMutations.SET_CARDS_DATA);
export const SET_SPOT_TRADES = mutationCreator<SpotTrade[]>('Statements', StatementsMutations.SET_SPOT_TRADES);
export const SET_FUTURES_TRADES = mutationCreator<FuturesTrade[]>('Statements', StatementsMutations.SET_FUTURES_TRADES);
export const SET_FUTURES_FUNDINGS = mutationCreator<FuturesFunding[]>('Statements', StatementsMutations.SET_FUTURES_FUNDINGS);
export const SET_SWAPS = mutationCreator<DexTransaction[]>('Statements', StatementsMutations.SET_SWAPS);
export const SET_LIQUIDITY_POOLS = mutationCreator<DexTransaction[]>('Statements', StatementsMutations.SET_LIQUIDITY_POOLS);
export const SET_TRANSFERS = mutationCreator<Transfer[]>('Statements', StatementsMutations.SET_TRANSFERS);
export const SET_TOTAL_PAGES = mutationCreator<number>('Statements', StatementsMutations.SET_TOTAL_PAGES);
export const SET_CURRENT_PAGE = mutationCreator<number>('Statements', StatementsMutations.SET_CURRENT_PAGE);

const mutations: Record<StatementsMutations, (state: StatementsState, ...args: any) => void> = {
    SET_UI(state, { payload }: ReturnType<typeof SET_UI>) {
        state.ui = { ...state.ui, ...payload };
    },
    SET_CARDS_DATA(state, { payload }: ReturnType<typeof SET_CARDS_DATA>) {
        state.cardsData = payload;
    },
    SET_SPOT_TRADES(state, { payload }: ReturnType<typeof SET_SPOT_TRADES>) {
        state.spotTrades = payload;
    },
    SET_FUTURES_TRADES(state, { payload }: ReturnType<typeof SET_FUTURES_TRADES>) {
        state.futuresTrades = payload;
    },
    SET_FUTURES_FUNDINGS(state, { payload }: ReturnType<typeof SET_FUTURES_FUNDINGS>) {
        state.futuresFundings = payload;
    },
    SET_SWAPS(state, { payload }: ReturnType<typeof SET_SWAPS>) {
        state.swaps = payload;
    },
    SET_LIQUIDITY_POOLS(state, { payload }: ReturnType<typeof SET_LIQUIDITY_POOLS>) {
        state.liquidityPools = payload;
    },
    SET_TRANSFERS(state, { payload }: ReturnType<typeof SET_TRANSFERS>) {
        state.transfers = payload;
    },
    SET_TOTAL_PAGES(state, { payload }: ReturnType<typeof SET_TOTAL_PAGES>) {
        state.ui.transactionsPagination.totalPage = payload;
    },
    SET_CURRENT_PAGE(state, { payload }: ReturnType<typeof SET_CURRENT_PAGE>) {
        state.ui.transactionsPagination.page = payload;
    },
};

export enum StatementsActions {
    init = 'init',
    getData = 'getData',
    getNewPage = 'getNewPage',
}

export const init = actionCreator<{ start: Date, end: Date }>('Statements', StatementsActions.init);
export const getData = actionCreator<{ start: Date, end: Date }>('Statements', StatementsActions.getData);
export const getNewPage = actionCreator<{ start: Date, end: Date, page: number }>('Statements', StatementsActions.getNewPage);

const actions: Record<StatementsActions, Action<StatementsState, any>> = {
    async init({ commit, rootGetters }, { payload: { start, end } }: ReturnType<typeof init>) {
        const accountId = rootGetters['Accounts/activeAccountID'];
        const quote = rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
        const req = new StatementRequest({
            accountId,
            quotationAsset: quote,
            from: start.valueOf(),
            to: end.valueOf(),
        });
        try {
            state.ui.isLoading = true;
            commit(SET_LOADING_ON(undefined, false), { root: true });
            // load cards data
            const { data: header } = await StatementsApi.privateStatementsSummaryGetCurrentBalance(req);
            const { data: spotSummary } = await StatementsApi.privateStatementsOrdersGetSpotSummary(req);
            const { data: underManagment } = await StatementsApi.privateStatementsSummaryGetBalanceHistory(req);
            const { data: accountFunds } = await StatementsApi.privateStatementsTransfersGetTypes(req);
            const { data: ordersTotal } = await StatementsApi.privateStatementsOrdersGetSpotTotal(req);
            const { data: futuresTotal } = await StatementsApi.privateStatementsOrdersGetFuturesSummary(req);
            const { data: fundings } = await StatementsApi.privateStatementsFundingsGetFuturesTotal(req);
            const { data: tradesTotal } = await StatementsApi.privateStatementsTradesGetSpotTotal(req);
            const { data: dex } = await StatementsApi.privateStatementsDexGetTransactionsTotal(req);
            const { data: transferFee } = await StatementsApi.privateStatementsTransfersGetCommission(req);
            const { data: pnl } = await StatementsApi.privateStatementsSummaryGetFinances(req);

            const cardsData = {
                header: {
                    total: (header.free || 0) + (header.hold || 0),
                    free: header.free || 0,
                    hold: header.hold || 0,
                    pnl: pnl.profit || 0,
                },
                underManagment: {
                    start: underManagment.start?.quantity || 0,
                    end: underManagment.end?.quantity || 0,
                },
                accountFunds: {
                    deposits: accountFunds.find((e) => e.type === 'deposit')?.quantity || 0,
                    withdrawal: accountFunds.find((e) => e.type === 'withdrawal')?.quantity || 0,
                },
                tradingPerformance: {
                    spotVolume: Number(tradesTotal.totalQuantity),
                    futuresVolume: Number(futuresTotal.executedQuantity),
                    futuresFundings: fundings.reduce((accum, { quantity }) => accum + (Number(quantity) ?? 0), 0),
                    dexVolume: Number(dex.aQuantityQuoted),
                    spotOrders: Number(spotSummary.spotCount),
                    spotTrades: Number(spotSummary.spotTotal?.tradesCount ?? 0),
                    futuresOrders: Number(futuresTotal.ordersCount),
                    futuresTrades: Number(futuresTotal.tradesCount),
                    futuresLiquidations: Number(futuresTotal.liquidations?.reduce((accum, current) => accum + Number(current.quotedQuantity), 0) ?? 0),
                    dexSwaps: Number(dex.transactionsCount),
                },
                fees: {
                    transfer: transferFee.quantity || 0,
                    cex: ordersTotal.commissionQuantity || 0,
                    futures: futuresTotal.commissionQuantity || 0,
                    dex: (dex.blockchainCommissionQuantity || 0) + (parseFloat(dex.exchangeCommissionQuantity || '0') || 0),
                },
            };
            commit(SET_CARDS_DATA(cardsData, true));
        } finally {
            state.ui.isLoading = false;
            commit(SET_LOADING_OFF(undefined, false), { root: true });
        }
    },
    async getData({ commit, state, rootGetters }, { payload: { start, end } }: ReturnType<typeof getData>) {
        commit(SET_CURRENT_PAGE(1, true));
        const accountId = rootGetters['Accounts/activeAccountID'];
        const quote = rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
        const req = new StatementRequest({
            accountId,
            quotationAsset: quote,
            page: state.ui.transactionsPagination.page,
            perPage: 11,
            from: start.valueOf(),
            to: end.valueOf(),
            includeTotal: true,
        });
        switch (state.ui.dataType) {
            case TabType.SPOT_TRADES: {
                const { data, headers } = await StatementsApi.privateStatementsTradesGetSpotTrades(req, true);
                commit(SET_SPOT_TRADES(data, true));
                if (headers) {
                    const totalPages = parsePaginationHeaders(headers).totalPage;
                    commit(SET_TOTAL_PAGES(totalPages || 0, true));
                }
                break;
            }
            case TabType.FUTURES_TRADES: {
                const { data, headers } = await StatementsApi.privateStatementsTradesGetFuturesTrades(req, true);
                commit(SET_FUTURES_TRADES(data, true));
                if (headers) {
                    const totalPages = parsePaginationHeaders(headers).totalPage;
                    commit(SET_TOTAL_PAGES(totalPages || 0, true));
                }
                break;
            }
            case TabType.FUTURES_FUNDINGS: {
                const { data, headers } = await StatementsApi.privateStatementsFundingsGetFutures(req, true);
                commit(SET_FUTURES_FUNDINGS(data, true));
                if (headers) {
                    const totalPages = parsePaginationHeaders(headers).totalPage;
                    commit(SET_TOTAL_PAGES(totalPages || 0, true));
                }
                break;
            }
            case TabType.SWAPS: {
                const { data, headers } = await StatementsApi.privateStatementsDexGetSwapTransactions(req, true);
                commit(SET_SWAPS(data, true));
                if (headers) {
                    const totalPages = parsePaginationHeaders(headers).totalPage;
                    commit(SET_TOTAL_PAGES(totalPages || 0, true));
                }
                break;
            }
            case TabType.LIQUIDITY_POOLS: {
                const { data, headers } = await StatementsApi.privateStatementsDexGetLiquidityTransactions(req, true);
                commit(SET_LIQUIDITY_POOLS(data, true));
                if (headers) {
                    const totalPages = parsePaginationHeaders(headers).totalPage;
                    commit(SET_TOTAL_PAGES(totalPages || 0, true));
                }
                break;
            }
            case TabType.TRANSFERS: {
                const { data, headers } = await StatementsApi.privateStatementsTransfersGetTransfers(req, true);
                commit(SET_TRANSFERS(data, true));
                if (headers) {
                    const totalPages = parsePaginationHeaders(headers).totalPage;
                    commit(SET_TOTAL_PAGES(totalPages || 0, true));
                }
                break;
            }
        }
    },
    async getNewPage({ state, commit, rootGetters }, { payload }: ReturnType<typeof getNewPage>) {
        commit(SET_CURRENT_PAGE(payload.page, true));
        const accountId = rootGetters['Accounts/activeAccountID'];
        const quote = rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
        const req = new StatementRequest({
            accountId,
            quotationAsset: quote,
            page: state.ui.transactionsPagination.page,
            perPage: 11,
            from: payload.start.valueOf(),
            to: payload.end.valueOf(),
        });
        switch (state.ui.dataType) {
            case TabType.SPOT_TRADES: {
                const { data } = await StatementsApi.privateStatementsTradesGetSpotTrades(req);
                commit(SET_SPOT_TRADES(data, true));
                break;
            }
            case TabType.FUTURES_TRADES: {
                const { data } = await StatementsApi.privateStatementsTradesGetFuturesTrades(req);
                commit(SET_FUTURES_TRADES(data, true));
                break;
            }
            case TabType.FUTURES_FUNDINGS: {
                const { data } = await StatementsApi.privateStatementsFundingsGetFutures(req);
                commit(SET_FUTURES_FUNDINGS(data, true));
                break;
            }
            case TabType.SWAPS: {
                const { data } = await StatementsApi.privateStatementsDexGetSwapTransactions(req);
                commit(SET_SWAPS(data, true));
                break;
            }
            case TabType.LIQUIDITY_POOLS: {
                const { data } = await StatementsApi.privateStatementsDexGetLiquidityTransactions(req);
                commit(SET_LIQUIDITY_POOLS(data, true));
                break;
            }
            case TabType.TRANSFERS: {
                const { data } = await StatementsApi.privateStatementsTransfersGetTransfers(req);
                commit(SET_TRANSFERS(data, true));
                break;
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    getters: {},
    mutations,
    actions,
};
