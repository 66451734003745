import { TradingPriceChange, TradeSides, TradeHistoryRecord } from 'Models/tradeHistory';

const state = {
    records: [],
    maxRecordsNumber: 20,

    /**
     * @type currentConnectionInfo
     */
    currentConnectionInfo: {
        placement: null,
        pair: null,
        datasource: null,
    },
    currentStreamId: null,

    tradingPriceChange: new TradingPriceChange(),
};

const getters = {
    currentConnectionInfo: (state) => state.currentConnectionInfo,
    currentStreamId: (state) => state.currentStreamId,
    isStreamActive: (state, getters) => !!getters.currentStreamId,

    records: (state) => state.records,
    isActiveRecordsList: (state, getters) => getters.records.length > 0,
    maxRecordsNumber: (state) => state.maxRecordsNumber,

    tradingPriceChange: (state) => state.tradingPriceChange,

    parseMessage: (state, getters, rootState, rootGetters) => (message) => ({
        ...message,
        amount: rootGetters['AssetPairs/GET_ROUND_AMOUNT_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION'](message.amount),
        price: rootGetters['AssetPairs/GET_ROUND_PRICE_BY_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION'](message.price),
        date: rootGetters.getTimeString(+message.ts),
        action: TradeSides[message.side],
        quoteAsset: message.pair.split('/')[1],
    }),
};

const mutations = {
    ADD_RECORD(state, record) {
        state.records.unshift(record);

        if (state.records.length > state.maxRecordsNumber) {
            state.records.splice(state.records.length - 1, 1);
        }
    },
    SET_RECORDS(state, records) {
        state.records = records;
    },
    CLEAR_RECORDS(state) {
        state.records = [];
    },
    SET_CURRENT_CONNECTION_INFO(state, info) {
        state.currentConnectionInfo = info;
    },
    SET_CURRENT_STREAM_ID(state, listenerId) {
        state.currentStreamId = listenerId;
    },
    SET_TRADE_PRICE_CHANGE(state, tradePriceChange) {
        state.tradingPriceChange = tradePriceChange;
    },
};

const actions = {
    setCurrentConnectionInfo({ commit }, info) {
        commit('SET_CURRENT_CONNECTION_INFO', info);
    },
    setCurrentStreamId({ commit }, streamId) {
        commit('SET_CURRENT_STREAM_ID', streamId);
    },
    disconnectCurrentTradeStreamListener({ getters, dispatch }) {
        dispatch('PublicDataSource/unsubscribeTradeHistory', {
            dataSource: getters.currentConnectionInfo.datasource,
            connectionId: getters.currentStreamId,
        }, { root: true }).then(() => {
            dispatch('setCurrentStreamId', null);
        });
    },
    async setTradeStreamListener({ getters, rootGetters, dispatch }, { placementName, assetPairSymbol }) {
        if (getters.isStreamActive) {
            dispatch('disconnectCurrentTradeStreamListener');
        }

        /**
         * @type currentConnectionInfo
         */
        const currentConnectionInfo = {
            placement: placementName.toUpperCase(),
            pairSymbol: assetPairSymbol,
            datasource: rootGetters['Placements/getPlacementDataSourceByName'](placementName),
        };

        dispatch('setCurrentConnectionInfo', currentConnectionInfo);

        const resp = await dispatch('PublicDataSource/subscribeTradeHistory', {
            subscriptionDetails: {
                placement: currentConnectionInfo.placement,
                assetPair: currentConnectionInfo.pairSymbol,
            },
            callbacks: {
                update: (trade) => {
                    dispatch('tradeStreamMessageHandler', trade);
                },
            },
        }, { root: true }).then((listenerId) => {
            if (currentConnectionInfo.placement === getters.currentConnectionInfo.placement && currentConnectionInfo.pairSymbol === getters.currentConnectionInfo.pairSymbol) {
                dispatch('setCurrentStreamId', listenerId);
            } else {
                dispatch('PublicDataSource/unsubscribeTradeHistory', {
                    dataSource: currentConnectionInfo.datasource,
                    connectionId: listenerId,
                }, { root: true });
            }
        });
        return resp;
    },
    clearCurrentData({ commit }) {
        commit('CLEAR_RECORDS');
    },

    tradeStreamMessageHandler({ dispatch }, message) {
        dispatch('addRecord', message);
    },
    addRecord({ getters, commit }, record) {
        const parsedRecord = new TradeHistoryRecord(record);

        commit('ADD_RECORD', parsedRecord);

        getters.tradingPriceChange.lastTradingPrice = parsedRecord.price;
    },
    setRecords({ getters, commit }, records) {
        const result: TradeHistoryRecord[] = [];

        records.forEach((record) => {
            result.push(new TradeHistoryRecord(record));
        });

        commit('SET_RECORDS', result);

        getters.tradingPriceChange.previousTradingPrice = result[result.length - 2].price;
        getters.tradingPriceChange.lastTradingPrice = result[result.length - 1].price;
    },
    setTradePriceChange({ commit }, tradePriceChange) {
        commit('SET_TRADE_PRICE_CHANGE', tradePriceChange);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
