import { actionCreator, mutationCreator } from 'Store/utils';
import UserApi from 'Apis/User';
import NotificationsRequest from 'Entities/privatePresenter/NotificationsRequest';
import UserNotification from 'Entities/privatePresenter/UserNotification';
import AccountsApi from 'Apis/Accounts';
import AccountRequestsRequest from 'Entities/privatePresenter/AccountRequestsRequest';
import AccountRequest from 'Entities/privatePresenter/AccountRequest';
import { updateBalancesList } from 'Store/v2/Balances';
import { init } from 'Store/v2/PrivateSocketData';
import { translatePolicy } from 'Lib/utils/entitiesTranslator';
import GroupsApi from 'Apis/Groups';
import GroupRequestsParams from 'Entities/privatePresenter/GroupRequestsParams';
import GroupRequest from 'Entities/privatePresenter/GroupRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import router from '@/router';
import { getGroupInfo, getGroups } from 'Store/v2/Groups';

export const permissionsOrder = ['trading', 'allocation', 'finance', 'portfolio', 'statements', 'defi', 'earn', 'institution'];

export const notificationsMessagesResolver = (message: any): string => {
    let resultMessage = '';
    switch (message.type) {
        case 'margin_call': {
            resultMessage = `Margin call. The margin ratio of your position ${message.placementName} ${message.positionSide} ${message.instrument} in account ${message.accountId} has reached 80%`;
            break;
        }
        case 'liquidation_call': {
            resultMessage = `Liquidation call. Your position ${message.placementName} ${message.positionSide} ${message.instrument} in account ${message.accountId} has been triggered into liquidation process as your ${message.marginBalance} ${message.instrument.split('/')[1]} Margin Balance was below ${message.marginBalanceRequired} ${message.instrument.split('/')[1]} maintenance margin required.`;
            break;
        }
        case 'account_created': {
            resultMessage = `New trading account has been created for you: ${message.account.id}`;
            break;
        }
        case 'account_revoked_for_owner': {
            resultMessage = `Your owned trading account has been revoked: ${message.account.id}`;
            break;
        }
        case 'account_revoked_for_manager': {
            resultMessage = `Your managed trading account has been revoked: ${message.account.id}`;
            break;
        }
        case 'account_refused_for_owner': {
            resultMessage = `Your owned trading account has been refused: ${message.account.id}`;
            break;
        }
        case 'account_refused_for_manager': {
            resultMessage = `Your managed trading account has been refused: ${message.account.id}`;
            break;
        }
        case 'sharing_request_for_owner': {
            resultMessage = `You have sent an access request to your trading account: ${message.request?.account?.id}<br />New trading account manager: ${message.recipient.firstName} ${message.recipient.lastName}<br />Granted access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_for_manager': {
            resultMessage = `You have received an access request to your trading account: ${message.request?.account?.id}<br />Managed account owner: ${message.issuer.firstName} ${message.issuer.lastName}<br />Granted access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_admitted_for_owner': {
            resultMessage = `New manager has confirmed an access request to your trading account: ${message.request?.account?.id}<br />New trading account manager: ${message.issuer.firstName} ${message.issuer.lastName}<br />Granted access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_admitted_for_manager': {
            resultMessage = `You have confirmed an access request to managed trading account: ${message.request?.account?.id}<br />Managed account owner: ${message.recipient.firstName} ${message.recipient.lastName}<br />Granted access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_rejected_for_owner': {
            resultMessage = `New manager has rejected an access request to your trading account: ${message.request?.account?.id}<br />New trading account manager: ${message.issuer.firstName} ${message.issuer.lastName}<br />Granted access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_rejected_for_manager': {
            resultMessage = `You have rejected an access request to managed trading account: ${message.request?.account?.id}<br />Managed account owner: ${message.recipient.firstName} ${message.recipient.lastName}<br />Granted access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_cancelled_for_owner': {
            resultMessage = `You have cancelled an access request to your trading account: ${message.request?.account?.id}<br />Cancelled account manager: ${message.recipient.firstName} ${message.recipient.lastName}<br />Cancelled access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'sharing_request_cancelled_for_manager': {
            resultMessage = `The owner has cancelled an access request to the account: ${message.request?.account?.id}<br />Managed account owner: ${message.issuer.firstName} ${message.issuer.lastName}<br />Cancelled access permissions: ${message.request.policies?.sort((a, b) => permissionsOrder.indexOf(a) - permissionsOrder.indexOf(b)).map((p) => translatePolicy(p)).join(', ')}.`;
            break;
        }
        case 'group_created': {
            resultMessage = `New Institutional Group has been created for you.<br />Group name: ${message.group.name}<br />${message.group.description ? `Group description${message.group.description}` : ''}`;
            break;
        }
        case 'group_updated': {
            resultMessage = `Your Institutional Group ${message.group.name} has been updated.<br />New group name: ${message.group.name}<br />${message.group.description ? `New group description${message.group.description}` : ''}`;
            break;
        }
        case 'group_deleted': {
            resultMessage = `Institutional Group ${message.group.name} has been deleted.`;
            break;
        }
        case 'group_request_created_for_issuer': {
            resultMessage = `You have sent an access request to your Institutional Group ${message.group.name}.<br />Member: ${message.recipient.firstName} ${message.recipient.lastName}<br />Role: ${message.recipient.role}`;
            break;
        }
        case 'group_request_created_for_recipient': {
            resultMessage = `You have received an access request to the Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_member_removed_for_recipient': {
            resultMessage = `You have been removed from the Institutional Group ${message.group.name}.`;
            break;
        }
        case 'group_member_removed_for_issuer': {
            resultMessage = `You have removed a member from your institutional group ${message.group.name}.<br />Member: ${message.recipient.firstName} ${message.recipient.lastName}<br />Role: ${message.recipient.role}`;
            break;
        }
        case 'group_member_removed_for_admin': {
            resultMessage = `Member has been removed from your institutional group ${message.group.name}.<br />Member: ${message.recipient.firstName} ${message.recipient.lastName}<br />Role: ${message.recipient.role}`;
            break;
        }
        case 'group_request_accepted_for_issuer': {
            resultMessage = `Member ${message.recipient.firstName} ${message.recipient.lastName} has accepted invite to your Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_accepted_for_recipient': {
            resultMessage = `You have accepted invite to the Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_accepted_for_admin': {
            resultMessage = `Member ${message.recipient.firstName} ${message.recipient.lastName} has accepted invite to your Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_rejected_for_issuer': {
            resultMessage = `Member ${message.recipient.firstName} ${message.recipient.lastName} has rejected invite to your Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_rejected_for_recipient': {
            resultMessage = `You have rejected invite to the Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_rejected_for_group_creator': {
            resultMessage = `Member ${message.recipient.firstName} ${message.recipient.lastName} has rejected invite to your Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_cancelled_for_issuer': {
            resultMessage = `You have canceled invite to the Institutional Group ${message.group.name} with the role ${message.recipient.role}.`;
            break;
        }
        case 'group_request_cancelled_for_recipient': {
            resultMessage = `Your request for access to the Institutional Group ${message.group.name} has been cancelled.`;
            break;
        }
        case 'group_member_left': {
            resultMessage = `You have left the Institutional Group ${message.group.name}.`;
            break;
        }
        case 'group_member_left_for_admin': {
            resultMessage = `Member ${message.issuer.firstName} ${message.issuer.lastName} with the role ${message.issuer.role} has left your Institutional Group ${message.group.name}.`;
            break;
        }
        case 'group_member_updated_for_issuer': {
            resultMessage = `The role of Member ${message.recipient.firstName} ${message.recipient.lastName} in the Institutional Group ${message.group.name} has been updated to the role ${message.recipient.role}.`;
            break;
        }
        case 'group_member_updated_for_group_creator': {
            resultMessage = `The role of Member ${message.recipient.firstName} ${message.recipient.lastName} in the Institutional Group ${message.group.name} has been updated to the role ${message.recipient.role}.`;
            break;
        }
        case 'group_member_updated_for_recipient': {
            resultMessage = `Your role in the Institutional Group ${message.group.name} has been updated  to the role ${message.recipient.role}.`;
            break;
        }
        default: {
            resultMessage = '';
            break;
        }
    }
    return resultMessage;
};

interface IParsedNotification {
    createdAt: string,
    message: any,
}

const state = {
    notifications: [] as IParsedNotification[],
    requests: [] as AccountRequest[],
    outgoingRequests: [] as AccountRequest[],
    groupsRequests: [] as GroupRequest[],
};

export type NotificationsState = typeof state;

export enum NotificationsGetters {
    notifications = 'notifications',
}

type GettersReturn<G extends { [key in NotificationsGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[NotificationsGetters]> };

interface Getters {
    notifications: (state: NotificationsState, getters: GettersReturn<Getters>) => any[];
}

const getters: Getters = {
    notifications: (state) => state.notifications,
};

export enum NotificationsMutations {
    SET_NOTIFICATIONS = 'SET_NOTIFICATIONS',
    SET_REQUESTS = 'SET_REQUESTS',
    SET_OUTGOING_REQUESTS = 'SET_OUTGOING_REQUESTS',
    UNSHIFT_NOTIFICATION = 'UNSHIFT_NOTIFICATION',
    UNSHIFT_REQUEST = 'UNSHIFT_REQUEST',
    UNSHIFT_OUTGOING_REQUEST = 'UNSHIFT_OUTGOING_REQUEST',
    SET_GROUPS_REQUESTS = 'SET_GROUPS_REQUESTS',
    REMOVE_GROUP_REQUEST = 'REMOVE_GROUP_REQUEST',
}

export const SET_NOTIFICATIONS = mutationCreator<IParsedNotification[]>('Notifications', NotificationsMutations.SET_NOTIFICATIONS);
export const SET_REQUESTS = mutationCreator<AccountRequest[]>('Notifications', NotificationsMutations.SET_REQUESTS);
export const SET_OUTGOING_REQUESTS = mutationCreator<AccountRequest[]>('Notifications', NotificationsMutations.SET_OUTGOING_REQUESTS);
export const UNSHIFT_NOTIFICATION = mutationCreator<IParsedNotification>('Notifications', NotificationsMutations.UNSHIFT_NOTIFICATION);
export const UNSHIFT_REQUEST = mutationCreator<AccountRequest>('Notifications', NotificationsMutations.UNSHIFT_REQUEST);
export const UNSHIFT_OUTGOING_REQUEST = mutationCreator<AccountRequest>('Notifications', NotificationsMutations.UNSHIFT_OUTGOING_REQUEST);
export const SET_GROUPS_REQUESTS = mutationCreator<GroupRequest[]>('Notifications', NotificationsMutations.SET_GROUPS_REQUESTS);
export const REMOVE_GROUP_REQUEST = mutationCreator<string>('Notifications', NotificationsMutations.REMOVE_GROUP_REQUEST);

const mutations: Record<NotificationsMutations, (state: NotificationsState, ...args: any) => void> = {
    SET_NOTIFICATIONS(state, { payload: notifications }: ReturnType<typeof SET_NOTIFICATIONS>) {
        state.notifications = notifications;
    },
    SET_REQUESTS(state, { payload: requests }: ReturnType<typeof SET_REQUESTS>) {
        state.requests = requests;
    },
    SET_OUTGOING_REQUESTS(state, { payload: requests }: ReturnType<typeof SET_OUTGOING_REQUESTS>) {
        state.outgoingRequests = requests;
    },
    UNSHIFT_NOTIFICATION(state, { payload: notification }: ReturnType<typeof UNSHIFT_NOTIFICATION>) {
        state.notifications.unshift(notification);
    },
    UNSHIFT_REQUEST(state, { payload: request }: ReturnType<typeof UNSHIFT_REQUEST>) {
        state.requests.unshift(request);
    },
    UNSHIFT_OUTGOING_REQUEST(state, { payload: request }: ReturnType<typeof UNSHIFT_OUTGOING_REQUEST>) {
        state.outgoingRequests.unshift(request);
    },
    SET_GROUPS_REQUESTS(state, { payload: requests }) {
        state.groupsRequests = requests;
    },
    REMOVE_GROUP_REQUEST(state, { payload: requestId }) {
        const index = state.groupsRequests.findIndex(({ id }) => id === requestId);
        if (index !== -1) {
            state.groupsRequests.splice(index, 1);
        }
    },
};

export enum NotificationsActions {
    setNotificationsList = 'setNotificationsList',
    setRequestsList = 'setRequestsList',
    getNotificationsList = 'getNotificationsList',
    getRequests = 'getRequests',
    updateNotifications = 'updateNotifications',
    setOutgoingRequestsList = 'setOutgoingRequestsList',
    getGroupsRequests = 'getGroupsRequests',
}

export const setNotificationsList = actionCreator<UserNotification[]>('Notifications', NotificationsActions.setNotificationsList);
export const setRequestsList = actionCreator<AccountRequest[]>('Notifications', NotificationsActions.setRequestsList);
export const setOutgoingRequestsList = actionCreator<AccountRequest[]>('Notifications', NotificationsActions.setOutgoingRequestsList);
export const getNotificationsList = actionCreator<undefined>('Notifications', NotificationsActions.getNotificationsList);
export const getRequests = actionCreator<undefined>('Notifications', NotificationsActions.getRequests);
export const updateNotifications = actionCreator<string>('Notifications', NotificationsActions.updateNotifications);
export const getGroupsRequests = actionCreator<undefined>('Notifications', NotificationsActions.getGroupsRequests);

const actions = {
    setNotificationsList({ commit }, { payload: notifications }: ReturnType<typeof setNotificationsList>) {
        const parsedNotifications: IParsedNotification[] = notifications.map((n) => {
            const serializedNotification = n.serialize();
            serializedNotification.message = JSON.parse(serializedNotification.message);
            return serializedNotification;
        });

        commit(SET_NOTIFICATIONS(parsedNotifications.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1)), true));
    },
    setRequestsList({ commit, rootState }, { payload: requests }: ReturnType<typeof setRequestsList>) {
        const userId = rootState.User.currentUser?.id;
        commit(SET_REQUESTS(requests.filter((r) => r.recipient === userId), true));
    },
    setOutgoingRequestsList({ commit, rootState }, { payload: requests }: ReturnType<typeof setOutgoingRequestsList>) {
        const userId = rootState.User.currentUser?.id;
        commit(SET_OUTGOING_REQUESTS(requests.filter((r) => r.issuer === userId), true));
    },
    async getNotificationsList({ dispatch }) {
        // TODO: Add pagination or time filtration
        const { data: notifications } = await UserApi.privateGetNotifications(new NotificationsRequest({
            page: 1,
            perPage: 30,
        }));

        dispatch(setNotificationsList(notifications, true));
    },
    async getRequests({ dispatch }) {
        try {
            const { data: requests } = await AccountsApi.privateGetAccountRequests(new AccountRequestsRequest({
                status: 'processing',
            }));

            dispatch(setRequestsList(requests, true));
            dispatch(setOutgoingRequestsList(requests, true));
        } catch {
            dispatch(setRequestsList([], true));
            dispatch(setOutgoingRequestsList([], true));
        }
    },
    async updateNotifications({ state, commit, dispatch, rootGetters, rootState }, { payload: notification }: ReturnType<typeof updateNotifications>) {
        // unshift new info notification
        const parsedNotification = JSON.parse(notification);
        const newNotification = {
            createdAt: parsedNotification.createdAt,
            message: { ...parsedNotification },
        };
        commit(UNSHIFT_NOTIFICATION(newNotification, true));

        // update requests if it is request notification
        if (parsedNotification.type === 'sharing_request_for_manager') {
            const newRequest: AccountRequest = new AccountRequest({
                accountId: parsedNotification.request.account.id,
                id: parsedNotification.request.id,
                issuer: parsedNotification.issuer.Id,
                recipient: parsedNotification.recipient.Id,
                issuerFirstName: parsedNotification.issuer.firstName,
                issuerLastName: parsedNotification.issuer.lastName,
                role: '',
                status: 'processing',
                policies: parsedNotification.request.policies,
            });
            commit(UNSHIFT_REQUEST(newRequest, true));
        } else if (parsedNotification.type.indexOf('group') === -1 && parsedNotification.type.indexOf('request') !== -1) {
            state.requests.forEach((request, index) => {
                if (request.id === parsedNotification.request.id) {
                    state.requests.splice(index, 1);
                }
            });
        }

        // update group requests if it is request notification
        if (parsedNotification.type === 'group_request_created_for_recipient') {
            const newGroupRequest: GroupRequest = new GroupRequest({
                id: parsedNotification.groupRequest.id,
                createdAt: parsedNotification.createdAt,
                groupUid: parsedNotification.group.id,
                groupName: parsedNotification.group.name,
                issuer: parsedNotification.issuer.Id,
                issuerFirstName: parsedNotification.issuer.firstName,
                issuerLastName: parsedNotification.issuer.lastName,
                recipient: parsedNotification.recipient.Id,
                role: parsedNotification.recipient.role,
                status: 'processing',
                updatedAt: parsedNotification.createdAt,
            });
            state.groupsRequests.unshift(newGroupRequest);
        } else if (parsedNotification.type.indexOf('group') !== -1 && parsedNotification.type.indexOf('request') !== -1) {
            commit(REMOVE_GROUP_REQUEST(parsedNotification.groupRequest.id, true));
        }

        if (parsedNotification.type.indexOf('group') !== -1
            && router.currentRoute.path === '/profile/group-manage'
            && rootState.Groups.managedGroup
        ) {
            await dispatch(getGroupInfo(rootState.Groups.managedGroup.id), { root: true });
        }

        if (parsedNotification.type.indexOf('group') !== -1
            && router.currentRoute.path === '/profile/groups-list'
        ) {
            await dispatch(getGroups(undefined), { root: true });
        }

        // update outgoing requests list
        if (parsedNotification.type === 'sharing_request_for_owner') {
            const newRequest: AccountRequest = new AccountRequest({
                accountId: parsedNotification.request.account.id,
                id: parsedNotification.request.id,
                issuer: parsedNotification.issuer.Id,
                recipient: parsedNotification.recipient.Id,
                role: '',
                status: 'processing',
                policies: parsedNotification.request.policies,
                recipientFirstName: parsedNotification.recipient.firstName,
                recipientLastName: parsedNotification.recipient.lastName,
            });
            commit(UNSHIFT_OUTGOING_REQUEST(newRequest, true));
        } else if (parsedNotification.type.indexOf('request') !== -1) {
            state.outgoingRequests.forEach((request, index) => {
                if (request.id === parsedNotification.request.id) {
                    state.outgoingRequests.splice(index, 1);
                }
            });
        }

        if (parsedNotification.type === 'account_revoked_for_owner'
            || parsedNotification.type === 'account_refused_for_owner'
            || parsedNotification.type === 'account_refused_for_owner'
            || parsedNotification.type === 'sharing_request_for_owner'
            || parsedNotification.type === 'sharing_request_admitted_for_owner'
            || parsedNotification.type === 'sharing_request_rejected_for_owner'
            || parsedNotification.type === 'sharing_request_cancelled_for_owner'
        ) {
            await dispatch('Accounts/Management/updateAccountManagers', { id: rootGetters['Accounts/activeAccountID'] }, { root: true });
        }

        if (parsedNotification.type === 'account_revoked_for_manager') {
            await dispatch('Accounts/updateAccountsList', undefined, { root: true });
            await dispatch('Accounts/setActiveAccount', rootGetters['Accounts/accounts'][0].id, { root: true });
        }

        if (parsedNotification.type === 'sharing_request_admitted_for_manager') {
            await dispatch('Accounts/updateAccountsList', undefined, { root: true });
            await dispatch(updateBalancesList(undefined), { root: true });
            rootState.PrivateSocketData.centrifuge!.disconnect();
            await dispatch(init(undefined), { root: true });
        }

        if (parsedNotification.type === 'account_created' || parsedNotification.type === 'account_removed') {
            await dispatch('User/getCurrentUser', undefined, { root: true });
            await dispatch('Accounts/updateAccountsList', undefined, { root: true });
            await dispatch(updateBalancesList(undefined), { root: true });
        }
    },
    async getGroupsRequests({ commit, rootState }) {
        let allRequests: GroupRequest[];
        const { data: requests, headers } = await GroupsApi.privateGetGroupRequests(new GroupRequestsParams({
            includeTotal: true,
            page: 1,
            perPage: 100,
            status: 'processing',
        }));
        allRequests = [...requests];
        if (headers) {
            const { totalPage } = parsePaginationHeaders(headers);
            if (totalPage && totalPage > 1) {
                for (let i = 2; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraRequests } = await GroupsApi.privateGetGroupRequests(new GroupRequestsParams({
                        page: i,
                        perPage: 100,
                        status: 'processing',
                    }));
                    allRequests = [...allRequests, ...extraRequests];
                }
            }
        }
        commit(SET_GROUPS_REQUESTS(allRequests.filter(({ recipient }) => recipient === rootState.User.currentUser!.id), true));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
