import Vue from 'vue';
import { Doughnut, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default Vue.extend<any, any, any, any>({
    extends: Doughnut,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        tooltipLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelColorCallback: {
            type: Function,
            default: null,
        },
        tooltipFooterCallback: {
            type: Function,
            default: null,
        },
        layoutPaddings: {
            type: Object,
            default: null,
        },
    },
    computed: {
        tooltipsCallbacks() {
            const result: any = {};

            if (this.tooltipLabelCallback) { result.label = this.tooltipLabelCallback; }
            if (this.tooltipLabelColorCallback) { result.labelColor = this.tooltipLabelColorCallback; }
            if (this.tooltipFooterCallback) { result.footer = this.tooltipFooterCallback; }

            return result;
        },
        hasTooltips() {
            return Object.keys(this.tooltipsCallbacks).length > 0;
        },
        data() {
            const { datasets, labels } = this.chartData;

            const mappedDataset = datasets.map((dataset) => (dataset.datalabels
                ? { ...dataset }
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels,
                datasets: mappedDataset,
            };
        },
        options() {
            const result: any = {
                responsive: true,
                rotation: 0.5 * Math.PI,
                legend: {
                    display: false,
                },
                tooltips: {
                    mode: 'index',
                    intersect: true,
                    titleFontSize: 13,
                    bodyFontSize: 13,
                    footerFontSize: 13,
                },
                hover: {
                    mode: 'index',
                    intersect: true,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                    outlabels: {
                        borderRadius: 3,
                        lineWidth: 1,
                        stretch: 30,
                        font: {
                            resizable: true,
                            minSize: 8,
                            maxSize: 8,
                        },
                        padding: {
                            top: 3,
                            bottom: 3,
                            left: 3,
                            right: 3,
                        },
                    },
                },
            };

            if (this.hasTooltips) {
                result.tooltips.callbacks = this.tooltipsCallbacks;
            }

            if (this.layoutPaddings) {
                result.layout = {
                    padding: this.layoutPaddings,
                };
            }

            return result;
        },
    },
    methods: {
        Render() {
            this.renderChart(this.data, this.options);
        },
    },
    mounted() {
        this.Render();
    },
});
