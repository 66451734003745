// This file was autogenerated. Please do not change.
export interface ISwapRequest {
    aAssetSymbol: string;
    accountId: string;
    bAssetSymbol: string;
    blockchainName: string;
    fee?: string;
    placementName: string;
    quantity: string;
    slippage?: string;
}

export default class SwapRequest {
    readonly _aAssetSymbol: string;

    /**
     * Example: USDT
     */
    get aAssetSymbol(): string {
        return this._aAssetSymbol;
    }

    readonly _accountId: string;

    /**
     * Example: AKP2W4P2VHE
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _bAssetSymbol: string;

    /**
     * Example: BNB
     */
    get bAssetSymbol(): string {
        return this._bAssetSymbol;
    }

    readonly _blockchainName: string;

    /**
     * Example: BNB
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _fee: number | undefined;

    /**
     * Example: 0.01
     */
    get fee(): number | undefined {
        return this._fee;
    }

    readonly _placementName: string;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _quantity: number;

    /**
     * Example: 10
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _slippage: number | undefined;

    /**
     * Example: 1
     */
    get slippage(): number | undefined {
        return this._slippage;
    }

    constructor(props: ISwapRequest) {
        this._aAssetSymbol = props.aAssetSymbol.trim();
        this._accountId = props.accountId.trim();
        this._bAssetSymbol = props.bAssetSymbol.trim();
        this._blockchainName = props.blockchainName.trim();
        if (typeof props.fee === 'string') {
            this._fee = Number(props.fee);
        }
        this._placementName = props.placementName.trim();
        this._quantity = Number(props.quantity);
        if (typeof props.slippage === 'string') {
            this._slippage = Number(props.slippage);
        }
    }

    serialize(): ISwapRequest {
        const data: ISwapRequest = {
            aAssetSymbol: this._aAssetSymbol,
            accountId: this._accountId,
            bAssetSymbol: this._bAssetSymbol,
            blockchainName: this._blockchainName,
            placementName: this._placementName,
            quantity: String(this._quantity),
        };
        if (typeof this._fee !== 'undefined') {
            data.fee = String(this._fee);
        }
        if (typeof this._slippage !== 'undefined') {
            data.slippage = String(this._slippage);
        }
        return data;
    }

    toJSON(): ISwapRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetSymbol: typeof this._aAssetSymbol === 'string' && !!this._aAssetSymbol.trim(),
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            bAssetSymbol: typeof this._bAssetSymbol === 'string' && !!this._bAssetSymbol.trim(),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            fee: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            quantity: true,
            slippage: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
