// This file was autogenerated. Please do not change.
export interface ITransferEntry {
    accountId?: string;
    placementName?: string;
}

export default class TransferEntry {
    readonly _accountId: string | undefined;

    /** Description: Account UID */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _placementName: string | undefined;

    /** Description: Placement name */
    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: ITransferEntry) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): ITransferEntry {
        const data: ITransferEntry = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): ITransferEntry {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
