<template>
    <div :class="s.container">
        <div :class="s.head">
            <div :class="s.icon">
                <slot name="icon"></slot>
            </div>
            <p :class="s.title" v-html="snackbar.title"></p>
            <Icon icon="cross" @clicked="hideSnackbar" :class="s.cross" />
        </div>
        <div :class="s.body" v-if="snackbar.message" v-html="snackbar.message"></div>
    </div>
</template>

<script>
import Icon from 'UI/Icon.vue';

export default {
    name: 'SnackbarItem',
    components: {
        Icon,
    },
    props: {
        snackbar: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return `${this.snackbar.message} [${this.snackbar.id}]`;
        },
    },
    methods: {
        hideSnackbar() {
            this.$store.dispatch('Notificator/removeNotification', this.snackbar.id);
        },
        setCountdown() {
            setTimeout(() => {
                this.hideSnackbar();
            }, this.snackbar.duration);
        },
    },
    mounted() {
        if (this.snackbar.duration) {
            this.setCountdown();
        }
    },
};
</script>

<style lang="postcss" module="s">
.container {
    width: 267px;
    background: var(--cl-white);
    border-radius: 16px;
    margin-bottom: var(--m-s);
    box-shadow: 0 5px 13px rgba(0, 0, 0, 0.07);
    position: relative;
    & .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: var(--m-m) var(--m-l);
        & .icon {
            margin-right: var(--m-l);
        }
        & .title {
            margin-right: auto;
            margin-bottom: var(--m-xs);
        }
        & .cross {
            width: 24px;
            height: 24px;
            color: var(--cl-gray);
            cursor: pointer;
        }
    }
    & .body {
        padding: var(--m-l);
        border-top: 1px solid var(--cl-gray-light);
    }
}
</style>
