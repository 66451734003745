// This file was autogenerated. Please do not change.
export interface IPlacementsRequest {
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    quotationSymbol?: string;
    type?: ('self' | 'crypto-spot' | 'crypto-spot-decentralized' | 'crypto-spot-otc');
}

export default class PlacementsRequest {
    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _quotationSymbol: string | undefined;

    /**
     * Example: USD
     */
    get quotationSymbol(): string | undefined {
        return this._quotationSymbol;
    }

    readonly _type: ('self' | 'crypto-spot' | 'crypto-spot-decentralized' | 'crypto-spot-otc') | undefined;

    /**
     * Description: Placement type
     * Example: self
     */
    get type(): ('self' | 'crypto-spot' | 'crypto-spot-decentralized' | 'crypto-spot-otc') | undefined {
        return this._type;
    }

    constructor(props: IPlacementsRequest) {
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.quotationSymbol === 'string') {
            this._quotationSymbol = props.quotationSymbol.trim();
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): IPlacementsRequest {
        const data: IPlacementsRequest = {
        };
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._quotationSymbol !== 'undefined') {
            data.quotationSymbol = this._quotationSymbol;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IPlacementsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            quotationSymbol: !this._quotationSymbol ? true : typeof this._quotationSymbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
