import ApiError from 'Entities/ApiError';
import StreamChannelsRequest from 'Entities/privatePresenter/StreamChannelsRequest';
import StreamChannelsResponse from 'Entities/privatePresenter/StreamChannelsResponse';
import StreamTokenResponse from 'Entities/privatePresenter/StreamTokenResponse';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class PrivateDataApi {
    static async privateGetStreamChannels(streamchannelsrequest: StreamChannelsRequest, withHeaders?: boolean): Promise<{ data: StreamChannelsResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...streamchannelsrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateGetStreamChannels', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getStreamChannels`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(streamchannelsrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new StreamChannelsResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PrivateData/privateGetStreamChannels', { response: res, data: result, fields: [JSON.stringify({ streamchannelsrequest })] });
    }

    static async privateGetStreamToken(withHeaders?: boolean): Promise<{ data: StreamTokenResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/getStreamToken`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new StreamTokenResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PrivateData/privateGetStreamToken', { response: res, data: result, fields: [] });
    }

    static async privateRefreshStreamToken(withHeaders?: boolean): Promise<{ data: StreamTokenResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/meta/refreshStreamToken`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new StreamTokenResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('PrivateData/privateRefreshStreamToken', { response: res, data: result, fields: [] });
    }
}
