import { Action } from 'vuex';

import PublicDataApi from 'Apis/PublicData';
import AccountsApi from 'Apis/Accounts';
import DefiApi from 'Apis/DeFi';
import Placement from 'Entities/publicPresenter/Placement';
import Blockchain from 'Entities/publicPresenter/Blockchain';
import Asset from 'Entities/publicPresenter/Asset';
import PlacementsRequest from 'Entities/publicPresenter/PlacementsRequest';
import BlockchainsRequest from 'Entities/publicPresenter/BlockchainsRequest';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import BalancesRequest from 'Entities/privatePresenter/BalancesRequest';
import Balance from 'Entities/privatePresenter/Balance';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import { actionCreator, mutationCreator } from 'Store/utils';
import DexTransaction, { IDexTransaction } from 'Entities/privatePresenter/DexTransaction';
import DexTransactionsRequest from 'Entities/privatePresenter/DexTransactionsRequest';
import ApiError from 'Entities/ApiError';
import Quote from 'Entities/dex/Quote';
import QuoteParams from 'Entities/dex/QuoteParams';
import ApproveRequest from 'Entities/dex/ApproveRequest';
import GetAllowanceRequest from 'Entities/dex/GetAllowanceRequest';
import SwapRequest from 'Entities/dex/SwapRequest';
import GasPriceParams from 'Entities/dex/GasPriceParams';
import DexData from 'Entities/publicPresenter/DexData';
import RoutesParams, { IRoutesParams } from 'Entities/dex/RoutesParams';

export enum PlacementsWithCapsules {
    UniswapV2 = 'Uniswap',
    UniswapV3 = 'Uniswap',
}
export enum PlacementCapsules {
    UniswapV2 = 'V2',
    UniswapV3 = 'V3',
}

export interface DefiUI {
    placement: string; // placement name
    blockchain: string; // blockchain name
    fromAsset: string; // asset symbol
    toAsset: string; // asset symbol
    quoteIndex: number;
    slippage: number;
    isApproved: boolean;
    networkFee: {
        value: number;
        timer: number;
        timerId: NodeJS.Timeout | null;
        lastAAssetSymbol: string;
        lastBAssetSymbol: string;
        lastPlacement: string;
        lastBlockchain: string;
    };
    transactionsPagination: ReturnType<typeof parsePaginationHeaders>;
}

export enum CHECK_APPROVAL_STATUSES {
    NOT_APPROVED = 'NOT_APPROVED',
    APPROVING = 'PROCESSING',
    APPROVED = 'APPROVED',
}

export const TRANSACTIONS_STATUSES = {
    Swap: 'Swap',
    Approve: 'Approve',
    Approve_swap: 'Approve',
    Liquidity_in: 'LP In',
    Liquidity_out: 'LP Out',
    Approve_lt: 'LP Approve',
    Wrap: 'Wrap',
    Unwrap: 'Unwrap',
};

const state = {
    dexTransactions: [] as DexTransaction[],
    placements: [] as Placement[],
    blockchains: undefined as Blockchain[] | undefined,
    fromBalances: undefined as undefined | Balance[],
    assets: undefined as undefined | Asset[],
    quotes: undefined as undefined | Quote[],
    marketData: undefined as undefined | DexData[],
    gasPrice: undefined as undefined | number,
    ui: {
        placement: '',
        blockchain: '',
        fromAsset: '',
        toAsset: '',
        quoteIndex: 0,
        slippage: 1,
        isApproved: false,
        networkFee: {
            value: 0,
            timer: 10,
            timerId: null,
            lastAAssetSymbol: '',
            lastBAssetSymbol: '',
            lastPlacement: '',
            lastBlockchain: '',
        },
        transactionsPagination: {
            page: 1,
            totalPage: -1,
        },
    } as DefiUI,
};

export type DefiState = typeof state;

export enum DefiMutations {
    SET_PLACEMENTS = 'SET_PLACEMENTS',
    SET_BLOCKCHAINS = 'SET_BLOCKCHAINS',
    SET_ASSETS = 'SET_ASSETS',
    SET_UI = 'SET_UI',
    SET_BALANCES = 'SET_BALANCES',
    SET_QUOTE = 'SET_QUOTE',
    SET_DEX_TRANSACTIONS = 'SET_DEX_TRANSACTIONS',
    SET_DEX_TRANSACTION = 'SET_DEX_TRANSACTION',
    SET_MARKET_DATA = 'SET_MARKET_DATA',
    UPDATE_BALANCE = 'UPDATE_BALANCE',
    SET_TOTAL_PAGES = 'SET_TOTAL_PAGES',
    SET_CURRENT_PAGE = 'SET_CURRENT_PAGE',
    SET_NETWORK_FEE_TIMER = 'SET_NETWORK_FEE_TIMER',
    SET_NETWORK_FEE_VALUE = 'SET_NETWORK_FEE_VALUE',
}

export const SET_PLACEMENTS = mutationCreator<Placement[]>('Defi', DefiMutations.SET_PLACEMENTS);
export const SET_BLOCKCHAINS = mutationCreator<Blockchain[]>('Defi', DefiMutations.SET_BLOCKCHAINS);
export const SET_ASSETS = mutationCreator<Asset[]>('Defi', DefiMutations.SET_ASSETS);
export const SET_BALANCES = mutationCreator<Balance[]>('Defi', DefiMutations.SET_BALANCES);
export const SET_QUOTE = mutationCreator<Quote[]>('Defi', DefiMutations.SET_QUOTE);
export const SET_UI = mutationCreator<Partial<DefiUI>>('Defi', DefiMutations.SET_UI);
export const SET_DEX_TRANSACTIONS = mutationCreator<DexTransaction[]>('Defi', DefiMutations.SET_DEX_TRANSACTIONS);
export const SET_DEX_TRANSACTION = mutationCreator<IDexTransaction>('Defi', DefiMutations.SET_DEX_TRANSACTION);
export const SET_MARKET_DATA = mutationCreator<DexData[]>('Defi', DefiMutations.SET_MARKET_DATA);
export const UPDATE_BALANCE = mutationCreator<any>('Defi', DefiMutations.UPDATE_BALANCE);
export const SET_TOTAL_PAGES = mutationCreator<number>('Defi', DefiMutations.SET_TOTAL_PAGES);
export const SET_CURRENT_PAGE = mutationCreator<number>('Defi', DefiMutations.SET_CURRENT_PAGE);
export const SET_NETWORK_FEE_TIMER = mutationCreator<number>('Defi', DefiMutations.SET_NETWORK_FEE_TIMER);
export const SET_NETWORK_FEE_VALUE = mutationCreator<number>('Defi', DefiMutations.SET_NETWORK_FEE_VALUE);

const mutations: Record<DefiMutations, (state: DefiState, ...args: any) => void> = {
    SET_PLACEMENTS(state, { payload: placements }: ReturnType<typeof SET_PLACEMENTS>) {
        state.placements = placements;
    },
    SET_BLOCKCHAINS(state, { payload: blockchains }: ReturnType<typeof SET_BLOCKCHAINS>) {
        state.blockchains = blockchains;
    },
    SET_ASSETS(state, { payload: assets }: ReturnType<typeof SET_ASSETS>) {
        state.assets = assets;
    },
    SET_UI(state, { payload: ui }: ReturnType<typeof SET_UI>) {
        state.ui = { ...(state.ui || {}), ...ui };
    },
    SET_BALANCES(state, { payload: balances }: ReturnType<typeof SET_BALANCES>) {
        state.fromBalances = balances;
    },
    SET_QUOTE(state, { payload: quotes }: ReturnType<typeof SET_QUOTE>) {
        state.quotes = quotes;
    },
    SET_DEX_TRANSACTIONS(state, { payload: dexTransactions }: ReturnType<typeof SET_DEX_TRANSACTIONS>) {
        state.dexTransactions = dexTransactions;
    },
    // Used in PrivateSocketData.ts for handle socket messages
    SET_DEX_TRANSACTION(state, { payload: dexTransaction }: ReturnType<typeof SET_DEX_TRANSACTION>) {
        const index = state.dexTransactions.findIndex((t) => t.id === dexTransaction.id);
        if (index !== -1) {
            const data = state.dexTransactions[index].serialize();
            state.dexTransactions[index] = new DexTransaction({ ...data, ...dexTransaction });
            state.dexTransactions = [...state.dexTransactions];
            return;
        }
        state.dexTransactions = [new DexTransaction(dexTransaction), ...state.dexTransactions];
    },
    SET_MARKET_DATA(state, { payload: marketData }: ReturnType<typeof SET_MARKET_DATA>) {
        state.marketData = marketData;
    },
    UPDATE_BALANCE(state, data: ReturnType<typeof UPDATE_BALANCE>) {
        const balanceIndex = state.fromBalances?.findIndex((b) => b.id === data.payload.id);
        if (typeof balanceIndex === 'number' && balanceIndex !== -1) {
            let oldBalance = state.fromBalances![balanceIndex].serialize();
            oldBalance = { ...oldBalance, ...data.payload.serialize() };
            const newBalances = [...state.fromBalances!];
            newBalances[balanceIndex] = new Balance(oldBalance);
            state.fromBalances = newBalances;
        }
    },
    SET_CURRENT_PAGE(state, number: ReturnType<typeof SET_CURRENT_PAGE>) {
        state.ui.transactionsPagination.page = number.payload;
    },
    SET_TOTAL_PAGES(state, number: ReturnType<typeof SET_TOTAL_PAGES>) {
        state.ui.transactionsPagination.totalPage = number.payload;
    },
    SET_NETWORK_FEE_TIMER(state, { payload: timer }) {
        state.ui.networkFee.timer = timer;
    },
    SET_NETWORK_FEE_VALUE(state, { payload: value }) {
        state.ui.networkFee.value = value;
    },
};

export enum DefiActions {
    init = 'init',
    getPlacements = 'getPlacements',
    getBlockchains = 'getBlockchains',
    getAssests = 'getAssests',
    onUiUpdate = 'onUiUpdate',
    getQuotes = 'getQuotes',
    getDexTransactions = 'getDexTransactions',
    checkCurrencyApprove = 'checkCurrencyApprove',
    checkQuantityApprove = 'checkQuantityApprove',
    approve = 'approve',
    swap = 'swap',
    getDexMarketData = 'getDexMarketData',
    updateDexTransactions = 'updateDexTransactions',
    getGasPrice = 'getGasPrice',
    getRoutes = 'getRoutes',
    getNetworkFee = 'getNetworkFee',
    clearNetworkFeeInterval = 'clearNetworkFeeInterval',
}

export const init = actionCreator<{ withoutHistory: boolean; withoutBlockchain: boolean; }>('Defi', DefiActions.init);
export const getPlacements = actionCreator<undefined>('Defi', DefiActions.getPlacements);
export const getBlockchains = actionCreator<undefined>('Defi', DefiActions.getBlockchains);
export const getAssests = actionCreator<undefined>('Defi', DefiActions.getAssests);
export const onUiUpdate = actionCreator<DefiUI>('Defi', DefiActions.onUiUpdate);
export const getQuotes = actionCreator<number>('Defi', DefiActions.getQuotes);
export const getDexTransactions = actionCreator<undefined>('Defi', DefiActions.getDexTransactions);
export const checkCurrencyApprove = actionCreator<undefined>('Defi', DefiActions.checkCurrencyApprove);
export const checkQuantityApprove = actionCreator<string>('Defi', DefiActions.checkQuantityApprove);
export const approve = actionCreator<string>('Defi', DefiActions.approve);
export const swap = actionCreator<{ amount: string, fee: string, }>('Defi', DefiActions.swap);
export const getDexMarketData = actionCreator<undefined>('Defi', DefiActions.getDexMarketData);
export const updateDexTransactions = actionCreator<number>('Defi', DefiActions.updateDexTransactions);
export const getGasPrice = actionCreator<undefined>('Defi', DefiActions.getGasPrice);
export const getRoutes = actionCreator<IRoutesParams>('Defi', DefiActions.getRoutes);
export const getNetworkFee = actionCreator<{
    isAutoReload: boolean;
    aAssetSymbol: string;
    bAssetSymbol: string;
    placementName: string;
    blockchain: string;
    quantity: string;
}>('Defi', DefiActions.getNetworkFee);
export const clearNetworkFeeInterval = actionCreator<undefined>('Defi', DefiActions.clearNetworkFeeInterval);

const actions: Record<DefiActions, Action<DefiState, any>> = {
    async init({ dispatch, commit }, { payload: { withoutHistory, withoutBlockchain } }: ReturnType<typeof init>) {
        if (!withoutHistory) {
            await dispatch(getPlacements(undefined, true));
        }
        await dispatch(getDexTransactions(undefined, true));
        commit(SET_QUOTE([], true));
        if (!withoutBlockchain) {
            await dispatch(getBlockchains(undefined, true));
        }
        await dispatch(getAssests(undefined, true));
        await dispatch(getDexMarketData(undefined, true));
        await dispatch(getGasPrice(undefined, true));
    },
    async getPlacements({ commit, dispatch, rootState }) {
        const placementsStatuses = rootState.Placements.maintenanceStatuses;
        if (!placementsStatuses.size) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Placements/getPlacementsStatuses',
                callback: () => dispatch('getPlacements'),
                once: true,
            }, { root: true });
            return Promise.reject();
        }

        const req = new PlacementsRequest({
            type: 'crypto-spot-decentralized',
        });
        try {
            const { data: resp } = await PublicDataApi.publicGetPlacements(req);
            for (let i = 0; i < resp.length; i += 1) {
                if (placementsStatuses.get(resp[i].name)) {
                    commit(SET_UI({ placement: resp[i].name }, true));
                    break;
                }
            }
            commit(SET_PLACEMENTS(resp, true));
            return Promise.resolve();
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async getBlockchains({ commit, dispatch, state }) {
        const { ui: { placement } } = state;
        if (!placement) {
            commit(SET_BLOCKCHAINS([], true));
            return;
        }
        try {
            const { data: resp } = await PublicDataApi.publicGetBlockchains(new BlockchainsRequest({ placementName: placement }));
            const [{ name: blockchain }] = resp;
            commit(SET_UI({ blockchain }, true));
            commit(SET_BLOCKCHAINS(resp, true));
            return Promise.resolve();
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async onUiUpdate({ dispatch, commit }, { payload }: ReturnType<typeof onUiUpdate>) {
        commit(SET_UI(payload, true));
        await dispatch(getBlockchains(undefined, true));
        await dispatch(getAssests(undefined, true));
    },
    async getAssests({ commit, state, rootGetters, dispatch }) {
        if (!state.ui) {
            console.error('failed to get from/to in Defi');
            return;
        }
        const activeAccountId = rootGetters['Accounts/activeAccountID'];
        if (!activeAccountId) {
            return;
        }
        try {
            const { data: assets, headers } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                placementName: state.ui.placement,
                blockchainName: state.ui.blockchain,
                perPage: 400,
                includeTotal: true,
            }), true);
            if (headers) {
                let pagination = parsePaginationHeaders(headers);
                if (pagination.totalPage && pagination.page) {
                    while (pagination.totalPage! > pagination.page!) {
                        // eslint-disable-next-line no-await-in-loop
                        const { data: addAssets, headers } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                            placementName: state.ui.placement,
                            blockchainName: state.ui.blockchain,
                            perPage: 400,
                            includeTotal: true,
                            page: pagination.page! += 1,
                        }), true);
                        assets.push(...addAssets);
                        if (headers) {
                            pagination = parsePaginationHeaders(headers!);
                        }
                    }
                }
            }

            const { data: balances } = await AccountsApi.privateGetBalances(new BalancesRequest({
                accountId: activeAccountId,
                blockchainName: state.ui.blockchain,
                placementName: 'Single Broker',
            }));

            const assetsMap = new Map<string, Asset>();
            assets.forEach((a) => assetsMap.set(a?.symbol, a));

            const filteredBalances: Balance[] = [];

            balances.forEach((b) => {
                const asset = assetsMap.get(b.assetSymbol);
                if (asset) {
                    filteredBalances.push(b);
                }
            });

            if (filteredBalances.length === 0) {
                commit(SET_ASSETS(assets, true));
                commit(SET_BALANCES(filteredBalances, true));
                return Promise.resolve(false);
            }

            if (filteredBalances.length > 0) {
                commit(SET_ASSETS(assets, true));
                commit(SET_BALANCES(filteredBalances, true));

                const [initialBalance] = filteredBalances;
                const { assetSymbol } = initialBalance;

                let nextAsset = assets[0]?.symbol;
                if (assetSymbol === nextAsset) {
                    nextAsset = assets[1]?.symbol;
                }
                commit(SET_UI({
                    fromAsset: assetSymbol,
                    toAsset: nextAsset,
                } as Partial<DefiUI>, true));
            }

            return Promise.resolve(true);
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async getQuotes({ commit, dispatch, state }, { payload: quantity }: ReturnType<typeof getQuotes>) {
        try {
            const { data: resp } = await DefiApi.privateDexGetQuotes(new QuoteParams({
                quantity: String(quantity),
                aAssetSymbol: state.ui.fromAsset,
                bAssetSymbol: state.ui.toAsset,
                placementName: state.ui.placement,
                blockchainName: state.ui.blockchain,
            }));
            if (resp.length > 0) {
                commit(SET_UI({
                    quoteIndex: 0,
                } as Partial<DefiUI>, true));
                commit(SET_QUOTE(resp, true));
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async checkQuantityApprove({ state, dispatch, rootGetters }, { payload: quantity }: ReturnType<typeof checkQuantityApprove>) {
        try {
            const { data: resp } = await DefiApi.privateDexSwapsCheckApprovalStatus(new ApproveRequest({
                accountId: rootGetters['Accounts/activeAccountID'],
                assetSymbol: state.ui.fromAsset,
                placementName: state.ui.placement,
                blockchainName: state.ui.blockchain,
                quantity,
            }));
            state.ui.isApproved = resp.status === 'APPROVED';
            return resp;
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async checkCurrencyApprove({ state, dispatch, rootGetters }) {
        try {
            if (!state.ui.fromAsset || state.ui.placement || !state.ui.blockchain) {
                return;
            }
            const { data: resp } = await DefiApi.privateDexSwapsGetAllowance(new GetAllowanceRequest({
                accountId: rootGetters['Accounts/activeAccountID'],
                assetSymbol: state.ui.fromAsset,
                placementName: state.ui.placement,
                blockchainName: state.ui.blockchain,
            }));
            return resp;
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async getDexTransactions({ commit, dispatch, rootGetters }, { payload /** TODO: use payload later */ }: ReturnType<typeof getDexTransactions>) {
        const accountId = rootGetters['Accounts/activeAccountID'];
        if (!accountId) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: () => dispatch(getDexTransactions(undefined, true)),
                once: true,
            }, { root: true });
            return;
        }
        try {
            commit(SET_CURRENT_PAGE(1, true));
            const { data: resp, headers } = await DefiApi.privateGetDexTransactions(new DexTransactionsRequest({
                accountId: rootGetters['Accounts/activeAccountID'],
                perPage: 11,
                page: 1,
                includeTotal: true,
            }), true);
            const pageInfo = parsePaginationHeaders(headers!);
            const totalPages = parsePaginationHeaders(headers!).totalPage;
            commit(SET_UI({ transactionsPagination: pageInfo }, true));
            commit(SET_TOTAL_PAGES(totalPages!, true));
            commit(SET_DEX_TRANSACTIONS(resp, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async updateDexTransactions({ commit, rootGetters, dispatch }, pageNumber: ReturnType<typeof updateDexTransactions>) {
        try {
            const { data: resp } = await DefiApi.privateGetDexTransactions(new DexTransactionsRequest({
                accountId: rootGetters['Accounts/activeAccountID'],
                perPage: 11,
                page: pageNumber.payload,
            }), true);
            commit(SET_CURRENT_PAGE(pageNumber.payload, true));
            commit(SET_DEX_TRANSACTIONS(resp, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async approve({ state, rootGetters, commit, dispatch }, { payload: quantity }: ReturnType<typeof approve>) {
        try {
            const { data } = await DefiApi.privateDexSwapsApprove(new ApproveRequest({
                accountId: rootGetters['Accounts/activeAccountID'],
                assetSymbol: state.ui.fromAsset,
                placementName: state.ui.placement,
                blockchainName: state.ui.blockchain,
                quantity,
            }));
            if (data?.id) {
                const transaction: IDexTransaction = {
                    accountId: rootGetters['Accounts/activeAccountID'],
                    blockchainName: state.ui.blockchain,
                    createdAt: new Date().toISOString(),
                    placementName: state.ui.placement,
                    aQuantity: '',
                    bQuantity: '',
                    status: data.status as any,
                    aAssetSymbol: state.ui.fromAsset,
                    bAssetSymbol: undefined as any,
                    id: data.id,
                    type: 'APPROVE',
                    updatedAt: '',

                };
                commit(SET_DEX_TRANSACTION(transaction, true));
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async swap({ state, rootGetters, commit, dispatch }, { payload: { fee, amount } }: ReturnType<typeof swap>) {
        try {
            const { data } = await DefiApi.privateDexSwapsSwap(new SwapRequest({
                accountId: rootGetters['Accounts/activeAccountID'],
                aAssetSymbol: state.ui.fromAsset,
                bAssetSymbol: state.ui.toAsset,
                placementName: state.ui.placement,
                blockchainName: state.ui.blockchain,
                fee: String(fee),
                quantity: amount,
                slippage: String(state.ui.slippage),
            }));
            if (data?.id) {
                const transaction: IDexTransaction = {
                    accountId: rootGetters['Accounts/activeAccountID'],
                    blockchainName: state.ui.blockchain,
                    createdAt: new Date().toISOString(),
                    placementName: state.ui.placement,
                    aQuantity: '',
                    status: data.status as any,
                    aAssetSymbol: state.ui.fromAsset,
                    bAssetSymbol: state.ui.toAsset,
                    id: data.id,
                    type: 'SWAP',
                    updatedAt: '',
                };
                commit(SET_DEX_TRANSACTION(transaction, true));
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async getDexMarketData({ commit, dispatch }) {
        try {
            const { data: marketData } = await PublicDataApi.publicGetDexExchanges();
            commit(SET_MARKET_DATA(marketData, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    async getGasPrice({ state }) {
        const { data: gasPrice } = await DefiApi.privateDexGetGasPrice(new GasPriceParams({
            blockchainName: state.ui.blockchain,
        }));
        state.gasPrice = gasPrice.value;
    },
    async getRoutes(_, { payload: requestPayload }: ReturnType<typeof getRoutes>) {
        const { data: res } = await DefiApi.privateDexGetRoutes(new RoutesParams({
            ...requestPayload,
        }));
        return res;
    },
    async getNetworkFee({ state, commit, dispatch, rootState }, { payload }: ReturnType<typeof getNetworkFee>) {
        try {
            const {
                isAutoReload,
                aAssetSymbol,
                bAssetSymbol,
                placementName,
                blockchain,
                quantity,
            } = payload;

            if (
                !isAutoReload
                && aAssetSymbol === state.ui.networkFee.lastAAssetSymbol
                && bAssetSymbol === state.ui.networkFee.lastBAssetSymbol
                && placementName === state.ui.networkFee.lastPlacement
                && blockchain === state.ui.networkFee.lastBlockchain
            ) {
                return;
            }

            state.ui.networkFee.lastPlacement = placementName;
            state.ui.networkFee.lastAAssetSymbol = aAssetSymbol;
            state.ui.networkFee.lastBAssetSymbol = bAssetSymbol;
            state.ui.networkFee.lastBlockchain = blockchain;

            if (state.ui.networkFee.timerId) {
                clearInterval(state.ui.networkFee.timerId);
                state.ui.networkFee.timerId = null;
                state.ui.networkFee.timer = 10;
                state.ui.networkFee.value = 0;
            }
            if (
                !rootState.Accounts.activeAccountID
                || !aAssetSymbol
                || !bAssetSymbol
                || !blockchain
                || !placementName
                || !state.ui.isApproved
            ) {
                return;
            }
            const { data: result } = await DefiApi.privateDexSwapsPrepareSwap(new SwapRequest({
                accountId: rootState.Accounts.activeAccountID,
                aAssetSymbol,
                bAssetSymbol,
                blockchainName: blockchain,
                placementName,
                quantity,
                fee: '1',
                slippage: '1',
            }));
            commit(SET_NETWORK_FEE_TIMER(10, true));
            commit(SET_NETWORK_FEE_VALUE(result.networkFee, true));
            state.ui.networkFee.timerId = setInterval(() => {
                commit(SET_NETWORK_FEE_TIMER(state.ui.networkFee.timer - 1, true));
                if (state.ui.networkFee.timer === 0) {
                    clearInterval(state.ui.networkFee.timerId!);
                    state.ui.networkFee.timerId = null;
                    dispatch(getNetworkFee({
                        isAutoReload: true,
                        aAssetSymbol,
                        bAssetSymbol,
                        placementName,
                        blockchain,
                        quantity,
                    }, true));
                }
            }, 1000);
        } catch (error) {
            await dispatch(clearNetworkFeeInterval(undefined, true));
        }
    },
    clearNetworkFeeInterval({ state }) {
        if (state.ui.networkFee.timerId) {
            clearInterval(state.ui.networkFee.timerId);
        }
        state.ui.networkFee.lastBlockchain = '';
        state.ui.networkFee.lastPlacement = '';
        state.ui.networkFee.lastAAssetSymbol = '';
        state.ui.networkFee.lastBAssetSymbol = '';
        state.ui.networkFee.timerId = null;
        state.ui.networkFee.timer = 10;
        state.ui.networkFee.value = 0;
    },
};

export default {
    namespaced: true,
    state,
    getters: {},
    mutations,
    actions,
};
