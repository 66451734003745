// This file was autogenerated. Please do not change.
/* Disable MFA factor payload */
export interface IDisableMFAPayload {
    action?: ('PasswordForgot' | 'PasswordChange' | 'MFADisable' | 'Transfer' | 'EmailVerification' | 'Withdraw' | 'ShareAccountAccess' | 'ChangeAccountAccessPermission' | 'GroupDelete' | 'DefaultActionConfirmation');
    emailPassCode: string;
    mfaType: ('Email' | 'EmailActivation' | 'TOTP');
    passCode: string;
    sessionId: string;
}

export default class DisableMFAPayload {
    readonly _action: ('PasswordForgot' | 'PasswordChange' | 'MFADisable' | 'Transfer' | 'EmailVerification' | 'Withdraw' | 'ShareAccountAccess' | 'ChangeAccountAccessPermission' | 'GroupDelete' | 'DefaultActionConfirmation') | undefined;

    /** Description: Action */
    get action(): ('PasswordForgot' | 'PasswordChange' | 'MFADisable' | 'Transfer' | 'EmailVerification' | 'Withdraw' | 'ShareAccountAccess' | 'ChangeAccountAccessPermission' | 'GroupDelete' | 'DefaultActionConfirmation') | undefined {
        return this._action;
    }

    readonly _emailPassCode: string;

    /** Description: One-time verification code from email */
    get emailPassCode(): string {
        return this._emailPassCode;
    }

    readonly _mfaType: ('Email' | 'EmailActivation' | 'TOTP');

    /** Description: MFA Type */
    get mfaType(): ('Email' | 'EmailActivation' | 'TOTP') {
        return this._mfaType;
    }

    readonly _passCode: string;

    /** Description: One-time verification code */
    get passCode(): string {
        return this._passCode;
    }

    readonly _sessionId: string;

    /** Description: Current user's session Id */
    get sessionId(): string {
        return this._sessionId;
    }

    constructor(props: IDisableMFAPayload) {
        if (props.action) {
            this._action = props.action;
        }
        this._emailPassCode = props.emailPassCode.trim();
        this._mfaType = props.mfaType;
        this._passCode = props.passCode.trim();
        this._sessionId = props.sessionId.trim();
    }

    serialize(): IDisableMFAPayload {
        const data: IDisableMFAPayload = {
            emailPassCode: this._emailPassCode,
            mfaType: this._mfaType,
            passCode: this._passCode,
            sessionId: this._sessionId,
        };
        if (typeof this._action !== 'undefined') {
            data.action = this._action;
        }
        return data;
    }

    toJSON(): IDisableMFAPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            sessionId: typeof this._sessionId === 'string' && !!this._sessionId.trim(),
            passCode: typeof this._passCode === 'string' && !!this._passCode.trim(),
            emailPassCode: typeof this._emailPassCode === 'string' && !!this._emailPassCode.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
