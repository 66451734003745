// This file was autogenerated. Please do not change.
export interface IRoutesParams {
    blockchainName: string;
    fromAssetSymbol: string;
    gasPrice: string;
    placementTag: string;
    quantity: string;
    toAssetSymbol: string;
}

export default class RoutesParams {
    readonly _blockchainName: string;

    /**
     * Example: ETH
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _fromAssetSymbol: string;

    /**
     * Example: ETH
     */
    get fromAssetSymbol(): string {
        return this._fromAssetSymbol;
    }

    readonly _gasPrice: string;

    /**
     * Example: 10.01
     */
    get gasPrice(): string {
        return this._gasPrice;
    }

    readonly _placementTag: string;

    /**
     * Example: 1INCH
     */
    get placementTag(): string {
        return this._placementTag;
    }

    readonly _quantity: number;

    /**
     * Example: 10.01
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _toAssetSymbol: string;

    /**
     * Example: DAI
     */
    get toAssetSymbol(): string {
        return this._toAssetSymbol;
    }

    constructor(props: IRoutesParams) {
        this._blockchainName = props.blockchainName.trim();
        this._fromAssetSymbol = props.fromAssetSymbol.trim();
        this._gasPrice = props.gasPrice.trim();
        this._placementTag = props.placementTag.trim();
        this._quantity = Number(props.quantity);
        this._toAssetSymbol = props.toAssetSymbol.trim();
    }

    serialize(): IRoutesParams {
        const data: IRoutesParams = {
            blockchainName: this._blockchainName,
            fromAssetSymbol: this._fromAssetSymbol,
            gasPrice: this._gasPrice,
            placementTag: this._placementTag,
            quantity: String(this._quantity),
            toAssetSymbol: this._toAssetSymbol,
        };
        return data;
    }

    toJSON(): IRoutesParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            fromAssetSymbol: typeof this._fromAssetSymbol === 'string' && !!this._fromAssetSymbol.trim(),
            gasPrice: typeof this._gasPrice === 'string' && !!this._gasPrice.trim(),
            placementTag: typeof this._placementTag === 'string' && !!this._placementTag.trim(),
            quantity: true,
            toAssetSymbol: typeof this._toAssetSymbol === 'string' && !!this._toAssetSymbol.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
