// This file was autogenerated. Please do not change.
export interface IBaseGroupPayload {
    groupId: string;
}

export default class BaseGroupPayload {
    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    constructor(props: IBaseGroupPayload) {
        this._groupId = props.groupId.trim();
    }

    serialize(): IBaseGroupPayload {
        const data: IBaseGroupPayload = {
            groupId: this._groupId,
        };
        return data;
    }

    toJSON(): IBaseGroupPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
