// This file was autogenerated. Please do not change.
export interface IGetFavoriteAssetPairsRequest {
    page?: number;
    perPage?: number;
    placementName?: string;
}

export default class GetFavoriteAssetPairsRequest {
    readonly _page: number | undefined;

    /** Description: Default: 1 */
    get page(): number | undefined {
        return this._page;
    }

    static readonly PageMinValue = 0;
    readonly _perPage: number | undefined;

    /** Description: Default: 100, Max: 300 */
    get perPage(): number | undefined {
        return this._perPage;
    }

    static readonly PerPageMinValue = 0;
    static readonly PerPageMaxValue = 300;
    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: IGetFavoriteAssetPairsRequest) {
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): IGetFavoriteAssetPairsRequest {
        const data: IGetFavoriteAssetPairsRequest = {
        };
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): IGetFavoriteAssetPairsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : ((this._perPage >= 0) && (this._perPage <= 300)),
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
