import TradingApi from 'Apis/Trading';
import PlaceOrderRequest from 'Lib/entities/orderRegistrator/PlaceOrderRequest';
import {
    OrderActiveFieldValues,
    OrderExpiries,
    LimitOrder,
    OrdersTypes,
    OrdersSides,
    OrderRestrictionFields,
    GenerateRestrictions,
    RestrictionsAdapter,
} from 'Models/trading';

const state = {
    expiries: Object.values(OrderExpiries),
    activeExpiryIndex: 0,

    quantity: 0,
    price: 0,
    total: 0,

    activeFieldValues: OrderActiveFieldValues,
    /**
   * If equal "quantity", then total will be calculating by quantity and price (total = quantity * price),
   * else if activeField is equal "total", then quantity will be calculating by total and price (quantity = total / price)
   *
   * @type "quantity"|"total"
   */
    activeField: OrderActiveFieldValues.QUANTITY,

    isOrderPerforming: false,
};

const getters = {
    activeSide: (state, getters, rootState, rootGetters) => rootGetters['Orders/Trading/activeSide'],

    expiries: (state) => state.expiries,
    activeExpiryIndex: (state) => state.activeExpiryIndex,
    activeExpiry: (state, getters) => getters.expiries[getters.activeExpiryIndex],

    activeFieldValues: (state) => state.activeFieldValues,
    activeField: (state) => state.activeField,

    quantityBalanceFree: (state, getters, rootState, rootGetters) => {
        const placementId = rootGetters['Placements/activeTerminalPlacementId'];
        const assetSymbol = rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL'];

        const balance = rootGetters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](placementId, assetSymbol);

        return balance ? balance.free : 0;
    },
    totalBalanceFree: (state, getters, rootState, rootGetters) => {
        const placementId = rootGetters['Placements/activeTerminalPlacementId'];
        const assetSymbol = rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL'];

        const balance = rootGetters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](placementId, assetSymbol);

        return balance ? balance.free : 0;
    },

    price: (state) => state.price,

    baseAssetPrecision: (state, getters, rootState, rootGetters) => rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_AMOUNT'],
    quoteAssetPrecision: (state, getters, rootState, rootGetters) => rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_PRECISION_PRICE'],

    quantityPrecision: (state, getters) => Math.min(
        getters.baseAssetPrecision,
        getters.quantityRestriction.stepSize ? getters.quantityRestriction.stepSize.getPrecision() : Infinity,
    ),
    pricePrecision: (state, getters) => Math.min(
        getters.quoteAssetPrecision,
        getters.priceRestriction.stepSize ? getters.priceRestriction.stepSize.getPrecision() : Infinity,
    ),

    quantity: (state, getters) => {
        const result = (getters.activeField === getters.activeFieldValues.QUANTITY
            ? state.quantity
            : (getters.total / getters.price) as any).round(getters.quantityPrecision);

        return Number.isFinite(result) ? result : 0;
    },
    total: (state, getters) => {
        const result = (getters.activeField === getters.activeFieldValues.TOTAL
            ? state.total
            : (+getters.quantity * +getters.price) as any).round(getters.pricePrecision);

        return Number.isFinite(result) ? result : 0;
    },
    totalEquivalent: (state, getters, rootState, rootGetters) => {
        if (getters.activeSide.value === OrdersSides.BUY.value) {
            if (getters.price >= rootGetters['OrderBook/marketPrice'][OrdersSides.BUY.value]) {
                return rootGetters['Assets/GET_QUOTE'](
                    rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL'],
                    getters.quantity,
                );
            }
            return rootGetters['Assets/GET_QUOTE'](
                rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL'],
                getters.total,
            );
        } if (getters.activeSide.value === OrdersSides.SELL.value) {
            if (getters.price <= rootGetters['OrderBook/marketPrice'][OrdersSides.SELL.value]) {
                return rootGetters['Assets/GET_QUOTE'](
                    rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL'],
                    getters.quantity,
                );
            }
            return rootGetters['Assets/GET_QUOTE'](
                rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL'],
                getters.total,
            );
        }
        return 0;
    },

    currentCommissionPart: (state, getters, rootState, rootGetters) => rootGetters['Placements/activeTerminalPlacementTradeCommission'].commissionTaker,
    commissionPrecision: (state, getters) => (getters.activeSide.value === OrdersSides.BUY.value
        ? getters.baseAssetPrecision
        : getters.quoteAssetPrecision),
    commissionAsset: (state, getters, rootState, rootGetters) => (getters.activeSide.value === OrdersSides.BUY.value
        ? rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_BASE_ASSET_SYMBOL']
        : rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_QUOTE_ASSET_SYMBOL']),
    currentCommission: (state, getters) => {
        const result = (((getters.activeSide.value === OrdersSides.BUY.value ? getters.quantity : getters.total) * getters.currentCommissionPart) as any)
            .round(getters.commissionPrecision);

        return Number.isFinite(result) ? result : 0;
    },
    currentCommissionQuotation: (state, getters, rootState, rootGetters) => rootGetters['Assets/GET_QUOTE'](getters.commissionAsset, getters.currentCommission),

    orderRestrictions: (state, getters, rootState, rootGetters) => rootGetters['Orders/Trading/orderRestrictions'][OrdersTypes.LIMIT.restriction],
    activeOrderRestriction: (state, getters) => (getters.orderRestrictions && getters.orderRestrictions[getters.activeSide.restriction]
        ? getters.orderRestrictions[getters.activeSide.restriction]
        : {}),
    activeOrderQuantityRestriction: (state, getters) => GenerateRestrictions(getters.activeOrderRestriction[OrderRestrictionFields.QUANTITY]),
    activeOrderPriceRestriction: (state, getters) => GenerateRestrictions(getters.activeOrderRestriction[OrderRestrictionFields.PRICE]),
    activeOrderTotalRestriction: (state, getters) => GenerateRestrictions(getters.activeOrderRestriction[OrderRestrictionFields.TOTAL]),

    quantityRestriction: (state, getters) => RestrictionsAdapter(getters.activeOrderQuantityRestriction),
    priceRestriction: (stage, getters) => RestrictionsAdapter(getters.activeOrderPriceRestriction),
    totalRestriction: (stage, getters) => RestrictionsAdapter(getters.activeOrderTotalRestriction),

    isOrderPerforming: (state) => state.isOrderPerforming,
};

const mutations = {
    SET_ACTIVE_EXPIRY_INDEX(state, index) {
        state.activeExpiryIndex = index;
    },

    SET_ACTIVE_FIELD(state, field) {
        state.activeField = field;
    },

    SET_QUANTITY(state, quantity) {
        state.quantity = quantity;
    },
    SET_PRICE(state, price) {
        state.price = price;
    },
    SET_TOTAL(state, total) {
        state.total = total;
    },

    SET_IS_ORDER_PERFORMING_STATUS(state, status) {
        state.isOrderPerforming = status;
    },
};

const actions = {
    setIsOrderPerformingStatus({ commit }, status) {
        commit('SET_IS_ORDER_PERFORMING_STATUS', status);
    },
    setActiveExpiryIndex({ commit }, index) {
        commit('SET_ACTIVE_EXPIRY_INDEX', index);
    },

    setActiveField({ commit }, field) {
        commit('SET_ACTIVE_FIELD', field);
    },

    setQuantity({ commit, getters, dispatch }, quantity) {
        commit('SET_QUANTITY', quantity);
        dispatch('setActiveField', getters.activeFieldValues.QUANTITY);
    },
    setPrice({ commit }, price) {
        commit('SET_PRICE', price);
    },
    setTotal({ commit, getters, dispatch }, total) {
        commit('SET_TOTAL', total);
        dispatch('setActiveField', getters.activeFieldValues.TOTAL);
    },

    resetFields({ dispatch }) {
        dispatch('setQuantity', 0);
        dispatch('setPrice', 0);
    },

    async validateOrder({ getters }) {
        let error: string | null = null;

        const quantity = +getters.quantity;
        const price = +getters.price;
        const total = +getters.total;

        /**
     *
     * @param {String} field
     * @param {Number} value
     * @param {Object} restrictions
     *
     * @return {string|null}
     */
        function validateValue(field, value, restrictions) {
            if (restrictions.min !== null && value < restrictions.min) {
                return `${field} should be above ${restrictions.min}`;
            } if (restrictions.max !== null && value > restrictions.max) {
                return `${field} should be below ${getters.activeOrderPriceRestriction.max}`;
            } if (restrictions.stepSize !== null && !restrictions.stepSize.isAliquotOf(value)) {
                return `${field} have be divide by ${restrictions.stepSize}`;
            } if (value === 0) {
                return `${field} have be above zero`;
            }
            return null;
        }

        const quantityValidate = validateValue('Quantity', quantity, getters.activeOrderQuantityRestriction);
        const priceValidate = validateValue('Price', price, getters.activeOrderPriceRestriction);
        const totalValidate = validateValue('Total', total, getters.activeOrderTotalRestriction);

        if (quantityValidate !== null) {
            error = quantityValidate;
        } else if (priceValidate !== null) {
            error = priceValidate;
        } else if (totalValidate !== null) {
            error = totalValidate;
        }

        if (error !== null) {
            throw {
                type: 'VALIDATION_ERROR',
                message: error,
            };
        }
    },

    async performOrder({ getters, rootGetters, dispatch }) {
        try {
            dispatch('setIsOrderPerformingStatus', true);

            await dispatch('validateOrder');

            const order = {
                type: OrdersTypes.LIMIT.value,
                accountId: rootGetters['Accounts/activeAccountID'],
                assetPairId: rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_ID'],
                placementId: rootGetters['Placements/activeTerminalPlacementId'],
                side: getters.activeSide.value,
                timeInForce: getters.activeExpiry.value,
                quantity: getters.quantity.toString(),
                price: getters.price.toString(),
                total: getters.total.toString(),
                placementOrderTypes: rootGetters['Placements/getActivePlacementOrderQuantityTypes'],
            };
            try {
                const res = await dispatch('placeOrder', new LimitOrder(order));
                dispatch('resetFields');
                return res;
            } finally {
                dispatch('setIsOrderPerformingStatus', false);
            }
        } catch (error) {
            if ((error as any).type === 'VALIDATION_ERROR') {
                dispatch('Notificator/showErrorNotification', (error as Error).message, { root: true });
            }

            dispatch('setIsOrderPerformingStatus', false);
        }
    },
    async placeOrder(_, order) {
        try {
            return await TradingApi.privateOrdersPlaceOrder(new PlaceOrderRequest(order));
        } catch (e) {
            return Promise.reject(e);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
