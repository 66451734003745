// This file was autogenerated. Please do not change.
export interface IPresetRequest {
    id: number;
    type: ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE');
}

export default class PresetRequest {
    readonly _id: number;

    get id(): number {
        return this._id;
    }

    readonly _type: ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE');

    get type(): ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE') {
        return this._type;
    }

    constructor(props: IPresetRequest) {
        this._id = props.id;
        this._type = props.type;
    }

    serialize(): IPresetRequest {
        const data: IPresetRequest = {
            id: this._id,
            type: this._type,
        };
        return data;
    }

    toJSON(): IPresetRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
