export const LS_FAVORITE_ASSET_PAIRS_FIELD = 'favoriteAssetPairs';
export const LS_IS_OWNED_ACCOUNTS_SHOW = 'isOwnedAccountsShow';
export const LS_IS_MANAGED_ACCOUNTS_SHOW = 'isManagedAccountsShow';
export const LS_ACTIVE_ACCOUNT_ID_FIELD = 'activeAccountId';
export const LS_ACCOUNTS_ORDERS_FIELD = 'accountsOrders';
export const LS_ACTIVE_ASSET_PAIR_FIELD = 'activeAssetPairField';
export const LS_QUOTATION_ASSET_SYMBOL_KEY = 'quotationAssetSymbol';
export const LS_PAGES_GRID_FIELD = 'pagesGrid';
export const LS_ACTIVE_COLOR_THEME = 'activeColorTheme';
export const LS_ACTIVE_PLACEMENT_ID_FIELD = 'activePlacementId';
