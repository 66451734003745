// This file was autogenerated. Please do not change.
export interface IChartPayload {
    UTCFromTs?: number;
    UTCToTs?: number;
    accountId?: string;
    accountIds?: string[];
    assetSymbols: string[];
    quotationAssetSymbol: string;
    timeGroup: ('Day');
}

export default class ChartPayload {
    readonly _UTCFromTs: number | undefined;

    get UTCFromTs(): number | undefined {
        return this._UTCFromTs;
    }

    readonly _UTCToTs: number | undefined;

    get UTCToTs(): number | undefined {
        return this._UTCToTs;
    }

    readonly _accountId: string | undefined;

    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _accountIds: string[] | undefined;

    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _assetSymbols: string[];

    /** Description: An array of asset symbols */
    get assetSymbols(): string[] {
        return this._assetSymbols;
    }

    readonly _quotationAssetSymbol: string;

    get quotationAssetSymbol(): string {
        return this._quotationAssetSymbol;
    }

    readonly _timeGroup: ('Day');

    /** Description: Represents time interval between chart points */
    get timeGroup(): ('Day') {
        return this._timeGroup;
    }

    constructor(props: IChartPayload) {
        if (typeof props.UTCFromTs === 'number') {
            this._UTCFromTs = props.UTCFromTs;
        }
        if (typeof props.UTCToTs === 'number') {
            this._UTCToTs = props.UTCToTs;
        }
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        this._assetSymbols = props.assetSymbols;
        this._quotationAssetSymbol = props.quotationAssetSymbol.trim();
        this._timeGroup = props.timeGroup;
    }

    serialize(): IChartPayload {
        const data: IChartPayload = {
            assetSymbols: this._assetSymbols,
            quotationAssetSymbol: this._quotationAssetSymbol,
            timeGroup: this._timeGroup,
        };
        if (typeof this._UTCFromTs !== 'undefined') {
            data.UTCFromTs = this._UTCFromTs;
        }
        if (typeof this._UTCToTs !== 'undefined') {
            data.UTCToTs = this._UTCToTs;
        }
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        return data;
    }

    toJSON(): IChartPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            quotationAssetSymbol: typeof this._quotationAssetSymbol === 'string' && !!this._quotationAssetSymbol.trim(),
            UTCFromTs: !this._UTCFromTs ? true : typeof this._UTCFromTs === 'number',
            UTCToTs: !this._UTCToTs ? true : typeof this._UTCToTs === 'number',
            assetSymbols: this._assetSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
