import store from '@/store';

class PlacementStyleIcons {
    _default

    _dark

    constructor(defaultIcon, dark: string | null = null) {
        this._default = defaultIcon;
        this._dark = dark === null ? defaultIcon : dark;
    }

    get default() {
        return store.getters.isThemeDark ? this._dark : this._default;
    }
}

const defaultThemeColors = {
    main: '#656FDB',
    lighter: '#7C84E1',
};

export const placementsStyle = {
    EXCELSIOR: {
        icons: new PlacementStyleIcons('singlebroker.svg', 'singlebroker_dark.svg'),
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    SINGLEBROKER: {
        icons: new PlacementStyleIcons('singlebroker_dark.svg', 'singlebroker.svg'),
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    BINANCE: {
        icons: {
            default: 'binance.svg',
        },
        theme_colors: {
            main: '#FABC00',
            lighter: '#FBC63D',
        },
    },
    BINANCEFUTURES: {
        icons: {
            default: 'binancefutures.svg',
        },
        theme_colors: {
            main: '#FABC00',
            lighter: '#FBC63D',
        },
    },
    BITFINEX: {
        icons: {
            default: 'bitfinex.svg',
        },
        theme_colors: {
            main: '#89CB44',
            lighter: '#9AD363',
        },
    },
    BITMEX: {
        icons: {
            default: 'bitmex.svg',
        },
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    BITTREX: {
        icons: {
            default: 'bittrex.svg',
        },
        theme_colors: {
            main: '#0081eb',
            lighter: '#0081EB',
        },
    },
    COINBASEPRO: {
        icons: {
            default: 'coinbasepro.png',
        },
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    GATEIO: {
        icons: {
            default: 'gateio.svg',
        },
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    FTX: {
        icons: {
            default: 'ftx.svg',
        },
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    GEMINI: {
        icons: {
            default: 'gemini.svg',
        },
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
    KRAKEN: {
        icons: {
            default: 'kraken.svg',
        },
        theme_colors: {
            main: '#650EE0',
            lighter: '#7C3CE5',
        },
    },
    KUCOIN: {
        icons: {
            default: 'kucoin.svg',
        },
        theme_colors: {
            main: '#23AE90',
            lighter: '#47B9A1',
        },
    },
    LIQUID: {
        icons: {
            default: 'liquid.svg',
        },
        theme_colors: {
            main: '#0157ff',
            lighter: '#3071FF',
        },
    },
    HUOBI: {
        icons: new PlacementStyleIcons('huobi.svg', 'huobi_dark.svg'),
        theme_colors: {
            main: '#357CE1',
            lighter: '#6398e2',
        },
    },
    HTX: {
        icons: new PlacementStyleIcons('huobi.svg', 'huobi_dark.svg'),
        theme_colors: {
            main: '#357CE1',
            lighter: '#6398e2',
        },
    },
    HUOBIFUTURES: {
        icons: new PlacementStyleIcons('huobi.svg', 'huobi_dark.svg'),
        theme_colors: {
            main: '#357CE1',
            lighter: '#6398e2',
        },
    },
    HTXFUTURES: {
        icons: new PlacementStyleIcons('huobi.svg', 'huobi_dark.svg'),
        theme_colors: {
            main: '#357CE1',
            lighter: '#6398e2',
        },
    },
    OKEX: {
        icons: new PlacementStyleIcons('okex.svg', 'okex_dark.svg'),
        theme_colors: {
            main: '#275FEC',
            lighter: '#5795F1',
        },
    },
    OKX: {
        icons: new PlacementStyleIcons('okex.svg', 'okex_dark.svg'),
        theme_colors: {
            main: '#275FEC',
            lighter: '#5795F1',
        },
    },
    HITBTC: {
        icons: {
            default: 'hitbtc.svg',
        },
        theme_colors: {
            main: '#005472',
            lighter: '#8bdaff',
        },
    },
    PANCAKESWAP: {
        icons: {
            default: 'pancakeswap.svg',
        },
        theme_colors: {
            main: '#b57a28',
            lighter: '#bf9c6b',
        },
    },
    '1INCH': {
        icons: {
            default: '1inch.svg',
        },
        theme_colors: {
            main: '#9512c4',
            lighter: '#b507f5',
        },
    },
    AAVE: {
        icons: {
            default: 'aave.svg',
        },
    },
    BNB: {
        icons: {
            default: 'bnb.svg',
        },
    },
    COMPOUND: {
        icons: {
            default: 'compound.svg',
        },
    },
    CURVE: {
        icons: {
            default: 'curve.svg',
        },
    },
    OPTIMISM: {
        icons: {
            default: 'optimism.svg',
        },
    },
    POLYGON: {
        icons: {
            default: 'polygon.svg',
        },
    },
    SUSHISWAP: {
        icons: {
            default: 'sushiswap.svg',
        },
        theme_colors: {
            main: '#0d6ba6',
            lighter: '#71acd1',
        },
    },
    UNISWAPV2: {
        icons: {
            default: 'uniswap.svg',
        },
        theme_colors: {
            main: '#ed093f',
            lighter: '#ed6f8d',
        },
    },
    UNISWAPV3: {
        icons: {
            default: 'uniswap.svg',
        },
        theme_colors: {
            main: '#fda032',
            lighter: '#fda999',
        },
    },
    OTCMARKET: {
        icons: {
            default: 'otcmarket.svg',
        },
        theme_colors: {
            main: '#1d248a',
            lighter: '#424eed',
        },
    },
    BYBIT: {
        icons: new PlacementStyleIcons('bybit.svg', 'bybit_dark.svg'),
        theme_colors: {
            main: '#5e4b04',
            lighter: '#856c11',
        },
    },
    BYBITFUTURES: {
        icons: new PlacementStyleIcons('bybit.svg', 'bybit_dark.svg'),
        theme_colors: {
            main: '#056914',
            lighter: '#1aa32f',
        },
    },
    DEFAULT: {
        icons: new PlacementStyleIcons('singlebroker.svg', 'singlebroker_dark.svg'),
        theme_colors: {
            main: '#656FDB',
            lighter: '#7C84E1',
        },
    },
};

/**
 *
 * @param {String} placementName
 * @return {Object}
 */
export function getPlacementStyle(placementName) {
    return placementsStyle[placementName.replace(/ /g, '').toUpperCase()] || placementsStyle.DEFAULT;
}

/**
 *
 * @param {String} placementName
 * @return {Object}
 */
export function getPlacementThemeColors(placementName) {
    const placementStyle = placementName ? getPlacementStyle(placementName) : null;

    return placementStyle ? placementStyle.theme_colors : defaultThemeColors;
}
