// This file was autogenerated. Please do not change.
export interface ITransferRequest {
    clientId?: string;
    id?: string;
}

export default class TransferRequest {
    readonly _clientId: string | undefined;

    /**
     * Example: my-fantast1c-transfer-007
     */
    get clientId(): string | undefined {
        return this._clientId;
    }

    readonly _id: string | undefined;

    /**
     * Example: TQ1CAJW8FH48WLSU7HGCL
     */
    get id(): string | undefined {
        return this._id;
    }

    constructor(props: ITransferRequest) {
        if (typeof props.clientId === 'string') {
            this._clientId = props.clientId.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
    }

    serialize(): ITransferRequest {
        const data: ITransferRequest = {
        };
        if (typeof this._clientId !== 'undefined') {
            data.clientId = this._clientId;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        return data;
    }

    toJSON(): ITransferRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            clientId: !this._clientId ? true : typeof this._clientId === 'string',
            id: !this._id ? true : typeof this._id === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
