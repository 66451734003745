// This file was autogenerated. Please do not change.
export interface ICheckApprovalResponse {
    isApproved?: boolean;
}

export default class CheckApprovalResponse {
    readonly _isApproved: boolean | undefined;

    /**
     * Example: true
     */
    get isApproved(): boolean | undefined {
        return this._isApproved;
    }

    constructor(props: ICheckApprovalResponse) {
        if (typeof props.isApproved === 'boolean') {
            this._isApproved = props.isApproved;
        }
    }

    serialize(): ICheckApprovalResponse {
        const data: ICheckApprovalResponse = {
        };
        if (typeof this._isApproved !== 'undefined') {
            data.isApproved = this._isApproved;
        }
        return data;
    }

    toJSON(): ICheckApprovalResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            isApproved: !this._isApproved ? true : typeof this._isApproved === 'boolean',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
