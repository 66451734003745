import { mutationCreator } from 'Store/utils';

const state = {
    placementName: '',
};

export type LinkPlacementState = typeof state;

export enum LinkPlacementMutations {
    SET_PLACEMENT_NAME = 'SET_PLACEMENT_NAME',
}

export const SET_PLACEMENT_NAME = mutationCreator<string>('LinkPlacement', LinkPlacementMutations.SET_PLACEMENT_NAME);

const mutations: Record<LinkPlacementMutations, (state: LinkPlacementState, ...args: any) => void> = {
    SET_PLACEMENT_NAME(state, { payload: name }: ReturnType<typeof SET_PLACEMENT_NAME>) {
        state.placementName = name;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
