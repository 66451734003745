export default class ApiError extends Error {
    fields?: string[] | undefined;
    response?: Response | undefined;
    info?: string;
    data?: any;

    constructor(name: string, props: { fields?: string[]; response?: Response, info?: string, data?: any }) {
        super(name);
        this.fields = props.fields;
        this.response = props.response;
        this.info = props.info;
        this.data = props.data;
    }
}
