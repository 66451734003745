// This file was autogenerated. Please do not change.
export interface IQuoteParams {
    aAssetSymbol: string;
    bAssetSymbol: string;
    blockchainName: string;
    placementName: string;
    quantity: string;
}

export default class QuoteParams {
    readonly _aAssetSymbol: string;

    /**
     * Example: USDT
     */
    get aAssetSymbol(): string {
        return this._aAssetSymbol;
    }

    readonly _bAssetSymbol: string;

    /**
     * Example: BNB
     */
    get bAssetSymbol(): string {
        return this._bAssetSymbol;
    }

    readonly _blockchainName: string;

    /**
     * Example: BNB
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _placementName: string;

    /**
     * Example: PancakeSwap
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _quantity: number;

    /**
     * Example: 10
     */
    get quantity(): number {
        return this._quantity;
    }

    constructor(props: IQuoteParams) {
        this._aAssetSymbol = props.aAssetSymbol.trim();
        this._bAssetSymbol = props.bAssetSymbol.trim();
        this._blockchainName = props.blockchainName.trim();
        this._placementName = props.placementName.trim();
        this._quantity = Number(props.quantity);
    }

    serialize(): IQuoteParams {
        const data: IQuoteParams = {
            aAssetSymbol: this._aAssetSymbol,
            bAssetSymbol: this._bAssetSymbol,
            blockchainName: this._blockchainName,
            placementName: this._placementName,
            quantity: String(this._quantity),
        };
        return data;
    }

    toJSON(): IQuoteParams {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aAssetSymbol: typeof this._aAssetSymbol === 'string' && !!this._aAssetSymbol.trim(),
            bAssetSymbol: typeof this._bAssetSymbol === 'string' && !!this._bAssetSymbol.trim(),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
