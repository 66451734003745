// This file was autogenerated. Please do not change.
export interface IBankRequisitesResponseData {
    alias?: string;
    beneficiaryAddress?: string;
    beneficiaryBankAddress?: string;
    beneficiaryBankCity?: string;
    beneficiaryBankCountry?: string;
    beneficiaryBankCountryCode?: string;
    beneficiaryBankName?: string;
    beneficiaryBankPostalCode?: string;
    beneficiaryCity?: string;
    beneficiaryCountry?: string;
    beneficiaryCountryCode?: string;
    beneficiaryIban?: string;
    beneficiaryName?: string;
    beneficiaryPostalCode?: string;
    beneficiarySwift?: string;
}

export default class BankRequisitesResponseData {
    readonly _alias: string | undefined;

    /** Description: Alias for requisites */
    get alias(): string | undefined {
        return this._alias;
    }

    readonly _beneficiaryAddress: string | undefined;

    /** Description: Beneficiary address */
    get beneficiaryAddress(): string | undefined {
        return this._beneficiaryAddress;
    }

    readonly _beneficiaryBankAddress: string | undefined;

    /** Description: Beneficiary bank address */
    get beneficiaryBankAddress(): string | undefined {
        return this._beneficiaryBankAddress;
    }

    readonly _beneficiaryBankCity: string | undefined;

    /** Description: Beneficiary bank city */
    get beneficiaryBankCity(): string | undefined {
        return this._beneficiaryBankCity;
    }

    readonly _beneficiaryBankCountry: string | undefined;

    /** Description: Beneficiary bank country */
    get beneficiaryBankCountry(): string | undefined {
        return this._beneficiaryBankCountry;
    }

    readonly _beneficiaryBankCountryCode: string | undefined;

    /** Description: Beneficiary bank country code in aplha2 format */
    get beneficiaryBankCountryCode(): string | undefined {
        return this._beneficiaryBankCountryCode;
    }

    readonly _beneficiaryBankName: string | undefined;

    /** Description: Beneficiary bank Name */
    get beneficiaryBankName(): string | undefined {
        return this._beneficiaryBankName;
    }

    readonly _beneficiaryBankPostalCode: string | undefined;

    /** Description: Beneficiary bank postal code */
    get beneficiaryBankPostalCode(): string | undefined {
        return this._beneficiaryBankPostalCode;
    }

    readonly _beneficiaryCity: string | undefined;

    /** Description: Beneficiary city */
    get beneficiaryCity(): string | undefined {
        return this._beneficiaryCity;
    }

    readonly _beneficiaryCountry: string | undefined;

    /** Description: Beneficiary country */
    get beneficiaryCountry(): string | undefined {
        return this._beneficiaryCountry;
    }

    readonly _beneficiaryCountryCode: string | undefined;

    /** Description: Beneficiary country code in aplha2 format */
    get beneficiaryCountryCode(): string | undefined {
        return this._beneficiaryCountryCode;
    }

    readonly _beneficiaryIban: string | undefined;

    /** Description: Beneficiary IBAN */
    get beneficiaryIban(): string | undefined {
        return this._beneficiaryIban;
    }

    readonly _beneficiaryName: string | undefined;

    /** Description: Beneficiary Name */
    get beneficiaryName(): string | undefined {
        return this._beneficiaryName;
    }

    readonly _beneficiaryPostalCode: string | undefined;

    /** Description: Beneficiary postal code */
    get beneficiaryPostalCode(): string | undefined {
        return this._beneficiaryPostalCode;
    }

    readonly _beneficiarySwift: string | undefined;

    /** Description: Beneficiary SWIFT */
    get beneficiarySwift(): string | undefined {
        return this._beneficiarySwift;
    }

    constructor(props: IBankRequisitesResponseData) {
        if (typeof props.alias === 'string') {
            this._alias = props.alias.trim();
        }
        if (typeof props.beneficiaryAddress === 'string') {
            this._beneficiaryAddress = props.beneficiaryAddress.trim();
        }
        if (typeof props.beneficiaryBankAddress === 'string') {
            this._beneficiaryBankAddress = props.beneficiaryBankAddress.trim();
        }
        if (typeof props.beneficiaryBankCity === 'string') {
            this._beneficiaryBankCity = props.beneficiaryBankCity.trim();
        }
        if (typeof props.beneficiaryBankCountry === 'string') {
            this._beneficiaryBankCountry = props.beneficiaryBankCountry.trim();
        }
        if (typeof props.beneficiaryBankCountryCode === 'string') {
            this._beneficiaryBankCountryCode = props.beneficiaryBankCountryCode.trim();
        }
        if (typeof props.beneficiaryBankName === 'string') {
            this._beneficiaryBankName = props.beneficiaryBankName.trim();
        }
        if (typeof props.beneficiaryBankPostalCode === 'string') {
            this._beneficiaryBankPostalCode = props.beneficiaryBankPostalCode.trim();
        }
        if (typeof props.beneficiaryCity === 'string') {
            this._beneficiaryCity = props.beneficiaryCity.trim();
        }
        if (typeof props.beneficiaryCountry === 'string') {
            this._beneficiaryCountry = props.beneficiaryCountry.trim();
        }
        if (typeof props.beneficiaryCountryCode === 'string') {
            this._beneficiaryCountryCode = props.beneficiaryCountryCode.trim();
        }
        if (typeof props.beneficiaryIban === 'string') {
            this._beneficiaryIban = props.beneficiaryIban.trim();
        }
        if (typeof props.beneficiaryName === 'string') {
            this._beneficiaryName = props.beneficiaryName.trim();
        }
        if (typeof props.beneficiaryPostalCode === 'string') {
            this._beneficiaryPostalCode = props.beneficiaryPostalCode.trim();
        }
        if (typeof props.beneficiarySwift === 'string') {
            this._beneficiarySwift = props.beneficiarySwift.trim();
        }
    }

    serialize(): IBankRequisitesResponseData {
        const data: IBankRequisitesResponseData = {
        };
        if (typeof this._alias !== 'undefined') {
            data.alias = this._alias;
        }
        if (typeof this._beneficiaryAddress !== 'undefined') {
            data.beneficiaryAddress = this._beneficiaryAddress;
        }
        if (typeof this._beneficiaryBankAddress !== 'undefined') {
            data.beneficiaryBankAddress = this._beneficiaryBankAddress;
        }
        if (typeof this._beneficiaryBankCity !== 'undefined') {
            data.beneficiaryBankCity = this._beneficiaryBankCity;
        }
        if (typeof this._beneficiaryBankCountry !== 'undefined') {
            data.beneficiaryBankCountry = this._beneficiaryBankCountry;
        }
        if (typeof this._beneficiaryBankCountryCode !== 'undefined') {
            data.beneficiaryBankCountryCode = this._beneficiaryBankCountryCode;
        }
        if (typeof this._beneficiaryBankName !== 'undefined') {
            data.beneficiaryBankName = this._beneficiaryBankName;
        }
        if (typeof this._beneficiaryBankPostalCode !== 'undefined') {
            data.beneficiaryBankPostalCode = this._beneficiaryBankPostalCode;
        }
        if (typeof this._beneficiaryCity !== 'undefined') {
            data.beneficiaryCity = this._beneficiaryCity;
        }
        if (typeof this._beneficiaryCountry !== 'undefined') {
            data.beneficiaryCountry = this._beneficiaryCountry;
        }
        if (typeof this._beneficiaryCountryCode !== 'undefined') {
            data.beneficiaryCountryCode = this._beneficiaryCountryCode;
        }
        if (typeof this._beneficiaryIban !== 'undefined') {
            data.beneficiaryIban = this._beneficiaryIban;
        }
        if (typeof this._beneficiaryName !== 'undefined') {
            data.beneficiaryName = this._beneficiaryName;
        }
        if (typeof this._beneficiaryPostalCode !== 'undefined') {
            data.beneficiaryPostalCode = this._beneficiaryPostalCode;
        }
        if (typeof this._beneficiarySwift !== 'undefined') {
            data.beneficiarySwift = this._beneficiarySwift;
        }
        return data;
    }

    toJSON(): IBankRequisitesResponseData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: !this._alias ? true : typeof this._alias === 'string',
            beneficiaryName: !this._beneficiaryName ? true : typeof this._beneficiaryName === 'string',
            beneficiaryIban: !this._beneficiaryIban ? true : typeof this._beneficiaryIban === 'string',
            beneficiarySwift: !this._beneficiarySwift ? true : typeof this._beneficiarySwift === 'string',
            beneficiaryCountry: !this._beneficiaryCountry ? true : typeof this._beneficiaryCountry === 'string',
            beneficiaryCountryCode: !this._beneficiaryCountryCode ? true : typeof this._beneficiaryCountryCode === 'string',
            beneficiaryCity: !this._beneficiaryCity ? true : typeof this._beneficiaryCity === 'string',
            beneficiaryAddress: !this._beneficiaryAddress ? true : typeof this._beneficiaryAddress === 'string',
            beneficiaryPostalCode: !this._beneficiaryPostalCode ? true : typeof this._beneficiaryPostalCode === 'string',
            beneficiaryBankName: !this._beneficiaryBankName ? true : typeof this._beneficiaryBankName === 'string',
            beneficiaryBankCountry: !this._beneficiaryBankCountry ? true : typeof this._beneficiaryBankCountry === 'string',
            beneficiaryBankCity: !this._beneficiaryBankCity ? true : typeof this._beneficiaryBankCity === 'string',
            beneficiaryBankAddress: !this._beneficiaryBankAddress ? true : typeof this._beneficiaryBankAddress === 'string',
            beneficiaryBankPostalCode: !this._beneficiaryBankPostalCode ? true : typeof this._beneficiaryBankPostalCode === 'string',
            beneficiaryBankCountryCode: !this._beneficiaryBankCountryCode ? true : typeof this._beneficiaryBankCountryCode === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
