/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable max-classes-per-file */
/**
 *
 * @typedef {Number|null} TradingPrice
 */

/**
 *
 * @typedef {TradingPrice} PrivateTradingPrice
 * @private
 */

/**
 *
 * @typedef {Class} TradingPriceChange
 *
 * @property {TradingPrice} lastTradingPrice - last trading price
 * @property {TradingPrice} previousTradingPrice - previous trading price
 * @property {String} status - status of change trading price. Calculate based on lastTradingPrice and previousTradingPrice
 * @property {Boolean} isActive
 * @property {Number} shift - difference of lastTradingPrice and previousTradingPrice
 */

export const TradeSides = {
    SELL: {
        value: 'SELL',
        field: 'SELL',
    },
    BUY: {
        value: 'BUY',
        field: 'BUY',
    },
};

/**
 *
 * @type Object
 *
 * @property {String} UP
 * @property {String} DOWN
 * @property {String} NO_CHANGE
 * @property {String} ERROR
 */
export const PriceChangeStatuses = {
    UP: 'Up',
    DOWN: 'Down',
    NO_CHANGE: 'No change',
    ERROR: 'Error',
};

/**
 *
 * @type TradingPriceChange
 */
export class TradingPriceChange {
    /**
     *
     * @type PrivateTradingPrice
     */
    _lastTradingPrice: any = null

    /**
     *
     * @type PrivateTradingPrice
     */
    _previousTradingPrice: any = null

    _interfaceLastTradingPrice: any = null

    _interfacePreviousTradingPrice: any = null

    /**
     *
     * @param {Number} updateDelay - delay in milliseconds
     * @param {TradingPrice} lastTradingPrice
     * @param {TradingPrice} previousTradingPrice
     */
    constructor(updateDelay = 1500, lastTradingPrice = null, previousTradingPrice = null) {
        this._lastTradingPrice = lastTradingPrice;
        this._previousTradingPrice = previousTradingPrice;

        this.setInterfacePricesUpdater(updateDelay);
    }

    /**
     *
     * @return {TradingPrice}
     */
    get lastTradingPrice() {
        return this._interfaceLastTradingPrice;
    }

    /**
     *
     * @return {TradingPrice}
     */
    get previousTradingPrice() {
        return this._interfacePreviousTradingPrice;
    }

    /**
     * Get real last trading price. Regardless of updater delay
     *
     * @return {TradingPrice}
     */
    get realLastTradingPrice() {
        return this._lastTradingPrice;
    }

    /**
     * Get real previous trading price. Regardless of updater delay
     *
     * @return {TradingPrice}
     */
    get realPreviousTradingPrice() {
        return this._previousTradingPrice;
    }

    /**
     *
     * @return {Boolean}
     */
    get isActive() {
        return this.lastTradingPrice !== null && this.previousTradingPrice !== null;
    }

    /**
     *
     * @return {Number}
     */
    get shift() {
        return this.isActive ? this.lastTradingPrice - this.previousTradingPrice : 0;
    }

    /**
     *
     * @return {String}
     */
    get status() {
        if (!this.isActive || this.shift === 0 || this.previousTradingPrice === 0) {
            return PriceChangeStatuses.NO_CHANGE;
        } if (this.shift > 0) {
            return PriceChangeStatuses.UP;
        } if (this.shift < 0) {
            return PriceChangeStatuses.DOWN;
        }

        return PriceChangeStatuses.ERROR;
    }

    /**
     *
     * @param {TradingPrice} price
     */
    set lastTradingPrice(price) {
        this.previousTradingPrice = this.lastTradingPrice !== null ? this.lastTradingPrice : this._lastTradingPrice;

        this._lastTradingPrice = +price;
    }

    /**
     *
     * @param {TradingPrice} price
     */
    set previousTradingPrice(price) {
        this._previousTradingPrice = +price;
    }

    /**
     *
     * @param {Number} updateDelay
     */
    setInterfacePricesUpdater(updateDelay) {
        this.updateInterfacePrices();

        setInterval(() => {
            this.updateInterfacePrices();
        }, updateDelay);
    }

    updateInterfacePrices() {
        this._interfaceLastTradingPrice = this._lastTradingPrice;
        this._interfacePreviousTradingPrice = this._previousTradingPrice;
    }
}

export class TradeHistoryRecord {
    amount

    price

    date

    action

    quoteAsset: any

    exchange

    pair

    side

    ts

    constructor(trade) {
        this.amount = trade.amount;
        this.price = trade.price;
        this.date = Date.getTimeString(+trade.ts);
        this.action = TradeSides[trade.side];
        // eslint-disable-next-line prefer-destructuring
        this.quoteAsset = trade.pair.split('/')[1];
        this.exchange = trade.exchange;
        this.pair = trade.pair;
        this.side = trade.side;
        this.ts = trade.ts;
    }
}
