/*
 *  Root actions
 */

import { init as privateSocketInit } from 'Store/v2/PrivateSocketData';
import { init as publciSocketInit } from 'Store/v2/PublicSocketData';
import { updateBalancesList } from 'Store/v2/Balances';
import { getNewWorkspaceSpotAssetPairs, updateSpotAssetPairsList } from 'Store/v2/AssetPairs';
import { getClosedOrders, getFundings, getOpenOrders, getOrders, getTrades } from 'Store/v2/Futures';

let isPublicSocketInitialized = false;
let isPrivateSocketInitialized = false;

export default {
    /**
     * Download main api data
     * Launch on app created if user is logged in
     * Main api data is: assets list, placements list, accounts list, balances list
     *
     * @param context
     */
    async downloadMainApiData({ dispatch }) {
        try {
            if (!isPrivateSocketInitialized) {
                isPrivateSocketInitialized = true;
                await dispatch(privateSocketInit(undefined), { root: true });
            }
        } catch {
            // code crushed because of network error
        }
        try {
            if (!isPublicSocketInitialized) {
                isPublicSocketInitialized = true;
                await dispatch(publciSocketInit(undefined), { root: true });
            }
        } catch {
            // code crushed because of network error
        }
        try {
            await dispatch('Placements/updatePlacementsList', undefined, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            await dispatch('Accounts/updateAccountsList', undefined, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            await dispatch(updateBalancesList(undefined), { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getOrders', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getClosedOrders', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getOpenOrders', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getTrades', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getOtcOrders', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getOtcClosedOrders', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getOtcOpenOrders', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch('Orders/History/getOtcTrades', { isNew: true, page: 1 }, { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch(getOrders({ isNew: true, page: 1 }), { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch(getClosedOrders({ isNew: true, page: 1 }), { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch(getOpenOrders({ isNew: true, page: 1 }), { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch(getTrades({ isNew: true, page: 1 }), { root: true });
        } catch {
            // code crushed because of network error
        }
        try {
            dispatch(getFundings({ isNew: true, page: 1 }), { root: true });
        } catch {
            // code crushed because of network error
        }
    },
    /**
     * Download terminal api data
     * Launch on terminal page created
     * Terminal api data is: placements tickers, asset pairs list, assets tickers list
     *
     * @param context
     */ async downloadTerminalApiData({ getters, dispatch }) {
        /**
         * Function, that downloading data of placements tickers and asset pairs list
         */
        async function downloadData() {
            await dispatch(updateSpotAssetPairsList({
                placementId: getters['Placements/activeTerminalPlacementId'],
                isFirstDownload: true,
            }));
            await dispatch(getNewWorkspaceSpotAssetPairs(getters['Placements/activeTerminalPlacementName']));
        }

        if (getters['Placements/activeTerminalPlacementId']) {
            await downloadData();
        } else {
            /**
             * First placements list listener id. Need to keep for delete listener after downloading data
             *
             * @type Long
             */

            const listenerId = await dispatch('VuexEventListener/addActionListener', {
                type: 'Placements/setActiveTerminalPlacementId',
                callback: () => {
                    downloadData();
                    dispatch('VuexEventListener/removeActionListener', {
                        type: 'Placements/setActiveTerminalPlacementId',
                        id: listenerId,
                    });
                },
            });
        }
    },
};
