import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import Asset from 'Entities/publicPresenter/Asset';
import PublicDataApi from 'Apis/PublicData';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import StakingProductsRequest from 'Entities/publicPresenter/StakingProductsRequest';
import StakingProduct from 'Entities/publicPresenter/StakingProduct';
import StakingApi from 'Apis/Staking';
import StakingPositionsRequest from 'Entities/privatePresenter/StakingPositionsRequest';
import StakingPosition, { IStakingPosition } from 'Entities/privatePresenter/StakingPosition';

export const allowedEarnPlacements = [
    {
        alias: 'Binance',
        name: 'Binance Simple Earn',
        backgroundImage: require('@/assets/images/icons/earnPlacements/Binance.svg'),
        allowed: true,
    },
    {
        alias: 'Gate.io',
        name: 'Coming Soon',
        backgroundImage: require('@/assets/images/icons/earnPlacements/Gateio.svg'),
        allowed: false,
    },
    {
        alias: 'OKX',
        name: 'Coming Soon',
        backgroundImage: require('@/assets/images/icons/earnPlacements/OKEx.svg'),
        allowed: false,
    },
    {
        alias: 'HTX',
        name: 'Coming Soon',
        backgroundImage: require('@/assets/images/icons/earnPlacements/Huobi.svg'),
        allowed: false,
    },
];

export const supportedDurations: number[] = [30, 60, 90, 120];

export interface ITimeFilter {
    name: string;
    checked: boolean;
}

export interface ISortingType {
    name: 'placement' | 'asset' | 'duration' | 'apr';
    type: 'asc' | 'desc';
}

export interface IAggregatedProduct {
    asset: string;
    duration: Map<number, { minAmount: number; apr: number; }>;
    placementName: string;
    rewardAsset: string;
}

const state = {
    selectedPlacementIndex: 0,
    activePlacementAssets: [] as Asset[],
    productsTimeFilters: [] as ITimeFilter[],
    sortingType: null as null | ISortingType,
    positionsSortingType: null as null | ISortingType,
    products: [] as IAggregatedProduct[],
    positions: [] as StakingPosition[],
    isProductsBlockVisible: true,
    isPositionsBlockVisible: true,
    globalSearchValue: '',
    ui: {
        productsBlockHeight: 9.3,
        positionsBlockHeight: 3.3,
    },
};

export type EarnState = typeof state;

export enum EarnMutations {
    SET_PLACEMENT_INDEX = 'SET_PLACEMENT_INDEX',
    SET_PLACEMENT_ASSETS = 'SET_PLACEMENT_ASSETS',
    SET_TIME_FILTERS = 'SET_TIME_FILTERS',
    SET_SORTING_TYPE = 'SET_SORTING_TYPE',
    SET_PRODUCTS = 'SET_PRODUCTS',
    TOGGLE_PRODUCTS_VISIBLE = 'TOGGLE_PRODUCTS_VISIBLE',
    TOGGLE_POSITIONS_VISIBLE = 'TOGGLE_POSITIONS_VISIBLE',
    SORT_PRODUCTS = 'SORT_PRODUCTS',
    SET_POSITIONS = 'SET_POSITIONS',
    SET_POSITIONS_SORTING_TYPE = 'SET_POSITIONS_SORTING_TYPE',
    SET_GLOBAL_SEARCH_VALUE = 'SET_GLOBAL_SEARCH_VALUE',
    SET_POSITIONS_BLOCK_HEIGHT = 'SET_POSITIONS_BLOCK_HEIGHT',
    SET_PRODUCTS_BLOCK_HEIGHT = 'SET_PRODUCTS_BLOCK_HEIGHT',
}

export const SET_PLACEMENT_INDEX = mutationCreator<number>('Earn', EarnMutations.SET_PLACEMENT_INDEX);
export const SET_PLACEMENT_ASSETS = mutationCreator<Asset[]>('Earn', EarnMutations.SET_PLACEMENT_ASSETS);
export const SET_TIME_FILTERS = mutationCreator<ITimeFilter[]>('Earn', EarnMutations.SET_TIME_FILTERS);
export const SET_SORTING_TYPE = mutationCreator<ISortingType>('Earn', EarnMutations.SET_SORTING_TYPE);
export const SET_POSITIONS_SORTING_TYPE = mutationCreator<ISortingType>('Earn', EarnMutations.SET_POSITIONS_SORTING_TYPE);
export const SET_PRODUCTS = mutationCreator<StakingProduct[]>('Earn', EarnMutations.SET_PRODUCTS);
export const TOGGLE_PRODUCTS_VISIBLE = mutationCreator<undefined>('Earn', EarnMutations.TOGGLE_PRODUCTS_VISIBLE);
export const TOGGLE_POSITIONS_VISIBLE = mutationCreator<undefined>('Earn', EarnMutations.TOGGLE_POSITIONS_VISIBLE);
export const SORT_PRODUCTS = mutationCreator<undefined>('Earn', EarnMutations.SORT_PRODUCTS);
export const SET_POSITIONS = mutationCreator<StakingPosition[]>('Earn', EarnMutations.SET_POSITIONS);
export const SET_GLOBAL_SEARCH_VALUE = mutationCreator<string>('Earn', EarnMutations.SET_GLOBAL_SEARCH_VALUE);
export const SET_POSITIONS_BLOCK_HEIGHT = mutationCreator<number>('Earn', EarnMutations.SET_POSITIONS_BLOCK_HEIGHT);
export const SET_PRODUCTS_BLOCK_HEIGHT = mutationCreator<number>('Earn', EarnMutations.SET_PRODUCTS_BLOCK_HEIGHT);

const mutations: Record<EarnMutations, (state: EarnState, ...args: any) => void> = {
    SET_PLACEMENT_INDEX(state, { payload: index }: ReturnType<typeof SET_PLACEMENT_INDEX>) {
        state.selectedPlacementIndex = index;
    },
    SET_PLACEMENT_ASSETS(state, { payload: assets }: ReturnType<typeof SET_PLACEMENT_ASSETS>) {
        state.activePlacementAssets = assets;
    },
    SET_TIME_FILTERS(state, { payload: filters }: ReturnType<typeof SET_TIME_FILTERS>) {
        state.productsTimeFilters = filters;
    },
    SET_SORTING_TYPE(state, { payload: type }: ReturnType<typeof SET_SORTING_TYPE>) {
        state.sortingType = type;
    },
    SET_POSITIONS_SORTING_TYPE(state, { payload: type }: ReturnType<typeof SET_POSITIONS_SORTING_TYPE>) {
        state.positionsSortingType = type;
    },
    SET_PRODUCTS(state, { payload: products }: ReturnType<typeof SET_PRODUCTS>) {
        const tempMap = new Map<string, IAggregatedProduct>();
        products.forEach((p) => {
            if (tempMap.has(p.asset)) {
                tempMap.get(p.asset)!.duration.set(p.duration, { apr: p.apr, minAmount: p.minAmount });
            } else {
                const newDurationMap = new Map<number, { apr: number; minAmount: number; }>();
                newDurationMap.set(p.duration, { apr: p.apr, minAmount: p.minAmount });
                const temp: IAggregatedProduct = {
                    asset: p.asset,
                    duration: newDurationMap,
                    placementName: p.placementName,
                    rewardAsset: p.rewardAsset,
                };
                tempMap.set(p.asset, temp);
            }
        });
        state.products = Array.from(tempMap.values());
    },
    TOGGLE_PRODUCTS_VISIBLE(state) {
        state.isProductsBlockVisible = !state.isProductsBlockVisible;
    },
    TOGGLE_POSITIONS_VISIBLE(state) {
        state.isPositionsBlockVisible = !state.isPositionsBlockVisible;
    },
    SORT_PRODUCTS(state) {
        state.products = state.products.sort((a, b) => {
            if (state.sortingType?.name === 'asset') {
                if (state.sortingType.type === 'asc') {
                    if (a.asset < b.asset) {
                        return -1;
                    }
                    if (a.asset > b.asset) {
                        return 1;
                    }
                    return 0;
                }
                if (state.sortingType.type === 'desc') {
                    if (a.asset < b.asset) {
                        return 1;
                    }
                    if (a.asset > b.asset) {
                        return -1;
                    }
                    return 0;
                }
            } else if (state.sortingType?.name === 'apr') {
                const maxFirstApr = Math.max(...Array.from(a.duration.values()).map(({ apr }) => apr));
                const maxSecondApr = Math.max(...Array.from(b.duration.values()).map(({ apr }) => apr));
                if (state.sortingType.type === 'asc') {
                    if (maxFirstApr < maxSecondApr) {
                        return -1;
                    }
                    if (maxFirstApr > maxSecondApr) {
                        return 1;
                    }
                    return 0;
                }
                if (state.sortingType.type === 'desc') {
                    if (maxFirstApr < maxSecondApr) {
                        return 1;
                    }
                    if (maxFirstApr > maxSecondApr) {
                        return -1;
                    }
                    return 0;
                }
            }
            if (a.asset < b.asset) {
                return -1;
            }
            if (a.asset > b.asset) {
                return 1;
            }
            return 0;
        });
    },
    SET_POSITIONS(state, { payload: positions }: ReturnType<typeof SET_POSITIONS>) {
        state.positions = positions;
    },
    SET_GLOBAL_SEARCH_VALUE(state, { payload: value }: ReturnType<typeof SET_GLOBAL_SEARCH_VALUE>) {
        state.globalSearchValue = value;
    },
    SET_POSITIONS_BLOCK_HEIGHT(state, { payload: height }: ReturnType<typeof SET_POSITIONS_BLOCK_HEIGHT>) {
        state.ui.positionsBlockHeight = height;
    },
    SET_PRODUCTS_BLOCK_HEIGHT(state, { payload: height }: ReturnType<typeof SET_PRODUCTS_BLOCK_HEIGHT>) {
        state.ui.productsBlockHeight = height;
    },
};

export enum EarnActions {
    getPlacementAssets = 'getPlacementAssets',
    getProducts = 'getProducts',
    getPositions = 'getPositions',
    updateStakingPosition = 'updateStakingPosition',
}

export const getPlacementAssets = actionCreator<string>('Earn', EarnActions.getPlacementAssets);
export const getProducts = actionCreator<undefined>('Earn', EarnActions.getProducts);
export const getPositions = actionCreator<undefined>('Earn', EarnActions.getPositions);
export const updateStakingPosition = actionCreator<IStakingPosition>('Earn', EarnActions.updateStakingPosition);

const actions: Record<EarnActions, (Action<EarnState, any>)> = {
    async getPlacementAssets({ commit }, { payload: placementName }: ReturnType<typeof getPlacementAssets>) {
        let allAssets: Asset[] = [];
        const { data: assets, headers } = await PublicDataApi.publicGetAssets(new AssetsRequest({
            placementName,
            includeTotal: true,
            perPage: 300,
            page: 1,
        }), true);
        allAssets = [...assets];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraAssets } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                        placementName,
                        perPage: 300,
                        page: i,
                    }));
                    allAssets = [...allAssets, ...extraAssets];
                }
            }
        }
        commit(SET_PLACEMENT_ASSETS(allAssets, true));
    },
    async getProducts({ state, commit }) {
        let allProducts: StakingProduct[] = [];
        const { data: products, headers } = await PublicDataApi.publicGetStakingProducts(new StakingProductsRequest({
            placementName: allowedEarnPlacements[state.selectedPlacementIndex].alias,
            orderBy: state.sortingType?.name ?? 'asset',
            ordering: state.sortingType?.type ?? 'asc',
            durations: state.productsTimeFilters.filter(({ name, checked }) => name !== 'All' && checked).map(({ name }) => Number(name)),
            includeTotal: true,
            page: 1,
            perPage: 100,
        }), true);
        allProducts = [...products];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraProducts } = await PublicDataApi.publicGetStakingProducts(new StakingProductsRequest({
                        placementName: allowedEarnPlacements[state.selectedPlacementIndex].alias,
                        orderBy: state.sortingType?.name ?? 'asset',
                        ordering: state.sortingType?.type ?? 'asc',
                        durations: state.productsTimeFilters.filter(({ name, checked }) => name !== 'All' && checked).map(({ name }) => Number(name)),
                        page: i,
                        perPage: 100,
                    }));
                    allProducts = [...allProducts, ...extraProducts];
                }
            }
        }
        commit(SET_PRODUCTS(allProducts, true));
        commit(SORT_PRODUCTS(undefined, true));
    },
    async getPositions({ state, commit, rootGetters }) {
        if (!rootGetters['Accounts/activeAccountID']) {
            return;
        }
        let allPositions: StakingPosition[] = [];
        const { data: positions, headers } = await StakingApi.privateGetStakingPositions(new StakingPositionsRequest({
            accountId: rootGetters['Accounts/activeAccountID'],
            placementName: allowedEarnPlacements[state.selectedPlacementIndex].alias,
            orderBy: state.positionsSortingType?.name ?? 'asset',
            ordering: state.positionsSortingType?.type ?? 'asc',
            includeTotal: true,
            page: 1,
            perPage: 100,
        }), true);
        allPositions = [...positions];
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraPositions } = await StakingApi.privateGetStakingPositions(new StakingPositionsRequest({
                        accountId: rootGetters['Accounts/activeAccountID'],
                        placementName: allowedEarnPlacements[state.selectedPlacementIndex].alias,
                        orderBy: state.positionsSortingType?.name ?? 'asset',
                        ordering: state.positionsSortingType?.type ?? 'asc',
                        page: i,
                        perPage: 100,
                    }));
                    allPositions = [...allPositions, ...extraPositions];
                }
            }
        }
        commit(SET_POSITIONS(allPositions, true));
    },
    updateStakingPosition({ state, rootGetters }, { payload: position }: ReturnType<typeof updateStakingPosition>) {
        if (position.accountId !== rootGetters['Accounts/activeAccountID']) {
            return;
        }
        const positionIndex = state.positions.findIndex(({ uid }) => uid === position.uid);
        if (positionIndex !== -1) {
            if (position.status === 'CLOSED') {
                state.positions.splice(positionIndex, 1);
            } else {
                const temp = state.positions[positionIndex].serialize();
                state.positions.splice(positionIndex, 1, new StakingPosition({ ...temp, ...position }));
            }
        } else {
            state.positions.push(new StakingPosition(position));
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
