import Vue from 'vue';
import VueGridLayout from 'vue-grid-layout';
import VModal from 'vue-js-modal';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import VueRangeSlider from 'vue-range-component';
import VirtualList from 'vue-virtual-scroll-list';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import 'intro.js/introjs.css';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import App from './App.vue';
import './lib/typesExtendes';
import router from './router';
import store from './store';
// connect styles
import './lib/connectors/Styles';
// connect custom components
import './lib/connectors/CustomComponents';
// connect libraries
import './lib/connectors/Libraries';

BACKEND_BASE_URI = process.env.VUE_APP_SERVER_URL!;

let offTransitionStyleIndex: number | undefined = 0;
let offTransitionStyleSheet: HTMLStyleElement | null = null;

VueGridLayout.GridLayout.beforeMount = function bMount() {
    // custom beforeMount code
    offTransitionStyleSheet = document.createElement('style');
    document.head.appendChild(offTransitionStyleSheet);
    offTransitionStyleIndex = offTransitionStyleSheet.sheet?.insertRule('.vue-grid-item { transition: none !important; }');

    this.$on('layout-mounted', () => {
    // postpone to next eventLoop tick
        setTimeout(() => {
            if (typeof offTransitionStyleIndex === 'number' && offTransitionStyleSheet?.sheet) {
                offTransitionStyleSheet.sheet.deleteRule(offTransitionStyleIndex);
            }
        }, 0);
    });

    // default beforeMount code
    this.$emit('layout-before-mount', this.layout);
};

Vue.component('GridLayout', VueGridLayout.GridLayout);
Vue.component('GridItem', VueGridLayout.GridItem);
Vue.component('VueRangeInput', VueRangeSlider);
Vue.component('virtual-list', VirtualList);

Vue.use(VModal);
Vue.use(Vuetify);

Vue.config.productionTip = false;
const dsn = process.env.SENTRY_DSN;

if (dsn !== 'undefined') {
    Sentry.init({
        dsn: dsn || '',
        integrations: [new Integrations.Vue({ Vue, attachProps: true })],
        release: process.env.RELEASE,
    });
}

const app = new Vue({
    store,
    router,
    render: (h) => h(App),
});

store.dispatch('Auth/loadUser').finally(() => {
    app.$mount('#app');
});
