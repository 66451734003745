// This file was autogenerated. Please do not change.
export interface IAccountTradingCommission {
    accountId?: string;
    maker?: number;
    placementName?: string;
    spotAssetPairSymbol?: string;
    taker?: number;
}

export default class AccountTradingCommission {
    readonly _accountId: string | undefined;

    /**
     * Example: AVF2UM6FZN1
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _maker: number | undefined;

    /**
     * Example: 0.1
     */
    get maker(): number | undefined {
        return this._maker;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    readonly _taker: number | undefined;

    /**
     * Example: 0.1
     */
    get taker(): number | undefined {
        return this._taker;
    }

    constructor(props: IAccountTradingCommission) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.maker === 'number') {
            this._maker = props.maker;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
        if (typeof props.taker === 'number') {
            this._taker = props.taker;
        }
    }

    serialize(): IAccountTradingCommission {
        const data: IAccountTradingCommission = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._maker !== 'undefined') {
            data.maker = this._maker;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        if (typeof this._taker !== 'undefined') {
            data.taker = this._taker;
        }
        return data;
    }

    toJSON(): IAccountTradingCommission {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            maker: !this._maker ? true : typeof this._maker === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
            taker: !this._taker ? true : typeof this._taker === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
