
import Vue from 'vue';

import AccountsApi from 'Apis/Accounts';
import PlacementPayload from 'Entities/accountManagement/PlacementPayload';
import ApiError from 'Entities/ApiError';
import Checkbox from 'Control/Checkbox.vue';
import Button from 'Control/Button.vue';
import Icon from 'UI/Icon.vue';

type Data = {
    name: string;
    accept: boolean;
};

type Methods = {
    getName: (data: string) => Promise<void>;
    link: () => Promise<void>;
    close: () => void;
};

type Computed = {
    placementName: string;
};

export default Vue.extend<Data, Methods, Computed>({
    components: { Icon, Button, Checkbox },
    data() {
        return {
            name: '',
            accept: false,
        };
    },
    computed: {
        placementName() {
            return this.$store.state.LinkPlacement.placementName;
        },
    },
    methods: {
        async getName(name) {
            try {
                const { data: representative } = await AccountsApi.extGetLinkRepresentative(new PlacementPayload({
                    tag: this.$store.state.Placements.placementNamesToPlacementTags.get(name.toUpperCase()),
                    id: this.$store.state.User.currentUser.id,
                }));
                this.name = representative.fullName;
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something went wrong');
                }
                this.name = '';
            }
        },
        async link() {
            if (!this.accept) {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Please accept the agreement');
                return;
            }

            await this.$store.dispatch('Accounts/linkPlacement', this.placementName);
            this.$modal.hide('confirmLinkModal');
        },
        close() {
            this.$modal.hide('confirmLinkModal');
        },
    },
    watch: {
        placementName(val) {
            this.getName(val);
        },
    },
});
