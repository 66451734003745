import {
    Placement, setLocalStorageActivePlacementId, getLocalStorageActivePlacementId, hasLocalStorageActivePlacementId,
} from 'Models/placements';
import PlacementEnt from 'Entities/publicPresenter/Placement';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import { setAccountPortfolioActivePlacementId } from 'Store/v2/Balances';
import { getNewWorkspaceSpotAssetPairs } from 'Store/v2/AssetPairs';
import PublicDataApi from 'Apis/PublicData';
import PlacementsRequest from 'Lib/entities/publicPresenter/PlacementsRequest';
import ApiError from 'Entities/ApiError';

export enum RELEASED_PLATFORMS {
    Binance = 'Binance',
    Bitfinex = 'Bitfinex',
    OKEx = 'OKEx',
    OKX = 'OKX',
    Huobi = 'Huobi',
    HTX = 'HTX',
    ByBit = 'ByBit',
    'ByBit Futures' = 'ByBit Futures',
    'Huobi Futures' = 'Huobi Futures',
    'HTX Futures' = 'HTX Futures',
    'Binance Futures' = 'Binance Futures',
}

const state = {
    placements: [],

    activeTerminalPlacementId: null,
    previousActiveTerminalPlacementDataSource: '',

    platformPlacementId: 1,
    platformPlacementName: 'Single Broker',

    lastTickersUpdate: 0,
    tickersUpdatingDelay: 60 * 1000, // 60 seconds

    maintenanceStatuses: new Map<string, boolean>(),
    placementNamesToPlacementTags: new Map<string, string>(),
};

const getters = {
    placements: (state) => state.placements,

    getPlacementById: (state, getters) => (id) => {
        const result = getters.placements.find((placement) => placement.id === id);

        return result || {};
    },
    getPlacementIndexById: (state, getters) => (id) => getters.placements.findIndex((placement) => placement.id === id),
    getPlacementNameById: (state, getters) => (id) => getters.getPlacementById(id).name,

    getPlacementByName: (state, getters) => (name) => {
        const result = getters.placements.find((placement) => placement.name.toUpperCase() === name.toUpperCase());

        return result || {};
    },
    getPlacementIdByName: (state, getters) => (name) => getters.getPlacementByName(name).id,
    getPlacementDataSourceByName: (state, getters) => (name) => getters.getPlacementByName(name).dataSource,
    getOrderTypesByPlacementName: (state, getters) => (placementName) => getters.getPlacementByName(placementName)?.orderTypes || [],

    activeTerminalPlacementId: (state) => state.activeTerminalPlacementId,
    activeTerminalPlacement: (state, getters) => getters.getPlacementById(getters.activeTerminalPlacementId),
    activeTerminalPlacementName: (state, getters) => getters.activeTerminalPlacement?.name || null,
    activeTerminalPlacementOrderTypes: (state, getters) => getters.getOrderTypesByPlacementName(getters.activeTerminalPlacementName),
    activeTerminalPlacementDataSource: (state, getters) => getters.activeTerminalPlacement?.dataSource || null,
    getActivePlacementOrderQuantityTypes: (state, getters) => getters.activeTerminalPlacement?.orderQuantityTypes || [],

    previousActiveTerminalPlacementDataSource: (state) => state.previousActiveTerminalPlacementDataSource,

    platformPlacementId: (state) => state.platformPlacementId,
    platformPlacementName: (state) => state.platformPlacementName,
    platformPlacementIndex: (state, getters) => getters.placements.findIndex((placement) => placement.id === getters.platformPlacementId),
    platformPlacement: (state, getters) => (getters.placements[getters.platformPlacementIndex] ? getters.placements[getters.platformPlacementIndex] : null),

    lastTickersUpdate: (state) => state.lastTickersUpdate,
    tickersUpdatingDelay: (state) => state.tickersUpdatingDelay,

    activeTerminalPlacementTradeCommission: (state, getters) => {
        const placement = getters.activeTerminalPlacement;

        return placement ? {
            commissionMaker: placement.commissionMaker / 100,
            commissionPlatform: placement.commissionPlatform / 100,
            commissionTaker: placement.commissionTaker / 100,
        } : {
            commissionMaker: 0,
            commissionPlatform: 0,
            commissionTaker: 0,
        };
    },
};

const mutations = {
    /**
     * @param state
     * @param {Array} placements
     */
    SET_PLACEMENTS(state, placements) {
        state.placements = placements;
    },
    SET_ACTIVE_TERMINAL_PLACEMENT_ID(state, placementId) {
        state.activeTerminalPlacementId = +placementId;
    },
    SET_PREVIOUS_ACTIVE_TERMINAL_PLACEMENT_DATA_SOURCE(state, dataSource) {
        state.previousActiveTerminalPlacementDataSource = dataSource;
    },
};

const actions = {
    setPlacements({ getters, commit, dispatch }, placements) {
        commit('SET_PLACEMENTS', placements.map((placement) => new Placement(placement)));

        if (getters.activeTerminalPlacementId === null && placements.length > 0) {
            if (hasLocalStorageActivePlacementId() && placements.some(({ id }) => +id === +getLocalStorageActivePlacementId()!)) {
                dispatch('setActiveTerminalPlacementId', getLocalStorageActivePlacementId());
            } else {
                dispatch('setActiveTerminalPlacementId', placements[1].id);
            }
        }
    },
    setPlacementNamesToPlacementTags({ state }, placements: PlacementEnt[]) {
        const newMap = new Map<string, string>();

        placements.forEach(({ name, tag }) => {
            newMap.set(name.toUpperCase(), tag);
        });

        state.placementNamesToPlacementTags = newMap;
    },
    async updatePlacementsList({ dispatch, rootGetters }, { type } = {} as { type?: any }) {
        try {
            const { data } = await PublicDataApi.publicGetPlacements(new PlacementsRequest({
                type,
                quotationSymbol: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
            }));
            await dispatch('getPlacementsStatuses');
            await dispatch('setPlacementNamesToPlacementTags', data);

            dispatch('setPlacements', data.sort(({ type: typeA }, { type: typeB }) => {
                if (typeA === 'self' && typeB !== 'self') {
                    return -1;
                } if (typeA !== 'self' && typeB === 'self') {
                    return 1;
                }
                return 0;
            }));

            return Promise.resolve(data);
        } catch (error) {
            return Promise.reject(error);
        }
    },
    async getPlacementsStatuses({ state, dispatch }) {
        try {
            const { data: statuses } = await PublicDataApi.publicGetMaintenanceStatuses();

            state.maintenanceStatuses = new Map<string, boolean>();
            statuses
                .filter(({ maintenanceScopeName }) => maintenanceScopeName === 'trading')
                .forEach(({ placementName, enabled }) => {
                    state.maintenanceStatuses.set(placementName, enabled);
                });
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            }
        }
    },
    setPreviousActiveTerminalPlacementDataSource({ getters, commit }, placementId) {
        const newActiveTerminalPlacementDataSource = getters.getPlacementById(placementId).dataSource;

        commit('SET_PREVIOUS_ACTIVE_TERMINAL_PLACEMENT_DATA_SOURCE', newActiveTerminalPlacementDataSource);
    },
    async setActiveTerminalPlacementId({ state, commit, dispatch, getters }, placementId) {
        try {
            commit(SET_LOADING_ON(undefined), { root: true });
            commit('SET_ACTIVE_TERMINAL_PLACEMENT_ID', placementId);
            await dispatch('setPreviousActiveTerminalPlacementDataSource', placementId);
            await dispatch(setAccountPortfolioActivePlacementId(placementId), { root: true });
            await dispatch(getNewWorkspaceSpotAssetPairs(getters.getPlacementNameById(placementId)), { root: true });

            setLocalStorageActivePlacementId(placementId);

            const currentActivePlacement = getters.activeTerminalPlacementName;
            if (!state.maintenanceStatuses.get(currentActivePlacement)) {
                for (let i = 0; i < state.placements.length; i += 1) {
                    if (RELEASED_PLATFORMS[state.placements[i].name] && state.maintenanceStatuses.get(state.placements[i].name)) {
                        dispatch('setActiveTerminalPlacementId', state.placements[i].id);
                        break;
                    }
                }
            }
        } finally {
            commit(SET_LOADING_OFF(undefined), { root: true });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
