const state = {
    transactions: [],

    activePage: 1,
    itemsPerPage: 11,
    pagesQuantity: 1,
    totalItemsCount: 0,
};

const getters = {
    transactions: (state) => state.transactions,

    activePage: (state) => state.activePage,
    itemsPerPage: (state) => state.itemsPerPage,
    pagesQuantity: (state) => state.pagesQuantity,
    totalItemsCount: (state) => state.totalItemsCount,
};

const mutations = {
    SET_TRANSACTIONS(state, transactions) {
        state.transactions = transactions;
    },

    SET_ACTIVE_PAGE(state, pageNumber) {
        state.activePage = pageNumber;
    },
    SET_ITEMS_PER_PAGE(state, itemsPerPage) {
        state.itemsPerPage = itemsPerPage;
    },
    SET_PAGES_QUANTITY(state, pagesQuantity) {
        state.pagesQuantity = pagesQuantity;
    },
    SET_TOTAL_ITEMS_COUNT(state, totalItemsCount) {
        state.totalItemsCount = totalItemsCount;
    },
};

const actions = {
    setTransactionsList({ commit }, transactions) {
        commit('SET_TRANSACTIONS', transactions);
    },
    setPaginationData({ commit }, headers) {
        commit('SET_PAGES_QUANTITY', headers['x-total-pages'] ? +headers['x-total-pages'] : 1);
        commit('SET_TOTAL_ITEMS_COUNT', headers['x-total-count'] ? +headers['x-total-count'] : 1);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
