// This file was autogenerated. Please do not change.
export interface IFuturesOrdersRequest {
    accountId: string;
    accountIds?: string[];
    fromTimestamp?: number;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementName?: string;
    positionSide?: ('LONG' | 'SHORT');
    spotAssetPairSymbol?: string;
    status?: string;
    statuses?: string[];
    toTimestamp?: number;
    type?: ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | 'FUTURES_MARKET_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS');
}

export default class FuturesOrdersRequest {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _accountIds: string[] | undefined;

    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _fromTimestamp: number | undefined;

    /**
     * Example: 1660302058
     */
    get fromTimestamp(): number | undefined {
        return this._fromTimestamp;
    }

    readonly _includeTotal: boolean | undefined;

    /**
     * Example: true
     */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /**
     * Example: 1
     */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /**
     * Example: 30
     */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _positionSide: ('LONG' | 'SHORT') | undefined;

    /**
     * Example: LONG
     */
    get positionSide(): ('LONG' | 'SHORT') | undefined {
        return this._positionSide;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: XLM/BTC
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    readonly _statuses: string[] | undefined;

    get statuses(): string[] | undefined {
        return this._statuses;
    }

    readonly _toTimestamp: number | undefined;

    /**
     * Example: 1660302058
     */
    get toTimestamp(): number | undefined {
        return this._toTimestamp;
    }

    readonly _type: ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | 'FUTURES_MARKET_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS') | undefined;

    /**
     * Example: FUTURES_MARKET
     */
    get type(): ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | 'FUTURES_MARKET_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS') | undefined {
        return this._type;
    }

    constructor(props: IFuturesOrdersRequest) {
        this._accountId = props.accountId.trim();
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        if (typeof props.fromTimestamp === 'number') {
            this._fromTimestamp = props.fromTimestamp;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (props.positionSide) {
            this._positionSide = props.positionSide;
        }
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (props.statuses) {
            this._statuses = props.statuses;
        }
        if (typeof props.toTimestamp === 'number') {
            this._toTimestamp = props.toTimestamp;
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): IFuturesOrdersRequest {
        const data: IFuturesOrdersRequest = {
            accountId: this._accountId,
        };
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        if (typeof this._fromTimestamp !== 'undefined') {
            data.fromTimestamp = this._fromTimestamp;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._positionSide !== 'undefined') {
            data.positionSide = this._positionSide;
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._statuses !== 'undefined') {
            data.statuses = this._statuses;
        }
        if (typeof this._toTimestamp !== 'undefined') {
            data.toTimestamp = this._toTimestamp;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): IFuturesOrdersRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            fromTimestamp: !this._fromTimestamp ? true : typeof this._fromTimestamp === 'number',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            statuses: !this._statuses ? true : this._statuses.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            toTimestamp: !this._toTimestamp ? true : typeof this._toTimestamp === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
