import AccountInfo, { IAccountInfo } from './AccountInfo';

// This file was autogenerated. Please do not change.
export interface IShareAccountPayload {
    account: IAccountInfo;
    message?: string;
    policies?: string[];
    recipient?: string;
    recipientEmail?: string;
    role?: string;
    totp: string;
}

export default class ShareAccountPayload {
    readonly _account: AccountInfo;

    get account(): AccountInfo {
        return this._account;
    }

    readonly _message: string | undefined;

    /** Description: Message to recipient */
    get message(): string | undefined {
        return this._message;
    }

    readonly _policies: string[] | undefined;

    /** Description: Allowed actions on shared account */
    get policies(): string[] | undefined {
        return this._policies;
    }

    readonly _recipient: string | undefined;

    /** Description: Recipient unique identifier */
    get recipient(): string | undefined {
        return this._recipient;
    }

    readonly _recipientEmail: string | undefined;

    /** Description: User's role on shared account */
    get recipientEmail(): string | undefined {
        return this._recipientEmail;
    }

    readonly _role: string | undefined;

    /** Description: User's role on shared account */
    get role(): string | undefined {
        return this._role;
    }

    readonly _totp: string;

    /** Description: One-time code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IShareAccountPayload) {
        this._account = new AccountInfo(props.account);
        if (typeof props.message === 'string') {
            this._message = props.message.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
        if (typeof props.recipient === 'string') {
            this._recipient = props.recipient.trim();
        }
        if (typeof props.recipientEmail === 'string') {
            this._recipientEmail = props.recipientEmail.trim();
        }
        if (typeof props.role === 'string') {
            this._role = props.role.trim();
        }
        this._totp = props.totp.trim();
    }

    serialize(): IShareAccountPayload {
        const data: IShareAccountPayload = {
            account: this._account.serialize(),
            totp: this._totp,
        };
        if (typeof this._message !== 'undefined') {
            data.message = this._message;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        if (typeof this._recipient !== 'undefined') {
            data.recipient = this._recipient;
        }
        if (typeof this._recipientEmail !== 'undefined') {
            data.recipientEmail = this._recipientEmail;
        }
        if (typeof this._role !== 'undefined') {
            data.role = this._role;
        }
        return data;
    }

    toJSON(): IShareAccountPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            account: this._account.validate().length === 0,
            message: !this._message ? true : typeof this._message === 'string',
            role: !this._role ? true : typeof this._role === 'string',
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            recipientEmail: !this._recipientEmail ? true : typeof this._recipientEmail === 'string',
            recipient: !this._recipient ? true : typeof this._recipient === 'string',
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
