// This file was autogenerated. Please do not change.
/* A response for api-key */
export interface ICreateKeyResponse {
    apiKey: string;
    name: string;
    scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];
    secret: string;
}

export default class CreateKeyResponse {
    readonly _apiKey: string;

    /** Description: API-key */
    get apiKey(): string {
        return this._apiKey;
    }

    readonly _name: string;

    /** Description: API-key name */
    get name(): string {
        return this._name;
    }

    readonly _scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];

    /** Description: API-key scopes */
    get scopes(): ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[] {
        return this._scopes;
    }

    readonly _secret: string;

    /** Description: Secret key */
    get secret(): string {
        return this._secret;
    }

    constructor(props: ICreateKeyResponse) {
        this._apiKey = props.apiKey.trim();
        this._name = props.name.trim();
        this._scopes = props.scopes;
        this._secret = props.secret.trim();
    }

    serialize(): ICreateKeyResponse {
        const data: ICreateKeyResponse = {
            apiKey: this._apiKey,
            name: this._name,
            scopes: this._scopes,
            secret: this._secret,
        };
        return data;
    }

    toJSON(): ICreateKeyResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            apiKey: typeof this._apiKey === 'string' && !!this._apiKey.trim(),
            name: typeof this._name === 'string' && !!this._name.trim(),
            secret: typeof this._secret === 'string' && !!this._secret.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
