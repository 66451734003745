// This file was autogenerated. Please do not change.
export interface IUpdatePresetRequest {
    data: undefined;
    description?: string;
    id: number;
    name: string;
    type: ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE');
}

export default class UpdatePresetRequest {
    readonly _data: undefined;

    get data(): undefined {
        return this._data;
    }

    readonly _description: string | undefined;

    get description(): string | undefined {
        return this._description;
    }

    readonly _id: number;

    get id(): number {
        return this._id;
    }

    readonly _name: string;

    get name(): string {
        return this._name;
    }

    readonly _type: ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE');

    get type(): ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE') {
        return this._type;
    }

    constructor(props: IUpdatePresetRequest) {
        this._data = props.data;
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._id = props.id;
        this._name = props.name.trim();
        this._type = props.type;
    }

    serialize(): IUpdatePresetRequest {
        const data: IUpdatePresetRequest = {
            data: this._data,
            id: this._id,
            name: this._name,
            type: this._type,
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        return data;
    }

    toJSON(): IUpdatePresetRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            description: !this._description ? true : typeof this._description === 'string',
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
