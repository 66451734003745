import ApiError from 'Entities/ApiError';
import TokenResponse from 'Entities/sumsubKycIntegration/TokenResponse';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class VerificationApi {
    static async getToken(withHeaders?: boolean): Promise<{ data: TokenResponse; headers?: Headers }> {
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/kyc/getToken`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`},
        });
        if (res.status === 200) {
            return { data: new TokenResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Verification/getToken', { response: res, data: result, fields: [] });
    }
}
