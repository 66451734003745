/**
 * @typedef {Object} NewEventListener
 *
 * @property {String} type - listener type. For example: "ModuleName/SubModuleName/(action|mutation)"
 * @property {Function} callback - the function that is called when the event is called
 * @property {Boolean} once - if `true`, listener will be remove after fire
 */

/**
 *
 * @typedef {Object} VuexEventListener
 *
 * @property {Long} id - unique id of listener. Need to unsubscribe listener
 * @property {Function} callback - the function that is called when the event is called
 *
 */

/**
 *
 * @typedef {Object} RegisteredEvent
 *
 * @property {String} type - name of event
 * @property {Array.<VuexEventListener>} listeners - list of listeners
 *
 */

const state = {

    /**
     * @name registeredMutationsEvents
     * @type {Array.<RegisteredEvent>}
     */

    registeredMutationsEvents: [],

    /**
     * @name registeredActionsEvents
     * @type {Array.<RegisteredEvent>}
     */

    registeredActionsEvents: [],

};

const getters = {
    registeredMutationsEvents: (state) => state.registeredMutationsEvents,
    registeredActionsEvents: (state) => state.registeredActionsEvents,
};

const mutations = {
    ADD_MUTATION_LISTENER(state, { type, callback, id }) {
        const currentEvent = state.registeredMutationsEvents.find((event) => event.type === type);
        const currentListener = {
            id,
            callback,
        };

        if (currentEvent) {
            currentEvent.listeners.push(currentListener);
        } else {
            state.registeredMutationsEvents.push({
                type,
                listeners: [currentListener],
            });
        }
    },
    ADD_ACTION_LISTENER(state, { type, callback, id }) {
        const currentEvent = state.registeredActionsEvents.find((event) => event.type === type);
        const currentListener = {
            id,
            callback,
        };

        if (currentEvent) {
            currentEvent.listeners.push(currentListener);
        } else {
            state.registeredActionsEvents.push({
                type,
                listeners: [currentListener],
            });
        }
    },
    REMOVE_MUTATION_LISTENER(state, { type, id }) {
        const currentEvent = state.registeredMutationsEvents.find((event) => event.type === type);

        currentEvent.listeners.splice(currentEvent.listeners.findIndex((listener) => listener.id === id), 1);
    },
    REMOVE_ACTION_LISTENER(state, { type, id }) {
        const currentEvent = state.registeredActionsEvents.find((event) => event.type === type);

        currentEvent.listeners.splice(currentEvent.listeners.findIndex((listener) => listener.id === id), 1);
    },
};

const actions = {
    /**
     *
     * @param context
     * @param {NewEventListener} eventListenerData
     */
    addMutationListener({ commit, rootGetters }, { type, callback }) {
        const id = rootGetters.generateId();

        commit('ADD_MUTATION_LISTENER', {
            type,
            callback,
            id,
        });

        return id;
    },
    /**
     *
     * @param context
     * @param {NewEventListener} eventListenerData
     */
    addActionListener({ commit, rootGetters, dispatch }, { type, callback, once }) {
        const id = rootGetters.generateId();

        commit('ADD_ACTION_LISTENER', {
            type,
            callback: once ? (...data) => {
                callback(...data);
                dispatch('removeActionListener', {
                    type,
                    id,
                });
            } : callback,
            id,
        });

        return id;
    },
    removeMutationListener({ commit }, { type, id }) {
        commit('REMOVE_MUTATION_LISTENER', {
            type,
            id,
        });
    },
    removeActionListener({ commit }, { type, id }) {
        commit('REMOVE_ACTION_LISTENER', {
            type,
            id,
        });
    },
    mutationHandler({ getters }, { mutation, state }) {
        const currentEvent = getters.registeredMutationsEvents.find((event) => event.type === mutation.type);

        if (currentEvent) {
            currentEvent.listeners.forEach((listener) => {
                listener.callback(mutation, state);
            });
        }
    },
    actionHandler({ getters }, { action, state }) {
        const currentEvent = getters.registeredActionsEvents.find((event) => event.type === action.type);

        if (currentEvent) {
            currentEvent.listeners.forEach((listener) => {
                listener.callback(action, state);
            });
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
