// This file was autogenerated. Please do not change.
export interface IMFAGroupPayload {
    emailCode: string;
    groupId: string;
    totp: string;
}

export default class MFAGroupPayload {
    readonly _emailCode: string;

    /** Description: Email verification code */
    get emailCode(): string {
        return this._emailCode;
    }

    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    readonly _totp: string;

    /** Description: One-time verification code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IMFAGroupPayload) {
        this._emailCode = props.emailCode.trim();
        this._groupId = props.groupId.trim();
        this._totp = props.totp.trim();
    }

    serialize(): IMFAGroupPayload {
        const data: IMFAGroupPayload = {
            emailCode: this._emailCode,
            groupId: this._groupId,
            totp: this._totp,
        };
        return data;
    }

    toJSON(): IMFAGroupPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
            emailCode: typeof this._emailCode === 'string' && !!this._emailCode.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
