// This file was autogenerated. Please do not change.
export interface ITotalPaidRebatesRequest {
    accountId?: string;
    quotationAsset: string;
}

export default class TotalPaidRebatesRequest {
    readonly _accountId: string | undefined;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _quotationAsset: string;

    /**
     * Example: USD
     */
    get quotationAsset(): string {
        return this._quotationAsset;
    }

    constructor(props: ITotalPaidRebatesRequest) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        this._quotationAsset = props.quotationAsset.trim();
    }

    serialize(): ITotalPaidRebatesRequest {
        const data: ITotalPaidRebatesRequest = {
            quotationAsset: this._quotationAsset,
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        return data;
    }

    toJSON(): ITotalPaidRebatesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            quotationAsset: typeof this._quotationAsset === 'string' && !!this._quotationAsset.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
