// This file was autogenerated. Please do not change.
/* A payload to create an API-key */
export interface ICreateKeyPayload {
    name: string;
    scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];
    totp: string;
}

export default class CreateKeyPayload {
    readonly _name: string;

    /** Description: API-key name */
    get name(): string {
        return this._name;
    }

    readonly _scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];

    /** Description: Requested API-key scopes */
    get scopes(): ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[] {
        return this._scopes;
    }

    readonly _totp: string;

    /** Description: One-time verification code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: ICreateKeyPayload) {
        this._name = props.name.trim();
        this._scopes = props.scopes;
        this._totp = props.totp.trim();
    }

    serialize(): ICreateKeyPayload {
        const data: ICreateKeyPayload = {
            name: this._name,
            scopes: this._scopes,
            totp: this._totp,
        };
        return data;
    }

    toJSON(): ICreateKeyPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
            name: typeof this._name === 'string' && !!this._name.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
