<template>
    <div @scroll="onScroll($event)">
        <div :class="s.header">
            <slot name="header"></slot>
            <Search
                v-if="showSearch"
                @on-search="searchQuery = $event"
                @toggle-show-search="setShowSearch($event)"
                :class="{ [s.w100]: !showHeader }"
            />
        </div>
        <draggable
            v-model="accountList"
            v-bind="dragOptions"
            :class="[s.list, { [s.show]: listShow }]"
        >
            <Account
                v-for="account in accountList"
                :key="account.id"
                :account="account"
                is-dragging
                @show="showAccountTooltip($event)"
                class="left-menu-account"
                @select="moveBackground($event)"
            />
            <div v-if="isAnyAccountSelected" ref="background" :class="s.background"></div>
            <p :class="s.noItems" v-if="!accountList.length">
                {{ noItemsLabel }}
            </p>
        </draggable>
        <div
            ref="tooltip"
            class="account-tooltip"
            :class="[s.tooltip, { [s.faded]: fadeTooltip }]"
            v-if="showTooltip"
            :style="{ left: `${tooltipPosition.x + 9}px`, top: `${tooltipPosition.y}px` }"
        >
            <AccountSettingsPopover
                :account="account"
                :hide-popover="hideAccountTooltip"
            />
        </div>
        <div :class="[s.settings, { [s.show]: listShow }]">
            <slot name="account-settings"></slot>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable';
import { mapGetters } from 'vuex';

import Search from 'Control/Search.vue';
import AccountSettingsPopover from 'Components/LeftMenu/AccountSettings/AccountSettingsPopover.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';

import Account from './Account.vue';

export default {
    name: 'LeftMenuGroup',
    components: {
        draggable,
        AccountSettingsPopover,
        Search,
        Account,
    },
    props: {
        accountsOrder: {
            type: Array,
            default: () => [],
        },
        isOwned: {
            type: Boolean,
            required: true,
        },
        accounts: {
            type: Array,
            required: true,
        },
        noItemsLabel: {
            type: String,
            default: 'No accounts',
        },
        listShow: {
            type: Boolean,
            required: true,
        },
        showSearch: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            searchQuery: '',
            isSearchFullWidth: false,
            dragOptions: {
                animation: 250,
                group: 'manage',
                disabled: false,
                ghostClass: 'ghost',
                handle: '.drag-handle',
                filter: '.ignore-elements',
            },
            account: null,
            tooltipPosition: {
                x: 0,
                y: 0,
            },
            showTooltip: false,
            fadeTooltip: true,
            showHeader: true,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        isAnyAccountSelected() {
            return this.accountList.some(({ id }) => id === this.activeAccountId);
        },
        accountList: {
            get() {
                let result = [];
                const accountsMap = new Map();
                this.accounts.forEach((a) => {
                    accountsMap.set(a.id, a);
                });

                this.accountsOrder.forEach((a) => {
                    if (accountsMap.get(a) && accountsMap.get(a).isMain) {
                        result.unshift(accountsMap.get(a));
                    } else {
                        result.push(accountsMap.get(a));
                    }
                    accountsMap.delete(a);
                });

                const accounts = Array.from(accountsMap.values());
                result = [...accounts.filter((a) => a.isMain), ...result, ...accounts.filter((a) => !a.isMain)];

                return result.filter((item) => (this.searchQuery ? item && item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) : item));
            },
            async set(accounts) {
                if (this.isOwned) {
                    await this.$store.dispatch('setOwnedAccountsOrder', accounts.map((a) => a.id));
                    return;
                }
                await this.$store.dispatch('setManagedAccountsOrder', accounts.map((a) => a.id));
            },
        },
    },
    methods: {
        moveBackground(accountRef) {
            const { background } = this.$refs;
            const rects = accountRef.getBoundingClientRect();
            background.style.top = `${accountRef.offsetTop}px`;
            background.style.width = `${rects.width}px`;
            background.style.height = `${rects.height}px`;
        },
        setSearchWidth(isSearchActive) {
            this.isSearchFullWidth = isSearchActive;
            this.$emit('set-search-width', isSearchActive);
        },
        showAccountTooltip(data) {
            this.account = data.account;
            this.tooltipPosition.x = data.event.x;
            this.showTooltip = true;
            setTimeout(() => {
                if (window.innerHeight < data.event.y + this.$refs.tooltip.clientHeight + 115) {
                    this.tooltipPosition.y = data.event.y - this.$refs.tooltip.clientHeight - 9;
                } else {
                    this.tooltipPosition.y = data.event.y + 9;
                }
                this.fadeTooltip = false;
            }, 10);
        },
        hideAccountTooltip() {
            this.showTooltip = false;
            this.fadeTooltip = true;
        },
        onScroll() {
            this.hideAccountTooltip();
        },
        setShowSearch(show) {
            if (show) {
                this.showHeader = false;
            } else {
                setTimeout(() => {
                    this.showHeader = true;
                }, 350);
            }
        },
    },
    mounted() {
        document.addEventListener('click', (event) => {
            const path = composedPath(event.target);
            if (path) {
                const flag = path.some(({ classList }) => (classList ? Array.from(classList).includes('account-tooltip') || Array.from(classList).includes('cogwheel') : false));
                if (!flag) {
                    this.showTooltip = false;
                    this.fadeTooltip = true;
                }
            }
        });
    },
    watch: {
        isSearchFullWidth(value) {
            this.$emit('set-search-width', value);
        },
    },
};
</script>

<style lang="postcss" module="s">
.tooltip {
    position: fixed;
    background-color: var(--cl-white);
    padding: var(--m-m) var(--m-s);
    border-radius: 8px;
    box-shadow: var(--popover-box-shadox);
    z-index: 9999;
    width: 250px;
    &.faded {
        opacity: 0;
    }
}
.header {
    position: sticky;
    top: 0;
    background: var(--cl-white);
    z-index: 999;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row;
    justify-content: space-between;
    padding: var(--m-m) var(--m-s) var(--m-m) var(--m-xxl);
    & .w100 {
        min-width: 100%;
        margin-bottom: var(--m-s);
    }
}
.settings {
    position: sticky;
    bottom: 0;
    z-index: 0;
    background: unset;
    padding: 0;
    &.show {
        z-index: 999;
        background: var(--cl-white);
        padding: var(--m-l) var(--m-m);
    }
}
.list {
    height: 0;
    opacity: 0;
    transition: transform var(--transition);
    position: relative;
    &.show {
        height: auto;
        opacity: 1;
    }
}
.noItems {
    padding: var(--m-s) var(--m-xxl);
    font-weight: var(--fw-bold);
    font-size: var(--fs-m);
    line-height: var(--fs-m);
    color: var(--cl-gray);
}
.background {
    position: absolute;
    z-index: 1;
    background: linear-gradient(88.61deg, var(--cl-violet-light) 9.34%, rgba(101, 111, 219, 0) 98.98%);
    transition: top var(--transition-slow);
}
</style>
