const makeColor = (string: string): string => {
    let firstCode: number;
    let secondCode: number;
    let thirdCode: number;
    if (string.length > 2) {
        firstCode = (string.charCodeAt(0) * 42) % 255;
        secondCode = (string.charCodeAt(1) * 42) % 255;
        thirdCode = (string.charCodeAt(2) * 42) % 255;
        return `rgb(${firstCode}, ${secondCode}, ${thirdCode})`;
    }
    firstCode = (string.charCodeAt(0) * 42) % 255;
    secondCode = (string.charCodeAt(1) * 42) % 255;
    thirdCode = 42;
    return `rgb(${firstCode}, ${secondCode}, ${thirdCode})`;
};

export { makeColor };
