import TransferStatus, { ITransferStatus } from './TransferStatus';
import TransferType, { ITransferType } from './TransferType';
import TransfersCommission, { ITransfersCommission } from './TransfersCommission';

// This file was autogenerated. Please do not change.
export interface ITransfersSummary {
    commission?: ITransfersCommission;
    statuses?: ITransferStatus[];
    types?: ITransferType[];
}

export default class TransfersSummary {
    readonly _commission: TransfersCommission | undefined;

    get commission(): TransfersCommission | undefined {
        return this._commission;
    }

    readonly _statuses: TransferStatus[] | undefined;

    get statuses(): TransferStatus[] | undefined {
        return this._statuses;
    }

    readonly _types: TransferType[] | undefined;

    get types(): TransferType[] | undefined {
        return this._types;
    }

    constructor(props: ITransfersSummary) {
        if (props.commission) {
            this._commission = new TransfersCommission(props.commission);
        }
        if (props.statuses) {
            this._statuses = props.statuses.map((p) => new TransferStatus(p));
        }
        if (props.types) {
            this._types = props.types.map((p) => new TransferType(p));
        }
    }

    serialize(): ITransfersSummary {
        const data: ITransfersSummary = {
        };
        if (typeof this._commission !== 'undefined') {
            data.commission = this._commission.serialize();
        }
        if (typeof this._statuses !== 'undefined') {
            data.statuses = this._statuses.map((p) => p.serialize());
        }
        if (typeof this._types !== 'undefined') {
            data.types = this._types.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): ITransfersSummary {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            commission: !this._commission ? true : this._commission.validate().length === 0,
            statuses: !this._statuses ? true : this._statuses.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            types: !this._types ? true : this._types.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
