const pageHeader = 'x-page'; // currentPage
const perPageHeader = 'x-per-page'; // elems per page
const countHeader = 'x-count'; // number of elems in response
const totalHeader = 'x-total-count'; // total number of elems
const totalPageHeader = 'x-total-pages'; // total number of pages

export const parsePaginationHeaders = (headers: Headers) => {
    const page = Number(headers.get(pageHeader));
    const perPage = Number(headers.get(perPageHeader));
    const count = Number(headers.get(countHeader));
    const total = Number(headers.get(totalHeader));
    const totalPage = Number(headers.get(totalPageHeader));

    return {
        page: Number.isNaN(page) ? undefined : page,
        perPage: Number.isNaN(perPage) ? undefined : perPage,
        count: Number.isNaN(count) ? undefined : count,
        total: Number.isNaN(total) ? undefined : total,
        totalPage: Number.isNaN(totalPage) ? undefined : totalPage,
    };
};
