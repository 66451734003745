import { Action } from 'vuex';

import FuturesPosition, { IFuturesPosition } from 'Entities/privatePresenter/FuturesPosition';
import { actionCreator, mutationCreator } from 'Store/utils';
import FuturesApi from 'Apis/Futures';
import PlaceOrderRequest from 'Entities/futuresOrderRegistrar/PlaceOrderRequest';
import FuturesOrderPresenter, { IFuturesOrderPresenter } from 'Entities/privatePresenter/FuturesOrderPresenter';

export interface IPlaceOrderPayload {
    orderType: 'Limit' | 'Market';
    takeProfitTriggerPrice?: number;
    takeProfitPrice?: number;
    stopLossTriggerPrice?: number;
    stopLossPrice?: number;
}

const state = {
    position: null as null | FuturesPosition,
    activeStopLossOrder: null as null | FuturesOrderPresenter,
    activeTakeProfitOrder: null as null | FuturesOrderPresenter,
};

export type TakeProfitStopLossModalState = typeof state;

export enum TakeProfitStopLossModalMutations {
    SET_POSITION = 'SET_POSITION',
    SET_TAKE_PROFIT_ORDER = 'SET_TAKE_PROFIT_ORDER',
    SET_STOP_LOSS_ORDER = 'SET_STOP_LOSS_ORDER',
}

export const SET_POSITION = mutationCreator<FuturesPosition>('TakeProfitStopLossModal', TakeProfitStopLossModalMutations.SET_POSITION);
export const SET_TAKE_PROFIT_ORDER = mutationCreator<FuturesOrderPresenter | null>('TakeProfitStopLossModal', TakeProfitStopLossModalMutations.SET_TAKE_PROFIT_ORDER);
export const SET_STOP_LOSS_ORDER = mutationCreator<FuturesOrderPresenter | null>('TakeProfitStopLossModal', TakeProfitStopLossModalMutations.SET_STOP_LOSS_ORDER);

const mutations: Record<TakeProfitStopLossModalMutations, (state: TakeProfitStopLossModalState, ...args: any[]) => void> = {
    SET_POSITION(state, { payload: position }) {
        state.position = position;
    },
    SET_TAKE_PROFIT_ORDER(state, { payload: order }) {
        state.activeTakeProfitOrder = order;
    },
    SET_STOP_LOSS_ORDER(state, { payload: order }) {
        state.activeStopLossOrder = order;
    },
};

export enum TakeProfitStopLossModalActions {
    placeOrder = 'placeOrder',
    updateModalPosition = 'updateModalPosition',
    updateModalOrders = 'updateModalOrders',
}

export const placeOrder = actionCreator<IPlaceOrderPayload>('TakeProfitStopLossModal', TakeProfitStopLossModalActions.placeOrder);
export const updateModalPosition = actionCreator<IFuturesPosition>('TakeProfitStopLossModal', TakeProfitStopLossModalActions.updateModalPosition);
export const updateModalOrders = actionCreator<IFuturesOrderPresenter>('TakeProfitStopLossModal', TakeProfitStopLossModalActions.updateModalOrders);

const actions: Record<TakeProfitStopLossModalActions, (Action<TakeProfitStopLossModalState, any>)> = {
    async placeOrder({ state, dispatch, rootState, rootGetters }, { payload: { orderType, takeProfitTriggerPrice, takeProfitPrice, stopLossTriggerPrice, stopLossPrice } }) {
        const addOrder = (order, type) => {
            let tempOrder: IFuturesOrderPresenter = order.serialize();
            tempOrder = { ...tempOrder, balanceId: '', initiatorId: '', initiatorType: '', orderType: type };
            rootState.Futures.orders.unshift(new FuturesOrderPresenter(tempOrder));
            if (tempOrder.status.toLowerCase() === 'rejected') {
                dispatch('Notificator/showWarningNotification', `Order ${tempOrder.id} has been ${tempOrder.status}`, { root: true });
            } else {
                dispatch('Notificator/showSuccessNotification', `Order ${tempOrder.id} has been ${tempOrder.status}`, { root: true });
            }
        };

        if (orderType === 'Limit') {
            if (takeProfitTriggerPrice) {
                const { data: order } = await FuturesApi.privateFuturesPlaceOrder(new PlaceOrderRequest({
                    accountId: rootGetters['Accounts/activeAccountID'],
                    contractSymbol: state.position?.contractSymbol ?? '',
                    leverage: Number(state.position?.leverage) ?? 1,
                    placementName: state.position?.placementName ?? '',
                    positionSide: state.position?.side ?? '',
                    side: 'SELL',
                    type: 'TAKE_PROFIT',
                    quantity: String(state.position?.quantity ?? 1),
                    triggerPrice: String(takeProfitTriggerPrice),
                    price: String(takeProfitPrice),
                }));
                addOrder(order, 'TAKE_PROFIT');
            }
            if (stopLossTriggerPrice) {
                const { data: order } = await FuturesApi.privateFuturesPlaceOrder(new PlaceOrderRequest({
                    accountId: rootGetters['Accounts/activeAccountID'],
                    contractSymbol: state.position?.contractSymbol ?? '',
                    leverage: Number(state.position?.leverage) ?? 1,
                    placementName: state.position?.placementName ?? '',
                    positionSide: state.position?.side ?? '',
                    side: 'SELL',
                    type: 'STOP_LOSS',
                    quantity: String(state.position?.quantity ?? 1),
                    triggerPrice: String(stopLossTriggerPrice),
                    price: String(stopLossPrice),
                }));
                addOrder(order, 'STOP_LOSS');
            }
        } else {
            if (takeProfitTriggerPrice) {
                const { data: order } = await FuturesApi.privateFuturesPlaceOrder(new PlaceOrderRequest({
                    accountId: rootGetters['Accounts/activeAccountID'],
                    contractSymbol: state.position?.contractSymbol ?? '',
                    leverage: Number(state.position?.leverage) ?? 1,
                    placementName: state.position?.placementName ?? '',
                    positionSide: state.position?.side ?? '',
                    side: 'SELL',
                    type: 'TAKE_PROFIT',
                    quantity: String(state.position?.quantity ?? 1),
                    triggerPrice: String(takeProfitTriggerPrice),
                }));
                addOrder(order, 'TAKE_PROFIT');
            }
            if (stopLossTriggerPrice) {
                const { data: order } = await FuturesApi.privateFuturesPlaceOrder(new PlaceOrderRequest({
                    accountId: rootGetters['Accounts/activeAccountID'],
                    contractSymbol: state.position?.contractSymbol ?? '',
                    leverage: Number(state.position?.leverage) ?? 1,
                    placementName: state.position?.placementName ?? '',
                    positionSide: state.position?.side ?? '',
                    side: 'SELL',
                    type: 'STOP_LOSS',
                    quantity: String(state.position?.quantity ?? 1),
                    triggerPrice: String(stopLossTriggerPrice),
                }));
                addOrder(order, 'STOP_LOSS');
            }
        }
    },
    updateModalPosition({ state }, { payload: position }) {
        if (!state.position
            || state.position.accountId !== position.accountId
            || state.position.contractSymbol !== position.contractSymbol
            || state.position.placementName !== position.placementName
            || state.position.side !== position.side) {
            return;
        }
        let tempPosition = state.position.serialize();
        tempPosition = { ...tempPosition, ...position, leverage: String(position.leverage) };
        state.position = new FuturesPosition(tempPosition);
    },
    updateModalOrders({ state }, { payload: order }) {
        if (state.position) {
            if (order.positionSide?.toLowerCase() === state.position.side.toLowerCase()
                && order.contractSymbol === state.position.contractSymbol
                && order.placementName === state.position.placementName
                && order.accountId === state.position.accountId) {
                if (order.orderType === 'TAKE_PROFIT') {
                    if (['REGISTERED', 'PLACED', 'PARTIALLY_FILLED'].indexOf(order.status) !== -1) {
                        state.activeTakeProfitOrder = order;
                    } else {
                        state.activeTakeProfitOrder = null;
                    }
                } else if (order.orderType === 'STOP_LOSS') {
                    if (['REGISTERED', 'PLACED', 'PARTIALLY_FILLED'].indexOf(order.status) !== -1) {
                        state.activeStopLossOrder = order;
                    } else {
                        state.activeStopLossOrder = null;
                    }
                }
            }
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
