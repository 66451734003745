
import Vue from 'vue';
import { mapGetters } from 'vuex';

interface Props {
    color: string;
    size: string | number;
    isActive: boolean;
    noMargin: boolean;
    withName?: {
        name: string;
        description: string;
    };
    needOutline?: boolean;
}

export default Vue.extend<any, any, any, Props>({
    props: {
        color: {
            type: String,
            required: true,
        },
        size: {
            type: [Number, String],
            default: 8,
        },
        isActive: {
            type: Boolean,
            default: false,
        },
        noMargin: {
            type: Boolean,
            default: false,
        },
        withName: {
            default: null,
        },
        needOutline: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
    },
});
