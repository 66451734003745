import AccountManagerInfo, { IAccountManagerInfo } from './AccountManagerInfo';

// This file was autogenerated. Please do not change.
export interface IUserGroup {
    groupId?: string;
    groupName?: string;
    members?: IAccountManagerInfo[];
}

export default class UserGroup {
    readonly _groupId: string | undefined;

    get groupId(): string | undefined {
        return this._groupId;
    }

    readonly _groupName: string | undefined;

    get groupName(): string | undefined {
        return this._groupName;
    }

    readonly _members: AccountManagerInfo[] | undefined;

    get members(): AccountManagerInfo[] | undefined {
        return this._members;
    }

    constructor(props: IUserGroup) {
        if (typeof props.groupId === 'string') {
            this._groupId = props.groupId.trim();
        }
        if (typeof props.groupName === 'string') {
            this._groupName = props.groupName.trim();
        }
        if (props.members) {
            this._members = props.members.map((p) => new AccountManagerInfo(p));
        }
    }

    serialize(): IUserGroup {
        const data: IUserGroup = {
        };
        if (typeof this._groupId !== 'undefined') {
            data.groupId = this._groupId;
        }
        if (typeof this._groupName !== 'undefined') {
            data.groupName = this._groupName;
        }
        if (typeof this._members !== 'undefined') {
            data.members = this._members.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): IUserGroup {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupName: !this._groupName ? true : typeof this._groupName === 'string',
            groupId: !this._groupId ? true : typeof this._groupId === 'string',
            members: !this._members ? true : this._members.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
