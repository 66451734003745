// This file was autogenerated. Please do not change.
export interface IFuturesOrderPresenter {
    accountId: string;
    balanceId: string;
    contractSymbol: string;
    createdAt: string;
    executedQuantity: string;
    id: string;
    initiatorId: string;
    initiatorType: string;
    isBuy: boolean;
    orderType: ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | 'FUTURES_MARKET_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS');
    placementName: string;
    positionSide?: ('LONG' | 'SHORT');
    price: string;
    priceAvg: string;
    quantity: string;
    rejectReason?: string;
    status: ('REGISTERED' | 'PLACED' | 'FILLED' | 'PARTIALY_FILLED' | 'CANCELED' | 'REJECTED');
    timeInForce?: string;
    triggerPrice?: string;
}

export default class FuturesOrderPresenter {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _balanceId: string;

    /**
     * Example: BL5LP5UP9VW
     */
    get balanceId(): string {
        return this._balanceId;
    }

    readonly _contractSymbol: string;

    /**
     * Example: UNI/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _executedQuantity: number;

    /**
     * Example: 0
     */
    get executedQuantity(): number {
        return this._executedQuantity;
    }

    readonly _id: string;

    /**
     * Example: FOJS2CHEHXLH5LD75Y11VL
     */
    get id(): string {
        return this._id;
    }

    readonly _initiatorId: string;

    /**
     * Example: URCJVWE3S
     */
    get initiatorId(): string {
        return this._initiatorId;
    }

    readonly _initiatorType: string;

    /**
     * Example: user
     */
    get initiatorType(): string {
        return this._initiatorType;
    }

    readonly _isBuy: boolean;

    get isBuy(): boolean {
        return this._isBuy;
    }

    readonly _orderType: ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | 'FUTURES_MARKET_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS');

    /**
     * Example: FUTURES_LIMIT
     */
    get orderType(): ('FUTURES_LIMIT' | 'FUTURES_MARKET' | 'FUTURES_LIMIT_STOP' | 'FUTURES_MARKET_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS') {
        return this._orderType;
    }

    readonly _placementName: string;

    /**
     * Example: Binance
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _positionSide: ('LONG' | 'SHORT') | undefined;

    /**
     * Example: LONG
     */
    get positionSide(): ('LONG' | 'SHORT') | undefined {
        return this._positionSide;
    }

    readonly _price: number;

    /**
     * Example: 0
     */
    get price(): number {
        return this._price;
    }

    readonly _priceAvg: number;

    /**
     * Example: 0
     */
    get priceAvg(): number {
        return this._priceAvg;
    }

    readonly _quantity: number;

    /**
     * Example: 0.64
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _rejectReason: string | undefined;

    get rejectReason(): string | undefined {
        return this._rejectReason;
    }

    readonly _status: ('REGISTERED' | 'PLACED' | 'FILLED' | 'PARTIALY_FILLED' | 'CANCELED' | 'REJECTED');

    /**
     * Example: REGISTERED
     */
    get status(): ('REGISTERED' | 'PLACED' | 'FILLED' | 'PARTIALY_FILLED' | 'CANCELED' | 'REJECTED') {
        return this._status;
    }

    readonly _timeInForce: string | undefined;

    get timeInForce(): string | undefined {
        return this._timeInForce;
    }

    readonly _triggerPrice: number | undefined;

    get triggerPrice(): number | undefined {
        return this._triggerPrice;
    }

    constructor(props: IFuturesOrderPresenter) {
        this._accountId = props.accountId.trim();
        this._balanceId = props.balanceId.trim();
        this._contractSymbol = props.contractSymbol.trim();
        this._createdAt = props.createdAt.trim();
        this._executedQuantity = Number(props.executedQuantity);
        this._id = props.id.trim();
        this._initiatorId = props.initiatorId.trim();
        this._initiatorType = props.initiatorType.trim();
        this._isBuy = props.isBuy;
        this._orderType = props.orderType;
        this._placementName = props.placementName.trim();
        if (props.positionSide) {
            this._positionSide = props.positionSide;
        }
        this._price = Number(props.price);
        this._priceAvg = Number(props.priceAvg);
        this._quantity = Number(props.quantity);
        if (typeof props.rejectReason === 'string') {
            this._rejectReason = props.rejectReason.trim();
        }
        this._status = props.status;
        if (typeof props.timeInForce === 'string') {
            this._timeInForce = props.timeInForce.trim();
        }
        if (typeof props.triggerPrice === 'string') {
            this._triggerPrice = Number(props.triggerPrice);
        }
    }

    serialize(): IFuturesOrderPresenter {
        const data: IFuturesOrderPresenter = {
            accountId: this._accountId,
            balanceId: this._balanceId,
            contractSymbol: this._contractSymbol,
            createdAt: this._createdAt,
            executedQuantity: String(this._executedQuantity),
            id: this._id,
            initiatorId: this._initiatorId,
            initiatorType: this._initiatorType,
            isBuy: this._isBuy,
            orderType: this._orderType,
            placementName: this._placementName,
            price: String(this._price),
            priceAvg: String(this._priceAvg),
            quantity: String(this._quantity),
            status: this._status,
        };
        if (typeof this._positionSide !== 'undefined') {
            data.positionSide = this._positionSide;
        }
        if (typeof this._rejectReason !== 'undefined') {
            data.rejectReason = this._rejectReason;
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        if (typeof this._triggerPrice !== 'undefined') {
            data.triggerPrice = String(this._triggerPrice);
        }
        return data;
    }

    toJSON(): IFuturesOrderPresenter {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            balanceId: typeof this._balanceId === 'string' && !!this._balanceId.trim(),
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            executedQuantity: true,
            id: typeof this._id === 'string' && !!this._id.trim(),
            initiatorId: typeof this._initiatorId === 'string' && !!this._initiatorId.trim(),
            initiatorType: typeof this._initiatorType === 'string' && !!this._initiatorType.trim(),
            isBuy: typeof this._isBuy === 'boolean',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            price: true,
            priceAvg: true,
            quantity: true,
            rejectReason: !this._rejectReason ? true : typeof this._rejectReason === 'string',
            timeInForce: !this._timeInForce ? true : typeof this._timeInForce === 'string',
            triggerPrice: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
