/* eslint-disable max-classes-per-file */
import store from 'Store';
import UserDataManager from 'Lib/utils/UserDataManager';

export const AccountRules = {
    name: {
        minLength: 3,
        maxLength: 15,
    },
    description: {
        minLength: 0,
        maxLength: 150,
    },
};

const AccountNameMaskToken = 'c';
export const AccountNameMask = {
    mask: (() => {
        let resultMask = '';

        for (let i = 0; i < AccountRules.name.maxLength; i++) {
            resultMask += AccountNameMaskToken;
        }

        return resultMask;
    })(),
    tokens: {
        [AccountNameMaskToken]: {
            pattern: /[0-9a-zA-Z_\s]/,
            transform: (v) => v.replace(' ', '_'),
        },
    },
};

export const AccountManagementPolicies = {
    TRADING: 'trading',
    FINANCE: 'finance',
    ALLOCATION: 'allocation',
    PORTFOLIO: 'portfolio',
    DISPLAY: 'display',
    INSTITUTION: 'institution',
    EARN: 'earn',
};

export const AccountRoleTypes = {
    OWN: 'own',
    MANAGED: 'managed',
};

export const AccountRoleNames = {
    OWNER: 'owner',
    FINANCIER: 'financier',
    FULL_ACCESS: 'full access',
    MANAGER: 'manager',
    DEMO: 'demo',
};

export const AccountRoles = {
    [AccountRoleNames.OWNER]: {
        role: 'OWNER',
        type: AccountRoleTypes.OWN,
    },
    [AccountRoleNames.FINANCIER]: {
        role: 'FINANCIER',
        type: AccountRoleTypes.MANAGED,
    },
    [AccountRoleNames.FULL_ACCESS]: {
        role: 'FULL_ACCESS',
        type: AccountRoleTypes.MANAGED,
    },
    [AccountRoleNames.MANAGER]: {
        role: 'MANAGER',
        type: AccountRoleTypes.MANAGED,
    },
    [AccountRoleNames.DEMO]: {
        role: 'DEMO',
        type: AccountRoleTypes.OWN,
    },
};

export const AccountStatusNames = {
    ACTIVE: 'active',
    SUSPENDED: 'suspended',
    BLOCKED: 'blocked',
};

export const AccountStatuses = {
    [AccountStatusNames.ACTIVE]: {
        value: 'active',
    },
    [AccountStatusNames.SUSPENDED]: {
        value: 'suspended',
    },
    [AccountStatusNames.BLOCKED]: {
        value: 'blocked',
    },
};

export class Account {
    id;

    ownerId;

    name;

    description;

    status;

    policies;

    placementLinks;

    pendingPlacementLinks;

    roleName;

    role;

    color;

    dailyPnl;

    isMain;

    order;

    managersCount;

    totalQuotations;

    holdQuotations;

    quotationAssetSymbols;

    totalQuoted;

    totalUsdOtc;

    totalUsd;

    holdUsd;

    constructor({
        id,
        ownerId,
        name,
        description,
        policies,
        placementLinks,
        pendingPlacementLinks,
        role,
        status,
        managersCount,
        quotationAssetSymbols,
        totalQuoted,
        holdQuoted,
        color,
        totalUsdOtc,
        totalUsd,
        holdUsd,
    }) {
        this.id = id;
        this.ownerId = ownerId;

        this.totalUsdOtc = totalUsdOtc;

        this.setName(name);
        this.setDescription(description);

        this.setStatus(status);

        this.policies = policies;
        this.roleName = role;
        this.totalUsd = Number(totalUsd);
        this.holdUsd = Number(holdUsd);

        this.placementLinks = placementLinks || [];

        this.pendingPlacementLinks = pendingPlacementLinks || [];

        if (AccountRoles[role]) {
            this.role = AccountRoles[role];
        } else {
            this.role = AccountRoles[AccountRoleNames.MANAGER];
        }

        this.totalQuotations = Object.fromEntries(
            quotationAssetSymbols.map((symbol, index) => [symbol, +totalQuoted[index]]),
        );
        this.holdQuotations = Object.fromEntries(
            quotationAssetSymbols.map((symbol, index) => [symbol, +holdQuoted[index]]),
        );

        this.isMain = name === 'main';

        this.color = color;

        this.managersCount = managersCount;

        this.dailyPnl = '';

        this.setDefaultOrder();

        this.totalQuoted = totalQuoted;
        this.quotationAssetSymbols = quotationAssetSymbols;
    }

    get quotationAssetSymbol() {
        return store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
    }

    get quotationAssetPrecision() {
        return store.getters['Assets/GET_QUOTATION_ASSET_PRECISION'];
    }

    get quotationAssetCharacter() {
        return store.getters['Assets/GET_QUOTATION_ASSET_CHARACTER'];
    }

    get total() {
        return this.totalQuotations[this.quotationAssetSymbol];
    }

    get hold() {
        return this.holdQuotations[this.quotationAssetSymbol];
    }

    get free() {
        return this.total - this.hold;
    }

    setDailyPnl(dailyPnl) {
        this.dailyPnl = dailyPnl;
    }

    setName(name) {
        this.name = name;
    }

    setDescription(description) {
        this.description = description;
    }

    setStatus(statusName) {
        this.status = AccountStatuses[statusName];
    }

    setDefaultOrder() {
        const accountOrder = UserDataManager.accountsOrders[this.id];

        this.setOrder(accountOrder || 0, true);
    }

    setOrder(order, isDefault = false) {
        this.order = this.isMain ? 9999 : order;

        if (!isDefault) {
            UserDataManager.setAccountOrder(this.id, order);
        }
    }

    get roleType() {
        return this.role.type;
    }

    get isOwned() {
        return this.roleType === AccountRoleTypes.OWN;
    }

    get isActive() {
        return this.status.value === AccountStatuses[AccountStatusNames.ACTIVE].value;
    }

    get isSuspended() {
        return this.status.value === AccountStatuses[AccountStatusNames.SUSPENDED].value;
    }

    get isBlocked() {
        return this.status.value === AccountStatuses[AccountStatusNames.BLOCKED].value;
    }

    get accountDailyPnl() {
        return this.dailyPnl;
    }
}

export class AccountManager {
    author;

    policies;

    requestGuid;

    isRequest;

    constructor(manager?: any, managementRequest?: any) {
        if (manager) {
            this.isRequest = false;

            this.setManagerAuthor(manager);
            this.setPolicies(manager.policies);
        } else if (managementRequest) {
            this.isRequest = true;

            this.setManagementRequestAuthor(managementRequest);
            this.setPolicies(managementRequest.policies);
            this.setRequestGuid(managementRequest.id);
        }
    }

    setManagerAuthor(manager) {
        this.author = {
            firstName: manager.firstName,
            lastName: manager.lastName,
            id: manager.id,
        };
    }

    setManagementRequestAuthor(request) {
        this.author = request.recipientData;
    }

    setRequestGuid(guid) {
        store.dispatch('Accounts/Management/updateUserManagementRequestGuid', {
            oldGuid: this.requestGuid,
            newGuid: guid,
        });

        this.requestGuid = guid;
    }

    /**
   *
   * @param {Array.<String>} policies
   */
    setPolicies(policies) {
        this.policies = Object.fromEntries(Object.values(AccountManagementPolicies).map((policy) => [policy, false]));

        policies.forEach((policy) => {
            this.policies[policy] = true;
        });

        if (this.isRequest) {
            store.dispatch('Accounts/Management/updateUserManagementRequestPolicies', {
                guid: this.requestGuid,
                policies,
            });
        }
    }
}
