// This file was autogenerated. Please do not change.
export interface IAccountTransferLimit {
    accountId: number;
    assetSymbol: string;
    dailyLimit: string;
    dailySpent: string;
    id: string;
}

export default class AccountTransferLimit {
    readonly _accountId: number;

    /**
     * Example: 4
     */
    get accountId(): number {
        return this._accountId;
    }

    readonly _assetSymbol: string;

    /**
     * Example: BTC
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _dailyLimit: number;

    /**
     * Example: 2
     */
    get dailyLimit(): number {
        return this._dailyLimit;
    }

    readonly _dailySpent: number;

    /**
     * Example: 0.411
     */
    get dailySpent(): number {
        return this._dailySpent;
    }

    readonly _id: string;

    /**
     * Example: AL6RG9MWFPZ
     */
    get id(): string {
        return this._id;
    }

    constructor(props: IAccountTransferLimit) {
        this._accountId = props.accountId;
        this._assetSymbol = props.assetSymbol.trim();
        this._dailyLimit = Number(props.dailyLimit);
        this._dailySpent = Number(props.dailySpent);
        this._id = props.id.trim();
    }

    serialize(): IAccountTransferLimit {
        const data: IAccountTransferLimit = {
            accountId: this._accountId,
            assetSymbol: this._assetSymbol,
            dailyLimit: String(this._dailyLimit),
            dailySpent: String(this._dailySpent),
            id: this._id,
        };
        return data;
    }

    toJSON(): IAccountTransferLimit {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'number',
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            dailyLimit: true,
            dailySpent: true,
            id: typeof this._id === 'string' && !!this._id.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
