import History from './Orders/History';
import Trading from './Orders/Trading';

const state = {

};

const getters = {

};

const mutations = {

};

const actions = {

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,

    modules: {
        History,
        Trading,
    },
};
