// This file was autogenerated. Please do not change.
export interface IAccountRequest {
    accountId: string;
    id: string;
    issuer: string;
    issuerEmail?: string;
    issuerFirstName?: string;
    issuerLastName?: string;
    policies: string[];
    recipient: string;
    recipientEmail?: string;
    recipientFirstName?: string;
    recipientLastName?: string;
    role: string;
    status: ('processing' | 'confirmed' | 'cancelled' | 'rejected');
}

export default class AccountRequest {
    readonly _accountId: string;

    /**
     * Example: AQVW2VSEUU2
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _id: string;

    /**
     * Example: ARH6GCZ5YZ4DA2
     */
    get id(): string {
        return this._id;
    }

    readonly _issuer: string;

    /**
     * Example: UL6HEQRK3
     */
    get issuer(): string {
        return this._issuer;
    }

    readonly _issuerEmail: string | undefined;

    /**
     * Example: foo@bar.com
     */
    get issuerEmail(): string | undefined {
        return this._issuerEmail;
    }

    readonly _issuerFirstName: string | undefined;

    /**
     * Example: Foo
     */
    get issuerFirstName(): string | undefined {
        return this._issuerFirstName;
    }

    readonly _issuerLastName: string | undefined;

    /**
     * Example: Bar
     */
    get issuerLastName(): string | undefined {
        return this._issuerLastName;
    }

    readonly _policies: string[];

    /**
     * Example: deposit, withdrawal
     */
    get policies(): string[] {
        return this._policies;
    }

    readonly _recipient: string;

    /**
     * Example: U4X4WUFD2
     */
    get recipient(): string {
        return this._recipient;
    }

    readonly _recipientEmail: string | undefined;

    /**
     * Example: foo@bar.com
     */
    get recipientEmail(): string | undefined {
        return this._recipientEmail;
    }

    readonly _recipientFirstName: string | undefined;

    /**
     * Example: Foo
     */
    get recipientFirstName(): string | undefined {
        return this._recipientFirstName;
    }

    readonly _recipientLastName: string | undefined;

    /**
     * Example: Bar
     */
    get recipientLastName(): string | undefined {
        return this._recipientLastName;
    }

    readonly _role: string;

    /**
     * Example: test_role
     */
    get role(): string {
        return this._role;
    }

    readonly _status: ('processing' | 'confirmed' | 'cancelled' | 'rejected');

    /**
     * Example: processing
     */
    get status(): ('processing' | 'confirmed' | 'cancelled' | 'rejected') {
        return this._status;
    }

    constructor(props: IAccountRequest) {
        this._accountId = props.accountId.trim();
        this._id = props.id.trim();
        this._issuer = props.issuer.trim();
        if (typeof props.issuerEmail === 'string') {
            this._issuerEmail = props.issuerEmail.trim();
        }
        if (typeof props.issuerFirstName === 'string') {
            this._issuerFirstName = props.issuerFirstName.trim();
        }
        if (typeof props.issuerLastName === 'string') {
            this._issuerLastName = props.issuerLastName.trim();
        }
        this._policies = props.policies;
        this._recipient = props.recipient.trim();
        if (typeof props.recipientEmail === 'string') {
            this._recipientEmail = props.recipientEmail.trim();
        }
        if (typeof props.recipientFirstName === 'string') {
            this._recipientFirstName = props.recipientFirstName.trim();
        }
        if (typeof props.recipientLastName === 'string') {
            this._recipientLastName = props.recipientLastName.trim();
        }
        this._role = props.role.trim();
        this._status = props.status;
    }

    serialize(): IAccountRequest {
        const data: IAccountRequest = {
            accountId: this._accountId,
            id: this._id,
            issuer: this._issuer,
            policies: this._policies,
            recipient: this._recipient,
            role: this._role,
            status: this._status,
        };
        if (typeof this._issuerEmail !== 'undefined') {
            data.issuerEmail = this._issuerEmail;
        }
        if (typeof this._issuerFirstName !== 'undefined') {
            data.issuerFirstName = this._issuerFirstName;
        }
        if (typeof this._issuerLastName !== 'undefined') {
            data.issuerLastName = this._issuerLastName;
        }
        if (typeof this._recipientEmail !== 'undefined') {
            data.recipientEmail = this._recipientEmail;
        }
        if (typeof this._recipientFirstName !== 'undefined') {
            data.recipientFirstName = this._recipientFirstName;
        }
        if (typeof this._recipientLastName !== 'undefined') {
            data.recipientLastName = this._recipientLastName;
        }
        return data;
    }

    toJSON(): IAccountRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            id: typeof this._id === 'string' && !!this._id.trim(),
            issuer: typeof this._issuer === 'string' && !!this._issuer.trim(),
            issuerEmail: !this._issuerEmail ? true : typeof this._issuerEmail === 'string',
            issuerFirstName: !this._issuerFirstName ? true : typeof this._issuerFirstName === 'string',
            issuerLastName: !this._issuerLastName ? true : typeof this._issuerLastName === 'string',
            policies: this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            recipient: typeof this._recipient === 'string' && !!this._recipient.trim(),
            recipientEmail: !this._recipientEmail ? true : typeof this._recipientEmail === 'string',
            recipientFirstName: !this._recipientFirstName ? true : typeof this._recipientFirstName === 'string',
            recipientLastName: !this._recipientLastName ? true : typeof this._recipientLastName === 'string',
            role: typeof this._role === 'string' && !!this._role.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
