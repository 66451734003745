import RouteEdge, { IRouteEdge } from './RouteEdge';
import RouteNode, { IRouteNode } from './RouteNode';

// This file was autogenerated. Please do not change.
export interface IGetRoutesResponse {
    edges?: IRouteEdge[];
    nodes?: IRouteNode[];
}

export default class GetRoutesResponse {
    readonly _edges: RouteEdge[] | undefined;

    get edges(): RouteEdge[] | undefined {
        return this._edges;
    }

    readonly _nodes: RouteNode[] | undefined;

    get nodes(): RouteNode[] | undefined {
        return this._nodes;
    }

    constructor(props: IGetRoutesResponse) {
        if (props.edges) {
            this._edges = props.edges.map((p) => new RouteEdge(p));
        }
        if (props.nodes) {
            this._nodes = props.nodes.map((p) => new RouteNode(p));
        }
    }

    serialize(): IGetRoutesResponse {
        const data: IGetRoutesResponse = {
        };
        if (typeof this._edges !== 'undefined') {
            data.edges = this._edges.map((p) => p.serialize());
        }
        if (typeof this._nodes !== 'undefined') {
            data.nodes = this._nodes.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): IGetRoutesResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            edges: !this._edges ? true : this._edges.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            nodes: !this._nodes ? true : this._nodes.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
