import AccountInfo, { IAccountInfo } from './AccountInfo';

// This file was autogenerated. Please do not change.
export interface IRevokeAccountPayload {
    account: IAccountInfo;
    message?: string;
    recipient: string;
    totp: string;
}

export default class RevokeAccountPayload {
    readonly _account: AccountInfo;

    get account(): AccountInfo {
        return this._account;
    }

    readonly _message: string | undefined;

    /** Description: Message to recipient */
    get message(): string | undefined {
        return this._message;
    }

    readonly _recipient: string;

    /** Description: Recipient unique identifier */
    get recipient(): string {
        return this._recipient;
    }

    readonly _totp: string;

    /** Description: One-time code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IRevokeAccountPayload) {
        this._account = new AccountInfo(props.account);
        if (typeof props.message === 'string') {
            this._message = props.message.trim();
        }
        this._recipient = props.recipient.trim();
        this._totp = props.totp.trim();
    }

    serialize(): IRevokeAccountPayload {
        const data: IRevokeAccountPayload = {
            account: this._account.serialize(),
            recipient: this._recipient,
            totp: this._totp,
        };
        if (typeof this._message !== 'undefined') {
            data.message = this._message;
        }
        return data;
    }

    toJSON(): IRevokeAccountPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            account: this._account.validate().length === 0,
            message: !this._message ? true : typeof this._message === 'string',
            recipient: typeof this._recipient === 'string' && !!this._recipient.trim(),
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
