// This file was autogenerated. Please do not change.
export interface IBankRequisites {
    bankAddress: string;
    bankBic: string;
    bankCountry: string;
    bankName: string;
    beneficiaryAddress: string;
    beneficiaryCountry: string;
    beneficiaryIban: string;
    beneficiaryName: string;
}

export default class BankRequisites {
    readonly _bankAddress: string;

    /**
     * Example: Street Name, City
     */
    get bankAddress(): string {
        return this._bankAddress;
    }

    readonly _bankBic: string;

    /**
     * Example: AAAABBCC123
     */
    get bankBic(): string {
        return this._bankBic;
    }

    readonly _bankCountry: string;

    /**
     * Example: US
     */
    get bankCountry(): string {
        return this._bankCountry;
    }

    readonly _bankName: string;

    /**
     * Example: BTC
     */
    get bankName(): string {
        return this._bankName;
    }

    readonly _beneficiaryAddress: string;

    /**
     * Example: Street Name, City
     */
    get beneficiaryAddress(): string {
        return this._beneficiaryAddress;
    }

    readonly _beneficiaryCountry: string;

    /**
     * Example: US
     */
    get beneficiaryCountry(): string {
        return this._beneficiaryCountry;
    }

    readonly _beneficiaryIban: string;

    /**
     * Example: AA12ABCD1234512345
     */
    get beneficiaryIban(): string {
        return this._beneficiaryIban;
    }

    readonly _beneficiaryName: string;

    /**
     * Example: Holder Name
     */
    get beneficiaryName(): string {
        return this._beneficiaryName;
    }

    constructor(props: IBankRequisites) {
        this._bankAddress = props.bankAddress.trim();
        this._bankBic = props.bankBic.trim();
        this._bankCountry = props.bankCountry.trim();
        this._bankName = props.bankName.trim();
        this._beneficiaryAddress = props.beneficiaryAddress.trim();
        this._beneficiaryCountry = props.beneficiaryCountry.trim();
        this._beneficiaryIban = props.beneficiaryIban.trim();
        this._beneficiaryName = props.beneficiaryName.trim();
    }

    serialize(): IBankRequisites {
        const data: IBankRequisites = {
            bankAddress: this._bankAddress,
            bankBic: this._bankBic,
            bankCountry: this._bankCountry,
            bankName: this._bankName,
            beneficiaryAddress: this._beneficiaryAddress,
            beneficiaryCountry: this._beneficiaryCountry,
            beneficiaryIban: this._beneficiaryIban,
            beneficiaryName: this._beneficiaryName,
        };
        return data;
    }

    toJSON(): IBankRequisites {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            bankAddress: typeof this._bankAddress === 'string' && !!this._bankAddress.trim(),
            bankBic: typeof this._bankBic === 'string' && !!this._bankBic.trim(),
            bankCountry: typeof this._bankCountry === 'string' && !!this._bankCountry.trim(),
            bankName: typeof this._bankName === 'string' && !!this._bankName.trim(),
            beneficiaryAddress: typeof this._beneficiaryAddress === 'string' && !!this._beneficiaryAddress.trim(),
            beneficiaryCountry: typeof this._beneficiaryCountry === 'string' && !!this._beneficiaryCountry.trim(),
            beneficiaryIban: typeof this._beneficiaryIban === 'string' && !!this._beneficiaryIban.trim(),
            beneficiaryName: typeof this._beneficiaryName === 'string' && !!this._beneficiaryName.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
