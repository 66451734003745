/*
 *  Root getters
 */
import idGenerator from 'Plugins/idGenerator';

export default {
    getTimeString: () => (timestamp, { showSeconds } = { showSeconds: true }) => {
        const date = new Date(timestamp);
        let result = '';

        result += `${date.getHours() < 10 ? `0${date.getHours()}` : date.getHours()}`;
        result += `:${date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()}`;
        if (showSeconds) {
            result += `:${date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds()}`;
        }

        return result;
    },
    getWeekDayName: (state, getters) => (date) => getters.localizations.English.WeekDays[(new Date(date)).getDay()],
    getMonthName: (state, getters) => (date) => getters.localizations.English.Months[(new Date(date)).getMonth()],
    getTimeDateString: (state, getters) => ({ timestamp, showSeconds, showTime } = { timestamp: new Date(), showSeconds: false, showTime: true }) => {
        const date = new Date(timestamp);

        showTime = showTime === undefined ? true : showTime;
        showSeconds = showSeconds === undefined ? false : showSeconds;

        return `${date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()} ${getters.getMonthName(date)?.ShortName ?? ''} ${date.getFullYear()}${showTime ? ', ' : ''} ${showTime ? getters.getTimeString(date, { showSeconds }) : ''}`;
    },
    round: () => (number, deep = 0) => Math.round(number * (10 ** deep)) / (10 ** deep),
    makeFirstUppercase: () => (data = '') => data.makeFirstUppercase(),
    generateId: () => () => idGenerator.next().value,
};
