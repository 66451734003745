// This file was autogenerated. Please do not change.
export interface ITokenResponse {
    token: string;
}

export default class TokenResponse {
    readonly _token: string;

    /**
     * Example: _act-sbx-f3cdbb1f-c2d9-4720-ac58-fa842f8f08d3
     */
    get token(): string {
        return this._token;
    }

    constructor(props: ITokenResponse) {
        this._token = props.token.trim();
    }

    serialize(): ITokenResponse {
        const data: ITokenResponse = {
            token: this._token,
        };
        return data;
    }

    toJSON(): ITokenResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            token: typeof this._token === 'string' && !!this._token.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
