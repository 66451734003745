// This file was autogenerated. Please do not change.
export interface ITradingData {
    currentPrice?: string;
    high24h?: string;
    low24h?: string;
    priceChange24h?: string;
    priceChangePercent24h?: string;
    quotedVolume24h?: string;
    symbol?: string;
    volume24h?: string;
}

export default class TradingData {
    readonly _currentPrice: number | undefined;

    /**
     * Example: 40111.97
     */
    get currentPrice(): number | undefined {
        return this._currentPrice;
    }

    readonly _high24h: number | undefined;

    /**
     * Example: 41234.56
     */
    get high24h(): number | undefined {
        return this._high24h;
    }

    readonly _low24h: number | undefined;

    /**
     * Example: 38765.43
     */
    get low24h(): number | undefined {
        return this._low24h;
    }

    readonly _priceChange24h: number | undefined;

    /**
     * Example: 51.96
     */
    get priceChange24h(): number | undefined {
        return this._priceChange24h;
    }

    readonly _priceChangePercent24h: number | undefined;

    /**
     * Example: 0.009
     */
    get priceChangePercent24h(): number | undefined {
        return this._priceChangePercent24h;
    }

    readonly _quotedVolume24h: number | undefined;

    /**
     * Example: 12345678.9
     */
    get quotedVolume24h(): number | undefined {
        return this._quotedVolume24h;
    }

    readonly _symbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get symbol(): string | undefined {
        return this._symbol;
    }

    readonly _volume24h: number | undefined;

    /**
     * Example: 47142.51281
     */
    get volume24h(): number | undefined {
        return this._volume24h;
    }

    constructor(props: ITradingData) {
        if (typeof props.currentPrice === 'string') {
            this._currentPrice = Number(props.currentPrice);
        }
        if (typeof props.high24h === 'string') {
            this._high24h = Number(props.high24h);
        }
        if (typeof props.low24h === 'string') {
            this._low24h = Number(props.low24h);
        }
        if (typeof props.priceChange24h === 'string') {
            this._priceChange24h = Number(props.priceChange24h);
        }
        if (typeof props.priceChangePercent24h === 'string') {
            this._priceChangePercent24h = Number(props.priceChangePercent24h);
        }
        if (typeof props.quotedVolume24h === 'string') {
            this._quotedVolume24h = Number(props.quotedVolume24h);
        }
        if (typeof props.symbol === 'string') {
            this._symbol = props.symbol.trim();
        }
        if (typeof props.volume24h === 'string') {
            this._volume24h = Number(props.volume24h);
        }
    }

    serialize(): ITradingData {
        const data: ITradingData = {
        };
        if (typeof this._currentPrice !== 'undefined') {
            data.currentPrice = String(this._currentPrice);
        }
        if (typeof this._high24h !== 'undefined') {
            data.high24h = String(this._high24h);
        }
        if (typeof this._low24h !== 'undefined') {
            data.low24h = String(this._low24h);
        }
        if (typeof this._priceChange24h !== 'undefined') {
            data.priceChange24h = String(this._priceChange24h);
        }
        if (typeof this._priceChangePercent24h !== 'undefined') {
            data.priceChangePercent24h = String(this._priceChangePercent24h);
        }
        if (typeof this._quotedVolume24h !== 'undefined') {
            data.quotedVolume24h = String(this._quotedVolume24h);
        }
        if (typeof this._symbol !== 'undefined') {
            data.symbol = this._symbol;
        }
        if (typeof this._volume24h !== 'undefined') {
            data.volume24h = String(this._volume24h);
        }
        return data;
    }

    toJSON(): ITradingData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            currentPrice: true,
            high24h: true,
            low24h: true,
            priceChange24h: true,
            priceChangePercent24h: true,
            quotedVolume24h: true,
            symbol: !this._symbol ? true : typeof this._symbol === 'string',
            volume24h: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
