// This file was autogenerated. Please do not change.
export interface IAsset {
    id?: number;
    symbol?: string;
}

export default class Asset {
    readonly _id: number | undefined;

    /**
     * Example: 17
     */
    get id(): number | undefined {
        return this._id;
    }

    readonly _symbol: string | undefined;

    /**
     * Example: BTC
     */
    get symbol(): string | undefined {
        return this._symbol;
    }

    constructor(props: IAsset) {
        if (typeof props.id === 'number') {
            this._id = props.id;
        }
        if (typeof props.symbol === 'string') {
            this._symbol = props.symbol.trim();
        }
    }

    serialize(): IAsset {
        const data: IAsset = {
        };
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._symbol !== 'undefined') {
            data.symbol = this._symbol;
        }
        return data;
    }

    toJSON(): IAsset {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'number',
            symbol: !this._symbol ? true : typeof this._symbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
