// This file was autogenerated. Please do not change.
export interface IGroupUser {
    createdAt: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    role_alias?: string;
    role_name?: string;
    uid?: string;
    updatedAt: string;
}

export default class GroupUser {
    readonly _createdAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get createdAt(): string {
        return this._createdAt;
    }

    readonly _email: string | undefined;

    get email(): string | undefined {
        return this._email;
    }

    readonly _first_name: string | undefined;

    get firstName(): string | undefined {
        return this._first_name;
    }

    readonly _last_name: string | undefined;

    get lastName(): string | undefined {
        return this._last_name;
    }

    readonly _role_alias: string | undefined;

    get roleAlias(): string | undefined {
        return this._role_alias;
    }

    readonly _role_name: string | undefined;

    get roleName(): string | undefined {
        return this._role_name;
    }

    readonly _uid: string | undefined;

    get uid(): string | undefined {
        return this._uid;
    }

    readonly _updatedAt: string;

    /**
     * Example: 2021-04-20T14:04:06.532623Z
     */
    get updatedAt(): string {
        return this._updatedAt;
    }

    constructor(props: IGroupUser) {
        this._createdAt = props.createdAt.trim();
        if (typeof props.email === 'string') {
            this._email = props.email.trim();
        }
        if (typeof props.first_name === 'string') {
            this._first_name = props.first_name.trim();
        }
        if (typeof props.last_name === 'string') {
            this._last_name = props.last_name.trim();
        }
        if (typeof props.role_alias === 'string') {
            this._role_alias = props.role_alias.trim();
        }
        if (typeof props.role_name === 'string') {
            this._role_name = props.role_name.trim();
        }
        if (typeof props.uid === 'string') {
            this._uid = props.uid.trim();
        }
        this._updatedAt = props.updatedAt.trim();
    }

    serialize(): IGroupUser {
        const data: IGroupUser = {
            createdAt: this._createdAt,
            updatedAt: this._updatedAt,
        };
        if (typeof this._email !== 'undefined') {
            data.email = this._email;
        }
        if (typeof this._first_name !== 'undefined') {
            data.first_name = this._first_name;
        }
        if (typeof this._last_name !== 'undefined') {
            data.last_name = this._last_name;
        }
        if (typeof this._role_alias !== 'undefined') {
            data.role_alias = this._role_alias;
        }
        if (typeof this._role_name !== 'undefined') {
            data.role_name = this._role_name;
        }
        if (typeof this._uid !== 'undefined') {
            data.uid = this._uid;
        }
        return data;
    }

    toJSON(): IGroupUser {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            createdAt: typeof this._createdAt === 'string' && !!this._createdAt.trim(),
            email: !this._email ? true : typeof this._email === 'string',
            first_name: !this._first_name ? true : typeof this._first_name === 'string',
            last_name: !this._last_name ? true : typeof this._last_name === 'string',
            role_alias: !this._role_alias ? true : typeof this._role_alias === 'string',
            role_name: !this._role_name ? true : typeof this._role_name === 'string',
            uid: !this._uid ? true : typeof this._uid === 'string',
            updatedAt: typeof this._updatedAt === 'string' && !!this._updatedAt.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
