// This file was autogenerated. Please do not change.
export interface ISummaryBalance {
    free?: string;
    hold?: string;
    positionsHold?: string;
}

export default class SummaryBalance {
    readonly _free: number | undefined;

    /**
     * Example: 1505.31265587
     */
    get free(): number | undefined {
        return this._free;
    }

    readonly _hold: number | undefined;

    /**
     * Example: 505.31265587
     */
    get hold(): number | undefined {
        return this._hold;
    }

    readonly _positionsHold: number | undefined;

    /**
     * Example: 345.94185816
     */
    get positionsHold(): number | undefined {
        return this._positionsHold;
    }

    constructor(props: ISummaryBalance) {
        if (typeof props.free === 'string') {
            this._free = Number(props.free);
        }
        if (typeof props.hold === 'string') {
            this._hold = Number(props.hold);
        }
        if (typeof props.positionsHold === 'string') {
            this._positionsHold = Number(props.positionsHold);
        }
    }

    serialize(): ISummaryBalance {
        const data: ISummaryBalance = {
        };
        if (typeof this._free !== 'undefined') {
            data.free = String(this._free);
        }
        if (typeof this._hold !== 'undefined') {
            data.hold = String(this._hold);
        }
        if (typeof this._positionsHold !== 'undefined') {
            data.positionsHold = String(this._positionsHold);
        }
        return data;
    }

    toJSON(): ISummaryBalance {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            free: true,
            hold: true,
            positionsHold: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
