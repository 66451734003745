import ApiError from 'Entities/ApiError';
import DexTransaction from 'Entities/statementsPresenter/DexTransaction';
import DexTransactionsTotal from 'Entities/statementsPresenter/DexTransactionsTotal';
import FinancialReport from 'Entities/statementsPresenter/FinancialReport';
import FuturesFunding from 'Entities/statementsPresenter/FuturesFunding';
import FuturesFundingTotal from 'Entities/statementsPresenter/FuturesFundingTotal';
import FuturesOrder from 'Entities/statementsPresenter/FuturesOrder';
import FuturesOrdersTotal from 'Entities/statementsPresenter/FuturesOrdersTotal';
import FuturesTrade from 'Entities/statementsPresenter/FuturesTrade';
import OrdersSummary from 'Entities/statementsPresenter/OrdersSummary';
import SpotOrder from 'Entities/statementsPresenter/SpotOrder';
import SpotOrderStatus from 'Entities/statementsPresenter/SpotOrderStatus';
import SpotOrdersSummary from 'Entities/statementsPresenter/SpotOrdersSummary';
import SpotOrdersTotal from 'Entities/statementsPresenter/SpotOrdersTotal';
import SpotTrade from 'Entities/statementsPresenter/SpotTrade';
import StatementRequest from 'Entities/statementsPresenter/StatementRequest';
import SummaryBalance from 'Entities/statementsPresenter/SummaryBalance';
import SummaryBalanceHistory from 'Entities/statementsPresenter/SummaryBalanceHistory';
import SummaryReport from 'Entities/statementsPresenter/SummaryReport';
import TradesTotal from 'Entities/statementsPresenter/TradesTotal';
import Transfer from 'Entities/statementsPresenter/Transfer';
import TransferStatus from 'Entities/statementsPresenter/TransferStatus';
import TransferType from 'Entities/statementsPresenter/TransferType';
import TransfersCommission from 'Entities/statementsPresenter/TransfersCommission';
import TransfersSummary from 'Entities/statementsPresenter/TransfersSummary';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class StatementsApi {
    static async privateStatementsDexGetLiquidityTransactions(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: DexTransaction[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsDexGetLiquidityTransactions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/dex/getLiquidityTransactions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new DexTransaction(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsDexGetLiquidityTransactions', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsDexGetSwapTransactions(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: DexTransaction[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsDexGetSwapTransactions', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/dex/getSwapTransactions`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new DexTransaction(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsDexGetSwapTransactions', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsDexGetTransactionsTotal(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: DexTransactionsTotal; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsDexGetTransactionsTotal', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/dex/getTransactionsTotal`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new DexTransactionsTotal(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsDexGetTransactionsTotal', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsFundingsGetFutures(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: FuturesFunding[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsFundingsGetFutures', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/fundings/getFutures`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesFunding(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsFundingsGetFutures', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsFundingsGetFuturesTotal(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: FuturesFundingTotal[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsFundingsGetFuturesTotal', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/fundings/getFuturesTotal`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesFundingTotal(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsFundingsGetFuturesTotal', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetFuturesOrders(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: FuturesOrder[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetFuturesOrders', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getFuturesOrders`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesOrder(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetFuturesOrders', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetFuturesSummary(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: OrdersSummary; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetFuturesSummary', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getFuturesSummary`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new OrdersSummary(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetFuturesSummary', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetFuturesTotal(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: FuturesOrdersTotal; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetFuturesTotal', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getFuturesTotal`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new FuturesOrdersTotal(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetFuturesTotal', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetSpotOrders(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SpotOrder[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetSpotOrders', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getSpotOrders`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotOrder(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetSpotOrders', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetSpotStatuses(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SpotOrderStatus[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetSpotStatuses', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getSpotStatuses`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotOrderStatus(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetSpotStatuses', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetSpotSummary(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SpotOrdersSummary; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetSpotSummary', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getSpotSummary`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotOrdersSummary(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetSpotSummary', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsOrdersGetSpotTotal(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SpotOrdersTotal; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsOrdersGetSpotTotal', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/orders/getSpotTotal`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SpotOrdersTotal(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsOrdersGetSpotTotal', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsSummaryGetBalanceHistory(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SummaryBalanceHistory; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsSummaryGetBalanceHistory', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/summary/getBalanceHistory`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SummaryBalanceHistory(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsSummaryGetBalanceHistory', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsSummaryGetCurrentBalance(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SummaryBalance; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsSummaryGetCurrentBalance', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/summary/getCurrentBalance`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SummaryBalance(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsSummaryGetCurrentBalance', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsSummaryGetFinances(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: FinancialReport; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsSummaryGetFinances', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/summary/getFinances`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new FinancialReport(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsSummaryGetFinances', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsSummaryGetSummary(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SummaryReport; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsSummaryGetSummary', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/summary/getSummary`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new SummaryReport(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsSummaryGetSummary', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTradesGetFuturesTotal(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: TradesTotal; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTradesGetFuturesTotal', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/trades/getFuturesTotal`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new TradesTotal(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTradesGetFuturesTotal', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTradesGetFuturesTrades(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: FuturesTrade[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTradesGetFuturesTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/trades/getFuturesTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new FuturesTrade(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTradesGetFuturesTrades', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTradesGetSpotTotal(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: TradesTotal; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTradesGetSpotTotal', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/trades/getSpotTotal`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new TradesTotal(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTradesGetSpotTotal', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTradesGetSpotTrades(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: SpotTrade[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTradesGetSpotTrades', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/trades/getSpotTrades`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new SpotTrade(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTradesGetSpotTrades', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTransfersGetCommission(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: TransfersCommission; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTransfersGetCommission', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/transfers/getCommission`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new TransfersCommission(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTransfersGetCommission', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTransfersGetStatuses(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: TransferStatus[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTransfersGetStatuses', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/transfers/getStatuses`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new TransferStatus(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTransfersGetStatuses', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTransfersGetSummary(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: TransfersSummary; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTransfersGetSummary', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/transfers/getSummary`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new TransfersSummary(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTransfersGetSummary', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTransfersGetTransfers(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: Transfer[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTransfersGetTransfers', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/transfers/getTransfers`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new Transfer(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTransfersGetTransfers', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsTransfersGetTypes(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: TransferType[]; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsTransfersGetTypes', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/transfers/getTypes`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: (await res.json()).map((r: any) => new TransferType(r)), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('Statements/privateStatementsTransfersGetTypes', { response: res, data: result, fields: [JSON.stringify({ statementrequest })] });
    }

    static async privateStatementsXlsGetStatements(statementrequest: StatementRequest, withHeaders?: boolean): Promise<{ data: Response; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...statementrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('privateStatementsXlsGetStatements', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/statements/xls/getStatements`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(statementrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: res };
        }
        throw new ApiError('Statements/privateStatementsXlsGetStatements', { response: res, fields: [JSON.stringify({ statementrequest })] });
    }
}
