// This file was autogenerated. Please do not change.
export interface IUserPlatformConfig {
    config: undefined;
}

export default class UserPlatformConfig {
    readonly _config: undefined;

    get config(): undefined {
        return this._config;
    }

    constructor(props: IUserPlatformConfig) {
        this._config = props.config;
    }

    serialize(): IUserPlatformConfig {
        const data: IUserPlatformConfig = {
            config: this._config,
        };
        return data;
    }

    toJSON(): IUserPlatformConfig {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
