/**
 * @typedef {Object} AssetManifest
 *
 * @property {String} symbol
 * @property {String} name
 * @property {String} color
 */

/**
 * @type Array.<AssetManifest>
 */
import vueCryptoiconManifest from 'vue-cryptoicon/src/cryptoicons/manifest.json';

/**
 * @type Array.<AssetManifest>
 */
import customAssetsManifest from 'Config/customAssetsManifest.json';
import { makeColor } from 'Lib/utils/hexColorMaker';

export const manifest = customAssetsManifest;

vueCryptoiconManifest.map((record) => {
    const { symbol } = record;

    if (!manifest.some(({ symbol: manifestSymbol }) => manifestSymbol === symbol)) {
        manifest.push(record);
    }
    return null;
});

/**
 *
 * @type Object
 */
export const objectManifest = {};

manifest.forEach((record) => {
    objectManifest[record.symbol] = record;
});

/**
 *
 * @param {String} symbol
 * @return {Boolean}
 */
export function hasManifest(symbol) {
    // eslint-disable-next-line no-prototype-builtins
    return objectManifest.hasOwnProperty(symbol);
}

/**
 *
 * @param {String} symbol
 *
 * @return {AssetManifest}
 */
export function getManifest(symbol) {
    return hasManifest(symbol) ? objectManifest[symbol] : {
        symbol,
        description: symbol,
        color: makeColor(symbol),
    };
}
