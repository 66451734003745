import { Action } from 'vuex';

import { getLocalStorageQuotationAsset, setLocalStorageQuotationAsset } from 'Models/assets';
import { actionCreator, mutationCreator } from 'Store/utils';
import Asset from 'Entities/publicPresenter/Asset';
import PublicDataApi from 'Apis/PublicData';
import AssetQuotationsRequest from 'Entities/publicPresenter/AssetQuotationsRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import AssetQuotation from 'Entities/publicPresenter/AssetQuotation';
import AssetsRequest from 'Entities/publicPresenter/AssetsRequest';

export interface ICachedAsset {
    placementId: number;
    assets: any[];
}

export interface IQuotations {
    USD: number;
    EUR: number;
    BTC: number;
    ETH: number;
}

export const assetsTypes = {
    FIAT: 'fiat',
    CRYPTO_SPOT: 'crypto-spot',
    STABLE_COIN: 'crypto-stable-coin',
};

export const quotationAssets: Record<string, Record<string, string>> = {
    USD: {
        symbol: 'USD',
        char: '$',
    },
    EUR: {
        symbol: 'EUR',
        char: '€',
    },
    BTC: {
        symbol: 'BTC',
        char: '₿',
    },
    ETH: {
        symbol: 'ETH',
        char: 'Ξ',
    },
};

const state = {
    assets: [] as Asset[],
    cachedAssets: [] as ICachedAsset[],
    activeTerminalAccountPortfolioPlacementAssets: [] as Asset[],
    quotationAssetSymbol: getLocalStorageQuotationAsset() as string,
    quotations: new Map<string, IQuotations>() as Map<string, IQuotations>,
};

export type AssetsState = typeof state;

export enum AssetsGetters {
    GET_ASSETS = 'GET_ASSETS',
    GET_ASSETS_SYMBOLS = 'GET_ASSETS_SYMBOLS',
    GET_ASSET_BY_ID = 'GET_ASSET_BY_ID',
    GET_ASSET_INDEX_BY_ID = 'GET_ASSET_INDEX_BY_ID',
    GET_ASSET_BY_SYMBOL = 'GET_ASSET_BY_SYMBOL',
    GET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS = 'GET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS',
    GET_ASSET_DEEP_BY_SYMBOL = 'GET_ASSET_DEEP_BY_SYMBOL',
    GET_ASSET_QUOTATION_BY_SYMBOL = 'GET_ASSET_QUOTATION_BY_SYMBOL',
    GET_QUOTE = 'GET_QUOTE',
    GET_ASSET_QUANTITY_BY_QUOTE = 'GET_ASSET_QUANTITY_BY_QUOTE',
    GET_QUOTATION_ASSET_SYMBOL = 'GET_QUOTATION_ASSET_SYMBOL',
    GET_QUOTATION_ASSET = 'GET_QUOTATION_ASSET',
    GET_QUOTATION_ASSET_PRECISION = 'GET_QUOTATION_ASSET_PRECISION',
    GET_QUOTATION_ASSET_CHARACTER = 'GET_QUOTATION_ASSET_CHARACTER',
}

type GettersReturn<G extends { [key in AssetsGetters]: (...args: any) => any }> = { [key in keyof G]: ReturnType<G[AssetsGetters]> };

interface Getters {
    GET_ASSETS: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => Asset[];
    GET_ASSETS_SYMBOLS: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string[];
    GET_ASSET_BY_ID: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetId: number) => Asset | undefined;
    GET_ASSET_INDEX_BY_ID: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetId: number) => number;
    GET_ASSET_BY_SYMBOL: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetSymbol: string) => Asset | undefined;
    GET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => Asset[];
    GET_ASSET_DEEP_BY_SYMBOL: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetSymbol: string) => number;
    GET_ASSET_QUOTATION_BY_SYMBOL: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetSymbol: string) => number;
    GET_QUOTE: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetSymbol: string, quantity: number) => number;
    GET_ASSET_QUANTITY_BY_QUOTE: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => (assetSymbol: string, quote: number) => number;
    GET_QUOTATION_ASSET_SYMBOL: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string;
    GET_QUOTATION_ASSET: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => Asset | null;
    GET_QUOTATION_ASSET_PRECISION: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => number;
    GET_QUOTATION_ASSET_CHARACTER: (state: AssetsState, getters: GettersReturn<Getters>, rootState: any, rootGetters: any) => string;
}

const getters: Getters = {
    GET_ASSETS(state) {
        return state.assets;
    },
    GET_ASSETS_SYMBOLS(state, getters) {
        return (getters.GET_ASSETS as Asset[]).map(({ symbol }) => symbol);
    },
    GET_ASSET_BY_ID(state, getters) {
        return (assetId) => {
            return (getters.GET_ASSETS as Asset[]).find(({ id }) => id === assetId);
        };
    },
    GET_ASSET_INDEX_BY_ID(state, getters) {
        return (assetId) => {
            return (getters.GET_ASSETS as Asset[]).findIndex(({ id }) => id === assetId);
        };
    },
    GET_ASSET_BY_SYMBOL(state, getters) {
        return (assetSymbol) => {
            return (getters.GET_ASSETS as Asset[]).find(({ symbol }) => symbol === assetSymbol);
        };
    },
    GET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS(state) {
        return state.activeTerminalAccountPortfolioPlacementAssets;
    },
    GET_ASSET_DEEP_BY_SYMBOL(state, getters) {
        return (assetSymbol) => {
            const result: Asset | undefined = (getters.GET_ASSET_BY_SYMBOL as any)(assetSymbol);
            if (!result) {
                return 8;
            }
            return Math.min(Math.ceil(Math.log10(result.multiplier)), 8);
        };
    },
    GET_ASSET_QUOTATION_BY_SYMBOL(state) {
        return (assetSymbol) => {
            if (state.quotations.has(assetSymbol)) {
                return state.quotations.get(assetSymbol)![state.quotationAssetSymbol];
            }
            return 0;
        };
    },
    GET_QUOTE(state, getters) {
        return (assetSymbol, quantity) => {
            return (quantity * (getters.GET_ASSET_QUOTATION_BY_SYMBOL as any)(assetSymbol)).round(getters.GET_QUOTATION_ASSET_PRECISION as number);
        };
    },
    GET_ASSET_QUANTITY_BY_QUOTE(state, getters) {
        return (assetSymbol, quote) => {
            return (Number(quote) / Number((getters.GET_ASSET_QUOTATION_BY_SYMBOL as any)(assetSymbol))).round((getters.GET_ASSET_DEEP_BY_SYMBOL as any)(assetSymbol));
        };
    },
    GET_QUOTATION_ASSET_SYMBOL(state) {
        return state.quotationAssetSymbol;
    },
    GET_QUOTATION_ASSET(state, getters) {
        return (getters.GET_ASSET_BY_SYMBOL as any)(getters.GET_QUOTATION_ASSET_SYMBOL as string) ?? null;
    },
    GET_QUOTATION_ASSET_PRECISION(state, getters) {
        const asset = getters.GET_QUOTATION_ASSET as Asset | null;
        if (!asset) {
            return 6;
        }
        return (getters.GET_ASSET_DEEP_BY_SYMBOL as any)(asset.symbol);
    },
    GET_QUOTATION_ASSET_CHARACTER(state, getters) {
        return quotationAssets[getters.GET_QUOTATION_ASSET_SYMBOL as string].char;
    },
};

export enum AssetsMutations {
    SET_ASSETS = 'SET_ASSETS',
    SET_QUOTATION_ASSET = 'SET_QUOTATION_ASSET',
    SET_ASSET_QUOTATION = 'SET_ASSET_QUOTATION',
    SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS = 'SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS',
    SAVE_ASSETS_TO_CACHE = 'SAVE_ASSETS_TO_CACHE',
}

export const SET_ASSETS = mutationCreator<Asset[]>('Assets', AssetsMutations.SET_ASSETS);
export const SET_QUOTATION_ASSET = mutationCreator<string>('Assets', AssetsMutations.SET_QUOTATION_ASSET);
export const SET_ASSET_QUOTATION = mutationCreator<{ assetSymbol: string; quotationAssetSymbol: string; quotation: number; }>('Assets', AssetsMutations.SET_ASSET_QUOTATION);
export const SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS = mutationCreator<Asset[]>('Assets', AssetsMutations.SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS);
export const SAVE_ASSETS_TO_CACHE = mutationCreator<ICachedAsset>('Assets', AssetsMutations.SAVE_ASSETS_TO_CACHE);

const mutations: Record<AssetsMutations, (state: AssetsState, ...args: any) => void> = {
    SET_ASSETS(state, { payload: assets }: ReturnType<typeof SET_ASSETS>) {
        state.assets = assets;
    },
    SET_QUOTATION_ASSET(state, { payload: assetSymbol }: ReturnType<typeof SET_QUOTATION_ASSET>) {
        state.quotationAssetSymbol = assetSymbol;
    },
    SET_ASSET_QUOTATION(state, { payload: { assetSymbol, quotationAssetSymbol, quotation } }: ReturnType<typeof SET_ASSET_QUOTATION>) {
        if (state.quotations.has(assetSymbol)) {
            state.quotations.get(assetSymbol)![quotationAssetSymbol] = quotation;
        } else {
            const initialQuotationsValue: IQuotations = {
                USD: 0,
                EUR: 0,
                BTC: 0,
                ETH: 0,
            };
            state.quotations.set(assetSymbol, initialQuotationsValue);
            state.quotations.get(assetSymbol)![quotationAssetSymbol] = quotation;
        }
    },
    SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS(state, { payload: assets }: ReturnType<typeof SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS>) {
        state.activeTerminalAccountPortfolioPlacementAssets = assets;
    },
    SAVE_ASSETS_TO_CACHE(state, { payload: data }: ReturnType<typeof SAVE_ASSETS_TO_CACHE>) {
        state.cachedAssets.push(data);
    },
};

export enum AssetsActions {
    setAssets = 'setAssets',
    setQuotationAsset = 'setQuotationAsset',
    setAssetsQuotations = 'setAssetsQuotations',
    updateAssetsQuotations = 'updateAssetsQuotations',
    updateActiveTerminalPlacementAssetsList = 'updateActiveTerminalPlacementAssetsList',
}

export const setAssets = actionCreator<Asset[]>('Assets', AssetsActions.setAssets);
export const setQuotationAsset = actionCreator<string>('Assets', AssetsActions.setQuotationAsset);
export const setAssetsQuotations = actionCreator<AssetQuotation[]>('Assets', AssetsActions.setAssetsQuotations);
export const updateAssetsQuotations = actionCreator<string[]>('Assets', AssetsActions.updateAssetsQuotations);
export const updateActiveTerminalPlacementAssetsList = actionCreator<number>('Assets', AssetsActions.updateActiveTerminalPlacementAssetsList);

const actions: Record<AssetsActions, Action<AssetsState, any>> = {
    setAssets({ commit }, { payload: assets }: ReturnType<typeof setAssets>) {
        commit(SET_ASSETS(assets, true));
    },
    async setQuotationAsset({ commit, dispatch }, { payload: assetSymbol }: ReturnType<typeof setQuotationAsset>) {
        commit(SET_QUOTATION_ASSET(assetSymbol, true));
        setLocalStorageQuotationAsset(assetSymbol);
        await dispatch('Accounts/updateAccountsDailyPnl', undefined, { root: true });
    },
    setAssetsQuotations({ commit }, { payload: quotations }: ReturnType<typeof setAssetsQuotations>) {
        quotations.forEach(({ assetPairSymbol, rate }) => {
            commit(SET_ASSET_QUOTATION({
                assetSymbol: assetPairSymbol.split('/')[0],
                quotationAssetSymbol: assetPairSymbol.split('/')[1],
                quotation: Number(rate),
            }, true));
        });
    },
    async updateAssetsQuotations({ dispatch }, { payload: symbols }: ReturnType<typeof updateAssetsQuotations>) {
        const page = 1;
        let result: AssetQuotation[] = [];
        const { data: res, headers: respHeaders } = await PublicDataApi.publicGetAssetQuotations(new AssetQuotationsRequest({
            assetSymbols: symbols,
            includeTotal: true,
            page,
            perPage: 400,
        }), true);
        result = [...result, ...res];
        let totalPage;
        if (respHeaders) {
            totalPage = parsePaginationHeaders(respHeaders).totalPage;
        }
        if (totalPage && totalPage > 1) {
            for (let i = 2; i <= totalPage; i += 1) {
                // eslint-disable-next-line no-await-in-loop
                const { data: response } = await PublicDataApi.publicGetAssetQuotations(new AssetQuotationsRequest({
                    page: i,
                    perPage: 400,
                    assetSymbols: symbols,
                }));
                result = [...result, ...response];
            }
        }
        await dispatch(setAssetsQuotations(result, true));
    },
    async updateActiveTerminalPlacementAssetsList({ state, commit, dispatch }, { payload: placementId }: ReturnType<typeof updateActiveTerminalPlacementAssetsList>) {
        const cache = state.cachedAssets.find((e) => Number(e.placementId) === Number(placementId));
        if (cache) {
            commit(SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS(cache.assets, true));
        } else {
            const page = 1;
            let result: Asset[] = [];
            const { data: res, headers: respHeaders } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                page,
                perPage: 300,
                placementId: Number(placementId),
                includeTotal: true,
            }), true);
            result = [...result, ...res];
            let totalPage;
            if (respHeaders) {
                totalPage = parsePaginationHeaders(respHeaders).totalPage;
            }
            if (totalPage && totalPage > 1) {
                for (let i = page + 1; i <= totalPage; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: response } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                        page: i,
                        perPage: 300,
                        placementId: Number(placementId),
                    }));
                    result = [...result, ...response];
                }
            }
            const temp: ICachedAsset = {
                placementId,
                assets: result.map((asset) => Object.assign(asset, { placementId })),
            };
            commit(SAVE_ASSETS_TO_CACHE(temp, true));
            commit(SET_ACTIVE_TERMINAL_ACCOUNT_PORTFOLIO_PLACEMENT_ASSETS(result.map((asset) => Object.assign(asset, { placementId })), true));
            commit(SET_ASSETS(result, true));
            const symbols = result.map((a) => a.symbol);
            await dispatch(updateAssetsQuotations(symbols, true));
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
