// This file was autogenerated. Please do not change.
export interface IBlockchainsRequest {
    assetId?: number;
    assetSymbol?: string;
    includeTotal?: boolean;
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
}

export default class BlockchainsRequest {
    readonly _assetId: number | undefined;

    /** Description: Asset platform ID */
    get assetId(): number | undefined {
        return this._assetId;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Description: Asset platform symbol, e.g. BTC, ETH, etc.
     * Example: BTC
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    /** Description: Placement platform ID */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Description: Placement (custody, CEx, DEx) name, e.g. Single Broker, Binance, etc.
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: IBlockchainsRequest) {
        if (typeof props.assetId === 'number') {
            this._assetId = props.assetId;
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): IBlockchainsRequest {
        const data: IBlockchainsRequest = {
        };
        if (typeof this._assetId !== 'undefined') {
            data.assetId = this._assetId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): IBlockchainsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetId: !this._assetId ? true : typeof this._assetId === 'number',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
