// This file was autogenerated. Please do not change.
export interface IFuturesOrder {
    accountId?: string;
    assetSymbol?: string;
    contractSymbol?: string;
    createdAt?: string;
    executedQuantity?: string;
    id?: string;
    isBuy?: boolean;
    leverage?: number;
    placementName?: string;
    positionSide?: string;
    price?: string;
    quantity?: string;
    rejectReason?: string;
    slOrderPrice?: string;
    slTriggerPrice?: string;
    status?: string;
    timeInForce?: string;
    tpOrderPrice?: string;
    tpTriggerPrice?: string;
    triggerPrice?: string;
    type?: string;
    updatedAt?: string;
}

export default class FuturesOrder {
    readonly _accountId: string | undefined;

    /**
     * Example: A00000001
     */
    get accountId(): string | undefined {
        return this._accountId;
    }

    readonly _assetSymbol: string | undefined;

    /**
     * Example: USDT
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _contractSymbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string | undefined {
        return this._contractSymbol;
    }

    readonly _createdAt: string | undefined;

    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _executedQuantity: number | undefined;

    /**
     * Example: 0
     */
    get executedQuantity(): number | undefined {
        return this._executedQuantity;
    }

    readonly _id: string | undefined;

    /**
     * Example: FO00000000000001
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _isBuy: boolean | undefined;

    get isBuy(): boolean | undefined {
        return this._isBuy;
    }

    readonly _leverage: number | undefined;

    get leverage(): number | undefined {
        return this._leverage;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance Futures
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _positionSide: string | undefined;

    /**
     * Example: SHORT
     */
    get positionSide(): string | undefined {
        return this._positionSide;
    }

    readonly _price: number | undefined;

    /**
     * Example: 3000
     */
    get price(): number | undefined {
        return this._price;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 1
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _rejectReason: string | undefined;

    get rejectReason(): string | undefined {
        return this._rejectReason;
    }

    readonly _slOrderPrice: number | undefined;

    /**
     * Example: 1000
     */
    get slOrderPrice(): number | undefined {
        return this._slOrderPrice;
    }

    readonly _slTriggerPrice: number | undefined;

    /**
     * Example: 1000
     */
    get slTriggerPrice(): number | undefined {
        return this._slTriggerPrice;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    readonly _timeInForce: string | undefined;

    /**
     * Example: IOC
     */
    get timeInForce(): string | undefined {
        return this._timeInForce;
    }

    readonly _tpOrderPrice: number | undefined;

    /**
     * Example: 1000
     */
    get tpOrderPrice(): number | undefined {
        return this._tpOrderPrice;
    }

    readonly _tpTriggerPrice: number | undefined;

    /**
     * Example: 1000
     */
    get tpTriggerPrice(): number | undefined {
        return this._tpTriggerPrice;
    }

    readonly _triggerPrice: number | undefined;

    /**
     * Example: 1000
     */
    get triggerPrice(): number | undefined {
        return this._triggerPrice;
    }

    readonly _type: string | undefined;

    /**
     * Example: LIMIT
     */
    get type(): string | undefined {
        return this._type;
    }

    readonly _updatedAt: string | undefined;

    get updatedAt(): string | undefined {
        return this._updatedAt;
    }

    constructor(props: IFuturesOrder) {
        if (typeof props.accountId === 'string') {
            this._accountId = props.accountId.trim();
        }
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.contractSymbol === 'string') {
            this._contractSymbol = props.contractSymbol.trim();
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.executedQuantity === 'string') {
            this._executedQuantity = Number(props.executedQuantity);
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.isBuy === 'boolean') {
            this._isBuy = props.isBuy;
        }
        if (typeof props.leverage === 'number') {
            this._leverage = props.leverage;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.positionSide === 'string') {
            this._positionSide = props.positionSide.trim();
        }
        if (typeof props.price === 'string') {
            this._price = Number(props.price);
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (typeof props.rejectReason === 'string') {
            this._rejectReason = props.rejectReason.trim();
        }
        if (typeof props.slOrderPrice === 'string') {
            this._slOrderPrice = Number(props.slOrderPrice);
        }
        if (typeof props.slTriggerPrice === 'string') {
            this._slTriggerPrice = Number(props.slTriggerPrice);
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
        if (typeof props.timeInForce === 'string') {
            this._timeInForce = props.timeInForce.trim();
        }
        if (typeof props.tpOrderPrice === 'string') {
            this._tpOrderPrice = Number(props.tpOrderPrice);
        }
        if (typeof props.tpTriggerPrice === 'string') {
            this._tpTriggerPrice = Number(props.tpTriggerPrice);
        }
        if (typeof props.triggerPrice === 'string') {
            this._triggerPrice = Number(props.triggerPrice);
        }
        if (typeof props.type === 'string') {
            this._type = props.type.trim();
        }
        if (typeof props.updatedAt === 'string') {
            this._updatedAt = props.updatedAt.trim();
        }
    }

    serialize(): IFuturesOrder {
        const data: IFuturesOrder = {
        };
        if (typeof this._accountId !== 'undefined') {
            data.accountId = this._accountId;
        }
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._contractSymbol !== 'undefined') {
            data.contractSymbol = this._contractSymbol;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._executedQuantity !== 'undefined') {
            data.executedQuantity = String(this._executedQuantity);
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._isBuy !== 'undefined') {
            data.isBuy = this._isBuy;
        }
        if (typeof this._leverage !== 'undefined') {
            data.leverage = this._leverage;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._positionSide !== 'undefined') {
            data.positionSide = this._positionSide;
        }
        if (typeof this._price !== 'undefined') {
            data.price = String(this._price);
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._rejectReason !== 'undefined') {
            data.rejectReason = this._rejectReason;
        }
        if (typeof this._slOrderPrice !== 'undefined') {
            data.slOrderPrice = String(this._slOrderPrice);
        }
        if (typeof this._slTriggerPrice !== 'undefined') {
            data.slTriggerPrice = String(this._slTriggerPrice);
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        if (typeof this._tpOrderPrice !== 'undefined') {
            data.tpOrderPrice = String(this._tpOrderPrice);
        }
        if (typeof this._tpTriggerPrice !== 'undefined') {
            data.tpTriggerPrice = String(this._tpTriggerPrice);
        }
        if (typeof this._triggerPrice !== 'undefined') {
            data.triggerPrice = String(this._triggerPrice);
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        if (typeof this._updatedAt !== 'undefined') {
            data.updatedAt = this._updatedAt;
        }
        return data;
    }

    toJSON(): IFuturesOrder {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: !this._accountId ? true : typeof this._accountId === 'string',
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            contractSymbol: !this._contractSymbol ? true : typeof this._contractSymbol === 'string',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            executedQuantity: true,
            id: !this._id ? true : typeof this._id === 'string',
            isBuy: !this._isBuy ? true : typeof this._isBuy === 'boolean',
            leverage: !this._leverage ? true : typeof this._leverage === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            positionSide: !this._positionSide ? true : typeof this._positionSide === 'string',
            price: true,
            quantity: true,
            rejectReason: !this._rejectReason ? true : typeof this._rejectReason === 'string',
            slOrderPrice: true,
            slTriggerPrice: true,
            status: !this._status ? true : typeof this._status === 'string',
            timeInForce: !this._timeInForce ? true : typeof this._timeInForce === 'string',
            tpOrderPrice: true,
            tpTriggerPrice: true,
            triggerPrice: true,
            type: !this._type ? true : typeof this._type === 'string',
            updatedAt: !this._updatedAt ? true : typeof this._updatedAt === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
