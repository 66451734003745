// This file was autogenerated. Please do not change.
export interface IBaseAccountNamingPayload {
    description: string;
    name: string;
}

export default class BaseAccountNamingPayload {
    readonly _description: string;

    /** Description: Account description */
    get description(): string {
        return this._description;
    }

    readonly _name: string;

    /** Description: Account name */
    get name(): string {
        return this._name;
    }

    constructor(props: IBaseAccountNamingPayload) {
        this._description = props.description.trim();
        this._name = props.name.trim();
    }

    serialize(): IBaseAccountNamingPayload {
        const data: IBaseAccountNamingPayload = {
            description: this._description,
            name: this._name,
        };
        return data;
    }

    toJSON(): IBaseAccountNamingPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            name: typeof this._name === 'string' && !!this._name.trim(),
            description: typeof this._description === 'string' && !!this._description.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
