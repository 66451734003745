// This file was autogenerated. Please do not change.
export interface ITransferResponseData {
    failureReason?: string;
    status: string;
    transferId: string;
}

export default class TransferResponseData {
    readonly _failureReason: string | undefined;

    /** Description: Failure reason (status "failed" retrieved) */
    get failureReason(): string | undefined {
        return this._failureReason;
    }

    readonly _status: string;

    /** Description: Transfer status */
    get status(): string {
        return this._status;
    }

    readonly _transferId: string;

    /** Description: Transfer UID */
    get transferId(): string {
        return this._transferId;
    }

    constructor(props: ITransferResponseData) {
        if (typeof props.failureReason === 'string') {
            this._failureReason = props.failureReason.trim();
        }
        this._status = props.status.trim();
        this._transferId = props.transferId.trim();
    }

    serialize(): ITransferResponseData {
        const data: ITransferResponseData = {
            status: this._status,
            transferId: this._transferId,
        };
        if (typeof this._failureReason !== 'undefined') {
            data.failureReason = this._failureReason;
        }
        return data;
    }

    toJSON(): ITransferResponseData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            transferId: typeof this._transferId === 'string' && !!this._transferId.trim(),
            status: typeof this._status === 'string' && !!this._status.trim(),
            failureReason: !this._failureReason ? true : typeof this._failureReason === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
