// This file was autogenerated. Please do not change.
export interface ITradingCommissionRateRequest {
    accountId: string;
    placementId: number;
    placementName?: string;
    spotAssetPairId?: number;
    spotAssetPairSymbol?: string;
}

export default class TradingCommissionRateRequest {
    readonly _accountId: string;

    /**
     * Example: A9TALRRMPP7
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _placementId: number;

    /**
     * Example: 10
     */
    get placementId(): number {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _spotAssetPairId: number | undefined;

    /**
     * Example: 10
     */
    get spotAssetPairId(): number | undefined {
        return this._spotAssetPairId;
    }

    readonly _spotAssetPairSymbol: string | undefined;

    /**
     * Example: XLM/BTC
     */
    get spotAssetPairSymbol(): string | undefined {
        return this._spotAssetPairSymbol;
    }

    constructor(props: ITradingCommissionRateRequest) {
        this._accountId = props.accountId.trim();
        this._placementId = props.placementId;
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.spotAssetPairId === 'number') {
            this._spotAssetPairId = props.spotAssetPairId;
        }
        if (typeof props.spotAssetPairSymbol === 'string') {
            this._spotAssetPairSymbol = props.spotAssetPairSymbol.trim();
        }
    }

    serialize(): ITradingCommissionRateRequest {
        const data: ITradingCommissionRateRequest = {
            accountId: this._accountId,
            placementId: this._placementId,
        };
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._spotAssetPairId !== 'undefined') {
            data.spotAssetPairId = this._spotAssetPairId;
        }
        if (typeof this._spotAssetPairSymbol !== 'undefined') {
            data.spotAssetPairSymbol = this._spotAssetPairSymbol;
        }
        return data;
    }

    toJSON(): ITradingCommissionRateRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            placementId: typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            spotAssetPairId: !this._spotAssetPairId ? true : typeof this._spotAssetPairId === 'number',
            spotAssetPairSymbol: !this._spotAssetPairSymbol ? true : typeof this._spotAssetPairSymbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
