String.prototype.getPrecision = function getPrecision() {
    return this.replace(',', '.').substr(this.toString().indexOf('.')).length - 1

}

/**
 *
 * @return {(number|string)}
 */

String.prototype.fraction = function () {

    return this.substr(Math.trunc(+this).toString().length)

};

/**
 * @return {string}
 */
String.prototype.noExponents = function () {

    return (+this).noExponents()

};

/**
 * @return {string}
 */
String.prototype.getSeparatedDigits = function () {

    let int = (+this).getInt(),
        fraction = this.split('.').length === 2 ? `.${ this.split('.')[1] }` : '';

    return String(int).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ') + fraction

};

/**
 * @return {string}
 */
String.prototype.getSpaceSeparatedDigits = function () {

    let int = (+this).getInt(),
        fraction = this.split('.').length === 2 ? `.${ this.split('.')[1] }` : '';

    return String(int).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ') + fraction

};

/**
 * @return {Array}
 */

String.prototype.toCharCode = function () {

    let result: any[] = []

    for (let symbol_index = 0; symbol_index < this.length; symbol_index++) {

        result.push(this.charCodeAt(symbol_index))

    }

    return result

};

String.prototype.toUniqueColor = function () {

    let c = (this.toCharCode().reduce((code, sum) => +code + +sum, 0) * 2 & 0x00FFFFFF)
        .toString(16)
        .toUpperCase();

    return "00000".substring(0, 6 - c.length) + c;

};

String.prototype.makeFirstUppercase = function () {

    let string = this.toLowerCase();

    if (string.length) {

        return string[0].toUpperCase() + string.substr(1)

    } else {
        return ''
    }

};

String.prototype.capitalize = function () {

    let string = this.toLowerCase();

    if (string.length) {

        return string[0].toUpperCase() + string.substr(1)

    } else {
        return ''
    }

}

String.prototype.copyToClipboard = function (callback: () => any) {

    const el = document.createElement('textarea');
    // @ts-ignore
    el.value = this;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    if (callback) {
        callback();
    }
}
