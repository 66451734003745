
import Vue from 'vue';

import { notificationsMessagesResolver } from 'Store/v2/Notifications';

interface Computed {
    createdAt: string;
    title: string;
    text: string;
}

export default Vue.extend<any, any, Computed, any>({
    name: 'Notification.CommonNotification',
    props: {
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        createdAt() {
            return this.notification.createdAt;
        },
        title() {
            return new Date(this.createdAt).toDateString();
        },
        text() {
            return notificationsMessagesResolver(this.notification.message);
        },
    },
});
