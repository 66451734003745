// This file was autogenerated. Please do not change.
export interface ITransfer {
    assetSymbol?: string;
    commissionQuantity?: string;
    createdAt?: string;
    fromPlacementName?: string;
    id?: string;
    quantity?: string;
    status?: ('EXECUTED' | 'NOT_EXECUTED');
    toPlacementName?: string;
    type?: ('deposit' | 'transfer' | 'withdrawal');
}

export default class Transfer {
    readonly _assetSymbol: string | undefined;

    /**
     * Example: ZEC
     */
    get assetSymbol(): string | undefined {
        return this._assetSymbol;
    }

    readonly _commissionQuantity: number | undefined;

    /**
     * Example: 0.26410533
     */
    get commissionQuantity(): number | undefined {
        return this._commissionQuantity;
    }

    readonly _createdAt: string | undefined;

    /**
     * Example: 2022-01-27T11:44:53.068669Z
     */
    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _fromPlacementName: string | undefined;

    /**
     * Example: Bitfinex
     */
    get fromPlacementName(): string | undefined {
        return this._fromPlacementName;
    }

    readonly _id: string | undefined;

    /**
     * Example: TFFS3G527MNPF8H4NXMZCU
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _quantity: number | undefined;

    /**
     * Example: 5.2821066
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _status: ('EXECUTED' | 'NOT_EXECUTED') | undefined;

    /**
     * Example: EXECUTED
     */
    get status(): ('EXECUTED' | 'NOT_EXECUTED') | undefined {
        return this._status;
    }

    readonly _toPlacementName: string | undefined;

    /**
     * Example: OKEx
     */
    get toPlacementName(): string | undefined {
        return this._toPlacementName;
    }

    readonly _type: ('deposit' | 'transfer' | 'withdrawal') | undefined;

    /**
     * Example: transfer
     */
    get type(): ('deposit' | 'transfer' | 'withdrawal') | undefined {
        return this._type;
    }

    constructor(props: ITransfer) {
        if (typeof props.assetSymbol === 'string') {
            this._assetSymbol = props.assetSymbol.trim();
        }
        if (typeof props.commissionQuantity === 'string') {
            this._commissionQuantity = Number(props.commissionQuantity);
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (typeof props.fromPlacementName === 'string') {
            this._fromPlacementName = props.fromPlacementName.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (props.status) {
            this._status = props.status;
        }
        if (typeof props.toPlacementName === 'string') {
            this._toPlacementName = props.toPlacementName.trim();
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): ITransfer {
        const data: ITransfer = {
        };
        if (typeof this._assetSymbol !== 'undefined') {
            data.assetSymbol = this._assetSymbol;
        }
        if (typeof this._commissionQuantity !== 'undefined') {
            data.commissionQuantity = String(this._commissionQuantity);
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._fromPlacementName !== 'undefined') {
            data.fromPlacementName = this._fromPlacementName;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        if (typeof this._toPlacementName !== 'undefined') {
            data.toPlacementName = this._toPlacementName;
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): ITransfer {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbol: !this._assetSymbol ? true : typeof this._assetSymbol === 'string',
            commissionQuantity: true,
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            fromPlacementName: !this._fromPlacementName ? true : typeof this._fromPlacementName === 'string',
            id: !this._id ? true : typeof this._id === 'string',
            quantity: true,
            toPlacementName: !this._toPlacementName ? true : typeof this._toPlacementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
