// This file was autogenerated. Please do not change.
export interface IPairChartData {
    chartData: undefined;
}

export default class PairChartData {
    readonly _chartData: undefined;

    get chartData(): undefined {
        return this._chartData;
    }

    constructor(props: IPairChartData) {
        this._chartData = props.chartData;
    }

    serialize(): IPairChartData {
        const data: IPairChartData = {
            chartData: this._chartData,
        };
        return data;
    }

    toJSON(): IPairChartData {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
