// This file was autogenerated. Please do not change.
export interface IRole {
    id: number;
    name: string;
    policies: string[];
}

export default class Role {
    readonly _id: number;

    /**
     * Example: 2
     */
    get id(): number {
        return this._id;
    }

    readonly _name: string;

    /**
     * Example: trader
     */
    get name(): string {
        return this._name;
    }

    readonly _policies: string[];

    /**
     * Example: order,display
     */
    get policies(): string[] {
        return this._policies;
    }

    constructor(props: IRole) {
        this._id = props.id;
        this._name = props.name.trim();
        this._policies = props.policies;
    }

    serialize(): IRole {
        const data: IRole = {
            id: this._id,
            name: this._name,
            policies: this._policies,
        };
        return data;
    }

    toJSON(): IRole {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            policies: this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
