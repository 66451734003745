<template>
  <div :class="s.container">
    <p :class="s.header">
        {{ $store.getters.localization.Header.Notifications.Title }}
    </p>
    <ul :class="s.list">
      <Notification
          v-for="notification in notifications"
          :key="notification.id"
          :notification="notification"
      />
      <li :class="s.notification" v-if="notifications.length === 0">
        <p>You have not new notifications</p>
      </li>
    </ul>
  </div>
</template>

<script>
import Notification from './Notification.vue';

export default {
    name: 'NotificationsPopover',
    components: {
        Notification,
    },
    props: {
        notifications: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    width: 100vw;
    max-width: 300px;
    max-height: 600px;
    overflow-x: hidden;
    overflow-y: auto;
    & .header {
        font-weight: var(--fw-extrabold);
        font-size: var(--m-l);
        line-height: var(--m-l);
        color: var(--cl-black);
        text-align: left;
        padding: var(--m-m);
        border-bottom: 1px solid var(--cl-gray-light);
    }
    & .list {
        flex-direction: column;
        & .notification {
            padding: var(--m-m);
            border-top: 1px solid var(--cl-gray-light);
            text-align: left;
            color: var(--cl-black);
            width: 100%;
        }
    }
}
</style>
