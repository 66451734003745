// This file was autogenerated. Please do not change.
export interface IPreset {
    active?: boolean;
    createdAt?: string;
    data?: undefined;
    description?: string;
    id?: number;
    name?: string;
    type: ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE');
    updatedAt?: string;
}

export default class Preset {
    readonly _active: boolean | undefined;

    get active(): boolean | undefined {
        return this._active;
    }

    readonly _createdAt: string | undefined;

    get createdAt(): string | undefined {
        return this._createdAt;
    }

    readonly _data: undefined | undefined;

    get data(): undefined | undefined {
        return this._data;
    }

    readonly _description: string | undefined;

    get description(): string | undefined {
        return this._description;
    }

    readonly _id: number | undefined;

    get id(): number | undefined {
        return this._id;
    }

    readonly _name: string | undefined;

    get name(): string | undefined {
        return this._name;
    }

    readonly _type: ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE');

    get type(): ('TERMINAL' | 'WALLETS' | 'PORTFOLIO' | 'WORKSPACE') {
        return this._type;
    }

    readonly _updatedAt: string | undefined;

    get updatedAt(): string | undefined {
        return this._updatedAt;
    }

    constructor(props: IPreset) {
        if (typeof props.active === 'boolean') {
            this._active = props.active;
        }
        if (typeof props.createdAt === 'string') {
            this._createdAt = props.createdAt.trim();
        }
        if (props.data) {
            this._data = props.data;
        }
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        if (typeof props.id === 'number') {
            this._id = props.id;
        }
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        this._type = props.type;
        if (typeof props.updatedAt === 'string') {
            this._updatedAt = props.updatedAt.trim();
        }
    }

    serialize(): IPreset {
        const data: IPreset = {
            type: this._type,
        };
        if (typeof this._active !== 'undefined') {
            data.active = this._active;
        }
        if (typeof this._createdAt !== 'undefined') {
            data.createdAt = this._createdAt;
        }
        if (typeof this._data !== 'undefined') {
            data.data = this._data;
        }
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._updatedAt !== 'undefined') {
            data.updatedAt = this._updatedAt;
        }
        return data;
    }

    toJSON(): IPreset {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            active: !this._active ? true : typeof this._active === 'boolean',
            createdAt: !this._createdAt ? true : typeof this._createdAt === 'string',
            description: !this._description ? true : typeof this._description === 'string',
            id: !this._id ? true : typeof this._id === 'number',
            name: !this._name ? true : typeof this._name === 'string',
            updatedAt: !this._updatedAt ? true : typeof this._updatedAt === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
