/**
 *
 * @type {{WITHDRAW: string, TRANSFER: string, EMPTY_TRANSFER_TYPE_VALUE: undefined, DEPOSIT: string}}
 */
export const RecordsTypes = {
    EMPTY_TRANSFER_TYPE_VALUE: undefined,
    TRANSFER: 'transfer',
    WITHDRAW: 'withdrawal',
    DEPOSIT: 'deposit',
    REBATE: 'rebate',
};

/**
 *
 * @type {{
 * created: string,
 * pending: string, processing: string, failed: string, confirmed: string, recovering: string, recovered: string, recovery_failed: string, manual_recovering: string}}
 */
export const RecordStatuses = {
    CREATED: 'created',
    PENDING: 'pending',
    CONFIRMED: 'confirmed',
    FAILED: 'failed',
    PROCESSING: 'processing',
    RECOVERING: 'recovering',
    RECOVERED: 'recovered',
    RECOVERY_FAILED: 'recovery_failed',
    MANUAL_RECOVERING: 'manual_recovering',
    CANCELLED: 'cancelled',
    NOT_COMPLETED: 'not_completed',
};
