import ApiError from 'Entities/ApiError';
import AddNewAddressRequest from 'Entities/addressWhitelistingManagement/AddNewAddressRequest';
import AddNewAddressResponse from 'Entities/addressWhitelistingManagement/AddNewAddressResponse';
import DeleteAddressRequest from 'Entities/addressWhitelistingManagement/DeleteAddressRequest';
import ProblemDetails from 'Entities/addressWhitelistingManagement/ProblemDetails';
import UpdateAddressRequest from 'Entities/addressWhitelistingManagement/UpdateAddressRequest';

// This file was autogenerated. Please do not change.
// All changes will be overwrited on commit.
export default class WhitelistingManagementFrontendApi {
    static async fWalletWhitelistAddNewAddress(addnewaddressrequest: AddNewAddressRequest, withHeaders?: boolean): Promise<{ data: AddNewAddressResponse; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...addnewaddressrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('fWalletWhitelistAddNewAddress', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/whitelist/addNewAddress`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(addnewaddressrequest.serialize()),
        });
        if (res.status === 200) {
            return { data: new AddNewAddressResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        if (res.status === 201) {
            return { data: new AddNewAddressResponse(await res.json()), headers: withHeaders ? res.headers : undefined };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('WhitelistingManagementFrontend/fWalletWhitelistAddNewAddress', { response: res, data: result, fields: [JSON.stringify({ addnewaddressrequest })] });
    }

    static async fWalletWhitelistDeleteAddress(deleteaddressrequest: DeleteAddressRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...deleteaddressrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('fWalletWhitelistDeleteAddress', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/whitelist/deleteAddress`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(deleteaddressrequest.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('WhitelistingManagementFrontend/fWalletWhitelistDeleteAddress', { response: res, data: result, fields: [JSON.stringify({ deleteaddressrequest })] });
    }

    static async fWalletWhitelistUpdateAddress(updateaddressrequest: UpdateAddressRequest, withHeaders?: boolean): Promise<{ data: number; headers?: Headers }> {
        const haveError: string[] = [];
        haveError.push(...updateaddressrequest.validate());
        if (haveError.length > 0) {
            throw new ApiError('fWalletWhitelistUpdateAddress', { fields: haveError });
        }
        const res = await fetch(`${BACKEND_BASE_URI}/private/v1/wallet/whitelist/updateAddress`, {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${AUTH_TOKEN}`, 'Content-Type': 'application/json'},
            body: JSON.stringify(updateaddressrequest.serialize()),
        });
        if (res.status === 204) {
            return { data: res.status };
        }
        let result;
        try { result = await res.json(); } catch { result = res; }
        throw new ApiError('WhitelistingManagementFrontend/fWalletWhitelistUpdateAddress', { response: res, data: result, fields: [JSON.stringify({ updateaddressrequest })] });
    }
}
