// This file was autogenerated. Please do not change.
export interface IPlaceOrderRequest {
    accountId: string;
    contractSymbol: string;
    leverage: number;
    marketPrice?: string;
    placementName: string;
    positionSide: string;
    price?: string;
    quantity: string;
    side: string;
    slOrderPrice?: string;
    slTriggerPrice?: string;
    timeInForce?: ('GTC' | 'FOK' | 'IOC');
    tpOrderPrice?: string;
    tpTriggerPrice?: string;
    triggerPrice?: string;
    type: ('MARKET' | 'LIMIT' | 'MARKET_STOP' | 'LIMIT_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS');
}

export default class PlaceOrderRequest {
    readonly _accountId: string;

    /**
     * Example: A00000001
     */
    get accountId(): string {
        return this._accountId;
    }

    readonly _contractSymbol: string;

    /**
     * Example: BTC/USDT
     */
    get contractSymbol(): string {
        return this._contractSymbol;
    }

    readonly _leverage: number;

    /**
     * Example: 5
     */
    get leverage(): number {
        return this._leverage;
    }

    readonly _marketPrice: number | undefined;

    /**
     * Example: 900
     */
    get marketPrice(): number | undefined {
        return this._marketPrice;
    }

    readonly _placementName: string;

    /**
     * Example: Binance Futures
     */
    get placementName(): string {
        return this._placementName;
    }

    readonly _positionSide: string;

    /**
     * Example: SHORT
     */
    get positionSide(): string {
        return this._positionSide;
    }

    readonly _price: number | undefined;

    /**
     * Example: 3000
     */
    get price(): number | undefined {
        return this._price;
    }

    readonly _quantity: number;

    /**
     * Example: 0.1
     */
    get quantity(): number {
        return this._quantity;
    }

    readonly _side: string;

    /**
     * Example: BUY
     */
    get side(): string {
        return this._side;
    }

    readonly _slOrderPrice: number | undefined;

    /**
     * Example: 1000
     */
    get slOrderPrice(): number | undefined {
        return this._slOrderPrice;
    }

    readonly _slTriggerPrice: number | undefined;

    /**
     * Example: 1000
     */
    get slTriggerPrice(): number | undefined {
        return this._slTriggerPrice;
    }

    readonly _timeInForce: ('GTC' | 'FOK' | 'IOC') | undefined;

    /**
     * Example: IOC
     */
    get timeInForce(): ('GTC' | 'FOK' | 'IOC') | undefined {
        return this._timeInForce;
    }

    readonly _tpOrderPrice: number | undefined;

    /**
     * Example: 1000
     */
    get tpOrderPrice(): number | undefined {
        return this._tpOrderPrice;
    }

    readonly _tpTriggerPrice: number | undefined;

    /**
     * Example: 1000
     */
    get tpTriggerPrice(): number | undefined {
        return this._tpTriggerPrice;
    }

    readonly _triggerPrice: number | undefined;

    /**
     * Example: 1000
     */
    get triggerPrice(): number | undefined {
        return this._triggerPrice;
    }

    readonly _type: ('MARKET' | 'LIMIT' | 'MARKET_STOP' | 'LIMIT_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS');

    /**
     * Example: LIMIT
     */
    get type(): ('MARKET' | 'LIMIT' | 'MARKET_STOP' | 'LIMIT_STOP' | 'TAKE_PROFIT' | 'STOP_LOSS') {
        return this._type;
    }

    constructor(props: IPlaceOrderRequest) {
        this._accountId = props.accountId.trim();
        this._contractSymbol = props.contractSymbol.trim();
        this._leverage = props.leverage;
        if (typeof props.marketPrice === 'string') {
            this._marketPrice = Number(props.marketPrice);
        }
        this._placementName = props.placementName.trim();
        this._positionSide = props.positionSide.trim();
        if (typeof props.price === 'string') {
            this._price = Number(props.price);
        }
        this._quantity = Number(props.quantity);
        this._side = props.side.trim();
        if (typeof props.slOrderPrice === 'string') {
            this._slOrderPrice = Number(props.slOrderPrice);
        }
        if (typeof props.slTriggerPrice === 'string') {
            this._slTriggerPrice = Number(props.slTriggerPrice);
        }
        if (props.timeInForce) {
            this._timeInForce = props.timeInForce;
        }
        if (typeof props.tpOrderPrice === 'string') {
            this._tpOrderPrice = Number(props.tpOrderPrice);
        }
        if (typeof props.tpTriggerPrice === 'string') {
            this._tpTriggerPrice = Number(props.tpTriggerPrice);
        }
        if (typeof props.triggerPrice === 'string') {
            this._triggerPrice = Number(props.triggerPrice);
        }
        this._type = props.type;
    }

    serialize(): IPlaceOrderRequest {
        const data: IPlaceOrderRequest = {
            accountId: this._accountId,
            contractSymbol: this._contractSymbol,
            leverage: this._leverage,
            placementName: this._placementName,
            positionSide: this._positionSide,
            quantity: String(this._quantity),
            side: this._side,
            type: this._type,
        };
        if (typeof this._marketPrice !== 'undefined') {
            data.marketPrice = String(this._marketPrice);
        }
        if (typeof this._price !== 'undefined') {
            data.price = String(this._price);
        }
        if (typeof this._slOrderPrice !== 'undefined') {
            data.slOrderPrice = String(this._slOrderPrice);
        }
        if (typeof this._slTriggerPrice !== 'undefined') {
            data.slTriggerPrice = String(this._slTriggerPrice);
        }
        if (typeof this._timeInForce !== 'undefined') {
            data.timeInForce = this._timeInForce;
        }
        if (typeof this._tpOrderPrice !== 'undefined') {
            data.tpOrderPrice = String(this._tpOrderPrice);
        }
        if (typeof this._tpTriggerPrice !== 'undefined') {
            data.tpTriggerPrice = String(this._tpTriggerPrice);
        }
        if (typeof this._triggerPrice !== 'undefined') {
            data.triggerPrice = String(this._triggerPrice);
        }
        return data;
    }

    toJSON(): IPlaceOrderRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            contractSymbol: typeof this._contractSymbol === 'string' && !!this._contractSymbol.trim(),
            leverage: typeof this._leverage === 'number',
            marketPrice: true,
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            positionSide: typeof this._positionSide === 'string' && !!this._positionSide.trim(),
            price: true,
            quantity: true,
            side: typeof this._side === 'string' && !!this._side.trim(),
            slOrderPrice: true,
            slTriggerPrice: true,
            tpOrderPrice: true,
            tpTriggerPrice: true,
            triggerPrice: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
