// This file was autogenerated. Please do not change.
export interface IUpdateAccountPayload {
    color?: string;
    description?: string;
    id: string;
    name?: string;
}

export default class UpdateAccountPayload {
    readonly _color: string | undefined;

    /** Description: Color */
    get color(): string | undefined {
        return this._color;
    }

    readonly _description: string | undefined;

    /** Description: Account description */
    get description(): string | undefined {
        return this._description;
    }

    readonly _id: string;

    /** Description: Account unique identifier */
    get id(): string {
        return this._id;
    }

    readonly _name: string | undefined;

    /** Description: Account name */
    get name(): string | undefined {
        return this._name;
    }

    constructor(props: IUpdateAccountPayload) {
        if (typeof props.color === 'string') {
            this._color = props.color.trim();
        }
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._id = props.id.trim();
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
    }

    serialize(): IUpdateAccountPayload {
        const data: IUpdateAccountPayload = {
            id: this._id,
        };
        if (typeof this._color !== 'undefined') {
            data.color = this._color;
        }
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        return data;
    }

    toJSON(): IUpdateAccountPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            name: !this._name ? true : typeof this._name === 'string',
            description: !this._description ? true : typeof this._description === 'string',
            id: typeof this._id === 'string' && !!this._id.trim(),
            color: !this._color ? true : typeof this._color === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
