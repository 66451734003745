// This file was autogenerated. Please do not change.
export interface IMaintenanceStatus {
    enabled?: boolean;
    maintenanceScopeId?: number;
    maintenanceScopeName?: string;
    placementId?: number;
    placementName?: string;
    placementTag?: string;
}

export default class MaintenanceStatus {
    readonly _enabled: boolean | undefined;

    get enabled(): boolean | undefined {
        return this._enabled;
    }

    readonly _maintenanceScopeId: number | undefined;

    get maintenanceScopeId(): number | undefined {
        return this._maintenanceScopeId;
    }

    readonly _maintenanceScopeName: string | undefined;

    get maintenanceScopeName(): string | undefined {
        return this._maintenanceScopeName;
    }

    readonly _placementId: number | undefined;

    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _placementTag: string | undefined;

    get placementTag(): string | undefined {
        return this._placementTag;
    }

    constructor(props: IMaintenanceStatus) {
        if (typeof props.enabled === 'boolean') {
            this._enabled = props.enabled;
        }
        if (typeof props.maintenanceScopeId === 'number') {
            this._maintenanceScopeId = props.maintenanceScopeId;
        }
        if (typeof props.maintenanceScopeName === 'string') {
            this._maintenanceScopeName = props.maintenanceScopeName.trim();
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.placementTag === 'string') {
            this._placementTag = props.placementTag.trim();
        }
    }

    serialize(): IMaintenanceStatus {
        const data: IMaintenanceStatus = {
        };
        if (typeof this._enabled !== 'undefined') {
            data.enabled = this._enabled;
        }
        if (typeof this._maintenanceScopeId !== 'undefined') {
            data.maintenanceScopeId = this._maintenanceScopeId;
        }
        if (typeof this._maintenanceScopeName !== 'undefined') {
            data.maintenanceScopeName = this._maintenanceScopeName;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._placementTag !== 'undefined') {
            data.placementTag = this._placementTag;
        }
        return data;
    }

    toJSON(): IMaintenanceStatus {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            enabled: !this._enabled ? true : typeof this._enabled === 'boolean',
            maintenanceScopeId: !this._maintenanceScopeId ? true : typeof this._maintenanceScopeId === 'number',
            maintenanceScopeName: !this._maintenanceScopeName ? true : typeof this._maintenanceScopeName === 'string',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            placementTag: !this._placementTag ? true : typeof this._placementTag === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
