import { SnackNotificationTypes, SnackNotification, SnackNotificationDefaultTitle } from 'Models/snackbars';

const state = {
    notifications: [],
};

const getters = {
    notifications: (state) => state.notifications,
};

const mutations = {
    ADD_NOTIFICATION(state, notification) {
        state.notifications.push(notification);
    },
    REMOVE_NOTIFICATION(state, notificationId) {
        const notificationIndex = state.notifications.findIndex(({ id }) => id === notificationId);

        if (notificationIndex !== -1) {
            state.notifications.splice(notificationIndex, 1);
        }
    },
};

const actions = {
    addNotification({ commit }, notification) {
        const { type } = notification;

        let duration: number;
        switch (type) {
            case 'success':
                duration = 6000;
                break;
            case 'error':
                duration = 8000;
                break;
            default:
                duration = 4000;
                break;
        }
        commit('ADD_NOTIFICATION', {
            ...notification,
            duration,
        });
    },
    removeNotification({ commit }, notificationId) {
        commit('REMOVE_NOTIFICATION', notificationId);
    },

    showNotification({ dispatch }, { type, data }) {
        if (typeof data === 'string') {
            dispatch(
                'addNotification',
                new SnackNotification({
                    type,
                    title: SnackNotificationDefaultTitle[type],
                    message: data,
                    duration: undefined,
                }),
            );
        } else if (typeof data === 'object') {
            const { message } = data;
            const title = data.title || SnackNotificationDefaultTitle[type];
            const duration = data.duration || undefined;

            dispatch(
                'addNotification',
                new SnackNotification({
                    type,
                    title,
                    message,
                    duration,
                }),
            );
        } else {
            throw new Error(`unprocessable data type: ${typeof data}`);
        }
    },

    showTechnicalNotification({ dispatch }, data) {
        dispatch('showNotification', {
            type: SnackNotificationTypes.TECHNICAL,
            data,
        });
    },
    showSuccessNotification({ dispatch }, data) {
        dispatch('showNotification', {
            type: SnackNotificationTypes.SUCCESS,
            data,
        });
    },
    showWarningNotification({ dispatch }, data) {
        dispatch('showNotification', {
            type: SnackNotificationTypes.WARNING,
            data,
        });
    },
    showErrorNotification({ dispatch }, data) {
        dispatch('showNotification', {
            type: SnackNotificationTypes.ERROR,
            data,
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
