<template>
    <div
        :class="['tag', 'guid', {'has-background': hasBackground}, s.maxWidth]"
        @click.stop="copyGuid"
    >

        <slot
            name="label"
            :guid="guid"
        >
            <span :class="{ [textClass]: textClass }">
                {{ isShortGuid ? shortGuid : guid }}
            </span>
        </slot>

        <svg width="10"
             height="10"
             viewBox="0 0 10 10"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M6.43538 1.75H1.78659C1.35038 1.75 0.996094 2.10429 0.996094 2.5405V9.21058C0.996094 9.64678 1.35038 10.0011 1.78659 10.0011H6.43538C6.87158 10.0011 7.22587 9.64678 7.22587 9.21058V2.5405C7.22383 2.10429 6.86954 1.75 6.43538 1.75ZM6.67089 9.20853C6.67089 9.3396 6.56439 9.44609 6.43333 9.44609H1.78454C1.65348 9.44609 1.54698 9.3396 1.54698 9.20853V2.5405C1.54698 2.40943 1.65348 2.30294 1.78454 2.30294H6.43333C6.56439 2.30294 6.67089 2.40943 6.67089 2.5405V9.20853Z"
                  fill="#8A8BA1"
            />
            <path d="M8.21272 0H3.56394C3.12773 0 2.77344 0.35429 2.77344 0.790498C2.77344 0.944092 2.89631 1.06697 3.04991 1.06697C3.2035 1.06697 3.32638 0.944092 3.32638 0.790498C3.32638 0.659431 3.43287 0.552939 3.56394 0.552939H8.21272C8.34378 0.552939 8.45028 0.659431 8.45028 0.790498V7.46058C8.45028 7.59164 8.34378 7.69814 8.21272 7.69814C8.05912 7.69814 7.93625 7.82101 7.93625 7.97461C7.93625 8.1282 8.05912 8.25107 8.21272 8.25107C8.64892 8.25107 9.00321 7.89678 9.00321 7.46058V0.790498C9.00321 0.35429 8.64892 0 8.21272 0Z"
                  fill="#8A8BA1"
            />
        </svg>

    </div>
</template>

<script>
export default {
    props: {
        guid: {
            type: [String, Number],
            required: true,
        },
        isShortGuid: {
            type: Boolean,
            default: false,
        },
        hasBackground: {
            type: Boolean,
            default: true,
        },
        notificationCopyMessage: {
            type: String,
            default: null,
        },
        textClass: {
            type: undefined,
        },
    },
    computed: {
        shortGuid() {
            if (!this.isShortGuid) {
                return '';
            }
            return `${this.guid.slice(0, 4)}...${this.guid.slice(this.guid.length - 2, this.guid.length)}`;
        },
    },
    methods: {
        copyGuid() {
            const guid = this.guid.toString();
            guid.copyToClipboard();
            this.$emit('copied');
            this.$store.dispatch('Notificator/showSuccessNotification', this.notificationCopyMessage ? this.notificationCopyMessage : `Id ${guid} is copied to your clipboard`);
        },
    },
};
</script>

<style lang="postcss" module="s">
.maxWidth {
    max-width: 100px;
}
</style>
