// This file was autogenerated. Please do not change.
export interface IUserTransferAddress {
    address: string;
    alias: string;
    assetSymbols: string[];
    blockchainName: string;
    memo?: string;
    status?: ('PENDING_COMPLIANCE_APPROVAL' | 'PENDING_USER_APPROVAL' | 'BLOCKED' | 'APPROVED');
}

export default class UserTransferAddress {
    readonly _address: string;

    /**
     * Example: GC5GTYT7RL34TOYHINKX6O5ODRYU5PEIGILYZGFXYTEUU2BAWIC7QR4F
     */
    get address(): string {
        return this._address;
    }

    readonly _alias: string;

    /**
     * Example: My Ether Wallet
     */
    get alias(): string {
        return this._alias;
    }

    readonly _assetSymbols: string[];

    /**
     * Example: ETH,USDT,USDC
     */
    get assetSymbols(): string[] {
        return this._assetSymbols;
    }

    readonly _blockchainName: string;

    /**
     * Example: Stellar
     */
    get blockchainName(): string {
        return this._blockchainName;
    }

    readonly _memo: string | undefined;

    /**
     * Example: 1a2b3c
     */
    get memo(): string | undefined {
        return this._memo;
    }

    readonly _status: ('PENDING_COMPLIANCE_APPROVAL' | 'PENDING_USER_APPROVAL' | 'BLOCKED' | 'APPROVED') | undefined;

    /**
     * Example: PENDING_COMPLIANCE_APPROVAL
     */
    get status(): ('PENDING_COMPLIANCE_APPROVAL' | 'PENDING_USER_APPROVAL' | 'BLOCKED' | 'APPROVED') | undefined {
        return this._status;
    }

    constructor(props: IUserTransferAddress) {
        this._address = props.address.trim();
        this._alias = props.alias.trim();
        this._assetSymbols = props.assetSymbols;
        this._blockchainName = props.blockchainName.trim();
        if (typeof props.memo === 'string') {
            this._memo = props.memo.trim();
        }
        if (props.status) {
            this._status = props.status;
        }
    }

    serialize(): IUserTransferAddress {
        const data: IUserTransferAddress = {
            address: this._address,
            alias: this._alias,
            assetSymbols: this._assetSymbols,
            blockchainName: this._blockchainName,
        };
        if (typeof this._memo !== 'undefined') {
            data.memo = this._memo;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IUserTransferAddress {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            address: typeof this._address === 'string' && !!this._address.trim(),
            alias: typeof this._alias === 'string' && !!this._alias.trim(),
            assetSymbols: this._assetSymbols.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            blockchainName: typeof this._blockchainName === 'string' && !!this._blockchainName.trim(),
            memo: !this._memo ? true : typeof this._memo === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
