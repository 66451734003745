import PrivateChannel, { IPrivateChannel } from './PrivateChannel';

// This file was autogenerated. Please do not change.
export interface IStreamChannelsResponse {
    channels: IPrivateChannel[];
}

export default class StreamChannelsResponse {
    readonly _channels: PrivateChannel[];

    get channels(): PrivateChannel[] {
        return this._channels;
    }

    constructor(props: IStreamChannelsResponse) {
        this._channels = props.channels.map((p) => new PrivateChannel(p));
    }

    serialize(): IStreamChannelsResponse {
        const data: IStreamChannelsResponse = {
            channels: this._channels.map((p) => p.serialize()),
        };
        return data;
    }

    toJSON(): IStreamChannelsResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            channels: this._channels.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
