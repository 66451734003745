// This file was autogenerated. Please do not change.
export interface IPlacement {
    assetPairs?: number;
    assets?: number;
    commissionMaker: string;
    commissionPlatform: string;
    commissionTaker: string;
    disabled: boolean;
    id: number;
    maintenanceStatus: Record<string, string>;
    name: string;
    needExternalKyc: boolean;
    orderTypes: Record<string, string>;
    positionQuantityUnit?: ('quote' | 'contract');
    tag: string;
    type: string;
    volume24h?: string;
}

export default class Placement {
    readonly _assetPairs: number | undefined;

    /**
     * Example: 200
     */
    get assetPairs(): number | undefined {
        return this._assetPairs;
    }

    readonly _assets: number | undefined;

    /**
     * Example: 100
     */
    get assets(): number | undefined {
        return this._assets;
    }

    readonly _commissionMaker: number;

    /**
     * Example: 0.075
     */
    get commissionMaker(): number {
        return this._commissionMaker;
    }

    readonly _commissionPlatform: number;

    /**
     * Example: 0
     */
    get commissionPlatform(): number {
        return this._commissionPlatform;
    }

    readonly _commissionTaker: number;

    /**
     * Example: 0.075
     */
    get commissionTaker(): number {
        return this._commissionTaker;
    }

    readonly _disabled: boolean;

    get disabled(): boolean {
        return this._disabled;
    }

    readonly _id: number;

    /**
     * Example: 2
     */
    get id(): number {
        return this._id;
    }

    readonly _maintenanceStatus: Record<string, string>;

    /**
     * Example: [object Object]
     */
    get maintenanceStatus(): Record<string, string> {
        return this._maintenanceStatus;
    }

    readonly _name: string;

    /**
     * Example: Binance
     */
    get name(): string {
        return this._name;
    }

    readonly _needExternalKyc: boolean;

    get needExternalKyc(): boolean {
        return this._needExternalKyc;
    }

    readonly _orderTypes: Record<string, string>;

    /**
     * Example: [object Object]
     */
    get orderTypes(): Record<string, string> {
        return this._orderTypes;
    }

    readonly _positionQuantityUnit: ('quote' | 'contract') | undefined;

    /**
     * Example: quote
     */
    get positionQuantityUnit(): ('quote' | 'contract') | undefined {
        return this._positionQuantityUnit;
    }

    readonly _tag: string;

    /**
     * Example: BINANCE
     */
    get tag(): string {
        return this._tag;
    }

    readonly _type: string;

    /**
     * Example: spot
     */
    get type(): string {
        return this._type;
    }

    readonly _volume24h: number | undefined;

    /**
     * Example: 12345.67
     */
    get volume24h(): number | undefined {
        return this._volume24h;
    }

    constructor(props: IPlacement) {
        if (typeof props.assetPairs === 'number') {
            this._assetPairs = props.assetPairs;
        }
        if (typeof props.assets === 'number') {
            this._assets = props.assets;
        }
        this._commissionMaker = Number(props.commissionMaker);
        this._commissionPlatform = Number(props.commissionPlatform);
        this._commissionTaker = Number(props.commissionTaker);
        this._disabled = props.disabled;
        this._id = props.id;
        this._maintenanceStatus = props.maintenanceStatus;
        this._name = props.name.trim();
        this._needExternalKyc = props.needExternalKyc;
        this._orderTypes = props.orderTypes;
        if (props.positionQuantityUnit) {
            this._positionQuantityUnit = props.positionQuantityUnit;
        }
        this._tag = props.tag.trim();
        this._type = props.type.trim();
        if (typeof props.volume24h === 'string') {
            this._volume24h = Number(props.volume24h);
        }
    }

    serialize(): IPlacement {
        const data: IPlacement = {
            commissionMaker: String(this._commissionMaker),
            commissionPlatform: String(this._commissionPlatform),
            commissionTaker: String(this._commissionTaker),
            disabled: this._disabled,
            id: this._id,
            maintenanceStatus: this._maintenanceStatus,
            name: this._name,
            needExternalKyc: this._needExternalKyc,
            orderTypes: this._orderTypes,
            tag: this._tag,
            type: this._type,
        };
        if (typeof this._assetPairs !== 'undefined') {
            data.assetPairs = this._assetPairs;
        }
        if (typeof this._assets !== 'undefined') {
            data.assets = this._assets;
        }
        if (typeof this._positionQuantityUnit !== 'undefined') {
            data.positionQuantityUnit = this._positionQuantityUnit;
        }
        if (typeof this._volume24h !== 'undefined') {
            data.volume24h = String(this._volume24h);
        }
        return data;
    }

    toJSON(): IPlacement {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetPairs: !this._assetPairs ? true : typeof this._assetPairs === 'number',
            assets: !this._assets ? true : typeof this._assets === 'number',
            commissionMaker: true,
            commissionPlatform: true,
            commissionTaker: true,
            disabled: typeof this._disabled === 'boolean',
            id: typeof this._id === 'number',
            name: typeof this._name === 'string' && !!this._name.trim(),
            needExternalKyc: typeof this._needExternalKyc === 'boolean',
            tag: typeof this._tag === 'string' && !!this._tag.trim(),
            type: typeof this._type === 'string' && !!this._type.trim(),
            volume24h: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
