<template>
    <li
        :id="isChosen ? `activeAccount${isThemeDark ? 'Dark' : 'Light'}` : `account${account.id}`"
        @click="setActiveAccount(account.id)"
        :class="s.container"
        ref="account"
    >
        <div :class="[s.row, s.mbS]">
            <span
                v-if="!areExchangesConnected"
                :class="[s.connectingExchangesHeader]"
            >
                Connecting to exchanges...
            </span>
            <span
                v-if="areExchangesConnected"
                :class="[s.totalText, s.pointer, { [s.violet]: isChosen, [s.small]: isSmallTextNeeded }]"
            >
                {{ account.quotationAssetCharacter + accountTotal.toFixed(pnlPrecision).getSpaceSeparatedDigits() }}
            </span>
            <span
                v-if="!isSuspended && areExchangesConnected"
                :class="[s.smallText, s.pointer, { [s.small]: isSmallTextNeeded, [s.green]: isDailyPnlGain, [s.red]: isDailyPnlLoose, [s.violet]: isChosen }]"
            >
                <span v-if="isDailyPnlLoose">-</span><span v-else-if="isDailyPnlGain">+</span><span>{{ account.quotationAssetCharacter }}</span>{{ dailyPnl ? dailyPnl > 0 ? dailyPnl.toFixed(pnlPrecision).getSeparatedDigits() : (dailyPnl * (-1)).toFixed(pnlPrecision).getSeparatedDigits() : 0 }}
            </span>
            <Icon
                :class="[s.right, areExchangesConnected ? s.violet : s.gray]"
                icon="check_mark"
                v-if="isChosen"
            />
            <Icon
                :class="[s.right, s.gray]"
                icon="clock"
                v-else-if="!areExchangesConnected"
            />
        </div>
        <span :class="[s.row, s.mbS, s.pointer]">
            <span
                v-if="!areExchangesConnected"
                :class="[s.connectingExchangesSubheader]"
            >
                Balances are being updated
            </span>
            <span
                v-if="areExchangesConnected"
                :class="[s.smallText, s.green, { [s.violet]: isChosen, [s.small]: isSmallTextNeeded }]"
            >
                {{ account.quotationAssetCharacter }}{{ accountFree.toFixed(pnlPrecision).getSeparatedDigits() }}
                {{ $store.getters.localization.LeftMenu.Spans.Free }}
            </span>
            <span
                v-if="areExchangesConnected"
            >
                •
            </span>
            <span
                v-if="areExchangesConnected"
                :class="[s.smallText, s.red, { [s.violet]: isChosen, [s.small]: isSmallTextNeeded }]"
            >
                {{ account.quotationAssetCharacter }}{{ accountHold.toFixed(pnlPrecision).getSeparatedDigits() }}
                {{ $store.getters.localization.LeftMenu.Spans.OnHold }}
            </span>
        </span>
        <div :class="s.row">
            <span
                :class="[s.tag, s.pointer, { [s.active]: isChosen }]"
                @click.stop
                v-tooltip.auto="{
                    content: accountDescription,
                    trigger: 'click',
                    autoHide: true,
                    offset: 15,
                    delay: { show: 100, hide: 100 },
                }"
            >
                <AccountColorMarker :color="accountColor" />
                #{{ accountName }}
            </span>
            <Icon
                v-if="account.managersCount > 0 && account.roleName !== 'demo'"
                icon="manager"
                :class="s.users"
            />
            <Icon
                v-if="isChosen && account.roleName !== 'demo'"
                :class="[s.right, s.pointer]"
                icon="cogwheel"
                class="cogwheel"
                @clicked="$emit('show', { account, event: $event })"
            />
        </div>
        <span class="drag-handle" :class="s.drag" v-if="isDragging && !isMain"></span>
    </li>
</template>

<script>
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import { PORTFOLIO_TYPES } from 'Models/portfolio';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import AccountColorMarker from 'Common/AccountColorMarker.vue';

export default {
    name: 'Account',
    components: {
        AccountColorMarker,
        Icon,
    },
    props: {
        account: {
            type: Object,
            default: () => ({}),
        },
        isDragging: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
        accountTotal() {
            return this.$store.getters['Balances/GET_ACCOUNT_TOTAL_QUOTED'](this.account.id);
        },
        accountFree() {
            return this.$store.getters['Balances/GET_ACCOUNT_FREE_QUOTED'](this.account.id);
        },
        accountHold() {
            return this.$store.getters['Balances/GET_ACCOUNT_HOLD_QUOTED'](this.account.id);
        },
        accountColor() {
            if (!this.account.color) {
                return this.isThemeDark ? '#23232A' : '#f1f2f5';
            }
            return this.account.color;
        },
        accountId() {
            return this.account.id;
        },
        accountName() {
            return this.account.name;
        },
        accountDescription() {
            return this.account.description;
        },
        activeAccount() {
            return this.$store.getters['Accounts/activeAccountID'];
        },
        isChosen() {
            return this.account.id === this.activeAccount;
        },
        dailyPnl() {
            return +this.account.accountDailyPnl;
        },
        isDailyPnlGain() {
            return this.dailyPnl > 0;
        },
        isDailyPnlLoose() {
            return this.dailyPnl < 0;
        },
        isMain() {
            return this.account.isMain;
        },
        isSuspended() {
            return this.account.isSuspended;
        },
        accountOrder() {
            return this.account.order;
        },
        isSelectable() {
            return !this.isSuspended;
        },
        pnlPrecision() {
            let precision;
            const quotationCharacter = this.$store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
            switch (quotationCharacter) {
                case 'BTC': {
                    precision = 8;
                    break;
                }
                case 'ETH': {
                    precision = 8;
                    break;
                }
                case 'USD': {
                    precision = 2;
                    break;
                }
                case 'EUR': {
                    precision = 2;
                    break;
                }
                default: {
                    precision = 2;
                    break;
                }
            }
            return precision;
        },
        isSmallTextNeeded() {
            return `${this.account.quotationAssetCharacter + this.accountTotal.toFixed(this.pnlPrecision).getSpaceSeparatedDigits()}`.length > 9;
        },
        areExchangesConnected() {
            const storePlacements = this.$store.state.Placements.placements
                .reduce((accum, current) => {
                    if (current.disabled) {
                        return accum;
                    }
                    const temp = [...accum];
                    temp.push(current.name.toLowerCase());
                    return temp;
                }, [])
                .sort();
            const accountPlacements = this.account.placementLinks
                .map((p) => p.toLowerCase())
                .sort();

            let flag = true;
            if (storePlacements.length === accountPlacements.length) {
                for (let i = 0; i < storePlacements.length; i += 1) {
                    if (storePlacements[i] !== accountPlacements[i]) {
                        flag = false;
                        break;
                    }
                }
            } else {
                flag = false;
            }

            return flag || this.account.role !== 'owner';
        },
    },
    methods: {
        setActiveAccount(id) {
            if (!this.isChosen && this.isSelectable) {
                try {
                    this.$store.commit(SET_LOADING_ON(undefined));
                    this.$store.dispatch('Accounts/setActiveAccount', id);
                    this.$store.dispatch('Portfolio/setPortfolioType', PORTFOLIO_TYPES.CURRENT_ACCOUNT);
                } finally {
                    this.$store.commit(SET_LOADING_OFF(undefined));
                }
            }
        },
    },
    mounted() {
        if (this.isChosen) {
            this.$emit('select', this.$refs.account);
        }
    },
    watch: {
        isChosen(value) {
            if (value) {
                this.$emit('select', this.$refs.account);
            }
        },
    },
};
</script>

<style lang="postcss" module="s">
.container {
    position: relative;
    padding: var(--m-l) var(--m-s) var(--m-l) var(--m-xxl);
    z-index: 2;
    & .row {
        display: flex;
        align-items: center;
        column-gap: var(--m-xs);
    }
    & .tag {
        display: flex;
        align-items: center;
        background-color: var(--cl-gray-light);
        padding: var(--m-xs) var(--m-s);
        border-radius: 100px;
        column-gap: var(--m-s);
        width: max-content;
        &.active {
            outline: 1px solid var(--cl-violet);
            color: var(--cl-violet);
        }
    }
    & .mbS {
        margin-bottom: var(--m-s);
    }
    & .right {
        margin-left: auto;
    }
    & .drag {
        position: absolute;
        left: 0;
        top: 50%;
        width: 20px;
        height: 35px;
        cursor: move;
        transform: translateY(-50%);
        &:before {
            content: '...';
            display: block;
            height: 50%;
            position: absolute;
            left: 120%;
            top: 50%;
            transform: translate(-90%, -50%) rotate(90deg);
            font-size: var(--fs-xxxl);
            color: var(--cl-gray);
        }
    }
    & .totalText {
        font-weight: var(--fw-bold);
        font-size: var(--fs-xxxl);
        line-height: var(--fs-sl);
        color: var(--cl-black);
        &.violet {
            color: var(--cl-violet);
        }
        &.small {
            font-size: var(--fs-xl);
            line-height: var(--fs-sl);
        }
    }
    & .smallText {
        font-weight: var(--fw-bold);
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        &.green {
            color: var(--cl-green);
        }
        &.red {
            color: var(--cl-red);
        }
        &.violet {
            color: var(--cl-violet);
        }
        &.small {
            font-size: var(--fs-s);
            line-height: var(--fs-s);
        }
    }
    & .users {
        color: var(--cl-gray);
    }
    & .violet {
        color: var(--cl-violet);
    }
    & .gray {
        color: var(--cl-gray);
    }
    & .pointer {
        cursor: pointer;
    }
    & .connectingExchangesHeader {
        font-size: var(--fs-xl);
        line-height: var(--fs-xl);
        font-weight: var(--fw-regular);
        color: var(--cl-gray);
        white-space: nowrap;
    }
    & .connectingExchangesSubheader {
        font-size: var(--fs-m);
        line-height: var(--fs-m);
        font-weight: var(--fw-semibold);
        color: var(--cl-gray);
        white-space: nowrap;
    }
}
</style>
