import { mutationCreator } from 'Store/utils';

const state = {
    isModalShown: false as boolean,
};

export type ModalState = typeof state;

export enum ModalMutations {
    SHOW_MODAL = 'SHOW_MODAL',
    HIDE_MODAL = 'HIDE_MODAL',
}

export const SHOW_MODAL = mutationCreator<undefined>('Modal', ModalMutations.SHOW_MODAL);
export const HIDE_MODAL = mutationCreator<undefined>('Modal', ModalMutations.HIDE_MODAL);

const mutations: Record<ModalMutations, (state: ModalState, ...args: any) => void> = {
    SHOW_MODAL(state) {
        state.isModalShown = true;
    },
    HIDE_MODAL(state) {
        state.isModalShown = false;
    },
};

export default {
    namespaced: true,
    state,
    mutations,
};
