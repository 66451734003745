// This file was autogenerated. Please do not change.
export interface ITransferFee {
    assetSymbol: string;
    value: string;
}

export default class TransferFee {
    readonly _assetSymbol: string;

    /**
     * Example: XLM
     */
    get assetSymbol(): string {
        return this._assetSymbol;
    }

    readonly _value: number;

    /**
     * Example: 0
     */
    get value(): number {
        return this._value;
    }

    constructor(props: ITransferFee) {
        this._assetSymbol = props.assetSymbol.trim();
        this._value = Number(props.value);
    }

    serialize(): ITransferFee {
        const data: ITransferFee = {
            assetSymbol: this._assetSymbol,
            value: String(this._value),
        };
        return data;
    }

    toJSON(): ITransferFee {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetSymbol: typeof this._assetSymbol === 'string' && !!this._assetSymbol.trim(),
            value: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
