export const EventTypes = {
    'user.session.start': {
        field: 'USER_SESSION_START',
        value: 'UserSessionStart',
    },
};

export const EventResponses = {
    Pass: {
        field: 'SUCCESS',
        value: 'Pass',
    },
    Fail: {
        field: 'FAILURE',
        value: 'Fail',
    },
    InProgress: {
        field: 'IN_PROGRESS',
        value: 'InProgress',
    },
};

export class AuthEvent {
    apiData

    createdAt

    eventType

    eventResponse

    ipAddress

    device

    browser

    country

    region

    constructor(data) {
        try {
            this.apiData = data;

            this.createdAt = new Date(data.published);
            this.eventType = EventTypes[data.eventType];
            this.eventResponse = EventResponses.Pass;

            this.ipAddress = data.client.ipAddress;

            this.device = data.client.userAgent.os;
            this.browser = data.client.userAgent.browser;

            this.country = data.client.geographicalContext.country;
            this.region = data.client.geographicalContext.city;
        } catch (e) {
            throw ({
                error: e,
                data,
            });
        }
    }
}
