import OrderAttributes, { IOrderAttributes } from './OrderAttributes';

// This file was autogenerated. Please do not change.
export interface IAttributes {
    limit?: IOrderAttributes;
    market?: IOrderAttributes;
}

export default class Attributes {
    readonly _limit: OrderAttributes | undefined;

    get limit(): OrderAttributes | undefined {
        return this._limit;
    }

    readonly _market: OrderAttributes | undefined;

    get market(): OrderAttributes | undefined {
        return this._market;
    }

    constructor(props: IAttributes) {
        if (props.limit) {
            this._limit = new OrderAttributes(props.limit);
        }
        if (props.market) {
            this._market = new OrderAttributes(props.market);
        }
    }

    serialize(): IAttributes {
        const data: IAttributes = {
        };
        if (typeof this._limit !== 'undefined') {
            data.limit = this._limit.serialize();
        }
        if (typeof this._market !== 'undefined') {
            data.market = this._market.serialize();
        }
        return data;
    }

    toJSON(): IAttributes {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            limit: !this._limit ? true : this._limit.validate().length === 0,
            market: !this._market ? true : this._market.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
