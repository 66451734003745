// This file was autogenerated. Please do not change.
export interface IUserAgentObject {
    browser?: string;
    os?: string;
    rawUserAgent?: string;
}

export default class UserAgentObject {
    readonly _browser: string | undefined;

    /** Description: Browser */
    get browser(): string | undefined {
        return this._browser;
    }

    readonly _os: string | undefined;

    /** Description: Operational system */
    get os(): string | undefined {
        return this._os;
    }

    readonly _rawUserAgent: string | undefined;

    /** Description: Raw user agent */
    get rawUserAgent(): string | undefined {
        return this._rawUserAgent;
    }

    constructor(props: IUserAgentObject) {
        if (typeof props.browser === 'string') {
            this._browser = props.browser.trim();
        }
        if (typeof props.os === 'string') {
            this._os = props.os.trim();
        }
        if (typeof props.rawUserAgent === 'string') {
            this._rawUserAgent = props.rawUserAgent.trim();
        }
    }

    serialize(): IUserAgentObject {
        const data: IUserAgentObject = {
        };
        if (typeof this._browser !== 'undefined') {
            data.browser = this._browser;
        }
        if (typeof this._os !== 'undefined') {
            data.os = this._os;
        }
        if (typeof this._rawUserAgent !== 'undefined') {
            data.rawUserAgent = this._rawUserAgent;
        }
        return data;
    }

    toJSON(): IUserAgentObject {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            rawUserAgent: !this._rawUserAgent ? true : typeof this._rawUserAgent === 'string',
            os: !this._os ? true : typeof this._os === 'string',
            browser: !this._browser ? true : typeof this._browser === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
