
import Vue from 'vue';

type Props = {
    content: string;
    isBottom?: boolean;
};

type NoData = Record<string, never>;

export default Vue.extend<NoData, NoData, NoData, Props>({
    props: {
        content: {
            type: String,
            required: true,
        },
        isBottom: {
            type: Boolean,
            default: false,
        },
    },
});
