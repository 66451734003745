var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(!_vm.withName)?_c('div',{class:_vm.s.colorMarker,style:(`background-color: ${_vm.color}; width: ${_vm.size}px; height: ${_vm.size}px; border: 1px solid ${_vm.isActive ? '#656FDB' : _vm.isThemeDark ? '#2c2c36' : '#FFFFFF'}`)}):_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
            content: _vm.withName.description,
            trigger: 'focus',
            placement: 'bottom-center',
            arrowSelector: '.tooltip-arrow',
            hideOnTargetClick: false,
            offset: 15,
        }),expression:"{\n            content: withName.description,\n            trigger: 'focus',\n            placement: 'bottom-center',\n            arrowSelector: '.tooltip-arrow',\n            hideOnTargetClick: false,\n            offset: 15,\n        }"}],class:[_vm.s.tooltip, { [_vm.s.noMargin]: _vm.noMargin, [_vm.s.outline]: _vm.needOutline }],attrs:{"tabindex":"0"}},[_c('div',{class:_vm.s.colorMarker,style:(`background-color: ${_vm.color}; width: ${_vm.size}px; height: ${_vm.size}px; border: 1px solid ${_vm.isActive ? '#656FDB' : _vm.isThemeDark ? '#2c2c36' : '#FFFFFF'}`)}),_vm._v(" #"+_vm._s(_vm.withName.name)+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }