// This file was autogenerated. Please do not change.
export interface IDexTransactionsTotal {
    aQuantityQuoted?: string;
    bQuantityQuoted?: string;
    blockchainCommissionQuantity?: string;
    exchangeCommissionQuantity?: string;
    transactionsCount?: number;
}

export default class DexTransactionsTotal {
    readonly _aQuantityQuoted: number | undefined;

    /**
     * Example: 57.86813479
     */
    get aQuantityQuoted(): number | undefined {
        return this._aQuantityQuoted;
    }

    readonly _bQuantityQuoted: string | undefined;

    /**
     * Example: 58.04473228
     */
    get bQuantityQuoted(): string | undefined {
        return this._bQuantityQuoted;
    }

    readonly _blockchainCommissionQuantity: number | undefined;

    /**
     * Example: 1.3474024
     */
    get blockchainCommissionQuantity(): number | undefined {
        return this._blockchainCommissionQuantity;
    }

    readonly _exchangeCommissionQuantity: string | undefined;

    /**
     * Example: 0.14547551
     */
    get exchangeCommissionQuantity(): string | undefined {
        return this._exchangeCommissionQuantity;
    }

    readonly _transactionsCount: number | undefined;

    /**
     * Example: 5
     */
    get transactionsCount(): number | undefined {
        return this._transactionsCount;
    }

    constructor(props: IDexTransactionsTotal) {
        if (typeof props.aQuantityQuoted === 'string') {
            this._aQuantityQuoted = Number(props.aQuantityQuoted);
        }
        if (typeof props.bQuantityQuoted === 'string') {
            this._bQuantityQuoted = props.bQuantityQuoted.trim();
        }
        if (typeof props.blockchainCommissionQuantity === 'string') {
            this._blockchainCommissionQuantity = Number(props.blockchainCommissionQuantity);
        }
        if (typeof props.exchangeCommissionQuantity === 'string') {
            this._exchangeCommissionQuantity = props.exchangeCommissionQuantity.trim();
        }
        if (typeof props.transactionsCount === 'number') {
            this._transactionsCount = props.transactionsCount;
        }
    }

    serialize(): IDexTransactionsTotal {
        const data: IDexTransactionsTotal = {
        };
        if (typeof this._aQuantityQuoted !== 'undefined') {
            data.aQuantityQuoted = String(this._aQuantityQuoted);
        }
        if (typeof this._bQuantityQuoted !== 'undefined') {
            data.bQuantityQuoted = this._bQuantityQuoted;
        }
        if (typeof this._blockchainCommissionQuantity !== 'undefined') {
            data.blockchainCommissionQuantity = String(this._blockchainCommissionQuantity);
        }
        if (typeof this._exchangeCommissionQuantity !== 'undefined') {
            data.exchangeCommissionQuantity = this._exchangeCommissionQuantity;
        }
        if (typeof this._transactionsCount !== 'undefined') {
            data.transactionsCount = this._transactionsCount;
        }
        return data;
    }

    toJSON(): IDexTransactionsTotal {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aQuantityQuoted: true,
            bQuantityQuoted: !this._bQuantityQuoted ? true : typeof this._bQuantityQuoted === 'string',
            blockchainCommissionQuantity: true,
            exchangeCommissionQuantity: !this._exchangeCommissionQuantity ? true : typeof this._exchangeCommissionQuantity === 'string',
            transactionsCount: !this._transactionsCount ? true : typeof this._transactionsCount === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
