const translatePolicy = (policy: string): string => {
    switch (policy) {
        case 'trading': {
            return 'Trading';
        }
        case 'finance': {
            return 'Finance';
        }
        case 'allocation': {
            return 'Allocation';
        }
        case 'defi': {
            return 'DeFi';
        }
        case 'display': {
            return 'Display';
        }
        case 'portfolio': {
            return 'Portfolio';
        }
        case 'statements': {
            return 'Statements';
        }
        case 'earn': {
            return 'Earn';
        }
        case 'institution': {
            return 'Institution';
        }
        default: {
            return '';
        }
    }
};

export { translatePolicy };
