import { subscribe, unsubscribeChannel } from 'Store/v2/PublicSocketData';
import { getAggregationLevelName, getAggregations } from 'Models/orderBook';
import OrderBook, { IOrderBook } from 'Entities/publicPresenter/OrderBook';
import MarketDataApi from 'Apis/MarketData';
import OrderBookRequest from 'Entities/publicPresenter/OrderBookRequest';
import OrderBookLevel, { IOrderBookLevel } from 'Entities/publicPresenter/OrderBookLevel';
import { actionCreator, mutationCreator } from 'Store/utils';

export interface IAskAndBidsRecord {
    price: number;
    volume: number;
    total: number;
}

interface LadderUi {
    bidsCount: number,
    asksCount: number,

    activeAggregationIndex: number,
    isCumulative: boolean,
}

interface IInitPayload {
    placement?: string,
    pair?: string,
}

export interface IAggregationRecord {
    aggregation: string,
    weight: number,
}

interface ITempRecord {
    asks: IOrderBookLevel[] | OrderBookLevel[],
    bids: IOrderBookLevel[] | OrderBookLevel[],
    sequence: number,
}

const state = {
    sequence: 0 as number,
    asks: [] as IAskAndBidsRecord[],
    bids: [] as IAskAndBidsRecord[],
    prices: [] as number[],
    maxVolume: 0 as number,
    aggregations: [] as IAggregationRecord[],
    subscriptionIndex: null as number | null,
    currentPlacement: '' as string,
    ui: {
        bidsCount: 10,
        asksCount: 10,

        activeAggregationIndex: 0,
        isCumulative: true,
    } as LadderUi,
    snapshotData: {
        asks: [] as IAskAndBidsRecord[],
        bids: [] as IAskAndBidsRecord[],
        tempRecords: [] as ITempRecord[],
    },
};

export type OrderBookLadderState = typeof state;

function formAggregationsArray(arr: OrderBookLevel[]) {
    const result = {};
    arr.forEach((el) => {
        result[el.price] = {
            total: String(el.price * el.volume),
            volume: String(el.volume),
        };
    });
    return result as { [key: string]: { total: string, volume: string } };
}

// функция аггрегирования для формирования группировки ценовых уровней асков и бидов
function aggregate(data: IAskAndBidsRecord[], aggregation: number, isAsk?: boolean) {
    const result = new Map();

    data.forEach((el) => {
        const { price, volume } = el;
        let newPrice;
        if (isAsk) {
            newPrice = ((price / aggregation).ceil(0) * aggregation).floor(Math.abs(Math.log10(aggregation)));
        } else {
            newPrice = ((price / aggregation).floor(0) * aggregation).floor(Math.abs(Math.log10(aggregation)));
        }

        const vol = result.get(newPrice) ?? 0;
        result.set(newPrice, volume + vol);
    });

    const records: any[] = [];
    result.forEach((value, key) => records.push({ price: key, volume: value }));
    return records;
}

function setAggregations(asks, bids, sequence, state) {
    if ((state.currentPlacement !== 'HTX' && state.currentPlacement !== 'HUOBI FUTURES') || state.aggregations.length === 0) {
        // приведение данных к нужному формату
        const aggregations = getAggregations(formAggregationsArray(asks), formAggregationsArray(bids), 4, 3);

        const aggregationLevels: any[] = [];
        let maxAggregationLevelWeight = 0;
        let maxAggregationLevelIndex = 0;

        // формирование и сортировка ценовых уровней
        Object.keys(aggregations).forEach((aggregation) => {
            aggregationLevels.push({
                aggregation: getAggregationLevelName(Number(aggregation)),
                weight: aggregations[aggregation],
            });
        });

        aggregationLevels.sort((a, b) => a.aggregation - b.aggregation);

        aggregationLevels.forEach((level, index) => {
            if (level.weight > maxAggregationLevelWeight) {
                maxAggregationLevelWeight = level.weight;
                maxAggregationLevelIndex = index;
            }
        });

        state.aggregations = aggregationLevels;
        state.ui.activeAggregationIndex = maxAggregationLevelIndex;
    }
    state.sequence = sequence;
    // запись данный в переменную для хранения первоначального снапшота данных с дальнейшим обновлением данных и удалением неактуальных ценовых уровней
    state.snapshotData.asks = asks.map((ask) => ({
        price: ask.price,
        volume: ask.volume,
        total: ask.volume * ask.price,
    })).reverse();
    state.snapshotData.bids = bids.map((bid) => ({
        price: bid.price,
        volume: bid.volume,
        total: bid.volume * bid.price,
    })).reverse();
}

// функция суммирования записей ценовых уровней асков и бидов по значению volume
function cumulateRecords(arr: IAskAndBidsRecord[], isAsks: boolean, state: OrderBookLadderState): number {
    let max = state.maxVolume;
    if (isAsks) {
        if (arr.length > 2) {
            for (let i = arr.length - 2; i >= 0; i--) {
                arr[i].volume += arr[i + 1].volume;
                arr[i].total += arr[i + 1].total;

                if (arr[i].volume > max) {
                    max = arr[i].volume;
                }
            }
        }
    } else if (arr.length > 2) {
        arr.forEach((bid, index) => {
            if (index > 0) {
                bid.volume += arr[index - 1].volume;
                bid.total += arr[index - 1].total;

                if (bid.volume > max) {
                    max = bid.volume;
                }
            }
        });
    }

    return max;
}

function setLadder(asks, bids, sequence, state) {
    state.sequence = sequence;
    // Блок поиска и обновления ценовых уровней асков и бидов в снапшоте
    const asksMap = new Map<number, number>();
    const bidsMap = new Map<number, number>();
    const newAsksSnap: any[] = [];
    state.snapshotData.asks.forEach((ask, index) => {
        asksMap.set(ask.price, index);
        newAsksSnap.push(ask);
    });
    const newBidsSnap : any[] = [];
    state.snapshotData.bids.forEach((bid, index) => {
        bidsMap.set(bid.price, index);
        newBidsSnap.push(bid);
    });
    asks!.forEach((ask) => {
        const { price, volume } = ask;
        if (volume !== 0) {
            if (asksMap.has(price)) {
                const foundedIndex = asksMap.get(price)!;
                newAsksSnap[foundedIndex].volume = volume;
                newAsksSnap[foundedIndex].total = volume * price;
            } else {
                const temp: IAskAndBidsRecord = {
                    price,
                    volume,
                    total: price * volume,
                };
                newAsksSnap.push(temp);
                asksMap.set(price, newAsksSnap.length - 1);
            }
        } else if (asksMap.has(price)) {
            const foundedIndex = asksMap.get(price)!;
            newAsksSnap.splice(foundedIndex, 1);
            asksMap.forEach((val, key) => {
                if (val > foundedIndex) {
                    asksMap.set(key, val - 1);
                }
            });
        }
    });

    bids!.forEach((bid) => {
        const { price, volume } = bid;
        if (volume !== 0) {
            if (bidsMap.has(price)) {
                const foundedIndex = bidsMap.get(price)!;
                newBidsSnap[foundedIndex].volume = volume;
                newBidsSnap[foundedIndex].total = volume * price;
            } else {
                const temp: IAskAndBidsRecord = {
                    price,
                    volume,
                    total: price * volume,
                };
                newBidsSnap.push(temp);
                bidsMap.set(price, newBidsSnap.length - 1);
            }
        } else if (bidsMap.has(price)) {
            const foundedIndex = bidsMap.get(price)!;
            newBidsSnap.splice(foundedIndex, 1);
            bidsMap.forEach((val, key) => {
                if (val > foundedIndex) {
                    bidsMap.set(key, val - 1);
                }
            });
        }
    });
    // state.snapshotData.asks = [...newAsksSnap];
    // state.snapshotData.bids = [...newBidsSnap];
    // Запись в переменные для отображения новых данных

    let newAsks = [...newAsksSnap];
    let newBids = [...newBidsSnap];

    // Произведения математических вычислений для аггрегации ценовых уровней
    const { aggregation } = state.aggregations[state.ui.activeAggregationIndex];
    newAsks = aggregate(newAsks.sort((a, b) => (a.price > b.price ? 1 : -1)), Number(aggregation), true)
        .slice(0, 10)
        .reverse();
    newBids = aggregate(newBids.sort((a, b) => (a.price > b.price ? -1 : 1)), Number(aggregation))
        .slice(0, 10);

    // обновление значение maxValue
    let maxValue = 0;
    if (state.ui.isCumulative) {
        // mutate arrays
        cumulateRecords(newAsks, true, state);
        cumulateRecords(newBids, false, state);
    }
    newAsks.forEach((ask) => {
        if (ask.volume > maxValue) {
            maxValue = ask.volume;
        }
    });
    newBids.forEach((bid) => {
        if (bid.volume > maxValue) {
            maxValue = bid.volume;
        }
    });

    // обновление массива всех цен стакана
    const prices: any[] = [];
    newAsks.forEach((el) => {
        prices.push(el.price);
    });
    state.asks.forEach((el) => {
        prices.push(el.price);
    });

    // done
    state.prices = prices;
    state.snapshotData.asks = [...newAsksSnap];
    state.snapshotData.bids = [...newBidsSnap];
    state.asks = [...newAsks];
    state.bids = [...newBids];
    state.maxVolume = maxValue;
}

export enum OrderBookLadderGetters {}

const getters: Record<OrderBookLadderGetters, (state: OrderBookLadderState, ...args: any) => void> = {};

export enum OrderBookLadderMutations {
    SET_LADDER = 'SET_LADDER',
    SET_AGGREGATIONS = 'SET_AGGREGATIONS',
    SET_ACTIVE_AGGREGATIONS_INDEX = 'SET_ACTIVE_AGGREGATIONS_INDEX',
    SET_IS_CUMULATIVE = 'SET_IS_CUMULATIVE',
    SET_SUBSCRIPTION_INDEX = 'SET_SUBSCRIPTION_INDEX',
    INIT = 'INIT',
}

export const SET_SUBSCRIPTION_INDEX = mutationCreator<number>('OrderBookLadder', OrderBookLadderMutations.SET_SUBSCRIPTION_INDEX);
export const SET_LADDER = mutationCreator<OrderBook>('OrderBookLadder', OrderBookLadderMutations.SET_LADDER);
export const SET_AGGREGATIONS = mutationCreator<OrderBook>('OrderBookLadder', OrderBookLadderMutations.SET_AGGREGATIONS);
export const SET_ACTIVE_AGGREGATIONS_INDEX = mutationCreator<number>('OrderBookLadder', OrderBookLadderMutations.SET_ACTIVE_AGGREGATIONS_INDEX);
export const SET_IS_CUMULATIVE = mutationCreator<boolean>('OrderBookLadder', OrderBookLadderMutations.SET_IS_CUMULATIVE);
export const INIT = mutationCreator<string>('OrderBookLadder', OrderBookLadderMutations.INIT);

const mutations: Record<OrderBookLadderMutations, (state: OrderBookLadderState, ...args: any) => void> = {
    SET_LADDER(state, ladder: ReturnType<typeof SET_LADDER>) {
        const { asks, bids, sequence } = ladder.payload;
        if (state.snapshotData.asks.length === 0 && state.snapshotData.bids.length === 0) {
            const temp = {
                sequence,
                asks,
                bids,
            };
            state.snapshotData.tempRecords.push(temp);
            return;
        }
        // Проверка на то, что снапшот был получен
        if (sequence && sequence >= state.sequence && state.snapshotData.asks.length > 0 && state.snapshotData.bids.length > 0) {
            setLadder(asks, bids, sequence, state);
        }
    },

    // функция формирования ценовых уровней после получения снапшота
    SET_AGGREGATIONS(state, data: ReturnType<typeof SET_AGGREGATIONS>) {
        const { asks, bids } = data.payload;
        let { sequence } = data.payload;

        setAggregations(asks, bids, sequence, state);

        state.snapshotData.tempRecords.forEach((el) => {
            if (el.sequence > sequence) {
                sequence = el.sequence;
                setLadder(el.asks, el.bids, el.sequence, state);
            }
        });
    },

    SET_ACTIVE_AGGREGATIONS_INDEX(state, index: ReturnType<typeof SET_ACTIVE_AGGREGATIONS_INDEX>) {
        state.ui.activeAggregationIndex = index.payload;
    },

    SET_IS_CUMULATIVE(state, isCumulative: ReturnType<typeof SET_IS_CUMULATIVE>) {
        state.ui.isCumulative = isCumulative.payload;
    },
    SET_SUBSCRIPTION_INDEX(state, index: ReturnType<typeof SET_SUBSCRIPTION_INDEX>) {
        state.subscriptionIndex = index.payload;
    },
    INIT(state, { payload: placement }: ReturnType<typeof INIT>) {
        state.currentPlacement = placement;
        state.snapshotData = {
            asks: [],
            bids: [],
            tempRecords: [],
        };
        state.aggregations = [];
        state.asks = [];
        state.bids = [];
    },
};

export enum OrderBookLadderActions {
    init = 'init',
}

export const init = actionCreator<IInitPayload>('OrderBookLadder', OrderBookLadderActions.init);

// velosiped, for not init twice
let initTo = '';

const actions = {
    async init(props, placementAndPairData: ReturnType<typeof init>) {
        const { commit, dispatch, state } = props;
        const { placement, pair } = placementAndPairData.payload;

        if (!placement || !pair || initTo === `orderbooks:${placement.replace(' ', '_')}-${pair}`) {
            return;
        }
        initTo = `orderbooks:${placement.replace(' ', '_')}-${pair}`;

        commit(INIT(placement, true));
        if (state.subscriptionIndex !== null) {
            dispatch(unsubscribeChannel(state.subscriptionIndex), { root: true });
        }
        const index = await dispatch(subscribe({
            channel: `orderbooks:${placement.replace(' ', '_')}-${pair}`,
            callbacks: {
                subscribe: async () => {
                    const { data: res } = await MarketDataApi.publicGetOrderBook(new OrderBookRequest({
                        placementName: placement,
                        symbol: pair,
                    }));
                    if (typeof res !== 'number') {
                        commit(SET_AGGREGATIONS(res, true));
                        commit(SET_LADDER(res, true));
                    }
                },
                publish: ({ data }: { data: IOrderBook & { type: string } }) => {
                    if (data.type === 'snapshot') {
                        commit(SET_AGGREGATIONS(new OrderBook(data), true));
                        commit(SET_LADDER(new OrderBook(data), true));
                    } else {
                        commit(SET_LADDER(new OrderBook(data), true));
                    }
                },
            },
        }), { root: true });

        if (initTo && initTo !== `orderbooks:${placement.replace(' ', '_')}-${pair}`) {
            // it means another placement/pair has been set;
            dispatch(unsubscribeChannel(index), { root: true });
            return;
        }

        commit(SET_SUBSCRIPTION_INDEX(index, true));
        initTo = '';
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
