import GeoContextObject, { IGeoContextObject } from './GeoContextObject';
import UserAgentObject, { IUserAgentObject } from './UserAgentObject';

// This file was autogenerated. Please do not change.
export interface IClientObject {
    device?: string;
    geographicalContext?: IGeoContextObject;
    ipAddress?: string;
    userAgent?: IUserAgentObject;
}

export default class ClientObject {
    readonly _device: string | undefined;

    /** Description: Device */
    get device(): string | undefined {
        return this._device;
    }

    readonly _geographicalContext: GeoContextObject | undefined;

    get geographicalContext(): GeoContextObject | undefined {
        return this._geographicalContext;
    }

    readonly _ipAddress: string | undefined;

    /** Description: Ip address */
    get ipAddress(): string | undefined {
        return this._ipAddress;
    }

    readonly _userAgent: UserAgentObject | undefined;

    get userAgent(): UserAgentObject | undefined {
        return this._userAgent;
    }

    constructor(props: IClientObject) {
        if (typeof props.device === 'string') {
            this._device = props.device.trim();
        }
        if (props.geographicalContext) {
            this._geographicalContext = new GeoContextObject(props.geographicalContext);
        }
        if (typeof props.ipAddress === 'string') {
            this._ipAddress = props.ipAddress.trim();
        }
        if (props.userAgent) {
            this._userAgent = new UserAgentObject(props.userAgent);
        }
    }

    serialize(): IClientObject {
        const data: IClientObject = {
        };
        if (typeof this._device !== 'undefined') {
            data.device = this._device;
        }
        if (typeof this._geographicalContext !== 'undefined') {
            data.geographicalContext = this._geographicalContext.serialize();
        }
        if (typeof this._ipAddress !== 'undefined') {
            data.ipAddress = this._ipAddress;
        }
        if (typeof this._userAgent !== 'undefined') {
            data.userAgent = this._userAgent.serialize();
        }
        return data;
    }

    toJSON(): IClientObject {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            device: !this._device ? true : typeof this._device === 'string',
            ipAddress: !this._ipAddress ? true : typeof this._ipAddress === 'string',
            userAgent: !this._userAgent ? true : this._userAgent.validate().length === 0,
            geographicalContext: !this._geographicalContext ? true : this._geographicalContext.validate().length === 0,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
