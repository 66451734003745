// This file was autogenerated. Please do not change.
export interface IRouteNodeExchange {
    placementName?: string;
}

export default class RouteNodeExchange {
    readonly _placementName: string | undefined;

    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: IRouteNodeExchange) {
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): IRouteNodeExchange {
        const data: IRouteNodeExchange = {
        };
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): IRouteNodeExchange {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
