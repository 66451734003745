// This file was autogenerated. Please do not change.
export interface IDexPoolsRequest {
    assetPairSymbol?: string;
    blockchainId?: number;
    blockchainName?: string;
    includeTotal?: boolean;
    orderBy?: ('reserve' | 'volume24h' | 'lpFee24h' | 'apr7d' | 'roiUSD7d' | 'roiUSD30d' | 'roiUSD365d' | 'txCount24h' | 'txCount7d');
    ordering?: ('asc' | 'desc' | 'ASC' | 'DESC');
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
}

export default class DexPoolsRequest {
    readonly _assetPairSymbol: string | undefined;

    /**
     * Example: USDT/WBNB
     */
    get assetPairSymbol(): string | undefined {
        return this._assetPairSymbol;
    }

    readonly _blockchainId: number | undefined;

    get blockchainId(): number | undefined {
        return this._blockchainId;
    }

    readonly _blockchainName: string | undefined;

    /**
     * Example: BNB Smart Chain
     */
    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _orderBy: ('reserve' | 'volume24h' | 'lpFee24h' | 'apr7d' | 'roiUSD7d' | 'roiUSD30d' | 'roiUSD365d' | 'txCount24h' | 'txCount7d') | undefined;

    get orderBy(): ('reserve' | 'volume24h' | 'lpFee24h' | 'apr7d' | 'roiUSD7d' | 'roiUSD30d' | 'roiUSD365d' | 'txCount24h' | 'txCount7d') | undefined {
        return this._orderBy;
    }

    readonly _ordering: ('asc' | 'desc' | 'ASC' | 'DESC') | undefined;

    get ordering(): ('asc' | 'desc' | 'ASC' | 'DESC') | undefined {
        return this._ordering;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    /** Description: Placement platform ID */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Description: Placement (custody, CEx, DEx) name, e.g. Single Broker, Binance, etc.
     * Example: UniswapV2
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    constructor(props: IDexPoolsRequest) {
        if (typeof props.assetPairSymbol === 'string') {
            this._assetPairSymbol = props.assetPairSymbol.trim();
        }
        if (typeof props.blockchainId === 'number') {
            this._blockchainId = props.blockchainId;
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (props.orderBy) {
            this._orderBy = props.orderBy;
        }
        if (props.ordering) {
            this._ordering = props.ordering;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
    }

    serialize(): IDexPoolsRequest {
        const data: IDexPoolsRequest = {
        };
        if (typeof this._assetPairSymbol !== 'undefined') {
            data.assetPairSymbol = this._assetPairSymbol;
        }
        if (typeof this._blockchainId !== 'undefined') {
            data.blockchainId = this._blockchainId;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._orderBy !== 'undefined') {
            data.orderBy = this._orderBy;
        }
        if (typeof this._ordering !== 'undefined') {
            data.ordering = this._ordering;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        return data;
    }

    toJSON(): IDexPoolsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetPairSymbol: !this._assetPairSymbol ? true : typeof this._assetPairSymbol === 'string',
            blockchainId: !this._blockchainId ? true : typeof this._blockchainId === 'number',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
