
import Vue from 'vue';

import Icon from 'UI/Icon.vue';
import { composedPath } from 'Lib/utils/eventPathChecker';

type Empty = Record<string, never>;

interface Props {
    placeholder?: string;
    closeOnClickOutside?: boolean;
}

interface Data {
    isOpen: boolean;
    search: string;
    elementsShown: boolean;
}

interface Methods {
    closeSearch: () => void;
    openSearch: () => void;
}

export default Vue.extend<Data, Methods, Empty, Props>({
    components: {
        Icon,
    },
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        closeOnClickOutside: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isOpen: false,
            elementsShown: false,
            search: '',
        };
    },
    methods: {
        openSearch() {
            this.isOpen = true;
            setTimeout(() => {
                this.elementsShown = true;
            }, 50);
            setTimeout(() => {
                (this.$refs.input as any).focus();
            }, 50);
            this.$emit('toggle-show-search', true);
        },
        closeSearch() {
            this.isOpen = false;
            this.search = '';
            this.$emit('toggle-show-search', false);
            setTimeout(() => {
                this.elementsShown = false;
            }, 350);
        },
    },
    mounted() {
        document.addEventListener('click', (event) => {
            if (!this.closeOnClickOutside) {
                return;
            }
            const path = composedPath(event.target);
            if (!path.some((el) => el.classList && Array.from(el.classList).includes('search-input')) && this.isOpen) {
                this.closeSearch();
            }
        });
    },
    watch: {
        search(value) {
            this.$emit('on-search', value);
        },
        isOpen(value) {
            if (!value) {
                this.search = '';
            }
        },
    },
});
