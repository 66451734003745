// This file was autogenerated. Please do not change.
export interface IQuote {
    fee: string;
    quantity: string;
}

export default class Quote {
    readonly _fee: number;

    /**
     * Example: 0.01
     */
    get fee(): number {
        return this._fee;
    }

    readonly _quantity: number;

    /**
     * Example: 12345.67
     */
    get quantity(): number {
        return this._quantity;
    }

    constructor(props: IQuote) {
        this._fee = Number(props.fee);
        this._quantity = Number(props.quantity);
    }

    serialize(): IQuote {
        const data: IQuote = {
            fee: String(this._fee),
            quantity: String(this._quantity),
        };
        return data;
    }

    toJSON(): IQuote {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            fee: true,
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
