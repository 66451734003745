// This file was autogenerated. Please do not change.
export interface IOnboardingPayload {
    completed: boolean;
    newSequenceNumber: number;
}

export default class OnboardingPayload {
    readonly _completed: boolean;

    /** Description: Onboarding is finished */
    get completed(): boolean {
        return this._completed;
    }

    readonly _newSequenceNumber: number;

    /** Description: New step number */
    get newSequenceNumber(): number {
        return this._newSequenceNumber;
    }

    constructor(props: IOnboardingPayload) {
        this._completed = props.completed;
        this._newSequenceNumber = props.newSequenceNumber;
    }

    serialize(): IOnboardingPayload {
        const data: IOnboardingPayload = {
            completed: this._completed,
            newSequenceNumber: this._newSequenceNumber,
        };
        return data;
    }

    toJSON(): IOnboardingPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            newSequenceNumber: typeof this._newSequenceNumber === 'number',
            completed: typeof this._completed === 'boolean',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
