// This file was autogenerated. Please do not change.
export interface IAddNewAddressResponse {
    address?: string;
    alias?: string;
    blockchainName?: string;
    nativeAssetSymbol?: string;
    status?: string;
}

export default class AddNewAddressResponse {
    readonly _address: string | undefined;

    get address(): string | undefined {
        return this._address;
    }

    readonly _alias: string | undefined;

    get alias(): string | undefined {
        return this._alias;
    }

    readonly _blockchainName: string | undefined;

    get blockchainName(): string | undefined {
        return this._blockchainName;
    }

    readonly _nativeAssetSymbol: string | undefined;

    get nativeAssetSymbol(): string | undefined {
        return this._nativeAssetSymbol;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    constructor(props: IAddNewAddressResponse) {
        if (typeof props.address === 'string') {
            this._address = props.address.trim();
        }
        if (typeof props.alias === 'string') {
            this._alias = props.alias.trim();
        }
        if (typeof props.blockchainName === 'string') {
            this._blockchainName = props.blockchainName.trim();
        }
        if (typeof props.nativeAssetSymbol === 'string') {
            this._nativeAssetSymbol = props.nativeAssetSymbol.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): IAddNewAddressResponse {
        const data: IAddNewAddressResponse = {
        };
        if (typeof this._address !== 'undefined') {
            data.address = this._address;
        }
        if (typeof this._alias !== 'undefined') {
            data.alias = this._alias;
        }
        if (typeof this._blockchainName !== 'undefined') {
            data.blockchainName = this._blockchainName;
        }
        if (typeof this._nativeAssetSymbol !== 'undefined') {
            data.nativeAssetSymbol = this._nativeAssetSymbol;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IAddNewAddressResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            alias: !this._alias ? true : typeof this._alias === 'string',
            address: !this._address ? true : typeof this._address === 'string',
            nativeAssetSymbol: !this._nativeAssetSymbol ? true : typeof this._nativeAssetSymbol === 'string',
            blockchainName: !this._blockchainName ? true : typeof this._blockchainName === 'string',
            status: !this._status ? true : typeof this._status === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
