import idGenerator from 'Plugins/idGenerator';

export const SnackNotificationTypes = {
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
    TECHNICAL: 'technical',
};

export const SnackNotificationIcons = {
    [SnackNotificationTypes.SUCCESS]: 'snack_success',
    [SnackNotificationTypes.WARNING]: 'snack_warn',
    [SnackNotificationTypes.ERROR]: 'snack_error',
    [SnackNotificationTypes.TECHNICAL]: 'snack_tech',
};

export const SnackNotificationDefaultTitle = {
    [SnackNotificationTypes.SUCCESS]: 'Success',
    [SnackNotificationTypes.WARNING]: 'Warning',
    [SnackNotificationTypes.ERROR]: 'Error',
    [SnackNotificationTypes.TECHNICAL]: 'Technical notification',
};

export class SnackNotification {
    id

    type

    message

    title

    duration

    constructor({
        type, message, title, duration,
    }) {
        this.id = idGenerator.next().value;

        this.type = type;
        this.message = message;
        this.title = title;
        this.duration = duration !== undefined ? duration : 3000;
    }
}
