import { LS_PAGES_GRID_FIELD, LS_ACTIVE_COLOR_THEME } from '../const/localStorage';

export const gridPages = {
    TERMINAL: 'terminal',
    PORTFOLIOS: 'portfolios',
    WALLETS: 'wallets',
    PROFILE: 'profile',
    DEFI: 'defi',
    STATEMENTS: 'statements',
    WORKSPACE: 'workspace',
    EARN: 'earn',
    OTC: 'otc',
};

export const colorThemes = {
    LIGHT: 'light',
    DARK: 'dark',
};

export function getLocalStorageLayouts() {
    const pagesGrid = localStorage.getItem(LS_PAGES_GRID_FIELD);

    return pagesGrid ? JSON.parse(pagesGrid) : null;
}

export function setLocalStorageLayouts(layouts) {
    localStorage.setItem(LS_PAGES_GRID_FIELD, JSON.stringify(layouts));
}

export function setLocalStorageActiveColorTheme(theme) {
    localStorage.setItem(LS_ACTIVE_COLOR_THEME, theme);
}

export function getLocalStorageActiveColorTheme() {
    return localStorage.getItem(LS_ACTIVE_COLOR_THEME);
}
