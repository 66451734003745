// This file was autogenerated. Please do not change.
export interface ITradeApiKeyResponse {
    data?: Record<string, string>;
}

export default class TradeApiKeyResponse {
    readonly _data: Record<string, string> | undefined;

    get data(): Record<string, string> | undefined {
        return this._data;
    }

    constructor(props: ITradeApiKeyResponse) {
        if (props.data) {
            this._data = props.data;
        }
    }

    serialize(): ITradeApiKeyResponse {
        const data: ITradeApiKeyResponse = {
        };
        if (typeof this._data !== 'undefined') {
            data.data = this._data;
        }
        return data;
    }

    toJSON(): ITradeApiKeyResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
