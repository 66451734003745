import { Action } from 'vuex';

import { actionCreator, mutationCreator } from 'Store/utils';
import RebatesApi from 'Apis/Rebates';
import TotalPaidRebatesRequest from 'Entities/privatePresenter/TotalPaidRebatesRequest';
import ApiError from 'Entities/ApiError';
import ExpectedRebatesRequest from 'Entities/privatePresenter/ExpectedRebatesRequest';
import PaidFeeQuotation from 'Entities/privatePresenter/PaidFeeQuotation';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import PaidRebatesRequest from 'Entities/privatePresenter/PaidRebatesRequest';
import PaidRebate from 'Entities/privatePresenter/PaidRebate';

const state = {
    totalPaidRebates: 0 as number,
    totalExpectedRebates: 0 as number,
    expectedRebates: new Map() as Map<string, number>,
    paidRebates: [] as PaidRebate[],
};

export type RebatesState = typeof state;

export enum RebatesMutations {
    SET_TOTAL_PAID_REBATES = 'SET_TOTAL_PAID_REBATES',
    SET_TOTAL_EXPECTED_REBATES = 'SET_TOTAL_EXPECTED_REBATES',
    SET_EXPECTED_REBATES = 'SET_EXPECTED_REBATES',
    SET_PAID_REBATES = 'SET_PAID_REBATES',
}

export const SET_TOTAL_PAID_REBATES = mutationCreator<number>('Rebates', RebatesMutations.SET_TOTAL_PAID_REBATES);
export const SET_TOTAL_EXPECTED_REBATES = mutationCreator<number>('Rebates', RebatesMutations.SET_TOTAL_EXPECTED_REBATES);
export const SET_EXPECTED_REBATES = mutationCreator<Map<string, number>>('Rebates', RebatesMutations.SET_EXPECTED_REBATES);
export const SET_PAID_REBATES = mutationCreator<PaidRebate[]>('Rebates', RebatesMutations.SET_PAID_REBATES);

const mutations: Record<RebatesMutations, (state: RebatesState, ...args: any) => void> = {
    SET_TOTAL_PAID_REBATES(state, { payload: total }: ReturnType<typeof SET_TOTAL_PAID_REBATES>) {
        state.totalPaidRebates = total;
    },
    SET_TOTAL_EXPECTED_REBATES(state, { payload: total }: ReturnType<typeof SET_TOTAL_EXPECTED_REBATES>) {
        state.totalExpectedRebates = total;
    },
    SET_EXPECTED_REBATES(state, { payload: rebates }: ReturnType<typeof SET_EXPECTED_REBATES>) {
        state.expectedRebates = rebates;
    },
    SET_PAID_REBATES(state, { payload: rebates }: ReturnType<typeof SET_PAID_REBATES>) {
        state.paidRebates = rebates;
    },
};

export enum RebatesActions {
    getTotalPaidRebates = 'getTotalPaidRebates',
    getTotalExpectedRebates = 'getTotalExpectedRebates',
    getExpectedRebates = 'getExpectedRebates',
    getPaidRebates = 'getPaidRebates',
}

export const getTotalPaidRebates = actionCreator<undefined>('Rebates', RebatesActions.getTotalPaidRebates);
export const getTotalExpectedRebates = actionCreator<undefined>('Rebates', RebatesActions.getTotalExpectedRebates);
export const getExpectedRebates = actionCreator<undefined>('Rebates', RebatesActions.getExpectedRebates);
export const getPaidRebates = actionCreator<undefined>('Rebates', RebatesActions.getPaidRebates);

const actions: Record<RebatesActions, (Action<RebatesState, any>)> = {
    async getTotalPaidRebates({ dispatch, commit, rootState, rootGetters }) {
        let total = 0;

        const accountIds = rootState.Accounts.accounts.reduce((accum: string[], curr) => {
            if (curr.isOwned) {
                return [...accum, curr.id];
            }
            return accum;
        }, []);

        for (let i = 0; i < accountIds.length; i += 1) {
            try {
                // eslint-disable-next-line no-await-in-loop
                const { data: rebates } = await RebatesApi.privateGetTotalPaidRebates(new TotalPaidRebatesRequest({
                    accountId: accountIds[i],
                    quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                }));
                for (let j = 0; j < rebates.length; j += 1) {
                    if (rebates[j] && rebates[j].quantity) {
                        total += rebates[j].quantity!;
                    }
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    // eslint-disable-next-line no-await-in-loop
                    await dispatch('Notificator/showErrorNotification', error.data?.message ?? 'Error getting total paid rebates');
                }
            }
        }
        commit(SET_TOTAL_PAID_REBATES(total, true));
    },
    async getTotalExpectedRebates({ dispatch, commit, rootState, rootGetters }) {
        let total = 0;

        const accountIds = rootState.Accounts.accounts.reduce((accum: string[], curr) => {
            if (curr.isOwned) {
                return [...accum, curr.id];
            }
            return accum;
        }, []);

        for (let i = 0; i < accountIds.length; i += 1) {
            try {
                // eslint-disable-next-line no-await-in-loop
                const { data: rebates } = await RebatesApi.privateGetTotalExpectedRebates(new ExpectedRebatesRequest({
                    accountId: accountIds[i],
                    quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                }));
                for (let j = 0; j < rebates.length; j += 1) {
                    if (rebates[j] && rebates[j].quantity) {
                        total += rebates[j].quantity!;
                    }
                }
            } catch (error) {
                if (error instanceof ApiError) {
                    // eslint-disable-next-line no-await-in-loop
                    await dispatch('Notificator/showErrorNotification', error.data?.message ?? 'Error getting total paid rebates');
                }
            }
        }
        commit(SET_TOTAL_EXPECTED_REBATES(total, true));
    },
    async getExpectedRebates({ commit, dispatch, rootGetters }) {
        try {
            let allRebates: PaidFeeQuotation[] = [];
            const { data: rebates, headers } = await RebatesApi.privateGetExpectedRebates(new ExpectedRebatesRequest({
                includeTotal: true,
                page: 1,
                perPage: 100,
                quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
            }), true);
            allRebates = rebates;

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage && totalPage > 1) {
                    for (let i = 2; i <= totalPage; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        const { data: extraRebates } = await RebatesApi.privateGetExpectedRebates(new ExpectedRebatesRequest({
                            page: i,
                            perPage: 100,
                            quotationAsset: rootGetters['Assets/GET_QUOTATION_ASSET_SYMBOL'],
                        }));
                        allRebates = [...allRebates, ...extraRebates];
                    }
                }
            }

            const rebatesMap = new Map<string, number>();
            allRebates.forEach((r) => {
                if (rebatesMap.has(r.placementName!)) {
                    rebatesMap.set(r.placementName!, rebatesMap.get(r.placementName!)! + r.quantity!);
                } else {
                    rebatesMap.set(r.placementName!, r.quantity!);
                }
            });
            commit(SET_EXPECTED_REBATES(rebatesMap, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data?.message ?? 'Error getting expected rebates');
            }
            commit(SET_EXPECTED_REBATES(new Map(), true));
        }
    },
    async getPaidRebates({ commit, dispatch }) {
        try {
            let allRebates: PaidRebate[] = [];
            const { data: rebates, headers } = await RebatesApi.privateGetPaidRebates(new PaidRebatesRequest({
                includeTotal: true,
                page: 1,
                perPage: 100,
            }), true);
            allRebates = rebates;

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage && totalPage > 1) {
                    for (let i = 2; i <= totalPage; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        const { data: extraRebates } = await RebatesApi.privateGetPaidRebates(new PaidRebatesRequest({
                            page: i,
                            perPage: 100,
                        }));
                        allRebates = [...allRebates, ...extraRebates];
                    }
                }
            }

            commit(SET_PAID_REBATES(allRebates, true));
        } catch (error) {
            if (error instanceof ApiError) {
                await dispatch('Notificator/showErrorNotification', error.data?.message ?? 'Error getting paid rebates');
            }
            commit(SET_PAID_REBATES([], true));
        }
    },
};

export default {
    state,
    mutations,
    actions,
    namespaced: true,
};
