// This file was autogenerated. Please do not change.
export interface IGroupMemberPayload {
    groupId: string;
    userId?: string;
}

export default class GroupMemberPayload {
    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    readonly _userId: string | undefined;

    /** Description: User's unique identifier */
    get userId(): string | undefined {
        return this._userId;
    }

    constructor(props: IGroupMemberPayload) {
        this._groupId = props.groupId.trim();
        if (typeof props.userId === 'string') {
            this._userId = props.userId.trim();
        }
    }

    serialize(): IGroupMemberPayload {
        const data: IGroupMemberPayload = {
            groupId: this._groupId,
        };
        if (typeof this._userId !== 'undefined') {
            data.userId = this._userId;
        }
        return data;
    }

    toJSON(): IGroupMemberPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
            userId: !this._userId ? true : typeof this._userId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
