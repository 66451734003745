// This file was autogenerated. Please do not change.
export interface IBasicAccountInfoSwaggerSchema {
    color?: string;
    description?: string;
    id: string;
    name?: string;
    policies?: string[];
    status: string;
}

export default class BasicAccountInfoSwaggerSchema {
    readonly _color: string | undefined;

    get color(): string | undefined {
        return this._color;
    }

    readonly _description: string | undefined;

    get description(): string | undefined {
        return this._description;
    }

    readonly _id: string;

    get id(): string {
        return this._id;
    }

    readonly _name: string | undefined;

    get name(): string | undefined {
        return this._name;
    }

    readonly _policies: string[] | undefined;

    get policies(): string[] | undefined {
        return this._policies;
    }

    readonly _status: string;

    get status(): string {
        return this._status;
    }

    constructor(props: IBasicAccountInfoSwaggerSchema) {
        if (typeof props.color === 'string') {
            this._color = props.color.trim();
        }
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._id = props.id.trim();
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
        this._status = props.status.trim();
    }

    serialize(): IBasicAccountInfoSwaggerSchema {
        const data: IBasicAccountInfoSwaggerSchema = {
            id: this._id,
            status: this._status,
        };
        if (typeof this._color !== 'undefined') {
            data.color = this._color;
        }
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        return data;
    }

    toJSON(): IBasicAccountInfoSwaggerSchema {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: typeof this._id === 'string' && !!this._id.trim(),
            name: !this._name ? true : typeof this._name === 'string',
            description: !this._description ? true : typeof this._description === 'string',
            status: typeof this._status === 'string' && !!this._status.trim(),
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            color: !this._color ? true : typeof this._color === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
