// This file was autogenerated. Please do not change.
/* A payload to update an existing API-key */
export interface IUpdateKeyPayload {
    apiKey: string;
    name?: string;
    scopes?: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];
    totp: string;
}

export default class UpdateKeyPayload {
    readonly _apiKey: string;

    /** Description: An API-key */
    get apiKey(): string {
        return this._apiKey;
    }

    readonly _name: string | undefined;

    /** Description: API-key name */
    get name(): string | undefined {
        return this._name;
    }

    readonly _scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[] | undefined;

    /** Description: Requested API-key scopes */
    get scopes(): ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[] | undefined {
        return this._scopes;
    }

    readonly _totp: string;

    /** Description: One-time verification code */
    get totp(): string {
        return this._totp;
    }

    constructor(props: IUpdateKeyPayload) {
        this._apiKey = props.apiKey.trim();
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
        if (props.scopes) {
            this._scopes = props.scopes;
        }
        this._totp = props.totp.trim();
    }

    serialize(): IUpdateKeyPayload {
        const data: IUpdateKeyPayload = {
            apiKey: this._apiKey,
            totp: this._totp,
        };
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        if (typeof this._scopes !== 'undefined') {
            data.scopes = this._scopes;
        }
        return data;
    }

    toJSON(): IUpdateKeyPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            totp: typeof this._totp === 'string' && !!this._totp.trim(),
            name: !this._name ? true : typeof this._name === 'string',
            apiKey: typeof this._apiKey === 'string' && !!this._apiKey.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
