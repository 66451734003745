<template>
    <div id="app" :class="s.relative">
        <Icons />
        <MaintenanceBanner
            v-if="currentUser === undefined && currentUserInited && (currentUserStatus === 404 || currentUserStatus >= 500 || currentUserStatus === undefined)"
        />
        <MainPreloader v-if="$store.state.Preloader.isLoading" />
        <MainShadowBlock v-if="$store.state.Preloader.isShadowShown" />
        <transition name="bounce" mode="out-in">
            <DemoAccountAlert
                v-if="$store.getters['Accounts/isActiveAccountDemo'] && showDemoAccountAlert && currentRoute !== '/KYC'"
                @close="showDemoAccountAlert = false"
            />
        </transition>
        <Header
            v-if="$route.meta.showHeader"
            :has-mobile-layout-tabs="hasMobileLayoutTabs"
            @change-mobile-layout-tabs="hasMobileLayoutTabs = $event"
        />
        <SnackbarContainer />
        <main
            id="mainBlock"
            :class="{ [s.hasLeftMenu]: isLeftMenuOpen, [s.hasHeader]: $route.meta.showHeader }"
        >
            <transition name="accounts-menu--slide">
                <LeftMenu v-if="isLeftMenuOpen"/>
            </transition>
            <div></div>
            <router-view/>
        </main>
        <ConfirmLinkModal />
    </div>
</template>

<script>
/* eslint-disable import/order */
import MainPreloader from 'Common/MainPreloader.vue';

import Header from './components/Header/Header.vue';
import LeftMenu from './components/LeftMenu/LeftMenu.vue';
import { getActivePresets, getPresetsList, makeDefaultPresets } from 'Store/v2/Presets';
import Icons from 'UI/Icons.vue';
import ApiError from 'Entities/ApiError';
import { getGroupsRequests, getNotificationsList, getRequests } from 'Store/v2/Notifications';
import { mapGetters } from 'vuex';
import SnackbarContainer from 'Components/Snackbars/SnackbarContainer.vue';
import { SET_LOADING_OFF, SET_LOADING_ON } from 'Store/v2/Preloader';
import DemoAccountAlert from 'UI/DemoAccountAlert.vue';
import MainShadowBlock from 'UI/MainShadowBlock.vue';
import { startIntroByStep } from 'Store/v2/Intro';
import ConfirmLinkModal from 'Common/ConfirmLinkModal.vue';
import MaintenanceBanner from 'UI/MaintenanceBanner.vue';

export default {
    name: 'App',
    components: {
        MaintenanceBanner,
        ConfirmLinkModal,
        MainShadowBlock,
        DemoAccountAlert,
        SnackbarContainer,
        Header,
        LeftMenu,
        MainPreloader,
        Icons,
    },
    provide() {
        const App = this.provideData;

        return { App };
    },
    data() {
        return {
            hasMobileLayoutTabs: false,
            isApiDataLoaded: false,
            provideData: {
                showLeftMenu: this.showLeftMenu,
                hideLeftMenu: this.hideLeftMenu,
                setLeftMenuDefault: this.setLeftMenuDefault,
            },
            customToggleLeftMenu: null,
            isOnboardingStarted: false,
        };
    },
    computed: {
        ...mapGetters({
            isLogged: 'Auth/isLogged',
            isEmailVerified: 'Auth/isEmailVerified',
            accounts: 'Accounts/accounts',
        }),
        isLeftMenuOpen() {
            return this.customToggleLeftMenu !== null ? this.customToggleLeftMenu : this.$route.meta.showLeftMenu;
        },
        currentUser() {
            return this.$store.state.User.currentUser;
        },
        currentUserStatus() {
            return this.$store.state.User.currentUserStatus;
        },
        currentUserInited() {
            return this.$store.state.User.inited;
        },
        isModalShown() {
            return this.$store.state.Modal.isModalShown;
        },
        isKycVerified() {
            if (!this.currentUser) {
                return false;
            }
            return this.currentUser.kycStatus === 'Verified';
        },
        isLoading() {
            return this.$store.state.Preloader.isLoading;
        },
        currentRoute() {
            return this.$route.path;
        },
        showDemoAccountAlert: {
            get() {
                return this.$store.state.Accounts.showDemoAccountAlert;
            },
            set(value) {
                this.$store.state.Accounts.showDemoAccountAlert = value;
            },
        },
        showMaintenanceBanner() {
            return this.currentUser === undefined
                && this.currentUserInited
                && (
                    this.currentUserStatus === 404
                    || this.currentUserStatus >= 500
                    || this.currentUserStatus === undefined
                );
        },
    },
    methods: {
        runUpdates() {
            document.title = this.$route.name ? `${this.$route.name} | Single Broker` : document.title;
        },
        async uploadApiData() {
            try {
                await this.$store.dispatch('downloadMainApiData');
            } catch {
                setTimeout(() => {
                    this.uploadApiData();
                }, 1000);
            }
        },
        showLeftMenu() {
            this.customToggleLeftMenu = true;
        },
        hideLeftMenu() {
            this.customToggleLeftMenu = false;
        },
        setLeftMenuDefault() {
            this.customToggleLeftMenu = null;
        },
        startIntro() {
            if (!this.currentUser.onboardingCompleted && !this.isOnboardingStarted) {
                this.isOnboardingStarted = true;
                setTimeout(async () => {
                    await this.$store.dispatch(startIntroByStep(this.currentUser.onboardingStatus + 1));
                }, 1000);
            }
        },
    },
    async created() {
        if (this.isEmailVerified) {
            await this.$store.dispatch('User/getCurrentUser');
            await this.$store.dispatch('initInterface');
        }
        try {
            if (this.isEmailVerified) {
                await this.$store.dispatch(makeDefaultPresets(undefined));
                await this.$store.dispatch(getPresetsList(undefined));
                await this.$store.dispatch(getActivePresets(undefined));
            }
        } catch (error) {
            if (error instanceof ApiError) {
                await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
            } else {
                await this.$store.dispatch('Notificator/showErrorNotification', 'Error while loading presets', { root: true });
            }
        }

        if (this.isLogged && this.isEmailVerified) {
            if (!this.isApiDataLoaded) {
                try {
                    this.runUpdates();
                } catch {
                    // code crushed because of $router variable
                }
                this.isApiDataLoaded = true;
                await this.uploadApiData();
            }
        }

        this.$router.beforeEach((to, from, next) => {
            this.setLeftMenuDefault();
            next();
        });
    },
    async mounted() {
        if (this.isLogged && this.isEmailVerified && this.accounts.length === 0 && !this.isKycVerified) {
            this.$store.commit(SET_LOADING_ON(undefined));
        }
        if (this.isLogged && this.currentUser) {
            this.startIntro();
        }
    },
    watch: {
        async isLogged(value) {
            if (value && this.isEmailVerified && this.currentUser && !this.isApiDataLoaded) {
                try {
                    this.runUpdates();
                } catch (error) {
                    // code crushed because of $router variable
                }
                this.isApiDataLoaded = true;
                await this.uploadApiData();
            }
            if (value && this.isEmailVerified && this.accounts.length === 0 && !this.isKycVerified) {
                this.$store.commit(SET_LOADING_ON(undefined));
            }
            if (value && this.currentUser) {
                this.startIntro();
            }
        },
        async currentUser(value, prevValue) {
            if (value) {
                await this.$store.dispatch(getRequests(undefined), { root: true });
                await this.$store.dispatch(getGroupsRequests(undefined), { root: true });
                await this.$store.dispatch(getNotificationsList(undefined), { root: true });
            }
            if (value && this.isEmailVerified && this.isLogged && !this.isApiDataLoaded) {
                try {
                    this.runUpdates();
                } catch (error) {
                    // code crushed because of $router variable
                }
                this.isApiDataLoaded = true;
                await this.uploadApiData();
            }
            if (value && !prevValue && this.isLogged) {
                this.startIntro();
            }
        },
        async isEmailVerified(value) {
            if (value && this.isLogged && this.currentUser && !this.isApiDataLoaded) {
                try {
                    this.runUpdates();
                } catch (error) {
                    // code crushed because of $router variable
                }
                this.isApiDataLoaded = true;
                await this.uploadApiData();
            }
            if (value) {
                await this.$store.dispatch('User/getCurrentUser');
                await this.$store.dispatch('initInterface');
                try {
                    await this.$store.dispatch(makeDefaultPresets(undefined));
                    await this.$store.dispatch(getPresetsList(undefined));
                    await this.$store.dispatch(getActivePresets(undefined));
                } catch (error) {
                    if (error instanceof ApiError) {
                        await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong', { root: true });
                    } else {
                        await this.$store.dispatch('Notificator/showErrorNotification', 'Error while loading presets', { root: true });
                    }
                }
            }
        },
        accounts(value) {
            if (value.length > 0 && !this.isKycVerified) {
                this.$store.commit(SET_LOADING_OFF(undefined));
            }
        },
        isLoading(value) {
            if (!value) {
                if (this.isLogged && this.isEmailVerified && this.accounts.length === 0 && !this.isKycVerified) {
                    this.$store.commit(SET_LOADING_ON(undefined));
                }
            }
        },
        currentRoute() {
            this.showDemoAccountAlert = true;
        },
    },
    beforeUpdate() {
        this.runUpdates();
    },
};
</script>

<style lang="postcss" module="s">
.blur {
    opacity: .5;
}
.relative {
    height: 100%;
    position: relative;
}
.hasLeftMenu {
    display: grid;
    grid-template-columns: var(--left-menu-width) calc(100% - var(--left-menu-width));
}
.hasHeader {
    padding-top: var(--sb-header-height);
}
</style>

<style>
@keyframes bounceIn {
    0% {
        transform: translateX(100%);
    }
    60% {
        transform: translateX(-10%);
    }
    80% {
        transform: translateX(5%);
    }
    100% {
        transform: translateX(0);
    }
}

.bounce-enter-active {
    animation: bounceIn var(--transition);
}
.bounce-leave-active {
    transition: transform var(--transition);
}
.bounce-enter, .bounce-leave-to {
    transform: translateX(100%);
}
</style>
