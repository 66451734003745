
import Vue from 'vue';
import { mapGetters } from 'vuex';

import Icon from 'UI/Icon.vue';
import CustomTooltip from 'UI/CustomTooltip.vue';
import Info from 'UI/Info.vue';

/*
* emits
* input-text: (data: string) => void;
* */

interface Props {
    title?: string;
    isPassword?: boolean;
    readonly?: boolean;
    copy?: boolean;
    placeholder?: string;
    isTextArea?: boolean;
    value: string;
    hasError?: boolean;
    disabled?: boolean;
    errorText?: string;
    errorTextBottom?: boolean;
    hasInfoTooltip?: boolean;
    infoTooltipText?: string;
}

interface Data {
    showPassword: boolean;
    showErrorText: boolean;
}

interface Methods {
    onInput: (data: any) => void;
    closeTooltip: () => void;
}

interface Computed {
    isThemeDark: boolean;
}

export default Vue.extend<Data, Methods, Computed, Props>({
    components: {
        Info,
        CustomTooltip,
        Icon,
    },
    props: {
        title: {
            type: String,
            default: '',
        },
        isPassword: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        copy: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        isTextArea: {
            type: Boolean,
            default: false,
        },
        value: {
            type: String,
        },
        hasError: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        errorText: {
            type: String,
            default: '',
        },
        errorTextBottom: {
            type: Boolean,
            default: false,
        },
        hasInfoTooltip: {
            type: Boolean,
            default: false,
        },
        infoTooltipText: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            showPassword: false,
            showErrorText: false,
        };
    },
    computed: {
        ...mapGetters({
            isThemeDark: 'isThemeDark',
        }),
    },
    methods: {
        onInput(e) {
            this.$emit('input-text', e.target.value);
        },
        closeTooltip() {
            this.showErrorText = false;
        },
    },
    watch: {
        hasError(val) {
            this.showErrorText = val;
        },
    },
});
