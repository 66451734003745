import { OrdersTypes, OrdersSides, FuturesOrderSides } from 'Models/trading';
import TradingApi from 'Apis/Trading';
import TradingCommissionRateRequest from 'Entities/privatePresenter/TradingCommissionRateRequest';
import AccountTradingCommission from 'Entities/privatePresenter/AccountTradingCommission';

import Limit from './TradingTypes/Limit';

const state = {
    ordersTypes: Object.values(OrdersTypes),
    activeOrderTypeIndex: 0,

    sides: Object.values(OrdersSides),
    futuresSides: Object.values(FuturesOrderSides),
    activeSideIndex: 0,
    personalFeesRate: null as null | AccountTradingCommission,
};

const getters = {
    ordersTypes: (state, getters, rootState, rootGetters) => state.ordersTypes.map((type) => ({ disabled: !type.typeNames.some((typeName) => rootGetters['Placements/activeTerminalPlacementOrderTypes'].some((placementOrderType) => placementOrderType.toUpperCase() === typeName.toUpperCase())), ...type })),
    activeOrderTypeIndex: (state) => state.activeOrderTypeIndex,
    activeOrderType: (state, getters) => getters.ordersTypes[getters.activeOrderTypeIndex],

    sides: (state) => state.sides,
    futuresSides: (state) => state.futuresSides,
    activeSideIndex: (state) => state.activeSideIndex,
    activeSide: (state, getters) => getters.sides[getters.activeSideIndex],
    activeFuturesSide: (state, getters) => getters.futuresSides[getters.activeSideIndex],

    orderRestrictions: (state, getters, rootState, rootGetters) => rootGetters['AssetPairs/GET_ACTIVE_TERMINAL_ASSET_PAIR_ATTRIBUTES'],
};

const mutations = {
    SET_ACTIVE_SIDE_INDEX(state, index) {
        state.activeSideIndex = index;
    },
    SET_ACTIVE_ORDER_TYPE_INDEX(state, index) {
        state.activeOrderTypeIndex = index;
    },
    SET_FEES_RATE(state, feesRate) {
        state.personalFeesRate = feesRate;
    },
};

const actions = {
    setActiveSideIndex({ commit, dispatch }, index) {
        commit('SET_ACTIVE_SIDE_INDEX', index);

        dispatch('Limit/setQuantity', 0);
    },
    setActiveOrderTypeIndex({ commit }, index) {
        commit('SET_ACTIVE_ORDER_TYPE_INDEX', index);
    },
    async getFeesRate({ commit, dispatch, rootGetters }, isFirstDownload) {
        if (isFirstDownload) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: () => dispatch('getFeesRate', false),
            }, { root: true });
            await dispatch('VuexEventListener/addActionListener', {
                type: 'AssetPairs/setActiveTerminalAssetPairId',
                callback: () => dispatch('getFeesRate', false),
            }, { root: true });
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Placements/setActiveTerminalPlacementId',
                callback: () => dispatch('getFeesRate', false),
            }, { root: true });
        }
        const activeAccountId = rootGetters['Accounts/activeAccountID'];
        const activePlacementId = rootGetters['Placements/activeTerminalPlacementId'];
        const activeAssetPairSymbol = rootGetters['AssetPairs/activeTerminalAssetPairSymbol'];
        if (!activeAccountId || !activePlacementId || !activeAssetPairSymbol) {
            return;
        }
        try {
            const { data: feesRate } = await TradingApi.privateGetSpotTradingCommissionRate(new TradingCommissionRateRequest({
                accountId: activeAccountId,
                placementId: activePlacementId,
                spotAssetPairSymbol: activeAssetPairSymbol,
            }));
            commit('SET_FEES_RATE', feesRate);
        } catch (error) {
            commit('SET_FEES_RATE', null);
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
    modules: {
        Limit,
    },
};
