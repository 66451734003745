import { Action } from 'vuex';

import PublicDataApi from 'Apis/PublicData';
import { mutationCreator, actionCreator } from 'Store/utils';
import Asset from 'Entities/publicPresenter/Asset';
import AssetsRequest from 'Lib/entities/publicPresenter/AssetsRequest';
import SpotAssetPairsRequest from 'Lib/entities/publicPresenter/SpotAssetPairsRequest';
import SpotAssetPair from 'Lib/entities/publicPresenter/SpotAssetPair';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import { OTC_PLACEMENT } from 'Const/otc';

const state = {
    assets: [] as Asset[],
    activeAsset: undefined as Asset | undefined,
    assetPairs: [] as SpotAssetPair[],
};

export type OTCState = typeof state;

export const allowedOTCPlacements = [
    {
        name: 'B2C2',
        backgroundImage: require('@/assets/images/icons/b2c2.svg'),
        allowed: true,
    },
    {
        name: 'Coming Soon',
        backgroundImage: require('@/assets/images/icons/earnPlacements/Gateio.svg'),
        allowed: false,
    },
    {
        name: 'Coming Soon',
        backgroundImage: require('@/assets/images/icons/earnPlacements/OKEx.svg'),
        allowed: false,
    },
];

export enum OTCMutations {
    SET_ASSETS = 'SET_ASSETS',
    SET_ACTIVE_ASSET = 'SET_ACTIVE_ASSET',
    SET_ASSET_PAIRS = 'SET_ASSET_PAIRS',
}

export const SET_ASSETS = mutationCreator<Asset[]>('OTC', OTCMutations.SET_ASSETS);
export const SET_ACTIVE_ASSET = mutationCreator<string | undefined>('OTC', OTCMutations.SET_ACTIVE_ASSET);
export const SET_ASSET_PAIRS = mutationCreator<SpotAssetPair[]>('OTC', OTCMutations.SET_ASSET_PAIRS);

const mutations: Record<OTCMutations, (state: OTCState, ...args: any) => void> = {
    SET_ASSETS(state, { payload }: ReturnType<typeof SET_ASSETS>) {
        state.assets = payload;
    },
    SET_ACTIVE_ASSET(state, { payload }: ReturnType<typeof SET_ACTIVE_ASSET>) {
        if (payload) {
            const asset = state.assets.find((a) => a.symbol === payload);
            state.activeAsset = asset;
            return;
        }
        state.activeAsset = undefined;
    },
    SET_ASSET_PAIRS(state, { payload }: ReturnType<typeof SET_ASSET_PAIRS>) {
        state.assetPairs = payload;
    },
};

export enum OTCActions {
    init = 'init',
    getAssets = 'getAssets',
    getAssetPairs = 'getAssetPairs',
}

export const init = actionCreator<undefined>('OTC', OTCActions.init);
export const getAssets = actionCreator<undefined>('OTC', OTCActions.getAssets);
export const getAssetPairs = actionCreator<undefined>('OTC', OTCActions.getAssetPairs);

const actions: Record<OTCActions, Action<OTCState, any>> = {
    async init({ dispatch }) {
        dispatch(getAssets(undefined, true));
        dispatch(getAssetPairs(undefined, true));
    },
    async getAssets({ commit }) {
        const allAssets: Asset[] = [];
        const { data, headers } = await PublicDataApi.publicGetAssets(new AssetsRequest({
            placementName: OTC_PLACEMENT,
            perPage: 30,
            includeTotal: true,
        }), true);
        allAssets.push(...data);
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraAssets } = await PublicDataApi.publicGetAssets(new AssetsRequest({
                        page: i,
                        perPage: 30,
                        placementName: OTC_PLACEMENT,
                    }));
                    allAssets.push(...extraAssets);
                }
            }
        }
        commit(SET_ASSETS(allAssets, true));
        commit(SET_ACTIVE_ASSET(data[0].symbol, true));
    },
    async getAssetPairs({ commit }) {
        const allSpotAssetPairs: SpotAssetPair[] = [];
        const { data, headers } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
            page: 1,
            includeTotal: true,
            placementName: OTC_PLACEMENT,
            perPage: 300,
        }), true);
        allSpotAssetPairs.push(...data);
        if (headers) {
            const totalPages = parsePaginationHeaders(headers).totalPage;
            if (totalPages && totalPages > 1) {
                for (let i = 2; i <= totalPages; i += 1) {
                    // eslint-disable-next-line no-await-in-loop
                    const { data: extraAssetPairs } = await PublicDataApi.publicGetSpotAssetPairs(new SpotAssetPairsRequest({
                        page: i,
                        perPage: 300,
                        placementName: OTC_PLACEMENT,
                    }));
                    allSpotAssetPairs.push(...extraAssetPairs);
                }
            }
        }
        commit(SET_ASSET_PAIRS(allSpotAssetPairs, true));
    },
};

export default {
    namespaced: true,
    state,
    getters: {},
    mutations,
    actions,
};
