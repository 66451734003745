// This file was autogenerated. Please do not change.
export interface IUpdateGroupPayload {
    description?: string;
    groupId: string;
    name?: string;
}

export default class UpdateGroupPayload {
    readonly _description: string | undefined;

    /** Description: Group description */
    get description(): string | undefined {
        return this._description;
    }

    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    readonly _name: string | undefined;

    /** Description: Group name */
    get name(): string | undefined {
        return this._name;
    }

    constructor(props: IUpdateGroupPayload) {
        if (typeof props.description === 'string') {
            this._description = props.description.trim();
        }
        this._groupId = props.groupId.trim();
        if (typeof props.name === 'string') {
            this._name = props.name.trim();
        }
    }

    serialize(): IUpdateGroupPayload {
        const data: IUpdateGroupPayload = {
            groupId: this._groupId,
        };
        if (typeof this._description !== 'undefined') {
            data.description = this._description;
        }
        if (typeof this._name !== 'undefined') {
            data.name = this._name;
        }
        return data;
    }

    toJSON(): IUpdateGroupPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            name: !this._name ? true : typeof this._name === 'string',
            description: !this._description ? true : typeof this._description === 'string',
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
