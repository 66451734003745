// This file was autogenerated. Please do not change.
export interface ITransferType {
    quantity?: string;
    type?: ('deposit' | 'transfer' | 'withdrawal');
}

export default class TransferType {
    readonly _quantity: number | undefined;

    /**
     * Example: 5.2821066
     */
    get quantity(): number | undefined {
        return this._quantity;
    }

    readonly _type: ('deposit' | 'transfer' | 'withdrawal') | undefined;

    /**
     * Example: transfer
     */
    get type(): ('deposit' | 'transfer' | 'withdrawal') | undefined {
        return this._type;
    }

    constructor(props: ITransferType) {
        if (typeof props.quantity === 'string') {
            this._quantity = Number(props.quantity);
        }
        if (props.type) {
            this._type = props.type;
        }
    }

    serialize(): ITransferType {
        const data: ITransferType = {
        };
        if (typeof this._quantity !== 'undefined') {
            data.quantity = String(this._quantity);
        }
        if (typeof this._type !== 'undefined') {
            data.type = this._type;
        }
        return data;
    }

    toJSON(): ITransferType {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            quantity: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
