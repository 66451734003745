// This file was autogenerated. Please do not change.
export interface ILinkPlacementResponse {
    status: ('processing' | 'success' | 'troubleshooting');
}

export default class LinkPlacementResponse {
    readonly _status: ('processing' | 'success' | 'troubleshooting');

    /** Description: Linkage status */
    get status(): ('processing' | 'success' | 'troubleshooting') {
        return this._status;
    }

    constructor(props: ILinkPlacementResponse) {
        this._status = props.status;
    }

    serialize(): ILinkPlacementResponse {
        const data: ILinkPlacementResponse = {
            status: this._status,
        };
        return data;
    }

    toJSON(): ILinkPlacementResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
