// This file was autogenerated. Please do not change.
export interface IRevokeAccountPayload {
    accountId: string;
    groupId: string;
    userId: string;
}

export default class RevokeAccountPayload {
    readonly _accountId: string;

    /** Description: Account's unique identifier */
    get accountId(): string {
        return this._accountId;
    }

    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    readonly _userId: string;

    /** Description: User's unique identifier */
    get userId(): string {
        return this._userId;
    }

    constructor(props: IRevokeAccountPayload) {
        this._accountId = props.accountId.trim();
        this._groupId = props.groupId.trim();
        this._userId = props.userId.trim();
    }

    serialize(): IRevokeAccountPayload {
        const data: IRevokeAccountPayload = {
            accountId: this._accountId,
            groupId: this._groupId,
            userId: this._userId,
        };
        return data;
    }

    toJSON(): IRevokeAccountPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
            userId: typeof this._userId === 'string' && !!this._userId.trim(),
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
