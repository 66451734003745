import Asset, { IAsset } from './Asset';

// This file was autogenerated. Please do not change.
export interface IDexPositionQuotation {
    aInvestedQuantity: string;
    aQuantity: string;
    asset?: IAsset;
    bInvestedQuantity: string;
    bQuantity: string;
    pnl: string;
    pnlPercent: string;
}

export default class DexPositionQuotation {
    readonly _aInvestedQuantity: number;

    /**
     * Example: 1200.23
     */
    get aInvestedQuantity(): number {
        return this._aInvestedQuantity;
    }

    readonly _aQuantity: number;

    /**
     * Example: 1204.15
     */
    get aQuantity(): number {
        return this._aQuantity;
    }

    readonly _asset: Asset | undefined;

    get asset(): Asset | undefined {
        return this._asset;
    }

    readonly _bInvestedQuantity: number;

    /**
     * Example: 1000.21
     */
    get bInvestedQuantity(): number {
        return this._bInvestedQuantity;
    }

    readonly _bQuantity: number;

    /**
     * Example: 1205.14
     */
    get bQuantity(): number {
        return this._bQuantity;
    }

    readonly _pnl: number;

    /**
     * Example: 1.1
     */
    get pnl(): number {
        return this._pnl;
    }

    readonly _pnlPercent: number;

    /**
     * Example: -2.2
     */
    get pnlPercent(): number {
        return this._pnlPercent;
    }

    constructor(props: IDexPositionQuotation) {
        this._aInvestedQuantity = Number(props.aInvestedQuantity);
        this._aQuantity = Number(props.aQuantity);
        if (props.asset) {
            this._asset = new Asset(props.asset);
        }
        this._bInvestedQuantity = Number(props.bInvestedQuantity);
        this._bQuantity = Number(props.bQuantity);
        this._pnl = Number(props.pnl);
        this._pnlPercent = Number(props.pnlPercent);
    }

    serialize(): IDexPositionQuotation {
        const data: IDexPositionQuotation = {
            aInvestedQuantity: String(this._aInvestedQuantity),
            aQuantity: String(this._aQuantity),
            bInvestedQuantity: String(this._bInvestedQuantity),
            bQuantity: String(this._bQuantity),
            pnl: String(this._pnl),
            pnlPercent: String(this._pnlPercent),
        };
        if (typeof this._asset !== 'undefined') {
            data.asset = this._asset.serialize();
        }
        return data;
    }

    toJSON(): IDexPositionQuotation {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            aInvestedQuantity: true,
            aQuantity: true,
            asset: !this._asset ? true : this._asset.validate().length === 0,
            bInvestedQuantity: true,
            bQuantity: true,
            pnl: true,
            pnlPercent: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
