// This file was autogenerated. Please do not change.
export interface IStakingProductsRequest {
    assetId?: number;
    durations?: number[];
    includeTotal?: boolean;
    orderBy?: ('placement' | 'asset' | 'duration' | 'apr');
    ordering?: ('asc' | 'desc' | 'ASC' | 'DESC');
    page?: number;
    perPage?: number;
    placementId?: number;
    placementName?: string;
    symbol?: string;
}

export default class StakingProductsRequest {
    readonly _assetId: number | undefined;

    /** Description: Asset platform id */
    get assetId(): number | undefined {
        return this._assetId;
    }

    readonly _durations: number[] | undefined;

    get durations(): number[] | undefined {
        return this._durations;
    }

    readonly _includeTotal: boolean | undefined;

    /** Description: Add total count to response */
    get includeTotal(): boolean | undefined {
        return this._includeTotal;
    }

    readonly _orderBy: ('placement' | 'asset' | 'duration' | 'apr') | undefined;

    get orderBy(): ('placement' | 'asset' | 'duration' | 'apr') | undefined {
        return this._orderBy;
    }

    readonly _ordering: ('asc' | 'desc' | 'ASC' | 'DESC') | undefined;

    get ordering(): ('asc' | 'desc' | 'ASC' | 'DESC') | undefined {
        return this._ordering;
    }

    readonly _page: number | undefined;

    /** Description: Page number */
    get page(): number | undefined {
        return this._page;
    }

    readonly _perPage: number | undefined;

    /** Description: Elements' count per page */
    get perPage(): number | undefined {
        return this._perPage;
    }

    readonly _placementId: number | undefined;

    /** Description: Placement platform ID */
    get placementId(): number | undefined {
        return this._placementId;
    }

    readonly _placementName: string | undefined;

    /**
     * Description: Placement (custody, CEx, DEx) name, e.g. Single Broker, Binance, etc.
     * Example: Binance
     */
    get placementName(): string | undefined {
        return this._placementName;
    }

    readonly _symbol: string | undefined;

    /**
     * Example: ETH
     */
    get symbol(): string | undefined {
        return this._symbol;
    }

    constructor(props: IStakingProductsRequest) {
        if (typeof props.assetId === 'number') {
            this._assetId = props.assetId;
        }
        if (props.durations) {
            this._durations = props.durations;
        }
        if (typeof props.includeTotal === 'boolean') {
            this._includeTotal = props.includeTotal;
        }
        if (props.orderBy) {
            this._orderBy = props.orderBy;
        }
        if (props.ordering) {
            this._ordering = props.ordering;
        }
        if (typeof props.page === 'number') {
            this._page = props.page;
        }
        if (typeof props.perPage === 'number') {
            this._perPage = props.perPage;
        }
        if (typeof props.placementId === 'number') {
            this._placementId = props.placementId;
        }
        if (typeof props.placementName === 'string') {
            this._placementName = props.placementName.trim();
        }
        if (typeof props.symbol === 'string') {
            this._symbol = props.symbol.trim();
        }
    }

    serialize(): IStakingProductsRequest {
        const data: IStakingProductsRequest = {
        };
        if (typeof this._assetId !== 'undefined') {
            data.assetId = this._assetId;
        }
        if (typeof this._durations !== 'undefined') {
            data.durations = this._durations;
        }
        if (typeof this._includeTotal !== 'undefined') {
            data.includeTotal = this._includeTotal;
        }
        if (typeof this._orderBy !== 'undefined') {
            data.orderBy = this._orderBy;
        }
        if (typeof this._ordering !== 'undefined') {
            data.ordering = this._ordering;
        }
        if (typeof this._page !== 'undefined') {
            data.page = this._page;
        }
        if (typeof this._perPage !== 'undefined') {
            data.perPage = this._perPage;
        }
        if (typeof this._placementId !== 'undefined') {
            data.placementId = this._placementId;
        }
        if (typeof this._placementName !== 'undefined') {
            data.placementName = this._placementName;
        }
        if (typeof this._symbol !== 'undefined') {
            data.symbol = this._symbol;
        }
        return data;
    }

    toJSON(): IStakingProductsRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            assetId: !this._assetId ? true : typeof this._assetId === 'number',
            durations: !this._durations ? true : this._durations.reduce<boolean>((result, p) => result && typeof p === 'number', true),
            includeTotal: !this._includeTotal ? true : typeof this._includeTotal === 'boolean',
            page: !this._page ? true : typeof this._page === 'number',
            perPage: !this._perPage ? true : typeof this._perPage === 'number',
            placementId: !this._placementId ? true : typeof this._placementId === 'number',
            placementName: !this._placementName ? true : typeof this._placementName === 'string',
            symbol: !this._symbol ? true : typeof this._symbol === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
