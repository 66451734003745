// This file was autogenerated. Please do not change.
export interface ISpotOrdersStatuses {
    active?: number;
    closed?: number;
}

export default class SpotOrdersStatuses {
    readonly _active: number | undefined;

    get active(): number | undefined {
        return this._active;
    }

    readonly _closed: number | undefined;

    /**
     * Example: 648
     */
    get closed(): number | undefined {
        return this._closed;
    }

    constructor(props: ISpotOrdersStatuses) {
        if (typeof props.active === 'number') {
            this._active = props.active;
        }
        if (typeof props.closed === 'number') {
            this._closed = props.closed;
        }
    }

    serialize(): ISpotOrdersStatuses {
        const data: ISpotOrdersStatuses = {
        };
        if (typeof this._active !== 'undefined') {
            data.active = this._active;
        }
        if (typeof this._closed !== 'undefined') {
            data.closed = this._closed;
        }
        return data;
    }

    toJSON(): ISpotOrdersStatuses {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            active: !this._active ? true : typeof this._active === 'number',
            closed: !this._closed ? true : typeof this._closed === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
