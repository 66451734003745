// This file was autogenerated. Please do not change.
export interface ICandle {
    close?: number;
    high?: number;
    low?: number;
    open?: number;
    time?: number;
    volume?: number;
}

export default class Candle {
    readonly _close: number | undefined;

    get close(): number | undefined {
        return this._close;
    }

    readonly _high: number | undefined;

    get high(): number | undefined {
        return this._high;
    }

    readonly _low: number | undefined;

    get low(): number | undefined {
        return this._low;
    }

    readonly _open: number | undefined;

    get open(): number | undefined {
        return this._open;
    }

    readonly _time: number | undefined;

    get time(): number | undefined {
        return this._time;
    }

    readonly _volume: number | undefined;

    get volume(): number | undefined {
        return this._volume;
    }

    constructor(props: ICandle) {
        if (typeof props.close === 'number') {
            this._close = props.close;
        }
        if (typeof props.high === 'number') {
            this._high = props.high;
        }
        if (typeof props.low === 'number') {
            this._low = props.low;
        }
        if (typeof props.open === 'number') {
            this._open = props.open;
        }
        if (typeof props.time === 'number') {
            this._time = props.time;
        }
        if (typeof props.volume === 'number') {
            this._volume = props.volume;
        }
    }

    serialize(): ICandle {
        const data: ICandle = {
        };
        if (typeof this._close !== 'undefined') {
            data.close = this._close;
        }
        if (typeof this._high !== 'undefined') {
            data.high = this._high;
        }
        if (typeof this._low !== 'undefined') {
            data.low = this._low;
        }
        if (typeof this._open !== 'undefined') {
            data.open = this._open;
        }
        if (typeof this._time !== 'undefined') {
            data.time = this._time;
        }
        if (typeof this._volume !== 'undefined') {
            data.volume = this._volume;
        }
        return data;
    }

    toJSON(): ICandle {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            close: !this._close ? true : typeof this._close === 'number',
            high: !this._high ? true : typeof this._high === 'number',
            low: !this._low ? true : typeof this._low === 'number',
            open: !this._open ? true : typeof this._open === 'number',
            time: !this._time ? true : typeof this._time === 'number',
            volume: !this._volume ? true : typeof this._volume === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
