// This file was autogenerated. Please do not change.
/* MFA factor payload */
export interface IBaseFactorPayload {
    mfaType: ('Email' | 'EmailActivation' | 'TOTP');
}

export default class BaseFactorPayload {
    readonly _mfaType: ('Email' | 'EmailActivation' | 'TOTP');

    /** Description: MFA Type */
    get mfaType(): ('Email' | 'EmailActivation' | 'TOTP') {
        return this._mfaType;
    }

    constructor(props: IBaseFactorPayload) {
        this._mfaType = props.mfaType;
    }

    serialize(): IBaseFactorPayload {
        const data: IBaseFactorPayload = {
            mfaType: this._mfaType,
        };
        return data;
    }

    toJSON(): IBaseFactorPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
