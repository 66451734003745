// This file was autogenerated. Please do not change.
/* Represents points data for each chart */
export interface IPNLPoints {
    UTCTimestampList?: number[];
    accountIds?: string[];
    quantityList?: string[];
}

export default class PNLPoints {
    readonly _UTCTimestampList: number[] | undefined;

    /** Description: UTC timestamp for each point */
    get UTCTimestampList(): number[] | undefined {
        return this._UTCTimestampList;
    }

    readonly _accountIds: string[] | undefined;

    /** Description: Id of each account */
    get accountIds(): string[] | undefined {
        return this._accountIds;
    }

    readonly _quantityList: string[] | undefined;

    /** Description: PNL value for each point */
    get quantityList(): string[] | undefined {
        return this._quantityList;
    }

    constructor(props: IPNLPoints) {
        if (props.UTCTimestampList) {
            this._UTCTimestampList = props.UTCTimestampList;
        }
        if (props.accountIds) {
            this._accountIds = props.accountIds;
        }
        if (props.quantityList) {
            this._quantityList = props.quantityList;
        }
    }

    serialize(): IPNLPoints {
        const data: IPNLPoints = {
        };
        if (typeof this._UTCTimestampList !== 'undefined') {
            data.UTCTimestampList = this._UTCTimestampList;
        }
        if (typeof this._accountIds !== 'undefined') {
            data.accountIds = this._accountIds;
        }
        if (typeof this._quantityList !== 'undefined') {
            data.quantityList = this._quantityList;
        }
        return data;
    }

    toJSON(): IPNLPoints {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accountIds: !this._accountIds ? true : this._accountIds.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            UTCTimestampList: !this._UTCTimestampList ? true : this._UTCTimestampList.reduce<boolean>((result, p) => result && typeof p === 'number', true),
            quantityList: !this._quantityList ? true : this._quantityList.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
