import GroupAccount, { IGroupAccount } from './GroupAccount';
import GroupUser, { IGroupUser } from './GroupUser';

// This file was autogenerated. Please do not change.
export interface IGroup {
    accounts?: IGroupAccount[];
    description: string;
    id: string;
    members?: IGroupUser[];
    name: string;
}

export default class Group {
    readonly _accounts: GroupAccount[] | undefined;

    get accounts(): GroupAccount[] | undefined {
        return this._accounts;
    }

    readonly _description: string;

    /**
     * Example: group description
     */
    get description(): string {
        return this._description;
    }

    readonly _id: string;

    /**
     * Example: GD3TGKFC3
     */
    get id(): string {
        return this._id;
    }

    readonly _members: GroupUser[] | undefined;

    /** Description: OwnerUserId         int64            `pg:"owner_user_id" json:"ownerId"` */
    get members(): GroupUser[] | undefined {
        return this._members;
    }

    readonly _name: string;

    /**
     * Example: GroupName
     */
    get name(): string {
        return this._name;
    }

    constructor(props: IGroup) {
        if (props.accounts) {
            this._accounts = props.accounts.map((p) => new GroupAccount(p));
        }
        this._description = props.description.trim();
        this._id = props.id.trim();
        if (props.members) {
            this._members = props.members.map((p) => new GroupUser(p));
        }
        this._name = props.name.trim();
    }

    serialize(): IGroup {
        const data: IGroup = {
            description: this._description,
            id: this._id,
            name: this._name,
        };
        if (typeof this._accounts !== 'undefined') {
            data.accounts = this._accounts.map((p) => p.serialize());
        }
        if (typeof this._members !== 'undefined') {
            data.members = this._members.map((p) => p.serialize());
        }
        return data;
    }

    toJSON(): IGroup {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            accounts: !this._accounts ? true : this._accounts.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            description: typeof this._description === 'string' && !!this._description.trim(),
            id: typeof this._id === 'string' && !!this._id.trim(),
            members: !this._members ? true : this._members.reduce<boolean>((result, p) => result && p.validate().length === 0, true),
            name: typeof this._name === 'string' && !!this._name.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
