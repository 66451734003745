// This file was autogenerated. Please do not change.
export interface ICandlesRequest {
    from: number;
    placementName: string;
    resolution: number;
    symbol: string;
    to: number;
}

export default class CandlesRequest {
    readonly _from: number;

    get from(): number {
        return this._from;
    }

    readonly _placementName: string;

    get placementName(): string {
        return this._placementName;
    }

    readonly _resolution: number;

    get resolution(): number {
        return this._resolution;
    }

    readonly _symbol: string;

    get symbol(): string {
        return this._symbol;
    }

    readonly _to: number;

    get to(): number {
        return this._to;
    }

    constructor(props: ICandlesRequest) {
        this._from = props.from;
        this._placementName = props.placementName.trim();
        this._resolution = props.resolution;
        this._symbol = props.symbol.trim();
        this._to = props.to;
    }

    serialize(): ICandlesRequest {
        const data: ICandlesRequest = {
            from: this._from,
            placementName: this._placementName,
            resolution: this._resolution,
            symbol: this._symbol,
            to: this._to,
        };
        return data;
    }

    toJSON(): ICandlesRequest {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            from: typeof this._from === 'number',
            placementName: typeof this._placementName === 'string' && !!this._placementName.trim(),
            resolution: typeof this._resolution === 'number',
            symbol: typeof this._symbol === 'string' && !!this._symbol.trim(),
            to: typeof this._to === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
