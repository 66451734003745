// This file was autogenerated. Please do not change.
export interface ITrade {
    id?: string;
    price?: number;
    side?: string;
    size?: number;
    symbol?: string;
    timestamp?: number;
}

export default class Trade {
    readonly _id: string | undefined;

    /**
     * Example: 1650359712234
     */
    get id(): string | undefined {
        return this._id;
    }

    readonly _price: number | undefined;

    /**
     * Example: 40624.83
     */
    get price(): number | undefined {
        return this._price;
    }

    readonly _side: string | undefined;

    /**
     * Example: buy
     */
    get side(): string | undefined {
        return this._side;
    }

    readonly _size: number | undefined;

    /**
     * Example: 0.00101
     */
    get size(): number | undefined {
        return this._size;
    }

    readonly _symbol: string | undefined;

    /**
     * Example: BTC/USDT
     */
    get symbol(): string | undefined {
        return this._symbol;
    }

    readonly _timestamp: number | undefined;

    get timestamp(): number | undefined {
        return this._timestamp;
    }

    constructor(props: ITrade) {
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.price === 'number') {
            this._price = props.price;
        }
        if (typeof props.side === 'string') {
            this._side = props.side.trim();
        }
        if (typeof props.size === 'number') {
            this._size = props.size;
        }
        if (typeof props.symbol === 'string') {
            this._symbol = props.symbol.trim();
        }
        if (typeof props.timestamp === 'number') {
            this._timestamp = props.timestamp;
        }
    }

    serialize(): ITrade {
        const data: ITrade = {
        };
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._price !== 'undefined') {
            data.price = this._price;
        }
        if (typeof this._side !== 'undefined') {
            data.side = this._side;
        }
        if (typeof this._size !== 'undefined') {
            data.size = this._size;
        }
        if (typeof this._symbol !== 'undefined') {
            data.symbol = this._symbol;
        }
        if (typeof this._timestamp !== 'undefined') {
            data.timestamp = this._timestamp;
        }
        return data;
    }

    toJSON(): ITrade {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            price: !this._price ? true : typeof this._price === 'number',
            side: !this._side ? true : typeof this._side === 'string',
            size: !this._size ? true : typeof this._size === 'number',
            symbol: !this._symbol ? true : typeof this._symbol === 'string',
            timestamp: !this._timestamp ? true : typeof this._timestamp === 'number',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
