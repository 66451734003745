// This file was autogenerated. Please do not change.
export interface IBaseGroupRequestResponse {
    groupId?: string;
    id?: string;
    status?: string;
}

export default class BaseGroupRequestResponse {
    readonly _groupId: string | undefined;

    /** Description: Group identifier */
    get groupId(): string | undefined {
        return this._groupId;
    }

    readonly _id: string | undefined;

    /** Description: Group request identifier */
    get id(): string | undefined {
        return this._id;
    }

    readonly _status: string | undefined;

    /** Description: Group name */
    get status(): string | undefined {
        return this._status;
    }

    constructor(props: IBaseGroupRequestResponse) {
        if (typeof props.groupId === 'string') {
            this._groupId = props.groupId.trim();
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): IBaseGroupRequestResponse {
        const data: IBaseGroupRequestResponse = {
        };
        if (typeof this._groupId !== 'undefined') {
            data.groupId = this._groupId;
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IBaseGroupRequestResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            status: !this._status ? true : typeof this._status === 'string',
            groupId: !this._groupId ? true : typeof this._groupId === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
