// This file was autogenerated. Please do not change.
export interface IBlockchain {
    addressExplorerUrl: string;
    id: number;
    memoRequired: boolean;
    name: string;
    nativeAssetSymbol: string;
    txExplorerUrl: string;
}

export default class Blockchain {
    readonly _addressExplorerUrl: string;

    /**
     * Example: https://www.blockchain.com/btc/address/{address}
     */
    get addressExplorerUrl(): string {
        return this._addressExplorerUrl;
    }

    readonly _id: number;

    /**
     * Example: 2
     */
    get id(): number {
        return this._id;
    }

    readonly _memoRequired: boolean;

    get memoRequired(): boolean {
        return this._memoRequired;
    }

    readonly _name: string;

    /**
     * Example: BTC
     */
    get name(): string {
        return this._name;
    }

    readonly _nativeAssetSymbol: string;

    /**
     * Example: BTC
     */
    get nativeAssetSymbol(): string {
        return this._nativeAssetSymbol;
    }

    readonly _txExplorerUrl: string;

    /**
     * Example: https://www.blockchain.com/btc/tx/{blockchain_tx_id}
     */
    get txExplorerUrl(): string {
        return this._txExplorerUrl;
    }

    constructor(props: IBlockchain) {
        this._addressExplorerUrl = props.addressExplorerUrl.trim();
        this._id = props.id;
        this._memoRequired = props.memoRequired;
        this._name = props.name.trim();
        this._nativeAssetSymbol = props.nativeAssetSymbol.trim();
        this._txExplorerUrl = props.txExplorerUrl.trim();
    }

    serialize(): IBlockchain {
        const data: IBlockchain = {
            addressExplorerUrl: this._addressExplorerUrl,
            id: this._id,
            memoRequired: this._memoRequired,
            name: this._name,
            nativeAssetSymbol: this._nativeAssetSymbol,
            txExplorerUrl: this._txExplorerUrl,
        };
        return data;
    }

    toJSON(): IBlockchain {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            addressExplorerUrl: typeof this._addressExplorerUrl === 'string' && !!this._addressExplorerUrl.trim(),
            id: typeof this._id === 'number',
            memoRequired: typeof this._memoRequired === 'boolean',
            name: typeof this._name === 'string' && !!this._name.trim(),
            nativeAssetSymbol: typeof this._nativeAssetSymbol === 'string' && !!this._nativeAssetSymbol.trim(),
            txExplorerUrl: typeof this._txExplorerUrl === 'string' && !!this._txExplorerUrl.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
