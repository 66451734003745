// This file was autogenerated. Please do not change.
export interface IAssignAccountPayload {
    accountId: string;
    groupId: string;
    policies: string[];
    userId: string;
}

export default class AssignAccountPayload {
    readonly _accountId: string;

    /** Description: Account's unique identifier */
    get accountId(): string {
        return this._accountId;
    }

    readonly _groupId: string;

    /** Description: Group's unique identifier */
    get groupId(): string {
        return this._groupId;
    }

    readonly _policies: string[];

    /** Description: List of permissions */
    get policies(): string[] {
        return this._policies;
    }

    readonly _userId: string;

    /** Description: User's unique identifier */
    get userId(): string {
        return this._userId;
    }

    constructor(props: IAssignAccountPayload) {
        this._accountId = props.accountId.trim();
        this._groupId = props.groupId.trim();
        this._policies = props.policies;
        this._userId = props.userId.trim();
    }

    serialize(): IAssignAccountPayload {
        const data: IAssignAccountPayload = {
            accountId: this._accountId,
            groupId: this._groupId,
            policies: this._policies,
            userId: this._userId,
        };
        return data;
    }

    toJSON(): IAssignAccountPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            groupId: typeof this._groupId === 'string' && !!this._groupId.trim(),
            userId: typeof this._userId === 'string' && !!this._userId.trim(),
            accountId: typeof this._accountId === 'string' && !!this._accountId.trim(),
            policies: this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
