import GasPrices, { IGasPrices } from './GasPrices';

// This file was autogenerated. Please do not change.
export interface IPreparedTxResponse {
    expiredAt?: string;
    gasLimit: string;
    gasPrice: string;
    gasPrices?: IGasPrices;
    networkFee: string;
    uid: string;
}

export default class PreparedTxResponse {
    readonly _expiredAt: string | undefined;

    /**
     * Example: 2021-04-20T14:00:25.561179Z
     */
    get expiredAt(): string | undefined {
        return this._expiredAt;
    }

    readonly _gasLimit: number;

    /**
     * Example: 1
     */
    get gasLimit(): number {
        return this._gasLimit;
    }

    readonly _gasPrice: number;

    /**
     * Example: 1
     */
    get gasPrice(): number {
        return this._gasPrice;
    }

    readonly _gasPrices: GasPrices | undefined;

    get gasPrices(): GasPrices | undefined {
        return this._gasPrices;
    }

    readonly _networkFee: number;

    /**
     * Example: 0.0002
     */
    get networkFee(): number {
        return this._networkFee;
    }

    readonly _uid: string;

    /**
     * Example: 123e4567-e89b-12d3-a456-426614174000
     */
    get uid(): string {
        return this._uid;
    }

    constructor(props: IPreparedTxResponse) {
        if (typeof props.expiredAt === 'string') {
            this._expiredAt = props.expiredAt.trim();
        }
        this._gasLimit = Number(props.gasLimit);
        this._gasPrice = Number(props.gasPrice);
        if (props.gasPrices) {
            this._gasPrices = new GasPrices(props.gasPrices);
        }
        this._networkFee = Number(props.networkFee);
        this._uid = props.uid.trim();
    }

    serialize(): IPreparedTxResponse {
        const data: IPreparedTxResponse = {
            gasLimit: String(this._gasLimit),
            gasPrice: String(this._gasPrice),
            networkFee: String(this._networkFee),
            uid: this._uid,
        };
        if (typeof this._expiredAt !== 'undefined') {
            data.expiredAt = this._expiredAt;
        }
        if (typeof this._gasPrices !== 'undefined') {
            data.gasPrices = this._gasPrices.serialize();
        }
        return data;
    }

    toJSON(): IPreparedTxResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            expiredAt: !this._expiredAt ? true : typeof this._expiredAt === 'string',
            gasLimit: true,
            gasPrice: true,
            gasPrices: !this._gasPrices ? true : this._gasPrices.validate().length === 0,
            networkFee: true,
            uid: typeof this._uid === 'string' && !!this._uid.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
