// This file was autogenerated. Please do not change.
export interface IAttributesValues {
    max?: string;
    min?: string;
    step?: string;
}

export default class AttributesValues {
    readonly _max: number | undefined;

    get max(): number | undefined {
        return this._max;
    }

    readonly _min: number | undefined;

    /**
     * Example: 0.01
     */
    get min(): number | undefined {
        return this._min;
    }

    readonly _step: number | undefined;

    /**
     * Example: 0.01
     */
    get step(): number | undefined {
        return this._step;
    }

    constructor(props: IAttributesValues) {
        if (typeof props.max === 'string') {
            this._max = Number(props.max);
        }
        if (typeof props.min === 'string') {
            this._min = Number(props.min);
        }
        if (typeof props.step === 'string') {
            this._step = Number(props.step);
        }
    }

    serialize(): IAttributesValues {
        const data: IAttributesValues = {
        };
        if (typeof this._max !== 'undefined') {
            data.max = String(this._max);
        }
        if (typeof this._min !== 'undefined') {
            data.min = String(this._min);
        }
        if (typeof this._step !== 'undefined') {
            data.step = String(this._step);
        }
        return data;
    }

    toJSON(): IAttributesValues {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            max: true,
            min: true,
            step: true,
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
