import table from './table.module.pcss';
import orderbook from './orderbook.module.pcss';
import tradehistory from './tradehistory.module.pcss';
import statusrow from './statusrow.module.pcss';
import moneyinput from './moneyinput.module.pcss';
import placeorder from './placeorder.module.pcss';
import quickbalances from './quickbalances.module.pcss';
import buttons from './buttons.module.pcss';
import aggregatedbalances from './aggregatedbalances.module.pcss';
import transferhistory from './transferhistory.module.pcss';
import layout from './layout.module.pcss';
import profile from './profile.module.pcss';
import auth from './auth.module.pcss';

const theme = {
    table,
    orderbook,
    tradehistory,
    statusrow,
    moneyinput,
    placeorder,
    quickbalances,
    buttons,
    aggregatedbalances,
    transferhistory,
    layout,
    profile,
    auth,
};

export default theme;
