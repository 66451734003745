import AccountInfo, { IAccountInfo } from './AccountInfo';

// This file was autogenerated. Please do not change.
export interface IAccountRequestInfo {
    account?: IAccountInfo;
    id?: string;
    issuer?: string;
    policies?: string[];
    recipient?: string;
    status?: string;
}

export default class AccountRequestInfo {
    readonly _account: AccountInfo | undefined;

    get account(): AccountInfo | undefined {
        return this._account;
    }

    readonly _id: string | undefined;

    get id(): string | undefined {
        return this._id;
    }

    readonly _issuer: string | undefined;

    get issuer(): string | undefined {
        return this._issuer;
    }

    readonly _policies: string[] | undefined;

    get policies(): string[] | undefined {
        return this._policies;
    }

    readonly _recipient: string | undefined;

    get recipient(): string | undefined {
        return this._recipient;
    }

    readonly _status: string | undefined;

    get status(): string | undefined {
        return this._status;
    }

    constructor(props: IAccountRequestInfo) {
        if (props.account) {
            this._account = new AccountInfo(props.account);
        }
        if (typeof props.id === 'string') {
            this._id = props.id.trim();
        }
        if (typeof props.issuer === 'string') {
            this._issuer = props.issuer.trim();
        }
        if (props.policies) {
            this._policies = props.policies;
        }
        if (typeof props.recipient === 'string') {
            this._recipient = props.recipient.trim();
        }
        if (typeof props.status === 'string') {
            this._status = props.status.trim();
        }
    }

    serialize(): IAccountRequestInfo {
        const data: IAccountRequestInfo = {
        };
        if (typeof this._account !== 'undefined') {
            data.account = this._account.serialize();
        }
        if (typeof this._id !== 'undefined') {
            data.id = this._id;
        }
        if (typeof this._issuer !== 'undefined') {
            data.issuer = this._issuer;
        }
        if (typeof this._policies !== 'undefined') {
            data.policies = this._policies;
        }
        if (typeof this._recipient !== 'undefined') {
            data.recipient = this._recipient;
        }
        if (typeof this._status !== 'undefined') {
            data.status = this._status;
        }
        return data;
    }

    toJSON(): IAccountRequestInfo {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            id: !this._id ? true : typeof this._id === 'string',
            account: !this._account ? true : this._account.validate().length === 0,
            issuer: !this._issuer ? true : typeof this._issuer === 'string',
            recipient: !this._recipient ? true : typeof this._recipient === 'string',
            policies: !this._policies ? true : this._policies.reduce<boolean>((result, p) => result && typeof p === 'string', true),
            status: !this._status ? true : typeof this._status === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
