// This file was autogenerated. Please do not change.
export interface IBaseGroupRequestPayload {
    requestId: string;
}

export default class BaseGroupRequestPayload {
    readonly _requestId: string;

    /** Description: Group's unique identifier */
    get requestId(): string {
        return this._requestId;
    }

    constructor(props: IBaseGroupRequestPayload) {
        this._requestId = props.requestId.trim();
    }

    serialize(): IBaseGroupRequestPayload {
        const data: IBaseGroupRequestPayload = {
            requestId: this._requestId,
        };
        return data;
    }

    toJSON(): IBaseGroupRequestPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            requestId: typeof this._requestId === 'string' && !!this._requestId.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
