import TradingApi from 'Apis/Trading';
import SpotTradesRequest from 'Lib/entities/privatePresenter/SpotTradesRequest';
import SpotOrdersRequest from 'Lib/entities/privatePresenter/SpotOrdersRequest';
import CancelOrderRequest from 'Lib/entities/orderRegistrator/CancelOrderRequest';
import { parsePaginationHeaders } from 'Lib/utils/PaginationParser';
import SpotTrade, { ISpotTrade } from 'Entities/privatePresenter/SpotTrade';
import SpotOrderPresenter, { ISpotOrderPresenter } from 'Entities/privatePresenter/SpotOrderPresenter';
import { deepEqual } from 'Lib/utils/DeepEqual';

export enum OrdersHistoryTypes {
    SPOT = 'SPOT',
    FUTURES = 'FUTURES',
}

const state = {
    ordersData: {
        orders: new Map<number, SpotOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as ISpotOrderPresenter[],
        isLoading: false,
    },
    closedOrdersData: {
        orders: new Map<number, SpotOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as ISpotOrderPresenter[],
        isLoading: false,
    },
    openOrdersData: {
        orders: new Map<number, SpotOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as ISpotOrderPresenter[],
        isLoading: false,
    },
    tradesData: {
        trades: new Map<number, SpotTrade[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        isLoading: false,
    },
    otcOrdersData: {
        orders: new Map<number, SpotOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as ISpotOrderPresenter[],
        isLoading: false,
    },
    otcClosedOrdersData: {
        orders: new Map<number, SpotOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as ISpotOrderPresenter[],
        isLoading: false,
    },
    otcOpenOrdersData: {
        orders: new Map<number, SpotOrderPresenter[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        updatesQueue: [] as ISpotOrderPresenter[],
        isLoading: false,
    },
    otcTradesData: {
        trades: new Map<number, SpotTrade[]>(),
        perPage: 12,
        totalPages: 1,
        isSubscribedToUpdates: false,
        isLoading: false,
    },
    // map key format - 'accountId, orderId'
    ordersTradesMap: new Map<string, SpotTrade[]>(),

    activeSide: OrdersHistoryTypes.SPOT as OrdersHistoryTypes,
    notificationsCache: new Map<string, Set<string>>(),
    activePage: 1,
    itemsPerPage: 12,
    isRecordsDownloading: false,
    activeTerminalModuleTabIndex: 0,
    isSubscribedToTradesUpdates: false,
};

const getters = {
    trades: (state) => state.trades,
    lastOrderCreatedAt: (state, getters) => new Date(getters.orders[0]?.createdAt || 0).valueOf(),
    ordersIds: (state, getters) => getters.orders.map(({ id }) => id),
    toggleAccountUpdaterID: (state) => state.toggleAccountUpdaterID,
    isToggleAccountUpdaterActive: (state, getters) => !!getters.toggleAccountUpdaterID,
    activePage: (state) => state.activePage,
    itemsPerPage: (state) => state.itemsPerPage,
    pagesCount: (state) => state.pagesCount,
    totalItemsCount: (state) => state.totalItemsCount,
    hasListActiveOrder: (state, getters) => getters.orders.some((order) => order.isActive),
    ordersListUpdaterID: (state) => state.ordersListUpdaterID,
    isOrdersListUpdaterActive: (state, getters) => getters.ordersListUpdaterID !== null,
    isRecordsDownloading: (state) => state.isRecordsDownloading,
    activeTerminalModuleTabIndex: (state) => state.activeTerminalModuleTabIndex,
    isActiveTabActive: (state, getters) => getters.activeTerminalModuleTabIndex === 1,
    isClosedTabActive: (state, getters) => getters.activeTerminalModuleTabIndex === 2,
    isTradesTabActive: (state, getters) => getters.activeTerminalModuleTabIndex === 3,
    isPositionsTabActive: (state, getters) => getters.activeTerminalModuleTabIndex === 4,
    isFundingsTabActive: (state, getters) => getters.activeTerminalModuleTabIndex === 5,
    activeUpdatersIds: (state) => state.activeUpdatersIds,
    isListUpdating: (state, getters) => getters.activeUpdatersIds.length > 0,
    currentOrdersUpdaterId: (state) => state.currentOrdersUpdaterId,
};

const mutations = {
    SET_ACTIVE_PAGE_NUMBER(state, pageNumber) {
        state.activePage = pageNumber;
    },
    SET_PAGES_COUNT(state, pagesCount) {
        state.pagesCount = pagesCount;
    },
    SET_TOTAL_ITEMS_COUNT(state, itemsCount) {
        state.totalItemsCount = itemsCount;
    },
    SET_NOTIFICATION_TO_CACHE(state, order: ISpotOrderPresenter) {
        const tempMap: Map<string, Set<string>> = new Map(state.notificationsCache);
        if (tempMap.has(order.id)) {
            const tempSet = new Set(tempMap.get(order.id)!);
            tempSet.add(order.status);
            tempMap.set(order.id, tempSet);
        } else {
            tempMap.set(order.id, new Set([order.status]));
        }
        state.notificationsCache = tempMap;
    },
    SET_TOGGLE_ACCOUNT_UPDATER_ID(state, id) {
        state.toggleAccountUpdaterID = id;
    },
    SET_ORDERS_LIST_UPDATER_ID(state, id) {
        state.ordersListUpdaterID = id;
    },
    SET_IS_RECORDS_DOWNLOADING(state, value) {
        state.isRecordsDownloading = value;
    },
    SET_ACTIVE_TERMINAL_MODULE_TAB_INDEX(state, index) {
        state.activeTerminalModuleTabIndex = index;
    },
    SET_CURRENT_ORDER_UPDATER_UD(state, id) {
        state.currentOrdersUpdaterId = id;
    },
    SET_ACTIVE_SIDE(state, side) {
        state.activeSide = side;
    },
    SET_ORDERS(state, orders) {
        state.ordersData.orders = orders;
    },
    SET_OPEN_ORDERS(state, orders) {
        state.openOrdersData.orders = orders;
    },
    SET_CLOSED_ORDERS(state, orders) {
        state.closedOrdersData.orders = orders;
    },
    SET_TRADES(state, trades) {
        state.tradesData.trades = trades;
    },
    SET_OTC_ORDERS(state, orders) {
        state.otcOrdersData.orders = orders;
    },
    SET_OTC_OPEN_ORDERS(state, orders) {
        state.otcOpenOrdersData.orders = orders;
    },
    SET_OTC_CLOSED_ORDERS(state, orders) {
        state.otcClosedOrdersData.orders = orders;
    },
    SET_OTC_TRADES(state, trades) {
        state.otcTradesData.trades = trades;
    },
};

const actions = {
    setActiveTerminalModuleTabIndex({ commit }, index) {
        commit('SET_ACTIVE_TERMINAL_MODULE_TAB_INDEX', index);
        commit('SET_ACTIVE_PAGE_NUMBER', 1);
    },
    // rest get orders
    async getOrders({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.ordersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getOrders', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.ordersData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_ORDERS', new Map());
        }
        if (!isNew && state.ordersData.orders.has(page)) {
            return;
        }

        try {
            state.ordersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateGetSpotOrders(new SpotOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.ordersData.perPage,
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'crypto-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.ordersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.ordersData.orders);
            temp.set(page, orders);
            commit('SET_ORDERS', temp);
        } catch {
            const temp = new Map(state.ordersData.orders);
            temp.set(page, []);
            commit('SET_ORDERS', temp);
        } finally {
            state.ordersData.isLoading = false;
        }
    },
    async getClosedOrders({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.closedOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getClosedOrders', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.closedOrdersData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_CLOSED_ORDERS', new Map());
        }
        if (!isNew && state.closedOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.closedOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateGetSpotOrders(new SpotOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.closedOrdersData.perPage,
                statuses: ['FILLED', 'CANCELED', 'REJECTED'],
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'crypto-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.closedOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.closedOrdersData.orders);
            temp.set(page, orders);
            commit('SET_CLOSED_ORDERS', temp);
        } catch {
            const temp = new Map(state.closedOrdersData.orders);
            temp.set(page, []);
            commit('SET_CLOSED_ORDERS', temp);
        } finally {
            state.closedOrdersData.isLoading = false;
        }
    },
    async getOpenOrders({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.openOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getOpenOrders', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.openOrdersData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_OPEN_ORDERS', new Map());
        }
        if (!isNew && state.openOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.openOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateGetSpotOrders(new SpotOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.openOrdersData.perPage,
                statuses: ['REGISTERED', 'PARTIALY_FILLED', 'CREATED', 'PLACED'],
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'crypto-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.openOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.openOrdersData.orders);
            temp.set(page, orders);
            commit('SET_OPEN_ORDERS', temp);
        } catch {
            const temp = new Map(state.openOrdersData.orders);
            temp.set(page, []);
            commit('SET_OPEN_ORDERS', temp);
        } finally {
            state.openOrdersData.isLoading = false;
        }
    },
    // otc rest get orders
    async getOtcOrders({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.otcOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getOtcOrders', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.otcOrdersData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_OTC_ORDERS', new Map());
        }
        if (!isNew && state.otcOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.otcOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateGetSpotOrders(new SpotOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.ordersData.perPage,
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'otc-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.otcOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.otcOrdersData.orders);
            temp.set(page, orders);
            commit('SET_OTC_ORDERS', temp);
        } catch {
            const temp = new Map(state.otcOrdersData.orders);
            temp.set(page, []);
            commit('SET_OTC_ORDERS', temp);
        } finally {
            state.otcOrdersData.isLoading = false;
        }
    },
    async getOtcClosedOrders({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.otcClosedOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getOtcClosedOrders', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.otcClosedOrdersData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_OTC_CLOSED_ORDERS', new Map());
        }
        if (!isNew && state.otcClosedOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.otcClosedOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateGetSpotOrders(new SpotOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.closedOrdersData.perPage,
                statuses: ['FILLED', 'CANCELED', 'REJECTED'],
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'otc-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.otcClosedOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.otcClosedOrdersData.orders);
            temp.set(page, orders);
            commit('SET_OTC_CLOSED_ORDERS', temp);
        } catch {
            const temp = new Map(state.otcClosedOrdersData.orders);
            temp.set(page, []);
            commit('SET_OTC_CLOSED_ORDERS', temp);
        } finally {
            state.otcClosedOrdersData.isLoading = false;
        }
    },
    async getOtcOpenOrders({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.otcOpenOrdersData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getOtcOpenOrders', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.otcOpenOrdersData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_OTC_OPEN_ORDERS', new Map());
        }
        if (!isNew && state.otcOpenOrdersData.orders.has(page)) {
            return;
        }

        try {
            state.otcOpenOrdersData.isLoading = true;
            const {
                data: orders,
                headers,
            } = await TradingApi.privateGetSpotOrders(new SpotOrdersRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.openOrdersData.perPage,
                statuses: ['REGISTERED', 'PARTIALY_FILLED', 'CREATED', 'PLACED'],
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'otc-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.otcOpenOrdersData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.otcOpenOrdersData.orders);
            temp.set(page, orders);
            commit('SET_OTC_OPEN_ORDERS', temp);
        } catch {
            const temp = new Map(state.otcOpenOrdersData.orders);
            temp.set(page, []);
            commit('SET_OTC_OPEN_ORDERS', temp);
        } finally {
            state.otcOpenOrdersData.isLoading = false;
        }
    },
    // rest get trades
    async getTrades({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.tradesData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getTrades', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.tradesData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_TRADES', new Map());
        }
        if (!isNew && state.tradesData.trades.has(page)) {
            return;
        }

        try {
            state.tradesData.isLoading = true;
            const {
                data: trades,
                headers,
            } = await TradingApi.privateGetSpotTrades(new SpotTradesRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.tradesData.perPage,
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'crypto-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.tradesData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.tradesData.trades);
            temp.set(page, trades);
            commit('SET_TRADES', temp);
        } catch {
            const temp = new Map(state.tradesData.trades);
            temp.set(page, []);
            commit('SET_TRADES', temp);
        } finally {
            state.tradesData.isLoading = false;
        }
    },
    async getOrderTrades({ state }, { orderId, accountId }: { orderId: string; accountId: string; }) {
        try {
            if (state.ordersTradesMap.has(`${accountId}, ${orderId}`)) {
                return;
            }

            const { data: trades } = await TradingApi.privateGetSpotTrades(new SpotTradesRequest({
                accountId,
                orderId,
            }));
            const tempMap = new Map(state.ordersTradesMap);
            tempMap.set(`${accountId}, ${orderId}`, trades);
            state.ordersTradesMap = tempMap;
        } catch {
            const tempMap = new Map(state.ordersTradesMap);
            tempMap.set(`${accountId}, ${orderId}`, []);
            state.ordersTradesMap = tempMap;
        }
    },
    // otc rest get trades
    async getOtcTrades({ state, dispatch, rootState, commit }, payload: { isNew?: boolean; page?: number; }) {
        if (!state.otcTradesData.isSubscribedToUpdates) {
            await dispatch('VuexEventListener/addActionListener', {
                type: 'Accounts/setActiveAccount',
                callback: async () => {
                    await dispatch('getOtcTrades', { isNew: true, page: 1 });
                },
            }, { root: true });
            state.otcTradesData.isSubscribedToUpdates = true;
        }

        let { page, isNew } = payload;
        if (page === undefined) {
            page = 1;
        }
        if (isNew === undefined) {
            isNew = false;
        }

        const activeAccountId = rootState.Accounts.activeAccountID;
        if (!activeAccountId) {
            return;
        }
        if (isNew) {
            commit('SET_OTC_TRADES', new Map());
        }
        if (!isNew && state.otcTradesData.trades.has(page)) {
            return;
        }

        try {
            state.otcTradesData.isLoading = true;
            const {
                data: trades,
                headers,
            } = await TradingApi.privateGetSpotTrades(new SpotTradesRequest({
                accountId: activeAccountId,
                includeTotal: true,
                page,
                perPage: state.tradesData.perPage,
                placementTags: rootState.Placements.placements.reduce((accum, current) => {
                    if (current.type === 'otc-spot') {
                        accum.push(current.tag);
                    }
                    return accum;
                }, []),
            }), true);

            if (headers) {
                const { totalPage } = parsePaginationHeaders(headers);
                if (totalPage !== undefined) {
                    state.otcTradesData.totalPages = totalPage;
                }
            }

            const temp = new Map(state.otcTradesData.trades);
            temp.set(page, trades);
            commit('SET_OTC_TRADES', temp);
        } catch {
            const temp = new Map(state.otcTradesData.trades);
            temp.set(page, []);
            commit('SET_OTC_TRADES', temp);
        } finally {
            state.otcTradesData.isLoading = false;
        }
    },
    // orders socket updates
    async updateOrdersBySocket({ state, dispatch, commit }, order: ISpotOrderPresenter) {
        if (!state.notificationsCache.has(order.id) || !state.notificationsCache.get(order.id)?.has(order.status)) {
            // eslint-disable-next-line no-nested-ternary
            dispatch(`Notificator/show${order.status.toLowerCase() === 'rejected'
                ? 'Error'
                : order.status.toLowerCase() === 'canceled'
                    ? 'Warning'
                    : 'Success'}Notification`, `Order #${order.id} has been ${order.status.capitalize()}`, { root: true });
        }
        commit('SET_NOTIFICATION_TO_CACHE', order);

        if (order.placementName === 'OTC Market') {
            const allActiveOrders: SpotOrderPresenter[][] = state.otcOpenOrdersData.orders.values();
            const hasSameActiveOrder = allActiveOrders
                .some((ordersArray) => ordersArray.some((o) => o.id === order.id));

            const tempOrdersQueue = [...state.otcOrdersData.updatesQueue];
            tempOrdersQueue.push(order);
            state.otcOrdersData.updatesQueue = tempOrdersQueue;
            await dispatch('updateOtcOrderBySocket', order);
            if (order.status.toLowerCase() === 'filled' || order.status.toLowerCase() === 'canceled' || order.status.toLowerCase() === 'rejected') {
                const tempClosedOrdersQueue = [...state.otcClosedOrdersData.updatesQueue];
                tempClosedOrdersQueue.push(order);
                state.otcClosedOrdersData.updatesQueue = tempClosedOrdersQueue;
                await dispatch('updateOtcClosedOrderBySocket', order);
            }
            if (
                (order.status.toLowerCase() !== 'filled' && order.status.toLowerCase() !== 'canceled' && order.status.toLowerCase() !== 'rejected')
                || hasSameActiveOrder
            ) {
                const tempOpenOrdersQueue = [...state.otcOpenOrdersData.updatesQueue];
                tempOpenOrdersQueue.push(order);
                state.otcOpenOrdersData.updatesQueue = tempOpenOrdersQueue;
                await dispatch('updateOtcOpenOrderBySocket', order);
            }
        } else {
            const allActiveOrders: SpotOrderPresenter[][] = state.openOrdersData.orders.values();
            const hasSameActiveOrder = allActiveOrders
                .some((ordersArray) => ordersArray.some((o) => o.id === order.id));

            const tempOrdersQueue = [...state.ordersData.updatesQueue];
            tempOrdersQueue.push(order);
            state.ordersData.updatesQueue = tempOrdersQueue;
            await dispatch('updateOrderBySocket', order);
            if (order.status.toLowerCase() === 'filled' || order.status.toLowerCase() === 'canceled' || order.status.toLowerCase() === 'rejected') {
                const tempClosedOrdersQueue = [...state.closedOrdersData.updatesQueue];
                tempClosedOrdersQueue.push(order);
                state.closedOrdersData.updatesQueue = tempClosedOrdersQueue;
                await dispatch('updateClosedOrderBySocket', order);
            }
            if (
                (order.status.toLowerCase() !== 'filled' && order.status.toLowerCase() !== 'canceled' && order.status.toLowerCase() !== 'rejected')
                || hasSameActiveOrder
            ) {
                const tempOpenOrdersQueue = [...state.openOrdersData.updatesQueue];
                tempOpenOrdersQueue.push(order);
                state.openOrdersData.updatesQueue = tempOpenOrdersQueue;
                await dispatch('updateOpenOrderBySocket', order);
            }
        }
    },
    async updateOrderBySocket({ dispatch, state, commit }, order: ISpotOrderPresenter) {
        try {
            if (state.ordersData.updatesQueue.length > 0 && deepEqual(state.ordersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.ordersData.orders.keys()).forEach((key) => {
                    const array = state.ordersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        const data = array![index].serialize();
                        array![index] = new SpotOrderPresenter({ ...data, ...order });
                        const temp = new Map(state.ordersData.orders);
                        temp.set(key, [...array]);
                        commit('SET_ORDERS', temp);
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.ordersData.orders).keys());
                    commit('SET_ORDERS', new Map());
                    for (let i = 0; i < tempArrayOfKeys.length; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        await dispatch('getOrders', { page: tempArrayOfKeys[i] });
                    }
                }

                const tempQueue = [...state.ordersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.ordersData.updatesQueue = tempQueue;
                if (state.ordersData.updatesQueue.length > 0) {
                    await dispatch('updateOrderBySocket', state.ordersData.updatesQueue[0]);
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    async updateClosedOrderBySocket({ dispatch, state, commit }, order: ISpotOrderPresenter) {
        try {
            if (state.closedOrdersData.updatesQueue.length > 0 && deepEqual(state.closedOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.closedOrdersData.orders.keys()).forEach((key) => {
                    const array = state.closedOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        const data = array![index].serialize();
                        array![index] = new SpotOrderPresenter({ ...data, ...order });
                        const temp = new Map(state.closedOrdersData.orders);
                        temp.set(key, [...array]);
                        commit('SET_CLOSED_ORDERS', temp);
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.closedOrdersData.orders).keys());
                    commit('SET_CLOSED_ORDERS', new Map());
                    for (let i = 0; i < tempArrayOfKeys.length; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        await dispatch('getClosedOrders', { page: tempArrayOfKeys[i] });
                    }
                }

                const tempQueue = [...state.closedOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.closedOrdersData.updatesQueue = tempQueue;
                if (state.closedOrdersData.updatesQueue.length > 0) {
                    await dispatch('updateClosedOrderBySocket', state.closedOrdersData.updatesQueue[0]);
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    async updateOpenOrderBySocket({ dispatch, state, commit }, order: ISpotOrderPresenter) {
        try {
            if (state.openOrdersData.updatesQueue.length > 0 && deepEqual(state.openOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.openOrdersData.orders.keys()).forEach((key) => {
                    const array = state.openOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        if (order.status.toLowerCase() === 'filled' || order.status.toLowerCase() === 'canceled' || order.status.toLowerCase() === 'rejected') {
                            array.splice(index, 1);
                        } else {
                            const data = array![index].serialize();
                            array![index] = new SpotOrderPresenter({ ...data, ...order });
                        }
                        const temp = new Map(state.openOrdersData.orders);
                        temp.set(key, [...array]);
                        commit('SET_OPEN_ORDERS', temp);
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.openOrdersData.orders).keys());
                    commit('SET_OPEN_ORDERS', new Map());
                    for (let i = 0; i < tempArrayOfKeys.length; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        await dispatch('getOpenOrders', { page: tempArrayOfKeys[i] });
                    }
                }

                const tempQueue = [...state.openOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.openOrdersData.updatesQueue = tempQueue;
                if (state.openOrdersData.updatesQueue.length > 0) {
                    await dispatch('updateOpenOrderBySocket', state.openOrdersData.updatesQueue[0]);
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    async updateOtcOrderBySocket({ dispatch, state, commit }, order: ISpotOrderPresenter) {
        try {
            if (state.otcOrdersData.updatesQueue.length > 0 && deepEqual(state.otcOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.otcOrdersData.orders.keys()).forEach((key) => {
                    const array = state.otcOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        const data = array![index].serialize();
                        array![index] = new SpotOrderPresenter({ ...data, ...order });
                        const temp = new Map(state.otcOrdersData.orders);
                        temp.set(key, [...array]);
                        commit('SET_OTC_ORDERS', temp);
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.otcOrdersData.orders).keys());
                    commit('SET_OTC_ORDERS', new Map());
                    for (let i = 0; i < tempArrayOfKeys.length; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        await dispatch('getOtcOrders', { page: tempArrayOfKeys[i] });
                    }
                }

                const tempQueue = [...state.otcOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.otcOrdersData.updatesQueue = tempQueue;
                if (state.otcOrdersData.updatesQueue.length > 0) {
                    await dispatch('updateOtcOrderBySocket', state.otcOrdersData.updatesQueue[0]);
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    async updateOtcClosedOrderBySocket({ dispatch, state, commit }, order: ISpotOrderPresenter) {
        try {
            if (state.otcClosedOrdersData.updatesQueue.length > 0 && deepEqual(state.otcClosedOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.otcClosedOrdersData.orders.keys()).forEach((key) => {
                    const array = state.otcClosedOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        const data = array![index].serialize();
                        array![index] = new SpotOrderPresenter({ ...data, ...order });
                        const temp = new Map(state.otcClosedOrdersData.orders);
                        temp.set(key, [...array]);
                        commit('SET_OTC_CLOSED_ORDERS', temp);
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.otcClosedOrdersData.orders).keys());
                    commit('SET_OTC_CLOSED_ORDERS', new Map());
                    for (let i = 0; i < tempArrayOfKeys.length; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        await dispatch('getOtcClosedOrders', { page: tempArrayOfKeys[i] });
                    }
                }

                const tempQueue = [...state.otcClosedOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.otcClosedOrdersData.updatesQueue = tempQueue;
                if (state.otcClosedOrdersData.updatesQueue.length > 0) {
                    await dispatch('updateOtcClosedOrderBySocket', state.otcClosedOrdersData.updatesQueue[0]);
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    async updateOtcOpenOrderBySocket({ dispatch, state, commit }, order: ISpotOrderPresenter) {
        try {
            if (state.otcOpenOrdersData.updatesQueue.length > 0 && deepEqual(state.otcOpenOrdersData.updatesQueue[0], order)) {
                let flag = false;
                Array.from(state.otcOpenOrdersData.orders.keys()).forEach((key) => {
                    const array = state.otcOpenOrdersData.orders.get(key);
                    const index = array?.findIndex((t) => t.id === order.id);
                    if (index !== undefined && index !== -1) {
                        if (order.status.toLowerCase() === 'filled' || order.status.toLowerCase() === 'canceled' || order.status.toLowerCase() === 'rejected') {
                            array.splice(index, 1);
                        } else {
                            const data = array![index].serialize();
                            array![index] = new SpotOrderPresenter({ ...data, ...order });
                        }
                        const temp = new Map(state.otcOpenOrdersData.orders);
                        temp.set(key, [...array]);
                        commit('SET_OTC_OPEN_ORDERS', temp);
                        flag = true;
                    }
                });
                if (!flag) {
                    const tempArrayOfKeys = Array.from(new Map(state.otcOpenOrdersData.orders).keys());
                    commit('SET_OTC_OPEN_ORDERS', new Map());
                    for (let i = 0; i < tempArrayOfKeys.length; i += 1) {
                        // eslint-disable-next-line no-await-in-loop
                        await dispatch('getOtcOpenOrders', { page: tempArrayOfKeys[i] });
                    }
                }

                const tempQueue = [...state.otcOpenOrdersData.updatesQueue];
                tempQueue.splice(0, 1);
                state.otcOpenOrdersData.updatesQueue = tempQueue;
                if (state.otcOpenOrdersData.updatesQueue.length > 0) {
                    await dispatch('updateOtcOpenOrderBySocket', state.otcOpenOrdersData.updatesQueue[0]);
                }
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    // trades socket updates
    updateTradesBySocket({ dispatch, rootState }, trade: ISpotTrade) {
        if (trade.placementName === 'OTC Market' && trade.accountId === rootState.Accounts.activeAccountID) {
            dispatch('updateOtcTradeBySocket', trade);
        } else if (trade.accountId === rootState.Accounts.activeAccountID) {
            dispatch('updateTradeBySocket', trade);
        }
        dispatch('updateOrdersTradesBySocket', trade);
    },
    async updateTradeBySocket({ dispatch, state, commit }, trade: ISpotTrade) {
        try {
            let flag = false;
            Array.from(state.tradesData.trades.keys()).forEach((key) => {
                const array = state.tradesData.trades.get(key);
                const index = array?.findIndex((t) => t.id === trade.id);
                if (index !== undefined && index !== -1) {
                    const data = array![index].serialize();
                    array![index] = new SpotTrade({ ...data, ...trade });
                    const temp = new Map(state.tradesData.trades);
                    temp.set(key, [...array]);
                    commit('SET_TRADES', temp);
                    flag = true;
                }
            });
            if (!flag) {
                const tempArrayOfKeys = Array.from(new Map(state.tradesData.trades).keys());
                commit('SET_TRADES', new Map());
                tempArrayOfKeys.forEach((key) => {
                    dispatch('getTrades', { page: key });
                });
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    async updateOtcTradeBySocket({ dispatch, state, commit }, trade: ISpotTrade) {
        try {
            let flag = false;
            Array.from(state.otcTradesData.trades.keys()).forEach((key) => {
                const array = state.otcTradesData.trades.get(key);
                const index = array?.findIndex((t) => t.id === trade.id);
                if (index !== undefined && index !== -1) {
                    const data = array![index].serialize();
                    array![index] = new SpotTrade({ ...data, ...trade });
                    const temp = new Map(state.otcTradesData.trades);
                    temp.set(key, [...array]);
                    commit('SET_OTC_TRADES', temp);
                    flag = true;
                }
            });
            if (!flag) {
                const tempArrayOfKeys = Array.from(new Map(state.otcTradesData.trades).keys());
                commit('SET_OTC_TRADES', new Map());
                tempArrayOfKeys.forEach((key) => {
                    dispatch('getOtcTrades', { page: key });
                });
            }
        } catch {
            // code crushed because of indexes error
        }
    },
    updateOrdersTradesBySocket({ state }, trade: ISpotTrade) {
        if (!state.ordersTradesMap.has(`${trade.accountId}, ${trade.orderId}`)) {
            return;
        }

        const tempArray = state.ordersTradesMap.get(`${trade.accountId}, ${trade.orderId}`);
        const index = tempArray.findIndex((t) => t.id === trade.id);
        if (index !== undefined && index !== -1) {
            tempArray.splice(index, 1, new SpotTrade({ ...tempArray[index].serialize(), ...trade }));
        } else {
            tempArray.push(new SpotTrade(trade));
        }
        const tempMap = new Map(state.ordersTradesMap);
        tempMap.set(`${trade.accountId}, ${trade.orderId}`, [...tempArray]);
        state.ordersTradesMap = tempMap;
    },

    cancelOrder(_, orderId) {
        return TradingApi.privateOrdersCancelOrder(new CancelOrderRequest({
            id: orderId,
        }));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
