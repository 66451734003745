// This file was autogenerated. Please do not change.
/* A response for api-key */
export interface IKeyResponse {
    apiKey: string;
    name: string;
    scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];
}

export default class KeyResponse {
    readonly _apiKey: string;

    /** Description: API-key */
    get apiKey(): string {
        return this._apiKey;
    }

    readonly _name: string;

    /** Description: API-key name */
    get name(): string {
        return this._name;
    }

    readonly _scopes: ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[];

    /** Description: API-key scopes */
    get scopes(): ('Read' | 'Transfer' | 'Trading' | 'DeFi' | 'Contracts' | 'Portfolio' | 'Institution' | 'Accounts' | 'Withdraw' | 'Deposit' | 'Finance' | 'Statements')[] {
        return this._scopes;
    }

    constructor(props: IKeyResponse) {
        this._apiKey = props.apiKey.trim();
        this._name = props.name.trim();
        this._scopes = props.scopes;
    }

    serialize(): IKeyResponse {
        const data: IKeyResponse = {
            apiKey: this._apiKey,
            name: this._name,
            scopes: this._scopes,
        };
        return data;
    }

    toJSON(): IKeyResponse {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            apiKey: typeof this._apiKey === 'string' && !!this._apiKey.trim(),
            name: typeof this._name === 'string' && !!this._name.trim(),
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
