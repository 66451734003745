import { PlacementsDataSources } from 'Config/placementsDataSources';

/**
 *
 * @param {String} placementName
 *
 * @return {Boolean}
 */
export function HasPlacementDataSource(placementName) {
    // eslint-disable-next-line no-prototype-builtins
    return PlacementsDataSources.hasOwnProperty(placementName.toUpperCase());
}

/**
 *
 * @param {String} placementName
 *
 * @return {String}
 */
export function GetPlacementDataSource(placementName) {
    return HasPlacementDataSource(placementName) ? PlacementsDataSources[placementName.toUpperCase()] : PlacementsDataSources.DEFAULT;
}
