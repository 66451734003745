import Vue from 'vue';
import { Line, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default Vue.extend<any, any, any, any>({
    extends: Line,
    mixins: [reactiveProp],
    props: {
        chartData: {
            type: Object,
            required: true,
        },
        tooltipItemSortCallback: {
            type: Function,
            default: null,
        },
        tooltipTitleCallback: {
            type: Function,
            default: null,
        },
        tooltipLabelCallback: {
            type: Function,
            default: null,
        },
        tooltipFooterCallback: {
            type: Function,
            default: null,
        },
        xAxeLabelCallback: {
            type: Function,
            default: null,
        },
        yAxeLabelCallback: {
            type: Function,
            default: null,
        },
    },
    computed: {
        darkTheme() {
            return this.$store.getters.isThemeDark;
        },
        fontColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.5)' : 'rgba(35, 36, 77, 0.5)';
        },
        gridLinesColor() {
            return this.darkTheme ? 'rgba(255, 255, 255, 0.05)' : 'rgba(0, 0, 0, 0.05)';
        },

        tooltipCallbacks() {
            const result: any = {};

            if (this.tooltipTitleCallback) { result.title = this.tooltipTitleCallback; }
            if (this.tooltipLabelCallback) { result.label = this.tooltipLabelCallback; }
            if (this.tooltipFooterCallback) { result.footer = this.tooltipFooterCallback; }

            return result;
        },
        data() {
            let { datasets } = this.chartData;
            const { labels } = this.chartData;

            datasets = datasets.map((dataset) => (dataset.datalabels
                ? dataset
                : {
                    ...dataset,
                    datalabels: {
                        labels: {
                            title: null,
                        },
                    },
                }));

            return {
                labels,
                datasets,
            };
        },
        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            color: '#f5f5f5',
                            ticks: {
                                beginAtZero: true,
                                fontSize: 13,
                                fontColor: this.fontColor,
                                fontFamily: 'Nunito Sans, sans-serif',
                                fontWeight: 500,
                                padding: 5,
                                callback: this.yAxeLabelCallback ? this.yAxeLabelCallback : (value) => value,
                            },
                            gridLines: {
                                drawBorder: false,
                                zeroLineBorderDashOffset: 1,
                                zeroLineColor: this.gridLinesColor,
                                color: this.gridLinesColor,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'time',
                            mode: 'categories',
                            time: {
                                unit: 'day',
                                step: 2,
                            },
                            color: '#fff',
                            showTicks: false,
                            gridLines: false,
                            ticks: {
                                major: {
                                    enabled: false,
                                    fontSize: 11,
                                    fontStyle: 'bold',
                                },
                                maxRotation: 0,
                                minRotation: 0,
                                fontSize: 10,
                                fontColor: this.fontColor,
                                lineHeight: 3,
                                fontFamily: 'Nunito Sans, sans-serif',
                                callback: this.xAxeLabelCallback ? this.xAxeLabelCallback : (value) => value,
                                autoSkip: true,
                                maxTicksLimit: 10,
                            },
                        },
                    ],
                },
                tooltips: {
                    mode: 'index',
                    intersect: false,
                    titleFontFamily: 'Nunito Sans, sans-serif',
                    bodyFontFamily: 'Nunito Sans, sans-serif',
                    callbacks: this.tooltipCallbacks,
                    itemSort: this.tooltipItemSortCallback ? this.tooltipItemSortCallback : () => 0,
                    titleFontSize: 13,
                    bodyFontSize: 13,
                    footerFontSize: 13,
                },
                hover: {
                    mode: 'x',
                    intersect: false,
                },
                plugins: {
                    datalabels: {
                        display: false,
                    },
                },
            };
        },
    },
    methods: {
        Render() {
            this.renderChart(this.data, this.options);
        },
    },
    mounted() {
        this.Render();
    },
    watch: {
        darkTheme() {
            this.Render();
        },
    },
});
