import AccountInfo, { IAccountInfo } from './AccountInfo';

// This file was autogenerated. Please do not change.
export interface IBaseAccountSharingPayload {
    account: IAccountInfo;
    message?: string;
}

export default class BaseAccountSharingPayload {
    readonly _account: AccountInfo;

    get account(): AccountInfo {
        return this._account;
    }

    readonly _message: string | undefined;

    /** Description: Message to recipient */
    get message(): string | undefined {
        return this._message;
    }

    constructor(props: IBaseAccountSharingPayload) {
        this._account = new AccountInfo(props.account);
        if (typeof props.message === 'string') {
            this._message = props.message.trim();
        }
    }

    serialize(): IBaseAccountSharingPayload {
        const data: IBaseAccountSharingPayload = {
            account: this._account.serialize(),
        };
        if (typeof this._message !== 'undefined') {
            data.message = this._message;
        }
        return data;
    }

    toJSON(): IBaseAccountSharingPayload {
        return this.serialize();
    }

    validate(): string[] {
        const validateRequired = {
            account: this._account.validate().length === 0,
            message: !this._message ? true : typeof this._message === 'string',
        };
        const errorInFields: string[] = [];
        Object.keys(validateRequired).forEach((key) => {
            if (!(validateRequired as any)[key]) {
                errorInFields.push(key);
            }
        });
        return errorInFields;
    }
}
