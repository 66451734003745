/* eslint-disable max-classes-per-file */
// eslint-disable-next-line no-unused-vars
import store from '@/store';
import { timeConstants } from 'Config/timeConstants';
import { getManifest } from 'Models/assetsManifest';
import { defaultChartBackgroundColorOpacity, defaultChartColors } from 'Config/portfolio';
import { hexToRgbaString } from 'Models/hexToRgb';
import Balance from 'Entities/privatePresenter/Balance';

export class StatisticRecord {
    balance = 0

    quoted = 0

    placements = {}

    constructor(balance = 0, quoted = 0) {
        this.setBalance(balance);
        this.setQuoted(quoted);
    }

    setBalance(balance) {
        this.balance = balance;
    }

    incrementBalance(value) {
        this.setBalance(this.balance + value);
    }

    setQuoted(quoted) {
        this.quoted = quoted;
    }

    incrementQuoted(value) {
        this.setQuoted(this.quoted + value);
    }

    setPlacement(placementName, balance, quoted) {
        this.placements[placementName] = {
            balance,
            quoted,
        };

        this.incrementBalance(balance);
        this.incrementQuoted(quoted);
    }
}

export function generateLabels(fromTimestamp, toTimestamp, { withNow, hasAdditionalLabel } = {} as { withNow?: boolean, hasAdditionalLabel?: any }) {
    const fromDate = new Date((new Date(fromTimestamp)).setHours(0, 0, 0, 0));
    const UTCFromDate = new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate()));

    let currentLabelTimestamp = UTCFromDate.getTime();

    const labels: any[] = [];

    while (currentLabelTimestamp < toTimestamp) {
        labels.push(currentLabelTimestamp);
        currentLabelTimestamp += timeConstants.DAY;
    }

    if (withNow) {
        labels.push(Date.now());
    }

    if (hasAdditionalLabel) {
        labels.push(currentLabelTimestamp);
    }

    return labels;
}

export function generateStatistic(placementsStatistics, balance, labels) {
    if (!balance) {
        return;
    }
    const { accountId, assetSymbol, assetPrecision } = balance;

    const assetManifest = getManifest(assetSymbol);

    const result = {
        accountId,
        assetSymbol,
        assetPrecision,

        total: balance.totalEquivalent,

        chartColors: {
            line: assetManifest ? assetManifest.color : defaultChartColors.line,
            background: assetManifest ? hexToRgbaString(assetManifest.color, defaultChartBackgroundColorOpacity) : defaultChartColors.background,
        },

        records: Object.fromEntries(labels.map((timestamp) => [timestamp, new StatisticRecord()])),
    };
    let currentPlacement = null;
    let currentRecordIndex: any = null;
    let currentBalance: any = null;
    let currentQuoted: any = null;

    Object.keys(placementsStatistics).forEach((placement) => {
        currentPlacement = placementsStatistics[placement];

        labels.forEach((timestamp, index) => {
            currentRecordIndex = (currentPlacement as any).UTCtimestampList.findIndex((ts) => ts === timestamp);

            if (~currentRecordIndex) {
                currentBalance = +(currentPlacement as any)?.totalBalanceList[currentRecordIndex];
                currentQuoted = +(currentPlacement as any)?.totalBalanceListQuoted[currentRecordIndex];

                result.records[timestamp].setPlacement(placement, currentBalance, currentQuoted);
            } else if (index === labels.length - 1 && timestamp > Date.now() - timeConstants.DAY) {
                const currentBalanceRecord: Balance = store.getters['Balances/GET_ACTIVE_ACCOUNT_BALANCE_BY_PARAMS'](store.getters['Placements/getPlacementIdByName'](placement), assetSymbol);

                if (currentBalanceRecord) {
                    currentBalance = currentBalanceRecord.total;
                    const currentQuotationAssetSymbol = store.getters['Assets/GET_QUOTATION_ASSET_SYMBOL'];
                    const quotationIndex = currentBalanceRecord.quotationAssetSymbols?.findIndex((s) => s === currentQuotationAssetSymbol);
                    currentQuoted = currentBalanceRecord.total * ((currentBalanceRecord.quotations ? currentBalanceRecord.quotations![quotationIndex ?? 0] : 0) as number);

                    result.records[timestamp].setPlacement(placement, currentBalance, currentQuoted);
                }
            }
        });
    });

    return result;
}

export class PnlRecord {
    quantity

    timestamp

    constructor(quantity, timestamp) {
        this.quantity = quantity ? +quantity : null;
        this.timestamp = timestamp;
    }
}

export const TRADES_STATISTIC_TYPES = {
    EXCHANGE: 'Exchange',
    ASSET_PAIR: 'AssetPair',
};

export const PORTFOLIO_TYPES = {
    CURRENT_ACCOUNT: 'current-account',
    ALL_GROUP_ACCOUNTS: 'all-group-accounts',
};
